import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

const SvgTooltipComponent = () => {
  const [svgModel, updateSvgModel] = useState<Element | null>();
  useEffect(() => {
    if (svgModel) {
      const clickListener = () => {
        const current_url = window.location.href.split("/").slice(0, -1);
        const domain_family_member = svgModel.getAttribute("data-model");
        if (domain_family_member) {
          const [domain, family, member] = domain_family_member.split("/", 3);
          const url = current_url
            .concat(["devices", domain, family, member, "server"])
            .join("/");
          window.open(url, "_blank");
        }
      };
      svgModel.addEventListener("click", clickListener);
      return () => {
        svgModel.removeEventListener("click", clickListener);
      }; // avoid endless call
    }
    return () => {};
  }, [svgModel]);

  function updateContent({ content, activeAnchor }) {
    return activeAnchor
      ? activeAnchor.getAttribute("data-model") +
          " " +
          (activeAnchor.getAttribute("data-value") || "") +
          " " +
          (activeAnchor.getAttribute("data-quality") || "")
      : " ";
  }
  return (
    <Tooltip
      id="svg-tooltip"
      ref={(c) => {
        updateSvgModel(c?.activeAnchor);
      }}
      render={updateContent}
    />
  );
};

export default SvgTooltipComponent;
