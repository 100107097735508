import attributeDisplay from "./AttributeDisplay/AttributeDisplay";
import attributeWriter from "./AttributeWriter/AttributeWriter";
import attributePlot from "./AttributePlot";
import attributeScatter from "./AttributeScatter/AttributeScatter";
import label from "./Label/Label";
import spectrum2d from "./Spectrum2D/Spectrum2D";
import spectrum from "./Spectrum/Spectrum";
import embedPage from "./EmbedPage/EmbedPage";
import attributeDial from "./AttributeDial/AttributeDial";
import booleanDisplay from "./BooleanDisplay/BooleanDisplay";
import ledDisplay from "./AttributeLEDDisplay/AttributeLEDDisplay";
import sardanaMotor from "./Sardana/SardanaMotor/SardanaMotor";
import macroButton from "./Sardana/MacroButton/MacroButton";
import attributeLogger from "./AttributeLogger/AttributeLogger";
import spectrumTable from "./SpectrumTable/SpectrumTable";
import attributeHeatMap from "./AttributeHeatMap/AttributeHeatMap";
import ImageDisplay from "./ImageDisplay/ImageDisplay";
import { Widget, WidgetDefinition, WidgetBundle } from "../types";
import DeviceStatus from "./DeviceStatus/DeviceStatus";
import CommandSwitch from "./CommandSwitch/CommandSwitch";
import Command from "./Command/Command";
import CommandFile from './CommandFile/CommandFile';
import ImageTable from './ImageTable/ImageTable'
import ElasticsearchLogViewer from "./ElasticsearchLogViewer/ElasticsearchLogViewer";
import attributeWriterDropdown from "./AttributeWriterDropdown/AttributeWriterDropDown";
import Timeline from "./Timeline";
import Parametric from "./Parametric/Parametric";
import Box from "./Box/Box";
import Tabular from "./Tabular/Tabular";
import PlaceHolder from "./PlaceHolder/PlaceHolder";
import DashboardLink from "./DashboardLink/DashboardLink";
import SvgWidget from "./SvgWidget/SvgWidget";

const config = window['config'];
const widgetsToHide:Array<String> = config.WIDGETS_TO_HIDE;

let bundleData: WidgetBundle<{}>[] = [
  Box,
  label,
  attributeDisplay,
  attributeWriter,
  attributeWriterDropdown,
  Parametric,
  SvgWidget,
  attributePlot,
  attributeScatter,
  spectrum,
  spectrum2d,
  Tabular,
  embedPage,
  DashboardLink,
  Timeline,
  Command,
  CommandFile,
  attributeDial,
  ElasticsearchLogViewer,
  CommandSwitch,
  booleanDisplay,
  DeviceStatus,
  ledDisplay,
  sardanaMotor,
  macroButton,
  attributeLogger,
  attributeHeatMap,
  ImageDisplay,
  spectrumTable,
  ImageTable,
  PlaceHolder
];

if (Array.isArray(widgetsToHide) && widgetsToHide.length > 0) {
  bundleData = bundleData.filter((bundle) => (-1 === widgetsToHide.indexOf(bundle.definition.type)));
}
export const bundles = bundleData;

function bundleForType(type: string) {
  const bundle = bundles.find(bundle => bundle.definition.type === type);
  if (!bundle) {
    const bundle = bundleData.find(bundle => bundle.definition.type === 'PlaceHolder');
    return bundle!;
  }
  return bundle;
}

export function definitionForType(type: string): WidgetDefinition<{}> {
  const bundle = bundleForType(type);
  return bundle.definition;
}

export function componentForType(type: string) {
  const bundle = bundleForType(type);
  return bundle.component;
}

export function bundleForWidget(widget: Widget) {
  return bundleForType(widget.type);
}

export function definitionForWidget(widget: Widget) {
  return definitionForType(widget.type);
}

export function componentForWidget(widget: Widget) {
  return componentForType(widget.type);
}