import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAttributeLastValueFromState } from "../../../shared/utils/getLastValueHelper";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
// In order to avoid importing the entire plotly.js library. Note that this mutates the global PlotlyCore object.
import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import createPlotlyComponent from "react-plotly.js/factory";

// prettier-ignore
const sampleData = [0, -2, 3, -2, 1, -5, 4, -3, -2, -4, 0, -4, 2, 2, -2, -2, 2, -5, -2, -3, 0];

const SpectrumValues = ({
  deviceName,
  attributeName,
  mode,
  showTitle,
  title,
  titlefont,
  font,
  margin,
  autosize,
  inelastic,
  config,
  responsive,
  style,
}) => {
  PlotlyCore.register([PlotlyScatter]);
  const Plotly = createPlotlyComponent(PlotlyCore);

  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      deviceName,
      attributeName
    );
  });

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  const { min: currMin, max: currMax } = { min: minValue, max: maxValue };

  const yAxis = (inelastic) => {
    const { min, max } = { min: minValue, max: maxValue };
    if (inelastic && min != null && max != null) {
      return {
        range: [1.1 * min, 1.1 * max],
      };
    } else {
      return {};
    }
  };

  const y = mode === "library" || value === undefined ? sampleData : value;
  const data = [{ y }];

  const propMin = y && y.length > 0 ? y.reduce((a, b) => Math.min(a, b)) : 0;
  const propMax = y && y.length > 0 ? y.reduce((a, b) => Math.max(a, b)) : 0;
  // rescale the plotting range based on the current spectrum
  const min = propMin;
  const max = propMax;

  if (min !== currMin || max !== currMax) {
    setMinValue(min);
    setMaxValue(max);
  }

  const yaxis = yAxis(inelastic);

  const layout = {
    title,
    titlefont: { size: 12 },
    font: { family: "Helvetica, Arial, sans-serif" },
    margin: {
      l: 30,
      r: 15,
      t: 15 + (showTitle ? 20 : 0),
      b: 20,
    },
    autosize: true,
    yaxis,
  };

  return (
    <div id = "plotlySpectrumDiv">
      <Plotly
        data={data}
        layout={layout}
        config={config}
        responsive={true}
        style={style}
      />
    </div>
  );
};

export default SpectrumValues;
