import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import { getStateUrls } from "../../user/state/selectors";
import { saveStateUrls, loadStateUrls } from "../../user/state/actionCreators";
import Notifications from "../../../shared/notifications/notifications";
import LogInOut from "../../user/components/LogInOut/LogInOut";

import "./Navbar.css";
import { IRootState } from "../../state/reducers/rootReducer";
import { loadTangoDBName } from "../../state/actions/tango";

const config = window["config"];

export interface StateUrls {
  deviceUrl: string;
  dashboardUrl: string;
  synopticUrl: string;
}

interface linkUrls {
  devices: string;
  dashboard: string;
  synoptic: string;
}

interface State {}

interface Props {
  onSaveStateUrls: Function;
  onLoadStateUrls: Function;
  onLoadTangoDBName: Function;
  stateUrls: StateUrls;
  children?: React.ReactNode;
}

interface paramsType {
  tangoDB: string;
  section: string;
}

interface NavContainerProps {
  params: paramsType;
  saveStateUrls: Function;
  stateUrls: StateUrls;
}

/**
 * This class contains the actual links of Navbar
 */
export class NavContainer extends Component<NavContainerProps, State> {
  stateUrl: linkUrls;

  public constructor(props: NavContainerProps) {
    super(props);

    this.stateUrl = {
      devices: "",
      dashboard: "",
      synoptic: "",
    };

    this.onSaveState = this.onSaveState.bind(this);
  }

  render() {
    let { tangoDB, section } = this.props.params;

    const sections = [
      ["Devices", "devices", this.props.stateUrls.deviceUrl],
      ["Dashboards", "dashboard", this.props.stateUrls.dashboardUrl],
    ];
    if(config.synopticEnabled) {
      sections.push(["Synoptics", "synoptic", this.props.stateUrls.synopticUrl]);
    }

    let objThis = this;
    const links = sections.map(function([name, identifier, savedUrlPath]) {
      objThis.stateUrl[identifier] =
        savedUrlPath &&
        savedUrlPath.includes(objThis.props.params.tangoDB + "/")
          ? savedUrlPath
          : config.basename + `/${tangoDB}/${identifier}`;
      return (
        <a
          key={identifier}
          className={
            "tabbed-menu " + (identifier === section ? "active" : "non-active")
          }
          onClick={() => objThis.onSaveState(identifier)}
          href={objThis.stateUrl[identifier]}
        >
          {name}
        </a>
      );
    });

    return (
      <div data-testid="pagelinks" className="page-links">
        {" "}
        {links}
      </div>
    );
  }

  onSaveState(identifier) {
    const history = createBrowserHistory();

    let stateUrls = {
      deviceUrl: this.stateUrl.devices,
      dashboardUrl: this.stateUrl.dashboard,
      synopticUrl: this.stateUrl.synoptic,
    };

    if ("devices" === identifier) {
      if (history.location.pathname.includes("synoptic")) {
        stateUrls.synopticUrl =
          history.location.pathname + history.location.search;
      } else {
        stateUrls.dashboardUrl =
          history.location.pathname + history.location.search;
      }
    } else if ("dashboard" === identifier) {
      if (history.location.pathname.includes("synoptic")) {
        stateUrls.synopticUrl =
          history.location.pathname + history.location.search;
      } else {
        stateUrls.deviceUrl =
          history.location.pathname + history.location.search;
      }
    } else if ("synoptic" === identifier) {
      if (history.location.pathname.includes("devices")) {
        stateUrls.deviceUrl =
          history.location.pathname + history.location.search;
      } else {
        stateUrls.dashboardUrl =
          history.location.pathname + history.location.search;
      }
    }

    this.props.saveStateUrls(stateUrls);
  }
}

export class Navigationbar extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.props.onLoadStateUrls();
  }

  componentDidMount(): void {
    this.props.onLoadTangoDBName();
  }

  render() {
    return (
      <div className="Navbar">
        <div className="navigation">
          <BrowserRouter basename={config.basename}>
            <Route
              path="/:tangoDB/:section"
              render={(props) => (
                <NavContainer
                  params={props.match.params}
                  saveStateUrls={this.props.onSaveStateUrls}
                  stateUrls={this.props.stateUrls}
                />
              )}
            />
          </BrowserRouter>
        </div>
        <Notifications />
        <div
          className={
            window.location.href.indexOf("devices") !== -1 ? "space" : ""
          }
        ></div>
        {this.props.children || null}
        <LogInOut />
      </div>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    stateUrls: getStateUrls(state),
  };
}

function mapDispatchToProps(dispatch: Function) {
  return {
    onLoadStateUrls: () => dispatch(loadStateUrls()),
    onSaveStateUrls: (stateUrls: StateUrls) =>
      dispatch(saveStateUrls(stateUrls)),
    onLoadTangoDBName: () => dispatch(loadTangoDBName()),
  };
}

export const Navbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigationbar);
