import React, { Component, FormEvent, ChangeEvent } from "react";
import { DeviceConsumer } from "../DevicesProvider";
import DeviceSuggester from "./DeviceSuggester";
import TangoAPI from "../../../shared/api/tangoAPI";
import { Variable } from "../../types";
import { getDeviceOfDashboardVariable } from '../../../shared/utils/DashboardVariables';
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath } from "../../runtime/utils";
import TangoDbSelector from "../TangoDbSelector";
import { splitFullPath } from "../../DBHelper"


interface Command {
  name: string;
  intype: string;
  intypedesc?: string;
  outtypedesc?: string;
  outtype?: string;
  tag?: Number;
}

interface Props {
  widgetId: string;
  device: string;
  command: string | Array<string>;
  inputType?: string;
  intypedesc?: string;
  onSelect: (
    device: string, 
    command: string | null, 
    acceptedType: string | null,
    intypedesc: string | null,
    outtypedesc: string | null,
    outtype: string | null,
    tag: Number | null,
    ) => void;
  selectMultipleCommands: boolean;
  nonEditable: boolean;
  variables: Variable[];
} 

interface State {
  fetchingCommands: boolean;
  commands: Command[];
}

export default class CommandSelect extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { fetchingCommands: false, commands: [] };
    this.handleSelectDevice = this.handleSelectDevice.bind(this);
    this.handleSelectCommand = this.handleSelectCommand.bind(this);
    this.handleSelectMultipleCommands = this.handleSelectMultipleCommands.bind(this);
    this.cleanDevices = this.cleanDevices.bind(this);
  }

  public componentDidMount() {
    this.fetchCommands();
  }

  public componentDidUpdate(prevProps) {
    if (this.props.device !== prevProps.device) {
      this.setState({ commands: [] });
      this.fetchCommands();
    }
  }

  public handleSelectDevice(newDevice: string, tangoDB: string) {
    this.fetchCommands();
    const { onSelect } = this.props;
    if (onSelect && newDevice) {
      const fullPath = createDeviceWithTangoDBFullPath(tangoDB, newDevice);
      onSelect(fullPath, null, null, null, null, null, null);
    }
  }

  public handleSelectMultipleCommands(event: ChangeEvent<HTMLInputElement>, tangoDB: string) {
    const { onSelect, device } = this.props;
    const newCommand = event.currentTarget.value;
    if (onSelect && device && newCommand) {
      const fullPath = createDeviceWithTangoDBFullPath(tangoDB, device);
      onSelect(fullPath, newCommand, null, null, null, null, null);
    }
  }

  public cleanDevices() {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect("", null, null, null, null, null, null);
    }

    this.setState({ commands: [] })
  }

  public handleSelectCommand(event: FormEvent<HTMLSelectElement>) {
    const { onSelect, device } = this.props;
    const intype = event.currentTarget.selectedOptions[0].attributes["data-intype"].value;
    const intypedesc = event.currentTarget.selectedOptions[0].attributes["data-intypedesc"].value;
    const outtypedesc = event.currentTarget.selectedOptions[0].attributes["data-outtypedesc"].value;
    const outtype = event.currentTarget.selectedOptions[0].attributes["data-outtype"].value;
    const tag = event.currentTarget.selectedOptions[0].attributes["data-tag"].value;
    const newCommand = event.currentTarget.value;
    if (onSelect && device && newCommand) {
      onSelect(device, newCommand, intype, intypedesc, outtypedesc, outtype, tag);
    }
  }

  public render() {
    const { device, command, selectMultipleCommands, nonEditable } = this.props;
    const commands = this.filteredCommands();
    
    return (
      <DeviceConsumer>
        {({ devices, onTangoDBChange, tangoDB }) => {
          const tangoDBActive = device ? getTangoDbFromPath(device) : tangoDB;
          const hasDevice = !!device;
          const hasCommands = commands.length > 0;

          return (
            <div className="CommandSelect">
              <TangoDbSelector
                widgetId={this.props.widgetId}
                onSelect={
                  (tangodb) => {
                    this.cleanDevices();
                    onTangoDBChange(tangodb);
                  }}
                tangoDB={tangoDBActive}
                hasDevice={hasDevice}
              />

              <DeviceSuggester
                deviceName={device}
                devices={devices}
                onSelection={newValue => this.handleSelectDevice(newValue, tangoDBActive)}
                nonEditable={nonEditable}
              />
              {
                selectMultipleCommands && hasDevice && hasCommands ? (
                  commands.map(({name}) => (
                    <div key={name} style={{ padding: "2px 5px", display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        value={name}
                        onChange={(e) => this.handleSelectMultipleCommands(e, tangoDBActive)}
                        checked={command.indexOf(name) !== -1}
                      />
                      <span style={{ margin: "0 0 0 2px" }}>{name}</span>
                    </div>
                  ))
                ) : (
                  <select
                    className="form-control"
                    value={command || ""}
                    disabled={hasCommands === false}
                    onChange={this.handleSelectCommand}
                  >
                    {hasDevice === false && (
                      <option value="">
                        Pick a device first
                      </option>
                    )}
                    {hasDevice && hasCommands === false && (
                      <option value="" >
                        No commands
                      </option>
                    )}
                    {hasDevice && hasCommands && (
                      <option value="" disabled={true} >
                        Select command...
                      </option>
                    )}
                    {commands.map(({ 
                      name, 
                      intype, 
                      intypedesc, 
                      outtypedesc,
                      outtype,
                      tag
                    }, i) => (
                      <option key={i} 
                      data-intype={intype} 
                      data-intypedesc={intypedesc} 
                      data-outtypedesc={outtypedesc}
                      data-outtype={outtype}
                      data-tag={tag}
                      value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                )
              }
            </div>
          );
        }}
      </DeviceConsumer>
    );
  }

  private filteredCommands() {
    const { commands } = this.state;
    const { inputType } = this.props;

    if (inputType != null) {
      if(inputType === "Any") //If the command is an array, return all command that receive an array as an input
        return commands;
      else if(inputType === "NotDevVoid")
        return commands.filter(({ intype }) => !intype.includes("DevVoid"));
      else if(inputType === "Array") //If the command is an array, return all command that receive an array as an input
        return commands.filter(({ intype }) => intype.includes(inputType));
      else
        return commands.filter(({ intype }) => intype === inputType);
    }

    return commands;
  }

  private async fetchCommands() {
    let { device } = this.props;

    if (device) {
      let [tangoDB, deviceName] = splitFullPath(device);
      deviceName = deviceName.includes('/') ? deviceName : getDeviceOfDashboardVariable(this.props.variables, deviceName);
      this.setState({ commands: [], fetchingCommands: true });
      const commands = await TangoAPI.fetchCommands(tangoDB, deviceName);
      this.setState({ commands, fetchingCommands: false });
    }
  }
}
