import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default class AttributeInputType extends Component {
  render() {
    const {
      bounds,
      alarms,
      value,
      type,
      label,
      validating,
      isValid,
      placeholder,
    } = this.props;

    const labelDisplay = label ? <label>Write Value:</label> : null;
    let placeholderValue = placeholder || value;
    if (placeholder === 0) placeholderValue = 0;
    const validationClass = validating && '' !== value
      ? cx("form-control", isValid ? "is-valid" : "is-invalid")
      : cx("form-control", "");

    if (type === "numeric" || type === "devenum") {
      const asNumber = Number(value);
      const isNumeric = value !== "" && !isNaN(asNumber);
      const hasBounds = bounds !== undefined;
      const minvalue = bounds ? bounds[0] : null;
      const maxvalue = bounds ? bounds[1] : null;
      const minAlarm = alarms ? alarms[0] : null;
      const maxAlarm = alarms ? alarms[1] : null;
      let isWithinBounds =
        isNumeric && hasBounds && minvalue && maxvalue
          ? asNumber >= minvalue && asNumber <= maxvalue
          : true;

      const warningMessage = !isNumeric && '' !== value
        ? "The input value is not numeric."
        : hasBounds && !isWithinBounds
          ? "The input value is not in the permitted range (" +
          String(bounds) +
          ")"
          : null;
      
      let warningValue = false;
      if(minAlarm || maxAlarm) warningValue = Number(placeholderValue) >= maxAlarm || Number(placeholderValue) <= minAlarm;

      return (
        <div>
          <div className="form-group">
            {labelDisplay}
            <input
              className={validationClass + ' text-box'}
              value={value}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              placeholder={placeholderValue}
              autoComplete="off"
              style={warningValue ?
                { backgroundColor: '#ffaaaa', color: 'black' } : {}}
            />
            <div className="invalid-feedback">{warningMessage}</div>
          </div>
        </div>
      );
    } else if (type === "boolean") {
      return (
        <div className="form-group">
          {labelDisplay}
          <select className="form-control select-box" onChange={this.props.onChange}>
            <option value="t">true</option>
            <option value="f">false</option>
          </select>
        </div>
      );
    } else if (type === "string") {
      const warningMessage = !isValid ? "The input can't be empty" : null;
      return (
        <div>
          <div className="form-group">
            {labelDisplay}
            <input
              className={validationClass + ' text-box'}
              value={value}
              onChange={this.props.onChange}
              placeholder={placeholderValue}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              autoComplete="on"
            />
            <div className="invalid-feedback">{warningMessage}</div>
          </div>
        </div>
      );
    }
    return null;
  }
}

AttributeInputType.propTypes = {
  bounds: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  label: PropTypes.bool,
  validating: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
