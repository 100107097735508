import React, { Component } from "react";
import "./SidebarTabs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TOGGLE_LIBRARY_COLLAPSED } from "../../shared/state/actions/actionTypes";
import { connect } from "react-redux";

interface Props {
  selectedTab: string;
  onTabChange: (newVal: "library" | "dashboards" | "layers") => void;
  toggleLibraryCollapse: () => void;
}

class SidebarTabs extends Component<Props> {
  public render() {
    const { onTabChange, selectedTab, toggleLibraryCollapse } = this.props;
    return (
      <div className="sidebar-tabs">
        <ul className="nav section-chooser">
          <li
            className={
              "toggle-button toggle-button-left " +
              (selectedTab === "library" ? "toggle-button-selected" : "")
            }
            style={{ width: "25%" }}
            key="library"
            onClick={e => onTabChange("library")}
          >
            Widgets
          </li>
          <li
            className={
              "toggle-button " +
              (selectedTab === "dashboards" ? "toggle-button-selected" : "")
            }
            key="dashboards"
            style={{ width: "40%" }}
            onClick={e => onTabChange("dashboards")}
          >
            Dashboards
          </li>
          <li
            className={
              "toggle-button toggle-button " +
              (selectedTab === "layers" ? "toggle-button-selected" : "")
            }
            key="layers"
            style={{ width: "25%" }}
            onClick={e => onTabChange("layers")}
          >
            Layers
          </li>
          <li
            title={`Hide the library sidebar \n (Alt+l)`}
            className={"toggle-button toggle-button-right "}
            style={{ width: "10%" }}
            key="collapse"
            onClick={toggleLibraryCollapse}
          >
            <FontAwesomeIcon icon={"angle-right"} />
          </li>
        </ul>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLibraryCollapse: () => dispatch({ type: TOGGLE_LIBRARY_COLLAPSED })
  };
}

export default connect(
  null,
  mapDispatchToProps
)(SidebarTabs);
