import { SYNOPTICS_LOADED, SYNOPTIC_SAVED } from "../actionTypes";
import { SynopticAction } from "../actions";
import { Synoptic } from "../../types";

export interface SynopticsState {
  synoptics: Synoptic[];
}
const initialState: SynopticsState = {
  synoptics: []
};

export default function synoptic(
  state: SynopticsState = initialState,
  action: SynopticAction
) {
  switch (action.type) {
    case SYNOPTICS_LOADED:
      return { ...state, synoptics: action.synoptics };

    case SYNOPTIC_SAVED:
      const { id, variables } = action;
      for (let synoptic of state.synoptics) {
        if (synoptic.id === id) {
          synoptic.variables = variables;
          break;
        }
      }
      return { ...state };

    default:
      return state;
  }
}
