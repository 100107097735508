export function getAttributeValuesFromState(messages, attributes) {
  
  let valuesResult = [];

  for ( const [key, value] of Object.entries(messages)) {

    const deviceFilter = attributes.filter(f => f.device === key);

    if (deviceFilter) {
      for(let devFilter of deviceFilter)
      {
        const attributeFilter = devFilter.attribute;

        if (value.attributes.hasOwnProperty(attributeFilter)) {
          valuesResult[key + "/"+ attributeFilter] = {
            values: []
          };

          const attribute = value.attributes[attributeFilter];

          attribute.values.forEach((v, i) => {
            valuesResult[key + "/"+ attributeFilter].values.push(v);
          });
        }
      }
    }  
  }
  return valuesResult; 
}

export function getAttributeTimestampFromState(messages, attributes) {
  let timestampResult = [];
  for (const [key, value] of Object.entries(messages)) {
    const deviceFilter = attributes.filter(f => f.device === key);

    if (deviceFilter) {
      for(let devFilter of deviceFilter)
      {
        const attributeFilter = devFilter.attribute;

        if (value.attributes.hasOwnProperty(attributeFilter)) {
          timestampResult[key + "/"+ attributeFilter] = {
            timestamp: []
          };
  
          const attribute = value.attributes[attributeFilter];
  
          attribute.values.forEach((v, i) => {
            timestampResult[key + "/"+ attributeFilter].timestamp.push(attribute.timestamp[i]);
          });
        }
      }
      
    }
  }

  return timestampResult; 
}