export function commandExamples(type) {
    switch (type) {
        case 'DevBoolean':
            return 'true,false or 0,1';
        case 'DevDouble':
            return '2.3 (64 bits float)';
        case 'DevFloat':
            return '2.3 (32 bits float)';
        case 'DevLong':
            return '10 or 0xa (signed 32bits)';
        case 'DevLong64':
            return '2.3 (64 bits float)';
        case 'DevShort':
            return '10 or 0xa (signed 16bits)\ninput >= -32768 && input <= 32767';
        case 'DevString':
            return 'ABC or "A, B"\n(quotes needed for string with space or special char)';
        case 'DevULong':
            return '10 or 0xa (unsigned 32bits)\ninput >= 0 && input <= 4294967295';
        case 'DevULong64':
            return '10 or 0xa (unsigned 64bits)\ninput >= 0 && input <= ' + Number.MAX_SAFE_INTEGER;
        case 'DevUShort':
            return '10 or 0xa (unsigned 16bits)\ninput >= 0 && input <= 65535';
        case 'DevVarCharArray':
            return "2,0xa,A or [2,0xa,A]";
        case 'DevVarDoubleArray':
            return '2.3,4.2 or [2.3,4.2] (64bits floats)';
        case 'DevVarDoubleStringArray':
            return '[[1.2,2.0][A,"B C",D]]\n(quotes needed for string with space or special char)';
        case 'DevVarFloatArray':
            return '2.3,4.2 or [2.3,4.2] (32bits floats)';
        case 'DevVarLong64Array':
            return '2.3,4.2 or [2.3,4.2] (64bits floats)';
        case 'DevVarLongArray':
            return '10,0xa or [10,0xa] (signed 32bits)';
        case 'DevVarLongStringArray':
            return '[[1,2][A,"B C",D]]\n(quotes needed for string with space or special char)';
        case 'DevVarShortArray':
            return '2,0xa,4 or [2,0xa,4] (signed 16bits)';
        case 'DevVarStringArray':
            return 'Dance,the tango or [Dance,"the tango"]';
        case 'DevVarULongArray':
            return '2,0xa,4 or [2,0xa,4] (unsigned 32bits)';
        case 'DevVarULong64Array':
            return '2,0xa,4 or [2,0xa,4] (unsigned 64bits)';
        case 'DevVarUShortArray':
            return '2,0xa,4 or [2,0xa,4] (unsigned 16bits)';
        default:
            return '';
    }
}

export function validateCommandInput(type, value) {
    let result = undefined;
    switch (type) {
        case 'DevBoolean':
            return validateAndConvertToDevBoolean(value);
        case 'DevDouble':
        case 'DevFloat':
            return validateAndConvertToFloat(value);
        case 'DevLong':
        case 'DevLong64':
            return validateAndConvertToInt(value);
        case 'DevShort':
            return validateAndConvertToDevShort(value);
        case 'DevString':
            return validateAndConvertToDevString(value);
        case 'DevULong':
            return validateAndConvertToDevULong(value);
        case 'DevULong64':
            return validateAndConvertToDevULong64(value);
        case 'DevUShort':
            return validateAndConvertToDevUShort(value);
        case 'DevVarCharArray':
            return validateAndConvertToDevVarCharArray(value);
        case 'DevVarDoubleArray':
        case 'DevVarFloatArray':
            return validateAndConvertDevVarFloatArray(value);
        case 'DevVarDoubleStringArray':
            return validateAndConvertDevVarDoubleStringArray(value);
        case 'DevVarLongArray':
        case 'DevVarLong64Array':
            return validateAndConvertDevVarIntArray(value);
        case 'DevVarLongStringArray':
            return validateAndConvertDevVarLongStringArray(value);
        case 'DevVarShortArray':
            return validateAndConvertToDevVarCharArray(value);
        case 'DevVarStringArray':
            return validateAndConvertToDevStringArray(value);
        case 'DevVarUShortArray':
        case 'DevVarULongArray':
        case 'DevVarULong64Array':
            return validateAndConvertDevULongArray(value);
        default:
            break;
    }
    return result;
}

function validateAndConvertToDevBoolean(input) {
    if (typeof input === 'boolean') {
        return input;
    } else if (typeof input === 'number') {
        if (input === 0 || input === 1) {
            return Boolean(input);
        }
    } else if (typeof input === 'string') {
        const lowercasedInput = input.toLowerCase();
        if (lowercasedInput === 'true') {
            return true;
        } else if (lowercasedInput === 'false') {
            return false;
        } else if (lowercasedInput === '1') {
            return true;
        } else if (lowercasedInput === '0') {
            return false;
        }
    }

    return undefined;
}

function validateAndConvertToFloat(input) {
    if (typeof input === 'number' && !isNaN(input)) {
        return input;
    } else if (typeof input === 'string') {
        const parsedFloat = parseFloat(input.replace(/"/g, ''));
        if (!isNaN(parsedFloat) && Number.isFinite(parsedFloat)) {
            return parsedFloat;
        }
    }

    return undefined;
}

function validateAndConvertToInt(input) {
    if (typeof input === 'number' && Number.isInteger(input)) {
        return input;
    } else if (typeof input === 'string') {
        if (/^-?\d+$/.test(input)) {
            return parseInt(input, 10);
        } else if (/^0x[0-9a-fA-F]+$/.test(input)) {
            return parseInt(input, 16);
        }
    }

    return undefined;
}

function validateAndConvertToDevShort(input) {
    if (typeof input === 'number' && Number.isInteger(input) && input >= -32768 && input <= 32767) {
        return input;
    } else if (typeof input === 'string') {
        if (/^-?\d+$/.test(input)) {
            const parsedInt = parseInt(input, 10);
            if (parsedInt >= -32768 && parsedInt <= 32767) {
                return parsedInt;
            }
        } else if (/^0x[0-9a-fA-F]+$/.test(input)) {
            const parsedInt = parseInt(input, 16);
            if (parsedInt >= -32768 && parsedInt <= 32767) {
                return parsedInt;
            }
        }
    }

    return undefined;
}

function validateAndConvertToDevString(input) {
    if (input && typeof input === 'string') {
        if (input.startsWith('"') && input.endsWith('"')) {
            return input.slice(1, -1); // Remove the enclosing double quotes
        }
        return input;
    }

    return undefined;
}

function validateAndConvertToDevULong(input) {
    if (typeof input === 'number' && Number.isInteger(input) && input >= 0 && input <= 4294967295) {
        return input;
    } else if (typeof input === 'string') {
        if (/^\d+$/.test(input)) {
            const parsedInt = parseInt(input, 10);
            if (parsedInt >= 0 && parsedInt <= 4294967295) {
                return parsedInt;
            }
        } else if (/^0x[0-9a-fA-F]+$/.test(input)) {
            const parsedInt = parseInt(input, 16);
            if (parsedInt >= 0 && parsedInt <= 4294967295) {
                return parsedInt;
            }
        }
    }

    return undefined;
}

function validateAndConvertToDevULong64(input) {
    if (typeof input === 'number' && Number.isInteger(input) && input >= 0 && input <= Number.MAX_SAFE_INTEGER) {
        return input;
    } else if (typeof input === 'string') {
        if (/^\d+$/.test(input)) {
            const parsedInt = parseInt(input, 10);
            if (parsedInt >= 0 && parsedInt <= Number.MAX_SAFE_INTEGER) {
                return parsedInt;
            }
        } else if (/^0x[0-9a-fA-F]+$/.test(input)) {
            const parsedInt = parseInt(input, 16);
            if (parsedInt >= 0 && parsedInt <= Number.MAX_SAFE_INTEGER) {
                return parsedInt;
            }
        }
    }

    return undefined;
}

function validateAndConvertToDevUShort(input) {
    if (typeof input === 'number' && Number.isInteger(input) && input >= 0 && input <= 65535) {
        return input;
    } else if (typeof input === 'string') {
        if (/^\d+$/.test(input)) {
            const parsedInt = parseInt(input, 10);
            if (parsedInt >= 0 && parsedInt <= 65535) {
                return parsedInt;
            }
        } else if (/^0x[0-9a-fA-F]+$/.test(input)) {
            const parsedInt = parseInt(input, 16);
            if (parsedInt >= 0 && parsedInt <= 65535) {
                return parsedInt;
            }
        }
    }

    return undefined;
}

function validateAndConvertToDevVarCharArray(input) {
    if (typeof input === 'string') {
        //eslint-disable-next-line
        const cleanedInput = input.replace(/[\[\]\s]/g, '');
        const elements = cleanedInput.split(',').map((item) => item.trim());
        const convertedArray = [];
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (/^\d+$/.test(element)) {
                const parsedInt = parseInt(element, 10);
                if (parsedInt >= 0 && parsedInt <= 255) {
                    convertedArray.push(parsedInt);
                }
            } else if (/^0x[0-9a-fA-F]+$/.test(element)) {
                const parsedInt = parseInt(element, 16);
                if (parsedInt >= 0 && parsedInt <= 255) {
                    convertedArray.push(parsedInt);
                }
            } else if (element.length === 1) {
                const charCode = element.charCodeAt(0);
                if (charCode >= 0 && charCode <= 255) {
                    convertedArray.push(charCode);
                }
            }
        }
        return convertedArray;
    }

    return undefined;
}

function validateAndConvertDevVarDoubleStringArray(input) {
    try {
        const parts = input.slice(1, -1).split('][');
        if (parts.length === 2) {
            const builtArray = [
                parts[0].split(',').map((floatNumber) => parseFloat(floatNumber)),
                parts[1].split(',').map((element) =>
                    typeof element === 'string' && element.startsWith('"') && element.endsWith('"')
                        ? element.slice(1, -1)
                        : element
                ),
            ];
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}

function validateAndConvertDevVarFloatArray(input) {
    if (!input) return undefined;

    try {
        if (input && input[0] !== '[') {
            const builtArray = [];
            const split = input.split(',');
            split.forEach(floatNumber => {
                builtArray.push(parseFloat(floatNumber));
            });
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
        else {
            const inputArray = JSON.parse(input);
            if (Array.isArray(inputArray)) {
                return inputArray;
            }
        }

    } catch (error) {
        console.error(error);
    }
    return undefined;
}

function validateAndConvertDevVarIntArray(input) {
    try {
        if (input[0] !== '[') {
            const builtArray = [];
            const split = input.split(',');
            split.forEach(intNumber => {
                const number = parseInt(intNumber);
                if (!isNaN(number)) builtArray.push(number);
            });
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
        else {
            const builtArray = [];
            const inputArray = JSON.parse(input);
            if (Array.isArray(inputArray)) {
                inputArray.forEach(intNumber => {
                    const number = parseInt(intNumber);
                    builtArray.push(number);
                });
            }
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}

function validateAndConvertDevVarLongStringArray(input) {
    try {
        const parts = input.slice(1, -1).split('][');
        if (parts.length === 2) {
            const builtArray = [
                parts[0].split(',').map((intNumber) => parseInt(intNumber)),
                parts[1].split(',').map((element) =>
                    typeof element === 'string' && element.startsWith('"') && element.endsWith('"')
                        ? element.slice(1, -1)
                        : element
                ),
            ];

            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}

function validateAndConvertToDevStringArray(input) {
    if (typeof input === 'string') {
        if (input.startsWith('"') && input.endsWith('"') && input.indexOf(',') !== -1) {
            return input.replace(/"/g, '').split(',');
        }
        else if (input.startsWith('[') && input.endsWith(']')) {
            if (input === "[]")
                return [];
            const builtArray = input.replace(/"/g, '').slice(1, -1).split(',');
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
        return input.replace(/"/g, '');
    }

    return undefined;
}

function validateAndConvertDevULongArray(input) {
    try {
        if (input[0] !== '[') {
            const builtArray = [];
            const split = input.split(',');
            for (let i = 0; i < split.length; i++) {
                const parsedNumber = parseInt(split[i]);
                if (parsedNumber >= 0) {
                    builtArray.push(parsedNumber);
                } else {
                    return undefined;
                }
            }
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        } else {
            const builtArray = [];
            const inputArray = JSON.parse(input);
            if (Array.isArray(inputArray)) {
                for (let i = 0; i < inputArray.length; i++) {
                    const parsedNumber = parseInt(inputArray[i]);
                    if (parsedNumber >= 0) {
                        builtArray.push(parsedNumber);
                    } else {
                        return undefined;
                    }
                }
            }
            if (builtArray.length > 0) return builtArray;
            else return undefined;
        }
    } catch (error) {
        console.error(error);
    }
    return undefined;
}
