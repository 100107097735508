import { FETCH_DATABASE_INFO_SUCCESS, LOAD_TANGO_DB_NAME_SUCCESS } from "../actions/actionTypes";
import JiveAction from "../actions";

export interface IDatabaseState {
  info: string | null;
  tangoDBName: string;
}

export default function database(
  state: IDatabaseState = { info: null, tangoDBName: "" },
  action: JiveAction
): IDatabaseState {
  switch (action.type) {
    case FETCH_DATABASE_INFO_SUCCESS:
      return { ...state, info: action.info };
    case LOAD_TANGO_DB_NAME_SUCCESS:
      return { ...state, tangoDBName: action.tangoDBName };
    default:
      return state;
  }
}
