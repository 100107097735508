export function getAttributeLastValueFromState(messages, device, attribute) {
  if (messages) {
    if (messages[device] && messages[device].attributes) {
      if (messages[device].attributes[attribute]) {
        let value = messages[device].attributes[attribute];
        value = value?.values[value?.values?.length - 1];
        return value;
      }
    }
  }
  else return null;
}

export function getAttributeLastQualityFromState(messages, device, attribute) {
  if (messages) {
    if (messages[device] && messages[device].attributes)
      if (messages[device].attributes[attribute]) {
        let quality = messages[device].attributes[attribute];
        quality = quality?.quality[quality?.quality?.length - 1];
        return quality;
      }
  }
  else return null;
}

export function getAttributeLastTimeStampFromState(messages, device, attribute) {
  if (messages) {
    if (messages[device] && messages[device].attributes)
      if (messages[device].attributes[attribute]) {
        let timestamp = messages[device].attributes[attribute];
        timestamp = timestamp?.timestamp[timestamp?.timestamp?.length - 1];
        return timestamp;
      }
  }
  else return null;
}

export function getAttributeLastWriteValueFromState(messages, device, attribute) {
  if (messages) {
    if (messages[device] && messages[device].attributes)
      if (messages[device].attributes[attribute]) {
        let obj = messages[device].attributes[attribute];
        obj = obj?.writeValue[obj?.writeValue?.length - 1];
        return obj;
      }
  }
  else return null;
}