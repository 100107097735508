import React, { Component } from "react";
import alphanumSort from "alphanum-sort";
import { connect } from "react-redux";
import { getDeviceNames } from "../../shared/state/selectors/deviceList";
import { fetchDeviceNames } from "../../shared/state/actions/tango";
import Spinner from "../../shared/components/Spinner/Spinner";
import { getDeviceFromPath, getTangoDbFromPath } from "../runtime/utils";
import { getTangoDB } from "../dashboardRepo";
import { Widget } from "../types";

interface Props {
  tangoDB: string;
  variables: any;
  devices: string[];
  children: React.ReactNode;
  loadDeviceNames: (tangoDB: string) => void;
  selectedWidgets: Widget[];
}


interface State {
  fetching: boolean;
  error: boolean;
  devices: string[];
  onlyDevices: string[];
  tangoDB: string;
  onTangoDBChange: (tangoDB: string) => void;
}

const initialState: Readonly<State> = {
  fetching: true,
  error: false,
  devices: [],
  onlyDevices: [],
  tangoDB: "",
  onTangoDBChange: () => {},
};

interface ProviderState extends Omit<State, 'devices'> {
  devices: string[];
}

const initialStateProvider: Readonly<ProviderState> = {
  fetching: true,
  error: false,
  devices: [],
  onlyDevices: [],
  tangoDB: getTangoDB(),
  onTangoDBChange: () => {},
};

const deviceContext = React.createContext<State>({ ...initialState });

export class DeviceProvider extends Component<Props, ProviderState> {
  public constructor(props: Props) {
    super(props);
    this.state = { ...initialStateProvider, tangoDB: props.tangoDB };
    this.props.loadDeviceNames(props.tangoDB);
    this.onTangoDBChange = this.onTangoDBChange.bind(this);
  }

  public componentDidUpdate(prevProps) {
    if (prevProps?.devices !== this.props?.devices || prevProps?.variables !== this.props?.variables) {
      const error = this.props.devices.length === 0;
      const devices = [...this.props.variables, ...alphanumSort(this.props.devices)];
      let currentTangoDb = this.state.tangoDB;

      if (prevProps.selectedWidgets[0]?.id !== this.props.selectedWidgets[0]?.id) {
        currentTangoDb = this.props.tangoDB;
      }
      this.setState({ devices, error, onlyDevices: devices, fetching: false, tangoDB: currentTangoDb });
    }
  }

  public onTangoDBChange = (tangoDBCurrent: string) => {
    if (tangoDBCurrent && !this.state.devices[tangoDBCurrent]) {
      this.setState({ tangoDB: tangoDBCurrent, fetching: true });
      this.props.loadDeviceNames(tangoDBCurrent);
    } else {
      this.setState({ tangoDB: tangoDBCurrent });
    }
  }

  public render() {
    return (
      <>
      {
        this.state.fetching ? (
          <div style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                background: 'white',
                zIndex: 999999
              }}>
                <Spinner size={4} />
                <span className="visually-hidden">Loading devices...</span>
              </div>
        ) : null}
        <deviceContext.Provider value={{
          ...this.state,
          // Allow Dashboard variables
          devices: this.state.devices.filter((dev) => -1 === dev.indexOf("/") || getTangoDbFromPath(dev) === this.state.tangoDB).map((dev) => getDeviceFromPath(dev)),
          onTangoDBChange: this.onTangoDBChange,
        }}>
          {this.props.children}
        </deviceContext.Provider>
      </>
    )
  }
}
  

function mapStateToProps(state) {
  return {
    devices: getDeviceNames(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDeviceNames: (tangoDB: string) => dispatch(fetchDeviceNames(tangoDB))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceProvider);


export const DeviceConsumer = deviceContext.Consumer;