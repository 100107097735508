import React, { Component } from "react";
import { connect } from "react-redux";
import { getAttributeList } from "../../../shared/state/selectors/attributeList";
import { IRootState } from "../../../shared/state/reducers/rootReducer";

import Autosuggest from "react-autosuggest";
import alphanumSort from "alphanum-sort";
import "./DeviceSuggester.css";


interface Attribute {
  name: string;
  label: string;
  dataformat: string;
  datatype: string;
}

interface State {
  value: string;
  attribute: string;
  suggestions: string[];
  labels: string[];
}

interface Props {
  attributeName: string | undefined;
  attributeLabel: string | undefined;
  hasDevice: boolean;
  onSelection: (newValue: string) => void;
  filter: (attributeList: Attribute[], dataType: string, dataFormat: string) => Attribute[];
  nonEditable: boolean; 
  attributesList?: Attribute[];
  dataType?: string; 
  dataFormat?:string;
}

export class AttributeSuggester extends Component<Props, State> {
  constructor(props) {
    super(props);
    let labels: string[] = []
    let names: string[] = []
    if(this.props.attributesList) {
      labels = this.props.attributesList.map(attribute => attribute.label)
      names = this.props.attributesList.map(attribute => attribute.name)
    }
    const {  attributeName, attributeLabel } = this.props;
    this.state = {
      value: attributeLabel || attributeName || "",
      attribute: attributeName || "",
      labels: labels || [],
      suggestions: names || []
    };

    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChange = this.onChange.bind(this);
    this.storeInputReference = this.storeInputReference.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    const { attributeName, attributeLabel } = this.props;
    if (attributeName !== prevProps.attributeName || attributeLabel !== prevProps.attributeLabel) {
      this.setState({ value: attributeLabel || attributeName || "" });
    }
  }

  public renderSuggestion = (suggestion: string) => {
    let labels: string[] = []
    let datatypes: string[] = []
    if(this.props.attributesList){
      labels = this.props.attributesList.map(attribute => attribute.label)
      datatypes = this.props.attributesList.map(attribute => attribute.datatype)
    }
    var index = labels.findIndex((element => element === suggestion));
    return <div style={{fontSize: "14px"}}>
        {suggestion}
          <div style={{ float: "right", margin: "0em 0.5em 0.5em 1em", fontSize: "10px", color:"gray"}}>
            {datatypes[index]}
          </div>
        </div>;
  };

  public storeInputReference(autosuggest: Autosuggest): void {
    if (autosuggest !== null) {
      autosuggest.input.spellcheck = false;
      autosuggest.input.onfocus = () => {
        autosuggest.input.select();
      };
    }
  }

  public render(): Autosuggest {
    let { value, suggestions } = this.state;
    const placeHolder = this.props.hasDevice ? "Type in an attribute (or *)" : "Pick a device first";
    const inputProps = {
      placeholder: placeHolder,
      value,
      onChange: this.onChange,
      disabled: this.props.nonEditable
    };

    const theme = {
      ...Autosuggest.defaultProps.theme,
      input: "form-control react-autosuggest__input"
    };
    if(!this.props.hasDevice){
      return null;
    }
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        highlightFirstSuggestion={true}
        ref={this.storeInputReference}
        inputProps={inputProps}
        theme={theme}
      /> 
    )
  }

  public getSuggestions(value: string): string[] {
    let labels: string[] = []
    const { dataType, dataFormat} = this.props; 
    if(this.props.attributesList)
      labels = this.props.filter(this.props.attributesList, dataType ? dataType : "", dataFormat ? dataFormat : "").map(attribute => attribute.label)
    if (value.trim() === "") {
      return [];
    }
    if (value.trim() === "*") {
      return labels.slice();
    }

    return labels.filter(label => label.toLowerCase().startsWith(value.trim().toLowerCase()));
  }

  // The suggester is unusably slow and resource-demanding if the list is not truncated. This is just a quickfix; there's probably a more sophisticated way such as using react-window
  public getTruncatedSuggestions(value: string): string[] {
    return this.getSuggestions(value).slice(0, 100);
  }

  public onSuggestionSelected(event, { suggestion, suggestionValue }): void {
    this.props.onSelection(suggestionValue);
  }

  public onChange = (event, { newValue, method }): void => {
    this.setState({
      value: newValue, 
      attribute: newValue
    });
  };

  public onSuggestionsFetchRequested = ({ value }): void => {
    this.setState({
      suggestions: alphanumSort(this.getTruncatedSuggestions(value))
    });
  };

  public onSuggestionsClearRequested = (): void => {
    this.setState({
      suggestions: []
    });
  };
}

const getSuggestionValue = (suggestion: string) => suggestion;


function mapStateToProps(state: IRootState) {
  return {
    attributesList: getAttributeList(state)
  };
}

export default connect(
  mapStateToProps,
  null
)(AttributeSuggester);
