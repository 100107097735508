export function unique<T>(arr: T[]) {
  return arr.filter((x, i) => arr.indexOf(x) === i);
}

export function objectValues<T>(obj: { [key: string]: T }) {
  return obj ? Object.keys(obj).map((key) => obj[key]) : [];
}

export function decimalToSexagesimal(
  decimalValue: number,
  precision: number = 3
): string {
  if (isNaN(decimalValue)) {
    return "Invalid input";
  }

  const degrees = Math.floor(decimalValue);
  const remainingMinutes = (decimalValue - degrees) * 60;
  const minutes = Math.floor(remainingMinutes);
  const remainingSeconds = (remainingMinutes - minutes) * 60;
  const seconds = Math.floor(remainingSeconds);
  const milliseconds = (remainingSeconds - seconds) * 1000;

  const result = `${degrees}:${minutes}:${seconds}.${milliseconds.toFixed(
    precision
  )}`;

  return result;
}

export function validateEnvironment(
  environment: string
): { result: string; errorMessage: string } {
  let result = "ERROR";
  let errorMessage = "Generic validation error";

  const httpRegex = /^https?:\/\//;
  const allowedCharacters = /^[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/;
  if (!allowedCharacters.test(environment)) {
    // it starts, checking if the environmet has any not permitted special chars
    result = "ERROR";
    errorMessage =
      "The environment contains not allowed char. Only " +
      allowedCharacters +
      " are permitted";
    return { result, errorMessage };
  }

  if (environment.endsWith("/")) environment = environment.slice(0, -1); //remove last /, if it exists

  let environmentElement = environment
    .replace(httpRegex, "")
    .replace(/\/devices$/, "")
    .replace(/\/dashboard$/, "")
    .split("/")
    .filter((element) => element !== ""); //removed http or https, device and dashboard link

  if (!(environmentElement.length === 2 || environmentElement.length === 3)) {
    result = "ERROR";
    errorMessage =
      "Enviroment link format must be like <DOMAIN>/<NAMESPACE>/<DB>/<DEVICE or DASHBOARD>. <DB>, <DEVICE> or <DASHBOARD> are not mandatory";
    return { result, errorMessage };
  }

  result = "OK";
  errorMessage = "";
  return { result, errorMessage };
}