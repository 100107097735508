import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./LogValues.css";
import { getAttributeLastTimeStampFromState, getAttributeLastValueFromState } from "../../../../shared/utils/getLastValueHelper";
import { IRootState } from "../../../../shared/state/reducers/rootReducer";
import { parseCss } from "../../../components/Inspector/StyleSelector";
import { showHideTangoDBName } from "../../../DBHelper";

interface Props {
  attributeName: string;
  deviceName: string;
  showAttribute: string;
  showDevice: boolean;
  showTangoDB: boolean;
  label: string;
  linesDisplayed: number;
  showLastValue: boolean;
  showTime: boolean;
  mode: string;
  OuterDivCSS: string;
  LastValueCSS: string;
  TableCSS: string;
}

const LogValues: React.FC<Props> = ({
  attributeName,
  deviceName,
  showAttribute,
  showDevice,
  showTangoDB,
  label,
  linesDisplayed,
  showLastValue,
  showTime,
  mode,
  OuterDivCSS,
  LastValueCSS,
  TableCSS
}) => {

  // Define the state variable
  const [valueLog, setValueLog] = useState<{ value: any, timestamp: string, time: string }[]>([]);

  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, deviceName, attributeName);
  });

  const timestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(state.messages, deviceName, attributeName)?.toString();
  });

  useEffect(() => {
    if (value !== undefined && timestamp !== undefined) {
      setValueLog((prevLog) => {
        // Add a new log entry
        // Add a new log entry at the beginning
        const newLog = [{ value, timestamp, time: formatDate(new Date(timestamp * 1000)) }, ...prevLog];

        // Only keep the last linesDisplayed entries
        return newLog.slice(0, linesDisplayed);
      });
    }
  }, [value, timestamp, linesDisplayed]); // Dependencies

  const OuterDivParsedCss = parseCss(String(OuterDivCSS)).data;
  const LastValueCSSParsedCss = parseCss(String(LastValueCSS)).data;
  const TableCSSParsedCss = parseCss(String(TableCSS)).data;

  const deviceLabel = showHideTangoDBName(showDevice, showTangoDB, deviceName);

  return (
    <div className="Logs" style={OuterDivParsedCss}>
      {showLastValue &&
        <>
          <div>
            <div className={"title"} style={LastValueCSSParsedCss}>
              Recent {
                showAttribute === 'Name' ? attributeName :
                  showAttribute === 'Label' ? label : ''
              } {showDevice ? "on " + deviceLabel : ""}
              <div>{mode === 'run' ? valueLog?.[0]?.value : '12345'} </div>
            </div>
          </div>
          <hr />
        </>
      }
      <div className="log-table">
        {
          <table style={TableCSSParsedCss}>
            <tbody>
              <tr>
                <th>Log Message</th>
                {showTime && <th>TimeStamp</th>}
              </tr>
              {mode === 'run'
                ? valueLog.map((value, key) => (
                  <Fragment key={key}>
                    <tr style={{ backgroundColor: key % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>
                      <td>{value?.value}</td>
                      {showTime && <td>{value?.time}</td>}
                    </tr>
                  </Fragment>
                ))
                : <Fragment key={123}>
                  <tr>
                    <td>{12345}</td>
                    {showTime && <td>{formatDate(new Date(123 * 1000))}</td>}
                  </tr>
                </Fragment>}
            </tbody>
          </table>
        }
      </div>
    </div>
  );

}

function formatDate(d) {
  const pad = (n) => (n < 10 ? '0' + n : n);
  return d.getFullYear() + '-'
    + pad(d.getMonth() + 1) + '-'
    + pad(d.getDate()) + ' '
    + pad(d.getHours()) + ':'
    + pad(d.getMinutes()) + ':'
    + pad(d.getSeconds()) + '.'
    + (d.getMilliseconds() / 1000).toFixed(3).slice(2, 5);
}

export default LogValues;

