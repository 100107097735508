export interface FetchAttributes {
  device: {
    attributes: Array<{ name: string; label: string; dataformat: string; datatype: string }>;
  };
}


export interface FetchAllDeviceAttributes {
  devices: Array<{ name: string }>;
}

export const FETCH_ATTRIBUTES = `
  query FetchAttributeNames($device: String!) {
    device(name: $device) {
      attributes {
        name
        label
        dataformat
        datatype
      }
    }
  }
  `;

export interface FetchCommands {
  device: {
    commands: Array<{ name: string; intype: string }>;
  };
}

export const FETCH_COMMANDS = `
  query FetchCommandNames($device: String!) {
    device(name: $device) {
      commands {
        name,
        tag,
        displevel,
        intype,
        intypedesc,
        outtype,
        outtypedesc,
        device
      }
    }
  }
  `;

export interface FetchDeviceNames {
  devices: Array<{ name: string }>;
}

export const FETCH_DEVICE_NAMES = `
  query {
    devices {
      name
    }
  }
  `;

export const FETCH_DEVICE_STATE = `
  query FetchDevice($name: String!) {
    device(name: $name) {
      state
    }
  }`;

export const EXECUTE_COMMAND = `
  mutation ExecuteCommand($device: String!, $command: String!, $argin: ScalarTypes) {
    executeCommand(device: $device, command: $command, argin: $argin) {
      ok
      message
      output
    }
  }`;

export const SET_DEVICE_ATTRIBUTE = `
  mutation WriteAttribute($device: String!, $attribute: String!, $value: ScalarTypes!) {
    setAttributeValue(device: $device, name: $attribute, value: $value) {
      ok
      message
      valueBefore
      attribute {
        device
        name
        value
        writevalue
        timestamp
        quality
      }
    }
  }`;

export const SET_DEVICE_PROPERTY = `
  mutation PutDeviceProperty($device: String!, $name: String!, $value: [String]) {
    putDeviceProperty(device: $device, name: $name, value: $value) {
      ok
      message
    }
  }
  `;

export const DELETE_DEVICE_PROPERTY = `
  mutation DeleteDeviceProperty($device: String!, $name: String!) {
    deleteDeviceProperty(device: $device, name: $name) {
      ok
      message
    }
  }
  `;

export const FETCH_ATTRIBUTE_METADATA = `
  query FetchAttributeMetadata($fullNames: [String]!) {
    attributes(fullNames: $fullNames) {
      name
      label
      device
      dataformat
      datatype
      enumLabels
      unit
      minalarm
      maxalarm
      minvalue
      maxvalue
    }
  }`;

export const FETCH_ATTRIBUTES_VALUES = `
  query FetchAttributeValues($fullNames: [String]!) {
    attributes(fullNames: $fullNames) {
      name
      label
      device
      value
      writevalue
      quality
      timestamp
    }
  }`;

export const FETCH_DEVICE_METADATA = `
  query FetchDeviceMetadata($deviceName: String!) {
    device(name: $deviceName) {
      alias
    }
  }`;

export const FETCH_DEVICES_METADATA = `
  query FetchDeviceMetadata($pattern: String!) {
    devices(pattern: $pattern) {
      alias,
      name
    }
  }`;

export const FETCH_DEVICE = `
  query FetchDevice($name: String!) {
    device(name: $name) {
      name
      connected
      state
      server {
        id
        host
      }
      attributes {
        name
        label
        dataformat
        datatype
        writable
        description
        displevel
        minvalue
        maxvalue
      }
      properties {
        name
        value
      }
      commands {
        name
        tag
        displevel
        intype
        intypedesc
        outtype
        outtypedesc
      }
    }
  }
  `;
export const FETCH_DATABASE_INFO = `
  query {
    info
  }`;

export const ATTRIBUTES_SUB_WITH_VALUES = `
  subscription Attributes($fullNames: [String]!) {
    attributes(fullNames: $fullNames) {
      device
      attribute
      value
      writeValue
      quality
    }
  }`;

export const ATTRIBUTES_SUB_WITH_VALUES_AND_TIMESTAMP = `
  subscription Attributes($fullNames: [String]!) {
      attributes(fullNames: $fullNames) {
      device
      attribute
      value
      writeValue
      timestamp
      quality
      }
  }`;

export const ATTRIBUTES_SUB_WITHOUT_VALUES = `
  subscription Attributes($fullNames: [String]!) {
    attributes(fullNames: $fullNames) {
      device
      attribute
      quality
    }
  }`;

export const IMAGE_ATTRIBUTE_SUB_VALUES = `
  subscription imageAttributes($fullNames: [String]!) {
    imageAttributes(fullNames: $fullNames) {
      device
      attribute
      value
      dataformat
      writeValue
      timestamp
    }
  }`;

export const FETCH_ALL_CLASSES_WITH_DEVICES = `
  query {
    classes(pattern: "*"){
      name
      devices {
        name,
        exported,
        connected
      }
    }
  }`;

export const FETCH_CLASS_WITH_DEVICES = `
query FetchClassWithDevice($name: String!) {
  classes(pattern: $name){
    name
    devices {
      name,
      exported,
      connected
    }
  }
}`;

export const FETCH_CLASS = `
query FetchClass($name: String!) {
  classes(pattern: $name){
    name
  }
}`;

export const FETCH_ALL_CLASSES = `
query {
  classes(pattern: "*"){
    name
  }
}`;
