import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { getAttributeLastValueFromState } from "../../../shared/utils/getLastValueHelper";
import PlotlyCore from "plotly.js/lib/core";
import PlotlyHeatmap from "plotly.js/lib/heatmap";
import createPlotlyComponent from "react-plotly.js/factory";

import { AttributeInput } from "../../types";
PlotlyCore.register([PlotlyHeatmap]);
const Plotly = createPlotlyComponent(PlotlyCore);
interface Props {
  setState: any;
  mode: string;
  attribute: AttributeInput;
  xAxis: AttributeInput;
  yAxis: AttributeInput;
  fixedScale: boolean;
  minValue: number;
  maxValue: number;
  defaultMinValue: number;
  defaultMaxValue: number;
  selectAxisAttribute: boolean;
  layout: object;
  config: object;
  responsive: boolean;
  style: CSSProperties;
  onAfterPlot?: () => void;
}

const sampleData = [
  [30, 60, 1],
  [20, 1, 60],
  [1, 20, 30],
];

const AttributeHeatMapValues: React.FC<Props> = ({
  setState,
  mode,
  attribute,
  xAxis,
  yAxis,
  fixedScale,
  minValue,
  maxValue,
  defaultMinValue,
  defaultMaxValue,
  selectAxisAttribute,
  layout,
  config,
  responsive,
  style,
  onAfterPlot,
}) => {
  let xAxisValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      xAxis?.device,
      xAxis?.attribute
    );
  });

  let yAxisValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      yAxis?.device,
      yAxis?.attribute
    );
  });

  let attributeValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      attribute?.device,
      attribute?.attribute
    );
  });

  function getData() {
    if (mode === "run" && selectAxisAttribute) {
      if (xAxisValue && yAxisValue && attributeValue) {
        if (
          xAxisValue.length !== attributeValue[0].length ||
          yAxisValue !== attributeValue.length
        ) {
          setState({ heatmapInvalid: true });
        }
      } else {
        setState({ heatmapInvalid: false });
      }
    }
    const plot = {
      z: mode === "run" ? attributeValue : sampleData,
      x:
        mode === "run"
          ? selectAxisAttribute && xAxisValue
            ? xAxisValue
            : null
          : null,
      y:
        mode === "run"
          ? selectAxisAttribute && yAxisValue
            ? yAxisValue
            : null
          : null,
      type: "heatmap",
      colorscale: "Jet",
    };

    if (fixedScale) {
      if (minValue >= maxValue) {
        plot["zmin"] = defaultMinValue;
        plot["zmax"] = defaultMaxValue;
      } else {
        plot["zmin"] = minValue;
        plot["zmax"] = maxValue;
      }
    }

    return [plot];
  }

  return (
    <Plotly
      data={getData()}
      layout={layout}
      config={config}
      responsive={responsive}
      style={style}
      onAfterPlot={onAfterPlot}
    />
  );
};
export default AttributeHeatMapValues;
