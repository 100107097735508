import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Files from "react-files";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileUpload, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotLoggedIn from "../../../jive/components/DeviceViewer/NotLoggedIn/NotLoggedIn";
import { getIsLoggedIn } from "../../../shared/user/state/selectors";
import { getSynoptics, getSelectedSynoptic } from "../../state/selectors";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { deleteSynoptic } from "../../state/actionCreators";
import DeleteSynopticModal from "../modals/DeleteSynopticModal";
import ConfigSynopticModal from "../modals/ConfigSynopticModal";
import { Synoptic, SelectedSynoptic, SharedSynoptics } from "../../types";
import {
  loadSynoptic,
  saveSynoptic,
  exportSynoptic,
  importSynoptic,
} from "../../state/actionCreators";
import { getGroupSynoptics, getGroupSynopticCount } from "../../synopticRepo";
import { Variable } from "../../types";

import TangoAPI from "../../../shared/api/tangoAPI";
import WarningBadge from "../../../dashboard/components/EditCanvas/WarningBadge";
import { checkVariableConsistency } from "../../utils/SynopticVariables";
import "./SynopticLibrary.css";
library.add(faFileUpload, faFile);

interface Props {
  tangoDB: string;
  render: boolean;
  synoptics: Synoptic[];
  isLoggedIn: boolean;
  selectedSynoptic: SelectedSynoptic;
  onDeleteSynoptic: (id: string) => void;
  loadSynoptic: (id: string) => void;
  exportSynoptic: (id: string) => void;
  saveSynoptic: (id: string, name: string, variables: Variable[]) => void;
  onUploadFile: () => void;
  onFilesChange: (files) => void;
}

interface SynopticConsistency {
  synopticID: string;
  consistency: boolean;
}

interface State {
  deleteSynopticModalId: string;
  configSynopticModalId: string;
  expandedGroups: { [group: string]: boolean };
  sharedSynoptics: SharedSynoptics;
  originalSynopticVariables: Variable[];
  synopticVariables: Variable[];
  tangoClasses: [];
  isSynopticsConsistencyFetched: boolean;
  synopticsConsistency: SynopticConsistency[];
}

class SynopticLibrary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleDeleteSynoptic = this.handleDeleteSynoptic.bind(this);
    this.filterSynopticVariables = this.filterSynopticVariables.bind(this);
    this.deleteSynopticVariable = this.deleteSynopticVariable.bind(this);
    this.addSynopticVariable = this.addSynopticVariable.bind(this);
    this.updateSynopticVariable = this.updateSynopticVariable.bind(this);
    this.saveSynopticVariables = this.saveSynopticVariables.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      deleteSynopticModalId: "",
      configSynopticModalId: "",
      expandedGroups: {},
      sharedSynoptics: {
        synoptics: [],
        availableGroupSynoptics: {},
      },
      synopticVariables: [],
      originalSynopticVariables: [],
      tangoClasses: [],
      isSynopticsConsistencyFetched: false,
      synopticsConsistency: [],
    };
  }

  public componentDidMount() {
    this.loadGroupSynopticCount();
    this.fetchClasses();
  }

  public async componentDidUpdate(prevProp) {
    //if we just logged in, fetch synoptic count
    if (this.props.isLoggedIn && !prevProp.isLoggedIn) {
      this.loadGroupSynopticCount();
    }
  }

  public async loadGroupSynopticCount() {
    const meta = await getGroupSynopticCount();
    const keys = Object.keys(meta);
    const sharedSynoptics: SharedSynoptics = {
      synoptics: [],
      availableGroupSynoptics: {},
    };
    keys.forEach((key) => {
      sharedSynoptics.availableGroupSynoptics[key] = {
        count: meta[key],
        loaded: false,
      };
    });
    this.setState({ sharedSynoptics });
  }

  onSharedSynopticLoad = (sharedSynoptics: SharedSynoptics) =>
    this.setState({ sharedSynoptics });

  filterSynopticVariables(searchString: string) {
    if (undefined === searchString || searchString === "") {
      this.setState({
        synopticVariables: this.state.originalSynopticVariables,
      });
      return;
    }

    searchString = searchString.toLowerCase();

    const updatedVariables = this.state.originalSynopticVariables.filter(
      (variable) =>
        variable.name.toLowerCase().includes(searchString) ||
        variable.class.toLowerCase().includes(searchString) ||
        variable.device.toLowerCase().includes(searchString)
    );
    this.setState({ synopticVariables: updatedVariables });
  }

  addSynopticVariable(
    synopticId: string,
    variableName: string,
    deviceClass: string,
    deviceName: string
  ) {
    const newVariable = {
      name: variableName,
      class: deviceClass,
      device: deviceName,
    };

    let updatedVariable = this.state.synopticVariables;
    updatedVariable.unshift(newVariable);
    this.updateVariables(updatedVariable);
    this.props.saveSynoptic(
      synopticId,
      this.props.selectedSynoptic.name,
      updatedVariable
    );
  }

  deleteSynopticVariable(synopticId: string, variableName: string) {
    variableName = variableName.toLowerCase();

    const updatedVariables = this.state.originalSynopticVariables.filter(
      (variable) => !variable.name.toLowerCase().includes(variableName)
    );
    this.updateVariables(updatedVariables);
    this.props.saveSynoptic(
      synopticId,
      this.props.selectedSynoptic.name,
      updatedVariables
    );
  }

  /**
   * This updates the value on synoptic variable, not in redux
   */
  updateSynopticVariable(varName: string, key: string, value: string) {
    const updatedVariables = this.state.synopticVariables.map((variable) => {
      if (variable.name === varName) {
        switch (key) {
          case "Class":
            variable.class = value;
            break;
          case "Device":
            variable.device = value;
            break;
          default:
            break;
        }
      }
      return variable;
    });

    this.setState({ synopticVariables: updatedVariables });
  }

  handleCancel() {
    this.updateVariables(this.state.originalSynopticVariables);
  }

  /**
   * This saves the synoptic variables in redux & DB
   *
   */
  saveSynopticVariables(varName: string, synopticId: string) {
    let updatedVariables = this.state.originalSynopticVariables;
    for (let i = 0; i < this.state.synopticVariables.length; i++) {
      if (this.state.synopticVariables[i].name === varName) {
        updatedVariables[i] = this.state.synopticVariables[i];
        break;
      }
    }
    this.updateVariables(updatedVariables);
    this.props.saveSynoptic(
      synopticId,
      this.props.selectedSynoptic.name,
      updatedVariables
    );
  }

  updateVariables(updatedVariables: Variable[] = []) {
    this.setState({
      synopticVariables: JSON.parse(JSON.stringify(updatedVariables)),
      originalSynopticVariables: JSON.parse(JSON.stringify(updatedVariables)),
    });
  }

  /**
   * This is called on config btn click, loads the synoptic variables from redux & opens the modal with this content
   *
   * @param synopticId
   */
  handleConfigBtnClick = (synopticId: string) => {
    this.setState({ configSynopticModalId: synopticId });
    const synoptic = this.props.synoptics.find(
      (synoptic) => synoptic.id === synopticId
    );
    this.updateVariables(synoptic?.variables);
  };

  async getSynopticsConsistency(synoptics: Synoptic[]) {
    if (!this.state.isSynopticsConsistencyFetched && synoptics.length > 0) {
      const consistencies: SynopticConsistency[] = [];
      for (const synoptic of synoptics) {
        const consistency = await checkVariableConsistency(
          this.props.tangoDB,
          synoptic.variables
        );
        consistencies.push({
          synopticID: synoptic.id,
          consistency: consistency ? consistency : false,
        });
      }
      this.setState({
        synopticsConsistency: consistencies,
        isSynopticsConsistencyFetched: true,
      });
    }
  }

  public render() {
    if (!this.props.render) {
      return null;
    }
    const { synoptics, isLoggedIn } = this.props;
    this.getSynopticsConsistency(synoptics);
    const {
      synoptics: groupSynoptics,
      availableGroupSynoptics,
    } = this.state.sharedSynoptics;
    const groupsWithSharedSynoptics = Object.keys(
      availableGroupSynoptics
    ).filter((group) => availableGroupSynoptics[group].count > 0);

    if (!isLoggedIn) {
      return (
        <NotLoggedIn>
          You have to be logged in to view and manage your synoptics.
        </NotLoggedIn>
      );
    }
    return (
      <div className="synoptic-settings">
        <div className="synoptic-settings-title">Add Synoptic</div>
        <div className="synoptic-row synoptic-menu">
          <button
            title="Create a new synoptic"
            onClick={() => this.setSelectedSynoptic("")}
            className="synoptic-add-synoptic"
          >
            <FontAwesomeIcon icon="file" />
            <span className="synoptic-menu-title">New synoptic</span>
          </button>
          <Files
            onChange={this.props.onFilesChange}
            onError={this.onFilesError}
            accepts={[".svg"]}
            multiple={false}
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <button
              className="synoptic-menu-button"
              title="Import an existing synoptic from a file (*.wj)"
            >
              <FontAwesomeIcon icon="file-upload" />

              <span className="synoptic-menu-title">Import synoptic</span>
            </button>
          </Files>
        </div>
        <div className="synoptic-settings-title">My Synoptics</div>

        {synoptics.map((synoptic) => {
          const consistent =
            this.state.synopticsConsistency.length > 0 &&
            this.state.synopticsConsistency.find(
              (d) => d.synopticID === synoptic.id
            );
          return this.SynopticRow(
            synoptic,
            false,
            consistent ? consistent.consistency : true
          );
        })}
        {/* SHARED SYNOPTICS */}
        <div className="synoptic-settings-title" style={{ marginTop: "0.5em" }}>
          Shared Synoptics
        </div>

        {groupsWithSharedSynoptics.map((groupName) => {
          return (
            <Fragment key={groupName}>
              {this.groupSynopticTitle(
                groupName,
                availableGroupSynoptics[groupName].count,
                this.state.expandedGroups[groupName]
              )}
              {this.state.expandedGroups[groupName] &&
                groupSynoptics
                  .filter((synoptic) => synoptic.group === groupName)
                  .map((synoptic) => {
                    const consistent =
                      this.state.synopticsConsistency.length > 0 &&
                      this.state.synopticsConsistency.find(
                        (d) => d.synopticID === synoptic.id
                      );
                    return this.SynopticRow(
                      synoptic,
                      true,
                      consistent ? consistent.consistency : true
                    );
                  })}
            </Fragment>
          );
        })}
        {groupsWithSharedSynoptics.length === 0 && (
          <div style={{ fontStyle: "italic", padding: "0.5em" }}>
            There are no shared synoptics in any of your groups
          </div>
        )}
      </div>
    );
  }
  groupSynopticTitle = (
    groupName: string,
    count: number,
    expanded: boolean
  ) => {
    if (expanded) {
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => this.collapseGroup(groupName)}
          className="synoptic-settings-title subtitle"
        >
          {groupName} ({count})
          <FontAwesomeIcon
            style={{ marginLeft: "0.5em" }}
            icon="chevron-up"
          />{" "}
        </div>
      );
    }
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.expandGroup(groupName)}
        title="Load synoptics shared with this group"
        className="synoptic-settings-title subtitle"
      >
        {groupName} ({count})
        <FontAwesomeIcon
          style={{ marginLeft: "0.5em" }}
          icon="chevron-down"
        />{" "}
      </div>
    );
  };

  expandGroup = async (groupName: string) => {
    const { availableGroupSynoptics, synoptics } = this.state.sharedSynoptics;
    const loaded = availableGroupSynoptics[groupName].loaded;
    let groupSynoptics: Synoptic[] = [];
    if (!loaded) {
      groupSynoptics = await getGroupSynoptics(groupName);
      availableGroupSynoptics[groupName].loaded = true;
      synoptics.push(...groupSynoptics);
      this.setState({
        sharedSynoptics: {
          availableGroupSynoptics,
          synoptics: synoptics.filter(
            (value, index, self) => self.indexOf(value) === index
          ),
        },
      });
    }
    const { expandedGroups } = this.state;
    this.setState({ expandedGroups: { ...expandedGroups, [groupName]: true } });
  };
  collapseGroup = (groupName: string) => {
    const { expandedGroups } = this.state;
    this.setState({
      expandedGroups: { ...expandedGroups, [groupName]: false },
    });
  };
  setSelectedSynoptic = (id: string) => {
    if (id) {
      this.props.loadSynoptic(id);
    } else {
      //creates a new synoptic, loads it, and selects it
      this.props.saveSynoptic("", "Untitled synoptic", []);
    }
  };
  SynopticRow = (
    synoptic: Synoptic,
    shared: boolean,
    consistent: boolean = true
  ) => {
    const { id: selectedSynopticId } = this.props.selectedSynoptic;
    return (
      <Fragment key={synoptic.id}>
        <div
          className={
            "synoptic-row " +
            (synoptic.id === selectedSynopticId ? "selected" : "")
          }
        >
          {selectedSynopticId !== synoptic.id ? (
            <button
              onClick={() => this.setSelectedSynoptic(synoptic.id)}
              className="synoptic-link"
            >
              {synoptic.name || "Untitled synoptic"}
            </button>
          ) : (
            <span>{synoptic.name || "Untitled synoptic"}</span>
          )}
          <div
            style={{
              width: "6.5em",
              textAlign: "right",
              alignSelf: "flex-start",
            }}
          >
            {!shared && (
              <div id="buttons">
                <button
                  title={`Delete synoptic '${synoptic.name ||
                    "Untitled synoptic"}'`}
                  className="delete-button"
                  onClick={() =>
                    this.setState({ deleteSynopticModalId: synoptic.id })
                  }
                >
                  <FontAwesomeIcon icon="trash" />
                </button>

                <button
                  title={`Export synoptic '${synoptic.name ||
                    "Untitled synoptic"}'`}
                  className="delete-button"
                  onClick={() => this.props.exportSynoptic(synoptic.id)}
                >
                  <FontAwesomeIcon icon="arrow-alt-circle-down" />
                </button>
                <button
                  title={`Configure '${synoptic.name || "Untitled synoptic"}'`}
                  className="delete-button"
                  id="dashConfig"
                  onClick={() => this.handleConfigBtnClick(synoptic.id)}
                >
                  <WarningBadge
                    visible={!consistent}
                    warningMessage=""
                    title="configure warning"
                  />
                  <i
                    className="fa fa-cog"
                    id="configureSynoptic"
                    aria-hidden="true"
                  >
                    &nbsp;
                  </i>
                </button>
              </div>
            )}
            {shared && (
              <span
                title={"This synoptic is owned by " + synoptic.user}
                style={{
                  color: "#666",
                  fontSize: "0.8em",
                  fontStyle: "italic",
                }}
              >
                <FontAwesomeIcon icon="user" /> {synoptic.user}
              </span>
            )}
          </div>
        </div>

        {this.state.deleteSynopticModalId === synoptic.id && (
          <DeleteSynopticModal
            id={synoptic.id}
            name={synoptic.name}
            onClose={() => this.setState({ deleteSynopticModalId: "" })}
            onDelete={this.handleDeleteSynoptic}
          />
        )}
        {this.state.configSynopticModalId === synoptic.id && (
          <ConfigSynopticModal
            id={synoptic.id}
            tangoDB={this.props.tangoDB}
            onClose={() => this.setState({ configSynopticModalId: "" })}
            synopticVariables={this.state.synopticVariables}
            filterSynopticVariables={this.filterSynopticVariables}
            deleteSynopticVariable={this.deleteSynopticVariable}
            addSynopticVariable={this.addSynopticVariable}
            updateSynopticVariable={this.updateSynopticVariable}
            saveSynopticVariables={this.saveSynopticVariables}
            handleCancel={this.handleCancel}
          />
        )}
      </Fragment>
    );
  };

  private handleDeleteSynoptic(id: string) {
    this.props.onDeleteSynoptic(id);
    this.setState({ deleteSynopticModalId: "" });
  }

  private onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  async fetchClasses() {
    const { tangoDB } = this.props;
    const data = await TangoAPI.fetchSelectedClassesAndDevices(
      tangoDB,
      this.state.synopticVariables
    );

    this.setState({ tangoClasses: data });
  }
}
function mapStateToProps(state: IRootState) {
  return {
    synoptics: getSynoptics(state),
    isLoggedIn: getIsLoggedIn(state),
    selectedSynoptic: getSelectedSynoptic(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveSynoptic: (id: string, name: string, variables: Variable[]) =>
      dispatch(saveSynoptic(id, name, variables)),
    onDeleteSynoptic: (id: string) => dispatch(deleteSynoptic(id)),
    loadSynoptic: (id: string) => dispatch(loadSynoptic(id)),
    exportSynoptic: (id: string) => dispatch(exportSynoptic(id)),
    onUploadFile: () => {},
    onFilesChange: (files) => {
      if (files[0] !== undefined) {
        dispatch(importSynoptic(files[0]));
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SynopticLibrary);
