import React from "react";

export default function WarningBadge({ isWarning = false, visible, warningMessage, title }) {
  const radius = 10;
  return (
    <div
      className="WarningBadge"
      style={{
        position: "absolute",
        marginLeft: -radius,
        marginTop: warningMessage ? -radius * 2 : -radius,
        borderRadius: warningMessage ? "5px" : radius,
        width: warningMessage ? "auto" : 2 * radius,
        height: warningMessage ? "auto" : 2 * radius,
        backgroundColor: isWarning ? "#fff3cd" : "#f2dede",
        borderColor: "#ebccd1",
        color: isWarning ? "#394147" : "#5C2524",
        textAlign: "center",
        opacity: visible ? 1 : 0,
        zIndex: 10,
        lineHeight: warningMessage ? "initial" : "",
        padding: warningMessage ? "5px" : "",
      }}
      title={title ? title : warningMessage ? warningMessage : "Not all required fields are filled"}
    >
      <span className="fa fa-exclamation" />
      {
        warningMessage ?
          <span style={{
            marginLeft: "3px",
            backgroundColor: isWarning ? "#fff3cd" : "#f2dede",
            borderColor: "#ebccd1",
            color: isWarning ? "#394147" : "#5C2524",
          }}>
            {warningMessage}</span> : null}
    </div>
  );
}
