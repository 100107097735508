import React from 'react';
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { useSelector } from "react-redux";
import { JSONTree, parseJSONObject } from '../../../shared/utils/JSONTree';

interface Props {
    deviceName: string;
    commandName: string;
}

const CommandOutput: React.FC<Props> = ({
    deviceName,
    commandName,
}) => {

    const commandOutput = useSelector((state: IRootState) => {
        return state.commandOutput?.[deviceName]?.[commandName];
    });
    const parsedOutput = commandOutput?.[0] === '{' ? parseJSONObject(commandOutput) : null;
    return (
        <div title={commandOutput}>
            {
                parsedOutput !== null ? <JSONTree data={parsedOutput} jsonCollapsed={true}></JSONTree> :
                    commandOutput !== undefined ? "Output: " + commandOutput : ""
            }
        </div>
    );
};

export default CommandOutput;
