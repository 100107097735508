// collapseAttribute accepts two parameters: attributes and compare.
// attributes is the array containing all the attributes to plot
// compare is the comparison method to collapse the attributes. They should be collapsed based on enum, using the value enumlabels. It means that the function collapses the attributes that have the same Enum labels. Or attributename. it means that the function collapses the attributes that have the same attribute name (eg. if the user wants to collapse the same attributes of two different devices).
// The function returns an array. Each element of the array is an array that contains the attributes that match the comparison method.
// example:
// Attribute1 and Attribute2 has the same Enum Label and Attribubute3 a different Enum Label
// collapseAttribute([Attribute1, Attribute2, Attribute3], 'enumlabels')
// return [[Attribute1, Attribute2], [Attribute3]]

export default function collapseAttribute(attributes, compare){
    
    let element = []
    let i = -1
    
    if(compare === "enumlabels")
    {
        attributes.forEach(attribute => {
            element[++i] = attributes.filter(enums => JSON.stringify(enums.attribute.enumlabels) === JSON.stringify(attribute.attribute.enumlabels));
            attributes = attributes.filter(enums => JSON.stringify(enums.attribute.enumlabels) !== JSON.stringify(attribute.attribute.enumlabels))
        })

        return element.filter(attribute => attribute.length > 0)
    }
    else if(compare === "attributename")
    {
        attributes.forEach(attribute => {
            element[++i] = attributes.filter(enums => enums.attribute.attribute === attribute.attribute.attribute);
            attributes = attributes.filter(enums => enums.attribute.attribute !== attribute.attribute.attribute)
        })

        return element.filter(attribute => attribute.length > 0)
    }
    else
    {
        return attributes;
    }
}

