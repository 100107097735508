import { WIDGET_CLIPBOARD_COPY, WIDGET_CLIPBOARD_PASTE } from "../actions/actionTypes";
import { Widget } from "../../../dashboard/types";
import { DashboardAction } from "../actions/actions";

export interface ClipboardState {
  widgets: Widget[];
  pasteCounter: number;
}

const initialState: ClipboardState = {
  widgets: [],
  pasteCounter: 0,
};

export default function clipboard(
  state: ClipboardState = initialState,
  action: DashboardAction
): ClipboardState {
  switch (action.type) {
    case WIDGET_CLIPBOARD_COPY:
      const { widgets } = action;
      const newWidgets = widgets.map(widget => {
        const tmp = {...widget}
        delete tmp._id;
        tmp.id = "";
        return tmp;
      })
      return {...state, widgets: newWidgets, pasteCounter: 0}
      case WIDGET_CLIPBOARD_PASTE:
        return {...state, pasteCounter: (state.pasteCounter + 1)}

    default:
      return state;
  }
}
