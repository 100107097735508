import React from 'react';
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { useSelector } from "react-redux";
import {
    getAttributeLastValueFromState
} from '../../../shared/utils/getLastValueHelper';
import '../Tabular/Tabular.css';
import './ImageTable.css';

interface Props {
    attributeName: string;
    deviceName: string;
    mode: string;
    showIndex: boolean;
    showLabel: boolean;
    rowLimit: number;
    columnLimit: number;
    rowOffset: number;
    columnOffset: number;
    showWarning: boolean;
}

const ImageTableValues: React.FC<Props> = ({
    attributeName,
    deviceName,
    mode,
    showIndex,
    showLabel,
    rowLimit,
    columnLimit,
    rowOffset,
    columnOffset,
    showWarning,
}) => {

    let value = useSelector((state: IRootState) => {
        return getAttributeLastValueFromState(state.messages, deviceName, attributeName);
    });

    const sampleData = generateSampleData(columnLimit, rowLimit);

    if (mode !== "run") value = sampleData;

    let displayWarningMsg: boolean = false;
    let allRowsCount: number = 0;
    let allColumnsCount: number = 0;

    if (value && value?.[0]) {
        allRowsCount = value.length;
        allColumnsCount = value[0].length;
    }


    if (mode === "run" && value !== null && value?.length > 0) {
        let startColumn = columnOffset || 0;
        let startRow = rowOffset || 0;
        if (columnLimit) {
            value = value?.map((row) => row.slice(startColumn, startColumn + columnLimit));
        }

        if (rowLimit) {
            value = value.slice(startRow, startRow + rowLimit);
        }
        displayWarningMsg = showWarning && value && value?.[0] &&
            (allRowsCount > value?.length || allColumnsCount > value?.[0].length);
    }

    return (
        <div>
            {
                displayWarningMsg ? (
                    <div style={{ color: '#a94442' }}>
                        <span className="fa fa-exclamation-triangle warning-elastic" />
                        Displaying {value[0].length} columns out of {allColumnsCount} and {value.length} rows out of {allRowsCount}
                        <span className="fa fa-exclamation-triangle warning-elastic" />
                    </div>
                ) : null
            }
            <table>
                <tbody>
                    {showLabel || showIndex ? (
                        <tr>
                            {showLabel ? <th key={'index'} className='tdStyle'>Index (x/y)</th> : null}
                            {showIndex ? [
                                showLabel ? null : <th key={'showLabel'}></th>,
                                value?.[0]?.map((_: any, i: number) => [<th className='tdStyle' key={i}>{i + columnOffset}</th>])
                            ] : null}
                        </tr>
                    ) : null}
                    {value?.map((row: any[], rowIndex: number) => [
                        <tr key={rowIndex + rowOffset}>
                            {showIndex ?
                                <th className='tdStyle'>{rowIndex + rowOffset}</th>
                                : null}
                            {showLabel && !showIndex ? (<td></td>) : null}
                            {row?.map((item: number | string, colIndex: number) => ([
                                <td className='tdStyle' key={colIndex + columnOffset}>{item}</td>
                            ]))
                            }
                        </tr>]
                    )}
                </tbody>
            </table>
        </div>
    );
};

const generateSampleData = (columnLimit, rowLimit) => {
    const sampleData = Array.from({ length: rowLimit }, (_, rowIndex) =>
        Array.from({ length: columnLimit }, (_, columnIndex) => rowIndex * columnLimit + columnIndex + 1)
    );
    return sampleData;
};

export default ImageTableValues;