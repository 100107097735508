import React, { Component } from "react";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { connect } from "react-redux";
import "./ElasticsearchLogViewer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPauseCircle,
  faPlayCircle,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';
import {
  StringInputDefinition,
  WidgetDefinition,
  Widget,
  Variable,
  Dashboard,
  SelectedDashboard,
  BooleanInputDefinition,
  DatePickerDefinition,
  MultipleSelectionInputDefinition,
  SelectInputDefinition,
  StyleInputDefinition
} from "../../types";

import {
  getCurrentCanvasWidgets,
  getCurrentDashoardVariables,
  getDashboards,
  getSelectedDashboard
} from "../../../shared/state/selectors";
import { extractDevicesFromDashboard } from "../../../shared/utils/ExtractDevicesFromDashboard";
import alphanumSort from "alphanum-sort";

import { parseCss } from "../../components/Inspector/StyleSelector";

import { fetchElasticLogs } from "../../../shared/elastic/state/actionCreators";
import { getElasticError, getElasticMessages } from "../../../shared/elastic/state/selectors";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { TypedInputs } from "../types";
import { getDeviceNames } from "../../../shared/state/selectors/deviceList";

const config = window['config'];

const animatedComponents = makeAnimated();

export type Inputs = {
  overflow: BooleanInputDefinition;
  textDisplay: BooleanInputDefinition;
  searchText: StringInputDefinition;
  fromDate: DatePickerDefinition;
  toDate: DatePickerDefinition;
  deviceSelection: MultipleSelectionInputDefinition<any>;
  logLevel: MultipleSelectionInputDefinition<any>;
  refresh: SelectInputDefinition;
  customCss: StyleInputDefinition;
};

type Props = {
  id: string;
  tangoDB: string;
  inputs: TypedInputs<Inputs>;
  mode: string;
  widgets: Widget[];
  selectedDashboard: SelectedDashboard;
  dashboards: Dashboard[];
  messages: Object;
  elasticError: string;
  variables: Variable[] | undefined;
  devices: string[];
  fetchElasticLogs: (filters: Object) => void;
};

interface State {
  updating: Boolean;
  showFilters: Boolean;
  searchText: string;
  fromDate: string | Date | DatePickerDefinition;
  toDate: string | Date | DatePickerDefinition;
  selectedDevices: string[];
  availabledevices: string[];
  selectedLogLevel: any;
  selectedRefresh: number;
  page: number;
  n_page: number;
  healTime: number;
}

const logLevels = config.LOG_LEVELS.map(v => {
  return {
    label: v,
    name: v,
    value: v
  }
});
const dateFormat = config.dateFormat || "YYYY-MM-DD";
const timeFormat = config.timeFormat || "hh:mm:ss";

const refreshOptions = [
  { "label": "1sec", "name": "1 second", "value": "1000" },
  { "label": "3sec", "name": "3 seconds", "value": "3000" },
  { "label": "5sec", "name": "5 seconds", "value": "5000" },
  { "label": "10sec", "name": "10 seconds", "value": "10000" },
  { "label": "30sec", "name": "30 seconds", "value": "30000" },
  { "label": "1min", "name": "1 minute", "value": "60000" },
  { "label": "5min", "name": "5 minutes", "value": "300000" }
]

class ElasticsearchLogViewer extends Component<Props, State> {

  intervalId;
  elasticQueryFn;

  public constructor(props: Props) {
    super(props);

    this.state = {
      updating: true,
      showFilters: true,
      searchText: this.props.inputs.searchText?.toString(),
      fromDate: this.props.inputs.fromDate,
      toDate: this.props.inputs.toDate,
      availabledevices: [],
      selectedDevices: [],
      selectedLogLevel: this.props.inputs.logLevel,
      selectedRefresh: Number(this.props.inputs.refresh),
      page: 0,
      n_page: 25,
      healTime:  8000
    };
  }

  launchElasticQuery(fn, refresh) {
    return setInterval(fn, refresh)
  }

  componentDidMount() {
    this.getAllDevices();
    const devices: any[] = [];
    this.state.selectedDevices.forEach(device => {
      devices.push(device);
    });

    const searchFilters = this.getSearchFilters();
    if (this.state.updating && this.props.mode === "run") this.props.fetchElasticLogs(searchFilters);

    this.elasticQueryFn = () => {
      if (this.state.updating && this.props.mode === "run") {
        const searchFilters = this.getSearchFilters();
        this.props.fetchElasticLogs(searchFilters);
      }
    };

    this.intervalId = this.launchElasticQuery(this.elasticQueryFn, this.state.selectedRefresh);
  }

  getSearchFilters() {
    return {
      id: this.props.id,
      devices: this.state.selectedDevices.map(device => device['value']),
      searchText: this.state.searchText,
      fromDate: this.state.fromDate ? new Date(this.state.fromDate.toString()) : "",
      toDate: this.state.toDate ? new Date(this.state.toDate.toString()) : "",
      logLevels: this.state?.selectedLogLevel?.map(v => v.value.toUpperCase()).join(' OR ')
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  getPaginationHtml(messages) {
    const maxPages = Math.trunc(messages.length / this.state.n_page) || 1;
    return (
      <div className="pagination-wrapper">
        <span className="pagination-text">Page {Math.trunc(this.state.page + 1)} of {maxPages}</span>
        <button className="paginator-button" onClick={() => { this.setState({ page: 0 }) }}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </button>{" "}
        <button className="paginator-button" onClick={() => { if (this.state.page > 0) this.setState({ page: this.state.page - 1 }) }}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>{" "}
        <button className="paginator-button" onClick={() => { if (this.state.page < maxPages - 1) this.setState({ page: this.state.page + 1 }) }}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>{" "}
        <button className="paginator-button" onClick={() => {
          const page = Math.trunc(messages.length / this.state.n_page) || 1;
          this.setState({ page: page - 1 })
        }}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>{" "}
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.elasticError !== this.props.elasticError && this.props.elasticError === "") {
      clearInterval(this.intervalId);
      this.intervalId = this.launchElasticQuery(this.elasticQueryFn, this.state.selectedRefresh);
    }
  }

  public render() {
    if (this.props.elasticError) {
      // In case of error in fetching log, add delay to elastic query call
      clearInterval(this.intervalId);
      this.intervalId = this.launchElasticQuery(this.elasticQueryFn, (this.state.selectedRefresh+this.state.healTime));
    }

    const { mode, inputs } = this.props;
    const messages = (this.props.messages && this.props.messages[this.props.id]) ? Object.values(this.props.messages[this.props.id]) : [];
    const regex = new RegExp(/\|/, 'g');
    const parsedCss = parseCss(String(inputs.customCss)).data;
    const acceptedURL = config?.ELASTIC_ACCEPTED_URLS.includes(window.location.origin);
    // Check if the current URL is accepted to render ELASTICSEARCH_LOG_VIEWER
    if (acceptedURL) {
      return (
        <div className="log-wrapper" style={{ ...parsedCss }}>
          {!this.state.showFilters &&
            <div className="log-filter-collapsed">
              <div className="pull-right" style={{ width: "auto", display: "flex" }}>
                {this.getPaginationHtml(messages)}
                <div className="pause-button" style={{ "width": "50px" }}>
                  <FontAwesomeIcon style={{ "marginBottom": "4px" }} icon={this.state.updating ? faPauseCircle : faPlayCircle} onClick={this.pauseUpdates} />
                  <i
                    onClick={(e) => this.setState({ showFilters: !this.state.showFilters })}
                    title="toggle filter section"
                    id="filter-arrow"
                    className="fa fa-angle-down"
                    style={{ "fontSize": "30px", marginLeft: "10px" }}>
                  </i>
                </div>
              </div>
            </div>
          }
          {this.state.showFilters && "library" !== mode &&
            <div className="log-filter">
              <div>
                <div className="pull-left">
                  <div className="form-group filter-container">
                    <label htmlFor="exampleInputEmail1">Search Text</label>
                    <input
                      type="text"
                      name="search-text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchText}
                      onChange={(e) => this.setState({ searchText: e.target.value })}
                    />
                  </div>
                  <div className="form-group filter-container">
                    <label htmlFor="fromDate">From date</label>
                    <Datetime
                      className="from-date-selector"
                      input={true}
                      closeOnSelect={true}
                      dateFormat={dateFormat}
                      timeFormat={timeFormat}
                      value={this.state.fromDate ? new Date(this.state.fromDate?.toString()) : ""}
                      onChange={(e) => {
                        const momentObj = moment(e);
                        this.setState({ fromDate: momentObj.toDate() })
                      }}
                    />

                  </div>
                  <div className="form-group filter-container">
                    <label htmlFor="toDate">To date</label>
                    <Datetime
                      className="to-date-selector"
                      input={true}
                      closeOnSelect={true}
                      dateFormat={dateFormat}
                      timeFormat={timeFormat}
                      value={this.state.toDate ? new Date(this.state.toDate?.toString()) : ""}
                      isValidDate={(current) => {
                        return current.isBefore(tomorrow);
                      }}
                      onChange={(e) => {
                        const momentObj = moment(e);
                        this.setState({ toDate: momentObj.toDate() })
                      }}
                    />
                  </div>
                  <div className="form-group filter-container">
                    <label htmlFor="refresh">Refresh</label>
                    <select
                      name="refresh"
                      id="refresh-rate"
                      className="form-control"
                      defaultValue={this.state.selectedRefresh.toString()}
                      onChange={(e) => {
                        this.setState(
                          { selectedRefresh: Number(e.currentTarget.value) },
                          () => {
                            clearInterval(this.intervalId);
                            this.intervalId = this.launchElasticQuery(this.elasticQueryFn, this.state.selectedRefresh);
                          }
                        );
                      }}
                    >
                      {
                        refreshOptions.map(option =>
                          <option value={option.value} key={option.label}>{option.label}</option>
                        )
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="pull-right" style={{ "width": "auto", display: "flex" }}>
                {this.getPaginationHtml(messages)}
                <div className="pause-button" style={{ "width": "auto" }}>
                  <FontAwesomeIcon
                    title={this.state.updating ? "Pause fetching logs" : "Resume fetching logs"}
                    icon={this.state.updating ? faPauseCircle : faPlayCircle}
                    onClick={this.pauseUpdates}
                  />
                  <i onClick={(e) => this.setState({ showFilters: !this.state.showFilters })} title="toggle filter section"
                    className="fa fa-angle-up"
                    id="filter-arrow"
                    style={{ fontSize: "30px", marginLeft: "5px" }}></i>
                </div>
              </div>
              <div style={{ clear: "both" }}>
                <div id="select-div">
                  <Select
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                    value={this.state.selectedDevices}
                    onChange={(selected) => {
                      this.deviceSelected(selected)
                    }}
                    options={this.state.availabledevices}
                    isMulti
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    placeholder="Select devices"
                  />
                </div>
                <div className="log-level-drp">
                  <Select
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                    value={this.state.selectedLogLevel}
                    onChange={(selected) => this.changeLogLevel(selected)}
                    options={logLevels}
                    isMulti
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    placeholder="Select log level"
                  />
                </div>
              </div>
            </div>
          }
          <div className={"log-container" + (this.state.showFilters ? "" : " log-container-expanded")}>
            {this.props.elasticError &&
              <div className="alert alert-warning" role="alert">
                {this.props.elasticError}
            </div>
            }
            <table className="table" >
              <thead>
                {this.props.inputs.textDisplay ? (
                  <tr>
                    <th className="sticky-column">Timestamp</th>
                    <th className="sticky-column">Log</th>
                  </tr>
                ) :
                  <tr>
                    <th className="sticky-column">Version</th>
                    <th className="sticky-column">Timestamp</th>
                    <th className="sticky-column">Level</th>
                    <th className="sticky-column">File</th>
                    <th className="sticky-column">Message</th>
                  </tr>
                }
              </thead>

              {(mode === "edit" || mode === "library") &&
                <tbody className="table-body">
                  <tr key={1}>
                    {this.props.inputs.textDisplay ? (
                      <>
                        <td className="elasticTD">{"16:38:54,409.409Z"}</td>
                        <td className="elasticTD">{"1.0.1 | 2022-07-05 16:38:54,409.409Z | INFO | test.py#1 | This is a info message"}</td>
                      </>
                    ) : (
                      <>
                        <td className="elasticTD">{"1.0.1"}</td>
                        <td className="elasticTD">{"2022-07-05 16:38:54,409.409Z"}</td>
                        <td className="elasticTD">{"INFO"}</td>
                        <td className="elasticTD">{"test.py#1"}</td>
                        <td className="elasticTD">{"This is a info message"}</td>
                      </>
                    )}
                  </tr>
                </tbody>
              }
              {mode === "run" &&
                <tbody className="table-body">
                  {
                    0 < messages.length && messages.slice(0 + this.state.n_page * this.state.page, 25 + this.state.n_page * this.state.page).map((message: any, index) => {
                      const split = message?._source?.message?.split("|");

                      if (message && 0 < Object.keys(message).length && split) {
                        const dateTime = message?._source['@timestamp'];

                        return (<tr key={index}>
                          {(this.props.inputs.textDisplay || (split && 1 === split.length)) ? (
                            <>
                              {/* timestamp fetched in message is in utc zone */}
                              <td className="elasticTD">{moment(dateTime).utc().format('HH:mm:ss.sss')}</td>
                              <td className="elasticTD">{message?._source?.message?.replace(regex, ' | ')}</td>
                            </>
                          ) : (
                            <>
                              <td className="elasticTD">{split[0].substring(0, 10)}</td>
                              <td className="elasticTD">{moment(dateTime).format(dateFormat + ' ' + timeFormat)}</td>
                              <td className="elasticTD">{split[2]}</td>
                              <td className="elasticTD">{split[5]}</td>
                              <td className="elasticTD">{split[6]}</td>
                            </>
                          )}
                        </tr>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
                </tbody>
              }
            </table>
          </div>
        </div>
      );
    }
    else return <div className="elastic-disabled">
      <span className="fa fa-exclamation-triangle warning-elastic" />
      The current URL is not accepted to use this widget
      <span className="fa fa-exclamation-triangle warning-elastic" />
    </div>
  }

  deviceSelected = (device) => {
    this.setState({ selectedDevices: device });
  }

  changeLogLevel = (logLevel) => {
    this.setState({ selectedLogLevel: logLevel })
  }


  async getAllDevices() {
    try {
      let devices = this.props.devices;
      devices = [...alphanumSort(devices)];
      const devicestmp: any[] = [];
      devices.forEach(device => {
        devicestmp.push({ value: device, label: device });
      });

      const selectedDevices: any[] = [];

      if (this.props.inputs?.deviceSelection) {
        const propertyValues = Object.values(this.props.inputs?.deviceSelection);
        propertyValues.forEach((device: any) => {
          selectedDevices.push({ value: device.value, label: device.value });
        });
      }
      else {
        const currentDeviceNames = extractDevicesFromDashboard(this.props.variables, this.props.widgets);
        currentDeviceNames.forEach(device => {
          selectedDevices.push({ value: device, label: device });
        });
      }

      this.setState({ availabledevices: devicestmp, selectedDevices: selectedDevices });

    } catch (err) {
      console.log("Couldn't fetch devices from database", err);
    }
  }

  pauseUpdates = () => {
    this.setState({ updating: !this.state.updating });
  }
}

const tomorrow = moment().add(1, 'day');
const definition: WidgetDefinition<Inputs> = {
  type: "ELASTICSEARCH_LOG_VIEWER",
  name: "Elasticsearch log viewer",
  defaultWidth: 50,
  defaultHeight: 20,
  inputs: {
    textDisplay: {
      type: "boolean",
      default: false,
      label: "Show logs as text",
    },
    searchText: {
      type: "string",
      label: "Search Input",
      default: "",
      placeholder: "Free text search"
    },
    fromDate: {
      type: "datePicker",
      label: "From date",
      dateFormat: dateFormat,
      timeFormat: timeFormat
    },
    toDate: {
      type: "datePicker",
      label: "To date",
      dateFormat: dateFormat,
      timeFormat: timeFormat
    },
    deviceSelection: {
      type: "multipleselection",
      label: "Multiple device selection"
    },
    logLevel: {
      type: "multipleselection",
      label: "Log Level",
      placeholder: "Select log level",
      value: "",
      options: logLevels
    },
    refresh: {
      type: "select",
      label: "Refresh:",
      default: "3000",
      options: refreshOptions
    },
    overflow: {
      type: "boolean",
      default: false,
      label: "Show overflow scroll",
    },
    customCss: {
      type: "style",
      label: "Custom CSS",
      default: ""
    }
  }
};

function mapStateToProps(state: IRootState) {
  return {
    widgets: getCurrentCanvasWidgets(state),
    selectedDashboard: getSelectedDashboard(state),
    dashboards: getDashboards(state),
    messages: getElasticMessages(state),
    elasticError: getElasticError(state),
    variables: getCurrentDashoardVariables(state),
    devices: getDeviceNames(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //fetchElasticLogs fetches logs from elk into redux store > message from where message is rendered on screen
    fetchElasticLogs: (filters) => dispatch(fetchElasticLogs(filters))
  };
}

export const ConnectedElasticsearchLogViewer =
  connect(mapStateToProps, mapDispatchToProps)(ElasticsearchLogViewer);

const ElasticsearchLogViewerExport = { component: ConnectedElasticsearchLogViewer, definition };
export default ElasticsearchLogViewerExport;