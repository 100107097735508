function extractDeviceFromInputs(inputs) {
    if (inputs.xAxis) {
        return [inputs.attribute.device, inputs.xAxis.device, inputs.yAxis.device];
    } else if (inputs.attribute) {
        return inputs.attribute.device;
    } else if (inputs.attributeX) {
        let devices = [inputs.attributeX.device];
        inputs.attributes.forEach(attribute => {
            devices.push(attribute.attribute?.device);
        });
        return devices;
    } else if (inputs.attributes) {
        let devices = [];
        inputs.attributes.forEach(attribute => {
            devices.push(attribute.attribute?.device);
        });
        return devices;
    } else if (inputs.dependent || inputs.independent) {
        return [inputs.dependent.device, inputs.independent.device];
    } else if (inputs.command) {
        return inputs.command.device;
    } else if (inputs.onCommand || inputs.offCommand) {
        return [inputs.onCommand.device, inputs.offCommand.device];
    } else if (inputs.device) {
        return inputs.device;
    } else if (inputs.door || inputs.macroserver || inputs.pool) {
        return [inputs.door, inputs.macroserver, inputs.pool];
    }
}

function cleanDevices(currentDeviceNames, variables) {
    currentDeviceNames.forEach((element, id) => {
        if (variables) {
            const filtered = variables.filter(variable => variable.name === element);
            if (filtered.length > 0) currentDeviceNames[id] = filtered[0]?.device;
        }
    });
    currentDeviceNames = [...new Set(currentDeviceNames)];
    return currentDeviceNames.filter(element => (element !== undefined && element !== null))
}

function extractInnerWidgets(innerWidgets, currentDeviceNames) {
    innerWidgets.forEach(innerWidget => {
        if (innerWidget.innerWidgets !== undefined) {
            extractInnerWidgets(innerWidget.innerWidgets, currentDeviceNames);
        }
        else {
            const devicesGet = extractDeviceFromInputs(innerWidget.inputs);
            if (Array.isArray(devicesGet)) {
                devicesGet.forEach(element => { currentDeviceNames.push(element); });
            }
            else currentDeviceNames.push(devicesGet);
        }
    });
}

export function extractDevicesFromDashboard(variables, widgets) {
    let currentDeviceNames = [];
    widgets.forEach(widget => {
        if (widget.type === "BOX" && widget.innerWidgets)
            extractInnerWidgets(widget.innerWidgets, currentDeviceNames);
        else {
            const devicesGet = extractDeviceFromInputs(widget.inputs);
            if (Array.isArray(devicesGet)) {
                devicesGet.forEach(element => { currentDeviceNames.push(element); });
            }
            else currentDeviceNames.push(devicesGet);
        }
    });
    return cleanDevices(currentDeviceNames, variables);
}