import { DASHBOARD_LOADED, TOGGLE_MODE, WEBSOCKET, FETCH_DEVICE_SUCCESS, CHANGE_SUBSCRIPTION } from "../actions/actionTypes";
import { ATTRIBUTES_SUB_WITH_VALUES_AND_TIMESTAMP } from '../../../shared/api/graphqlQuery'
import { fetchInitialValues } from "../../../shared/utils/fetchInitialValues";
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, NormalizedCacheObject } from '@apollo/client/core';
import { socketUrl, retriveDeviceFromSub } from "./utils";
import { getSubs } from "../../utils/getSubs";
import { getDashboardVariables, mapVariableNameToDevice } from "../../utils/DashboardVariables";
import { getTangoDB } from "../../../dashboard/dashboardRepo";
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath } from "../../../dashboard/runtime/utils";
import { splitFullPath } from "../../../dashboard/DBHelper"
import { Subscription } from "apollo-client/util/Observable";
import { filterMissingDevices } from "../../../dashboard/utils";


// error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

function getTangoDBFilteredDevices(fullDevNames) {
  let tangoDBs = {};
  for (let name of fullDevNames) {
    const [tangoDB, device] = splitFullPath(name);
    
    if (tangoDB in tangoDBs) {
      tangoDBs[tangoDB].push(device);
    }
    else {
      tangoDBs[tangoDB] = [device];
    }
  }
  return tangoDBs;

};

// create the apollo client
const clients: ApolloClient<NormalizedCacheObject>[] = [];

let subscriptions: Subscription[] = [];

const subscribeToWebsocket = async (fullNames, store, dashboard) => {
  const variables = dashboard?.variables || getDashboardVariables(store.getState()?.selectedDashboard?.id, store.getState()?.dashboards?.dashboards);

  fullNames = mapVariableNameToDevice(
    fullNames,
    variables
  );

  fullNames = filterMissingDevices(store.getState().deviceList?.nameList, fullNames);
  const devicesByTangoDB = getTangoDBFilteredDevices(fullNames)

  for await (const [tangoDB, devices] of Object.entries(devicesByTangoDB)) {
    fetchInitialValues(store.dispatch, devices, tangoDB);
    const wsLink = new WebSocketLink(
      {
        uri: socketUrl(tangoDB),
        options: {
          reconnect: true,
        }
      }
      );

    const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
      link: ApolloLink.from([errorLink, wsLink]),
      cache: new InMemoryCache()
    });

    clients.push(client);

    const subscriptionObservable = client.subscribe({
      query: gql(ATTRIBUTES_SUB_WITH_VALUES_AND_TIMESTAMP),
      variables: {
        fullNames: devices
      }
    });
  
    // subscribe to the updates
    const subscription = subscriptionObservable.subscribe({
      next: ({ data }) => {
        store.dispatch({
          type: WEBSOCKET.WS_MESSAGE,
          value: [{ data: JSON.stringify({ type: "data", payload: { 
            data: {
              ...data,
              attributes: {
                ...data.attributes,
                device: createDeviceWithTangoDBFullPath(tangoDB, data.attributes['device'])}
              }
            }})}
          ],
      })
    }
    });

    subscriptions.push(subscription);
  }
}

const unsubscribeToWebsockets = () => {
  while (subscriptions.length) {
    const subscription = subscriptions.pop();
    if (subscription)
      subscription.unsubscribe();
  }
}

window.addEventListener("beforeunload", async () => {
  unsubscribeToWebsockets();
  while (clients.length) {
    const client = clients.pop();
    if (client)
      client.stop();
  }
});

export const websocketMiddleware = (store) => (next) => async (action) => {
  if (action.type === WEBSOCKET.WS_SUBSCRIBE) {
    unsubscribeToWebsockets();
    subscribeToWebsocket(action.payload["devices"], store, '');
  }
  else if (action.type && action.type === TOGGLE_MODE) {
    const { ui: { mode }, selectedDashboard } = store.getState();
    const widgets = selectedDashboard?.widgets;

    if (mode === 'edit' && Object.values(widgets)?.length > 0) {

      //mode is in edit it will change to run
      if (selectedDashboard.id) {
        //adds the attributes in the store
        retriveDeviceFromSub(getSubs(widgets)).forEach(res => {
          const tangoDB = getTangoDbFromPath(res.name);
          store.dispatch({
            type: FETCH_DEVICE_SUCCESS, 
            tangoDB,
            device: res
          })
        });
        subscribeToWebsocket(getSubs(widgets), store, '');
      }

    }
    else {
      //mode is in run it will change to edit
      unsubscribeToWebsockets();
    }
  }
  else if (action.type && ( action.type === DASHBOARD_LOADED || action.type === CHANGE_SUBSCRIPTION)) {
    const { ui: { mode } } = store.getState();

    let widgets = action?.widgets,
        dashboard = action?.dashboard;

    if(action.type === CHANGE_SUBSCRIPTION) {
      widgets = Object.values(store.getState()?.selectedDashboard?.widgets);
      dashboard = store.getState()?.dashboards.dashboards.find(dash => dash.id === store.getState()?.selectedDashboard?.id)
      unsubscribeToWebsockets();
    }

    if (mode === 'run' && widgets?.length > 0) {
      retriveDeviceFromSub(getSubs(widgets)).forEach(res => {
        store.dispatch({
          type: FETCH_DEVICE_SUCCESS,
          tangoDB: getTangoDB(),
          device: res
        })
      });

      subscribeToWebsocket(getSubs(widgets), store, dashboard);
    }
  }
  return next(action);
}