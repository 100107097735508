import { take, fork, put, call, race, delay } from "redux-saga/effects";

import createUserSaga from "../../shared/user/state/saga";
import * as API from "../synopticRepo";
import {
  synopticsLoaded,
  synopticExported,
  synopticRenamed,
  synopticDeleted,
  synopticCloned,
  synopticLoaded,
  synopticSaved,
  showNotification,
  hideNotification,
  synopticShared,
} from "./actionCreators";
import {
  PRELOAD_USER_SUCCESS,
  LOGIN_SUCCESS,
} from "../../shared/user/state/actionTypes";
import {
  RENAME_SYNOPTIC,
  DELETE_SYNOPTIC,
  CLONE_SYNOPTIC,
  SHARE_SYNOPTIC,
  LOAD_SYNOPTIC,
  EXPORT_SYNOPTIC,
  SYNOPTIC_RENAMED,
  SYNOPTIC_DELETED,
  SYNOPTIC_SHARED,
  SYNOPTIC_CLONED,
  SAVE_SYNOPTIC,
  SYNOPTIC_SAVED,
  SYNOPTIC_CREATED,
  SHOW_NOTIFICATION,
  SYNOPTIC_EXPORTED,
  IMPORT_SYNOPTIC,
} from "./actionTypes";
import { NotificationLevel } from "../../shared/notifications/notifications";

// Non-blocking fork effects
export default function* rootSaga() {
  yield fork(createUserSaga());
  yield fork(loadSynoptics);
  yield fork(renameSynoptic);
  yield fork(deleteSynoptic);
  yield fork(cloneSynoptic);
  yield fork(loadSynopticSaga);
  yield fork(exportSynopticSaga);
  yield fork(importSynopticSaga);
  yield fork(saveSynoptic);
  yield fork(notifyOnSave);
  yield fork(notifyOnExport);
  yield fork(notifyOnClone);
  yield fork(notifyOnDelete);
  yield fork(notifyOnShare);
  yield fork(hideNotificationAfterDelay);
  yield fork(shareSynoptic);
}

export function* loadSynoptics() {
  while (true) {
    const payload = yield take([
      PRELOAD_USER_SUCCESS,
      LOGIN_SUCCESS,
      SYNOPTIC_RENAMED,
      SYNOPTIC_DELETED,
      SYNOPTIC_CLONED,
      SYNOPTIC_SAVED,
    ]);

    //in the case of SYNOPTIC_SAVED, only load the synoptic from the db if it was created.
    //Loading the synoptic on every save becomes very sluggish, e.g. when trying to type text
    if (payload.type !== SYNOPTIC_SAVED || payload.created) {
      try {
        const result = yield call(API.loadUserSynoptics);

        yield put(synopticsLoaded(result));
      } catch (exception) {
        console.log(exception);
      }
    }
  }
}

export function* shareSynoptic() {
  while (true) {
    const { id, group, groupWriteAccess } = yield take(SHARE_SYNOPTIC);
    yield call(API.shareSynoptic, id, group, groupWriteAccess);
    yield put(synopticShared(id, group, groupWriteAccess));
  }
}

export function* renameSynoptic() {
  while (true) {
    const { id, name } = yield take(RENAME_SYNOPTIC);

    try {
      yield call(API.renameSynoptic, id, name);
      yield put(synopticRenamed(id, name));
    } catch {}
  }
}

export function* deleteSynoptic() {
  while (true) {
    const { id } = yield take(DELETE_SYNOPTIC);
    const result = yield call(API.deleteSynoptic, id);
    yield put(synopticDeleted(result.id));
  }
}

export function* cloneSynoptic() {
  while (true) {
    const { id } = yield take(CLONE_SYNOPTIC);
    const { id: newId } = yield call(API.cloneSynoptic, id);
    yield put(synopticCloned(newId));
  }
}

export function* loadSynopticSaga() {
  while (true) {
    const payload = yield take([
      LOAD_SYNOPTIC,
      SYNOPTIC_CLONED,
      SYNOPTIC_SAVED,
    ]);

    const { id, type } = payload;
    //In the case of synoptic_saved, only load synoptic if the synoptic was just created (we need the ID)
    let created = false;
    if (type === SYNOPTIC_SAVED) {
      created = payload.created;
    }
    if (!(type === SYNOPTIC_SAVED && !created)) {
      try {
        const {
          name,
          user,
          svg,
          insertTime,
          updateTime,
          group,
          groupWriteAccess,
          lastUpdatedBy,
          variables,
        } = yield call(API.load, id);

        yield put(
          synopticLoaded({
            id,
            name,
            svg,
            user,
            insertTime,
            updateTime,
            group,
            groupWriteAccess,
            lastUpdatedBy,
            variables,
          })
        );
      } catch (exception) {
        yield put(
          showNotification(
            NotificationLevel.ERROR,
            LOAD_SYNOPTIC,
            "Synoptic not found"
          )
        );
      }
    }
  }
}

export function* exportSynopticSaga() {
  while (true) {
    //Generator only executes is type is EXPORT_SYNOPTIC
    const payload = yield take(EXPORT_SYNOPTIC);
    const { id } = payload;
    try {
      const result = yield call(API.exportSynoptic, id);
      const {
        name,
        user,
        svg,
        insertTime,
        updateTime,
        group,
        groupWriteAccess,
        lastUpdatedBy,
        variables,
      } = result;

      yield put(
        synopticExported({
          id,
          name,
          user,
          svg,
          insertTime,
          updateTime,
          group,
          groupWriteAccess,
          lastUpdatedBy,
          variables,
        })
      );
    } catch (exception) {
      yield put(
        showNotification(
          NotificationLevel.ERROR,
          EXPORT_SYNOPTIC,
          "Synoptic not exported: " + exception
        )
      );
    }
  }
}

export function* importSynopticSaga() {
  while (true) {
    //Generator only executes is type is EXPORT_SYNOPTIC
    const payload = yield take(IMPORT_SYNOPTIC);
    const { content } = payload;
    try {
      const result = yield call(API.importSynoptic, content);
      if (result.created !== undefined) {
        if (result.warning.length > 0) {
          yield put(
            showNotification(
              NotificationLevel.WARNING,
              IMPORT_SYNOPTIC,
              result.warning
            )
          );
        }
        const { id: newId, created, name } = result;
        yield put(synopticSaved(newId, created, name));
      } else {
        yield put(
          showNotification(
            NotificationLevel.ERROR,
            IMPORT_SYNOPTIC,
            "Synoptic not imported: " + result
          )
        );
      }
    } catch (exception) {
      yield put(
        showNotification(
          NotificationLevel.ERROR,
          IMPORT_SYNOPTIC,
          "Synoptic not imported: " + exception
        )
      );
    }
  }
}

export function* notifyOnExport() {
  while (true) {
    const { synoptic } = yield take(SYNOPTIC_EXPORTED);
    if (synoptic.id) {
      yield put(
        showNotification(
          NotificationLevel.INFO,
          SYNOPTIC_EXPORTED,
          "Synoptic exported"
        )
      );
    }
  }
}

export function* saveSynoptic() {
  while (true) {
    const { id, name, variables } = yield take(SAVE_SYNOPTIC);
    try {
      const { id: newId, created } = yield call(
        API.save,
        id,
        name || "",
        variables
      );

      yield put(synopticSaved(newId, created, name, variables));
    } catch (exception) {
      yield put(
        showNotification(
          NotificationLevel.ERROR,
          SAVE_SYNOPTIC,
          "You cannot edit this synoptic " + exception
        )
      );
    }
  }
}

export function* notifyOnSave() {
  while (true) {
    const { created } = yield take(SYNOPTIC_SAVED);
    if (created) {
      yield put(
        showNotification(
          NotificationLevel.INFO,
          SYNOPTIC_CREATED,
          "Synoptic created"
        )
      );
    }
  }
}

export function* notifyOnShare() {
  while (true) {
    const { group, groupWriteAccess } = yield take(SYNOPTIC_SHARED);
    const msg = group
      ? "Synoptic shared with " +
        group +
        (groupWriteAccess ? " (write access)" : "")
      : "Synoptic unshared";
    yield put(showNotification(NotificationLevel.INFO, SYNOPTIC_SHARED, msg));
  }
}

export function* notifyOnClone() {
  while (true) {
    yield take(SYNOPTIC_CLONED);
    yield put(
      showNotification(
        NotificationLevel.INFO,
        SYNOPTIC_CLONED,
        "Synoptic cloned"
      )
    );
  }
}

export function* notifyOnDelete() {
  yield take(SYNOPTIC_DELETED);
  yield put(
    showNotification(
      NotificationLevel.INFO,
      SYNOPTIC_DELETED,
      "Synoptic deleted"
    )
  );
}

// Can possibly be simplified using debounce()
export function* hideNotificationAfterDelay() {
  while (true) {
    const { notification } = yield take(SHOW_NOTIFICATION);

    while (true) {
      const { timePassed } = yield race({
        newNotification: take(SHOW_NOTIFICATION),
        timePassed: delay(notification.duration),
      });

      if (timePassed) {
        break;
      }
    }

    yield put(hideNotification());
  }
}
