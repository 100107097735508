import { createSelector } from "reselect";
import { IElasticState } from "./reducer";

interface IRootState {
  elastic: IElasticState;
}

export function getElasticState(state: IRootState) {
  return state.elastic;
}

export const getElasticMessages = createSelector(
  getElasticState,
  state => state.messages
);

export const getElasticError = createSelector(
  getElasticState,
  state => state.error
);
