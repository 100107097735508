import { createSelector } from "reselect";
import { getAllInnerWidgetsById } from "../../utils/canvas";
import { IRootState } from "../reducers/rootReducer";
import { SelectedDashboardState } from "../reducers/selectedDashboard";

function getSelectedDashboardState(state: IRootState): SelectedDashboardState {
  return state.selectedDashboard;
}

function getUIState(state: IRootState) {
  return state.ui;
}

function getCanvasState(state: IRootState) {
  return state.canvases;
}

function getDashboardsState(state: IRootState) {
  return state.dashboards;
}

function getUserState(state: IRootState) {
  return state.user;
}
function getNotificationsState(state: IRootState) {
  return state.notifications;
}
function getClipboardState(state: IRootState) {
  return state.clipboard;
}
export const getNotification = createSelector(
  getNotificationsState,
  state => state.notification
);
export const hasSelectedWidgets = createSelector(
  getSelectedDashboardState,
  state => state.selectedIds.length > 0
);
export const getUserName = createSelector(
  getUserState,
  state => state.username
);
export const getDashboards = createSelector(
  getDashboardsState,
  state => state.dashboards
);

export const getSelectedDashboard = createSelector(
  getSelectedDashboardState,
  state => {
    return state;
  }
);

export const getCurrentDashoardVariables = createSelector(
  getSelectedDashboardState,
  getDashboardsState,
  (selected, dashboards) =>
    dashboards.dashboards.filter(dash => dash.id === selected.id)[0] ?
      dashboards.dashboards.filter(dash => dash.id === selected.id)[0].variables : undefined
);

export const getCurrentDashboard = createSelector(
  getSelectedDashboardState,
  getDashboards,
  (selected, dashboards) => dashboards.filter(dash => dash.id === selected.id)[0]
);

export const getClipboardWidgets = createSelector(
  getClipboardState,
  state => {
    return state.widgets;
  }
)
export const getClipboardPasteCounter = createSelector(
  getClipboardState,
  state => { return state.pasteCounter }
)
export const hasClipboardWidgets = createSelector(
  getClipboardState,
  state => {
    return state.widgets.length > 0;
  }
)
export const getUserGroups = createSelector(
  getUserState,
  state => state.userGroups || []
)

const getWidgetsObject = createSelector(
  getSelectedDashboardState,
  state => state.widgets
);

export const getMode = createSelector(
  getUIState,
  ui => ui.mode
);

export const getInspectorCollapsed = createSelector(
  getUIState,
  state => state.inspectorCollapsed
);
export const getLibraryCollapsed = createSelector(
  getUIState,
  state => state.libraryCollapsed
);
export const getMouseDownOnWidget = createSelector(
  getUIState,
  state => state.MouseDownOnWidget
)
export const getWidgets = createSelector(
  getWidgetsObject,
  widgetsObject => Object.keys(widgetsObject).map(key => widgetsObject[key])
);

export const getSelectedWidgets = createSelector(
  getSelectedDashboardState,
  ({ selectedIds, widgets}) => {

    widgets = getAllInnerWidgetsById({ ...widgets });
    return selectedIds.map(id => widgets[id])
  }
);

export const getCurrentCanvasWidgets = createSelector(
  getUIState,
  getWidgets,
  (ui, widgets) => widgets.filter(widget => widget.canvas === ui.selectedCanvas)
);

export const getCanvases = createSelector(
  getCanvasState,
  state => Object.keys(state).map(id => state[id])
);

export const getSelectedCanvas = createSelector(
  getUIState,
  getCanvasState,
  (ui, canvas) => canvas[ui.selectedCanvas]
);
