import React, { CSSProperties } from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  FileInputDefinition,
  StyleInputDefinition,
  StringInputDefinition,
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import SvgComponent from "../../../shared/components/SvgComponent/SvgComponent";
import sampleSvg from './sampleSvg';

type Inputs = {
  svgFile: FileInputDefinition | any;
  svgUploaded: StringInputDefinition;
  title: StringInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

const SvgWidget: React.FC<Props> = (props) => {
  const { mode, inputs } = props;
  let { svgFile, title } = inputs;

  svgFile = svgFile || "{}";
  let svgFileContent = sampleSvg;
  if (mode !== "library") {
    const svgFileString =
      typeof svgFile === "string"
        ? svgFile
        : JSON.stringify({ fileContent: svgFile });
    try {
      const parsedFile = JSON.parse(svgFileString);
      svgFileContent = parsedFile.fileContent || "{}";
    } catch (error) {
      console.error("Error parsing svgFile:", error);
      svgFileContent = sampleSvg;
    }
  }

  const widgetCss = props.inputs.widgetCss
    ? parseCss(props.inputs.widgetCss).data
    : {};
  const style: CSSProperties = {
    whiteSpace: "pre-wrap",
    ...widgetCss,
  };

  return (
    <div className="svg-wrapper" style={style}>
      {title !== "" && <div className="text-center">{title}</div>}
      <SvgComponent path={svgFileContent} mode={mode} zoom={false} layers={false} />
    </div>
  );
};

const definition: WidgetDefinition<Inputs> = {
  type: "SVG_WIDGET",
  name: "SVG Widget",
  defaultWidth: 10,
  defaultHeight: 10,
  inputs: {
    title: {
      type: "string",
      label: "Title",
    },
    svgFile: {
      type: "file",
      label: "SVG File",
      fileAccepted: ".svg",
    },
    svgUploaded: {
      type: "string",
      label: "SVG Uploaded",
      default: "None",
      dependsOn: "svgFile",
    },
    widgetCss: {
      type: "style",
      default: "",
      label: "Custom Css",
    },
  },
};

const SVGWidget = { component: SvgWidget, definition };
export default SVGWidget;
