import React, { useState } from "react";
import classNames from "classnames";
import ValueDisplay from "./ValueDisplay/ValueDisplay";
import DescriptionDisplay from "../DescriptionDisplay/DescriptionDisplay";
import { NonScalarValueModal } from "./NonScalarValueModal";
import { QualityIndicator } from './QualityIndicator';

export const AttributeTableRow = ({
  tangoDB,
  deviceName,
  attribute,
  allowedToEdit,
  onEdit,
}) => {
  const {
    name,
    label,
    writeValue,
    datatype,
    dataformat,
    writable,
    maxvalue,
    minvalue,
    description,
  } = attribute;

  const [nonScalarOnDisplay, setNonScalarOnDisplay] = useState(null);
  const isWritable = dataformat === "SCALAR" && writable !== "READ";

  return (
    <tr>
      <td className="quality-name">
        <QualityIndicator name={name} deviceName={deviceName} />
      </td>
      <td title={datatype}>
        {dataformat !== "SCALAR" && (
          <i
            className="fa fa-eye view"
            style={{ marginRight: "1em" }}
            onClick={() => setNonScalarOnDisplay(name)
            }
          />
        )}
        {label}
      </td>
      {allowedToEdit && (
        <td
          className={classNames("edit", { writable: isWritable })}
          onClick={() => isWritable && onEdit && onEdit(name)}
        >
          {isWritable && <i className="fa fa-pencil" />}
        </td>
      )}
      <td className="value">
        {dataformat === "SCALAR" ? (
          <ValueDisplay
            name={name}
            deviceName={deviceName}
            writeValue={writeValue}
            datatype={datatype}
            dataformat={dataformat}
            writable={writable}
            maxvalue={maxvalue}
            minvalue={minvalue}
          />
        ) : nonScalarOnDisplay ? (
          <NonScalarValueModal
            tangoDB={tangoDB}
            device={deviceName}
            attribute={name}
            dataformat={dataformat}
            datatype={datatype}
            onClose={() => setNonScalarOnDisplay(null)}
          />
        ) : null}
      </td>
      <td className="description">
        <DescriptionDisplay name={name} description={description} />
      </td>
    </tr>
  );
};