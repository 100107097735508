import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCommandOutputs } from "../../../../shared/state/selectors/commandOutput";
import { getDisabledDisplevels } from "../../../../shared/state/selectors/deviceDetail";
import { getCommandsState } from "../../../../shared/state/selectors/devices";
import { getCommandOutputsLoading } from "../../../../shared/state/selectors/loadingStatus";

import { getIsLoggedIn } from "../../../../shared/user/state/selectors";

import { executeCommand } from "../../../../shared/state/actions/tango";

import NotLoggedIn from "../NotLoggedIn/NotLoggedIn";
import DescriptionDisplay from "../DescriptionDisplay/DescriptionDisplay";
import CommandArgsFile from "../../../../shared/components/CommandArgsFile/CommandArgsFile";

import { command } from "../../../propTypes";
import "./CommandTable.css";

import { InputField } from "../../../../shared/utils/InputField";
import OutputDisplay from "../../../../shared/utils/OutputDisplay";
import { WEBSOCKET } from "../../../../shared/state/actions/actionTypes";

const config = window['config'];

class CommandTable extends Component {

  componentDidMount() {
    this.props.onSubscribe();
  }

  render() {
    const {
      commands,
      onExecute,
      disabledDisplevels,
      outputsLoading,
      commandOutputs,
      isLoggedIn,
    } = this.props;

    const permitsUpload = config.SHOW_COMMAND_FILE_ON_DEVICES;

    return (
      <div className="CommandTable">
        <NotLoggedIn>
          You are currently not logged in and cannot execute any commands.
        </NotLoggedIn>
        <table className="separated">
          <tbody>
            {commands.map((command, i) => {
              const {
                name,
                displevel,
                intype,
                intypedesc,
                outtypedesc,
              } = command;
              const enabled =
                Object.values(disabledDisplevels).indexOf(displevel) === -1;
              return (
                enabled && (
                  <tr key={i}>
                    <td>
                      <div title={intype}>{name}</div>
                      <br />
                      <OutputDisplay
                        value={commandOutputs[name]}
                        isLoading={outputsLoading[name]}
                      />
                    </td>
                    <td className="input">
                      <InputField
                        onExecute={onExecute}
                        name={name}
                        device={this.props.deviceName}
                        commandFromDevice={true}
                        intype={intype}
                        requireConfirmation={true}
                        alignButtonRight={true}
                      />
                      {intype === "DevString" &&
                        permitsUpload &&
                        isLoggedIn && (
                          <CommandArgsFile
                            label=""
                            uploadBtnLabel="Upload File"
                            sendBtnText="Execute"
                            output=""
                            mode="run"
                            commandFromDevice={onExecute}
                            commandName={name}
                            commandDevice={this.props.deviceName}
                            requireConfirmation={true}
                          />
                        )}
                    </td>
                    <td className="description">
                      <DescriptionDisplay
                        name={name}
                        description={`Input: ${intypedesc}\nOutput: ${outtypedesc}`}
                      />
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

CommandTable.defaultProps = {
  commands: [],
  commandOutputs: {},
};

CommandTable.propTypes = {
  commands: PropTypes.oneOfType([PropTypes.arrayOf(command), command]),
  onExecute: PropTypes.func,
  deviceName: PropTypes.string,
  enabledList: PropTypes.arrayOf(PropTypes.string),
  outputsLoading: PropTypes.object, //uses dynamic keys, tricky to validate this with shape()
  commandOutputs: PropTypes.object, //uses dynamic keys, tricky to validate this with shape()
};

function mapStateToProps(state, ownProps) {
  const deviceName = ownProps.deviceName;

  return {
    disabledDisplevels: getDisabledDisplevels(state),
    commands: Object.values(getCommandsState(state)[deviceName]),
    commandOutputs: getCommandOutputs(deviceName)(state),
    outputsLoading: getCommandOutputsLoading(state),
    isLoggedIn: getIsLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { tangoDB, deviceName } = ownProps;
  return {
    onSubscribe: () => dispatch({ //Subscribe to device state
      type: WEBSOCKET.WS_SUBSCRIBE,
      payload: { devices: [deviceName + "/state"] }
    }),
    onExecute: (command, value) =>
      dispatch(executeCommand(tangoDB, command, value, deviceName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandTable);
