import fileDownload from "js-file-download";
import { validateSVG } from "./state/reducers/selectedSynoptic/lib.ts";
const config = window["config"];

const headers = {
  "Content-Type": "application/json; charset=utf-8",
};

async function save(id, name, svg, variables = []) {
  const res = await fetch(config.basename + "/synoptics/", {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({
      id,
      name,
      svg,
      variables,
      tangoDB: getTangoDB(),
    }),
  });
  if (!res.ok) {
    throw res;
  }
  return res.ok ? res.json() : null;
}

async function load(id) {
  const res = await fetch(config.basename + "/synoptics/" + id, {
    method: "GET",
    credentials: "include",
    headers,
  });
  const synoptic = await res.json();
  return synoptic;
}

async function getGroupSynopticCount() {
  const res = await fetch(
    config.basename +
      "/synoptics/group/synopticsCount?excludeCurrentUser=true&tangoDB=" +
      getTangoDB(),
    {
      method: "GET",
      credentials: "include",
      headers,
    }
  );
  return res.json();
}

async function getGroupSynoptics(groupName) {
  const res = await fetch(
    config.basename +
      "/synoptics/group/synoptics?excludeCurrentUser=true&group=" +
      groupName +
      "&tangoDB=" +
      getTangoDB(),
    {
      method: "GET",
      credentials: "include",
      headers,
    }
  );
  return res.json();
}

async function loadUserSynoptics() {
  const res = await fetch(
    config.basename + "/synoptics/user/synoptics?tangoDB=" + getTangoDB(),
    {
      method: "GET",
      credentials: "include",
      headers,
    }
  );
  return res.json();
}

async function deleteSynoptic(id) {
  const res = await fetch(config.basename + "/synoptics/" + id, {
    method: "DELETE",
    credentials: "include",
    headers,
  });
  return res.json();
}

async function cloneSynoptic(id) {
  const res = await fetch(config.basename + "/synoptics/" + id + "/clone", {
    method: "POST",
    credentials: "include",
    headers,
  });
  return res.json();
}

async function shareSynoptic(id, group, groupWriteAccess) {
  const res = await fetch(config.basename + "/synoptics/" + id + "/share", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify({ group, groupWriteAccess }),
  });
  return res.json();
}

async function renameSynoptic(id, newName) {
  const res = await fetch(config.basename + "/synoptics/" + id + "/rename", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify({ newName }),
  });
  return res.json();
}

function getTangoDB() {
  try {
    if (config.basename !== "") return window.location.pathname.split("/")[2];
    else return window.location.pathname.split("/")[1];
  } catch (e) {
    return "";
  }
}

async function exportSynoptic(id) {
  const res = await fetch(config.basename + "/synoptics/" + id, {
    method: "GET",
    credentials: "include",
    headers,
  });

  const synoptic = await res.json();

  try {
    const version = `${process.env.REACT_APP_VERSION}`;
    const {
      name,
      user,
      svg,
      insertTime,
      updateTime,
      group,
      groupWriteAccess,
      lastUpdatedBy,
      variables,
    } = synoptic;

    let canvas = {
      id: id,
      name: name,
      svg: svg,
      version: version,
      user: user,
      insertTime: insertTime,
      updateTime: updateTime,
      group: group,
      groupWriteAccess: groupWriteAccess,
      lastUpdatedBy: lastUpdatedBy,
      variables,
    };

    var output = JSON.stringify(canvas, null, 2);
    //sanitize the file name in order to prevent incompability across different OS
    var sanitize = require("sanitize-filename");
    const nameSanitized = sanitize(name);
    fileDownload(output, nameSanitized + ".wj");
    return synoptic;
  } catch (exception) {
    //const errorMsg = {level: NotificationLevel.ERROR, message: "Error exporting synoptic: "+exception}
    //feedBackService.setData(errorMsg);
    console.log(exception);
    return exception;
  }
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}

async function importSynoptic(file) {
  let contentBuffer = await readFileAsync(file);
  const { validationResult, importError } = validateSVG(
    contentBuffer
  );

  if (validationResult) {
    try {
      let resultSave = await save(
        "",
        file.name,
        contentBuffer,
        [] /*variables*/
      );
      resultSave.name = file.name;
      resultSave.warning = importError;

      return resultSave;
    } catch (e) {
      //const errorMsg = {level: NotificationLevel.ERROR, message: "Error parsing Json: "+e}
      //feedBackService.setData(errorMsg);
      console.log(e);
      return e;
    }
  } else {
    return importError;
  }
}

export {
  renameSynoptic,
  shareSynoptic,
  getTangoDB,
  cloneSynoptic,
  deleteSynoptic,
  loadUserSynoptics,
  getGroupSynoptics,
  getGroupSynopticCount,
  load,
  exportSynoptic,
  importSynoptic,
  save,
};
