export const SAVE_STATE_URL = 'SAVE_STATE_URL';
export const SAVE_STATE_URL_SUCCESS = 'SAVE_STATE_URL_SUCCESS';
export const SAVE_STATE_URL_FAILED = 'SAVE_STATE_URL_FAILED';

export const LOAD_STATE_URL = 'LOAD_STATE_URL';
export const LOAD_STATE_URL_SUCCESS = 'LOAD_STATE_URL_SUCCESS';
export const LOAD_STATE_URL_FAILED = 'LOAD_STATE_URL_FAILED';

export const OPEN_LOGIN_DIALOG = "OPEN_LOGIN_DIALOG";
export const CLOSE_LOGIN_DIALOG = "CLOSE_LOGIN_DIALOG";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const PRELOAD_USER = "PRELOAD_USER";
export const PRELOAD_USER_SUCCESS = "PRELOAD_USER_SUCCESS";
export const PRELOAD_USER_FAILED = "PRELOAD_USER_FAILED";

export const EXTEND_LOGIN = "EXTEND_LOGIN";
export const EXTEND_LOGIN_SUCCESS = "EXTEND_LOGIN_SUCCESS";
export const EXTEND_LOGIN_FAILED = "EXTEND_LOGIN_FAILED";

export const SAVE_NOTIFICATIONS = "SAVE_NOTIFICATIONS";
export const SAVE_NOTIFICATIONS_SUCCESS = "SAVE_NOTIFICATIONS_SUCCESS";
export const SAVE_NOTIFICATIONS_FAILED = "SAVE_NOTIFICATIONS_FAILED";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_NOTIFICATIONS_FAILED = "LOAD_NOTIFICATIONS_FAILED";

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS_SUCCESS = "CLEAR_NOTIFICATIONS_SUCCESS";
export const CLEAR_NOTIFICATIONS_FAILED = "CLEAR_NOTIFICATIONS_FAILED";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_SUCCESS = "SET_NOTIFICATIONS_SUCCESS";
export const SET_NOTIFICATIONS_FAILED = "SET_NOTIFICATIONS_FAILED";

export const NOTIFY_LOGOUT = "NOTIFY_LOGOUT";
