import React, { Component, CSSProperties } from "react";
import Files from "react-files";
import { CommandInput } from "../../../dashboard/types";
import "./styles/command-file.styles.css";

import { getTangoDB } from "../../../dashboard/dashboardRepo";
import { connect } from "react-redux";
import { executeCommand } from "../../state/actions/tango";
import { getCommandsState } from "../../state/selectors/devices";
import { ICommandsState } from "../../state/reducers/commands";
import { commandExamples, validateCommandInput } from "../../utils/commandInputValidation";
import { getIsLoggedIn } from "../../user/state/selectors";
import CommandOutput from "../CommandOutput/CommandOutput";
import { Col, Container, Row } from "react-bootstrap";
import DraggableModal from "./DraggableModal";

type Props = {
  tangoDB: string;
  label: string;
  uploadBtnCss?: CSSProperties;
  uploadBtnLabel: string;
  sendBtnCss?: CSSProperties;
  sendBtnText: string;
  mode: string;
  requireConfirmation: boolean;
  alignSendButtonRight: boolean;
  commands: ICommandsState;
  command?: CommandInput;
  commandFromDevice?: Function;
  commandName: string;
  commandDevice: string;
  commandOutputs: object;
  onExecute: (tangoDB, command, value, deviceName) => void;
  loggedIn: boolean;
  displayOutput: boolean;
};

interface State {
  fileName: String;
  fileContent: any;
  fileType: String;
  updateFileContent: any;
  pending: boolean;
  showFileViewModal: boolean;
  commandStatus: String;
  showCompare: boolean;
  restoreValue: boolean;
}

class CommandArgsFile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fileName: "No file selected",
      fileContent: "",
      fileType: "",
      updateFileContent: "",
      pending: false,
      showFileViewModal: false,
      commandStatus: "",
      showCompare: false,
      restoreValue: false,
    };

    this.onFilesChange = this.onFilesChange.bind(this);
    this.handleSendCommand = this.handleSendCommand.bind(this);
  }

  public render() {
    const {
      commandName,
      commandDevice,
      loggedIn,
      displayOutput,
      mode,
      commandFromDevice
    } = this.props;
    const acceptedType = this.getAcceptedType(commandDevice, commandName);
    const modified = this.state.fileContent !== this.state.updateFileContent ? "[Modified] " : "";
    
    return (
      <div style={commandFromDevice === undefined ? {} : {float:'right', marginTop: '5px'}}>
        <Container fluid >
          <Row className="text-row">
            <Files
              onChange={this.onFilesChange}
              onError={this.onFilesError}
              multiple={false}
              maxFileSize={25165824}
              minFileSize={0}
              clickable={loggedIn && mode === 'run'}
            >
              <div>
                <button
                  style={this.props.uploadBtnCss}
                  className="btn-upload btn taranta-btn btn-secondary btn-sm"
                  title={mode === 'run' ? loggedIn ? "Ex: " + commandExamples(acceptedType) + "\n(Max file size 24MB)" :
                    "⚠️ You need to be logged in to use this ⚠️" : ""}
                  disabled={!loggedIn}
                >
                  <span >{this.props.uploadBtnLabel}</span>
                </button>
              </div>

            </Files>
            <div
              title={
                "" !== this.state.fileContent
                  ? 'Click here to view or edit the content of "' +
                  this.state.fileName.toString() +
                  '"'
                  : ""
              }
              data-testid="content-edit"
              onClick={(e) => {
                this.openFileViewModal(e);
              }}
              className={
                "file-name-wrapper" +
                ("" !== this.state.fileContent ? " link-view" : "")
              }
            >
              {"run" === this.props.mode ?
                this.truncate(modified + this.state.fileName, 17) : "No file selected"}
            </div>
            <div className={this.props.alignSendButtonRight ? "ml-auto" : ""}>
              <button
                style={this.props.sendBtnCss}
                onClick={this.handleSendCommand}
                disabled={this.state.fileContent.length < 1 || this.state.pending || !loggedIn || mode !== 'run'}
                className="btn btn-outline-secondary btn-send btn-sm"
                title={mode === 'run' ? loggedIn ? "Click this button to fire command" : "⚠️ You need to be logged in to use this ⚠️" : ""}
              >
                <span style={{ fontSize: '12px' }}>{
                  this.props.label === "" ? this.props.sendBtnText :
                    "Send: " + this.props.label}</span>
              </button>
            </div>
          </Row>
          <Row>
            <Col className="output">
              {
                (!this.props.commandFromDevice && displayOutput && mode === 'run') &&
                <CommandOutput
                  commandName={commandName}
                  deviceName={commandDevice}
                />
              }
            </Col>
          </Row>
        </Container>
        <div
          id="modal-file-viewer"
          className={false === this.state.showFileViewModal ? "hide" : ""}
        >
          <div>
            <div
              role="dialog"
              className={
                "modal modal-inner " +
                (true === this.state.showFileViewModal ? "show" : "")
              }
            >
              <DraggableModal
                fileName={this.state.fileName}
                fileContent={this.state.fileContent}
                fileType={this.state.fileType}
                updateFileContent={this.state.updateFileContent}
                showCompare={this.state.showCompare}
                restoreValue={this.state.restoreValue}
                setState={(obj) => { this.setState(obj) }}
              ></DraggableModal>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Event Listeners
   */
  openFileViewModal(e) {
    if ("" === this.state.fileContent) return;
    this.setState({ showFileViewModal: true });
  }

  async onFilesChange(files) {
    try {
      if (files[0] !== undefined) {
        let uploadFileName = files[0].name;
        this.setState({ fileName: uploadFileName });

        const fileContent = await this.readFileAsync(files[0]);
        if (files[0].type.includes("json") || files[0].type.includes("text"))
          this.setState({ fileType: "text" });
        else this.setState({ fileType: "binary" });
        this.setState({ fileContent });
        this.setState({
          updateFileContent: fileContent,
          showCompare: false,
          restoreValue: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;
      reader.readAsText(file);
    });
  }


  private async handleSendCommand() {
    if (this.state.pending) {
      return;
    }
    const { tangoDB, commandName, commandDevice } = this.props;
    const acceptedType = this.getAcceptedType(commandDevice, commandName);
    const requireConfirmation = this.props.requireConfirmation;
    const content = this.state.updateFileContent.trim();
    const argin = validateCommandInput(acceptedType, content);

    if (argin === undefined) {
      this.showAlert(content, acceptedType);
      return;
    }

    this.setState({ commandStatus: "Executing" });
    const message = `Confirm executing "${commandName}" on "${commandDevice}" with ${limitString(content, 8)} as uploaded file 
    ${this.state.fileContent.trim() !== content ? `MODIFIED` : ``}`;
    if (!requireConfirmation || window.confirm(message)) {
      this.setState({ pending: true });
      //EXECUTE COMMAND 
      this.props.onExecute(
        tangoDB,
        commandName,
        argin,
        commandDevice
      );
      this.setState({ pending: false, commandStatus: "Executed" });
    }
  }

  /**
   * Supporting Functions
   */
  private truncate = (str, len) => {
    return str.length > len ? str.substr(0, len - 4) + ".." : str;
  }

  private getAcceptedType = (commandDevice, commandName) => {
    let acceptedType = '';
    if (this.props.command) {
      //Dashboards part
      acceptedType = this.props?.command?.['acceptedType'];
    }
    else if (Object.keys(this.props.commands).length > 0) {
      //Jive part
      acceptedType = this.props.commands?.[commandDevice]?.[commandName]?.['intype'];
    }
    return acceptedType;
  }

  private showAlert(content, acceptedType) {
    const warning = '\u26A0'; // Warning sign (Unicode character)

    const formattedContent = limitString(String(content), 8);
    const message = `Input '${formattedContent}' is invalid for type ${acceptedType}`;

    // Combine the warning sign, ANSI color, and the message
    const alertMessage = `${warning} ${message} ${warning}`;

    alert(alertMessage);
  }

  onFilesError(error, file) {
    console.log(error, file);
  }
}

function limitString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength) + '...';
  }
}

function mapStateToProps(state, ownProps) {
  return {
    commands: getCommandsState(state),
    tangoDB: getTangoDB(),
    loggedIn: getIsLoggedIn(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onExecute: (tangoDB, command, value, deviceName) =>
      dispatch(executeCommand(tangoDB, command, value, deviceName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandArgsFile);
