import tangoAPI from "../api/tangoAPI";
import { WEBSOCKET } from "../state/actions/actionTypes";
import { createDeviceWithTangoDBFullPath } from "../../dashboard/runtime/utils";

const config = window['config'];

export async function fetchInitialValues(dispatch, element, tangoDB='') {
    const tangoDBName = tangoDB || window.location.href.replace(config.basename, "").split('/')[3];
    const attributes = await tangoAPI.fetchAttributesValues(
        tangoDBName,
        element
    );

    if (attributes[0]) {
        attributes.forEach((attributeData) => {
            const attribute = {
                device: createDeviceWithTangoDBFullPath(tangoDBName, attributeData.device),
                attribute: attributeData.name,
                value: attributeData.value,
                timestamp: attributeData.timestamp,
                quality: attributeData.quality,
                writeValue: attributeData.writevalue
            }
            const data = JSON.stringify({ payload: { data: { attributes: attribute } } });
            dispatch({
                type: WEBSOCKET.WS_MESSAGE,
                value: [{ data }]
            });
        })

    }
}