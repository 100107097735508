import lodash from "lodash";
import panzoom from "panzoom";

// calculate the largest scale at which the whole image fits in
// the window.
// This seems to conflict with the panzoom library
const fitImageInWindow = (
  windowWidth,
  windowHeight,
  imageWidth,
  imageHeight
) => {
  var windowRatio = windowWidth / windowHeight,
    imageRatio = imageWidth / imageHeight;

  if (windowRatio < imageRatio) {
    return windowWidth / imageWidth;
  }
  return windowHeight / imageHeight;
};

// get the current view box
const getViewBox = (svg, transform) => {
  return {
    width: svg.clientWidth / transform.scale,
    height: svg.clientHeight / transform.scale,
    x: -parseFloat(transform.x / transform.scale),
    y: -parseFloat(transform.y / transform.scale),
  };
};

// smoothly pan the view to center on a coordinate
const moveTo = (svg, zoom, coord, duration, minimumScale, maxZoom) => {
  var transform = zoom.getTransform();
  var bbox = getViewBox(svg, transform),
    padded = {
      x: coord.x - bbox.width / 2,
      y: coord.y - bbox.height / 2,
      width: bbox.width,
      height: bbox.height,
    };
  if (duration) {
    setZoom(svg, padded, minimumScale, maxZoom, zoom)
      .transition()
      .duration(duration)
      .ease("linear");
  } else {
    setZoom(svg, padded, minimumScale, maxZoom, zoom);
  }
};

// setup the mouse pan/zoom behavior
function addZoomBehaviour(svg, svgMain) {
  svg.querySelectorAll("svg > g > g > g").forEach((node) => {
    var name = node.getAttribute("inkscape:label") || "";
    var match = /zoom(\d)/.exec(name);
    if (match) {
      // This is a zoom layer
      var level = parseInt(match[1]);
      node.classList.add("zoom");
      node.classList.add("level" + level);
      node.style.display = null;
    }
  });
  return panzoom(svgMain);
}

// define the area to be visible
// replace with zoom.zoomTo(x,y,scale)
function setZoom(svg, bbox, minimumScale, maxZoom, zoom) {
  var parent = svg.parentElement;
  var elWidth = parent.clientWidth,
    elHeight = parent.clientHeight;
  var scale = Math.min(elWidth / bbox.width, elHeight / bbox.height);
  scale = Math.min(scale, minimumScale * maxZoom);
  var x = elWidth / 2 - scale * (bbox.x + bbox.width / 2),
    y = elHeight / 2 - scale * (bbox.y + bbox.height / 2);
  return zoom.zoomTo(x, y, scale);
}

// indicate the current view box
function updateIndicator(svg, bbox, inner, indicator) {
  // Calculate the ratio between the thumbnail and the svg elements sizes
  const width_ratio = inner.clientWidth / svg.clientWidth;
  const height_ratio = inner.clientHeight / svg.clientHeight;

  // Multiply the ratio with the viewport
  indicator.style.left = `${bbox.x * width_ratio}px`;
  indicator.style.top = `${bbox.y * height_ratio}px`;
  indicator.style.width = `${bbox.width * width_ratio}px`;
  indicator.style.height = `${bbox.height * height_ratio}px`;
}

function toggleVisibility(toggle, outer) {
  var isInvisible = toggle.classList.contains("isinvisible");
  outer.style.visibility = isInvisible ? "visible" : "hidden";
  if (isInvisible) {
    toggle.classList.remove("isinvisible");
    toggle.textContent = "-";
  } else {
    toggle.classList.add("isinvisible");
    toggle.textContent = "+";
  }
}

const prepareZoom = (svg, config) => {
  /*
  config.zoomSteps is a list of numbers. The first number
  determines at which scale (relative to full view) we switch
  from detail level 0 to 1, and so on. Setting the first
  number to 1 means that the lowest detail level is only used
  when fully zoomed out, showing the whole picture.  The end
  points of zoomSteps also limits user zooming.  If the
  number of steps is smaller than the number of zoom levels
  in the SVG, those higher zoom levels will not be visible.
  */

  config = config || {};
  var zoomSteps = config.zoomSteps || [1, 10, 100],
    maxZoom = zoomSteps.slice(-1)[0];

  //TODO: Secure to get the main layer e.g main is maybe not the only top layer
  // TODO: change the zoom mouse movement layer
  var svgMain = svg.querySelector("svg > g"); // the toplevel group
  // var zoomSel = svgMain.querySelectorAll('g[inkscape\\:label^="zoom"]'); // all zoom levels

  if(!svgMain) return;

  var minimumScale = 1,
    oldZoomLevel;
  var parent = svg.parentElement;
  var windowBox = svg.getBoundingClientRect();
  var imageBox = svgMain.getBBox();
  svg.setAttribute("viewBox", [0, 0, imageBox.width, imageBox.height]);

  minimumScale = fitImageInWindow(
    windowBox.width,
    windowBox.height,
    imageBox.width,
    imageBox.height
  );

  var zoom = addZoomBehaviour(svg, svgMain).on("transform", (event) => {
    var transform = event.getTransform();
    updateZoomLevel(transform);
    updateIndicator(svg, getViewBox(svg, transform), inner, indicator);
  });

  // update the zoom levels so that the one that corresponds to
  // the current scale is visible
  var updateZoomLevel = lodash.throttle(
    function(transform) {
      var relativeScale = transform.scale / minimumScale,
        zoomLevel;
      /*
      This is a primitive way to switch zoom level.
      Can't see a way to make this completely general
      so for now it must be configured manually.
      */
      for (var i = 0; i < zoomSteps.length; i++) {
        var z = zoomSteps[i];
        if (z >= relativeScale) {
          zoomLevel = i;
          break;
        }
        zoomLevel = i; // never go beyond the highest level
      }
      if (zoomLevel !== oldZoomLevel) {
        var levelClass = ".level" + zoomLevel;
        svgMain
          .querySelectorAll("g.zoom:not(" + levelClass + ")")
          .forEach((node) => {
            node.classList.add("hidden");
            node.opacity = 0;
            node.classList.add("really-hidden");
          });
        svgMain.querySelectorAll("g.zoom" + levelClass).forEach((node) => {
          node.classList.remove("hidden");
          node.classList.remove("really-hidden");
          node.opacity = 1;
        });
        oldZoomLevel = zoomLevel;
      }
    },
    100,
    { leading: false }
  );

  // ====== From thumbnail.js ===========
  var outer = document.createElement("div");
  outer.classList.add("thumbnail");
  parent.appendChild(outer);

  /*
  allow the user to configure the size of the thumbnail.
  Note that only the height can be set. Seems more likely that users
  will enlarge the window horizontally, so it's more important that the
  thumbnail does not grow huge then. But the logic is still lacking,
  we should perhaps limit the *area* of the thumbnail instead?
  */
  if (config.size) {
    outer.style.height = config.size;
  } else {
    outer.style.height = "20%";
    outer.style.width = "20%";
  }

  var inner = document.createElement("div");
  inner.classList.add("inner");
  outer.appendChild(inner);

  var indicator = document.createElement("div");
  indicator.classList.add("indicator");
  inner.appendChild(indicator);
  indicator.style.width = `${inner.clientWidth}px`;
  indicator.style.height = `${inner.clientHeight}px`;

  var toggle = document.createElement("div");
  toggle.classList.add("thumbnail-toggle");
  toggle.textContent = "-";
  toggle.title = "Hide/show thumbnail";
  parent.appendChild(toggle);

  var thumb = svg.cloneNode(true);
  inner.appendChild(thumb);

  var width = thumb.width,
    inner_width = inner.offsetWidth,
    scale = width / inner_width;

  // re-center the view on click
  const panTo = (event) => {
    moveTo(
      svg,
      zoom,
      { x: event.layerX * scale, y: event.layerY * scale },
      200,
      minimumScale,
      maxZoom
    );
  };
  thumb.onClick = panTo;

  var transform = zoom.getTransform();
  updateIndicator(svg, getViewBox(svg, transform), inner, indicator);

  toggle.onClick = toggleVisibility(toggle, outer);
};

export default prepareZoom;
export { fitImageInWindow, getViewBox, toggleVisibility };
