import { takeEvery, fork, call, put } from "redux-saga/effects";
import {
  fetchElasticLogsSucces,
  fetchElasticLogsError
} from "./actionCreators";

import {
  FETCH_ELASTIC,
} from "./actionTypes";

import ElasticAPI from "../api";
import moment from "moment";

function* fetchElasticLog(action) {
  const result = yield call(ElasticAPI.fetchElasticLogs, action.filters);
  const resultAction = result ? fetchElasticLogsSucces(result) : fetchElasticLogsError(`Failed to fetch the latest log at ${moment().format('YYYY-MM-DD hh:mm:ss')}`);
  yield put(resultAction);
}

export function* fetchElasticLogsSaga() {
  yield takeEvery(FETCH_ELASTIC, fetchElasticLog);
}

export default function elasticLogsSaga() {
  return function* elasticLogs() {
    yield fork(fetchElasticLogsSaga);
  }
}
