import React from 'react';
import ReactDiffViewer from "react-diff-viewer-continued";
import Draggable from "react-draggable";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "./styles/prism.css";

interface Props {
    fileName: String;
    fileContent: any;
    fileType: String;
    updateFileContent: any;
    showCompare: boolean;
    restoreValue: boolean;
    setState: (obj) => void;
}

const compareStyles = {
    line: {
        fontSize: 13,
    },
};

const DraggableModal: React.FC<Props> = ({
    fileName,
    fileContent,
    fileType,
    updateFileContent,
    showCompare,
    restoreValue,
    setState
}) => {

    return (
        <Draggable handle=".modal-header">
            <div className="modal-dialog">
                <div className="modal-content" role="document">
                    <div className="modal-header">
                        <h4 className="modal-title">{"View/Edit " + fileName}</h4>
                    </div>
                    {fileType === "binary" && (
                        <div className="modal-body file-view-body">
                            Unable to generate a preview for a binary file.
                        </div>
                    )}
                    {fileType === "text" && (
                        <div>
                            <div className="modal-body file-view-body">
                                {!restoreValue && (
                                    <button
                                        onClick={() => {
                                            setState({ restoreValue: true });
                                        }}
                                        disabled={
                                            fileContent ===
                                            updateFileContent
                                        }
                                        className="btn-restore taranta-btn btn btn-primary btn-sm"
                                        title="Restore"
                                    >
                                        <span className="btn-text">Restore Content</span>
                                    </button>
                                )}
                                {restoreValue && (
                                    <div>
                                        <span>Are you sure?</span>
                                        <button
                                            onClick={() => {
                                                setState({
                                                    updateFileContent: fileContent,
                                                });
                                                setState({ showCompare: false });
                                                setState({ restoreValue: false });
                                            }}
                                            className="btn-yes taranta-btn btn btn-primary btn-sm"
                                            title="Yes"
                                        >
                                            <span className="btn-text">Yes</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setState({
                                                    showCompare: false,
                                                    restoreValue: false,
                                                });
                                            }}
                                            className="btn-no taranta-btn btn btn-secondary btn-sm"
                                            title="No"
                                        >
                                            <span className="btn-text">No</span>
                                        </button>
                                    </div>
                                )}
                                {!showCompare && (
                                    <button
                                        onClick={() => {
                                            setState({ showCompare: true });
                                        }}
                                        disabled={
                                            fileContent ===
                                            updateFileContent
                                        }
                                        className="btn-compare taranta-btn btn btn-primary btn-sm"
                                        title="compare"
                                    >
                                        <span className="btn-text">Compare</span>
                                    </button>
                                )}
                                {showCompare && (
                                    <button
                                        onClick={() => {
                                            setState({ showCompare: false });
                                        }}
                                        disabled={
                                            fileContent ===
                                            updateFileContent
                                        }
                                        className="btn-edit taranta-btn btn btn btn-primary btn-sm"
                                        title="Restore"
                                    >
                                        <span className="btn-text">Edit</span>
                                    </button>
                                )}
                                {!showCompare && (
                                    <Editor
                                        value={updateFileContent}
                                        onValueChange={(code) =>
                                            setState({ updateFileContent: code })
                                        }
                                        highlight={(code) =>
                                            highlight(code, languages.js)
                                        }
                                        padding={10}
                                        style={{
                                            fontFamily:
                                                '"Fira code", "Fira Mono", monospace',
                                            fontSize: 14,
                                        }}
                                    />
                                )}
                                {showCompare && (
                                    <ReactDiffViewer
                                        styles={compareStyles}
                                        hideLineNumbers={true}
                                        oldValue={fileContent}
                                        newValue={updateFileContent}
                                        splitView={true}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn-close-modal btn btn-primary"
                            onClick={(e) => {
                                setState({ showFileViewModal: false });
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default DraggableModal;
