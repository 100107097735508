import React from "react";
import AttributeScatterValues from "./AttributeScatterValues";

import {
  WidgetDefinition,
  AttributeInput,
  AttributeInputDefinition,
  SelectInputDefinition,
  BooleanInputDefinition
} from "../../types";
import { WidgetProps } from "../types";
import { showHideTangoDBName } from "../../DBHelper";


type Inputs = {
  independent: AttributeInputDefinition;
  dependent: AttributeInputDefinition;
  showAttribute: SelectInputDefinition;
  showTangoDB: BooleanInputDefinition;
};

type Props = WidgetProps<Inputs>;

function fullName(attribute: AttributeInput, showAttribute: string, showTangoDB: boolean) {
  let display = "";
  const attributeDevice = showHideTangoDBName(true, showTangoDB, attribute.device)
  if (showAttribute === "Label") display = attribute.label;
  else if (showAttribute === "Name") display = attribute.attribute;
  return `${attributeDevice || "?"}/${display || "?"}`;
}

function AttributeScatter(props: Props) {
  const { mode, inputs, actualWidth, actualHeight } = props;
  const staticMode = mode !== "run";

  const { dependent, independent, showAttribute, showTangoDB } = inputs;
  const independentName =
    mode === "library" ? "attribute 1" : fullName(independent, showAttribute, showTangoDB);
  const dependentName =
    mode === "library" ? "attribute 2" : fullName(dependent, showAttribute, showTangoDB);

  const defaultRange = mode !== "run" ? { range: [-5, 5] } : {};

  const layout = {
    font: { family: "Helvetica, Arial, sans-serif" },
    margin: {
      l: 45,
      r: 15,
      t: 15,
      b: 35,
    },
    autosize: true,
    hovermode: "closest",
    xaxis: {
      title: independentName,
      titlefont: { size: 12 },
      ...defaultRange,
    },
    yaxis: {
      title: dependentName,
      titlefont: { size: 12 },
      ...defaultRange,
    },
  };
  return (
    <AttributeScatterValues
      dependent={dependent}
      independent={independent}
      mode={mode}
      layout={layout}
      config={{ staticPlot: staticMode === true }}
      responsive={true}
      style={{
        width: actualWidth,
        height: mode === "library" ? 150 : actualHeight,
      }}
    />
  );
}
const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTE_SCATTER",
  name: "Attribute Scatter",
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
      ],
    },
    independent: {
      label: "Independent Attribute",
      type: "attribute",
      required: true,
      dataFormat: "scalar",
      dataType: "numeric",
    },
    dependent: {
      label: "Dependent Attribute",
      type: "attribute",
      required: true,
      dataFormat: "scalar",
      dataType: "numeric",
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    }
  },
};
const AttributeScatterExport = { definition, component: AttributeScatter };
export default AttributeScatterExport;
