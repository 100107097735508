import React, { useEffect, useState } from "react";
import TangoAPI from "../../../../shared/api/tangoAPI";

import {
  WidgetDefinition,
  DeviceInputDefinition,
  StyleInputDefinition,
  BooleanInputDefinition,
} from "../../../types";

import "./MacroButton.css";
import { StateIndicatorLabel } from "../../../../shared/ui/components/StateIndicatorLabel";
import { WidgetProps } from "../../types";
import { DeviceConsumer } from "../../../components/DevicesProvider";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import cx from "classnames";
import { useSelector } from "react-redux";
import { getAttributeLastValueFromState } from "../../../../shared/utils/getLastValueHelper";
import { IRootState } from "../../../../shared/state/reducers/rootReducer";
import { parseCss } from "../../../components/Inspector/StyleSelector";
import { splitFullPath } from "../../../DBHelper";


type Inputs = {
  door: DeviceInputDefinition;
  macroserver: DeviceInputDefinition;
  pool: DeviceInputDefinition;
  outerDivCss: StyleInputDefinition;
  alignButtonRight: BooleanInputDefinition;
};

interface MacroArg {
  name: string;
  value: string;
  description: string;
  type: string; //Moveable, Float etc.
}

interface Macro {
  name: string;
  description: string;
}

interface TangoExecutionResult {
  ok: boolean;
  output: string[];
  message: string;
}

type Props = WidgetProps<Inputs>;
let outputMessage: string[] = [];
const MacroButton: React.FC<Props> = (props) => {
  const [selected_macro, set_selected_macro] = useState<Macro>({
    description: "",
    name: "",
  });
  const [macroArg, setMacroArg] = useState<string>("");
  const [selected_macro_args, set_selected_macro_args] = useState<MacroArg[]>([]);

  useEffect(() => {
    if (props.mode === "edit") {
      set_selected_macro_args([
        {
          name: "testMacro",
          value: "macro",
          description: "Taranta test macro",
          type: "Movable",
        },
      ]);
    } else {
      set_selected_macro_args([]);
    }
  }, [props.mode]);

  let stateValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      props.inputs.door.name,
      "state"
    );
  });

  let motorList = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      props.inputs.pool.name,
      "motorlist"
    );
  });

  let macroList = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      props.inputs.macroserver.name,
      "macrolist"
    );
  });

  let outputValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      props.inputs.door.name,
      "output"
    );
  });
  useEffect(() => {
    if (stateValue === "RUNNING") {
      outputMessage = [...outputMessage, outputValue];
    } else outputMessage = [];
  }, [outputValue, stateValue]);

  const onChangeMacroArgValue = (event: any) => {
    const { value, name } = event.target;
    const macro_args = selected_macro_args;
    macro_args[macro_args.findIndex((obj) => obj.name === name)].value = value;

    // Concatinate all macro_args and display in readonly field
    let macro_arg_string = "";
    macro_args.forEach((arg: MacroArg) => {
      macro_arg_string += arg.value + " ";
    });
    macro_arg_string.trim();

    set_selected_macro_args(macro_args);
    setMacroArg(macro_arg_string);
  };

  const setMacro = (event: any, tangoDB: string) => {
    const selectedMacro = {
      description: "",
      name: event.target.value
    };
    set_selected_macro(selectedMacro);
    executeCommand("macroserver", "GetMacroInfo", selectedMacro.name)
  };

  const handleMacro = (tangoDB: string, device: string, action: string) => {
    executeCommand(device, action);
  };

  const getOutputError = () => {
    const executeAsync = async () => {
      const { door } = props.inputs;
      const [tangoDB, doorDev] = splitFullPath(door.name)
  
      if (door) {
        const error = await TangoAPI.fetchAttributesValues(tangoDB, [
          doorDev + "/Error",
        ]);
        return error[0].value ? error[0].value[0] : null;
      } else {
        alert("No door device");
      } 
    }

    return executeAsync();
  }

  async function executeCommand(
    device: string,
    action: string,
    selectedMacroName = "",
  ) {
    let currentMacroName = selectedMacroName;
    if (currentMacroName === "" && selected_macro.name) {
      currentMacroName = selected_macro.name;
    }
    const { door, macroserver } = props.inputs;
    const argument =
      action === "RunMacro"
        ? Array(currentMacroName).concat(
            macroArg.split(" ")
          )
        : action === "GetMacroInfo"
        ? [currentMacroName]
        : null;

    const deviceName = device === "door" ? door.name : macroserver.name;
    const selectedMacroArgs: MacroArg[] = [];

    const [tangoDBCurrent, devName] = splitFullPath(deviceName);

    const executionResult: TangoExecutionResult | null = await TangoAPI.executeCommand(
      tangoDBCurrent,
      devName,
      action,
      argument
    );
    let alertText: string | null = null;

    if (device === "macroserver" && executionResult) {
      let parameters = JSON.parse(executionResult.output[0]);
      for (let i = 0; i < parameters.parameters.length; i++) {
        let macro_arg_item: MacroArg = {
          description: parameters.parameters[i].description,
          name: parameters.parameters[i].name,
          value: "",
          type: parameters.parameters[i].type,
        };
        selectedMacroArgs.push(macro_arg_item);
      }

      set_selected_macro_args(selectedMacroArgs);
      set_selected_macro({
        description: parameters.description.replace(/\s\s+/g, " "),
        name: currentMacroName,
      });
    } else {
      // output error is only for door device
      const error = await getOutputError();
      if (error) {
        alertText = error;
      }
    }

    if (!executionResult || !executionResult.ok) {
      alertText = `There was a problem executing ${action}`;
    }
    alertText && alert(alertText);
  };

  const { inputs, mode } = props;
  const { door, outerDivCss, alignButtonRight } = inputs;

  let outerDivCssObj = parseCss(outerDivCss).data;

  let name = "";
  let alias = "";

  if (door) {
    name = door.name;
    alias = door.alias;
  }

  return (
    <div className="macro-button" style={outerDivCssObj}>
      <div className="door-row">
        <Container>
          <Row>
            <Col xs={5}>
              <StateIndicatorLabel
                state={mode === "run" ? stateValue : "UNKNOWN"}
              />
              <span className="device-alias">{alias || name || "Device"}</span>
            </Col>
            <Col xs={1}>
              <Tooltip
                id="my-tooltip"
                content={`(${selected_macro.name}) ${selected_macro.description}`}
                style={{
                  marginLeft: "1.2rem",
                  width: "auto",
                  display: selected_macro.description === "" ? "none" : "block",
                  direction: "ltr",
                }}
              >
                <i className={cx("DescriptionDisplay fa fa-info-circle")} />
              </Tooltip>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={6}>
              <select
                className="form-control macro-list-dropdown full-width"
                disabled={["library", "edit"].includes(props.mode)}
                style={{
                  display: ["library", "edit"].includes(props.mode)
                    ? "block"
                    : "none",
                }}
              >
                <option value="" disabled>
                  {macroList === undefined
                    ? "No Macros available"
                    : "Select Macro"}
                </option>
              </select>
            </Col>

            <Col xs={6}>
              <input
                type="text"
                className="form-control full-width"
                style={{
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                  display: ["library", "edit"].includes(props.mode)
                    ? "block"
                    : "none",
                }}
                placeholder={"Macro Args (separated by whitespace)"}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "0.5rem" }}>
            <Col xs={6} className={alignButtonRight ? "ml-auto" : ""}>
              <Button
                className="full-width form-control"
                style={{
                  width: "100%",
                  display: ["library", "edit"].includes(props.mode)
                    ? "block"
                    : "none",
                }}
                size="lg"
                variant="success"
              >
                Run
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="door-row">
          <div className="input-group">
            {mode === "run" && (
              <>
                <DeviceConsumer>
                  {({ tangoDB }) => (
                    <>
                      <Container>
                        <Row>
                          <Col xs={6}>
                            <select
                              id="macroListSelect"
                              className="form-control macro-list-dropdown"
                              value={selected_macro.name}
                              disabled={"library" === props.mode}
                              onChange={(e) => setMacro(e, tangoDB)}
                            >
                              <option value="" disabled>
                                {macroList === undefined
                                  ? "No Macros available"
                                  : "Select Macro"}
                              </option>
                              {macroList &&
                                macroList.map(
                                  (macroItem: string, index: number) => {
                                    return (
                                      <option key={index} value={macroItem}>
                                        {macroItem}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </Col>
                          <Col xs={6}>
                            <input
                              type="text"
                              className="form-control"
                              style={{
                                borderTopLeftRadius: 4,
                                borderBottomLeftRadius: 4,
                              }}
                              value={macroArg}
                              readOnly={true}
                              placeholder={"Macro Args string will appear here"}
                            />
                          </Col>
                        </Row>
                      </Container>

                      <Container>
                        <>
                          <Row
                            style={{
                              display: `${
                                selected_macro_args.length === 0
                                  ? "none"
                                  : "flex"
                              }`,
                            }}
                          >
                            {selected_macro_args.map(
                              (arg: MacroArg, index: number) => {
                                return arg.type === "Moveable" ? (
                                  <Col sm={4} key={index}>
                                    <select
                                      id="movableMacroSelect"
                                      key={index}
                                      className="form-control"
                                      name={arg.name}
                                      value={arg.value}
                                      onChange={onChangeMacroArgValue}
                                      style={{ marginTop: "0.5rem" }}
                                    >
                                      <option value="" disabled>
                                        {motorList.length === 0
                                          ? "No Motors available"
                                          : "Select Motor"}
                                      </option>
                                      {motorList.map(
                                        (motor: string, index: number) => {
                                          const motorName = JSON.parse(motor).name;
                                          return (
                                            <option key={index} value={motorName}>
                                              {motorName}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </Col>
                                ) : (
                                  <Col sm={4} key={index}>
                                    <input
                                      key={index}
                                      type="text"
                                      className="form-control"
                                      style={{
                                        borderTopLeftRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        marginTop: "0.5rem",
                                      }}
                                      placeholder={arg.name}
                                      onChange={onChangeMacroArgValue}
                                      name={arg.name}
                                      value={arg.value}
                                    />
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                          <Row style={{ marginTop: "0.5rem" }}>
                            <Col
                              sm={4}
                              className={alignButtonRight ? "ml-auto" : ""}
                            >
                              <Button
                                id="executeButton"
                                className="full-width form-control"
                                onClick={() =>
                                  handleMacro(
                                    tangoDB,
                                    "door",
                                    stateValue === "RUNNING"
                                      ? "StopMacro"
                                      : "RunMacro"
                                  )
                                }
                                style={{ width: "100%" }}
                                // disabled={selected_macro.name === ""}
                                size="lg"
                                variant={
                                  stateValue === "RUNNING"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {stateValue === "RUNNING" ? "Stop" : "Run"}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      </Container>
                    </>
                  )}
                </DeviceConsumer>
                <br />
              </>
            )}
          </div>
          <div className="result-section">
            <table className="result-table">
              <tbody>
                {outputMessage ? (
                  outputMessage.length === 0 ? (
                    <tr>
                      <td style={{ color: "grey", marginBottom: "0" }}>
                        Door output will be displayed here
                      </td>
                    </tr>
                  ) : (
                    outputMessage.map(
                      (singleStringRow: string, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              <pre>{singleStringRow}</pre>
                            </td>
                          </tr>
                        );
                      }
                    )
                  )
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const definition: WidgetDefinition<Inputs> = {
  type: "MACROBUTTON",
  name: "Macro Button",
  defaultHeight: 12,
  defaultWidth: 32,
  inputs: {
    door: {
      type: "device",
      label: "Door",
      publish: "$door"
    },
    macroserver: {
      type: "device",
      label: "MacroServer",
      publish: "$macroserver"
    },
    pool: {
      type: "device",
      label: "Pool",
      publish: "$pool"
    },
    alignButtonRight: {
      type: "boolean",
      label: "Align the Run/Stop Button to Right",
      default: false,
    },
    outerDivCss: {
      type: "style",
      label: "Outer Div CSS"
    },
  }
};

const MacroButtonExport = { definition, component: MacroButton };
export default MacroButtonExport;
