import React, { Component } from "react";
import AttributeHeatMapValues from "./AttributeHeatMapValues";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  AttributeInputDefinition,
  NumberInputDefinition,
  SelectInputDefinition,
  AttributeInput,
} from "../../types";
import { showHideTangoDBName } from "../../DBHelper";


type Inputs = {
  attribute: AttributeInputDefinition;
  xAxis: AttributeInputDefinition;
  yAxis: AttributeInputDefinition;
  showTitle: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  selectAxisAttribute: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
  fixedScale: BooleanInputDefinition;
  maxValue: NumberInputDefinition;
  minValue: NumberInputDefinition;
};

interface State {
  time?: any;
  heatmapInvalid: boolean;
}

type Props = WidgetProps<Inputs>;

class AttributeHeatMap extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      time: null,
      heatmapInvalid: false,
    };
  }

  private rendered: boolean = true;
  private interval: any = null;

  componentDidMount() {
    if (process.env.REACT_APP_REFRESHING_RATE) {
      this.interval = setInterval(
        () => this.setState({ time: new Date() }),
        parseInt(process.env.REACT_APP_REFRESHING_RATE)
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  shouldComponentUpdate(_, nextState) {
    if (this.rendered) {
      if (this.interval) {
        if (this.state.time !== nextState.time) {
          this.rendered = false;
          return true;
        }

        return false;
      }
      this.rendered = false;
      return true;
    }

    return false;
  }

  private getTitle() {
    const { mode, inputs } = this.props;
    const { showTitle, attribute, showAttribute, showTangoDB } = inputs;

    const display = this.getDisplay(attribute, showAttribute);

    const deviceName = showHideTangoDBName(true, showTangoDB, attribute.device);
    return !showTitle
      ? null
      : mode === "library"
      ? "device/attribute"
      : `${deviceName || "?"}/${display}`;
  }

  private getLayout() {
    const { showTitle } = this.props.inputs;
    const title = this.getTitle();

    return {
      title,
      titlefont: { size: 12 },
      font: { family: "Helvetica, Arial, sans-serif" },
      margin: {
        l: 30,
        r: 15,
        t: 15 + (showTitle ? 20 : 0),
        b: 20,
      },
      autosize: true,
    };
  }

  private getDisplay(attribute: AttributeInput, showAttribute: string): string {
    let display = "";
    if (showAttribute === "Label") {
      if (attribute.label !== "") display = attribute.label;
      else display = "attributeLabel";
    } else if (showAttribute === "Name") {
      if (attribute.attribute !== "") display = attribute.attribute;
      else display = "attributeName";
    } else {
      display = "";
    }
    return display;
  }

  public render() {
    const { mode } = this.props;
    return (
      <>
        {mode === "run" && this.state.heatmapInvalid && (
          <div style={{ color: "deeppink" }}>*Array indices do not match</div>
        )}
        <AttributeHeatMapValues
          setState={this.setState.bind(this)}
          mode={this.props.mode}
          attribute={this.props.inputs.attribute}
          xAxis={this.props.inputs.xAxis}
          yAxis={this.props.inputs.yAxis}
          fixedScale={this.props.inputs.fixedScale}
          minValue={this.props.inputs.minValue}
          maxValue={this.props.inputs.maxValue}
          defaultMinValue={definition.inputs.minValue.default as number}
          defaultMaxValue={definition.inputs.maxValue.default as number}
          selectAxisAttribute={this.props.inputs.selectAxisAttribute}
          layout={this.getLayout()}
          config={{ staticPlot: true }}
          responsive={true}
          style={{
            width: this.props.actualWidth,
            height: mode === "library" ? 150 : this.props.actualHeight,
          }}
          onAfterPlot={() => (this.rendered = true)}
        />
      </>
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTEHEATMAP",
  name: "AttributeHeatMap",
  historyLimit: 1,
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    attribute: {
      label: "",
      type: "attribute",
      dataFormat: "image",
      required: true,
    },
    selectAxisAttribute: {
      type: "boolean",
      label: "Select axis attribute",
      default: false,
    },
    xAxis: {
      label: "x-axis",
      type: "attribute",
      dataFormat: "spectrum",
      required: false,
    },
    yAxis: {
      label: "y-axis",
      type: "attribute",
      dataFormat: "spectrum",
      required: false,
    },
    showTitle: {
      type: "boolean",
      label: "Show Title",
      default: true,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
        {
          name: "None",
          value: "None",
        },
      ],
    },
    fixedScale: {
      type: "boolean",
      label: "Fixed scale",
      default: true,
    },
    maxValue: {
      label: "Max scale",
      type: "number",
      default: 30,
      nonNegative: false,
    },
    minValue: {
      label: "Min scale",
      type: "number",
      default: 1,
      nonNegative: false,
    },
  },
};

const AttributeHeatMapExport = { component: AttributeHeatMap, definition };
export default AttributeHeatMapExport;
