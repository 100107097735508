import React, { Component, ChangeEvent } from "react";
import TangoAPI from "../../../shared/api/tangoAPI";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { connect } from "react-redux";
import { getTangoDBName } from "../../../shared/state/selectors/database";

import {
  WidgetDefinition,
  StringInputDefinition,
  VariableInputDefinition,
  Dashboard,
  Variable,
  SelectedDashboard
} from "../../types";
import { getDashboards, getSelectedDashboard } from "../../../shared/state/selectors";
import { getDashboardVariables } from "../../../shared/utils/DashboardVariables";
import { saveDashboard } from "../../../shared/state/actions/actionCreators";
import { CHANGE_SUBSCRIPTION } from "../../../shared/state/actions/actionTypes";

type Inputs = {
  name: StringInputDefinition;
  variable: VariableInputDefinition;
};

type Props = {
  selectedDashboard: SelectedDashboard;
  dashboards: Dashboard[];
  tangoDB: string;
  inputs: Inputs;
  mode: string;
  saveDashboard: (
    id: string,
    name: string,
    widgets: any,
    variables: Variable[],
    environment: string[]
  ) => void;
  changeSubscription: () => void;
  updateState: (obj) => void;
};

interface TangoClassWithDevices {
  name: string;
  devices: any;
  defaultDevice: string;
  variableName: string;
}

interface State {
  tangoClasses: any;
  tangoClassWithDevices: TangoClassWithDevices;
}

class Parametric extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      tangoClasses: [],
      tangoClassWithDevices: {
        defaultDevice: "",
        devices: [],
        name: "",
        variableName: ""
      }
    };
  }

  /**
   * This function updated device change in mongoDB & redux store,
   * this will result in updating subscribed widgets
   */
  changeDevice(e: ChangeEvent<HTMLSelectElement>) {
    const variableName = this.props.inputs.variable.toString();
    const variables: Variable[] = getDashboardVariables(
      this.props.selectedDashboard.id,
      this.props.dashboards
    );

    variables.forEach(variable => {
      if (variable.name === variableName) {
        variable.device = e.target.value;
      }
    });

    this.setState({
      tangoClassWithDevices: {
        ...this.state.tangoClassWithDevices,
        defaultDevice: e.target.value
      }
    });

    this.props.saveDashboard(
      this.props.selectedDashboard.id,
      this.props.selectedDashboard.name,
      Object.values(this.props.selectedDashboard.widgets),
      variables,
      this.props.selectedDashboard.environment
    );
    // This will force the RunCanvas to fully update
    // This will reflect the device change of variable selector to subscribed widgets
    this.props.updateState({hasInitialized: false});
    // Rendering all the widgets on it, this should be fixed on the refactor to 2.0.0
    this.props.changeSubscription();
  }

  public render() {
    const { inputs } = this.props;
    const { variable } = inputs;
    const name = inputs.name || inputs.variable;
    this.fetchDevices(variable ? variable.toString() : "");

    return (
      <div>
        <span>{name?.toString()}</span>
        <select
          className="form-control parametric-dropdown"
          style={{ marginTop: "0px" }}
          value={this.state.tangoClassWithDevices.defaultDevice}
          disabled={"library" === this.props.mode}
          onChange={e => this.changeDevice(e)}
        >
          {this.state.tangoClassWithDevices.devices.length === 0 && (
            <option key="" disabled value="">
              No device found
            </option>
          )}
          {this.state.tangoClassWithDevices.devices.length > 0 &&
            this.state.tangoClassWithDevices.devices.map(device => {
              return <option key={device.name}>{device.name}</option>;
            })}
        </select>
      </div>
    );
  }

  async fetchDevices(variableName: string) {
    /*
    Before:
      1. The widget initially fetch all the classes and their devices
         In the render function:
          2. It extracts the selected variables from dashboard variables
          3. use class from that variable to filter out the devices

    After:
      It does not have to fetch all classes and their devices initially
      In the render function:
        If there is a variable:
          It extracts that variable from dashboard variables
          calls TangoAPI.fetchClassAndDevices (this function returns devices against provided class)
          sets the devices in the state
          the render function uses state.tangoClassWithDevices.devices to display available devices in dropdown
    */
    if (
      !variableName ||
      this.state.tangoClassWithDevices.variableName === variableName
    ) {
      return;
    }
    const variables: Variable[] = getDashboardVariables(
      this.props.selectedDashboard.id,
      this.props.dashboards
    );
    if (variables.length === 0) {
      // There are no dashboard variables
      return;
    }

    const variable: Variable = variables.filter(
      variable => variable.name === variableName
    )[0];
    const { tangoDB } = this.props;
    const classAndDevices = await TangoAPI.fetchClassAndDevices(
      tangoDB,
      variable?.class
    );
    const ret = {
      name: variable?.class,
      devices: classAndDevices.length > 0 ? classAndDevices[0].devices : [],
      defaultDevice: variable?.device,
      variableName: variableName
    };

    this.setState({ tangoClassWithDevices: ret });
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "PARAMETRIC_WIDGET",
  name: "Variable Selector",
  defaultHeight: 4,
  defaultWidth: 10,
  inputs: {
    name: {
      type: "string",
      label: "Name:",
      default: "Name",
      placeholder: "Name of variable",
      required: true
    },
    variable: {
      type: "variable",
      label: "Variable:"
    }
  }
};

function mapStateToProps(state: IRootState) {
  return {
    selectedDashboard: getSelectedDashboard(state),
    dashboards: getDashboards(state),
    tangoDB: getTangoDBName(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveDashboard: (
      id: string,
      name: string,
      widgets: any,
      variables: Variable[],
      environment: string[]
    ) => dispatch(saveDashboard(id, name, widgets, variables, environment)),
    changeSubscription: () => dispatch({
      type: CHANGE_SUBSCRIPTION,
    }),
  };
}
export const ParametricWidget = connect(mapStateToProps, mapDispatchToProps)(Parametric);

const ParametricWidgetExport = { component: ParametricWidget, definition };
export default ParametricWidgetExport;