import React from 'react';
import { IRootState } from '../../../shared/state/reducers/rootReducer';
import { useSelector } from 'react-redux';
import {
  getAttributeLastTimeStampFromState,
  getAttributeLastValueFromState,
} from '../../../shared/utils/getLastValueHelper';

interface Props {
    deviceName: string;
    attributeName: string;
    emledSize: string;
    trueColor: string;
    falseColor: string;
    minMargin: string;
    textSize: number;
    compare: string;
    relation: string;
    showAttributeValue: boolean;
  }

  const AttributeLEDValues: React.FC<Props> = ({
    attributeName,
    deviceName,
    emledSize,
    trueColor,
    falseColor,
    minMargin, 
    textSize, 
    compare, 
    relation, 
    showAttributeValue
  }) => {

    const checkCondition = (value, compare, relation) : any => {
;
          const numericCompare = Number(compare);
          
          if (isNaN(numericCompare)) {
            if (relation === "=") {
                return String(value) === compare;
              }
            else {
                return null;
              }
          }
          else {
           value = Number(value);
            switch (relation) {
              case ">":
                return value > numericCompare;
              case "<":
                return value < numericCompare;
              case "=":
                return value === numericCompare;
              case ">=":
                return value >= numericCompare;
              case "<=":
                return value <= numericCompare;
              default:
                break;
            }
          }
      };
  
    const value = useSelector((state: IRootState) => {
      return getAttributeLastValueFromState(state.messages, deviceName, attributeName);
    });
  
    const timestamp = useSelector((state: IRootState) => {
      return getAttributeLastTimeStampFromState(state.messages, deviceName, attributeName)?.toString();
    });

    const condition = checkCondition(value, compare, relation);

    // if condition is null, color the LED to white (blank)
    let fillColor = "#ffffff";
    // black color for the value text
    let textColor = "#000000";
    // otherwise apply the color specified
    if (condition !== null) {
        fillColor = condition ? trueColor : falseColor;
      }
    
    let renderValue = "..."
    if (value !== undefined)
        if(typeof value === "number")
            renderValue = (Math.round(value * 100) / 100).toFixed(3);
        else if(typeof value === "boolean"){
            if (value === true) {
                renderValue = "true";
            }
            if (value === false) {
                renderValue = "false";
            }
        }
        else
            renderValue = value

    return (
        <span>
            <div
                title = {new Date(timestamp * 1000).toUTCString()}
                className="led"
                style={{
                    color: textColor,
                    backgroundColor: fillColor,
                    width: emledSize,
                    height: emledSize,
                    display: "inline-block",
                    margin: minMargin,
                }}
            />
            {showAttributeValue ? <span style={{ fontSize: textSize + "em" }}>{renderValue}</span> : ''}  
        </span>
    )

  };
  

export default AttributeLEDValues;