import React  from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'react-bootstrap';
import { parseCss } from "../../components/Inspector/StyleSelector"
import { getTangoDB } from "../../../dashboard/dashboardRepo";
import {
    setDeviceAttribute
  } from "../../../shared/state/actions/tango";


interface Props {
    device: string,
    attribute: string,
    selected: { title: string, value: string } | null,
    submitButtonCss: string,  
    variant: string,
    type: string,
    className: string,
    submitButtonTitle: string, 
    isNumeric: boolean, 
    isBoolean: boolean, 
    isEnum: boolean
}

const AttributeWriterButton: React.FC<Props> = ({
    device,
    attribute,
    selected,
    submitButtonCss, 
    variant,
    type,
    className,
    submitButtonTitle, 
    isNumeric, 
    isBoolean, 
    isEnum, 
}) => {

    const dispatch = useDispatch()
    const tangoDB = useSelector(() => {
    return getTangoDB();
    });

    const handleSubmit = () => {
        if (selected === null) return;

        const value = selected.value; 
        if (value === null || ((isNumeric || isBoolean || isEnum) && (value === ""))) {
            return; //we don't want to interpret an emtpy string as a zero or false
        }
        let typeCastedValue: any = value;
        if (isNumeric) {
            typeCastedValue = Number(value);

        } else if (isBoolean) {
            if (value.toLowerCase() === "false" || value.toLowerCase() === "0") {
                typeCastedValue = false;
            } else if (value.toLowerCase() === "true" || value.toLowerCase() === "1") {
                typeCastedValue = true;
            } else {
                typeCastedValue = null;
            }
        } else if (isEnum) {
            if (isNaN(parseInt(value))) return;

            typeCastedValue = parseInt(value);
        }

        if (typeof value === "number" && isNaN(value)) {
            return;
        }
        if (typeof value === "boolean" && !typeCastedValue === null) {
            return;
        }

        dispatch(setDeviceAttribute(tangoDB, device, attribute, typeCastedValue)); 
    }

    return(
        <div>
                <Button
                    disabled={selected===null}
                    onClick={() => handleSubmit()} 
                    style={parseCss(submitButtonCss).data} 
                    size="sm"
                    variant={variant}
                    type={type}
                    className={className}
                >
                    {submitButtonTitle || 'Submit'}
                </Button>
        </div>
    )
};


  


export default AttributeWriterButton;