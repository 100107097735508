import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getAttributeValuesFromState,
  getAttributeTimestampFromState,
} from "../../../shared/utils/getValuesHelper";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import Plot, { Trace } from "./Plot";
import { WidgetProps } from "../types";
import { Inputs } from ".";
import collapseAttribute from "../../../shared/utils/CollapseAttribute";

type Props = WidgetProps<Inputs>;
type AttributeEntries = Props["inputs"]["attributes"];

const tangoStates = ["ON", "OFF", "CLOSE", "OPEN", "INSERT", "EXTRACT", "MOVING",
  "STANDBY", "FAULT", "INIT", "RUNNING", "ALARM", "DISABLE", "UNKNOWN"];

interface plotData {
  traces: Trace[];
  params: any;
}


const Timeline: React.FC<Props> = (props) => {
  const [changeViewEnable, setChangeViewEnable] = useState(true);
  const [dataFlow, setDataFlow] = useState(true);
  const [buttonLabel, setButtonLabel] = useState("Pause Data update");
  const [viewLabel, setViewLabel] = useState("Show Event View");
  const [eventView, setEventView] = useState(false);

  const { mode, inputs, t0, actualWidth, actualHeight } = props;
  const { attributes, timeWindow, showTangoDB } = inputs;

  const attributesList = attributes.map((attr) => ({
    device: attr.attribute.device,
    attribute: attr.attribute.attribute,
  }));


  const customEqualValues = (oldValue, newValue) => {
    for (var attribute of attributesList) {
      let pair = attribute?.device + '/' + attribute?.attribute;
      if(oldValue?.[pair]){
        let oldValueIndex = oldValue?.[pair]?.values?.length; 
        let newValueIndex = newValue?.[pair]?.values?.length;
        if (oldValue?.[pair]?.values?.[oldValueIndex - 1] !== newValue?.[pair]?.values?.[newValueIndex-1])
          return false;
      }
    }
    return true;
  }

  const customEqualTimeStamp = (oldValue, newValue) => {
    
    for (var attribute of attributesList) {
      let pair = attribute?.device + '/' + attribute?.attribute;
      if(oldValue?.[pair]){
        let oldTimestampIndex = oldValue?.[pair]?.timestamp?.length; 
        let newTimestampIndex = newValue?.[pair]?.timestamp?.length; 
        if (oldValue?.[pair]?.timestamp?.[oldTimestampIndex - 1] !== newValue?.[pair]?.timestamp?.[newTimestampIndex-1])
          return false;
      }
    }
    return true;
  }


  const values = useSelector((state: IRootState) =>
    getAttributeValuesFromState(state.messages, attributesList), customEqualValues
  );

  const timestamp = useSelector((state: IRootState) =>
    getAttributeTimestampFromState(state.messages, attributesList), customEqualTimeStamp
  );

  const populateData = () => {

    const runParams = {
      width: actualWidth,
      height: actualHeight,
      timeWindow,
      staticMode: false,
    };

    const staticParams = { ...runParams, staticMode: true };
    let plotData: plotData;

    if (mode === "run") {
      const traces = tracesFromAttributeInputs(attributes, t0);
      plotData = { traces: traces, params: runParams };
    } else if (mode === "library") {
      const xValues = Array(timeWindow)
        .fill(0)
        .map((_, i) => i);
      const sample1 = xValues.map(
        (x) => 8 * Math.sin(x / 6) * Math.sin(x / 20)
      );
      const sample2 = xValues.map(
        (x) => 5 * Math.cos(x / 20) * Math.cos(x / 3)
      );
      const traces: Trace[] = [
        {
          fullName: "attribute 1",
          x: xValues,
          y: sample1,
          axisLocation: "left",
          yAxisDisplay: "Label",
          position: 1,
        },
        {
          fullName: "attribute 2",
          x: xValues,
          y: sample2,
          axisLocation: "left",
          yAxisDisplay: "Label",
          position: 2,
        },
      ];

      plotData = { traces: traces, params: { ...staticParams, height: 150 } };
    } else {
      //edit
      const traces = attributes.map((attributeInput) => {
        const { device, attribute, label } = attributeInput.attribute;
        const { showAttribute } = attributeInput;

        let display = "";
        if (showAttribute === "Label") {
          if (label !== "") display = label;
          else display = "attributeLabel";
        } else if (showAttribute === "Name") {
          if (attribute !== null) display = attribute;
          else display = "attributeName";
        }

        const fullName = `${device || "?"}/${display || "?"}`;
        const trace: Trace = {
          fullName,
          yLabels: ["EnumLabels"],
          axisLocation: attributeInput.yAxis,
          yAxisDisplay: attributeInput.yAxisDisplay,
          position: 1
        };
        return trace;
      });

      plotData = {
        traces,
        params: staticParams,
      };
    }

    return plotData;
  }

  const tracesFromAttributeInputs = (
    complexInputs: AttributeEntries,
    t0: number
  ): Trace[] => {
    let i = 1;
    return complexInputs.map((complexInput) => {
      const { attribute: attributeInput, yAxis } = complexInput;
      const { device, attribute } = attributeInput;
      const fullName = `${device}/${attribute}`;

      let x: number[] = [];
      let y: number[] = [];

      let yLabels = complexInput.attribute.enumlabels;

      if (complexInput.attribute.dataType === "DevState") yLabels = tangoStates;

      let pair = `${device}/${attribute}`;
      if (values[pair]) {
        let newValuesLenght: number = timestamp[pair].timestamp.filter(time => time > t0).length;

        x = timestamp[pair].timestamp.slice(newValuesLenght*-1).map(timestamp => timestamp - t0);

        if (complexInput.attribute.dataType === "DevState")
          y = values[pair].values.slice(newValuesLenght*-1).map(value => tangoStates.indexOf(value));
        else
          y = values[pair].values.slice(newValuesLenght*-1);
      }

      let position = i;
      if (props.inputs.groupAttributes) {
        const collapsedArray = collapseAttribute(complexInputs, "enumlabels");

        for (let _i = 0; _i < collapsedArray.length; _i++) {
          if (collapsedArray[_i].indexOf(complexInput) >= 0) {
            position = _i + 1;
          }
        }
      }
      i++;
      return {
        fullName,
        x,
        y,
        yLabels,
        axisLocation: yAxis,
        yAxisDisplay: complexInput.yAxisDisplay,
        position: position,
      };
    });
  };

  const changeDataFlow = () => {
    setDataFlow(!dataFlow);
    setButtonLabel(dataFlow ? "Continue data update" : "Pause data update");
    setChangeViewEnable(!dataFlow);
  };

  const changeView = () => {
    setEventView(!eventView);
    setViewLabel(eventView ? "Show Event View" : "Show Time View");
  };

  const plotData: plotData = populateData();

  return (
    <div className="timeline-wrapper">
      <div className="options">
        <button style={{ borderRadius: "5px" }} onClick={changeDataFlow}>
          {buttonLabel}
        </button>
        <button
          style={{ borderRadius: "5px" }}
          disabled={!changeViewEnable}
          onClick={changeView}
        >
          {viewLabel}
        </button>
      </div>
      <Plot
        traces={plotData.traces}
        params={plotData.params}
        dataFlow={dataFlow}
        eventView={eventView}
        mode={mode}
        showTangoDB={showTangoDB}
      />
    </div>
  );
};

export default Timeline;