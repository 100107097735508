import {
  Widget,
  IndexPath,
  WidgetDefinition,
  InputDefinitionMapping,
  InputMapping,
  InputDefinition,
  DashboardEditHistory,
  Variable
} from "../../../../dashboard/types";
import { defaultInputs } from "../../../../dashboard/utils";
import { getAllInnerWidgetsById, getWidgetsAligned } from "../../../utils/canvas";
import { definitionForWidget } from "../../../../dashboard/widgets";
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath } from "../../../../dashboard/runtime/utils";
import { getTangoDB } from "../../../../dashboard/dashboardRepo";
import { devicePresent, variablePresent } from "../../../utils/DashboardVariables";
import { WIDGET_VALID, WIDGET_INVALID, WIDGET_WARNING, WIDGET_MISSING_DEVICE } from "../../actions/actionTypes";

const config = window['config'];
const HISTORY_SIZE = 100;

export function validateJson(json: string) {

  const acceptedValue = [
    "id",
    "name",
    "version",
    "user",
    "widget",
    "insertTime",
    "updateTime",
    "group",
    "groupWriteAccess",
    "lastUpdatedBy",
    "variables",
    "environment"
  ];
  let validationResult = true;
  let importError: string[] = [];
  let result: validationError;

  try {
    let dashboard = JSON.parse(json)
    //check if required field are in the file
    let name: String = dashboard.name;
    if (name === undefined || name === "") {
      validationResult = false;
      importError.push("Dashboard name missing");
    }

    let user: String = dashboard.user;
    if (user === undefined || user === "") {
      validationResult = false;
      importError.push("User missing");
    }

    let updateTime: String = dashboard.updateTime;
    if (updateTime === undefined || updateTime === "") {
      validationResult = false;
      importError.push("Update time missing");
    }

    let widget = dashboard.widget;
    if (widget === undefined) {
      validationResult = false;
      importError.push("Widgets are missing");
    }

    for (var key in dashboard) {
      if (!acceptedValue.includes(key)) {
        validationResult = false;
        importError.push(key + " not accepted");
      }
    }

    result = validateWidget(dashboard.widget);
    if (result.missingBundle.length > 0) {
      result.missingBundle = result.missingBundle.filter((v, i, a) => a.indexOf(v) === i);
      importError.push('Widget definition not found for ' + result.missingBundle.join(', '));
    }

    if (result.warnWidgets.length > 0) {
      result.warnWidgets = result.warnWidgets.filter((v, i, a) => a.indexOf(v) === i);
      importError.push(config.WARN_WIDGETS_MSG + ' ' + result.warnWidgets.join(', '));
    }
  } catch (e: any) {
    validationResult = false;
    importError.push(e);
    return { validationResult, importError };
  }

  return { validationResult, importError, missingBundleIds: result.missingBundleIds };
}

interface validationError {
  missingBundle: string[],
  missingBundleIds: Number[],
  warnWidgets: string[],
  invalidDefinition: string[],
  isValid: Boolean
}
export function validateWidget(widgets: Widget[]) {
  let validation: validationError = {
    missingBundle: [],
    missingBundleIds: [],
    invalidDefinition: [],
    warnWidgets: [],
    isValid: true
  }
  const warnWidgets:Array<String> = config.WARN_WIDGETS;
  widgets.forEach((widget, i) => {
    try {
      //definitionForWidget -> throws an expection in case of not valid widget
      const definition = definitionForWidget(widget);
      for (const key of Object.keys(definition.inputs)) {
        if (!(key in widget.inputs) && "default" in definition.inputs[key]) {
          validation.invalidDefinition.push(widget.type);
          validation.isValid = false;
        }

        // eslint-disable-next-line no-loop-func
        Object.keys(widget.inputs).forEach(input => {
          if (!definition.inputs[input]) {
            validation.invalidDefinition.push(widget.type);
            validation.isValid = false;
          }
        });
      }

      if (warnWidgets.includes(widget.type)) {
        validation.warnWidgets.push(widget.type);
      }
    } catch (e) {
      validation.missingBundleIds.push(Number(widget.id));
      validation.missingBundle.push(widget.type);
    }
  });

  return validation
}

export function move(widget: Widget, dx: number, dy: number) {
  const { x, y } = widget;
  const targetX = Math.max(0, x + dx);
  const targetY = Math.max(0, y + dy);
  if (widget.innerWidgets) {
    widget.innerWidgets = widget.innerWidgets.map(innerWidget => {
      return move(innerWidget, dx, dy);
    })
  }
  return { ...widget, x: targetX, y: targetY };
}
export function undo(
  history: DashboardEditHistory,
  widgets: Record<string, Widget>
) {
  if (history.undoLength === 0) {
    return { history, widgets };
  }
  //pull latest value from UNDO
  history.undoIndex =
    history.undoIndex === 0 ? HISTORY_SIZE - 1 : history.undoIndex - 1;
  const prevWidgets = history.undoActions[history.undoIndex];
  history.undoLength = history.undoLength === 0 ? 0 : history.undoLength - 1;

  //push latest value from UNDO onto REDO
  history.redoLength =
    history.redoLength === HISTORY_SIZE ? HISTORY_SIZE : history.redoLength + 1;
  history.redoIndex = (history.redoIndex + 1) % HISTORY_SIZE;
  history.redoActions[history.redoIndex] = widgets;
  return { history, widgets: prevWidgets };
}

export function redo(
  history: DashboardEditHistory,
  widgets: Record<string, Widget>
) {
  if (history.redoLength === 0) {
    return { history, widgets };
  }
  //push old widget as new action to UNDO
  history.undoLength =
    history.undoLength === HISTORY_SIZE ? HISTORY_SIZE : history.undoLength + 1;
  history.undoActions[history.undoIndex] = widgets;
  history.undoIndex = (history.undoIndex + 1) % HISTORY_SIZE;

  //pull, update and return from REDO
  const prevWidgets = history.redoActions[history.redoIndex];
  history.redoLength = history.redoLength === 0 ? 0 : history.redoLength - 1;
  history.redoIndex =
    history.redoIndex === 0 ? HISTORY_SIZE - 1 : history.redoIndex - 1;

  return { history, widgets: prevWidgets };
}

export function pushToHistory(
  history: DashboardEditHistory,
  widgets: Record<string, Widget>
) {
  history.undoLength =
    history.undoLength === HISTORY_SIZE ? HISTORY_SIZE : history.undoLength + 1;
  history.undoActions[history.undoIndex] = widgets;
  history.undoIndex = (history.undoIndex + 1) % HISTORY_SIZE;
  //invalidate REDO stack at a regular action
  history.redoLength = 0;
  return history;
}

export function resize(
  widget: Widget,
  mx: number,
  my: number,
  dx: number,
  dy: number
) {
  const moved = move(widget, mx, my);
  const { width, height } = moved;
  return {
    ...moved,
    width: Math.max(2, width + dx),
    height: Math.max(2, height + dy)
  };
}

/**
 * Compares the inputs found in the widget from the database with those found in the widget definition in taranta
 * If an input is found in the database that doesn't exist in the widget definition, this input is removed and a warning is returned
 * If an input is found in the widget definition that doesn't exist in the database widget, taranta creates it and assigns it
 * its default value.
 * @param widgets The widgets of the selected dashboard, exactly as retrieved from the database
 * @returns An object containing
 *  1) the the same widgets with inputs added or deleted to match the input Definition
 *  2) a boolean warning, indicating if the widget inputs had to be altered to comply with the lastest taranta input definitions
 */

export function resolveWidgetCompatibility(widgets: Widget[]): { widgets: Widget[], warning: boolean } {
  let warning = false;

  widgets.forEach(widget => {
    const definition = definitionForWidget(widget);
    const defaultInputsForDefinition = defaultInputs(definition.inputs);
    warning = updateWidgetInputs(widget, definition, defaultInputsForDefinition) || warning;
  });

  return { widgets, warning };
}

function updateWidgetInputs(widget: Widget, definition: any, defaultInputsForDefinition: any): boolean {
  let warning = false;
  const newInputs: InputMapping = {};

  for (const key of Object.keys(definition.inputs)) {
    if (!(key in widget.inputs)) {
      widget.inputs[key] = "default" in definition.inputs[key] ? definition.inputs[key].default : defaultInputsForDefinition[key];
      warning = true;
    }
  }

  Object.keys(widget.inputs).forEach(input => {
    if (definition.inputs[input]) {
      newInputs[input] = widget.inputs[input];
      handleInputs(input, widget, newInputs);
    } else {
      warning = true;
    }
  });

  widget.inputs = newInputs;
  return warning;
}

function handleInputs(input: string, widget: Widget, newInputs: InputMapping) {
  if (input === 'attributes') {
    handleAttributesInput(widget, newInputs);
  }

  const devInputs = ['attribute', 'command', 'offCommand', 'onCommand', 'device', 'pool', 'door', 'macroserver', 'xAxis', 'yAxis'];
  devInputs.includes(input) && handleSimpleInput(input, widget, newInputs);
}

function handleAttributesInput(widget: Widget, newInputs: InputMapping) {
  for (let i = 0; i < widget.inputs.attributes.length; i++) {
    const device = widget.inputs.attributes[i].attribute.device;
    const tangoDB = getTangoDbFromPath(device);

    if (tangoDB === "") {
      newInputs.attributes[i].attribute.device = createDeviceWithTangoDBFullPath(getTangoDB(), device);
    }
  }
}

function handleSimpleInput(input: string, widget: Widget, newInputs: InputMapping) {
  const flatInputs = ['device', 'pool', 'door', 'macroserver'];

  let device = flatInputs.includes(input) ? widget.inputs[input] : widget.inputs[input].device;
  const tangoDB = getTangoDbFromPath(device);

  if (tangoDB === "") {
    if (flatInputs.includes(input)) {
      if (device) {
        const newDevName = createDeviceWithTangoDBFullPath(getTangoDB(), device);
        newInputs[input] = newDevName;
      }
    } else {
      newInputs[input].device = device;
    }
  }
}

export function validate(widget: Widget, variables: Variable[] = [], deviceList: string[] = []) {
  let valid = WIDGET_VALID;
  switch(true) {
    default:
      //converting bool to integer val using unary + operator
      const definition = definitionForWidget(widget);
      valid = +(inputsAreValid(definition!.inputs, widget.inputs));
      if (WIDGET_INVALID === valid) {
        valid = WIDGET_INVALID;
        break;
      }

      const checkVar = variablePresent(widget, variables);
      if (!checkVar.found) {
        valid = WIDGET_WARNING;
        break;
      }

      const checkDevice = devicePresent(widget, deviceList);
      if (!checkDevice.found) {
        valid = WIDGET_MISSING_DEVICE;
        break;
      }
  }

  return { ...widget, valid };
}

export function setInput(widget: Widget, path: IndexPath, value: any, widgetType: string) {
  const oldInputs = widget.inputs;

  const widgetDefinition = definitionForWidget(widget);
  const widgetDefinitionName = path[0];
  const widgetInputName = path[2];

  if ('complex' === widgetDefinition.inputs[widgetDefinitionName].type) {
    if ('radio' === widgetDefinition.inputs[widgetDefinitionName].inputs[widgetInputName].type) {
      // Reset all radio buttons in the complex input list
      widget.inputs[widgetDefinitionName].map(obj => {
        obj[widgetInputName] = false;
        return true;
      })
    }
  }

  const widgetsWithMultipleCommands = ['CONTAINER_FOR_DEVICE'];

  let newInputs;
  if (widgetsWithMultipleCommands.indexOf(widgetType) > -1) {
    newInputs = setWithIndexPath(oldInputs, path, value, MULTIPLE_COMMANDS);
  } else {
    newInputs = setWithIndexPath(oldInputs, path, value, REPLACE);
  }

  return { ...widget, inputs: newInputs };
}

export function deleteInput(widget: Widget, path: IndexPath) {
  const oldInputs = widget.inputs;
  const newInputs = setWithIndexPath(oldInputs, path, null, DELETE);
  return { ...widget, inputs: newInputs };
}

export function addInput(widget: Widget, path: IndexPath, value: any) {
  const oldInputs = widget.inputs;
  const newInputs = setWithIndexPath(oldInputs, path, value, ADD);
  return { ...widget, inputs: newInputs };
}

export function removeWidget(widgets, deleteWidgetId: Widget) {
  widgets.forEach((widget, k) => {

    if (deleteWidgetId === widget.id) {
      widgets.splice(k, 1);

    } else if ("BOX" === widget.type && widget.innerWidgets && widget.innerWidgets.length > 0) {
      let widgetCount = widget.innerWidgets.length;
      removeWidget(widget.innerWidgets, deleteWidgetId);

      if (widgetCount !== widget.innerWidgets.length)
        widget = getWidgetsAligned([widget], config.MIN_WIDGET_SIZE)[0];
    }
  });
}

export function duplicateWidgets(state, newWidgets: Record<string, Widget>, newIds) {
  let newId = parseInt(nextId(state.widgets));
  let newOrderIndex = nextOrderIndex(state.widgets);

  let widgets: Record<string, Widget> = getAllInnerWidgetsById(state.widgets, true);

  state.selectedIds.forEach(id => {
    const newWidgetToClone = Object.assign({}, Object.values(widgets).filter(widget => (widget.id === id))[0]);
    const newWidget = JSON.parse(JSON.stringify(newWidgetToClone));
    newWidget.x += 1;
    newWidget.y += 1;
    newWidget.id = newId.toString();
    newWidget.order = newOrderIndex;
    newIds.push(newId.toString());

    newWidgets[newId.toString()] = newWidget;

    let populateInnerWidgets = function (newWidget, newId) {
      if ("BOX" === newWidget.type && newWidget.innerWidgets) {
        newWidget.innerWidgets.forEach(innerWidget => {
          newId++
          innerWidget.id = newId.toString();
          if ("BOX" === innerWidget.type && innerWidget.innerWidgets) {
            populateInnerWidgets(innerWidget, newId);
          }
        })
      }
    }

    populateInnerWidgets(newWidget, newId);

    if ("BOX" === newWidget.type && newWidget.innerWidgets) {
      newWidget.innerWidgets.forEach(innerWidget => {
        newId++
        innerWidget.id = newId.toString();
      })
    }

    delete newWidget._id;
    newId++;
    newOrderIndex++;
  });
}

export function updateWidget(widgetsArr: Widget[], updatedWidget: Widget) {
  if (widgetsArr) {
    for (let i = 0; i < widgetsArr.length; i++) {

      if (widgetsArr[i].id === updatedWidget.id) {
        widgetsArr[i] = updatedWidget;
        break;

      } else if (widgetsArr[i].innerWidgets && widgetsArr[i].innerWidgets?.length) {
        updateWidget(widgetsArr[i].innerWidgets || [], updatedWidget);
      }
    }
  }
}

const DELETE = Symbol("DELETE");
const ADD = Symbol("ADD");
const REPLACE = Symbol("REPLACE");
const MULTIPLE_COMMANDS = Symbol("MULTIPLE_COMMANDS");

type Mode = typeof DELETE | typeof ADD | typeof REPLACE | typeof MULTIPLE_COMMANDS;

function handleMultipleCommands(
  obj: object,
  head: string | number,
  value: any,
  replacement: any,
) {
  let values: string[] = [];

  if (typeof obj[head].command === "string") {
    values.push(obj[head].command);
  } else if (obj[head].command && obj[head].command.length > 0) {
    values = [...obj[head].command];
  }

  if (value.command) {
    if (values.indexOf(value.command) === -1) {
      values.push(value.command);
    } else {
      values = values.filter((val) => val !== value.command);
    }
  }

  replacement.command = values;

  return replacement;
}
export function setWithIndexPath(
  obj: object,
  path: IndexPath,
  value: any,
  mode: Mode
) {
  const [head, ...tail] = path;
  let replacement =
    tail.length > 0 ? setWithIndexPath(obj[head], tail, value, mode) : value;

  if (Array.isArray(obj)) {
    const copy = obj.concat();
    if (typeof head !== "number") {
      throw new Error("head must be an integer when obj is an array");
    } else {
      if (mode === DELETE) {
        copy.splice(head, 1);
      } else if (mode === REPLACE) {
        copy.splice(head, 1, replacement);
      } else if (mode === ADD) {
        copy.splice(0, 0, replacement);
      }
    }
    return copy;
  } else {
    if (mode === MULTIPLE_COMMANDS && head === "command") {
      replacement = handleMultipleCommands(obj, head, value, replacement);
    }

    return { ...obj, [head]: replacement };
  }
}

export function defaultDimensions(
  definition: WidgetDefinition<{}>
): { width: number; height: number } {
  const { defaultWidth: width, defaultHeight: height } = definition;
  return { width, height };
}

export function nestedDefault(
  definition: WidgetDefinition<{}>,
  path: IndexPath
) {
  const initial = { inputs: definition.inputs };
  const leaf = path.reduce((accum, segment): {
    inputs: InputDefinitionMapping;
  } => {
    const input = accum.inputs[segment];
    if (typeof segment === "number") {
      return accum;
    } else if (input.type === "complex") {
      return input;
    } else {
      throw new Error("only complex inputs can be traversed");
    }
  }, initial);
  return defaultInputs(leaf.inputs);
}

// TODO: cover more validation cases
function inputIsValid(definition: InputDefinition, value: any): boolean {
  if (definition.type === "complex") {
    if (definition.repeat) {
      return value
        .map(input => inputIsValid({ ...definition, repeat: false }, input))
        .reduce((prev, curr) => prev && curr, true);
    } else {
      const inputNames = Object.keys(definition.inputs);
      return inputNames
        .map(name => inputIsValid(definition.inputs[name], value[name]))
        .reduce((prev, curr) => prev && curr, true);
    }
  }

  if (definition.type === "attribute") {
    const resolvedDevice = value.device || definition.device;
    const resolvedAttribute = value.attribute || definition.attribute;
    if (definition.required === false) {
      return true;
    }
    return resolvedDevice != null && resolvedAttribute != null;
  }

  if (definition.type === "command") {
    const resolvedDevice = value.device || definition.device;
    const resolvedCommand = value.command || definition.command;
    return resolvedDevice != null && resolvedCommand != null;
  }

  if (definition.type === "number") {
    if (isNaN(value)) {
      return false;
    }

    if (definition.nonNegative) {
      return value >= 0;
    }
  }

  if (definition.type === "device") {
    return value != null;
  }

  return true;
}

export function inputsAreValid(
  definition: InputDefinitionMapping,
  inputs: InputMapping
): boolean {
  const inputNames = Object.keys(definition);
  const results = inputNames.map(name => {
    const inputDefinition = definition[name];
    const input = inputs[name];
    return inputIsValid(inputDefinition, input);
  });

  return results.reduce((prev, curr) => prev && curr, true);
}

export function nextId(widgets: Record<string, Widget>): string {
  const allWidgets = getAllInnerWidgetsById({ ...widgets });

  const ids = Object.keys(allWidgets).map(id => parseInt(id, 10));
  const highest = ids.reduce((max, id) => Math.max(max, id), 0);
  return String(1 + highest);
}

export function nextOrderIndex(widgets: Record<string, Widget>) {
  const allWidgets: Record<string, Widget> = getAllInnerWidgetsById({ ...widgets });
  const orders = Object.values(allWidgets).map(value => {
    if (!value.hasOwnProperty("order")) {
      return -1;
    }
    return value.order;
  });
  return orders.reduce((max, current) => Math.max(max, current), -1) + 1;
}

/**
 * Reassigns all order indexes after the removal of 1 or more layers
 * @param widgets
 */
export function reorderIndex(widgets: Record<string, Widget>) {
  let currentIndex = 0;
  Object.values(widgets)
    .sort((a, b) => a.order - b.order)
    .forEach(widget => {
      widget.order = currentIndex;
      currentIndex++;
    });
  return widgets;
}
/**
 * Given allWidgets, moves the widgets in widgetsToMove 1 step up or down. The location of a widget is determined by widget.order.
 * Returns a copy of allWidgets, with updated order of the widgets. If widgets cannot be moved in the specified direction, returns null
 * @param widgets
 * @param sourceIndex
 * @param up
 */
export function moveOrderIndices(
  allWidgets: Widget[],
  widgetsToMove: Widget[],
  up: boolean
): Widget[] | null {
  //If up, take no action if one of the widgets already is at the top of the list
  if (up && widgetsToMove.find(widget => widget.order === 0)) {
    return null;
  }
  if (!up) {
    //If down, take no action if one of the widgets already is at the bottom of the list
    const maxOrder = allWidgets.reduce(
      (prev, curr) => Math.max(prev, curr.order),
      0
    );
    if (widgetsToMove.find(widget => widget.order === maxOrder)) {
      return null;
    }
  }
  const newWidgets: Widget[] = [];
  const widgetsToMoveIds = widgetsToMove.map(widget => widget.id);
  const assignedOrders: number[] = [];
  widgetsToMove.forEach(widget => {
    const copy = { ...widget };
    copy.order = widget.order + (up ? -1 : 1);
    assignedOrders.push(copy.order);
    newWidgets.push(copy);
  });
  let currNewOrder = 0;
  allWidgets
    .sort((a, b) => a.order - b.order)
    .forEach(widget => {
      if (!widgetsToMoveIds.includes(widget.id)) {
        while (assignedOrders.includes(currNewOrder)) {
          currNewOrder = currNewOrder + 1;
        }
        const copy = { ...widget };
        copy.order = currNewOrder;
        newWidgets.push(copy);
        assignedOrders.push(currNewOrder);
      }
    });
  return newWidgets;
}

export function cloneWidgets(widgetsToClone, widgets, newIds, pasteCounter = 1) {
  let newId = parseInt(nextId(widgets));
  let newOrderIndex = nextOrderIndex(widgets);
  const newWidgets = { ...widgets }

  widgetsToClone.forEach(widget => {
    const newWidget = JSON.parse(JSON.stringify(widget));
    newWidget.x += pasteCounter;
    newWidget.y += pasteCounter;
    newWidget.id = newId.toString();
    newWidget.order = newOrderIndex++;
    newIds.push(newId.toString());

    newWidgets[newId.toString()] = newWidget;

    let populateInnerWidgets = function (newWidget, newId) {
      if ("BOX" === newWidget.type && 0 < newWidget.innerWidgets.length) {
        newWidget.innerWidgets.forEach(innerWidget => {
          newId++
          innerWidget.id = newId.toString();
          innerWidget.order = newOrderIndex++;
          delete innerWidget._id;
          if ("BOX" === innerWidget.type && innerWidget.innerWidgets) {
            populateInnerWidgets(innerWidget, newId);
          }
        })
      }
    }

    populateInnerWidgets(newWidget, newId);
    delete newWidget._id;
    newId++;
  });

  return newWidgets;
}

export function updateDependentInputs(widgets: Widget[]) {

  let newWidgets = Object.values(widgets);
  for (let i = 0; i < newWidgets.length; i++) {
    if ('TABULAR_VIEW' === newWidgets[i]?.type) {
      //Fetch all the devices for tabular view
      let widgetDevices: string;
      const devices = newWidgets[i]?.inputs?.devices?.map(obj => obj.device);
      widgetDevices = devices ? devices.toString() : '';

      for (let j = 0; j < newWidgets[i]?.inputs?.customAttributes?.length; j++) {
        //update the device field on the customAttributes
        if (null !== newWidgets[i]?.inputs?.customAttributes[j]?.attribute?.device
          && widgetDevices !== newWidgets[i]?.inputs?.customAttributes[j]?.attribute?.device
        ) {
          newWidgets[i].inputs.customAttributes[j].attribute.device = widgetDevices;
        }
      }

      // Default Attribute
      if (newWidgets[i]?.inputs?.showDefaultAttribute && config?.defaultAttribute?.length > 1) {
        // Add default attribute
        const existingAttrs = newWidgets[i]?.inputs?.customAttributes?.map(obj => {
          if (obj.attribute && obj.attribute.attribute) {
            return obj?.attribute?.attribute?.toLowerCase();
          }
          return null;
        })
        const attr2Add = config.defaultAttribute.filter(x => {
          return !existingAttrs.includes(x.toLowerCase())
        });

        if (attr2Add?.length > 0) {
          attr2Add.reverse().map(defAttr => {
            let newAttr = {
              "attribute": {
                "device": widgetDevices ? widgetDevices : '',
                "attribute": defAttr,
                "label": defAttr,
                "hideList": true
              }
            }
            newAttr.attribute.attribute = defAttr;
            newWidgets[i]?.inputs?.customAttributes.unshift(newAttr)
            return null;
          })
        }
      } else if (!newWidgets[i]?.inputs?.showDefaultAttribute) {
        // Remove default attribute
        newWidgets[i].inputs.customAttributes = newWidgets[i]?.inputs?.customAttributes?.filter(each => !(each?.attribute && each?.attribute?.hideList))
      }
    }
  }

  return newWidgets;
}