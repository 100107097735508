import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../actions/actionTypes";
import { Notification } from "../../../dashboard/types";
import { DashboardAction } from "../actions/actions";
import { NotificationLevel } from '../../../shared/notifications/notifications'


export interface NotificationsState {
  notification: Notification;
}

const initialState: NotificationsState = {
  notification: { level: NotificationLevel.UNDEFINED, sourceAction: "", msg: "" }
};

export default function notifications(
  state: NotificationsState = initialState,
  action: DashboardAction
): NotificationsState {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return { ...state, notification: action.notification };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: { level: NotificationLevel.UNDEFINED, sourceAction: "", msg: "" }
      };
    default:
      return state;
  }
}
