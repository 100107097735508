import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileUpload,
  faUndo,
  faRedo,
  faClone,
  faShareAlt,
  faTrash,
  faSpinner,
  faEdit,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faUser,
  faPlus,
  faUserEdit,
  faPen,
  faCopy,
  faPaste,
  faChevronDown,
  faChevronUp,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faLayerGroup,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Synoptic from "./components/Synoptic/Synoptic";
import UserAware from "../shared/user/components/UserAware";
import "./App.css";

library.add(
  faFileUpload,
  faUndo,
  faRedo,
  faClone,
  faShareAlt,
  faTrash,
  faSpinner,
  faEdit,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleLeft,
  faAngleRight,
  faUser,
  faPlus,
  faUserEdit,
  faPen,
  faCopy,
  faPaste,
  faChevronDown,
  faChevronUp,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faLayerGroup,
  faTimesCircle
);

const config = window["config"];
export default class App extends Component {
  render() {
    return (
      <BrowserRouter basename={config.basename}>
        <UserAware>
          <Route path="/:tangoDB/synoptic" component={Synoptic} />
        </UserAware>
      </BrowserRouter>
    );
  }
}
