import Timeline from "./Timeline";
import {
  WidgetDefinition,
  NumberInputDefinition,
  BooleanInputDefinition,
  ComplexInputDefinition,
} from "../../../dashboard/types";

import { AttributeComplexInput } from "../../types";
export type Inputs = {
  timeWindow: NumberInputDefinition;
  overflow: BooleanInputDefinition;
  groupAttributes: BooleanInputDefinition;
  attributes: ComplexInputDefinition<AttributeComplexInput>;
  showTangoDB?: BooleanInputDefinition | undefined; 
};

const definition: WidgetDefinition<Inputs> = {
  type: "TIMELINE",
  name: "Timeline",
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    timeWindow: {
      type: "number",
      default: 120,
      label: "Time Window",
    },
    overflow: {
      type: "boolean",
      default: false,
      label: "Show overflow scroll",
    },
    groupAttributes: {
      type: "boolean",
      default: false,
      label: "Merge plots",
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    attributes: {
      label: "Graphs",
      type: "complex",
      repeat: true,
      inputs: {
        attribute: {
          label: "",
          type: "attribute",
          required: true,
          dataFormat: "scalar",
          dataType: "enum",
        },
        yAxisDisplay: {
          type: "select",
          label: "Display Y axis",
          default: "Label",
          options: [
            {
              name: "Label",
              value: "Label",
            },
            {
              name: "Number",
              value: "Number",
            },
          ],
        },
        showAttribute: {
          type: "select",
          label: "Attribute legend",
          default: "Label",
          options: [
            {
              name: "Label",
              value: "Label",
            },
            {
              name: "Name",
              value: "Name",
            },
          ],
        },
        yAxis: {
          type: "select",
          default: "left",
          label: "Alignment of Y-Axis",
          options: [
            {
              name: "Left",
              value: "left",
            },
            {
              name: "Right",
              value: "right",
            },
          ],
        },
      },
    },
  },
};

const TimelineExport = { definition, component: Timeline };
export default TimelineExport;