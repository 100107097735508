export const UNDO = "UNDO"
export const REDO = "REDO"
export const DUPLICATE_WIDGET = "DUPLICATE_WIDGET"

export const SELECT_WIDGETS = "SELECT_WIDGETS";
export const DELETE_WIDGET = "DELETE_WIDGET";
export const ADD_WIDGET = "ADD_WIDGET";
export const ADD_INNER_WIDGET = "ADD_INNER_WIDGET";
export const REORDER_INNER_WIDGET = "REORDER_INNER_WIDGET"
export const DROP_INNER_WIDGET = "DROP_INNER_WIDGET";
export const MOVE_WIDGETS = "MOVE_WIDGET";
export const RESIZE_WIDGET = "RESIZE_WIDGET";

export const SET_INPUT = "SET_INPUT";
export const ADD_INPUT = "ADD_INPUT";
export const DELETE_INPUT = "DELETE_INPUT";

export const SELECT_CANVAS = "SELECT_CANVAS";

export const TOGGLE_MODE = "TOGGLE_MODE";

export const SAVE_SYNOPTIC="SAVE_SYNOPTIC"
export const SYNOPTIC_SAVED="SYNOPTIC_SAVED"

export const LOAD_SYNOPTIC = "LOAD_SYNOPTIC";
export const SYNOPTIC_LOADED = "SYNOPTIC_LOADED";

export const EXPORT_SYNOPTIC = "EXPORT_SYNOPTIC";
export const SYNOPTIC_EXPORTED = "SYNOPTIC_EXPORTED";

export const IMPORT_SYNOPTIC = "IMPORT_SYNOPTIC";
export const SYNOPTIC_IMPORTED = "SYNOPTIC_IMPORTED";

export const LOAD_SYNOPTICS = "LOAD_SYNOPTICS";
export const SYNOPTICS_LOADED = "SYNOPTICS_LOADED";

export const RENAME_SYNOPTIC = "RENAME_SYNOPTIC";
export const SYNOPTIC_RENAMED = "SYNOPTIC_RENAMED";

export const DELETE_SYNOPTIC = "DELETE_SYNOPTIC";
export const SYNOPTIC_DELETED = "SYNOPTIC_DELETED";

export const CLONE_SYNOPTIC = "CLONE_SYNOPTIC";
export const SYNOPTIC_CLONED = "SYNOPTIC_CLONED";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const SYNOPTIC_CREATED = "SYNOPTIC_CREATED";

export const SHARE_SYNOPTIC = "SHARE_SYNOPTIC";
export const SYNOPTIC_SHARED = "SYNOPTIC_SHARED";

export const SYNOPTIC_EDITED = "SYNOPTIC_EDITED";

export const REORDER_WIDGETS = "REORDER_WIDGETS"
export const UPDATE_WIDGET = "UPDATE_WIDGET"

export const WIDGET_CLIPBOARD_COPY = "WIDGET_CLIPBOARD_COPY"
export const WIDGET_CLIPBOARD_PASTE = "WIDGET_CLIPBOARD_PASTE"

export const TOGGLE_LIBRARY_COLLAPSED = "TOGGLE_LIBRARY_COLLAPSED"
export const TOGGLE_INSPECTOR_COLLAPSED = "TOGGLE_INSPECTOR_COLLAPSED"
export const MOUSE_DOWN_ON_WIDGET = "MOUSE_DOWN_ON_WIDGET"
