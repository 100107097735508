import React, { Component } from "react";

import SidebarTabs from "../SidebarTabs";
import DashboardLibrary from "./DashboardLibrary";
import WidgetLibrary from "./WIdgetLibrary";
import DashboardLayers from "../DashboardLayers";
import { Widget } from "../../types";
import { connect } from "react-redux";
import { getLibraryCollapsed } from "../../../shared/state/selectors";
import { IRootState } from "../../../shared/state/reducers/rootReducer";

interface Props {
  selectedTab: "dashboards" | "library" | "layers";
  onTabChange: (x: "dashboards" | "library" | "layers") => void;
  nbrSelectedWidgets: number;
  widgets: Widget[];
  isRootCanvas: boolean;
  tangoDB: string;
  libraryCollapsed: boolean;
}

class Library extends Component<Props> {
  public render() {
    const { onTabChange, selectedTab, libraryCollapsed } = this.props;
    return (
      <div>
        <SidebarTabs selectedTab={selectedTab} onTabChange={onTabChange} />
        {/* by sending a 'render' props instead of conditional rendering here, the component won't get unmounted by
      changing tabs, and the state won't be lost */}

        <WidgetLibrary
          render={selectedTab === "library" && !libraryCollapsed}
        />
        <DashboardLibrary
          render={selectedTab === "dashboards" && !libraryCollapsed}
          tangoDB={this.props.tangoDB}
        />
        <DashboardLayers
          render={selectedTab === "layers" && !libraryCollapsed}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return { libraryCollapsed: getLibraryCollapsed(state) };
}

export default connect(mapStateToProps)(Library);
