import React, { Component } from "react";
import { Widget } from "../types";
import { connect } from "react-redux";
import { IRootState } from "../../shared/state/reducers/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DashboardLayers.css";
import { Dispatch } from "redux";
import {
  selectWidgets,
  reorderWidgets,
  updateWidget,
  deleteWidget,
} from "../../shared/state/actions/actionCreators";
import {
  getWidgets,
  getSelectedDashboard,
  getSelectedWidgets,
} from "../../shared/state/selectors";
import LayerList from "./LayerList";
import { calculateInnerWidgetAlignment, getAllBoxWidgets, getParentBoxWidget } from "../../shared/utils/canvas";
import { CUSTOM_HEIGHT, CUSTOM_WIDTH, CUSTOM_HEIGHT_WIDTH } from '../../shared/utils/canvas';
import { isEditableDashboard } from "../utils"
import { SelectedDashboardState } from "../../shared/state/reducers/selectedDashboard";
import { IUserState } from "../../shared/user/state/reducer"


const config = window['config'];

interface Props {
  selectedDashboard: SelectedDashboardState;
  widgets: Widget[];
  selectedWidgets: Widget[];
  onSelectWidgets: (ids: string[]) => void;
  onReorderWidget: (widgets: Widget[]) => void;
  onUpdateWidget: (widget: Widget) => void;
  onDeleteWidget: () => void;
  user: IUserState;
  render: boolean;
}

const TILE_SIZE = config.MIN_WIDGET_SIZE;

class DashboardLayers extends Component<Props> {

  public render() {
    const {
      widgets,
      selectedDashboard,
      selectedWidgets,
      onSelectWidgets,
      onReorderWidget,
    } = this.props;

    const displayStyle = this.props.render ? "block" : "none";
    const allBoxWidgets = getAllBoxWidgets(this.props.widgets);
    const parentWidget = selectedWidgets[0] ? getParentBoxWidget(allBoxWidgets, selectedWidgets[0].id) : false;
    const editableDashboard = isEditableDashboard(selectedDashboard, this.props.user).result;

    return (
      <div className={`layers-container ${editableDashboard ? '' : 'disabled'}`} style={{ display: displayStyle }} tabIndex={0}>
        <div className={"widget-size " + (parentWidget ? "" : "disabled")}>
          <div className="row">
            <div className="col-4">
              <div className="mb-1">Size</div>
              <div title="It allows you to restore the values and switch the widget to the default layout">
                <input
                  style={{verticalAlign: "middle"}}
                  type="checkbox"
                  name="default-size"
                  checked={this.isDefaultView()}
                  onChange={(e) => this.handleDefaultChange()}
                />
                &nbsp;
                <label>Default</label>
              </div>
            </div>
            <div className="col-2 pr-1">
              <div className="form-group">
                <label htmlFor="percentage">%</label>
                <input
                  type="number"
                  name="percentage"
                  className="form-control form-control-sm"
                  placeholder="%"
                  min="0"
                  max="100"
                  value={this.getPercentage()}
                  onChange={(e) => this.handleSizeChange(e)}
                />
              </div>
            </div>
            <div className="col-3 pr-2 pl-1">
              <div className="pl-2 border-left">
                <div className="form-group">
                  <label htmlFor="width">Width</label>
                  <input type="number" className="form-control form-control-sm" placeholder="px"
                    name="width"
                    value={undefined !== this.props.selectedWidgets[0] && undefined !== this.props.selectedWidgets[0].width ? this.props.selectedWidgets[0].width * TILE_SIZE : ""}
                    onChange={(e) => this.handleSizeChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-3 pl-2">
              <div className="form-group">
                <label htmlFor="height">Height</label>
                <input type="number" className="form-control form-control-sm" placeholder="px"
                  name="height"
                  value={undefined !== this.props.selectedWidgets[0] && undefined !== this.props.selectedWidgets[0].height ? this.props.selectedWidgets[0].height * TILE_SIZE : ""}
                  onChange={(e) => this.handleSizeChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="widget-layers">
          <LayerList
            selectedIds={selectedDashboard.selectedIds}
            widgets={widgets.sort((a, b) => b.order - a.order)}
            selectedWidgets={selectedWidgets}
            onSelectWidgets={onSelectWidgets}
            onReorderWidget={onReorderWidget}
          />
        </div>
        <div className="widget-bttns">
          <button
            title="Delete selected layers"
            onClick={this.props.onDeleteWidget}
            disabled={selectedDashboard.selectedIds.length === 0}
            className="btn btn-outline-secondary btn-sm btn-layer-action"
          >
            <FontAwesomeIcon icon="trash" />{" "}
          </button>
          <button
            title="Group selected layers"
            onClick={() => window.alert("not implemented yet")}
            disabled={selectedDashboard.selectedIds.length < 2}
            className="btn btn-outline-secondary btn-sm btn-layer-action"
          >
            <FontAwesomeIcon icon="layer-group" />{" "}
          </button>
        </div>
      </div>
    );
  }

  handleSizeChange(event) {
    let updatedWidget = {...this.props.selectedWidgets[0]};

    updatedWidget[event.target.name] = Number(event.target.value / (event.target.name === 'percentage' ? 1 : TILE_SIZE));
    // Number converts "" to 0, avoid that for percentage
    if ("percentage" === event.target.name && "" === event.target.value)
      updatedWidget[event.target.name] = event.target.value;

    if ('height' === event.target.name || 'width' === event.target.name) {
      updatedWidget = this.setPercentage(updatedWidget, event.target.name);
    }

    this.props.onUpdateWidget(updatedWidget);
  }

  handleDefaultChange() {
    let updatedWidget = {...this.props.selectedWidgets[0]};
    const allBoxWidgets = getAllBoxWidgets(this.props.widgets);
    const parentWidget: any = getParentBoxWidget(allBoxWidgets, this.props.selectedWidgets[0].id);

    if (parentWidget && parentWidget!.innerWidgets && 0 < parentWidget!.innerWidgets.length) {
      // Reset the width & height of selected widget
      let alignment = calculateInnerWidgetAlignment(parentWidget, TILE_SIZE, "edit", true);
      parentWidget.innerWidgets.forEach((inWidget, i) => {

        if (inWidget.id === updatedWidget.id) {
          updatedWidget.width = alignment[i].width / TILE_SIZE;
          updatedWidget.height = alignment[i].height / TILE_SIZE;
        }
      });
    }

    updatedWidget.percentage = "";
    this.props.onUpdateWidget(updatedWidget);
  }

  setPercentage(widget: Widget, fieldName: string) {
    if (!widget['percentage'] || (![CUSTOM_HEIGHT, CUSTOM_WIDTH, CUSTOM_HEIGHT_WIDTH].includes(Number(widget['percentage'])))) {
      // percentage field is not set or set to a diff value.
      widget['percentage'] = 'height' === fieldName ? CUSTOM_HEIGHT : CUSTOM_WIDTH;

    } else if (('height' === fieldName && CUSTOM_WIDTH === widget['percentage']) || ('width' === fieldName && CUSTOM_HEIGHT === widget['percentage'])) {
      widget['percentage'] = CUSTOM_HEIGHT_WIDTH;
    }

    return widget;
  }

  getPercentage() {
    return (
      undefined !== this.props.selectedWidgets[0]
      && this.props.selectedWidgets[0].percentage
      && ![CUSTOM_HEIGHT, CUSTOM_WIDTH, CUSTOM_HEIGHT_WIDTH].includes(this.props.selectedWidgets[0].percentage)
        ? this.props.selectedWidgets[0].percentage : ""
    );
  }

  // Default is true if either percentage field is not set or set to empty string.
  isDefaultView() {
    return (
      undefined !== this.props.selectedWidgets[0]
        ? undefined !== this.props.selectedWidgets[0].percentage
          ? "" === this.props.selectedWidgets[0].percentage : true : false
    )
  }
}

function mapStateToProps(state: IRootState) {
  return {
    selectedDashboard: getSelectedDashboard(state),
    widgets: getWidgets(state),
    selectedWidgets: getSelectedWidgets(state),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onSelectWidgets: (ids: string[]) => dispatch(selectWidgets(ids)),
    onReorderWidget: (widgets: Widget[]) => dispatch(reorderWidgets(widgets)),
    onUpdateWidget: (UpdatedWidget: Widget) => dispatch(updateWidget(UpdatedWidget)),
    onDeleteWidget: () => dispatch(deleteWidget()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayers);
