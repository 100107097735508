import { take, put, select} from "redux-saga/effects";
import {
    saveDashboard as saveDashboardAction,
    dashboardEdited,
} from "../actions/actionCreators";
import {
    move,
    setInput,
    deleteInput,
    addInput,
    defaultDimensions,
    nestedDefault,
    validate,
    resize,
    nextId,
    pushToHistory,
    undo,
    redo,
    nextOrderIndex,
    reorderIndex,
    duplicateWidgets,
    updateWidget,
    removeWidget
} from "../reducers/selectedDashboard/lib";
import {
    ADD_WIDGET,
    ADD_INNER_WIDGET,
    REORDER_INNER_WIDGET,
    DROP_INNER_WIDGET,
    MOVE_WIDGETS,
    RESIZE_WIDGET,
    DELETE_WIDGET,
    UNDO,
    REDO,
    DUPLICATE_WIDGET,
    SET_INPUT,
    DELETE_INPUT,
    ADD_INPUT,
    REORDER_WIDGETS,
    UPDATE_WIDGET,
    WIDGET_CLIPBOARD_PASTE,
} from "../actions/actionTypes";
import {
    getDashboards,
    getSelectedDashboard,
    getClipboardWidgets,
    getClipboardPasteCounter
} from "../selectors";
import { definitionForType, definitionForWidget } from "../../../dashboard/widgets";
import { defaultInputs } from "../../../dashboard/utils";
import {
    getAllInnerWidgetsById,
    mergeObjects,
    calculateInnerWidgetsOrder,
    getAllBoxWidgets,
    getWidgetsAligned,
    getParentBoxWidget
} from "../../utils/canvas";
import { cloneWidgets, updateDependentInputs } from "../reducers/selectedDashboard/lib";
import cloneDeep from "lodash/cloneDeep";
import { getDeviceNames } from "../selectors/deviceList";

const config = window['config'];
const TILE_SIZE = config.MIN_WIDGET_SIZE;

export function* editWidget() {
    while (true) {
        const { type, ...payload } = yield take([
            ADD_WIDGET,
            ADD_INNER_WIDGET,
            REORDER_INNER_WIDGET,
            DROP_INNER_WIDGET,
            MOVE_WIDGETS,
            RESIZE_WIDGET,
            DELETE_WIDGET,
            UNDO,
            REDO,
            DUPLICATE_WIDGET,
            SET_INPUT,
            DELETE_INPUT,
            REORDER_WIDGETS,
            UPDATE_WIDGET,
            ADD_INPUT,
            WIDGET_CLIPBOARD_PASTE
        ]);

        const state = yield select(getSelectedDashboard);
        const dashboards = yield select(getDashboards);
        const deviceList = yield select(getDeviceNames);
        let newState = {};
        switch (type) {
            case WIDGET_CLIPBOARD_PASTE: {
                const clipboardWidgets = yield select(getClipboardWidgets);
                const clipboardPasteCounter = yield select(getClipboardPasteCounter);
                const newIds = [];

                const newWidgets = cloneWidgets(clipboardWidgets, state.widgets, newIds, clipboardPasteCounter)

                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = {
                    ...state,
                    widgets: newWidgets,
                    selectedIds: newIds,
                    history
                };
                break;
            }
            case UNDO:
                {
                    const { history: oldHistory, widgets: oldWidgets } = state;
                    const { history, widgets } = undo(oldHistory, oldWidgets);
                    newState = {
                        ...state,
                        widgets,
                        history,
                        selectedIds: []
                    };
                }
                break;
            case REDO: {
                const { history: oldHistory, widgets: oldWidgets } = state;
                const { history, widgets } = redo(oldHistory, oldWidgets);
                newState = {
                    ...state,
                    widgets,
                    history,
                    selectedIds: []
                };
                break;
            }
            case ADD_WIDGET: {
                const { x, y, canvas, widgetType: type } = payload;
                const definition = definitionForType(type);
                const inputs = defaultInputs(definition.inputs);
                let { width, height } = defaultDimensions(definition);
                const percent = (20 / TILE_SIZE);
                width = width * percent;
                height = height * percent;

                const id = nextId(state.widgets);

                const widget = validate({
                    id,
                    x,
                    y,
                    canvas,
                    width,
                    height,
                    type,
                    inputs,
                    valid: 0,
                    order: nextOrderIndex(state.widgets)
                }, state.variables, deviceList);
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = {
                    ...state,
                    widgets: { ...state.widgets, [id]: widget },
                    selectedIds: [id],
                    history
                };
                break;
            }
            case REORDER_INNER_WIDGET: {
                const oldWidgets = cloneDeep(state.widgets);

                const { x, y, parentWidget, innerWidget } = payload;
                calculateInnerWidgetsOrder(parentWidget, innerWidget, x, y, config.MIN_WIDGET_SIZE);

                const { history: oldHistory } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = {
                    ...state,
                    selectedIds: [innerWidget.id],
                    history
                };
                break;
            }
            case ADD_INNER_WIDGET: {
                const { widget, x, y, parentID, innerWidgetCheck } = payload;
                const oldWidgets = cloneDeep(state.widgets);
                const allWidgets = getAllInnerWidgetsById(oldWidgets);
                //empty the hover array for parent widget since hovered element will be added to its innerwidgets
                if (allWidgets[Number(parentID)] && allWidgets[Number(parentID)].hover)
                    allWidgets[Number(parentID)].hover = [];

                //Can only call one saga at time from handleMouseUp on EditCanvas so delete from state
                let widgets_input = (!Array.isArray(widget)) ? [widget] : widget;

                widgets_input.forEach(widget_input => {
                    if (widget_input.id && !innerWidgetCheck) delete state.widgets[widget_input.id];
                    else if (widget_input.id && innerWidgetCheck) {
                        //Droping innerWidget inside another box
                        let bWidgets = getAllBoxWidgets(state.widgets);
                        //Remove widget from old box
                        bWidgets.forEach((element, i) => {
                            if (element.innerWidgets && element.innerWidgets.length > 0) {
                                element.innerWidgets.forEach((innerW, k) => {

                                    if (widget[0].id === innerW.id) {
                                        let temp = bWidgets[i].innerWidgets;
                                        temp.splice(k, 1);
                                        bWidgets[i].innerWidgets = temp;
                                    }
                                });
                            }
                        });
                    }

                    const definition = definitionForType(widget_input.type);
                    const inputs = defaultInputs(definition.inputs);
                    const { width, height } = defaultDimensions(definition);
                    const id = nextId(state.widgets);

                    const innerWidget = validate({
                        id: widget_input.id ? widget_input.id : id,
                        x,
                        y,
                        canvas: "0",
                        width: widget_input.width ? widget_input.width : width,
                        height: widget_input.height ? widget_input.height : height,
                        type: widget_input.type,
                        inputs: widget_input.inputs ? widget_input.inputs : inputs,
                        valid: 0,
                        percentage: widget_input.percentage,
                        innerWidgets: widget_input.innerWidgets ? widget_input.innerWidgets : undefined,
                        order: nextOrderIndex(state.widgets)
                    }, state.variables, deviceList);

                    Object.values(getAllBoxWidgets(state.widgets)).forEach(widget => {
                        const id = widget.id;
                        if (id === parentID) {
                            let parentWidget = widget;
                            if (parentWidget.innerWidgets !== undefined && parentWidget.innerWidgets.length > 0) {
                                //Already got widgets, it puts the widget in the correct order
                                calculateInnerWidgetsOrder(parentWidget, innerWidget, x, y, config.MIN_WIDGET_SIZE);

                                parentWidget["innerWidgets"] = [...parentWidget["innerWidgets"], innerWidget];
                            }
                            else {
                                //First Widget order 1
                                innerWidget.order = 1;
                                parentWidget["innerWidgets"] = [innerWidget];
                            }

                            parentWidget = getWidgetsAligned([parentWidget], config.MIN_WIDGET_SIZE)[0];
                        }
                    });
                });

                const { history: oldHistory } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                const widgets = { ...state.widgets };
                newState = { ...state, widgets, history };
                newState.selectedIds = [];
                break;
            }
            case DROP_INNER_WIDGET: {
                //Move inner widget from box to another box / canvas
                const oldWidgets = cloneDeep(state.widgets);
                const { x, y, innerWidget } = payload;
                const bWidgets = Object.values(state.widgets).filter(widget => widget.type === "BOX")

                removeWidget(bWidgets, innerWidget.id);

                innerWidget.x = x - innerWidget.width / 2;
                innerWidget.y = y - innerWidget.height / 2;
                state.widgets = Object.assign({ [innerWidget.id]: innerWidget }, state.widgets);

                const { history: oldHistory } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                const widgets = { ...state.widgets };
                newState = { ...state, widgets, history };
                newState.selectedIds = [];
                break;
            }
            case MOVE_WIDGETS: {
                const { dx, dy, ids } = payload;
                const oldWidgets = cloneDeep(state.widgets);

                const moved = ids
                    .map(id => state.widgets[id])
                    .map(widget => move(widget, dx, dy))
                    .reduce((accum, widget) => {
                        return { ...accum, [widget.id]: widget };
                    }, {});

                const widgets = { ...state.widgets, ...moved };
                const { history: oldHistory } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = { ...state, widgets, history };
                break;
            }
            case RESIZE_WIDGET: {
                const { dx, dy, mx, my, id } = payload;
                const newWidget = resize(state.widgets[id], mx, my, dx, dy);
                const widgets = { ...state.widgets, [id]: newWidget };
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = { ...state, widgets, history };
                break;
            }
            case DUPLICATE_WIDGET: {
                const newWidgets = Object.assign({}, state.widgets);
                const newIds = [];

                duplicateWidgets(state, newWidgets, newIds);

                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = {
                    ...state,
                    widgets: newWidgets,
                    selectedIds: newIds,
                    history
                };

                break;
            }
            case DELETE_WIDGET: {
                const oldWidgets = cloneDeep(state.widgets);
                const widgets = Object.keys(state.widgets)
                    .filter(id => state.selectedIds.indexOf(id) === -1)
                    .reduce((accum, id) => {
                        return { ...accum, [id]: state.widgets[id] };
                    }, {});

                if (JSON.stringify(widgets) === JSON.stringify(state.widgets)) {
                    removeWidget(Object.values(widgets), state.selectedIds[0]);
                }

                const { history: oldHistory } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = {
                    ...state,
                    widgets: reorderIndex(widgets),
                    selectedIds: [],
                    history
                };
                break;
            }
            case SET_INPUT: {
                const { path, value, widgetType } = payload;
                newState = state;
                const newWidgets = {};
                const allWidgets = getAllInnerWidgetsById({ ...state.widgets });

                state.selectedIds.forEach(id => {
                    const newWidget = validate(
                        setInput(allWidgets[id], path, value, widgetType),
                        state.variables,
                        deviceList
                    );
                    newWidgets[id] = newWidget;
                });

                updateDependentInputs(newWidgets);
                const widgets = mergeObjects(state.widgets, newWidgets);
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                newState = { ...state, widgets, history };
                break;
            }
            case ADD_INPUT: {
                newState = state;
                const { path } = payload;
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);

                const newWidgets = {};
                let parentWidget = {};

                state.selectedIds.forEach(select_id => {

                    let oldWidget = state.widgets[select_id];
                    if (!oldWidget) {
                        //It's not a main widget but an InnerWidget
                        parentWidget = getParentBoxWidget(Object.values(state.widgets).filter(widget => "BOX" === widget.type), Number(select_id));
                        Object.values(parentWidget.innerWidgets).forEach((inWid, inner_id) => {
                            if (select_id === inWid.id) {
                                oldWidget = inWid;

                                const definition = definitionForWidget(oldWidget);
                                const value = nestedDefault(definition, path);
                                const newWidget = validate(
                                    addInput(oldWidget, [...path, -1], value),
                                    state.variables,
                                    deviceList
                                );

                                parentWidget.innerWidgets[inner_id] = newWidget;
                            }
                        });
                    } else {//It's a main widget
                        const definition = definitionForWidget(oldWidget);
                        const value = nestedDefault(definition, path);
                        const newWidget = validate(
                            addInput(oldWidget, [...path, -1], value),
                            state.variables,
                            deviceList
                        );
                        newWidgets[select_id] = newWidget;
                    }
                });

                const widgets = { ...state.widgets, ...newWidgets };
                newState = { ...state, widgets, history };
                break;
            }
            case DELETE_INPUT: {
                newState = state;
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                const { path } = payload;
                const newWidgets = {};

                state.selectedIds.forEach(id => {

                    if (state.widgets[id]) {
                        const newWidget = validate(
                            deleteInput(state.widgets[id], path),
                            state.variables,
                            deviceList
                        );
                        newWidgets[id] = newWidget;
                        updateDependentInputs({ [id]: newWidget });
                    } else {
                        // For inner widget, get the widget, update it & then assign it to parent widget.
                        const parentWidget = getParentBoxWidget(Object.values(state.widgets).filter(widget => "BOX" === widget.type), id);
                        Object.values(parentWidget.innerWidgets).forEach((inWid, inner_id) => {
                            if (id === inWid.id) {
                                const newWidget = validate(
                                    deleteInput(inWid, path),
                                    state.variables,
                                    deviceList
                                );
                                updateDependentInputs({ [id]: newWidget });
                                parentWidget.innerWidgets[inner_id] = newWidget;
                            }
                        });
                    }
                });

                const widgets = { ...state.widgets, ...newWidgets };
                newState = { ...state, widgets, history };
                break;
            }
            case REORDER_WIDGETS: {
                const { widgets } = payload;
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);
                const newWidgets = {}

                widgets.forEach(widget => {
                    newWidgets[widget.id] = widget;
                })
                newState = { ...state, widgets: newWidgets, history };
                break;
            }
            case UPDATE_WIDGET: {
                const { updatedWidget } = payload;
                const { history: oldHistory, widgets: oldWidgets } = state;
                const history = pushToHistory(oldHistory, oldWidgets);

                const newWidgets = { ...state.widgets }
                updateWidget(Object.values(newWidgets), updatedWidget);
                newState = { ...state, widgets: newWidgets, history };
                break;
            }
            default: {
                newState = state;
            }
        }
        yield put(dashboardEdited(newState));
        const widgetArray = Object.keys(newState.widgets).map(
            key => newState.widgets[key]
        );

        const dashboard = dashboards?.find(dashboard => dashboard.id === state.id);
        let variables = undefined;
        if (dashboard) variables = undefined !== dashboard.variables ? dashboard.variables : [];
        yield put(saveDashboardAction(newState.id, newState.name, widgetArray, variables, dashboard?.environment));
    }
}
