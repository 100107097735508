import React from "react";
import ModeToggleButton from "./ModeToggleButton/ModeToggleButton";
import SynopticTitle from "./SynopticTitle/SynopticTitle";
import { Navbar } from "../../shared/ui/navbar/Navbar";

interface Props {
  mode: "edit" | "run";
  onToggleMode: () => void;
  modeToggleDisabled: boolean;
}

export default function TopBar(props: Props) {
  const { mode, onToggleMode, modeToggleDisabled } = props;

  return (
    <Navbar>
      <div style={{ width: "100%", padding: "0 1em" }}>
        <form className="form-inline" style={{ display: "inline-block" }}>
          <ModeToggleButton
            onClick={onToggleMode}
            disabled={modeToggleDisabled}
            mode={mode}
          />
        </form>
        <SynopticTitle />
      </div>
    </Navbar>
  );
}
