import { StateUrls } from "../../ui/navbar/Navbar";
import {
  ISaveStateUrlAction,
  ISaveStateUrlSuccessAction,
  ISaveStateUrlFailedAction,
  ILoadStateUrlAction,
  ILoadStateUrlSuccessAction,
  ILoadStateUrlFailedAction,
  IPreloadUserAction,
  IPreloadUserSuccessAction,
  IPreloadUserFailedAction,
  ILogoutAction,
  ILogoutSuccessAction,
  ILoginSuccessAction,
  ILoginFailedAction,
  IExtendLoginAction,
  IExtendLoginSuccessAction,
  IExtendLoginFailedAction,
  IOpenLoginDialogAction,
  ICloseLoginDialogAction,
  ISaveNotificationAction,
  ISaveNotificationFailedAction,
  ISaveNotificationSuccessAction,
  ILoadNotificationAction,
  ILoadNotificationFailedAction,
  ILoadNotificationSuccessAction,
  IClearNotificationAction,
  IClearNotificationFailedAction,
  IClearNotificationSuccessAction,
  ISetNotificationAction,
  ISetNotificationFailedAction,
  ISetNotificationSuccessAction,
} from "./actions";

import {
  SAVE_STATE_URL,
  SAVE_STATE_URL_SUCCESS,
  SAVE_STATE_URL_FAILED,
  LOAD_STATE_URL,
  LOAD_STATE_URL_SUCCESS,
  LOAD_STATE_URL_FAILED,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  PRELOAD_USER,
  PRELOAD_USER_FAILED,
  PRELOAD_USER_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  EXTEND_LOGIN,
  EXTEND_LOGIN_SUCCESS,
  EXTEND_LOGIN_FAILED,
  OPEN_LOGIN_DIALOG,
  CLOSE_LOGIN_DIALOG,
  SAVE_NOTIFICATIONS,
  SAVE_NOTIFICATIONS_FAILED,
  SAVE_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_FAILED,
  LOAD_NOTIFICATIONS_SUCCESS,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_FAILED,
  CLEAR_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_FAILED,
  SET_NOTIFICATIONS_SUCCESS
} from "./actionTypes";

import { Notification } from "../../notifications/notifications";

interface IUser {
  username: string;
  userGroups: string[];
}

export function saveStateUrls(stateUrls: StateUrls):ISaveStateUrlAction {
  return { type: SAVE_STATE_URL, stateUrls: stateUrls };
}

export function saveStateUrlSucces(stateUrls: StateUrls):ISaveStateUrlSuccessAction {
  return { type: SAVE_STATE_URL_SUCCESS, stateUrls: stateUrls };
}

export function saveStateUrlFailed():ISaveStateUrlFailedAction {
  return { type: SAVE_STATE_URL_FAILED };
}

export function loadStateUrls():ILoadStateUrlAction {
  return { type: LOAD_STATE_URL };
}

export function loadStateUrlSucces(stateUrls: StateUrls):ILoadStateUrlSuccessAction {
  return { type: LOAD_STATE_URL_SUCCESS, stateUrls: stateUrls };
}

export function loadStateUrlFailed():ILoadStateUrlFailedAction {
  return { type: LOAD_STATE_URL_FAILED };
}

export function login(username: string, password: string) {
  return { type: LOGIN, username, password };
}

export function loginSuccess(user: IUser): ILoginSuccessAction {
  const username = user.username;
  const userGroups = user.userGroups;
  return { type: LOGIN_SUCCESS, username, userGroups };
}

export function loginFailed(): ILoginFailedAction {
  return { type: LOGIN_FAILED };
}

export function preloadUser(): IPreloadUserAction {
  return { type: PRELOAD_USER };
}

export function preloadUserSuccess(user: IUser): IPreloadUserSuccessAction {
  const {username, userGroups} = user;
  return { type: PRELOAD_USER_SUCCESS, username, userGroups};
}

export function preloadUserFailed(): IPreloadUserFailedAction {
  return { type: PRELOAD_USER_FAILED };
}

export function logout(): ILogoutAction {
  return { type: LOGOUT };
}

export function logoutSuccess(): ILogoutSuccessAction {
  return { type: LOGOUT_SUCCESS };
}

export function extendLogin(): IExtendLoginAction {
  return { type: EXTEND_LOGIN };
}

export function extendLoginSuccess(): IExtendLoginSuccessAction {
  return { type: EXTEND_LOGIN_SUCCESS };
}

export function extendLoginFailed(): IExtendLoginFailedAction {
  return { type: EXTEND_LOGIN_FAILED };
}

export function openLoginDialog(): IOpenLoginDialogAction {
  return { type: OPEN_LOGIN_DIALOG };
}

export function closeLoginDialog(): ICloseLoginDialogAction {
  return { type: CLOSE_LOGIN_DIALOG };
}

export function saveNotification(notification: Notification, username: string): ISaveNotificationAction {
  return { type: SAVE_NOTIFICATIONS, notification, username };
}

export function saveNotificationSuccess(notifications: Notification[]): ISaveNotificationSuccessAction {
  
  return { type: SAVE_NOTIFICATIONS_SUCCESS, notifications};
}

export function saveNotificationFailed(): ISaveNotificationFailedAction {
  return { type: SAVE_NOTIFICATIONS_FAILED };
}

export function loadNotifications(username: any): ILoadNotificationAction {
  return { type: LOAD_NOTIFICATIONS, username };
}

export function loadNotificationsSuccess(notifications: Notification[]): ILoadNotificationSuccessAction {
  return { type: LOAD_NOTIFICATIONS_SUCCESS, notifications};
}

export function loadNotificationsFailed(): ILoadNotificationFailedAction {
  return { type: LOAD_NOTIFICATIONS_FAILED };
}

export function clearNotifications(username: string): IClearNotificationAction {
  return { type: CLEAR_NOTIFICATIONS, username };
}

export function clearNotificationsSuccess(notifications: Notification[]): IClearNotificationSuccessAction {
  
  return { type: CLEAR_NOTIFICATIONS_SUCCESS, notifications };
}

export function clearNotificationsFailed(): IClearNotificationFailedAction {
  return { type: CLEAR_NOTIFICATIONS_FAILED };
}

export function setNotifications(key: number): ISetNotificationAction {
  return { type: SET_NOTIFICATIONS, key };
}

export function setNotificationsSuccess(notifications: Notification[]): ISetNotificationSuccessAction {
  
  return { type: SET_NOTIFICATIONS_SUCCESS, notifications };
}

export function setNotificationsFailed(): ISetNotificationFailedAction {
  return { type: SET_NOTIFICATIONS_FAILED };
}