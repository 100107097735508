
import MongoAPI from "../../../shared/api/mongoAPI";
import { fork, take, put, call } from "redux-saga/effects";
import { FETCH_LOGGED_ACTIONS } from "../actions/actionTypes";
import {
  fetchLoggedActionsFailed,
  fetchLoggedActionsSuccess
} from "../actions/mongodb";

export default function* mongodb() {
  yield fork(fetchLoggedActions);
}

export function* fetchLoggedActions() {
  while (true) {
    const { tangoDB, deviceName, limit } = yield take(FETCH_LOGGED_ACTIONS);
    try {
      const logs = yield call(
        MongoAPI.fetchLoggedActions,
        tangoDB,
        deviceName,
        limit
      );
      yield put(fetchLoggedActionsSuccess(logs));
    } catch (err) {
      yield put(fetchLoggedActionsFailed(err.toString()));
    }
  }
}