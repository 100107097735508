import { Action } from "redux";
import {
    FETCH_LOGGED_ACTIONS,
    FETCH_LOGGED_ACTIONS_SUCCESS,
    FETCH_LOGGED_ACTIONS_FAILED,
} from "./actionTypes";
interface FetchLoggedActionsAction extends Action {
  type: typeof FETCH_LOGGED_ACTIONS;
  tangoDB: string;
  deviceName: string;
  limit;
}

export function fetchLoggedActions(
  tangoDB: string,
  deviceName: string,
  limit
): FetchLoggedActionsAction {
  return { type: FETCH_LOGGED_ACTIONS, tangoDB, deviceName, limit };
}

interface FetchLoggedActionsSuccessAction extends Action {
  type: typeof FETCH_LOGGED_ACTIONS_SUCCESS;
  logs;
}

export function fetchLoggedActionsSuccess(
  logs
): FetchLoggedActionsSuccessAction {
  return { type: FETCH_LOGGED_ACTIONS_SUCCESS, logs };
}

interface FetchLoggedActionsFailedAction extends Action {
  type: typeof FETCH_LOGGED_ACTIONS_FAILED;
  reason;
}

export function fetchLoggedActionsFailed(
  reason
): FetchLoggedActionsFailedAction {
  return { type: FETCH_LOGGED_ACTIONS_FAILED, reason };
}
export type UserAction =
  | FetchLoggedActionsAction
  | FetchLoggedActionsSuccessAction
  | FetchLoggedActionsFailedAction;