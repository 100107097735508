import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SynopticsConfiguration from "./Library/SynopticsConfiguration";
import { IRootState } from "../../shared/state/reducers/rootReducer";
import { getLibraryCollapsed } from "../state/selectors";
import { TOGGLE_LIBRARY_COLLAPSED } from "../state/actionTypes";

interface Props {
  mode: "run" | "edit";
  selectedTab: "synoptics";
  tangoDB: string;
  libraryCollapsed: boolean;
  toggleLibraryCollapse: () => void;
}
interface State {
  selectedTab: "synoptics";
}

class Sidebar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { selectedTab } = this.props;
    this.state = { selectedTab };
  }
  public render() {
    const {
      mode,
      tangoDB,
      libraryCollapsed,
      toggleLibraryCollapse,
    } = this.props;
    const { selectedTab } = this.state;
    if (mode === "run") {
      return null;
    }
    const display = libraryCollapsed ? "none" : "block";
    return (
      <>
        {libraryCollapsed && (
          <div className="expand-library">
            <div
              className="Library-collapsed"
              title={`Show the library \n (Alt+l)`}
              onClick={toggleLibraryCollapse}
            >
              <FontAwesomeIcon icon={"angle-left"} />{" "}
            </div>
          </div>
        )}
        <div className="Sidebar" style={{ display }}>
          <SynopticsConfiguration
            selectedTab={selectedTab}
            onTabChange={(newVal) => this.setState({ selectedTab: newVal })}
            isRootCanvas={true}
            tangoDB={tangoDB}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return { libraryCollapsed: getLibraryCollapsed(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLibraryCollapse: () => dispatch({ type: TOGGLE_LIBRARY_COLLAPSED }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
