import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal as BootstrapModal } from "react-bootstrap";
import Draggable from 'react-draggable';

import "./Modal.css";

let modalRoot = document.getElementById("modal");

function ModalPortal({ transparentModal, children }) {
  const div = document.createElement("div");
  div.classList.add("taranta-modal");
  if(true === transparentModal) div.classList.add("transparent-modal");
  const hostRef = useRef(div);

  useEffect(() => {
    const element = hostRef.current;

    if (null === modalRoot) {
      //This code block is to support test case for react portals
      modalRoot = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.querySelector('body').appendChild(modalRoot);
    }

    modalRoot.appendChild(element);
    return () => {
      modalRoot.removeChild(element);
    };
  }, []);

  return ReactDOM.createPortal(children, hostRef.current);
}

export default function Modal({ title, rightTitle = '', transparentModal = false, size='md', children }) {
  return (
    <ModalPortal transparentModal={transparentModal}>
      <Draggable enableUserSelectHack={false} handle=".modal-header">
        <BootstrapModal.Dialog size={size}>
          {title && (
            <BootstrapModal.Header>
              <BootstrapModal.Title>{title}</BootstrapModal.Title>
              {rightTitle && (
                <div className="right-subtitle">
                  <BootstrapModal.Title>{rightTitle}</BootstrapModal.Title>
                </div>
              )}
            </BootstrapModal.Header>
          )}
          {children}
        </BootstrapModal.Dialog>
      </Draggable>
    </ModalPortal>
  );
}

Modal.Body = BootstrapModal.Body;
Modal.Footer = BootstrapModal.Footer;