import React, { Component, CSSProperties } from "react";

import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
  StringInputDefinition,
  NumberInputDefinition,
  ColorInputDefinition,
  StyleInputDefinition
} from "../../types";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { getAttributeLastValueFromState } from "../../../shared/utils/getLastValueHelper";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { showHideTangoDBName } from "../../DBHelper";

export interface AttributeComplexInput {
  attribute: AttributeInputDefinition;
}

type Inputs = {
  attribute: AttributeInputDefinition;
  showAttribute: SelectInputDefinition;
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  scale: StringInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

const imgSample =
  "iVBORw0KGgoAAAANSUhEUgAAAPsAAAD7CAAAAACJGb16AAAC40lEQVR4nO3b227jMAxFUbLo//8y+6Ab5Sh902GR7jUdFM1gEB+TlBM5NQMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4H/x4icM9fMn39Jn89PJLouvzO7n7GXpdT0/km/PGP2rJL2s7n7MPlWEF2X3+ec08B5ekV6T3VvZ3w68mYXLw0uyt+C/ZQ83feUV2d1n+hY/Cx/JXR1ekL1HTyO/m8nV4e9n90f6HD58fg+3MO3MS+ru7e+6zu3aGdCv9dezu/uIf2r6dn3rwcXpb2cfybeRf2r93tILwwvmPRf+lL0Nu77st7O7u+X4/TrXzevbDC6NL6l77vu98P01zSj7J9XdfXzfF7zs0e/Cib+/1rW23xe8ZS1zfRY+aq2z0fZpwRvmMqdveLuc3VNMzwteeygtcyu3cLWT9Pxj7FPTb4PeH5FR7V3ksZ/dEEWD3kn3Kn0r/FjmyvYqtXvUthW+ZqdqkWe3tARWBrea7CZ+7frOV9Hzyu+FHVRlr696Wc//hegl2XvwqD4B8uxhY0cyLGrjK7OHWXuPOsKHWUTdCRBl77Fz2VPhi07A7ew9ko8f8tZEWCt8/ynUp+Bq9sh7czaDr8LH7Pv1f3TxJT3f36u1fvet8KPvK7pe0vNj0J93ncJm4c30Y383ezwG/XG/LXr6feyvHlF2v+fnoLdpz/GjjbzN+B91P27ebkjL3D7wacEz6Uonqru/LnPj3yIteB9W93F78c3N9VjpR/zLx5Ndr/u2zPnjE0UxrnMlhRdc3+eIvxY+8sjLr/CCee/9PpK/XuHTgnf9aDJJ3UfyU+HHda6nV1K8pk3Jn+lT0+v3MnSv54/JIl/nFIeSad6/v/3AaKSRlxxJJtsrPn+OOtZ1Tk+2Z3UON7OrDiNT3iM4fbKwLnnBXuXrY2V7tf/596QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD+gh9FZWLIxuSZwgAAAABJRU5ErkJggg==";

class ImageDisplay extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render() {
    const { mode, inputs } = this.props;
    const {
      attribute,
      showAttribute,
      showDevice,
      showTangoDB,
      scale,
      textColor,
      size,
      font,
      backgroundColor,
      widgetCss
    } = inputs;

    const device = attribute.device || "device";
    const CustomCss = parseCss(widgetCss).data;
    let display = "";
    const userScale = (parseFloat(scale) * 100).toString() + "%";
    if (showAttribute === "Label") display = attribute.label;
    else if (showAttribute === "Name") display = attribute.attribute;

    const style: CSSProperties = {
      whiteSpace: "pre-wrap",
      color: textColor,
      fontSize: size + "em",
      backgroundColor: backgroundColor,
      ...CustomCss,
    };

    if (font)
      style["fontFamily"] = font;
 
      return (
        <div id="ImageDisplay" className="w-100 h-100" style={style}>
          <div style={{height: "25px"}}>{showDevice ? showHideTangoDBName(showDevice, showTangoDB, device) : ""}
            {showDevice && showAttribute && "/"}
            {display}
            {(showDevice || showAttribute !== "None") && ": "}
          </div>
          <div style={{
            width: "100%",
            height: "calc(100% - 25px)"
          }}>
            {"run" === mode &&
              <GenerateImage device={attribute?.device} attribute={attribute?.attribute} scale={userScale} />
            }
            {"run" !== mode &&
              <img
                src={"data:image/png;base64," + imgSample}
                alt="Failed at fetching the device attribute"
                style={{
                  width: userScale,
                  height: userScale
                }}
              />
            }
          </div>
        </div>
      );
  }
}

export function GenerateImage({device, attribute, scale}) {
  const image = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, device, attribute);
  })

  if (!image) {
    return <div>No data found for {device}/{attribute}</div>;
  }

  try {
    const canvas = document.createElement("canvas");
    const height = image.length;
    const width = image[0].length;

    let max = Number(image[0][0]);
    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const pixel = Number(image[x][y]);
        if (pixel > max) {
          max = pixel;
        }
      }
    }

    const context = canvas.getContext("2d");
    if (context == null) {
      return <div>Context not found</div>;
    }

    const imgData = context.createImageData(width, height);

    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const value = image[x][y];
        const index = y * width * 4 + x * 4;
        const normal = 255 * (Number(value) / (max === 0 ? 1 : max));
        imgData.data[index + 0] = normal;
        imgData.data[index + 1] = normal;
        imgData.data[index + 2] = normal;
        imgData.data[index + 3] = 255;
      }
    }

    context.putImageData(imgData, 0, 0);
    let img = new Image();
    img.src = canvas.toDataURL("image/png");

    return <img
      src={img.src}
      alt="Failed at fetching the device attribute"
      style={{
        width: scale,
        height: scale
      }}
    />
  } catch(e) {
    return <div>Error found</div>
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "Image_Display",
  name: "Image Display",
  historyLimit: 1,
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    attribute: {
      label: "",
      type: "attribute",
      dataFormat: "image",
      required: true,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
        {
          name: "None",
          value: "None",
        },
      ],
    },
    showDevice: {
      type: "boolean",
      label: "Device Name",
      default: false,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    scale: {
      type: "string",
      default: "1",
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
      ],
    },
    widgetCss: {
      type: "style",
      default: "",
      label: "Custom Css"
    },
  },
};

const ImageDisplayExport = { component: ImageDisplay, definition };
export default ImageDisplayExport;