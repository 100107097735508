import { createSelector } from "reselect";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { SelectedSynopticState } from "../reducers/selectedSynoptic";

function getSelectedSynopticState(state: IRootState): SelectedSynopticState {
  return state.selectedSynoptic;
}

function getUIState(state: IRootState) {
  return state.ui;
}

function getCanvasState(state: IRootState) {
  return state.canvases;
}

function getSynopticsState(state: IRootState) {
  return state.synoptics;
}

function getUserState(state: IRootState) {
  return state.user;
}
function getNotificationsState(state: IRootState) {
  return state.notifications;
}
function getClipboardState(state: IRootState) {
  return state.clipboard;
}
export const getNotification = createSelector(
  getNotificationsState,
  (state) => state.notification
);
export const getUserName = createSelector(
  getUserState,
  (state) => state.username
);
export const getSynoptics = createSelector(
  getSynopticsState,
  (state) => state.synoptics
);

export const getSelectedSynoptic = createSelector(
  getSelectedSynopticState,
  (state) => {
    return state;
  }
);

export const getCurrentSynopticVariables = createSelector(
  getSelectedSynopticState,
  getSynopticsState,
  (selected, synoptics) =>
    synoptics.synoptics.filter((dash) => dash.id === selected.id)[0]
      ? synoptics.synoptics.filter((dash) => dash.id === selected.id)[0]
          .variables
      : undefined
);

export const getCurrentSynoptic = createSelector(
  getSelectedSynopticState,
  getSynoptics,
  (selected, synoptics) =>
    synoptics.filter((dash) => dash.id === selected.id)[0]
);

export const getClipboardPasteCounter = createSelector(
  getClipboardState,
  (state) => {
    return state.pasteCounter;
  }
);
export const getUserGroups = createSelector(
  getUserState,
  (state) => state.userGroups || []
);

export const getMode = createSelector(getUIState, (ui) => ui.mode);

export const getCanvases = createSelector(getCanvasState, (state) =>
  Object.keys(state).map((id) => state[id])
);
export const getInspectorCollapsed = createSelector(
  getUIState,
  (state) => state.inspectorCollapsed
);
export const getLibraryCollapsed = createSelector(
  getUIState,
  (state) => state.libraryCollapsed
);

export const getSelectedCanvas = createSelector(
  getUIState,
  getCanvasState,
  (ui, canvas) => canvas[ui.selectedCanvas]
);
