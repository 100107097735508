import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";

import { Dashboard, Variable, Widget } from "../../types";
import { bundleForWidget, definitionForWidget } from "../../widgets";
import ErrorBoundary from "../ErrorBoundary";
import TangoAPI from "../../../shared/api/tangoAPI";

import {
  getUsername,
  getIsLoggedIn,
} from "../../../shared/user/state/selectors";
import { getTangoDBName } from "../../../shared/state/selectors/database";

import { getSelectedDashboard, getDashboards, getCurrentDashoardVariables } from "../../../shared/state/selectors";

import {
  mapVariableNameToDevice
} from "../../../shared/utils/DashboardVariables";

import {
  AttributeValue,
  enrichedInputs,
  AttributeMetadata,
  DeviceMetadata
} from "../../runtime/enrichment";

import {
  extractFullNamesFromWidgets,
  extractDeviceNamesFromWidgets
} from "../../runtime/extraction";

import "./RunCanvas.css";

import { saveNotification } from "../../../shared/user/state/actionCreators";
import { calculateInnerWidgetAlignment, getAllInnerWidgetsById, isWidgetValid } from "../../../shared/utils/canvas";

import {
  Notification,
  NotificationLevel
} from "../../../shared/notifications/notifications";
import { getWidgets } from "../../../shared/state/selectors";

import deprecategif from '../../assets/deprecategif.gif';
import { Button, Spinner } from "react-bootstrap";
import Modal from "../../../shared/modal/components/Modal/Modal";
import { IRootState } from "../../../shared/state/reducers/rootReducer" ;
import { generateUUID } from "../../../shared/utils/generateUUID";
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath, getTangoDBFilteredDevices } from "../../runtime/utils";
import { splitFullPath } from "../../DBHelper"
import { WIDGET_VALID, WIDGET_WARNING } from "../../../shared/state/actions/actionTypes";
import { getDeviceNames } from "../../../shared/state/selectors/deviceList";
import { filterMissingDevices } from "../../utils";
import { getSubs } from "../../../shared/utils/getSubs";

const config = window['config'];

const TILE_SIZE: number = config.MIN_WIDGET_SIZE;
export const HISTORY_LIMIT = 1000;
let historyLimit = {}
interface RuntimeErrorDescriptor {
  type: "warning" | "error";
  message: string;
}

let currentUserName = "";

function RuntimeErrors(props: { errors: RuntimeErrorDescriptor[] }) {
  let { errors } = props;
  errors = errors.filter(error => error.message !== "");

  if (errors.length === 0) {
    return null;
  }

  return errors.length === 0 ? null : <div className="RuntimeErrors"> </div>;
}

function ErrorWidget({ error }) {
  return (
    <div
      style={{
        backgroundColor: "pink",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "small"
      }}
    >
      <span className="fa fa-exclamation-triangle" />
      ️️ {String(error)}
    </div>
  );
}

type TangoDBWithDevices = {
  [key: string]: string[];
}

interface StateProps {
  username?: string;
  isLoggedIn?: boolean;
  widgets: Widget[];
  tangoDB: string;
  devices?: string[];
  selectedDashboard?: Dashboard;
  dashboards?: Dashboard[];
  dashboardVariables?: Variable[];
}

type Props = StateProps & DispatchProps;

interface State {
  attributeValues: Record<string, AttributeValue>;
  attributeHistories: Record<string, AttributeValue[]>;
  attributeMetadata: Record<string, AttributeMetadata> | null;
  deviceMetadata: Record<string, DeviceMetadata> | null;
  t0: number;
  runtimeErrors: RuntimeErrorDescriptor[];
  unrecoverableError: boolean;
  hasInitialized: boolean;
  showModal: boolean;
  showWarningModal: boolean;
}

class RunCanvas extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      attributeValues: {},
      attributeHistories: {},
      attributeMetadata: null,
      deviceMetadata: null,
      t0: Date.now() / 1000,
      hasInitialized: false,
      unrecoverableError: false,
      runtimeErrors: [],
      showModal: false,
      showWarningModal: false,
    };

    this.resolveAttributeValue = this.resolveAttributeValue.bind(this);
    this.resolveDeviceMetadata = this.resolveDeviceMetadata.bind(this);
    this.resolveAttributeMetadata = this.resolveAttributeMetadata.bind(this);
    this.resolveAttributeHistories = this.resolveAttributeHistories.bind(this);

    this.writeAttribute = this.writeAttribute.bind(this);
    this.handleInvalidation = this.handleInvalidation.bind(this);
  }

  public async componentDidMount() {
    try {
      await this.initialize();
    } catch (error) {
      this.setState({
        hasInitialized: true,
        unrecoverableError: true
      });
      //Add error when error string is not null
      // if ("" !== error && undefined !== error.length) {
      //   this.setState({
      //     runtimeErrors: [
      //       ...this.state.runtimeErrors,
      //       { type: "error", message: error }
      //     ]
      //   });
      // }
    }
  }

  public async componentDidUpdate() {
    if (!this.state.hasInitialized) {
      try {
        await this.initialize();
      } catch (error) { }
    }
  }

  /**
   * This returns all the possible devices for dashboard variables
   *
   * @param fullNames
   */
  async getAllTangoClassDevices(tangoDB) {
    let response: any[] = [];

    //Populate all possible devices from tango class for all variables
    if (!this.props.dashboardVariables) return response;

    for (const variable of this.props.dashboardVariables) {
      const required_tangoClass = variable.class;
      const tangoClass = await TangoAPI.fetchClassAndDevices(
        tangoDB,
        required_tangoClass
      );
      const devices = tangoClass[0]?.devices.map(c => {
        return c.name;
      });
      response = response.concat(devices);
    }
    return response;
  }

  private async initialize() {
    const { username, isLoggedIn } = this.props;
    let widgets = [...this.props.widgets];
    this.checkIsDisabled(widgets);
    historyLimit = {}
    try {

      widgets = getAllInnerWidgetsById(widgets, true);
      const types = widgets?.filter((widget) => widget.type === "COMMAND_EXECUTOR" || widget.type === "COMMAND_WRITER");
      if (types.length > 0) this.setState({ showModal: true });

      widgets = widgets.filter(widget => {
        if (WIDGET_WARNING === widget.valid)
          this.setState({showWarningModal: true});

        return isWidgetValid(widget);
      })

      let fullNames = extractFullNamesFromWidgets(widgets);
      if (isLoggedIn && username) currentUserName = username;

      fullNames = mapVariableNameToDevice(
        fullNames,
        this.props?.selectedDashboard?.variables
      );

      fullNames = filterMissingDevices(this.props.devices, fullNames);
      const tangoDBs: string[] = Array.from(new Set(fullNames.map(
        (name) => {
          const tangoDB = getTangoDbFromPath(name);
          return tangoDB
        })));

      const allDevices: string[] = [];

      for await (const tangoDB of tangoDBs) {
        const devs = await this.getAllTangoClassDevices(tangoDB);
        allDevices.concat(devs);
      }

      let additionalDevices: any[] = [];

      //For all fullNames, add its equivalent entry for the corresponding dashboard variables(devices)
      fullNames.forEach(fullName => {
        const devices = allDevices.map(device => {
          return (
            device + "/" + fullName.substring(fullName.lastIndexOf("/") + 1)
          );
        });

        additionalDevices = additionalDevices.concat(devices);
      });
      this.setHistoryLimit(widgets)
      //Merge existing & additionalDevices
      fullNames = [...fullNames, ...additionalDevices];
      //Filter duplicate ones
      fullNames = fullNames.filter((v, i, a) => a.indexOf(v) === i);

      const attributeMetadata = {};
    
      const devicesByTangoDB: TangoDBWithDevices = getTangoDBFilteredDevices(fullNames);
      for await (const [tangoDB, devices] of Object.entries(devicesByTangoDB)) {
        const attributeMetadataTemp = await TangoAPI.fetchAttributeMetadata(
          tangoDB,
          devices,
        );
        Object.assign(attributeMetadata, attributeMetadataTemp);
      }

      if (attributeMetadata == null) {
        return this.reportUnrecoverableRuntimeError(
          "Failed to fetch attribute metadata. This dashboard cannot run."
        );
      }

      const deviceNames = filterMissingDevices(this.props?.devices, extractDeviceNamesFromWidgets(widgets), false);

      const devicesFromWidgetsByTangoDB: TangoDBWithDevices = getTangoDBFilteredDevices(deviceNames);

      let deviceAliases: any[] = [];
      for await (const [tangoDB, devices] of Object.entries(devicesFromWidgetsByTangoDB)) {
        const deviceAliasesTmp: any = await TangoAPI.fetchDevicesMetadata(
          tangoDB,
          devices,
        );

        if (deviceAliasesTmp)
          deviceAliases.push({ [tangoDB]: deviceAliasesTmp });
      }

      let deviceMetadata = {};
      deviceAliases = deviceAliases[0];
      if (deviceAliases) {
        for (const [tangoDB, devices] of Object.entries(deviceAliases)) {
          devices.forEach((element) => {
            if (element) {
              const fullPath = createDeviceWithTangoDBFullPath(tangoDB, element["name"]);
              let object = {};
              object[fullPath] = {
                alias: element["alias"]
              };
              Object.assign(deviceMetadata, object);
            }
          });
        }
      }

      if (deviceMetadata == null) {
        return this.reportUnrecoverableRuntimeError(
          "Failed to fetch device metadata. This dashboard cannot run."
        );
      }

      const attributeHistories = fullNames.reduce((accum, name) => {
        return { ...accum, [name]: [] };
      }, {});

      this.setState(
        { deviceMetadata, attributeMetadata, attributeHistories, hasInitialized: true },
        () => { }
      );
    } catch (e) {
      console.log("Error: ", e);
    }
  }

  public render() {
    let { widgets, username, isLoggedIn } = this.props;
    const { t0, hasInitialized, unrecoverableError } = this.state;

    if (isLoggedIn && username) currentUserName = username;

    if (!hasInitialized) {
      return <div style={{fontSize: '1.5em'}}>Loading: 
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>;
    }

    widgets = mapVariableNameToDevice(
      widgets,
      this.props.dashboardVariables
    );

    const executionContext = {
      deviceMetadataLookup: this.resolveDeviceMetadata,
      attributeMetadataLookup: this.resolveAttributeMetadata,
      attributeValuesLookup: this.resolveAttributeValue,
      attributeHistoryLookup: this.resolveAttributeHistories,
      onWrite: this.writeAttribute,
      onInvalidate: this.handleInvalidation
    };

    const widgetsToRender = unrecoverableError
      ? []
      : widgets
        .sort((a, b) => a.order - b.order)
        .map(widget => {
          const { component, definition } = bundleForWidget(widget);
          const { x, y, id, width, height } = widget;

          const actualWidth = width * TILE_SIZE;
          const actualHeight = height * TILE_SIZE;

          let element: ReactNode;
          let overflow = true;
          try {
            const inputs = enrichedInputs(
              widget.inputs,
              definition.inputs,
              executionContext
            );

            if (inputs['overflow'] !== undefined) overflow = inputs['overflow'];
            const innerWidgets = 'BOX' === widget.type ? this.getInnerWidgets(widget, executionContext, t0) : [];

            const props = {
              mode: "run",
              inputs,
              actualWidth,
              actualHeight,
              t0,
              id: widget.id,
              tangoDB: this.props.tangoDB,
              innerWidgets: innerWidgets,
              updateState: (obj) => this.setState(obj)
            };
            element = React.createElement(component, props);
          } catch (error) {
            element = <ErrorWidget error={error} />;
          }

          const left = 1 + x * TILE_SIZE;
          const top = 1 + y * TILE_SIZE;

          return (
            <div
              key={id}
              className="Widget"
              aria-disabled={widget.disabled}
              style={{
                left,
                top,
                width: actualWidth,
                height: actualHeight,
                overflow: overflow ? "auto" : "hidden"
              }}
            >
              <ErrorBoundary>{element}</ErrorBoundary>
            </div>
          );
        });
    return (
      <div className="Canvas run">
        {this.state.showModal ?
          <Modal title={""} transparentModal={true}>
            <Modal.Body style={{ overflow: "hidden" }}>
              <span className="fa fa-exclamation-triangle span-icon-lib"
                style={{ color: "red", position: "absolute" }} />
              <span className="fa fa-exclamation-triangle span-icon-lib"
                style={{ color: "red", position: "absolute", marginLeft: "450px" }} />
              <div style={{
                textAlign: "center"
              }}>

                This dashboard is using old deprecated widgets.<br /> "COMMAND_EXECUTOR" or "COMMAND_WRITER" <br />Please replace them like this example:
              </div>
              <br />
              <img width={"600px"} height={"300px"} src={deprecategif} alt="loading" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="btn-close"
                variant="primary"
                onClick={() => this.closeModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal> : null
        }

        {this.state.showWarningModal ?
          <Modal title={""} transparentModal={true}>
            <Modal.Body style={{ overflow: "hidden" }}>
              <div className="alert alert-warning" role="alert">
                The widgets in light gray will not be available in run mode as some variables are missing.
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="btn-close"
                variant="primary"
                onClick={() => this.setState({showWarningModal: false})}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal> : null
        }
        <RuntimeErrors errors={this.state.runtimeErrors} />
        {widgetsToRender}
      </div>
    );
  }

  checkIsDisabled(widgets) {
    widgets.forEach(widget => {
      if (widget.type === "BOX" && widget.innerWidgets && 0 < widget.innerWidgets.length) {
        this.checkIsDisabled(widget?.innerWidgets);
      } else {
        let disabled: boolean = false;
        if (WIDGET_VALID !== widget.valid) {
          const attrs = getSubs([widget]);
          const tmp = filterMissingDevices(this.props.devices, attrs!)
          disabled = tmp && tmp?.length === 0 ? true : false;
        }

        widget.disabled = disabled;
      }
    })
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  getInnerWidgets(boxWidget: Widget, executionContext, t0) {
    const innerWidgets = boxWidget.innerWidgets || [];
    const alignment = calculateInnerWidgetAlignment(boxWidget, TILE_SIZE)

    return innerWidgets
      .sort((a, b) => a.order - b.order)
      .map((widget: Widget, i) => {
        const { component, definition } = bundleForWidget(widget);
        const { id } = widget;
        let { x, y } = widget;
        x = alignment[i].x;
        y = alignment[i].y;

        const actualWidth = alignment[i].width;
        const actualHeight = alignment[i].height;

        let element: ReactNode;
        let overflow = true;
        try {
          const inputs = enrichedInputs(
            widget.inputs,
            definition.inputs,
            executionContext
          );

          if (inputs['overflow'] !== undefined) overflow = inputs['overflow'];
          const innerWidgets = 'BOX' === widget.type ? this.getInnerWidgets(widget, executionContext, t0) : [];

          const props = {
            mode: "run",
            inputs,
            actualWidth,
            actualHeight,
            t0,
            id: widget.id,
            tangoDB: this.props.tangoDB,
            innerWidgets: innerWidgets,
            updateState: (obj) => this.setState(obj)
          };
          element = React.createElement(component, props);
        } catch (error) {
          element = <ErrorWidget error={error} />;
        }

        const left = 1 + x * TILE_SIZE;
        const top = 1 + y * TILE_SIZE;

        return (
          <div
            key={id}
            className="Widget"
            aria-disabled={widget.disabled}
            style={{
              left,
              top,
              width: actualWidth,
              height: actualHeight,
              overflow: overflow ? "auto" : "hidden",
            }}
          >
            <ErrorBoundary>{element}</ErrorBoundary>
          </div>
        );
      });
  }

  private resolveAttributeValue(name: string) {
    return this.state.attributeValues[name] || {};
  }

  private resolveDeviceMetadata(name: string) {
    const { deviceMetadata } = this.state;
    if (deviceMetadata == null) {
      throw new Error("trying to resolve device metadata before initialised");
    }
    return deviceMetadata[name];
  }

  private resolveAttributeMetadata(name: string) {
    const { attributeMetadata } = this.state;
    if (attributeMetadata == null) {
      throw new Error(
        "trying to resolve attribute metadata before initialised"
      );
    }
    return attributeMetadata[name];
  }

  private resolveAttributeHistories(name: string) {
    return this.state.attributeHistories[name] || [];
  }

  private reportRuntimeWarning(message: string) {
    const notification: Notification = {
      username: currentUserName,
      level: NotificationLevel.WARNING,
      message: message,
      notified: false,
      timestamp: Date.now().toString(),
      key: generateUUID()
    };

    this.props.onSaveNotification(notification, currentUserName);
  }

  private reportUnrecoverableRuntimeError(message: string): void {

    const notification: Notification = {
      username: currentUserName,
      level: NotificationLevel.ERROR,
      message: message,
      notified: false,
      timestamp: Date.now().toString(),
      key: generateUUID()
    };

    this.props.onSaveNotification(notification, currentUserName);
    this.setState({ unrecoverableError: true });
  }


  private async writeAttribute(
    device: string,
    attribute: string,
    value: any
  ): Promise<void> {
    let result: any;

    const [tangoDB, deviceName] = splitFullPath(device);

    try {
      result = await TangoAPI.setDeviceAttribute(
        tangoDB,
        deviceName,
        attribute,
        value
      );
    } catch (err) {
      return;
    }

    const { ok, attribute: attributeAfter } = result;
    if (ok) {
      this.recordAttribute(
        device,
        attribute,
        attributeAfter.value,
        attributeAfter.writevalue,
        attributeAfter.quality,
        attributeAfter.timestamp,
      );
    } else {
      this.reportRuntimeWarning(
        `Couldn't set attribute "${attribute}" on "${device}" to ${JSON.stringify(
          value
        )}`
      );
    }
  }

  private async handleInvalidation(fullNames: string[]) {
    const attributes = await TangoAPI.fetchAttributesValues(
      this.props.tangoDB,
      fullNames
    );

    for (const attribute of attributes) {
      const { device, name, value, writevalue, quality, timestamp } = attribute;
      this.recordAttribute(device, name, value, writevalue, quality, timestamp);
    }
  }

  private setHistoryLimit(widgets: Widget[]) {
    widgets.forEach(widget => {
      const definition = definitionForWidget(widget);
      // extract fullnames from each widget
      let fullNamesWidget = extractFullNamesFromWidgets([widget]);
      for (let i = 0; i < fullNamesWidget.length; i++) {
        if (fullNamesWidget[i] in historyLimit) {
          // compare the historylimit and assign the highest number
          if (definition.historyLimit !== undefined) {
            historyLimit = {
              ...historyLimit,
              [fullNamesWidget[i]]: Math.max(historyLimit[fullNamesWidget[i]], definition.historyLimit)
            }
          } else {
            historyLimit = {
              ...historyLimit,
              [fullNamesWidget[i]]: HISTORY_LIMIT
            }
          }
        } else {
          historyLimit = {
            ...historyLimit,
            [fullNamesWidget[i]]: definition.historyLimit === undefined ? HISTORY_LIMIT : definition.historyLimit
          }
        }
      }
    })
  }

  private recordAttribute(
    device: string,
    attribute: string,
    value: any,
    writeValue: any,
    quality: string,
    timestamp: number,
  ): void {
    const { attributeValues, attributeHistories } = this.state;
    const valueRecord = { value, writeValue, timestamp, quality };

    const fullName = `${device}/${attribute}`;
    const newAttributeValues = {
      ...attributeValues,
      [fullName]: valueRecord
    };

    const attributeHistory = attributeHistories[fullName];
    if (attributeHistory !== undefined) {
      const newHistory = [...attributeHistory, valueRecord];

      if (attributeHistory.length > 0) {
        const lastFrame = attributeHistory.slice(-1)[0];

        if (lastFrame.timestamp == null) {
          throw new Error("timestamp is missing");
        }

        if (lastFrame.timestamp >= timestamp) {
          return;
        }
      }

      const shortenedHistory =
        newHistory.length > historyLimit[fullName]
          ? newHistory.slice(-historyLimit[fullName])
          : newHistory;

      const newAttributeHistories = {
        ...attributeHistories,
        [fullName]: shortenedHistory
      };

      this.setState({
        attributeValues: newAttributeValues,
        attributeHistories: newAttributeHistories
      });
    }
  }
}

interface DispatchProps {
  onSaveNotification: (notification: Notification, username: string) => void;
}

function mapStateToProps(state:IRootState): StateProps {
  return {
    username: getUsername(state),
    isLoggedIn: getIsLoggedIn(state),
    widgets: getWidgets(state),
    tangoDB: getTangoDBName(state),
    dashboards: getDashboards(state),
    devices: getDeviceNames(state),
    selectedDashboard: getSelectedDashboard(state),
    dashboardVariables: getCurrentDashoardVariables(state)
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onSaveNotification: (notification: Notification, username: string) =>
      dispatch(saveNotification(notification, username))
  };
}

export default connect<StateProps, DispatchProps, Props, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(RunCanvas);
