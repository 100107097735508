/* eslint-disable jsx-a11y/anchor-is-valid */


import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
  logout,
  openLoginDialog
} from "../../../../shared/user/state/actionCreators";

import {
  getIsLoggedIn,
  getUsername,
  getAwaitingResponse,
  getLoginFailure
} from "../../../../shared/user/state/selectors";
import "./LogInOut.css";

import preval from 'preval.macro';

var buildDate = `Build date: ` + preval`module.exports = new Date().toUTCString();`;

const WhenLoggedIn = ({ username, onPressLogout, version }) => (
  <Fragment>
    <a
      className="versionCSS"
      title={buildDate}
      href="https://taranta.readthedocs.io/en/latest/history.html"
      target="_blank"
      rel="noreferrer"
    >
      {version}
    </a>
    <span style={{ fontWeight: "bold" }}>{username}</span>{" "}
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        onPressLogout();
      }}
    >
      Log Out
    </a>
  </Fragment>
);

const WhenLoggedOut = ({ onPressLogin, version }) => (
  <Fragment>
    <a className="versionCSS" title={buildDate}>{version} </a>
    Not logged in.{" "}
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        onPressLogin();
      }}
    >
      Log In
    </a>
  </Fragment>
);

interface State {
  showingModal: boolean;
  username: string;
  password: string;
}

type Props = StateProps & DispatchProps;

class LogInOut extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showingModal: false,
      username: "",
      password: ""
    };
  }

  public render() {
    const {
      username,
      isLoggedIn,
      awaitingResponse,
      onPressLogout,
      onPressLogin
    } = this.props;

    const version = `${process.env.REACT_APP_VERSION}`;

    return awaitingResponse ? null : (
      <div className="LogInOut">
        {isLoggedIn ? (
          <WhenLoggedIn username={username} onPressLogout={onPressLogout} version={version} />
        ) : (
          <WhenLoggedOut onPressLogin={onPressLogin} version={version} />
        )}
      </div>
    );
  }
}

interface StateProps {
  isLoggedIn: boolean;
  username?: string;
  awaitingResponse: boolean;
  loginFailure: boolean;
}

interface DispatchProps {
  onPressLogin: () => void;
  onPressLogout: () => void;
}

function mapStateToProps(state): StateProps {
  return {
    isLoggedIn: getIsLoggedIn(state),
    username: getUsername(state),
    awaitingResponse: getAwaitingResponse(state),
    loginFailure: getLoginFailure(state)
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onPressLogin: () => dispatch(openLoginDialog()),
    onPressLogout: () => dispatch(logout())
  };
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(LogInOut);
