import { WIDGET_VALID } from "../state/actions/actionTypes";

const config = window['config'];
export const CUSTOM_HEIGHT = -1;
export const CUSTOM_WIDTH = -2
export const CUSTOM_HEIGHT_WIDTH = -3

export const getAllInnerWidgetsById = (widgets, widgetsAsArray = false) => {
  let widgetsObj = widgets.constructor();

  if (Object.keys(widgets).length > 0) {
    Object.values(widgets).forEach(widget => {

      if (widget.innerWidgets) {
        const innerWidgets = getAllInnerWidgetsById(widget.innerWidgets);
        widgetsObj = { ...widgetsObj, ...innerWidgets };
      }

      widgetsObj[widget.id] = widget;
    })
  }

  return widgetsAsArray ? Object.values(widgetsObj) : widgetsObj;
}

export const getAllBoxWidgets = (widgets) => {

  return getAllInnerWidgetsById(widgets, true).filter(widget => widget.type === "BOX");
}

export const retrieveBoxWidget = (widgets, returnWidgets) => {
  //the function retrieves recursively the box widgets contained from parent widgets
  let boxWidgets = Array.isArray(widgets) ? widgets.filter(widget => widget.type === "BOX") : [...[], widgets]
  //let boxWidgets = widgets.filter(widget => widget.type === "BOX"); 
  boxWidgets.forEach(boxWidget => {
      let innerBoxWidgets = Array.isArray(boxWidget.innerWidgets) ? boxWidget.innerWidgets.filter(innerWidget => innerWidget.type === "BOX") : []; 
      if(innerBoxWidgets.length){
        retrieveBoxWidget(innerBoxWidgets, returnWidgets)
      }
    }
  )
  returnWidgets.push.apply(returnWidgets, boxWidgets);
}

export const mergeObjects = (oldObject, replaceObject, isInnerWidget = false) => {
  if (Object.values(oldObject).length === 0 || Object.values(replaceObject).length === 0)
    return oldObject;

  Object.values(oldObject).forEach((widget, i) => {
    if (Object.keys(replaceObject).length > 0) {
      if (undefined !== widget && widget.innerWidgets) {
        if (undefined !== replaceObject[widget.id] && widget.id === replaceObject[widget.id].id) {
          // Merge Box widget configs
          if (isInnerWidget) {
            oldObject[i] = replaceObject[widget.id];
          } else {
            const temp = {};
            temp[widget.id] = replaceObject[widget.id];
            oldObject = {...oldObject, ...temp};
          }
          delete replaceObject[widget.id];
        } else {
          // Merge inner widgets
          widget.innerWidgets = mergeObjects(widget.innerWidgets, replaceObject, true);
        }
      } else if (undefined !== replaceObject) {
        if (isInnerWidget) {

          Object.values(replaceObject).forEach((replaceWidget, repKey) => {
            if (replaceWidget.id === widget.id) {
              oldObject[i] = replaceWidget;
              delete replaceObject[replaceWidget.id];
            }
          });
        } else {
          const matchingKeys = Object.keys(replaceObject).filter(k => k in oldObject);
          if(matchingKeys.length > 0) {
            oldObject = { ...oldObject, ...replaceObject };
          }
        }
      }
    }
  })

  return oldObject;
}

/* 
Calculate the order of the InnerWidget based on the parent BoxWidget dimension, InnerWidget
position and the TILE_SIZE. 
It returns the array of the inner widget with the new order
*/
export const calculateInnerWidgetsOrder = (parentWidget, innerWidget, x, y, TILE_SIZE) => {
  const innerWidgets =  parentWidget.innerWidgets || [];
  //calculate the actual alignment of the InnerWidget
  const alignment = calculateInnerWidgetAlignment(parentWidget, TILE_SIZE, "edit")

  let orderedInnerWidget = []; 
  if(innerWidgets.length !== 0)
  {
    //order the inner widgets
    innerWidgets
    .sort((a, b) => a.order-b.order)
    .forEach((innerWidget, i) => {
      orderedInnerWidget[i] = {"id": innerWidget.id, "order": innerWidget.order, "y": (alignment[i].y + parentWidget.y) * 1.15, "x": (alignment[i].x + parentWidget.x) * 1.15} //using 1.15 increases the space between the inner widgets and adds more flexibiltiy to drop the new widget
    })
  }
  if(parentWidget.inputs.layout === "vertical")
  {
    let biggerYWidgets = orderedInnerWidget.filter(biggerYWidget => biggerYWidget.y >= y);
    //there are widget with a bigger Y
    if(biggerYWidgets.length !== 0)
    {
      let newWidgetOrder = biggerYWidgets[0].order;
      //increase the order to the widgets that have a bigger Y value
      let biggerYWidgetId = biggerYWidgets.map(function(item){ return item.id});
      
      parentWidget.innerWidgets.forEach(innerWidget => {
        if(biggerYWidgetId.includes(innerWidget.id))
        {
          //increase the order of the order of the widgets having a bigger Y
          innerWidget.order++
        }
      })
      //assign the order to widget dropped
      innerWidget.order = newWidgetOrder
    }
    else //is the last element
    {
      //set the order to be the last element
      innerWidget.order = Math.max.apply(null, orderedInnerWidget.map(function(item){ return item.order})) + 1; 
    }
  }
  else if(parentWidget.inputs.layout === "horizontal")
  {
    let biggerXWidgets = orderedInnerWidget.filter(biggerXWidget => biggerXWidget.x >= x);
    //there are widget with a bigger x
    if(biggerXWidgets.length !== 0)
    {
      let newWidgetOrder = biggerXWidgets[0].order;
      //increase the order to the widgets that have a bigger X value
      let biggerXWidgetId = biggerXWidgets.map(function(item){ return item.id});
      
      parentWidget.innerWidgets.forEach(innerWidget => {
        if(biggerXWidgetId.includes(innerWidget.id))
        {
          //increase the order of the order of the widgets having a bigger X
          innerWidget.order++
        }
      })
      //assign the order to widget dropped
      innerWidget.order = newWidgetOrder
    }
    else //is the last element
    {
      //set the order to be the last element
      innerWidget.order = Math.max.apply(null, orderedInnerWidget.map(function(item){ return item.order})) + 1; 
    }
  }

  return orderedInnerWidget;
}

/*
calculateInnerWidgetAlignment calculate the alignment of the inner widgets. It need the box widget with 
the inner widgets contained, the TILE_SIZE and the mode (edit or run). If not passed, the mode in "run"
The function return an array. Each element of the array contains the size: 
x, y, width and height of each inner widget. 
*/
export const calculateInnerWidgetAlignment = (boxWidget, TILE_SIZE, mode = "run", defaultView = false) => {
  let alignment = []
  const innerWidgets =  boxWidget.innerWidgets || [];
  //it calculates the alignment only if the innerwidget array in the box widget is not empty. 
  if(innerWidgets.length !== 0)
  {
    //retrieve the size of the big widget defined by the user
    const BIG_SLOT = boxWidget.inputs.bigWidget; 
    //retrieve the size of the small widget defined by the user
    const LITTLE_SLOT = boxWidget.inputs.smallWidget; 
    //define the list of the widget considered BIG
    const BIG_WIDGET = config.BIG_WIDGET
    //retrive the outer size of the box widget
    const {width, height} = boxWidget; 
    //based on the innerwidget and the types of widget defined in the box widget (small or big), calculate the available size of a single slot  
    const slots = innerWidgets.filter(element => BIG_WIDGET.includes(element.type)).length * BIG_SLOT + innerWidgets.filter(element => !BIG_WIDGET.includes(element.type)).length * LITTLE_SLOT;
    //if the user defined a title and a padding aroud the title, it must be subtracted in the avaible space. 
    let titleSize = boxWidget.inputs.title === '' ? 0 : (( boxWidget.inputs.textSize + 2* boxWidget.inputs.padding )  );
    //if the TILE_SIZE is minor of 15, the title must be bigger
    if(TILE_SIZE < 15)
    {
      titleSize = titleSize * 2
    }
    //if the user defined a border, it must be subtracted in the avaible space. 
    const border = boxWidget.inputs.borderWidth;
    //the width and heigth margin are different in edit and run mode 
    const widthMargin = mode === "edit" ? 0.4 : 0; 
    const heightMargin = (mode === "edit" ? height * 0.08 : 0) + widthMargin; 
    //it calculates the size base on all the previous considerations 
    const availableHeight = (height - titleSize - (border / TILE_SIZE)) - heightMargin
    const availablewidth = (width -  (border / TILE_SIZE)) - 2 * widthMargin 
    const slot_height = Math.trunc((availableHeight * TILE_SIZE) / slots);
    const slot_width = Math.trunc((availablewidth * TILE_SIZE) / slots);


    let heightWidget; 
    let widthWidget; 
    let xWidget; 
    let yWidget; 
    let hasCustomSize = false;

    if(boxWidget.inputs.layout === "vertical" )
    {
        innerWidgets
       .sort((a, b) => { return a.order - b.order})
       .forEach((widget, i) => {

        if (widget.percentage && !defaultView) {
          hasCustomSize = true;

          if (0 <= widget.percentage) {
            heightWidget = (availableHeight * TILE_SIZE) * (widget.percentage/100);
            widthWidget = width * TILE_SIZE - 2*(border) - 2* widthMargin * TILE_SIZE;

          } else {
            switch (Number(widget.percentage)) {
              case CUSTOM_HEIGHT:
                heightWidget = widget.height * TILE_SIZE;
                widthWidget = width * TILE_SIZE - 2*(border) - 2* widthMargin * TILE_SIZE;
                break;
              case CUSTOM_WIDTH:
                widthWidget = widget.width * TILE_SIZE;
                heightWidget = slot_height * (BIG_WIDGET.includes(widget.type) ? BIG_SLOT : LITTLE_SLOT);
                break;
              case CUSTOM_HEIGHT_WIDTH:
                heightWidget = widget.height * TILE_SIZE;
                widthWidget = widget.width * TILE_SIZE;
                break;
              default:
                //default here
            }
          }
        } else {
          heightWidget = (BIG_WIDGET.includes(widget.type) ? slot_height * BIG_SLOT : slot_height * LITTLE_SLOT);
          widthWidget = width * TILE_SIZE - 2*(border) - 2* widthMargin * TILE_SIZE
        }

         if(i === 0){ //if vertical, the first element is in y=0 position, but it need to consider it there is the border and the margin
          yWidget = 0 + titleSize + border/TILE_SIZE + heightMargin
         }
         if(i > 0)
         {//the elements i > 0 follow the previous one
          yWidget = ((alignment[i  - 1].y) + (alignment[i  - 1].height/TILE_SIZE))
         }
         //if vertical, the x = 0. It need only to check if there is a border or margin. 
         xWidget = 0 + (border / TILE_SIZE) + widthMargin; 

         //add the size in the alignment array. 
         alignment[i] = {"height": heightWidget, "width": widthWidget, "x": xWidget, "y": yWidget, type: widget.type}
       })
      }
      else if(boxWidget.inputs.layout === "horizontal" )
      {
        innerWidgets
       .sort((a, b) => a.order - b.order)
       .forEach((widget, i) => {

          if (widget.percentage && !defaultView) {
            hasCustomSize = true;
            if (0 <= widget.percentage) {
              widthWidget = (availablewidth * TILE_SIZE) * (widget.percentage/100);
              heightWidget = height * TILE_SIZE - 2*(border) - heightMargin * TILE_SIZE - titleSize * TILE_SIZE;

            } else {
              switch (Number(widget.percentage)) {
                case CUSTOM_HEIGHT:
                  heightWidget = widget.height * TILE_SIZE;
                  widthWidget = slot_height * (BIG_WIDGET.includes(widget.type) ? BIG_SLOT : LITTLE_SLOT);
                  break;
                case CUSTOM_WIDTH:
                  widthWidget = widget.width * TILE_SIZE;
                  heightWidget = height * TILE_SIZE - 2*(border) - heightMargin * TILE_SIZE - titleSize * TILE_SIZE
                  break;
                case CUSTOM_HEIGHT_WIDTH:
                  heightWidget = widget.height * TILE_SIZE;
                  widthWidget = widget.width * TILE_SIZE;
                  break;
                default:
                  //default here
              }
            }
          } else {
            heightWidget = height * TILE_SIZE - 2*(border) - heightMargin * TILE_SIZE - titleSize * TILE_SIZE
            widthWidget = (BIG_WIDGET.includes(widget.type) ? slot_width * BIG_SLOT : slot_width * LITTLE_SLOT);
          }

          if(i === 0){ //if horizontal, the first element is in x=0 position, but it need to consider it there is the border and the margin
            xWidget = 0 + border/TILE_SIZE + widthMargin
          }
          if(i > 0)
          {//the elements i > 0 follow the previous one
            xWidget = ((alignment[i  - 1].x) + (alignment[i  - 1].width/TILE_SIZE))
          }
          //if vertical, the y = 0. It need only to check if there is a border or margin. 
          yWidget = 0 + titleSize + (border / TILE_SIZE) + heightMargin; 

          //add the size in the alignment array. 
          alignment[i] = {"height": heightWidget, "width": widthWidget, "x": xWidget, "y": yWidget, type: widget.type}
        })
      }

      const spaceAlignment = boxWidget.inputs.alignment;
      // By default all inner widgets are left aligned
      if (hasCustomSize && 'Left' !== spaceAlignment) {
        let totalSize = 0;

        if("vertical" === boxWidget.inputs.layout) {
          innerWidgets
          .sort((a, b) => a.order - b.order)
          .forEach((widget, i) => {
            totalSize += parseFloat(widget.height);
          });

          if (totalSize < availableHeight) {
            let diff = Number(parseFloat(availableHeight - totalSize)).toFixed(3);
            diff = "Center" === spaceAlignment ? diff/2 : diff;

            innerWidgets
            .forEach((widget, i) => {
              alignment[i].y = parseFloat(Number(alignment[i].y+parseFloat(diff)).toFixed(3))
            });
          }
        } else {
          innerWidgets
          .sort((a, b) => a.order - b.order)
          .forEach((widget, i) => {
            totalSize += parseFloat(widget.width);
          });

          if (totalSize < availablewidth) {
            let diff = Number(parseFloat(availablewidth - totalSize)).toFixed(3);
            diff = "Center" === spaceAlignment ? diff/2 : diff;

            innerWidgets
            .forEach((widget, i) => {
              alignment[i].x = parseFloat(Number(alignment[i].x+parseFloat(diff)).toFixed(3));
            });
          }
        }
      }
  }

  return alignment;
};

export const hasBoxWidget = (widgets) => {
 return getAllInnerWidgetsById(widgets, true).filter(widget => "BOX" === widget.type && widget.innerWidgets.length > 0).length > 0 ? true : false;
}

export const getParentBoxWidget = (bWidgets, selectedWidgetId) => {
  let parentWidget = null;

  if (0 === bWidgets.length || undefined === selectedWidgetId)
    return parentWidget;

  Object.values(bWidgets).forEach(bWidget => {
    if (bWidget.innerWidgets) {
      for (let i=0; i<bWidget.innerWidgets.length; i++) {

        const widget = bWidget.innerWidgets[i];
        if (Number(widget.id) === Number(selectedWidgetId)) {
          parentWidget = bWidget;
          break;

        } else if ("BOX" === widget.type && widget.innerWidgets && widget.innerWidgets.length > 0) {
          parentWidget = getParentBoxWidget([widget], selectedWidgetId);
        }
      }
    }
  })

  return parentWidget;
}

/**
 * This function calculates innerwidget width & height and updates the widgets
 *
 * @param  widgets
 * @param  TILE_SIZE
 *
 * @returns widgets
 */
export const getWidgetsAligned = (widgets, TILE_SIZE) => {
  widgets.forEach(widget => {

    if (widget.innerWidgets) {
      let alignment = calculateInnerWidgetAlignment(widget, TILE_SIZE, "edit");

      widget.innerWidgets.forEach((inWidget, i) => {
        inWidget.width = alignment[i].width / TILE_SIZE
        inWidget.height = alignment[i].height / TILE_SIZE

        if (inWidget.innerWidgets && inWidget.innerWidgets.length > 0) {
          let inAlignment = calculateInnerWidgetAlignment(inWidget, TILE_SIZE, "edit");

          inWidget.innerWidgets.forEach((inWidget1, j) => {
            inWidget1.width = inAlignment[j].width / TILE_SIZE
            inWidget1.height = inAlignment[j].height / TILE_SIZE
          })
        }
      })
    }
  });

  return widgets;
}

/**
 * the function check if the parent box widget is hovering the nested box widget
 *
 * @param {Widget} element
 * @param {Integer} widgetId
 * @param {boolean} checkNested
 *
 * @returns
 */
export const isBoxWidgetContained = (element, widgetId , checkNested = false) => {
  let isContained = false

  if(element.type === "BOX" && element.innerWidgets !== undefined) {
    for (let i=0; i<element.innerWidgets.length; i++) {
      const innerWidget = element.innerWidgets[i];

      if(innerWidget.id === widgetId) {
        isContained =  true;
        break;
      } else if (innerWidget.type === "BOX" && innerWidget.innerWidgets !== undefined) {
        //it avoids dropping a box widget to more than 2 level nesting.
        isContained = checkNested ? isBoxWidgetContained(innerWidget, widgetId, true) : false;
      }
    }
  }

  return isContained;
}

/**
 * the function avoids to create a box widget configuration with more than 2 level nesting
 *
 * @param {Widget} element: Element to be nested / added
 * @param {Integer} widgetId: Box widget id on which element is hovered
 * @param {widget[]} BoxWidgets: List of Box widgets in the dashboard
 *
 * @returns {boolean} canBeNest: return true if it can be nested, otherwise false. 
 */
export const canBeNested = (element, widgetId, BoxWidgets) => {
  let canBeNest = true; 

  //check if the element to drop already has inner box widgets nested
  if(element.type === "BOX" && element.innerWidgets !== undefined){
    if(element.innerWidgets.filter(innerWidget => (innerWidget.type === "BOX")).length > 0)
      canBeNest = false;
  }

  //check if the widgetId is nested in a boxWidget
  if(element.type === "BOX" && Array.isArray(BoxWidgets)){
    BoxWidgets.forEach(boxWidget => {
      if(boxWidget.innerWidgets !== undefined && canBeNest)// test if the boxwidet has inner widget defined and skip the next istruction if it is already false. 
        if(boxWidget.innerWidgets.filter(innerWidget => (innerWidget.id === widgetId)).length > 0)
          canBeNest = false
    })
  }
  
  return canBeNest;
}

/**
 * Removes all box widget & its inner widget ids from selectedWidgetIds
 *
 * @param {Array} widgets
 * @param {Array} selectedWidgetIds
 * @param {boolean} isInnerWidget
 */
export function removeBoxAndInnerWidgets(widgets, selectedWidgetIds, isInnerWidget = false) {
  widgets.forEach((widget) => {

    if(-1 !== selectedWidgetIds.indexOf(widget.id) && (isInnerWidget || "BOX" === widget.type)) {
      selectedWidgetIds.splice(selectedWidgetIds.indexOf(widget.id), 1);
    }
    if("BOX" === widget.type && widget.innerWidgets && widget.innerWidgets.length > 0) {
      removeBoxAndInnerWidgets(widget.innerWidgets, selectedWidgetIds, true);
    }
  });
}

export function isWidgetValid(widget) {
  return (-1 !== config.BIG_WIDGET.indexOf(widget.type) ? true : WIDGET_VALID === widget.valid);
}
