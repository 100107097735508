import React, { Component, CSSProperties } from "react";
import { Dropdown } from 'react-bootstrap';
import _isString from 'lodash/isString';
import _has from 'lodash/has';
import AttributeWriterButton from './AttributeWriterButton'
import { WidgetProps } from "../types";
import {
    WidgetDefinition,
    AttributeInputDefinition,
    BooleanInputDefinition,
    ColorInputDefinition,
    NumberInputDefinition,
    SelectInputDefinition,
    AttributeInput,
    StringInputDefinition,
    ComplexInputDefinition,
    StyleInputDefinition
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector"
import { showHideTangoDBName } from "../../DBHelper";

interface AttributeValueComplexInput {
    title: StringInputDefinition;
    value: StringInputDefinition;
}

type Inputs = {
    attribute: AttributeInputDefinition;
    dropdownTitle: StringInputDefinition;
    submitButtonTitle: StringInputDefinition;
    writeValuesSpectrum: AttributeInputDefinition;
    writeValues: ComplexInputDefinition<AttributeValueComplexInput>;
    showDevice: BooleanInputDefinition;
    showTangoDB: BooleanInputDefinition;
    showAttribute: SelectInputDefinition;
    textColor: ColorInputDefinition;
    backgroundColor: ColorInputDefinition;
    size: NumberInputDefinition;
    font: SelectInputDefinition;
    dropdownButtonCss: StyleInputDefinition;
    submitButtonCss: StyleInputDefinition;
    customCss: StyleInputDefinition
};

type Props = WidgetProps<Inputs>;

interface State {
    pending: boolean;
    selectedValue: { title: string, value: string } | null;
}

class AttributeWriterDropdown extends Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            pending: false,
            selectedValue: null
        }
    }

    private dataType(): "numeric" | "boolean" | "string" | "enum" | "other" {
        const { attribute } = this.props.inputs;
        const { dataType, isNumeric } = attribute;
        const isBoolean = dataType === "DevBoolean";
        const isString = dataType === "DevString";
        const isEnum = dataType === "DevEnum";
        return isNumeric
            ? "numeric"
            : isBoolean
                ? "boolean"
                : isString
                    ? "string"
                    : isEnum
                        ? "enum"
                        : "other";
    }

    private getDisplay(attribute: AttributeInput, showAttribute: string): string {
        let display = "";
        if (showAttribute === "Label") {
            if (attribute.label !== "") display = attribute.label;
            else display = "attributeLabel";
        }
        else if (showAttribute === "Name") {
            if (attribute.attribute !== null) display = attribute.attribute;
            else display = "attributeName";
        }
        return display;
    }

    

    public render() {
        const { mode, inputs } = this.props;
        const {
            attribute,
            showDevice,
            showTangoDB,
            dropdownTitle,
            submitButtonTitle,
            writeValues,
            writeValuesSpectrum,
            showAttribute,
            backgroundColor,
            textColor,
            size,
            font,
            dropdownButtonCss,
            submitButtonCss,
        } = inputs;
        const { selectedValue } = this.state;
        const dropdownButtonStyle = parseCss(dropdownButtonCss).data
        const customCss = parseCss(inputs.customCss).data;

        const { device} = attribute;
        const unit = mode === "run" ? attribute.unit : "unit";
        let display = this.getDisplay(attribute, showAttribute);
        const deviceLabel = showHideTangoDBName(showDevice, showTangoDB, device, "device");

        const displayWidget = [
            ...((showDevice && mode !== "library") ? [deviceLabel] : []),
            display
        ].join("/");

        const dataType = this.dataType();
        if (
            mode === "run" &&
            dataType !== "numeric" &&
            dataType !== "string" &&
            dataType !== "boolean" &&
            dataType !== "enum"
        ) {
            return (
                <div style={{ backgroundColor: "red", padding: "0.5em" }}>
                    {attribute.dataType} is not supported.
                </div>
            );
        }

        const isInvalid = (value) => {
            return (
                dataType === "string" && !_isString(value))
                || ((dataType === "numeric" && isNaN(parseFloat(value)))
                || (dataType === "boolean" && ["true", "false", "1", "0"].indexOf(value.toLowerCase()) === -1))
                || (dataType === "enum" && isNaN(parseInt(value)))
        }

        const style: CSSProperties = {
            display: "flex",
            alignItems: "center",
            padding: "0.25em 0.5em",
            backgroundColor,
            color: textColor,
            fontSize: size + "em",
            ...customCss
        };
        if (font) {
            style["fontFamily"] = font;
        }
        let allDropdownValues = writeValues;

        if (_has(writeValuesSpectrum, 'value')) {
            const spectrumDropdownValues = writeValuesSpectrum.value.map(val => ({ 'title': val, 'value': val }));
            allDropdownValues = [...writeValues, ...spectrumDropdownValues];
        }

        const values = allDropdownValues.length > 0 ? allDropdownValues.map((input, idx) => {
            const invalid = isInvalid(input.value)

            return (
                <Dropdown.Item
                    key={idx}
                    disabled={invalid}
                    title={`write value: ${input.value} ${unit}`}
                    as="button"
                    onClick={() => this.setState({ selectedValue: input })}
                >
                    {invalid ?
                        ("Invalid type for value ")
                        : (!input.title && !input.value ?
                            ("Empty string") :
                            (input.title || input.value))
                    }
                </Dropdown.Item>
            )
        }) : (
                <Dropdown.Item
                    disabled
                >
                    No write value available
                </Dropdown.Item>
            );
        return (
            <div style={style}>
                {displayWidget && (
                    <span style={{ flexGrow: 0, marginRight: "0.5em" }}>{displayWidget}:</span>
                )}
                <Dropdown style={{ flexGrow: 1, marginRight: "0.5em" }} >
                    <Dropdown.Toggle
                        style={{
                            width: '100%',
                            ...dropdownButtonStyle
                        }}
                        variant="outline-secondary"
                        className="dropdown-toggle btn btn-sm"
                    >
                        {selectedValue ? selectedValue.title || selectedValue.value : dropdownTitle || "Dropdown"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="taranta-menu"
                    >
                        {selectedValue ?
                            <Dropdown.Item
                                key={-1}
                                as="button"
                                onClick={() => this.setState({ selectedValue: null })}
                            >
                                {/* {dropdownTitle || "Dropdown"} */}
                            Reset selection
                        </Dropdown.Item> : null
                        }
                        {values}
                    </Dropdown.Menu>
                </Dropdown>
                <AttributeWriterButton
                    device = {attribute.device}
                    attribute = {attribute.attribute}
                    selected = { selectedValue }
                    submitButtonCss = {submitButtonCss}
                    variant="secondary" 
                    type="submit"
                    className="taranta-submit-btn"
                    submitButtonTitle = {submitButtonTitle || 'Submit'} 
                    isNumeric = {attribute.isNumeric} 
                    isBoolean = {attribute.dataType === "DevBoolean"} 
                    isEnum = {attribute.dataType === "DevEnum"}
                />
            </div>
        );
    }
}

const definition: WidgetDefinition<Inputs> = {
    type: "ATTRIBUTE WRITER DROPDOWN",
    name: "Attribute Writer Dropdown",
    defaultHeight: 3,
    defaultWidth: 25,
    inputs: {
        attribute: {
            type: "attribute",
            label: "",
            dataFormat: "scalar"
        },
        submitButtonTitle: {
            type: "string",
            label: "Button Title",
            placeholder: "Text on the button"
        },
        dropdownTitle: {
            type: "string",
            label: "Dropdown Title",
            placeholder: "Text on the dropdown"
        },
        writeValues: {
            label: "Write Values",
            type: "complex",
            repeat: true,
            inputs: {
                title: {
                    type: "string",
                    label: "Label",
                    placeholder: "Text on the option",
                    default: ""
                },
                value: {
                    label: "Value",
                    type: "string",
                    placeholder: "Write value",
                    default: ""
                }
            }
        },
        writeValuesSpectrum: {
            type: "attribute",
            label: "Spectrum Attribute",
            dataFormat: "spectrum",
            required: false,
        },
        showDevice: {
            type: "boolean",
            label: "Show Device Name",
            default: true
        },
        showTangoDB: {
            type: "boolean",
            label: "Show Tango database name",
            default: false,
        },
        showAttribute: {
            type: "select",
            label: "Attribute display:",
            default: "Label",
            options: [
                {
                    name: "Label",
                    value: "Label"
                },
                {
                    name: "Name",
                    value: "Name"
                },
                {
                    name: "None",
                    value: "None"
                }
            ]
        },
        textColor: {
            label: "Text Color",
            type: "color",
            default: "#000000"
        },
        backgroundColor: {
            label: "Background Color",
            type: "color",
            default: "#ffffff"
        },
        size: {
            label: "Text size (in units)",
            type: "number",
            default: 1,
            nonNegative: true
        },
        font: {
            type: "select",
            default: "Helvetica",
            label: "Font type",
            options: [
                {
                    name: "Default (Helvetica)",
                    value: "Helvetica"
                },
                {
                    name: "Monospaced (Courier new)",
                    value: "Courier new"
                }
            ]
        },
        dropdownButtonCss: {
            type: "style",
            label: "Dropdown Title CSS"
        },
        submitButtonCss: {
            type: "style",
            label: "Submit Button CSS"
        },
        customCss: {
            type: "style",
            default: "",
            label: "Custom CSS"
        }
    }
};

const AttributeWriterDropdownExport = { definition, component: AttributeWriterDropdown };
export default AttributeWriterDropdownExport;