import React, { Component, CSSProperties } from "react";

import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  AttributeInputDefinition,
  NumberInputDefinition
} from "../../types";
import ImageTableValues from "./ImageTableValues";
import { showHideTangoDBName } from "../../DBHelper"


type Inputs = {
  showDevice: BooleanInputDefinition;
  showAttribute: BooleanInputDefinition;
  attribute: AttributeInputDefinition;
  showIndex: BooleanInputDefinition;
  showLabel: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  fontSize: NumberInputDefinition;
  rowLimit: NumberInputDefinition;
  columnLimit: NumberInputDefinition;
  rowOffset: NumberInputDefinition;
  columnOffset: NumberInputDefinition;
  showWarning: BooleanInputDefinition;
};

type Props = WidgetProps<Inputs>;

function Table(props: Props) {
  const { mode, inputs } = props;
  const {
    attribute,
    showIndex,
    showLabel,
    showDevice,
    showTangoDB,
    showAttribute,
    fontSize,
    rowLimit,
    columnLimit,
    rowOffset,
    columnOffset
  } = inputs;
  let { showWarning } = inputs;

  const mainDivStyle: CSSProperties = { marginLeft: "5px", fontSize: fontSize + "px" };

  let spanText = showDevice === true ? showHideTangoDBName(true, showTangoDB, attribute.device) + "/" : "";
  spanText += showAttribute === true ? attribute.attribute + ":" : "";

  return (
    <div style={mainDivStyle}>
      {
        showDevice || showAttribute ?
          <span className="spanStyle">{spanText}</span>
          : null
      }
      {
        <ImageTableValues
          attributeName={attribute?.attribute}
          deviceName={attribute?.device}
          mode={mode}
          showIndex={showIndex}
          showLabel={showLabel}
          rowLimit={rowLimit}
          columnLimit={columnLimit}
          rowOffset={rowOffset}
          columnOffset={columnOffset}
          showWarning={showWarning}
        />
      }
    </div>
  );
}

class ImageTable extends Component<Props> {
  public render() {
    return (
      <Table {...this.props} />
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "IMAGE_TABLE",
  name: "ImageTable",
  defaultWidth: 10,
  defaultHeight: 10,
  inputs: {
    attribute: {
      label: "",
      type: "attribute",
      dataFormat: "image",
      dataType: "numeric",
      required: true
    },
    rowLimit: {
      type: "number",
      label: "Limit rows to display (0 - no limit)",
      default: 5
    },
    columnLimit: {
      type: "number",
      label: "Limit columns to display (0 - no limit)",
      default: 5
    },
    rowOffset: {
      type: "number",
      label: "Offset on rows",
      default: 0
    },
    columnOffset: {
      type: "number",
      label: "Offset on columns",
      default: 0
    },
    showDevice: {
      type: "boolean",
      label: "Show Device",
      default: false
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "boolean",
      label: "Show Attribute",
      default: false
    },
    showIndex: {
      type: "boolean",
      label: "Show Index",
      default: false
    },
    showLabel: {
      type: "boolean",
      label: "Show Labels",
      default: false
    },
    showWarning: {
      type: "boolean",
      label: "Show Warning",
      default: true
    },
    fontSize: {
      type: "number",
      label: "Font Size (px)",
      default: 16,
      nonNegative: true
    },
  }
};

const ImageTableExport = { component: ImageTable, definition };
export default ImageTableExport;