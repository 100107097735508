import { eventChannel } from "redux-saga";
import { fork, take, put, call, cancel, cancelled, takeLatest } from "redux-saga/effects";

import {
  FETCH_DEVICE_NAMES,
  FETCH_ATTRIBUTES,
  EXECUTE_COMMAND,
  SET_DEVICE_PROPERTY,
  DELETE_DEVICE_PROPERTY,
  FETCH_DEVICE,
  FETCH_DATABASE_INFO,
  SET_DEVICE_ATTRIBUTE_SUCCESS,
  SUBSCRIBE_DEVICE_ATTRS,
  UNSUBSCRIBE_DEVICE_ATTRS,
  DEVICE_STATE_RECEIVED,
  SET_DEVICE_ATTRIBUTE,
  LOAD_TANGO_DB_NAME
} from "../actions/actionTypes";

import TangoAPI from "../../../shared/api/tangoAPI";
import {
  fetchDeviceNamesSuccess,
  fetchDeviceNamesFailed,
  fetchAttributesSuccess,
  fetchAttributesFailed,
  executeCommandSuccess,
  executeCommandFailed,
  setDevicePropertySuccess,
  setDevicePropertyFailed,
  setDeviceAttributeSuccess,
  setDeviceAttributeFailed,
  deleteDevicePropertySuccess,
  deleteDevicePropertyFailed,
  fetchDeviceSuccess,
  fetchDeviceFailed,
  fetchDatabaseInfoSuccess,
  fetchDatabaseInfoFailed,
  attributeFrameReceived,
  loadTangoDBNameSuccess,
  loadTangoDBNameFailed
} from "../actions/tango";

import { displayError } from "../actions/error";
import { getTangoDBName } from "../../utils/database";
import { createDeviceWithTangoDBFullPath } from "../../../dashboard/runtime/utils";
import { splitFullPath } from "../../../dashboard/DBHelper"

export default function* tango() {
  yield fork(fetchDeviceNames);
  yield fork(fetchAttributes);
  yield fork(executeCommand);
  yield fork(setDeviceAttribute);
  yield fork(setDeviceProperty);
  yield fork(deleteDeviceProperty);
  yield fork(fetchDevice);
  yield fork(fetchDatabaseInfo);
  yield fork(loadTangoDBName);
  yield fork(refetchDeviceStateOnAttributeWrite);
  yield fork(subscribeDeviceAttrs);
}

/* Asynchronous actions */

export function* fetchDeviceNames() {
  while (true) {
    const { tangoDB } = yield take(FETCH_DEVICE_NAMES);
    try {
      const names = yield call(TangoAPI.fetchDeviceNames, tangoDB);
      yield put(fetchDeviceNamesSuccess(names.map((name) => createDeviceWithTangoDBFullPath(tangoDB, name))));
    } catch (err) {
      yield put(fetchDeviceNamesFailed(err.toString()));
    }
  }
}

export function* fetchAttributes() {
  yield takeLatest(FETCH_ATTRIBUTES, fetchAttributesData);
}

export function* fetchAttributesData(action) {
  const { tangoDB, device } = action;

  let attributes = [];
  try {
    for (var i = 0; i < device.length; i++) {
      let attributesResults = yield call(TangoAPI.fetchDeviceAttributes, tangoDB, device[i])
      attributesResults.forEach(att => {
        if (!attributes.some(item => item.name === att.name)) {
          attributes.push(att);
        }
      });
    }
    if (attributes.length)
      yield put(fetchAttributesSuccess(attributes));
  } catch (err) {
    yield put(fetchAttributesFailed(err.toString()));
  }
}

export function* executeCommand() {
  while (true) {
    const { command, argin, device, tangoDB: tangoDBActual } = yield take(EXECUTE_COMMAND);
    let [tangoDB, currentDevice] = splitFullPath(device);
    tangoDB = tangoDB || tangoDBActual

    try {
      const { ok, output, message } = yield call(
        TangoAPI.executeCommand,
        tangoDB,
        currentDevice,
        command,
        argin
      );
      const action = ok
        ? executeCommandSuccess(tangoDB, command, output, device)
        : executeCommandFailed(tangoDB, device, command, argin, message);
      yield put(action);
    } catch (err) {
      yield put(displayError(err.toString()));
    }
  }
}

export function* setDeviceAttribute() {
  while (true) {
    const { tangoDB, device, name, value } = yield take(SET_DEVICE_ATTRIBUTE);
    try {
      const { ok, attribute } = yield call(
        TangoAPI.setDeviceAttribute,
        tangoDB,
        device,
        name,
        value
      );
      const action = ok
        ? setDeviceAttributeSuccess(tangoDB, attribute)
        : setDeviceAttributeFailed(tangoDB, device, name, value);
      yield put(action);
    } catch (err) {
      yield put(displayError(err.toString()));
    }
  }
}

export function* setDeviceProperty() {
  while (true) {
    const { tangoDB, device, name, value } = yield take(SET_DEVICE_PROPERTY);
    const ok = yield call(
      TangoAPI.setDeviceProperty,
      tangoDB,
      device,
      name,
      value
    );
    const action = ok
      ? setDevicePropertySuccess(tangoDB, device, name, value)
      : setDevicePropertyFailed(tangoDB, device, name, value);
    yield put(action);
  }
}

export function* deleteDeviceProperty() {
  while (true) {
    const { tangoDB, device, name } = yield take(DELETE_DEVICE_PROPERTY);
    const ok = yield call(TangoAPI.deleteDeviceProperty, tangoDB, device, name);
    const action = ok
      ? deleteDevicePropertySuccess(tangoDB, device, name)
      : deleteDevicePropertyFailed(tangoDB, device, name);
    yield put(action);
  }
}

export function* fetchDevice() {
  while (true) {
    const { tangoDB, name } = yield take(FETCH_DEVICE);
    const device = yield call(TangoAPI.fetchDevice, tangoDB, name);
    const action = device
      ? fetchDeviceSuccess(tangoDB, device)
      : fetchDeviceFailed(tangoDB, name);
    yield put(action);
  }
}

export function* fetchDatabaseInfo() {
  while (true) {
    const { tangoDB } = yield take(FETCH_DATABASE_INFO);
    const info = yield call(TangoAPI.fetchDatabaseInfo, tangoDB);
    const action = info
      ? fetchDatabaseInfoSuccess(tangoDB, info)
      : fetchDatabaseInfoFailed(tangoDB);
    yield put(action);
  }
}

export function* loadTangoDBName() {
  while (true) {
    yield take(LOAD_TANGO_DB_NAME);
    const tangoDBName = yield call(getTangoDBName);
    const action = tangoDBName
      ? loadTangoDBNameSuccess(tangoDBName)
      : loadTangoDBNameFailed();
    yield put(action);
  }
}

export function* refetchDeviceStateOnAttributeWrite() {
  while (true) {
    const {
      tangoDB,
      attribute: { device }
    } = yield take(SET_DEVICE_ATTRIBUTE_SUCCESS);
    const state = yield call(TangoAPI.fetchDeviceState, tangoDB, device);
    const action = { type: DEVICE_STATE_RECEIVED, device, state, tangoDB };
    yield put(action);
  }
}

/* Subscriptions */

export function createChangeEventChannel(tangoDB, fullNames, includeValues) {
  const emitter = TangoAPI.changeEventEmitter(
    tangoDB,
    fullNames,
    includeValues
  );
  return eventChannel(emitter);
}

export function* handleChangeEvents(channel) {
  try {
    while (true) {
      const frame = yield take(channel);
      const action = attributeFrameReceived(frame);
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export function* subscribeDeviceAttrs() {
  let scalarHandler = null;
  let nonScalarHandler = null;

  while (true) {
    const { tangoDB, deviceName, attributes } = yield take(SUBSCRIBE_DEVICE_ATTRS);
    const scalarAttributes = attributes.filter(
      ({ dataformat }) => dataformat === "SCALAR"
    );

    const nonScalarAttributes = attributes.filter(
      ({ dataformat }) => dataformat !== "SCALAR"
    );

    const scalarFullNames = scalarAttributes.map(
      ({ name }) => `${deviceName}/${name}`
    );
    const nonScalarFullNames = nonScalarAttributes.map(
      ({ name }) => `${deviceName}/${name}`
    );

    const scalarChannel = yield call(
      createChangeEventChannel,
      tangoDB,
      scalarFullNames
    );

    const nonScalarChannel = yield call(
      createChangeEventChannel,
      tangoDB,
      nonScalarFullNames,
      false // <- important
    );

    if (scalarHandler != null) {
      yield cancel(scalarHandler);
    }

    if (nonScalarHandler != null) {
      yield cancel(nonScalarHandler);
    }

    scalarHandler = yield fork(handleChangeEvents, scalarChannel);
    nonScalarHandler = yield fork(handleChangeEvents, nonScalarChannel);

    yield take(UNSUBSCRIBE_DEVICE_ATTRS);
    yield cancel(scalarHandler);
    yield cancel(nonScalarHandler);
  }
}
