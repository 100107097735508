import { Synoptic, Widget, Variable } from "./../types";
import tangoAPI from "../../shared/api/tangoAPI";

export const getSynopticVariables = (
  selectedSynopticId: string,
  synoptics: Synoptic[]
) => {
  let variables: Variable[] = [];
  if (
    undefined === selectedSynopticId ||
    "" === selectedSynopticId ||
    0 === synoptics.length
  ) {
    return variables;
  }

  for (let i = 0; i < synoptics.length; i++) {
    //Match the synoptic
    if (
      synoptics[i].id === selectedSynopticId &&
      undefined !== synoptics[i].variables
    ) {
      variables = synoptics[i].variables;
      break;
    }
  }
  return variables;
};

export const mapVariableNameToDevice = (
  widgets: Widget[],
  selectedSynopticId: string,
  synoptics: Synoptic[]
) => {
  if (
    0 === widgets.length ||
    0 === selectedSynopticId.length ||
    0 === synoptics.length
  ) {
    return widgets;
  }

  const synopticVariables = getSynopticVariables(
    selectedSynopticId,
    synoptics
  );

  let checkNested = function(wids) {
    wids.forEach((wid, i) => {
      if ("BOX" === wid.type) {
        checkNested(wid.innerWidgets);

      } else if("PARAMETRIC_WIDGET" !== wid.type) {
        let temp = JSON.stringify(wid);

        for (let j = 0; j < synopticVariables.length; j++) {
          const expression = new RegExp(synopticVariables[j].name, "g");
          temp = temp.replace(expression, synopticVariables[j].device);
        }
        wids[i] = JSON.parse(temp);
      }
    })
  }

  const clonedWidgets = JSON.parse(JSON.stringify(widgets));
  checkNested(clonedWidgets);

  return clonedWidgets.sort((a, b) => {
    return Number(b.id) - Number(a.id);
  });
};

export const getDeviceOfSynopticVariable = (
  variables: Variable[],
  variableName: string
) => {
  let deviceName = variableName;

  for (let i = 0; i < variables.length; i++) {
    //Match the variable
    if (variables[i].name === variableName) {
      deviceName = variables[i].device;
    }
  }

  return deviceName;
};

/**
 * This function checks if variables used in widget are defined in configuration
 *
 * @param widget
 * @param variables
 */
export const variablePresent = (widget: Widget, variables: Variable[]) => {
  let response = {
    found: true,
    device: ""
  };

  if (!variables) return response;
  if (
    widget.inputs.attribute &&
    widget.inputs.attribute.device &&
    -1 === widget.inputs.attribute.device.indexOf("/")
  ) {
    const variable = variables.find(
      variable => variable.name === widget.inputs.attribute.device
    );
    response.found = variable ? true : false;
    response.device = widget.inputs.attribute.device;
  } else if (
    widget.inputs.command &&
    widget.inputs.command.device &&
    -1 === widget.inputs.command.device.indexOf("/")
  ) {
    const variable = variables.find(
      variable => variable.name === widget.inputs.command.device
    );
    response.found = variable ? true : false;
    response.device = widget.inputs.command.device;
  } else if (widget.inputs.device && -1 === widget.inputs.device.indexOf("/")) {
    const variable = variables.find(
      variable => variable.name === widget.inputs.device
    );
    response.found = variable ? true : false;
    response.device = widget.inputs.device;
  } else if (widget.inputs.attributes && 0 < widget.inputs.attributes.length) {
    for (const attr of widget.inputs.attributes) {
      if (attr.attribute.device && -1 === attr.attribute.device.indexOf("/")) {
        const variable = variables.find(
          variable => variable.name === attr.attribute.device
        );
        if (!variable) {
          response.found = false;
          response.device = attr.attribute.device;
          break;
        }
      }
    }
  }

  return response;
};

export function getRunningClasses(tangoClasses) {
  //return the classes that has at least 1 device running
  return tangoClasses.filter(
    c => c.devices.filter(d => d.connected === true).length > 0
  );
}

export function getRunningDevices(tangoDevices) {
  //return the devices running for a Tango Class
  if (tangoDevices) {
    const runningDevices = tangoDevices.filter(d => d.connected === true);
    return runningDevices;
  } else {
    return [];
  }
}

export function checkVariableDevice(tangoDevice, tangoDevices) {
  let status = { connected: false, exported: false };
  //it checks only when all classes are fetched
  if (tangoDevices.length === 0) {
    return status;
  }
  const device = tangoDevices.find(d => d.name === tangoDevice);
  return device ? device : status;
}

export async function getDevicePropertiesFromVariables(tangoDB, variables) {
  // Fetches the attributes (name, exported, connected) for the devices present in the [variables]
  if (variables.length > 0) {
    const uniqueVariables = variables.filter(
      (value, index, self) =>
        self.findIndex(m => m.device === value.device) === index
    );
    return await tangoAPI.fetchDevicesProperty(tangoDB, uniqueVariables);
  } else {
    return [];
  }
}

export async function checkVariableConsistency(tangoDB, variables) {
  let valid = true;
  if (variables !== undefined && variables.length > 0) {
    const uniqueVariables = variables.filter(
      (value, index, self) =>
        self.findIndex(m => m.device === value.device) === index
    ); //filters out unique variables by device name
    const devices: any = await tangoAPI.fetchDevicesProperty(
      tangoDB,
      uniqueVariables
    );
    for (const device of devices) {
      if (!device?.connected || !device?.exported) {
        valid = false;
      }
    }
    return valid;
  }
  return valid;
}
