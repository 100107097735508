import React, { Component } from "react";
import Inspector from "./Inspector/Inspector";
import { Widget } from "../types";
import { connect } from "react-redux";
import { getInspectorCollapsed, getMouseDownOnWidget } from "../../shared/state/selectors";
import { IRootState } from "../../shared/state/reducers/rootReducer";
import { TOGGLE_INSPECTOR_COLLAPSED } from "../../shared/state/actions/actionTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Draggable from "react-draggable";

interface Props {
  mode: "run" | "edit";
  tangoDB: string;
  selectedWidgets: Widget[];
  inspectorCollapsed: boolean;
  mouseDownOnWidget: boolean;
  toggleInspectorCollapse: () => void;
}


class LeftSidebar extends Component<Props> {
  public render() {
    const { mode, selectedWidgets, tangoDB, inspectorCollapsed, mouseDownOnWidget } = this.props;
    if (mode === "run") {
      return null;
    }
    if (inspectorCollapsed) {
      return (
        <div className="expand-inspector">
        <div
          className="Inspector-collapsed"
          title={`Show the widget inspector \n (Alt+i)`}
          onClick={this.props.toggleInspectorCollapse}
        >
          <FontAwesomeIcon icon={"angle-right"} />{" "}
        </div>
        </div>
      );
    }

    const display = inspectorCollapsed || mouseDownOnWidget ? "none" : "block"
    return (
      <Draggable cancel={"input, svg, button, select, textarea"}>
        <div className="leftSidebar" style={{display, zIndex: 100000}}>
          <Inspector
            nbrSelectedWidgets={selectedWidgets.length}
            widgets={selectedWidgets}
            isRootCanvas={true}
            tangoDB={tangoDB}
            render={selectedWidgets.length > 0}
          />
        </div>
      </Draggable>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return { inspectorCollapsed: getInspectorCollapsed(state),
  mouseDownOnWidget: getMouseDownOnWidget(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleInspectorCollapse: () =>
      dispatch({ type: TOGGLE_INSPECTOR_COLLAPSED }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
