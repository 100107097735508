import React, { Component } from "react";

import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  ComplexInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
} from "../../types";

// In order to avoid importing the entire plotly.js library. Note that this mutates the global PlotlyCore object.
import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import PlotlyBar from "plotly.js/lib/bar";
import createPlotlyComponent from "react-plotly.js/factory";
import Spectrum2DValues from "./Spectrum2DValues";
import { showHideTangoDBName } from "../../DBHelper";


// prettier-ignore
const sampleDatax = [-10, -5, -1, 0, 4, 7, 10, 12, 16, 20, 25, 29, 32, 36, 41, 45, 50, 57, 60, 63, 68];
const sampleDatay1 = [0, -2, 3, -2, 10, 50, 4, -3, -2, -40, 100, -4, 2, 2, -20, -2, 20, -5, -2, -30, 0];
const sampleDatay2 = [-1, 2, -30, 2, -2, 50, -4, 3, -2, -40, 1, -4, 20, -2, 2, -20, 2, -5, 2, -30, 4];
const sampleDatay3 = [4, 3, 30, 2, -3, -4, -20, 3, 1, -2, 1, 1, 3, -30, -3, -1, 2, -1, -3, -20, -1];
const sampleDatay4 = [-2, -4, 10, -1, 3, -40, 4, -3, -2, 0, -3, -5, 3, 40, -5, -3, -5, 0, -50, -1, 0];
const sampleData = [sampleDatay1, sampleDatay2, sampleDatay3, sampleDatay4]

export interface AttributeComplexInput {
  attribute: AttributeInputDefinition;
}

export type Inputs = {
  attributeX: AttributeInputDefinition;
  attributes: ComplexInputDefinition<AttributeComplexInput>;
  showTitle: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
  showTangoDB: BooleanInputDefinition;
  xScientificNotation: BooleanInputDefinition;
  yScientificNotation: BooleanInputDefinition;
  xLogarithmicScale: BooleanInputDefinition;
  yLogarithmicScale: BooleanInputDefinition;
  plotStyling: SelectInputDefinition;
};

type Props = WidgetProps<Inputs>;


class Spectrum2D extends Component<Props>{
  public constructor(props: Props) {
    super(props);
  }

  public render() {
    const { mode, inputs } = this.props;
    const { attributeX, attributes, showTitle, showAttribute,
      xScientificNotation, yScientificNotation,
      xLogarithmicScale, yLogarithmicScale, plotStyling, showTangoDB } = inputs;
    let display = "";
    const deviceName = showHideTangoDBName(true, showTangoDB, attributeX.device)
    if (showAttribute === "Label") display = attributeX.label;
    else if (showAttribute === "Name") display = attributeX.attribute;
    const title =
      showTitle === false
        ? null
        : mode === "library"
          ? "device/x-attribute"
          : `${deviceName || "?"}/${display || "?"}`;

    const layout = {
      title,
      titlefont: { size: 12 },
      font: { family: "Helvetica, Arial, sans-serif" },
      margin: {
        l: 40,
        r: 15,
        t: 15 + (showTitle ? 20 : 0),
        b: 30
      },
      xaxis: {
        type: (xLogarithmicScale ? "log" : "linear"),
        fixedrange: false,
        autorange: true,
        range: [],
        exponentformat: (xScientificNotation ? "e" : "none"),
      },
      yaxis: {
        type: (yLogarithmicScale ? "log" : "linear"),
        exponentformat: (yScientificNotation ? "e" : "none"),
      },
      uirevision: true,
      autosize: true,
      barmode: 'overlay',
    };

    function registerPlotly() {
      let plot = (plotStyling === "bar") ? PlotlyBar : PlotlyScatter;
      PlotlyCore.register([plot]);
      return createPlotlyComponent(PlotlyCore);
    }

    if (mode === "library") {
      const Plotly = registerPlotly();
      const x = sampleDatax;
      const c1 = sampleData[0];
      const c2 = sampleData[1];
      const c3 = sampleData[2];
      const c4 = sampleData[3];
      const data = [{ x: x, y: c1, name: "Channel1" }, { x: x, y: c2, name: "Channel2" }, { x: x, y: c3, name: "Channel3" }, { x: x, y: c4, name: "Channel4" }];

      return (
        <div>
          <Plotly
            data={data}
            layout={layout}
            config={{ staticPlot: true }}
            responsive={true}
            style={{ width: this.props.actualWidth, height: 150 }}
          />
        </div>
      );
    }

    if (mode === "edit") {
      const Plotly = registerPlotly();
      return (
        <div>
          <Plotly
            layout={layout}
            config={{ staticPlot: true }}
            responsive={true}
            style={{ width: this.props.actualWidth, height: this.props.actualHeight }}
          />
        </div>
      );
    }

    return (
      <div id="spectrum2d-values-main-div">
        <Spectrum2DValues
          attributeX={attributeX}
          attributes={attributes}
          plotStyling={plotStyling}
          layout={layout}
          actualWidth={this.props.actualWidth}
          actualHeight={this.props.actualHeight}
        />
      </div>
    );

  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "SPECTRUM_2D",
  name: "Spectrum 2D",
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    attributeX: {
      label: "Time Base",
      type: "attribute",
      dataFormat: "spectrum",
      dataType: "numeric",
      required: true
    },
    attributes: {
      label: "Graphs",
      type: "complex",
      repeat: true,
      inputs: {
        attribute: {
          label: "",
          type: "attribute",
          required: true,
          dataFormat: "spectrum",
          dataType: "numeric"
        },

      }
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label"
        },
        {
          name: "Name",
          value: "Name"
        }
      ]
    },
    showTitle: {
      type: "boolean",
      label: "Show Title",
      default: true
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    xScientificNotation: {
      type: "boolean",
      label: "X-axis Scientific Notation",
      default: false
    },
    yScientificNotation: {
      type: "boolean",
      label: "Y-axis Scientific Notation",
      default: false
    },
    xLogarithmicScale: {
      type: "boolean",
      label: "X-axis Logarithmic Scale",
      default: false
    },
    yLogarithmicScale: {
      type: "boolean",
      label: "Y-axis Logarithmic Scale",
      default: false
    },
    plotStyling: {
      type: "select",
      label: "Plot Styling:",
      default: "Linear",
      options: [
        {
          name: "Linear",
          value: "lines"
        },
        {
          name: "Bar Chart",
          value: "bar"
        },
        {
          name: "Markers",
          value: "markers"
        },
        {
          name: "Linear with markers",
          value: "lines+markers"
        }
      ]
    },

  },

};

const Spectrum2DExport = { component: Spectrum2D, definition };
export default Spectrum2DExport;