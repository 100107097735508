import { DASHBOARDS_LOADED, DASHBOARD_SAVED } from "../actions/actionTypes";
import { DashboardAction } from "../actions/actions";
import { Dashboard } from "../../../dashboard/types";

export interface DashboardsState {
  dashboards: Dashboard[];
}
const initialState: DashboardsState = {
  dashboards: []
};

export default function dashboard(
  state: DashboardsState = initialState,
  action: DashboardAction
) {
  switch (action.type) {
    case DASHBOARDS_LOADED:
      return { ...state, dashboards: action.dashboards };

    case DASHBOARD_SAVED:
      const { id, variables } = action;
      for (let dashboard of state.dashboards) {
        if (dashboard.id === id) {
          dashboard.variables = variables;
          break;
        }
      }
      return { ...state };

    default:
      return state;
  }
}
