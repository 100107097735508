import React, { Component, Fragment } from "react";
import NotLoggedIn from "../../../jive/components/DeviceViewer/NotLoggedIn/NotLoggedIn";
import { connect } from "react-redux";
import { getIsLoggedIn, getUsername } from "../../../shared/user/state/selectors";
import "./DashboardLibrary.css";
import "../DashboardTitle.css";
import {
  getDashboards,
  getSelectedDashboard,
} from "../../../shared/state/selectors";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import DeleteDashboardModal from "../modals/DeleteDashboardModal";
import ConfigDashboardModal from "../modals/ConfigDashboardModal";
import { Dashboard, SelectedDashboard, SharedDashboards } from "../../types";
import { Dispatch } from "redux";
import {
  loadDashboard,
  saveDashboard,
  exportDashboard,
  importDashboard,
  showNotification,
  deleteDashboard,
  updateDashboard,
  exportAllDashboards,
} from "../../../shared/state/actions/actionCreators";
import { IMPORT_DASHBOARD } from "../../../shared/state/actions/actionTypes";
import {
  getGroupDashboards,
  getGroupDashboardCount,
} from "../../dashboardRepo";
import { Variable, Widget } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Files from "react-files";
import TangoAPI from "../../../shared/api/tangoAPI";
import WarningBadge from "../EditCanvas/WarningBadge";
import { checkVariableConsistency } from "../../../shared/utils/DashboardVariables";

import { library } from "@fortawesome/fontawesome-svg-core";

import { getIDFromURL } from "../../../shared/utils/getDashboardIDFromURL";
import {
  faFileUpload,
  faFileDownload,
  faFile,
  faTrash,
  faArrowAltCircleDown,
  faUser,
  faShare,
  faShareAlt,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { getDeviceNames } from "../../../shared/state/selectors/deviceList";
import { validate } from "../../../shared/state/reducers/selectedDashboard/lib";
import JSZip from "jszip";
import { NotificationLevel } from "../../../shared/notifications/notifications";

library.add(
  faFileUpload,
  faFileDownload,
  faFile,
  faTrash,
  faArrowAltCircleDown,
  faUser,
  faShareAlt,
  faShare,
  faChevronDown,
  faChevronUp
);

interface Props {
  tangoDB: string;
  render: boolean;
  dashboards: Dashboard[];
  username?: string | undefined;
  isLoggedIn: boolean;
  selectedDashboard: SelectedDashboard;
  deviceList: string[];
  onDeleteDashboard: (id: string) => void;
  loadDashboard: (id: string) => void;
  exportDashboard: (id: string) => void;
  exportAllDashboards: () => void;
  saveDashboard: (
    id: string,
    name: string,
    widgets: Widget[],
    variables: Variable[],
    environment: string[]
  ) => void;
  updateDashboard: (id: string, fields: object) => void;
  onUploadFile: () => void;
  onFilesChange: (files) => void;
  onFilesError: (error, files) => void;
}

interface DashboardConsistency {
  dashboardID: string;
  consistency: boolean;
}

interface State {
  deleteDashboardModalId: string;
  configDashboardModalId: string;
  expandedGroups: { [group: string]: boolean };
  sharedDashboards: SharedDashboards;
  originalDashboardVariables: Variable[];
  dashboardVariables: Variable[];
  environment: string[];
  tangoClasses: [];
  isDashboardsConsistencyFetched: boolean;
  dashboardsConsistency: DashboardConsistency[];
}

const config = window['config'];

class DashboardLibrary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleDeleteDashboard = this.handleDeleteDashboard.bind(this);
    this.filterDashboardVariables = this.filterDashboardVariables.bind(this);
    this.deleteDashboardVariable = this.deleteDashboardVariable.bind(this);
    this.addDashboardVariable = this.addDashboardVariable.bind(this);
    this.updateDashboardVariable = this.updateDashboardVariable.bind(this);
    this.saveDashboardVariables = this.saveDashboardVariables.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.addUpdateEnvironment = this.addUpdateEnvironment.bind(this);

    this.state = {
      deleteDashboardModalId: "",
      configDashboardModalId: "",
      expandedGroups: {},
      sharedDashboards: {
        dashboards: [],
        availableGroupDashboards: {},
      },
      dashboardVariables: [],
      environment: [],
      originalDashboardVariables: [],
      tangoClasses: [],
      isDashboardsConsistencyFetched: false,
      dashboardsConsistency: [],
    };
  }

  public componentDidMount() {
    this.loadGroupDashboardCount();
    this.fetchClasses();
    const id = getIDFromURL() || null;
    if (id) {
      this.setSelectedDashboard(id);
    }
  }

  public async componentDidUpdate(prevProp) {
    //if we just logged in, fetch dashboard count
    if (this.props.isLoggedIn && !prevProp.isLoggedIn) {
      this.loadGroupDashboardCount();
    }
  }

  public async loadGroupDashboardCount() {
    const meta = await getGroupDashboardCount();
    const keys = Object.keys(meta);
    const sharedDashboards: SharedDashboards = {
      dashboards: [],
      availableGroupDashboards: {},
    };
    keys.forEach((key) => {
      sharedDashboards.availableGroupDashboards[key] = {
        count: meta[key],
        loaded: false,
      };
    });
    this.setState({ sharedDashboards });
  }

  onSharedDashboardLoad = (sharedDashboards: SharedDashboards) =>
    this.setState({ sharedDashboards });

  filterDashboardVariables(searchString: string) {
    if (undefined === searchString || searchString === "") {
      this.setState({
        dashboardVariables: this.state.originalDashboardVariables,
      });
      return;
    }

    searchString = searchString.toLowerCase();

    const updatedVariables = this.state.originalDashboardVariables.filter(
      (variable) =>
        variable.name.toLowerCase().includes(searchString) ||
        variable.class.toLowerCase().includes(searchString) ||
        variable.device.toLowerCase().includes(searchString)
    );
    this.setState({ dashboardVariables: updatedVariables });
  }

  addDashboardVariable(
    dashboardId: string,
    variableName: string,
    deviceClass: string,
    deviceName: string
  ) {
    const newVariable = {
      name: variableName,
      class: deviceClass,
      device: deviceName,
    };

    let updatedVariable = this.state.dashboardVariables;
    updatedVariable.unshift(newVariable);
    this.updateVariables(updatedVariable);

    const widgets: any = this.props.selectedDashboard.widgets;
    this.nestedValidation(widgets, updatedVariable);
    this.props.saveDashboard(
      dashboardId,
      this.props.selectedDashboard.name,
      Object.values(widgets),
      updatedVariable,
      this.props.selectedDashboard.environment
    );
  }

  addUpdateEnvironment(id: string, env: string[]) {
    const fields = {
      environment: env,
    };

    this.props.updateDashboard(id, fields);
  }

  nestedValidation(wids, variables) {
    return Object.values(wids).map((widget: any) => {
      if (widget.innerWidgets && 0 < widget.innerWidgets.length) {
        this.nestedValidation(widget?.innerWidgets, variables);
      } else {
        const wid = validate(widget, variables, this.props.deviceList);
        widget.valid = wid.valid;
      }

      return widget;
    });
  }

  deleteDashboardVariable(dashboardId: string, variableName: string) {
    variableName = variableName.toLowerCase();

    const updatedVariables = this.state.originalDashboardVariables.filter(
      (variable) => !variable.name.toLowerCase().includes(variableName)
    );

    this.updateVariables(updatedVariables);

    const widgets: any = this.props.selectedDashboard.widgets;
    this.nestedValidation(
      this.props.selectedDashboard.widgets,
      updatedVariables
    );

    this.props.saveDashboard(
      dashboardId,
      this.props.selectedDashboard.name,
      Object.values(widgets),
      updatedVariables,
      this.props.selectedDashboard.environment
    );
  }

  /**
   * This updates the value on dashboard variable, not in redux
   */
  updateDashboardVariable(varName: string, key: string, value: string) {
    const updatedVariables = this.state.dashboardVariables.map((variable) => {
      if (variable.name === varName) {
        switch (key) {
          case "Class":
            variable.class = value;
            break;
          case "Device":
            variable.device = value;
            break;
          default:
            break;
        }
      }
      return variable;
    });

    this.setState({ dashboardVariables: updatedVariables });
  }

  handleCancel() {
    this.updateVariables(this.state.originalDashboardVariables);
  }

  /**
   * This saves the dashboard variables in redux & DB
   *
   */
  saveDashboardVariables(varName: string, dashboardId: string) {
    let updatedVariables = this.state.originalDashboardVariables;
    for (let i = 0; i < this.state.dashboardVariables.length; i++) {
      if (this.state.dashboardVariables[i].name === varName) {
        updatedVariables[i] = this.state.dashboardVariables[i];
        break;
      }
    }
    this.updateVariables(updatedVariables);
    this.props.saveDashboard(
      dashboardId,
      this.props.selectedDashboard.name,
      Object.values(this.props.selectedDashboard.widgets),
      updatedVariables,
      this.props.selectedDashboard.environment
    );
  }

  updateVariables(updatedVariables: Variable[] = []) {
    this.setState({
      dashboardVariables: JSON.parse(JSON.stringify(updatedVariables)),
      originalDashboardVariables: JSON.parse(JSON.stringify(updatedVariables)),
    });
  }

  /**
   * This is called on config btn click, loads the dashboard variables from redux & opens the modal with this content
   *
   * @param dashboardId
   */
  handleConfigBtnClick = (dashboardId: string) => {
    this.setState({ configDashboardModalId: dashboardId });
    const dashboard = this.props.dashboards.find(
      (dashboard) => dashboard.id === dashboardId
    );
    if (dashboard) {
      this.updateVariables(dashboard?.variables);
      this.setState({ environment: dashboard?.environment?.filter(Boolean) });
    }
  };

  async getDashboardsConsistency(dashboards: Dashboard[]) {
    if (!this.state.isDashboardsConsistencyFetched && dashboards.length > 0) {
      const consistencies: DashboardConsistency[] = [];
      for (const dashboard of dashboards) {
        const consistency = await checkVariableConsistency(
          this.props.tangoDB,
          dashboard.variables
        );
        consistencies.push({
          dashboardID: dashboard.id,
          consistency: consistency ? consistency : false,
        });
      }
      this.setState({
        dashboardsConsistency: consistencies,
        isDashboardsConsistencyFetched: true,
      });
    }
  }

  public render() {
    if (!this.props.render) {
      return null;
    }
    const { dashboards, isLoggedIn } = this.props;
    this.getDashboardsConsistency(dashboards);
    const {
      dashboards: groupDashboards,
      availableGroupDashboards,
    } = this.state.sharedDashboards;
    const groupsWithSharedDashboards = Object.keys(
      availableGroupDashboards
    ).filter((group) => availableGroupDashboards[group].count > 0);

    if (!isLoggedIn) {
      return (
        <NotLoggedIn>
          You have to be logged in to view and manage your dashboards.
        </NotLoggedIn>
      );
    }
    return (
      <div className="dashboard-settings">
        <div className="dashboard-settings-title">Dashboard Options</div>
        <div className="dashboard-row dashboard-menu">
          <button
            title="Create a new dashboard"
            onClick={() => this.setSelectedDashboard("")}
            className="dashboard-add-dashboard"
          >
            <FontAwesomeIcon icon="file" />
            <span className="dashboard-menu-title">New dashboard</span>
          </button>
            <>
          <Files
            className='files-dropzone'
            onChange={this.props.onFilesChange}
            onError={this.props.onFilesError}
            accepts={[".wj", ".zip"]}
            multiple={false}
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <button
              className="dashboard-menu-button"
              title="Import or drop here an existing dashboard from a file (*.wj) or from an archive (*.zip)"
            >
              <FontAwesomeIcon icon="file-upload" />

                  <span className="dashboard-menu-title">Import dashboard</span>
                </button>
              </Files>

        <button
          title="Export all dashboards as a zip file"
          onClick={() => this.props.exportAllDashboards()}     
          className="dashboard-menu-button"                   
        >
          <FontAwesomeIcon icon="file-download" />
          <span className="dashboard-menu-title">Export all dashboards</span>
        </button>
        </>
        
        </div>
        <div className="dashboard-settings-title">My Dashboards</div>

        {dashboards.map((dashboard) => {
          const consistent =
            this.state.dashboardsConsistency.length > 0 &&
            this.state.dashboardsConsistency.find(
              (d) => d.dashboardID === dashboard.id
            );
          return this.DashboardRow(
            dashboard,
            false,
            consistent ? consistent.consistency : true
          );
        })}
        {/* SHARED DASHBOARDS: admin has access to all dashboards under My Dashboards hence not showing for him  */}
        {groupsWithSharedDashboards.length > 0 && config.ADMIN_USERNAME !== this.props.username &&
          <>
            <div
              className="dashboard-settings-title"
              style={{ marginTop: "0.5em" }}
            >
              Shared Dashboards
            </div>

            {groupsWithSharedDashboards.map((groupName) => {
              return (
                <Fragment key={groupName}>
                  {this.groupDashboardTitle(
                    groupName,
                    availableGroupDashboards[groupName].count,
                    this.state.expandedGroups[groupName]
                  )}
                  {this.state.expandedGroups[groupName] &&
                    groupDashboards
                      .filter((dashboard) => dashboard.group === groupName)
                      .map((dashboard) => {
                        const consistent =
                          this.state.dashboardsConsistency.length > 0 &&
                          this.state.dashboardsConsistency.find(
                            (d) => d.dashboardID === dashboard.id
                          );
                        return this.DashboardRow(
                          dashboard,
                          true,
                          consistent ? consistent.consistency : true
                        );
                      })}
                </Fragment>
              );
            })}
          </>
        }
      </div>
    );
  }
  groupDashboardTitle = (
    groupName: string,
    count: number,
    expanded: boolean
  ) => {
    if (expanded) {
      return (
        <div
          style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}
          onClick={() => this.collapseGroup(groupName)}
          className="dashboard-settings-title subtitle"
        >
          {groupName} ({count})
          <FontAwesomeIcon
            style={{ marginLeft: "0.5em" }}
            icon={"chevron-up"}
          />{" "}
        </div>
      );
    }
    return (
      <div
        style={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }}
        onClick={() => this.expandGroup(groupName)}
        title="Load dashboards shared with this group"
        className="dashboard-settings-title subtitle"
      >
        {groupName} ({count})
        <FontAwesomeIcon
          style={{ marginLeft: "0.5em" }}
          icon={"chevron-down"}
        />{" "}
      </div>
    );
  };

  expandGroup = async (groupName: string) => {
    const {
      availableGroupDashboards,
      dashboards,
    } = this.state.sharedDashboards;
    const loaded = availableGroupDashboards[groupName].loaded;
    let groupDashboards: Dashboard[] = [];
    if (!loaded) {
      groupDashboards = await getGroupDashboards(groupName);
      availableGroupDashboards[groupName].loaded = true;
      dashboards.push(...groupDashboards);
      this.setState({
        sharedDashboards: {
          availableGroupDashboards,
          dashboards: dashboards.filter(
            (value, index, self) => self.indexOf(value) === index
          ),
        },
      });
    }
    const { expandedGroups } = this.state;
    this.setState({ expandedGroups: { ...expandedGroups, [groupName]: true } });
  };
  collapseGroup = (groupName: string) => {
    const { expandedGroups } = this.state;
    this.setState({
      expandedGroups: { ...expandedGroups, [groupName]: false },
    });
  };
  setSelectedDashboard = (id: string) => {
    if (id) {
      this.props.loadDashboard(id);
    } else {
      //creates a new dashboard, loads it, and selects it
      const dashboardName = "New Dashboard";
      this.props.saveDashboard("", dashboardName, [], [], []);
    }
  };

  DashboardRow = (
    dashboard: Dashboard,
    shared: boolean,
    consistent: boolean = true
  ) => {
    const { id: selectedDashboardId } = this.props.selectedDashboard;
    return (
      <Fragment key={dashboard.id}>
        <div
          className={
            "dashboard-item " +
            (dashboard.id === selectedDashboardId ? "selected" : "")
          }
        >
        <div className = "dashboard-row">
          {selectedDashboardId !== dashboard.id ? (
            <button
              onClick={() => this.setSelectedDashboard(dashboard.id)}
              className="dashboard-link"
            >
              {dashboard.name || "Untitled dashboard"}
            </button>
          ) : (
            <span>{dashboard.name || "Untitled dashboard"}</span>
          )}
          <div
            style={{
              width: "6.5em",
              textAlign: "right",
              alignSelf: "flex-start",
            }}
          >
            {!shared && (
              <div>
                <div id="buttons">
                  <button
                    title={`Delete dashboard '${dashboard.name ||
                      "Untitled dashboard"}'`}
                    className="delete-button"
                    onClick={() =>
                      this.setState({ deleteDashboardModalId: dashboard.id })
                    }
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>

                  <button
                    title={`Export dashboard '${dashboard.name ||
                      "Untitled dashboard"}'`}
                    className="delete-button"
                    onClick={() => this.props.exportDashboard(dashboard.id)}
                  >
                    <FontAwesomeIcon icon="arrow-alt-circle-down" />
                  </button>
                  <button
                    title={`Configure '${dashboard.name ||
                      "Untitled dashboard"}'`}
                    className="delete-button"
                    id="dashConfig"
                    onClick={() => this.handleConfigBtnClick(dashboard.id)}
                  >
                    <WarningBadge
                      visible={!consistent}
                      warningMessage=""
                      title={
                        consistent
                          ? ""
                          : "All devices either not connected or exported"
                      }
                    />
                    <i
                      className="fa fa-cog"
                      id="configureDashboard"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </i>
                  </button>
                </div>
              </div>
            )}
            {shared && (
              <span
                title={"This dashboard is owned by " + dashboard.user}
                style={{
                  color: "#666",
                  fontSize: "0.8em",
                  fontStyle: "italic",
                }}
              >
                <FontAwesomeIcon icon={"user"} /> {dashboard.user}
              </span>
            )}
          </div>
          </div>
          <div>
            {dashboard.group !== "" && dashboard.group !== null && !shared && (
              <div className="shared-status-row">
                <span className="shared-status-message">
                  <FontAwesomeIcon icon={"share-alt"} /> 
                  <span style = {{ marginLeft: "0.5em" }}>Shared with {dashboard.group}</span>
                </span>
              </div>
            )}
          </div>
        </div>

        {this.state.deleteDashboardModalId === dashboard.id && (
          <DeleteDashboardModal
            id={dashboard.id}
            name={dashboard.name}
            onClose={() => this.setState({ deleteDashboardModalId: "" })}
            onDelete={this.handleDeleteDashboard}
          />
        )}
        {this.state.configDashboardModalId === dashboard.id && (
          <ConfigDashboardModal
            id={dashboard.id}
            name={dashboard.name}
            tangoDB={this.props.tangoDB}
            onClose={() => this.setState({ configDashboardModalId: "" })}
            dashboardVariables={this.state.dashboardVariables}
            environment={this.state.environment}
            filterDashboardVariables={this.filterDashboardVariables}
            deleteDashboardVariable={this.deleteDashboardVariable}
            addDashboardVariable={this.addDashboardVariable}
            updateDashboardVariable={this.updateDashboardVariable}
            saveDashboardVariables={this.saveDashboardVariables}
            handleCancel={this.handleCancel}
            addUpdateEnvironment={this.addUpdateEnvironment}
          />
        )}
      </Fragment>
    );
  };

  private handleDeleteDashboard(id: string) {
    this.props.onDeleteDashboard(id);
    this.setState({ deleteDashboardModalId: "" });
  }

  async fetchClasses() {
    const { tangoDB } = this.props;
    const data = await TangoAPI.fetchSelectedClassesAndDevices(
      tangoDB,
      this.state.dashboardVariables
    );

    this.setState({ tangoClasses: data });
  }
}
function mapStateToProps(state: IRootState) {
  return {
    dashboards: getDashboards(state),
    username: getUsername(state),
    isLoggedIn: getIsLoggedIn(state),
    selectedDashboard: getSelectedDashboard(state),
    deviceList: getDeviceNames(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    saveDashboard: (
      id: string,
      name: string,
      widgets: Widget[],
      variables: Variable[],
      environment: string[]
    ) => dispatch(saveDashboard(id, name, widgets, variables, environment)),
    updateDashboard: (id: string, fields: object) =>
      dispatch(updateDashboard(id, fields)),
    onDeleteDashboard: (id: string) => dispatch(deleteDashboard(id)),
    loadDashboard: (id: string) => dispatch(loadDashboard(id)),
    exportDashboard: (id: string) => dispatch(exportDashboard(id)),
    exportAllDashboards: () => dispatch(exportAllDashboards()),
    onUploadFile: () => {},
    onFilesChange: async (files) => {
      if (
        files[0] &&
        (files[0].type === "application/zip" ||
          files[0].type === "application/x-zip-compressed")
      ) {
        const zipFile = files[0];
        const zip = new JSZip();
        try {
          const zipData = await zip.loadAsync(zipFile);
          let filesData: File[] = [];
          await Promise.all(
            Object.keys(zipData.files).map(async (filename) => {
              const fileData = await zipData.files[filename].async("blob");
              const file = new File([fileData], filename);

              if (
                !file.name.includes("__MACOSX") &&
                !file.name.startsWith("._") &&
                !file.name.startsWith(".") &&
                file.name.endsWith(".wj")
              ) {
                filesData.push(file);
              }
            })
          );

          dispatch(importDashboard(filesData));
        } catch (error) {
          console.error(
            "Error while unzipping the file. No dashboards imported"
          );
          dispatch(
            showNotification(
              NotificationLevel.ERROR,
              IMPORT_DASHBOARD,
              "Error while unzipping the file. No dashboards imported"
            )
          );
        }
      } else if (files[0] !== undefined) {
        dispatch(importDashboard(files[0]));
      }
    },
    onFilesError: (error, file) => {
      //const errorMsg = {level: NotificationLevel.ERROR, message: 'File Upload Error: ' + error.code + ': ' + error.message}
      //feedBackService.setData(errorMsg);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLibrary);
