const headers = {
    "Content-Type": "application/json"
};

const elasticLogsExport = {
    async fetchElasticLogs(filters) {

        const { devices, from, size, searchText, fromDate, toDate, logLevels } = filters;
        let queryString = constructQuery(devices, logLevels, searchText);

        //Populate filter object
        let rangeFilter = {}
        if (fromDate && toDate) {
            rangeFilter = {
                "range": {
                    "@timestamp": {
                        "gte": fromDate,
                        "lte": toDate
                    }
                }
            }
        }
        //form the query
        const query = {
            "from": from | 0,
            "size": size | 500,
            "sort": [{ "@timestamp": "desc" }],
            "query": {
                "bool": {}
            }
        }
        //add query string if found
        if (queryString.length > 0) {
            query.query.bool['must'] = {
                "query_string": {
                    "query": queryString,
                    "fields": ["message"]
                }
            }
        }

        //Add filters if found
        if (Object.keys(rangeFilter).length > 0) {
            query.query.bool['filter'] = { ...rangeFilter }
        }

        try {
            let data = await fetchWithTimeout(
                '/elasticsearch/_search',
                {
                    headers,
                    method: "post",
                    cache: "no-cache",
                    body: JSON.stringify(query),
                }
            );

            const rsp = await data.json();
            const hits = rsp?.hits?.hits;
            const messages = hits?.map(element => {
                return element;
            });

            return ({ [filters.id]: messages });
        } catch (error) {
            console.log("error: ", error);
            return null;
        }
    }
}

async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 10000 } = options;

    const abortController = new AbortController();
    const id = setTimeout(() => abortController.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: abortController.signal
    });
    clearTimeout(id);
    return response;
}

function constructQuery(devices, logLevels, searchText) {
    let queryStringTMP = "";
    //surrounding with double quotes is necessary for elastic to be able to query things like sys/tg_test/1

    if (devices.length > 0) {
        queryStringTMP = "(((";
        devices.forEach((device, index) => {
            if (index !== 0) queryStringTMP += ' OR (\"' + device + '\")'; // eslint-disable-line
            else queryStringTMP += '\"' + device + '\")'; // eslint-disable-line
        });
        queryStringTMP += "))";
    }

    if (logLevels) {
        if (devices.length > 0) {
            queryStringTMP = queryStringTMP.slice(0, -1);
            queryStringTMP += " AND (" + logLevels + "))";
        }
        else queryStringTMP = "(" + logLevels + ")";
    }

    if (searchText) {
        if (devices.length > 0 || logLevels) {
            queryStringTMP = queryStringTMP.slice(0, -1);
            queryStringTMP += " AND (*" + searchText + "*))";
        }
        else queryStringTMP += "(*" + searchText + "*)";
    }

    return queryStringTMP;
}

export default elasticLogsExport;
