import React, { Component, CSSProperties } from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  NumberInputDefinition,
  BooleanInputDefinition,
  CommandInputDefinition,
  StringInputDefinition,
  ColorInputDefinition,
  SelectInputDefinition,
  ComplexInputDefinition,
  StyleInputDefinition
} from "../../types";
import { InputField } from "../../../shared/utils/InputField";
import { parseCss } from "../../components/Inspector/StyleSelector";
import CommandOutput from "../../../shared/components/CommandOutput/CommandOutput";
import "./Command.css";
import { showHideTangoDBName } from "../../DBHelper";

type Inputs = {
  command: CommandInputDefinition;
  commandArgs: ComplexInputDefinition;
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showCommand: BooleanInputDefinition;
  title: StringInputDefinition;
  buttonText: StringInputDefinition;
  requireConfirmation: BooleanInputDefinition;
  displayOutput: BooleanInputDefinition;
  alignButtonRight: BooleanInputDefinition;
  placeholder: SelectInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  btnCss: StyleInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

interface State {
  input: string;
  showModal: boolean;
}

class Command extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      input: "",
      showModal: false,
    };
  }

  public render() {
    const { inputs, mode } = this.props;
    const {
      command,
      showDevice,
      showTangoDB,
      showCommand,
      backgroundColor,
      textColor,
      size,
      font,
      placeholder,
      requireConfirmation,
      displayOutput,
      alignButtonRight
    } = inputs;

    const { device, command: commandName } = command;
    const acceptedType: string = command["acceptedType"]
      ? command["acceptedType"]
      : "DevString";

    const intypedesc = command["intypedesc"];
    const deviceLabel = showHideTangoDBName(showDevice, showTangoDB, device, "device");
    const commandLabel = commandName || "command";

    const label = [
      ...([deviceLabel]),
      ...(showCommand ? [commandLabel] : []),
    ].join("/");

    const widgetCss = this.props.inputs.widgetCss ? parseCss(this.props.inputs.widgetCss).data : {}
    const btnCss = parseCss(inputs.btnCss).data;

    const style: CSSProperties = {
      backgroundColor,
      display: "flex",
      color: textColor,
      fontSize: size + "em",
      height: "100%",
      ...widgetCss
    };

    if (font) {
      style["fontFamily"] = font;
    }

    const buttonLabel = this.props.inputs.buttonText || "Submit";
    const titleLabel = this.props.inputs.title;

    const renderType = this.props.inputs.commandArgs && this.props.inputs.commandArgs.length > 0 ? 'custom' : acceptedType;

    return (
      <div>
        <div style={style}>
          {titleLabel && (
            <div>
              <span style={{ flexGrow: 0, marginRight: "0.5em" }}>
                {titleLabel}
              </span>
            </div>
          )}
          {label && (
            <div>
              <span style={{ flexGrow: 0, marginRight: "0.5em" }}>
                {label}:
              </span>
            </div>
          )}
          <div className="w-100">
            <InputField
              mode={mode}
              commandArgs={this.props.inputs.commandArgs}
              intype={acceptedType}
              renderType={renderType}
              intypedesc={intypedesc}
              buttonLabel={buttonLabel}
              placeholder={placeholder}
              btnCss={btnCss}
              name={this.props.inputs.command.command}
              device={this.props.inputs.command.device}
              requireConfirmation={requireConfirmation}
              alignButtonRight={alignButtonRight}
            />
          </div>
        </div>

        {mode === "run" && displayOutput &&
          <div className="command-output">
            <CommandOutput
              commandName={this.props.inputs.command.command}
              deviceName={this.props.inputs.command.device}
            />
          </div>
        }
      </div>
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "COMMAND",
  name: " Command",
  defaultHeight: 3,
  defaultWidth: 20,
  inputs: {
    title: {
      type: "string",
      label: "Title",
    },
    buttonText: {
      type: "string",
      label: "Button Label",
    },
    command: {
      label: "",
      type: "command",
      required: true,
      intype: "Any",
    },
    commandArgs: {
      label: "Predefined Command Args",
      type: "complex",
      repeat: true,
      inputs: {
        name: {
          type: "string",
          label: "Name",
          default: ""
        },
        value: {
          type: "string",
          label: "Value",
          default: ""
        },
        isDefault: {
          type: "radio",
          label: "Make default",
          default: false
        }
      }
    },
    showDevice: {
      type: "boolean",
      label: "Show Device Name",
      default: true,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showCommand: {
      type: "boolean",
      label: "Show Commnad Name",
      default: true,
    },
    requireConfirmation: {
      type: "boolean",
      label: "Require Confirmation",
      default: true,
    },
    displayOutput: {
      type: "boolean",
      label: "Display Output",
      default: true,
    },
    alignButtonRight: {
      type: "boolean",
      label: "Align the Send Button to Right",
      default: false,
    },
    placeholder: {
      type: "select",
      default: "intype",
      label: "Select placeholder display",
      options: [
        {
          name: "Display In Type",
          value: "intype",
        },
        {
          name: "Display In Type Description",
          value: "intypedesc",
        },
      ],
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
      ],
    },
    btnCss: {
      type: "style",
      label: "Submit button CSS",
      default: ""
    },
    widgetCss: {
      type: "style",
      label: "Widget CSS",
      default: ""
    }
  },
};

const CommandExport = { definition, component: Command };
export default CommandExport;