import React from "react";
import ReactJson from "react-json-view";

export const JSONTree = ({ data, jsonCollapsed }) => {
  return (
    <ReactJson
      name={false}
      collapsed={jsonCollapsed}
      enableClipboard={false}
      src={data}
      displayDataTypes={false}
      displayObjectSize={false}
    />
  );
};

export function parseJSONObject(str) {
  try {
    const obj = JSON.parse(str);
    return typeof obj === "object" ? obj : null;
  } catch (err) {
    return null;
  }
}
