import fileDownload from 'js-file-download';
import JSZip from 'jszip';
import { resolveWidgetCompatibility, validateJson } from "../shared/state/reducers/selectedDashboard/lib";
import { devicePresent, variablePresent } from '../shared/utils/DashboardVariables';
import { WIDGET_MISSING_DEVICE, WIDGET_WARNING } from '../shared/state/actions/actionTypes';
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath } from './runtime/utils';

const config = window['config'];

const headers = {
  "Content-Type": "application/json; charset=utf-8"
};

/**
 * [backwards compatibility] In case we loaded a dashboard with widgets that don't have 
 * order set on them, we assign them a incrementing integer order starting with zero here
 * Assuming the dashboard is edited in some way, this order will get saved to the database as well
 */
function ensureProperOrdering(widgets) {
  let highest = widgets.reduce((max, current) => Math.max(max, current.order || -1), -1);
  widgets.forEach(widget => {
    if (!widget.hasOwnProperty("order")) {
      highest++;
      widget.order = highest;
    }
  });
}

/**
 * 
 * @param {string} url 
 * @returns the url without the content after the last slash
 * Example: http://localhost:3000/testdb/dashboard becomes:
 * http://localhost:3000/testdb
 */
function removeContentAfterLastSlash(url) {
  return url.substring(0, url.lastIndexOf('/'));
}

async function save(id, widgets, name, variables = [], environment) {
  if(config['environment']){
    if(environment?.length === 0)
      environment = [removeContentAfterLastSlash(window.location.href)];
  }
  else environment = ['*/*'];

  const res = await fetch(config.basename + "/dashboards/", {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({
      id,
      widgets: widgets,
      name,
      variables,
      environment: environment,
      tangoDB: getTangoDB()
    })
  });
  if (!res.ok) {
    throw await res.json();
  }
  return res.ok ? res.json() : null;
}

async function updateDashboard(id, fields) {

  if (!fields || Object.keys(fields).length === 0)
    throw Error("No field passed to updated on dashboard");

  const validFields = ['environment'];
  const isValid = Object.keys(fields).map(x => -1 !== validFields.indexOf(x))

  if (!isValid)
    throw Error("Trying to update invalid field on dashboard");

  const res = await fetch("/dashboards/" + id + "/update", {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({
      id,
      fields,
      tangoDB: getTangoDB()
    })
  });
  if (!res.ok) {
    throw await res.json();
  }
  return res.ok ? res.json() : null;
}

async function load(id) {
  const res = await fetch(config.basename + "/dashboards/" + id, {
    method: "GET",
    credentials: "include",
    headers
  });
  const dashboard = await res.json();
  ensureProperOrdering(dashboard.widgets);
  // For compatibility issues. Once old dashboard is imported,
  // it populates with the currently selected DB
  updateDeviceKeys(dashboard.widgets)
  return dashboard;
}

async function getGroupDashboardCount() {
  const res = await fetch(
    config.basename + "/dashboards/group/dashboardsCount?excludeCurrentUser=true&tangoDB=" +
    getTangoDB(),
    {
      method: "GET",
      credentials: "include",
      headers
    }
  );
  return res.json();
}

async function getGroupDashboards(groupName) {
  const res = await fetch(
    config.basename + "/dashboards/group/dashboards?excludeCurrentUser=true&group=" +
    groupName +
    "&tangoDB=" +
    getTangoDB(),
    {
      method: "GET",
      credentials: "include",
      headers
    }
  );
  return res.json();
}

async function loadUserDashboards() {

  let environment = ['*/*'];
  if(config['environment']){
    environment = [removeContentAfterLastSlash(window.location.href)];
  }

  const res = await fetch(
    config.basename + "/dashboards/user/dashboards?tangoDB=" + getTangoDB()+"&environment[]="+environment,
    {
      method: "GET",
      credentials: "include",
      headers
    }
  );
  return res.json();
}

async function deleteDashboard(dashboardId) {
  const res = await fetch(config.basename + "/dashboards/" + dashboardId, {
    method: "DELETE",
    credentials: "include",
    headers
  });
  return res.json();
}

async function cloneDashboard(dashboardId) {
  const res = await fetch(config.basename + "/dashboards/" + dashboardId + "/clone", {
    method: "POST",
    credentials: "include",
    headers
  });
  return res.json();
}

async function shareDashboard(dashboardId, group, groupWriteAccess) {
  const res = await fetch(config.basename + "/dashboards/" + dashboardId + "/share", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify({ group, groupWriteAccess })
  });
  return res.json();
}

async function renameDashboard(id, newName, environment) {
  const res = await fetch(config.basename + "/dashboards/" + id + "/rename", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify({ newName, environment })
  });
  return res.json();
}

function getTangoDB() {
  try {
    if (config.basename !== "")
      return window.location.pathname.split("/")[2];
    else
      return window.location.pathname.split("/")[1];
  } catch (e) {
    return "";
  }
}


async function exportAllDash(dashboardIds) {
const zip = new JSZip();
const dashboardList = [];
try
{
  for (const id of dashboardIds) {
    const res = await fetch(config.basename +"/dashboards/" + id, {
      method: "GET",
      credentials: "include",
      headers
  });

  const dashboard = await res.json();
  ensureProperOrdering(dashboard.widgets);
    const version = `${process.env.REACT_APP_VERSION}`;
    const {
      widgets,
      name,
      user,
      insertTime,
      updateTime,
      group,
      groupWriteAccess,
      lastUpdatedBy,
      variables
    } = dashboard;

    const { widgets: newWidgets } = resolveWidgetCompatibility(
      widgets
    );

    //remove the _id in widgets, in order to reimport a widget with the same primary key into MongoDB
    newWidgets.forEach(widget => {
      delete (widget["_id"])
      updateDeviceKeys(widget)
    })


    let canvas = {
      id: id,
      name: name,
      version: version,
      user: user,
      insertTime: insertTime,
      updateTime: updateTime,
      group: group,
      groupWriteAccess: groupWriteAccess,
      lastUpdatedBy: lastUpdatedBy,
      widget: newWidgets,
      variables
    }

    var output = JSON.stringify(canvas, null, 2);
    var sanitize = require("sanitize-filename");
    const nameSanitized = sanitize(name);
    dashboardList.push(dashboard);
    zip.file(nameSanitized+'.wj', output);
  } 

  zip.generateAsync({ type: 'blob' })
  .then(blob => {
      fileDownload(blob, 'dashboards.zip');
  });
  return dashboardList;
  } catch (exception) {
    console.log(exception);
    return exception;
  }  
}

async function exportDash(id) {

  const res = await fetch("/dashboards/" + id, {
    method: "GET",
    credentials: "include",
    headers
  });

  const dashboard = await res.json();
  ensureProperOrdering(dashboard.widgets);

  try
  {
    const version = `${process.env.REACT_APP_VERSION}`;
    const {
      widgets,
      name,
      user,
      insertTime,
      updateTime,
      group,
      groupWriteAccess,
      lastUpdatedBy,
      variables,
      environment
    } = dashboard;

    const { widgets: newWidgets } = resolveWidgetCompatibility(
      widgets
    );
    
    //remove the _id in widgets, in order to reimport a widget with the same primary key into MongoDB
    newWidgets.forEach(widget => {
      delete(widget["_id"])
      updateDeviceKeys(widget)
  })


    let canvas = {
      id: id,
      name: name,
      version: version,
      user: user,
      insertTime: insertTime,
      updateTime: updateTime,
      group: group,
      groupWriteAccess: groupWriteAccess,
      lastUpdatedBy: lastUpdatedBy,
      widget: newWidgets,
      variables,
      environment
    }

    var output = JSON.stringify(canvas, null, 2);
    //sanitize the file name in order to prevent incompability across different OS
    var sanitize = require("sanitize-filename");
    const nameSanitized = sanitize(name);
    fileDownload(output, nameSanitized + '.wj');
    return dashboard;

  } catch (exception) {
    //const errorMsg = {level: NotificationLevel.ERROR, message: "Error exporting dashboard: "+exception}
    //feedBackService.setData(errorMsg);
    console.log(exception);
    return exception;
  }
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  })
}

function updateDeviceKeys(obj) {
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      updateDeviceKeys(obj[key]);
    } else if (key === 'device') {
      const tangoDB = getTangoDbFromPath(obj[key]);
      if (tangoDB === '')
        obj[key] = createDeviceWithTangoDBFullPath(getTangoDB(), obj[key])
    }
  }
}

async function importDash(content, dashboards, deviceList = []) {
  let contentBuffer = await readFileAsync(content);
  const { validationResult, importError, missingBundleIds } = validateJson(contentBuffer);

  if (validationResult) {
    try {
      var obj = JSON.parse(contentBuffer);
      var version = obj.version.split(".");
      version = parseInt(version[0] + version[1] + version[2]);
      if (version < 105) {
        var orderArray = [];
        obj.widget.forEach(widget => {
          orderArray.push(widget.order);
        });
        var reversed = orderArray.reverse();
        obj.widget.forEach((widget, index) => {
          widget.order = reversed[index];
        });
      }
      if (missingBundleIds.length > 0) {
        obj.widget = obj.widget.filter(widget => missingBundleIds.indexOf(Number(widget.id)) === -1)
      }

      updateDeviceKeys(obj.widget)

      const existingNames = dashboards.map(d => d.name);
      let copyName = obj.name;
      while (existingNames.includes(copyName)) {
        const copyNumbers = existingNames
          .filter(existingName => existingName.startsWith(obj.name))
          .map(existingName => existingName.replace(`${obj.name} copy `, ''))
          .filter(copyNum => !isNaN(copyNum))
          .map(Number)
          .sort((a, b) => b - a);
        const nextCopyNumber = copyNumbers.length > 0 ? copyNumbers[0] + 1 : 1;
        if (existingNames.includes(obj.name)) {
          copyName = `${obj.name} copy ${nextCopyNumber}`;
        }
      }

      checkWidgetValidity(obj.widget, deviceList, obj.variables);

      let resultSave = await save('', obj.widget, copyName, obj.variables, obj.environment);

      resultSave.name = copyName;
      resultSave.warning = importError;

      return resultSave;
    } catch (e) {
      console.log(e);
      return e;
    }
  } else {
    return importError;
  }
}

function checkWidgetValidity(widgets, deviceList, variables) {
  widgets && widgets.forEach(wid => {
    if (wid.type === "BOX") {
      checkWidgetValidity(wid?.innerWidgets, deviceList, variables);
    } else {
      switch (true) {
        default:
          const checkVar = variablePresent(wid, variables);
          if (!checkVar.found) {
            wid.valid = WIDGET_WARNING;
            break;
          }

          const checkDevice = devicePresent(wid, deviceList);
          if (!checkDevice.found) {
            wid.valid = WIDGET_MISSING_DEVICE;
            break;
          }
      }
    }
  })
}

export {
  renameDashboard,
  shareDashboard,
  getTangoDB,
  cloneDashboard,
  deleteDashboard,
  loadUserDashboards,
  getGroupDashboards,
  getGroupDashboardCount,
  load,
  exportDash,
  exportAllDash,
  importDash,
  save,
  updateDashboard
 };
