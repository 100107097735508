import React, { useState, useEffect } from "react";

import Modal from "../../../../shared/modal/components/Modal/Modal";
import { Button } from "react-bootstrap";

import AttributeInputType from "../../../../shared/utils/AttributeInputType/AttributeInputType";

function NumericInput({ attribute, onChange }) {
  const { writeValue, minvalue, maxvalue } = attribute;

  const [validating, setValidation] = useState();
  const [editValue, setEditValue] = useState(writeValue);

  const asNumber = Number(editValue);
  const isNumeric = editValue !== "" && !isNaN(asNumber);
  const hasBounds = minvalue != null && maxvalue != null;

  let isWithinBounds =
    isNumeric && hasBounds
      ? asNumber >= minvalue && asNumber <= maxvalue
      : true;

  const bounds = hasBounds ? `${minvalue}, ${maxvalue}` : null;
  const isValid = isNumeric && (!hasBounds || isWithinBounds);

  useEffect(() => {
    onChange(asNumber, isValid);
  }, [asNumber, isValid, onChange]);

  return (
    <AttributeInputType
      label={true}
      bounds={bounds}
      validating={validating}
      onFocus={() => setValidation(true)}
      onBlur={() => setValidation(false)}
      type={"numeric"}
      value={editValue}
      isValid={isValid}
      onChange={(e) => setEditValue(e.target.value)}
    />
  );
}

function BooleanInput({ attribute, onChange }) {
  function onSelect(event) {
    const value = event.target.value === "t";
    onChange(value);
  }

  return (
    <AttributeInputType
      label={true}
      type={"boolean"}
      value={attribute.writeValue}
      isValid={true}
      onChange={onSelect}
    />
  );
}

function StringInput({ attribute, onChange }) {
  const { value } = attribute;
  const [validating, setValidation] = useState();
  const [editValue, setValue] = useState(value);
  const asString = String(editValue);
  const isValid = asString.trim() !== "";

  useEffect(() => {
    onChange(asString, isValid);
  }, [asString, isValid, onChange]);

  return (
    <AttributeInputType
      label={true}
      type={"string"}
      value={editValue}
      validating={validating}
      isValid={isValid}
      onFocus={() => setValidation(true)}
      onBlur={() => setValidation(false)}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default function EditModal({ attribute, onClose, onWrite }) {
  const { name, datatype } = attribute;

  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState();

  const numericTypes = [
    "DevDouble",
    "DevFloat",
    "DevLong",
    "DevLong64",
    "DevShort",
    "DevUChar",
    "DevULong",
    "DevULong64",
    "DevUShort",
  ];

  const attributeIsNumeric = numericTypes.includes(datatype);
  const attributeIsBoolean = datatype === "DevBoolean";
  const attributeIsString = datatype === "DevString";
  const attributeIsDevEnum = datatype === "DevEnum";
  const attributeIsScalar = attribute.dataformat === "SCALAR";

  function onChange(_value, _isValid) {
    setValue(_value);
    setIsValid(_isValid === undefined ? true : _isValid);
  }

  function trigger() {
    if (isValid) {
      onWrite(value);
    }
  }

  function onSubmit(event) {
    event.preventDefault();
    trigger();
  }

  function onClick() {
    trigger();
  }

  const input =
    attributeIsScalar &&
    (attributeIsNumeric || attributeIsDevEnum ? (
      <NumericInput attribute={attribute} onChange={onChange} />
    ) : attributeIsBoolean ? (
      <BooleanInput attribute={attribute} onChange={onChange} />
    ) : attributeIsString ? (
      <StringInput attribute={attribute} onChange={onChange} />
    ) : null);

  const body = input ? (
    <form onSubmit={onSubmit}>{input}</form>
  ) : (
    <div>
      Currently only numeric, boolean and string scalar attributes can be
      edited.
    </div>
  );

  return (
    <Modal title={name}>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={onClick}
          disabled={!isValid}
        >
          Write
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
