import { getTangoDB } from "../dashboardRepo";
import { InputMapping, InputDefinitionMapping } from "../types";
import { splitFullPath } from "../DBHelper"

export function resolveDevice(
  published: PublishedDevices,
  inputDevice: string,
  definitionDevice?: string
) {
  return definitionDevice && published.hasOwnProperty(definitionDevice)
    ? published[definitionDevice]
    : inputDevice;
}

export interface PublishedDevices {
  [variable: string]: string;
}

export function publishedDevices(
  inputs: InputMapping,
  definitions: InputDefinitionMapping
): PublishedDevices {
  const inputNames = Object.keys(inputs);
  const result = {};

  for (const name of inputNames) {

    const definition = definitions[name];
    if (definition && definition.type === "device") {
      const { publish } = definition;

      if (publish == null) {
        continue;
      }

      if (publish[0] !== "$") {
        throw new Error("published names must begin with $");
      }

      const input = inputs[name];
      result[publish] = input;
    }
  }

  return result;
}

export function createDeviceWithTangoDBFullPath(tangoDB: string, devicePath: string) {
  return tangoDB ? `${tangoDB}://${devicePath}` : devicePath;
}


export function getTangoDbFromPath(fullPath: string): string {
  return splitFullPath(fullPath)[0]
}

export function getDeviceFromPath(fullPath: string): string {
  return splitFullPath(fullPath)[1]
}



export function getTangoDBFilteredDevices(fullDevNames) {
  let tangoDBs = {};
  for (let name of fullDevNames) {
    let [tangoDB, device] = splitFullPath(name);

    if (tangoDB === '') {
      tangoDB = getTangoDB()
    }
    
    if (tangoDB in tangoDBs) {
      tangoDBs[tangoDB].push(device);
    }
    else {
      tangoDBs[tangoDB] = [device];
    }
  }
  return tangoDBs;

};