import React, { Component } from "react";
import Dashboard from "./components/Dashboard";
import { BrowserRouter, Route } from "react-router-dom";
import UserAware from "../shared/user/components/UserAware";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileUpload, faUndo, faRedo, faClone, faShareAlt, faTrash, faSpinner, faEdit, faAngleDown, faAngleUp, faAngleLeft, faAngleRight, faUser, faPlus, faUserEdit, faPen, faCopy, faPaste, faChevronDown, faChevronUp, faArrowAltCircleUp, faArrowAltCircleDown, faLayerGroup, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import "./App.css";

const config = window['config'];

library.add( faFileUpload, faUndo, faRedo, faClone, faShareAlt, faTrash, faSpinner, faEdit, faAngleDown, faAngleUp, faAngleLeft, faAngleLeft, faAngleRight, faUser, faPlus, faUserEdit, faPen, faCopy, faPaste, faChevronDown, faChevronUp, faArrowAltCircleUp, faArrowAltCircleDown, faLayerGroup, faTimesCircle)

export default class App extends Component {
  render() {
    return (
        <BrowserRouter basename={config.basename}>
          <UserAware>
            <Route path="/:tangoDB/dashboard" component={Dashboard} />
          </UserAware>
        </BrowserRouter>
    );
  }
}
