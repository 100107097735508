import React from "react";
import {
  StringInputDefinition,
  WidgetDefinition,
} from "../../types";


const PlaceHolder = () => {

  return (
    <div>Widget no longer available</div>
  );
};

type Inputs = {
  text: StringInputDefinition;
};

const definition: WidgetDefinition<Inputs> = {
  type: "PlaceHolder",
  name: "PlaceHolder",
  defaultHeight: 2,
  defaultWidth: 10,
  inputs: {
    text: {
      label: "Text",
      type: "string",
      default: ""
    }
  }
};

const PlaceHolderExport = { definition, component: PlaceHolder };

export default PlaceHolderExport;
