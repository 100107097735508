/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import EditModal from "./EditModal";

import NotLoggedIn from "../NotLoggedIn/NotLoggedIn";

import {
  setDeviceAttribute,
  setDataFormat,
} from "../../../../shared/state/actions/tango";

import {
  getActiveDataFormat,
  getDisabledDisplevels,
} from "../../../../shared/state/selectors/deviceDetail";

import "./AttributeTable.css";
import { getIsLoggedIn } from "../../../../shared/user/state/selectors";

import { AttributeTableRow } from './AttributeTableRow';

import { WEBSOCKET } from "../../../../shared/state/actions/actionTypes";
import { fetchInitialValues } from "../../../../shared/utils/fetchInitialValues";
import { getDeviceFromPath } from "../../../../dashboard/runtime/utils";

const DataFormatChooser = ({ dataFormats, selected, onSelect }) => {
  const order = ["SCALAR", "SPECTRUM", "IMAGE"];
  const sortedFormats = dataFormats
    .slice()
    .sort((f1, f2) => order.indexOf(f1) - order.indexOf(f2));

  return (
    <ul className="DataFormatChooser nav nav-pills">
      {sortedFormats.map((format) => (
        <li className="nav-item" key={format}>
          <a
            className={classNames("nav-link", { active: format === selected })}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onSelect(format);
            }}
          >
            {format}
          </a>
        </li>
      ))}
    </ul>
  );
};

DataFormatChooser.propTypes = {
  dataFormats: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  selected: PropTypes.string,
};



function AttributeTable(props) {

  const {
    attributes,
    selectedFormat,
    disabledDisplevels,
    deviceName,
    onSelectDataFormat,
    onSetDeviceAttribute,
    isLoggedIn,
    tangoDB,
  } = props;

  const [editingName, setEditingName] = useState(null);
  const editingAttribute =
    editingName == null
      ? null
      : attributes.find(({ name }) => name === editingName);

  const dataFormats = Array.from(
    new Set(attributes.map((attr) => attr.dataformat))
  ).filter(n => n);

  const selectedOrFirstFormat =
    dataFormats.indexOf(selectedFormat) !== -1
      ? selectedFormat
      : dataFormats[0];

  const filteredAttributes = attributes.filter(
    (attr) =>
      attr.dataformat === selectedOrFirstFormat &&
      disabledDisplevels.indexOf(attr.displevel) === -1
  );

  function onWrite(value) {
    onSetDeviceAttribute(editingName, value);
    setEditingName(null);
  }

  function onClose() {
    setEditingName(null);
  }

  const devices = [];
  filteredAttributes.forEach((attribute) => {
    devices.push(deviceName + "/" + String(attribute.name));
  });

  const dispatch = useDispatch();
  if (selectedOrFirstFormat === "SCALAR") {
    // Subscribe to this tab attributes only if on SCALAR (direct display)
    dispatch({
      type: WEBSOCKET.WS_SUBSCRIBE,
      payload: { devices },
    });
  }
  else {
    // SPECTRUM or IMAGE, fetch initial attributes state and sub only to state attribute
    const devNameShort = getDeviceFromPath(deviceName);
    filteredAttributes.forEach(async element => {
      fetchInitialValues(dispatch, devNameShort + "/" + element.name, tangoDB);
    });

    dispatch({
      type: WEBSOCKET.WS_SUBSCRIBE,
      payload: { devices: [deviceName + "/state"] },
    });
  }


  return (
    <div className="AttributeTable">
      {editingAttribute && (
        <EditModal
          attribute={editingAttribute}
          onWrite={onWrite}
          onClose={onClose}
        />
      )}
      <NotLoggedIn>
        You are currently not logged in and cannot change attribute values.
      </NotLoggedIn>
      <DataFormatChooser
        dataFormats={dataFormats}
        selected={selectedOrFirstFormat}
        onSelect={onSelectDataFormat}
      />
      <table className="separated">
        <tbody>
          {filteredAttributes.map((attribute) => (
            <AttributeTableRow
              key={attribute.name}
              tangoDB={tangoDB}
              attribute={attribute}
              deviceName={deviceName}
              allowedToEdit={isLoggedIn}
              onEdit={(attribute) => setEditingName(attribute)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

AttributeTable.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      dataformat: PropTypes.string,
      datatype: PropTypes.string,
      description: PropTypes.string,
      displevel: PropTypes.string,
      maxvalue: PropTypes.any,
      minvalue: PropTypes.any,
      name: PropTypes.string,
      quality: PropTypes.string,
      value: PropTypes.any, //possibly PropTypes.oneOfType(...)
      writeValue: PropTypes.any,
      writable: PropTypes.string,
    })
  ),
  deviceName: PropTypes.string,
  disabledDisplevels: PropTypes.arrayOf(PropTypes.string),
  onSelectDataFormat: PropTypes.func,
  onSetDeviceAttribute: PropTypes.func,
  selectedFormat: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: getIsLoggedIn(state),
    selectedFormat: getActiveDataFormat(state),
    disabledDisplevels: getDisabledDisplevels(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { tangoDB, deviceName } = ownProps;
  return {
    onSelectDataFormat: (format) => dispatch(setDataFormat(format)),
    onSetDeviceAttribute: (name, value) =>
      dispatch(setDeviceAttribute(tangoDB, deviceName, name, value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeTable);
