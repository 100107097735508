import React, { Component } from "react";

import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  StringInputDefinition,
  BooleanInputDefinition
} from "../../types";

import "../styles/EmbedPage.styles.css";


type Inputs = {
  url: StringInputDefinition;
  overflow: BooleanInputDefinition;
};


type Props = WidgetProps<Inputs>;

class EmbedPage extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render() {
    const { mode, inputs } = this.props;
    const {url} = inputs;
    const prefix = url.toLowerCase().startsWith("http") ? "" : "http://"

    if(mode === "library"){
        return(
            <div>
                <p>
                    EMBED A PAGE HERE
                </p>
            </div>
        );
    }
    else{
        return(
            <div>
                <div className="aspect-ratio">
                    <iframe title="responsive iframe" className="responsive-iframe" src={prefix + url} scrolling="no"></iframe>
                </div>
            </div>
        );
    }

  }

}

const definition: WidgetDefinition<Inputs> = {
  type: "EMBED_PAGE",
  name: "Embed Page",
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
      url:{
          type: "string",
          default: "",
          label: "URL For Embedding"

      },
      overflow: {
        type: "boolean",
        default: false,
        label: "Show overflow scroll",
      }
  }
};

const EmbedPageExport = { component: EmbedPage, definition };
export default EmbedPageExport;