import React, { Component } from "react";
import "./ModeToggleButton.css";

interface Props {
  onClick: () => void;
  disabled: boolean;
  mode: "edit" | "run";
}

export default class ModeToggleButton extends Component<Props> {
  public render() {
    const { onClick, mode, disabled } = this.props;
    const [label, icon] =
      mode === "run" ? ["Edit", "pencil"] : ["Start", "play"];

    return (
      <button
        id="mode-toggle-btn"
        type="button"
        onClick={onClick}
        disabled={disabled}
        style={{
          cursor: disabled ? "not-allowed" : "",
        }}
        tabIndex={-1}
      >
        <span className={`fa fa-${icon}`} /> {label}
      </button>
    );
  }
}
