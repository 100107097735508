import React, { Component } from "react";
import { connect } from "react-redux";
import { IndexPath, Widget, Variable } from "../../types";
import { bundles } from "../../widgets";
import {
  DELETE_INPUT,
  ADD_INPUT,
  SET_INPUT,
  TOGGLE_INSPECTOR_COLLAPSED
} from "../../../shared/state/actions/actionTypes";
import InputList from "./InputList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { getInspectorCollapsed, getSelectedDashboard, getDashboards } from "../../../shared/state/selectors";
import {Alert} from "react-bootstrap";
import { Dashboard } from '../../types';
import { SelectedDashboardState } from "../../../shared/state/reducers/selectedDashboard";
import { isEditableDashboard } from "../../utils"
import { IUserState } from "../../../shared/user/state/reducer"

const MultipleSelection = () => (
    <Alert style={{margin: 0}}>
        Multiple selection of different type of widget
    </Alert>
);
interface Props {
  tangoDB: string;
  widgets: Widget[];
  isRootCanvas: boolean;
  nbrSelectedWidgets: number;
  render: boolean;
  onSetInput: (path: IndexPath, value: any, widgetType?: string) => void;
  inspectorCollapsed: boolean;
  selectedDashboard: SelectedDashboardState,
  dashboards: Dashboard[],
  user: IUserState;
  toggleInspectorCollapse: () => void;
  onDeleteInput: (path: IndexPath) => void;
  onAddInput: (path: IndexPath) => void;
}

class Inspector extends Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.state = { collapsed: false, variables: [] };
  }

  public render() {
    if (!this.props.render) {
      return null;
    }

   const editableDashboard = isEditableDashboard(this.props.selectedDashboard, this.props.user).result;

    const selectedDashboardId = this.props.selectedDashboard.id;
    let variables: Variable[] = [];
    for (let i=0; i<this.props.dashboards.length; i++) {
      //Match the dashboard
      if (this.props.dashboards[i].id === selectedDashboardId && undefined !== this.props.dashboards[i].variables) {
        variables = this.props.dashboards[i].variables;
        break;
      }
    }

    const { widgets, tangoDB, inspectorCollapsed, nbrSelectedWidgets } = this.props;
    const definitions = bundles.map(bundle => bundle.definition);
    const definition = definitions.find(({ type }) => type === widgets[0].type);

    if (definition == null) {
      return null;
    }
    const isSameTypeOfWidget = widgets.reduce(
      (acc, curr) => acc && curr.type === widgets[0].type,
      true
    );
    return isSameTypeOfWidget ? (
      <div className={`Inspector ${editableDashboard ? '' : 'disabled'}`}>
        <div className="InspectorWidgetName">
          {definition.name}
          <FontAwesomeIcon
            onClick={this.props.toggleInspectorCollapse}
            title={`Hide the widget inspector \n (Alt+i)`}
            icon={"angle-left"}
            style={{
              float: "right",
              margin: "0em 0.5em 0.5em 1em",
              cursor: "pointer"
            }}
          />
        </div>
        {!inspectorCollapsed && (
          <InputList
            inputId={widgets[0].id}
            tangoDB={tangoDB}
            inputDefinitions={definition.inputs}
            inputs={widgets[0].inputs}
            widgets={widgets}
            onChange={(path, value) => this.props.onSetInput(path, value, widgets[0].type)}
            onDelete={path => this.props.onDeleteInput(path)}
            onAdd={path => this.props.onAddInput(path)}
            widgetType={widgets[0].type}
            nonEditable={nbrSelectedWidgets > 1}
            variables={variables}
          />
        )}
      </div>
    ) : (
      <MultipleSelection />
    );
  }
}

function mapStateToProps(state: IRootState) {
  return { 
    inspectorCollapsed: getInspectorCollapsed(state),
    selectedDashboard: getSelectedDashboard(state),
    dashboards: getDashboards(state),
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSetInput: (path: IndexPath, value: any, widgetType?: string) =>
      dispatch({ type: SET_INPUT, path, value, widgetType }),
    toggleInspectorCollapse: () =>
      dispatch({ type: TOGGLE_INSPECTOR_COLLAPSED }),
    onAddInput: (path: IndexPath) => dispatch({ type: ADD_INPUT, path }),
    onDeleteInput: (path: IndexPath) => dispatch({ type: DELETE_INPUT, path })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inspector);
