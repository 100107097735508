import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./shared/state/store/configureStore";
import JiveApp from "./jive/App";
import DashboardApp from "./dashboard/App";
import SynopticApp from "./synoptic/App";
import { createRoot } from 'react-dom/client';

import "bootstrap/dist/css/bootstrap.min.css";
const store = configureStore();
const config = window['config'];
const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <Switch>
        <Route path={"/:tangoDB/dashboard"} component={DashboardApp} />
        <Route path={"/:tangoDB/synoptic"} component={SynopticApp} />
        <Route path={"/"} component={JiveApp} />
      </Switch>
    </BrowserRouter>
  </Provider>
);
