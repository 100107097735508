import React, { Component } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Widget } from "../types";

const reorder = (list: Widget[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((r, i) => (r.order = result.length - 1 - i));
  return result;
};

const getItemStyle = (
  isDragging: boolean,
  isSelected: boolean,
  draggableStyle
) => {
  const style = {
    userSelect: "none",
    borderLeft: isSelected ? "3px solid lightblue" : "3px solid white",
    outline: isDragging ? "1px solid lightblue" : "1px solid white",
    ...draggableStyle,
  };
  if (isDragging) {
    style["background"] = "#ebfaff";
  }
  return style;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "#eee" : "white",
});
interface Props {
  selectedIds: string[];
  widgets: Widget[];
  selectedWidgets: Widget[];
  onSelectWidgets: (ids: string[]) => void;
  onReorderWidget: (widgets: Widget[]) => void;
}

class LayerList extends Component<Props> {
  
  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    this.props.onReorderWidget(
      reorder(this.props.widgets, result.source.index, result.destination.index)
    );
  };

  selectLayer = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => {
    const { selectedIds } = this.props;
    let ids: string[] = [];
    if (!e.shiftKey) {
      ids = [id];
    } else {
      if (selectedIds.includes(id)) {
        //deselect the layer
        ids = selectedIds.filter((x) => x !== id);
      } else {
        //add the layer
        ids = selectedIds.concat([id]);
      }
    }
    this.props.onSelectWidgets(ids);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="layerlist-droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.widgets.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        this.props.selectedIds.includes(item.id),
                        provided.draggableProps.style
                      )}
                      className="widget-layer"
                    >
                      {this.getWidgetData(item)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  getWidgetData(widget: Widget, nestedCall: boolean = false) {

    if ("BOX" === widget.type && undefined !== widget.innerWidgets) {
      // Add style to nested Box
      const innerBoxStyle = {
        paddingLeft: "10px",
        borderLeft: "3px solid " + (this.props.selectedIds.includes(widget.id) ? "lightBlue": "transparent")
      }

      return (
      <div style={nestedCall ? innerBoxStyle : {}} key={"wrapper-"+widget.id}>
        {this.getLayerHtml(widget) }
        <div style={{marginLeft: "10px"}} key={"container-"+widget.id}>
          {widget.innerWidgets.map(innerWid => {
            return (
              ("BOX" === innerWid.type) ? this.getWidgetData(innerWid, true) : this.getLayerHtml(innerWid, true)
            )
          })}
        </div>
       </div>);
    } else {
      return (
        this.getLayerHtml(widget)
      );
    }
  }

  getLayerHtml(widget: Widget, isInnerWidget = false) {
    const style = {
      marginBottom: "10px",
      borderLeft: "3px solid " + (this.props.selectedIds.includes(widget.id) ? "lightBlue": "transparent"),
      paddingLeft: "10px"
    }

    return (
      <div key={widget.id} 
        style={isInnerWidget ? style: {}} 
        onClick={(e) => this.selectLayer(e, widget.id)}
      >
        <span className="label">
          {widget.type.split("_").join(" ")} {Number(widget.width).toFixed(2)}×
          {Number(widget.height).toFixed(2)}
        </span>
        <div style={{ fontSize: "0.8em" }}>
          {getRenderString(widget, false)}
        </div>
      </div>
    )
  }
}

const getRenderString = (widget: Widget, selected: boolean) => {
  let renderString = "";
  const inputs = widget.inputs;

  if (inputs.text !== undefined) {
    if (inputs.text) {
      renderString = inputs.text;
    } else {
      renderString = "No text";
    }
  } else if (inputs.attribute) {
    if (!inputs.attribute.device || !inputs.attribute.attribute) {
      renderString = "Undefined";
    } else {
      renderString = inputs.attribute.device + ":" + inputs.attribute.attribute;
    }
  } else if (inputs.command) {
    renderString = inputs.command.device + ":" + inputs.command.command;
  } else if (inputs.device) {
    renderString = inputs.device;
  } else if (inputs.attributes) {
    renderString = inputs.attributes.length + " attributes";
  } else {
  }
  if (renderString.length > 40) {
    renderString = renderString.slice(0, 37) + "...";
  }
  return renderString;
};
export default LayerList;
