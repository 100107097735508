import {
  UNDO,
  REDO,
  ADD_WIDGET,
  ADD_INNER_WIDGET,
  REORDER_INNER_WIDGET,
  DROP_INNER_WIDGET,
  MOVE_WIDGETS,
  RESIZE_WIDGET,
  DELETE_WIDGET,
  SELECT_CANVAS,
  TOGGLE_MODE,
  SELECT_WIDGETS,
  DASHBOARD_LOADED,
  LOAD_DASHBOARDS,
  DASHBOARDS_LOADED,
  DASHBOARD_EXPORTED,
  DASHBOARD_IMPORTED,
  DASHBOARD_RENAMED,
  DASHBOARD_DELETED,
  DASHBOARD_CLONED,
  LOAD_DASHBOARD,
  EXPORT_DASHBOARD,
  IMPORT_DASHBOARD,
  EXPORT_ALL_DASHBOARDS,
  ALL_DASHBOARDS_EXPORTED,
  RENAME_DASHBOARD,
  DELETE_DASHBOARD,
  CLONE_DASHBOARD,
  SAVE_DASHBOARD,
  DASHBOARD_SAVED,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  DUPLICATE_WIDGET,
  SHARE_DASHBOARD,
  DASHBOARD_SHARED,
  DASHBOARD_EDITED,
  REORDER_WIDGETS,
  UPDATE_WIDGET,
  WIDGET_CLIPBOARD_PASTE,
  WIDGET_CLIPBOARD_COPY,
  MOUSE_DOWN_ON_WIDGET,
  UPDATE_DASHBOARD,
  DASHBOARD_UPDATED,
} from "./actionTypes";

import {
  UndoAction,
  RedoAction,
  AddWidgetAction,
  AddInnerWidgetAction,
  ReorderInnerWidgetAction,
  MoveWidgetsAction,
  ResizeWidgetAction,
  DeleteWidgetAction,
  SelectCanvasAction,
  ToggleModeAction,
  SelectWidgetsAction,
  DashboardLoadedAction,
  DashboardRenamedAction,
  SaveDashboardAction,
  DashboardSavedAction,
  DashboardUpdatedAction,
  RenameDashboardAction,
  CloneDashboardAction,
  DuplicateWidgetAction,
  dashboardEditedAction,
  ReorderWidgetsAction,
  UpdateWidgetAction,
  DashboardExportedAction,
  DashboardImportedAction,
  DashboardsExportedAction,
  DropInnerWidgetAction,
  UpdateDashboardAction
} from "./actions";

import { Widget, Dashboard, Variable } from "../../../dashboard/types";
import { SelectedDashboardState } from "../reducers/selectedDashboard";

import { NotificationLevel } from '../../notifications/notifications'

export function undo(): UndoAction {
  return { type: UNDO }
}

export function redo(): RedoAction {
  return { type: REDO }
}

export function duplicateWidget(): DuplicateWidgetAction {
  return { type: DUPLICATE_WIDGET }
}

export function addWidget(
  x: number,
  y: number,
  widgetType: string,
  canvas: string
): AddWidgetAction {
  return { type: ADD_WIDGET, x, y, widgetType, canvas };
}

export function reorderInnerWidget(
  x: number,
  y: number,
  parentWidget: Widget,
  innerWidget: Widget
  ): ReorderInnerWidgetAction {
  return { type: REORDER_INNER_WIDGET, x, y,  parentWidget, innerWidget };
}

export function addInnerWidget(
  x: number,
  y: number,
  widget: Widget,
  parentID: string,
  innerWidgetCheck: boolean
): AddInnerWidgetAction {
  return { type: ADD_INNER_WIDGET, x, y, widget, parentID, innerWidgetCheck };
}

export function dropInnerWidget(
  x: number,
  y: number,
  innerWidget: Widget,
): DropInnerWidgetAction {
  return { type: DROP_INNER_WIDGET, x, y ,innerWidget };
}

export function moveWidgets(
  ids: string[],
  dx: number,
  dy: number
): MoveWidgetsAction {
  return { type: MOVE_WIDGETS, dx, dy, ids };
}

export function resizeWidget(
  id: string,
  mx: number,
  my: number,
  dx: number,
  dy: number
): ResizeWidgetAction {
  return { type: RESIZE_WIDGET, mx, my, dx, dy, id };
}

export function selectWidgets(ids: string[]): SelectWidgetsAction {
  return { type: SELECT_WIDGETS, ids };
}

export function deleteWidget(): DeleteWidgetAction {
  return { type: DELETE_WIDGET };
}

export function selectCanvas(id: string): SelectCanvasAction {
  return { type: SELECT_CANVAS, id };
}

export function toggleMode(): ToggleModeAction {
  return { type: TOGGLE_MODE };
}

export function loadDashboards() {
  return { type: LOAD_DASHBOARDS };
}

export function dashboardsLoaded(dashboards: Dashboard[]) {
  return { type: DASHBOARDS_LOADED, dashboards };
}

export function renameDashboard(
  id: string,
  name: string,
  environment: string[]
): RenameDashboardAction {
  return { type: RENAME_DASHBOARD, id, name, environment };
}
export function dashboardRenamed(
  id: string,
  name: string
): DashboardRenamedAction {
  return { type: DASHBOARD_RENAMED, id, name };
}
export function deleteDashboard(id: string) {
  return { type: DELETE_DASHBOARD, id };
}
export function dashboardDeleted(id: string) {
  return { type: DASHBOARD_DELETED, id };
}
export function shareDashboard(id: string, group: string, groupWriteAccess: boolean) {
  return { type: SHARE_DASHBOARD, id, group, groupWriteAccess }
}
export function dashboardShared(id: string, group: string, groupWriteAccess: boolean) {
  return { type: DASHBOARD_SHARED, id, group, groupWriteAccess }
}
export function cloneDashboard(
  id: string,
  newUser: string
): CloneDashboardAction {
  return { type: CLONE_DASHBOARD, id, newUser };
}
export function dashboardCloned(id: string) {
  return { type: DASHBOARD_CLONED, id };
}
export function loadDashboard(id: string) {
  return { type: LOAD_DASHBOARD, id };
}
export function exportDashboard(id: string) {
  return { type: EXPORT_DASHBOARD, id };
}
export function importDashboard(content: any) {
  return { type: IMPORT_DASHBOARD, content };
}

export function dashboardLoaded(
  dashboard: Dashboard,
  widgets: Widget[],
  deviceList: string[]
): DashboardLoadedAction {
  return { type: DASHBOARD_LOADED, dashboard, widgets, deviceList};
}
export function dashboardExported(
  dashboard: Dashboard,
  widgets: Widget[]
): DashboardExportedAction {
  return { type: DASHBOARD_EXPORTED, dashboard, widgets };
}
export function dashboardImported(
  dashboard: Dashboard,
  widgets: Widget[]
): DashboardImportedAction {
  return { type: DASHBOARD_IMPORTED, dashboard, widgets };
}

export function exportAllDashboards() {
  return { type: EXPORT_ALL_DASHBOARDS };
}

export function allDashboardsExported(
  dashboards: Dashboard[],
): DashboardsExportedAction {
  return { type: ALL_DASHBOARDS_EXPORTED, dashboards };
}

export function dashboardSaved(
  id: string,
  created: boolean,
  name: string,
  variables: Variable[],
  environment: string[]
): DashboardSavedAction {
  return { type: DASHBOARD_SAVED, id, created, name, variables, environment };
}
export function dashboardUpdated(
  id: string,
  fields: object
): DashboardUpdatedAction {
  return { type: DASHBOARD_UPDATED, id, fields };
}
export function reorderWidgets(
  widgets: Widget[],
): ReorderWidgetsAction {
  return { type: REORDER_WIDGETS, widgets };
}
export function updateWidget(
  updatedWidget: Widget,
): UpdateWidgetAction {
  return { type: UPDATE_WIDGET, updatedWidget };
}
export function saveDashboard(
  id: string,
  name: string,
  widgets: Widget[],
  variables: Variable[],
  environment: string[]
): SaveDashboardAction {
  return { type: SAVE_DASHBOARD, id, name, widgets, variables, environment };
}
export function updateDashboard(
  id: string,
  fields: object,
): UpdateDashboardAction {
  return { type: UPDATE_DASHBOARD, id, fields };
}
export function dashboardEdited(
  dashboard: SelectedDashboardState
): dashboardEditedAction {
  return { type: DASHBOARD_EDITED, dashboard }
}
export function showNotification(level: NotificationLevel, action: string, msg: string, duration: number = 2000) {
  return { type: SHOW_NOTIFICATION, notification: { level, action, msg, duration } };
}
export function hideNotification() {
  return { type: HIDE_NOTIFICATION };
}

export function copyToWidgetClipboard(widgets: Widget[]) {
  return { type: WIDGET_CLIPBOARD_COPY, widgets };
}
export function pasteFromWidgetClipboard() {
  return { type: WIDGET_CLIPBOARD_PASTE };
}
export function mouseDownOnWidget(isDown: boolean) {
  return { type: MOUSE_DOWN_ON_WIDGET, isDown };
}