export function splitFullPath(fullPath: string): string[] {
  if (!fullPath) {
    return ["", ""]
  } else if (fullPath.includes("://")){
    return fullPath.split("://")
  } else return ["", fullPath]
}

export function showHideTangoDBName(showDevice: boolean, showTangoDB: boolean, deviceName: string, defaultValue: string = "") {
    if (!deviceName) {
      if (showDevice)
        return defaultValue
      return null
    }
  
    
    const [tangoDB, devName] = splitFullPath(deviceName);
  
    if (showDevice && !showTangoDB) {
      return devName;
    }
  
    if (showDevice && showTangoDB) {
      return deviceName;
    }
  
    if (!showDevice && showTangoDB) {
      return `${tangoDB}:/`;
    }
  
    return null
  }