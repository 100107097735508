import React, { Component, CSSProperties } from "react";
import SpectrumValues from "./SpectrumValues";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
  NumberInputDefinition,
  StringInputDefinition,
  StyleInputDefinition
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { showHideTangoDBName } from "../../DBHelper";

type Inputs = {
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
  attribute: AttributeInputDefinition;
  showSpecificIndexValue: StringInputDefinition;
  precision: NumberInputDefinition;
  showIndex: BooleanInputDefinition;
  showLabel: BooleanInputDefinition;
  fontSize: NumberInputDefinition;
  layout: SelectInputDefinition<"horizontal" | "vertical">;
  customCss: StyleInputDefinition;
};

interface State {
  min?: number;
  max?: number;
}

type Props = WidgetProps<Inputs>;

class SpectrumTable extends Component<Props, State> {

  public render() {
    const { device } = this.deviceAndAttribute();
    const {
      showDevice,
      showTangoDB,
      showAttribute,
      attribute,
      showSpecificIndexValue,
      precision,
      showIndex,
      showLabel,
      fontSize,
      layout,
      customCss,
    } = this.props.inputs;
    const mode = this.props.mode;
    const parsedCss = parseCss(customCss).data;
    const mainDivStyle: CSSProperties = { marginLeft: "5px", fontSize: fontSize + "px", overflow: "auto", ...parsedCss };
    const spanStyle: CSSProperties = { marginLeft: "5px", display: "inline" };
    let spanText = showHideTangoDBName(showDevice, showTangoDB, attribute.device);
    let display = "";
    if (!attribute.attribute) {
      attribute.attribute = "";
    }

    if (showAttribute === "Label") {
      if (attribute.label !== "") {
        display = attribute.label
      }
      else {
        display = attribute.attribute
      }
    }
    else if (showAttribute === "Name") {
      if (attribute.attribute !== null) {
        display = attribute.attribute
      }
      else {
        display = "attributeName"
      }
    }

    if (spanText === null) spanText = display;
    else  spanText += display;
    
    return (
      <div id="SpectrumTable" style={mainDivStyle} >
        {showDevice || showAttribute ? <span style={spanStyle}>{spanText}</span> : null}
        <SpectrumValues
          attributeName={attribute?.attribute}
          deviceName={device}
          mode={mode}
          showSpecificIndexValue={showSpecificIndexValue}
          precision={precision}
          layout={layout}
          showIndex={showIndex}
          showLabel={showLabel}
          attribute={attribute}
        />
      </div>
    );
  }

  private deviceAndAttribute(): { device: string; name: string, label: string } {
    const { attribute } = this.props.inputs;
    const device = attribute.device || "device";
    const name = attribute.attribute || "attributeName";
    const label = attribute.label || "attributeLabel";
    return { device, name, label };
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "SPECTRUM_TABLE",
  name: "SpectrumTable",
  defaultWidth: 8,
  defaultHeight: 5,
  inputs: {
    attribute: {
      label: "",
      type: "attribute",
      dataFormat: "spectrum",
      dataType: "string",
      required: true
    },
    showSpecificIndexValue: {
      type: "string",
      label: "Show value at index",
      placeholder: "Type the index"
    },
    precision: {
      type: "number",
      label: "Precision",
      default: 3
    },
    layout: {
      type: "select",
      label: "Layout",
      default: "horizontal",
      options: [
        {
          name: "Horizontal",
          value: "horizontal"
        },
        {
          name: "Vertical",
          value: "vertical"
        }
      ]
    },
    showDevice: {
      type: "boolean",
      label: "Show Device",
      default: false
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label"
        },
        {
          name: "Name",
          value: "Name"
        },
        {
          name: "None",
          value: "None"
        }
      ]
    },
    showIndex: {
      type: "boolean",
      label: "Show Index",
      default: false
    },
    showLabel: {
      type: "boolean",
      label: "Show Labels",
      default: false
    },
    fontSize: {
      type: "number",
      label: "Font Size (px)",
      default: 16,
      nonNegative: true
    },
    customCss: {
      type: "style",
      label: "Custom CSS",
      default: ""
    }
  }
};

const SpectrumTableExport = { component: SpectrumTable, definition };
export default SpectrumTableExport;