import React, { Component } from "react";
import moment from "moment";
import "./DashboardTitle.css";
import { connect } from "react-redux";
import {
  getSelectedDashboard,
  getUserName,
  getNotification,
  getMode,
  hasSelectedWidgets,
  getUserGroups,
  getSelectedWidgets,
  hasClipboardWidgets
} from "../../shared/state/selectors";
import { IRootState } from "../../shared/state/reducers/rootReducer";
import { getUserState } from "../../shared/user/state/selectors";

import { Dashboard, Widget } from "../types";
import {
  renameDashboard,
  cloneDashboard,
  undo,
  redo,
  duplicateWidget,
  shareDashboard,
  copyToWidgetClipboard,
  pasteFromWidgetClipboard
} from "../../shared/state/actions/actionCreators";
import { Notification } from "../types";
import { Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareDashboardModal from "./modals/ShareDashboardModal";
import { isEditableDashboard } from "../utils"
import { SelectedDashboardState } from "../../shared/state/reducers/selectedDashboard";
import { IUserState } from "../../shared/user/state/reducer";



const redoHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+Shift+z" : "Ctrl+y";
const undoHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+z" : "Ctrl+z";
const duplicateHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+d" : "Ctrl+d";
const copyHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+c" : "Ctrl+c";
const pasteHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+v" : "Ctrl+v";


interface Props {
  dashboard: Dashboard;
  loggedInUser: string;
  user: IUserState;
  notification: Notification;
  mode: "edit" | "run";
  hasSelectedWidgets: boolean;
  hasClipboardWidgets: boolean;
  selectedWidgets: Widget[];
  onTitleChange: (id: string, name: string, environment: string[]) => void;
  onClone: (id: string, newUser: string) => void;
  onDuplicateWidget: () => void;
  onCopyWidget: (widgets: Widget[]) => void;
  onPasteWidget: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onShareDashboard: (
    id: string,
    group: string,
    groupWriteAccess: boolean
  ) => void;
  userGroups: string[];
  children: React.ReactNode;
}

interface State {
  wipName: string | null;
  showShareModal: boolean;
}

class DashboardTitle extends Component<Props, State> {
  public inputRef: any;

  constructor(props) {
    super(props);
    this.handleShareDashboard = this.handleShareDashboard.bind(this);
    this.state = { wipName: null, showShareModal: false };
  }

  private handleShareDashboard(
    id: string,
    group: string,
    groupWriteAccess: boolean
  ) {
    this.props.onShareDashboard(id, group, groupWriteAccess);
    this.setState({ showShareModal: false });
  }

  public render() {
    const { dashboard, loggedInUser, mode, userGroups } = this.props;
    const {
      id,
      user: owner,
      group,
      lastUpdatedBy,
      updateTime,
      groupWriteAccess,
      environment
    } = dashboard;
 
    const isMine = loggedInUser === owner;
    const inEditMode = mode === "edit";
    const showRecentlyEditedMessage =
      wasRecently(updateTime) &&
      lastUpdatedBy &&
      lastUpdatedBy !== loggedInUser;
    const editableTitle = (isMine || !owner) && inEditMode;
    const isSharedWithMe = userGroups.includes(group || "") && !isMine;

    const showOwnedByElseMsg = !isMine && owner;
    const showSharedMessage = isSharedWithMe;
    const showSharedForEditMessage = isSharedWithMe && groupWriteAccess;
    

    const clonable = !!owner; // && !isMine; user requested option to always clone a dashboard!
    const { msg: notificationMsg } = this.props.notification;

    const shareButtonColor =
      isMine && group ? "#17a6b7" : isMine ? "inherit" : "greytext";
    let ds = dashboard as SelectedDashboardState;
    const dashboardEditable = isEditableDashboard(ds, this.props.user).result;
    if (!loggedInUser) {
      return (
        <div className="dashboard-menu">
          <>
          {id && <span style={{ marginLeft: "0.5em" }}>{dashboard.name}</span>}
          {inEditMode && (
            //feedBackService.setData({level: NotificationLevel.INFO, message: 'You need to be logged in to save dashboards'})
            console.log('You need to be logged in to save dashboards')
          )}
          </>
        </div>
      );
    }

    const { wipName } = this.state;
    const name =
      wipName != null ? wipName : dashboard.name || "Untitled dashboard";
    const redoDisabled = this.props.dashboard.history.redoLength === 0;
    const undoDisabled = this.props.dashboard.history.undoLength === 0;

    if(inEditMode && notificationMsg)
    {
      //const error = {level: level, message: notificationMsg}
      //feedBackService.setData(error);
    }

    return (
      <>
        {this.state.showShareModal && (
          <ShareDashboardModal
            id={dashboard.id}
            name={dashboard.name}
            userGroups={userGroups}
            currentGroup={dashboard.group}
            currentGroupWriteAccess={dashboard.groupWriteAccess}
            onClose={() => this.setState({ showShareModal: false })}
            onShare={this.handleShareDashboard}
          />
        )}
        <div className="dashboard-menu">
          <input
            ref={ref => (this.inputRef = ref)}
            type="text"
            value={name}
            disabled={!editableTitle}
            onChange={e => this.setState({ wipName: e.target.value })}
            onKeyPress={e => {
              if (e.key === "Enter" && wipName != null) {
                this.props.onTitleChange(id, wipName, environment);
                e.currentTarget.blur();
              }
            }}
            onBlur={() => this.setState({ wipName: null })}
            onFocus={() => this.inputRef.select()}
          />
          {inEditMode && (
            <>
              <button
                className="dashboard-menu-button"
                title={`Undo last action\n${undoHotKey}`}
                onClick={this.props.onUndo}
                disabled={undoDisabled || !dashboardEditable}
              >
                <FontAwesomeIcon icon="undo" />
              </button>
              <button
                className="dashboard-menu-button"
                title={`Redo last action\n${redoHotKey}`}
                onClick={this.props.onRedo}
                disabled={redoDisabled || !dashboardEditable}
              >
                <FontAwesomeIcon icon="redo" />
              </button>
              <button
                className="dashboard-menu-button"
                title={`Duplicate currently selected widgets (copy and paste) \n${duplicateHotKey}`}
                onClick={this.props.onDuplicateWidget}
                disabled={!this.props.hasSelectedWidgets || !dashboardEditable}
              >
                <FontAwesomeIcon icon="clone" />
              </button>

              <button
                className="dashboard-menu-button"
                title={`Copy currently selected widgets\n${copyHotKey}`}
                onClick={() =>
                  this.props.onCopyWidget(this.props.selectedWidgets)
                }
                disabled={!this.props.hasSelectedWidgets || !dashboardEditable}
              >
                <FontAwesomeIcon icon="copy" />
              </button>
              <button
                className="dashboard-menu-button"
                title={`Paste current clipboard widgets\n${pasteHotKey}`}
                onClick={this.props.onPasteWidget}
                disabled={!this.props.hasClipboardWidgets || !dashboardEditable}
              >
                <FontAwesomeIcon icon="paste" />
              </button>
            </>
          )}
          {inEditMode && userGroups && userGroups.length > 0 && (
            <button
              className="dashboard-menu-button"
              style={{
                color: shareButtonColor
              }}
              disabled={!dashboard.id || !isMine}
              title={
                dashboard.group
                  ? "This dashboard is shared with the group '" +
                    dashboard.group +
                    "'"
                  : "Share this dashboard with a user group"
              }
              onClick={() => this.setState({ showShareModal: true })}
            >
              <FontAwesomeIcon icon="share-alt" />
            </button>
          )}
          {showOwnedByElseMsg && inEditMode && (
            <span
              className="notification-msg "
              title={`This dashboard is owned by ${owner}`}
            >
              <FontAwesomeIcon icon="user" /> {owner}
            </span>
          )}
          {showSharedMessage && inEditMode && (
            <span
              className="notification-msg "
              title={
                "This dashboard is shared " +
                (showSharedForEditMessage ? "for edit" : "as readonly") +
                " with the group " +
                dashboard.group
              }
            >
              {showSharedForEditMessage ? (
                <FontAwesomeIcon icon="pen" />
              ) : (
                <FontAwesomeIcon icon="share-alt" />
              )}{" "}
              {dashboard.group}
            </span>
          )}
          {showRecentlyEditedMessage && inEditMode && (
            <span
              className="notification-msg ERROR "
              title={`This dashboard is currently being edited by ${lastUpdatedBy}`}
            >
              <FontAwesomeIcon icon="user-edit" /> {lastUpdatedBy}
            </span>
          )}
          {clonable && inEditMode && (
            <button
              onClick={() => this.props.onClone(id, loggedInUser)}
              className="btn-clone"
              title="Create a new copy of this dashboard"
            >
              Clone
            </button>
          )}
          {/* {inEditMode && notificationMsg && (
            <span className={`notification-msg " + ${level}`}>
              {notificationMsg}
            </span>
          )} */}
        </div>
      </>
    );
  }
}
function wasRecently(timestamp: Date | null) {
  if (!moment(timestamp || "").isValid()) {
    return false;
  }
  const diffInSeconds = moment().diff(moment(timestamp || "")) / 1000;
  return diffInSeconds < 60;
}

function mapStateToProps(state: IRootState) {
  return {
    dashboard: getSelectedDashboard(state),
    loggedInUser: getUserName(state),
    notification: getNotification(state),
    hasSelectedWidgets: hasSelectedWidgets(state),
    userGroups: getUserGroups(state),
    user: getUserState(state),
    mode: getMode(state),
    selectedWidgets: getSelectedWidgets(state),
    hasClipboardWidgets: hasClipboardWidgets(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onTitleChange: (id: string, name: string, environment: string[]) => {
      dispatch(renameDashboard(id, name, environment));
    },
    onClone: (id: string, newUser: string) => {
      dispatch(cloneDashboard(id, newUser));
    },
    onUndo: () => {
      dispatch(undo());
    },
    onRedo: () => {
      dispatch(redo());
    },
    onDuplicateWidget: () => {
      dispatch(duplicateWidget());
    },
    onCopyWidget: (widgets: Widget[]) => {
      dispatch(copyToWidgetClipboard(widgets));
    },
    onPasteWidget: () => {
      dispatch(pasteFromWidgetClipboard());
    },
    onShareDashboard: (id: string, group: string, groupWriteAccess: boolean) =>
      dispatch(shareDashboard(id, group, groupWriteAccess))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTitle);
