import { fork } from "redux-saga/effects";
import createUserSaga from "../../user/state/saga";
import tango from "./tango";
import mongodb from "./mongodb";
import elasticSaga from "../../elastic/state/saga";
import dashboards from "./dashboards";
import synoptics from "../../../synoptic/state/sagas";

export default function* sagas() {
  yield fork(createUserSaga());
  yield fork(tango);
  yield fork(mongodb);
  yield fork(elasticSaga());
  yield fork(dashboards);
  yield fork(synoptics);
}
