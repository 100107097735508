import React, { useState } from "react";
import cx from "classnames";
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from "../../../../shared/modal/components/Modal/Modal";

import "./DescriptionDisplay.css";
import { Button } from "react-bootstrap";

function DescriptionDisplay({ name, description }) {
  const [onDisplay, setOnDisplay] = useState(false);

  return (
    <>
      {onDisplay && (
        <Modal title={name}>
          <Modal.Body>
            <div style={{ whiteSpace: "pre-wrap" }}>{description}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="primary"
              onClick={() => setOnDisplay(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <ReactTooltip
        content={description}
        useDefaultStyles={true}
        hoverDelay={0}
        direction="left"
      >
        <i
        data-testid="info"
          className={cx("DescriptionDisplay fa fa-info-circle", {
            "no-description": description === "No description"
          })}
          onClick={() => setOnDisplay(true)}
          title={description}
        />
      </ReactTooltip>
    </>
  );
}

export default DescriptionDisplay;
