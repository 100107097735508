/*

Naming convention:

- FETCH_XXX           Remote asynchronous calls
- FETCH_XXX_SUCCESS   Data returned from remote calls (typically created in .then(json => ...))
- FETCH_XXX_FAILED    Failure to retrieve remote data (typically created in .catch(err => ...))
- SET_XXX             Set local state synchronously

Avoid GET_XXX in order not to confuse selectors and action creators.

*/

export const FETCH_DATABASE_INFO = "FETCH_DATABASE_INFO";
export const FETCH_DATABASE_INFO_SUCCESS = "FETCH_DATABASE_INFO_SUCCESS";
export const FETCH_DATABASE_INFO_FAILED = "FETCH_DATABASE_INFO_FAILED";

export const LOAD_TANGO_DB_NAME = "LOAD_TANGO_DB_NAME";
export const LOAD_TANGO_DB_NAME_SUCCESS = "LOAD_TANGO_DB_NAME_SUCCESS";
export const LOAD_TANGO_DB_NAME_FAILED = "LOAD_TANGO_DB_NAME_FAILED";

export const FETCH_DEVICE_NAMES = "FETCH_DEVICE_NAMES";
export const FETCH_DEVICE_NAMES_SUCCESS = "FETCH_DEVICE_NAMES_SUCCESS";
export const FETCH_DEVICE_NAMES_FAILED = "FETCH_DEVICE_NAMES_FAILED";

export const FETCH_ATTRIBUTES = "FETCH_ATTRIBUTES";
export const FETCH_ATTRIBUTES_SUCCESS = "FETCH_ATTRIBUTES_SUCCESS";
export const FETCH_ATTRIBUTES_FAILED = "FETCH_ATTRIBUTES_FAILED";

export const SUBSCRIBE_DEVICE_ATTRS = "SUBSCRIBE_DEVICE_ATTRS";
export const SUBSCRIBE_DEVICE_ATTRS_SUCCESS = "SUBSCRIBE_DEVICE_ATTRS_SUCCESS";
export const SUBSCRIBE_DEVICE_ATTRS_FAILED = "SUBSCRIBE_DEVICE_ATTRS_FAILED";

export const UNSUBSCRIBE_DEVICE_ATTRS = "UNSUBSCRIBE_DEVICE_ATTRS"

export const FETCH_DEVICE = "FETCH_DEVICE";
export const FETCH_DEVICE_SUCCESS = "FETCH_DEVICE_SUCCESS";
export const FETCH_DEVICE_FAILED = "FETCH_DEVICE_FAILED";

export const SET_CURRENT_DEVICE = "SET_CURRENT_DEVICE";
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const SET_DATA_FORMAT = "SET_DATA_FORMAT";

export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const DEVICE_STATE_RECEIVED = "DEVICE_STATE_RECEIVED";
export const ATTRIBUTE_FRAME_RECEIVED = "ATTRIBUTE_FRAME_RECEIVED";

export const FETCH_LOGGED_ACTIONS = "FETCH_LOGGED_ACTIONS";
export const FETCH_LOGGED_ACTIONS_SUCCESS = "FETCH_LOGGED_ACTIONS_SUCCESS";
export const FETCH_LOGGED_ACTIONS_FAILED = "FETCH_LOGGED_ACTIONS_FAILED";

export const EXECUTE_COMMAND = "EXECUTE_COMMAND";
export const EXECUTE_COMMAND_SUCCESS = "EXECUTE_COMMAND_SUCCESS";
export const EXECUTE_COMMAND_FAILED = "EXECUTE_COMMAND_FAILED";

export const ENABLE_DISPLEVEL = "ENABLE_DISPLEVEL";
export const DISABLE_DISPLEVEL = "DISABLE_DISPLEVEL";
export const ENABLE_ALL_DISPLEVEL = "ENABLE_ALL_DISPLEVEL";

export const SET_DEVICE_PROPERTY = "SET_DEVICE_PROPERTY";
export const SET_DEVICE_PROPERTY_SUCCESS = "SET_DEVICE_PROPERTY_SUCCESS";
export const SET_DEVICE_PROPERTY_FAILED = "SET_DEVICE_PROPERTY_FAILED";

export const SET_DEVICE_ATTRIBUTE = "SET_DEVICE_ATTRIBUTE";
export const SET_DEVICE_ATTRIBUTE_SUCCESS = "SET_DEVICE_ATTRIBUTE_SUCCESS";
export const SET_DEVICE_ATTRIBUTE_FAILED = "SET_DEVICE_ATTRIBUTE_FAILED";

export const DELETE_DEVICE_PROPERTY = "DELETE_DEVICE_PROPERTY";
export const DELETE_DEVICE_PROPERTY_SUCCESS = "DELETE_DEVICE_PROPERTY_SUCCESS";
export const DELETE_DEVICE_PROPERTY_FAILED = "DELETE_DEVICE_PROPERTY_FAILED";

const DATA = {
    LOAD: 'DATA_LOAD',
    LOAD_SUCCESS: 'DATA_LOAD_SUCCESS',
    LOAD_FAIL: 'DATA_LOAD_FAIL',
};

const TODO = {
    UPDATE_TODO_LIST: 'UPDATE_TODO_LIST',
    UPDATE_TODO_FIELD: 'UPDATE_TODO_FIELD',
};

const WEBSOCKET = {
    WS_OPEN: 'WS_OPEN',
    WS_SUBSCRIBE: 'WS_SUBSCRIBE',
    WS_SUBSCRIBED: 'WS_SUBSCRIBED',
    WS_UNSUBSCRIBED: 'WS_UNSUBSCRIBED',
    WS_MESSAGE: 'WS_MESSAGE',
    WS_EXECUTE_COMMAND: 'WS_EXECUTE_COMMAND',
    WS_EXECUTE_COMMAND_SUCCESS: 'WS_EXECUTE_COMMAND_SUCCESS',
    WS_CLOSE: 'WS_CLOSE',
}

export { DATA, TODO, WEBSOCKET };

export const UNDO = "UNDO"
export const REDO = "REDO"
export const DUPLICATE_WIDGET = "DUPLICATE_WIDGET"

export const SELECT_WIDGETS = "SELECT_WIDGETS";
export const DELETE_WIDGET = "DELETE_WIDGET";
export const ADD_WIDGET = "ADD_WIDGET";
export const ADD_INNER_WIDGET = "ADD_INNER_WIDGET";
export const REORDER_INNER_WIDGET = "REORDER_INNER_WIDGET"
export const DROP_INNER_WIDGET = "DROP_INNER_WIDGET";
export const MOVE_WIDGETS = "MOVE_WIDGET";
export const RESIZE_WIDGET = "RESIZE_WIDGET";

export const SET_INPUT = "SET_INPUT";
export const ADD_INPUT = "ADD_INPUT";
export const DELETE_INPUT = "DELETE_INPUT";

export const SELECT_CANVAS = "SELECT_CANVAS";

export const TOGGLE_MODE = "TOGGLE_MODE";

export const SAVE_DASHBOARD="SAVE_DASHBOARD"
export const DASHBOARD_SAVED="DASHBOARD_SAVED"

export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const DASHBOARD_UPDATED = "DASHBOARD_UPDATED";

export const LOAD_DASHBOARD = "LOAD_DASHBOARD";
export const DASHBOARD_LOADED = "DASHBOARD_LOADED";

export const EXPORT_DASHBOARD = "EXPORT_DASHBOARD";
export const DASHBOARD_EXPORTED = "DASHBOARD_EXPORTED";

export const IMPORT_DASHBOARD = "IMPORT_DASHBOARD";
export const DASHBOARD_IMPORTED = "DASHBOARD_IMPORTED";

export const EXPORT_ALL_DASHBOARDS = "EXPORT_ALL_DASHBOARDS";
export const ALL_DASHBOARDS_EXPORTED = "ALL_DASHBOARDS_EXPORTED";

export const LOAD_DASHBOARDS = "LOAD_DASHBOARDS";
export const DASHBOARDS_LOADED = "DASHBOARDS_LOADED";

export const RENAME_DASHBOARD = "RENAME_DASHBOARD";
export const DASHBOARD_RENAMED = "DASHBOARD_RENAMED";

export const DELETE_DASHBOARD = "DELETE_DASHBOARD";
export const DASHBOARD_DELETED = "DASHBOARD_DELETED";

export const CLONE_DASHBOARD = "CLONE_DASHBOARD";
export const DASHBOARD_CLONED = "DASHBOARD_CLONED";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const DASHBOARD_CREATED = "DASHBOARD_CREATED";

export const SHARE_DASHBOARD = "SHARE_DASHBOARD";
export const DASHBOARD_SHARED = "DASHBOARD_SHARED";

export const DASHBOARD_EDITED = "DASHBOARD_EDITED";

export const REORDER_WIDGETS = "REORDER_WIDGETS"
export const UPDATE_WIDGET = "UPDATE_WIDGET"

export const WIDGET_CLIPBOARD_COPY = "WIDGET_CLIPBOARD_COPY"
export const WIDGET_CLIPBOARD_PASTE = "WIDGET_CLIPBOARD_PASTE"

export const TOGGLE_LIBRARY_COLLAPSED = "TOGGLE_LIBRARY_COLLAPSED"
export const TOGGLE_INSPECTOR_COLLAPSED = "TOGGLE_INSPECTOR_COLLAPSED"
export const MOUSE_DOWN_ON_WIDGET = "MOUSE_DOWN_ON_WIDGET"
export const CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION"

export const WIDGET_INVALID = 0;
export const WIDGET_VALID = 1;
export const WIDGET_WARNING = 2;
export const WIDGET_MISSING_DEVICE = 3;

