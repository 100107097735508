import {
  IFetchElasticLogsAction, IFetchElasticLogsErrordAction, IFetchElasticLogsSuccessAction
} from "./actions";

import {
  FETCH_ELASTIC,
  FETCH_ELASTIC_ERROR,
  FETCH_ELASTIC_SUCCESS
} from "./actionTypes";

export function fetchElasticLogs(filters: Object):IFetchElasticLogsAction {
  return { type: FETCH_ELASTIC, filters: filters };
}

export function fetchElasticLogsSucces(results: Object):IFetchElasticLogsSuccessAction {
  return { type: FETCH_ELASTIC_SUCCESS, results: results };
}

export function fetchElasticLogsError(error: string):IFetchElasticLogsErrordAction {
  return { type: FETCH_ELASTIC_ERROR, error: error };
}
