import React, { Component } from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
} from "../../types";
import SpectrumValues from "./SpectrumValues";
import { showHideTangoDBName } from "../../DBHelper";


type Inputs = {
  attribute: AttributeInputDefinition;
  showTitle: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  inelastic: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
};


type Props = WidgetProps<Inputs>;

class Spectrum extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render() {
    const { mode, inputs } = this.props;
    const { attribute, showTitle, inelastic, showAttribute, showTangoDB } = inputs;
    let display = "";
    const deviceName = showHideTangoDBName(true, showTangoDB, attribute.device)
    if (showAttribute === "Label") display = attribute.label;
    else if (showAttribute === "Name") display = attribute.attribute;
    const title =
      showTitle === false
        ? null
        : mode === "library"
        ? "device/attribute"
        : `${deviceName || "?"}/${display || "?"}`;

    return (
      <div>
        <SpectrumValues
          deviceName={attribute.device}
          attributeName={attribute.attribute}
          mode={mode}
          showTitle={showTitle}
          title={title}
          titlefont={{ size: 12 }}
          font={{ family: "Helvetica, Arial, sans-serif" }}
          margin={{
            l: 30,
            r: 15,
            t: 15 + (showTitle ? 20 : 0),
            b: 20,
          }}
          autosize={true}
          inelastic={inelastic}
          config={{ staticPlot: mode === "run" ? false : true }}
          responsive={true}
          style={{
            width: this.props.actualWidth,
            height: mode === "library" ? 150 : this.props.actualHeight,
          }}
        />
      </div>
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "SPECTRUM",
  name: "Spectrum",
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    attribute: {
      label: "",
      type: "attribute",
      dataFormat: "spectrum",
      dataType: "numeric",
      required: true,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
      ],
    },
    showTitle: {
      type: "boolean",
      label: "Show Title",
      default: true,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    inelastic: {
      type: "boolean",
      label: "Inelastic Y Axis",
      default: true,
    },
  },
};

const SpectrumExport = { component: Spectrum, definition };
export default SpectrumExport;
