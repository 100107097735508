/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { openLoginDialog } from "../../../../shared/user/state/actionCreators";
import { IRootState } from "../../../../shared/state/reducers/rootReducer";

function NotLoggedIn(props) {

  const dispatch = useDispatch()
  const isLogged = useSelector((state: IRootState) => state.user.username)

  return isLogged ? null : (
    <div className="alert alert-warning" role="alert">
      {props.children}{" "}
      <a href='#' onClick={(e) => { e.preventDefault(); dispatch(openLoginDialog()) }}>Click here to log in.</a>
    </div>
  )
}

export default NotLoggedIn;
