import React, { CSSProperties, useState, useEffect } from 'react';
import { IRootState } from '../../../shared/state/reducers/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAttributeLastTimeStampFromState,
  getAttributeLastValueFromState,
} from '../../../shared/utils/getLastValueHelper';
import { setDeviceAttribute } from '../../../shared/state/actions/tango';
import { getTangoDB } from '../../../dashboard/dashboardRepo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseCss } from '../../components/Inspector/StyleSelector';

interface Props {
  attributeName: string;
  deviceName: string;
  dataType: string;
  mode: string;
  styleCheckbox: CSSProperties;
  OnCSS: string;
  OffCSS: string;
}

const BooleanValues: React.FC<Props> = ({
  attributeName,
  deviceName,
  dataType,
  mode,
  styleCheckbox,
  OnCSS,
  OffCSS
}) => {
  const dispatch = useDispatch();
  const tangoDB = useSelector(() => {
    return getTangoDB();
  });

  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, deviceName, attributeName);
  });

  const timestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(state.messages, deviceName, attributeName)?.toString();
  });

  const isBoolean = dataType === 'DevBoolean';

  const [writeValue, setWriteValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (writeValue !== value) {
        setWriteValue(value);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [value, writeValue]);

  const handleChange = () => {
    dispatch(setDeviceAttribute(tangoDB, deviceName, attributeName, !value));

  };

  const Checkbox = mode !== 'run' || isBoolean ? (
    <span title={new Date(timestamp ? timestamp : 1692782581.762031 * 1000).toUTCString()}>
      {writeValue}
      <label className="toggle">
        <input
          title="boolean_display"
          type="checkbox"
          checked={Boolean(value)}
          disabled={writeValue !== value}
          value={value}
          onClick={() => handleChange()}
          onChange={() => setWriteValue(!value)}
        />
        <span className="slider round" style={Boolean(value) ? parseCss(OnCSS).data : parseCss(OffCSS).data}>
          {writeValue !== value ? <FontAwesomeIcon icon="spinner" /> : ''}
        </span>
      </label>
    </span>
  ) : (
    'Selected attribute is different than boolean'
  );

  return <span style={styleCheckbox}>{Checkbox}</span>;
};

export default BooleanValues;
