import React, { ChangeEvent } from "react";
import { getTangoDB } from "../dashboardRepo";

const config = window['config'];

type Props = {
  widgetId: string;
  tangoDB: string;
  onSelect: (value) => void;
  hasDevice: boolean;
};

const TangoDbSelector = (props: Props) => {
  const { onSelect } = props;

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    onSelect(value);
  }

  const getDatabases = (): string[] => {
    let databases: string[] = [];
    if (config.TANGO_DATABASES.length > 0)
      databases = [...config.TANGO_DATABASES];

    const currentDB = getTangoDB();

    if (currentDB && currentDB !== props.tangoDB)
      databases.unshift(currentDB);

    return Array.from(new Set(databases.filter((db) => db !== props.tangoDB)))
  }

  if (getDatabases().length === 0) return null;

  return (
    <div>
      <select
        className="form-control parametric-dropdown"
        style={{ marginTop: "0px" }}
        value={props.tangoDB}
        onChange={onSelectChange}
      >
        <option>{props.tangoDB}</option>
        {getDatabases().map((db) => <option key={db}>{db}</option>)}
      </select>
    </div>
  );
}

export default TangoDbSelector
