import AttributePlot from "./AttributePlot";
import {
  WidgetDefinition,
  NumberInputDefinition,
  BooleanInputDefinition,
  ComplexInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
  ColorInputDefinition
} from "../../../dashboard/types";

export interface AttributeComplexInput {
  attribute: AttributeInputDefinition;
  yAxis: SelectInputDefinition<"left" | "right">;
  showAttribute: SelectInputDefinition;
  lineColor: ColorInputDefinition;
}

export type Inputs = {
  timeWindow: NumberInputDefinition;
  showZeroLine: BooleanInputDefinition;
  logarithmic: BooleanInputDefinition;
  attributes: ComplexInputDefinition<AttributeComplexInput>;
  showTangoDB: BooleanInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
}

const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTE_PLOT",
  name: "Attribute Plot",
  defaultWidth: 30,
  defaultHeight: 20,
  inputs: {
    timeWindow: {
      type: "number",
      default: 120,
      label: "Time Window"
    },
    showZeroLine: {
      type: "boolean",
      default: true,
      label: "Show Zero Line"
    },
    logarithmic: {
      type: "boolean",
      default: false,
      label: "Log y axis"
    },
    attributes: {
      label: "Graphs",
      type: "complex",
      repeat: true,
      inputs: {
        attribute: {
          label: "",
          type: "attribute",
          required: true,
          dataFormat: "scalar",
          dataType: "numeric"
        },
        showAttribute: {
          type: "select",
          label: "Attribute display:",
          default: "Label",
          options: [
            {
              name: "Label",
              value: "Label"
            },
            {
              name: "Name",
              value: "Name"
            }
          ]
        },
        yAxis: {
          type: "select",
          default: "left",
          label: "Y Axis",
          options: [
            {
              name: "Left",
              value: "left"
            },
            {
              name: "Right",
              value: "right"
            }
          ]
        },
        lineColor: {
          label: "Line Color",
          type: "color",
          default: "#000000"
        },
      }
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000"
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff"
    }
  }
};

const AttributePlotExport = { definition, component: AttributePlot };
export default AttributePlotExport;