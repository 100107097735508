import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../shared/state/reducers/rootReducer";
import { getAttributeLastValueFromState } from "../../../../shared/utils/getLastValueHelper";

interface ImageDisplayProps {
    device: string;
    attribute: string;
}

export function ImageDisplay(props: ImageDisplayProps) {
    const image = useSelector((state: IRootState) => {
        return getAttributeLastValueFromState(state.messages, props.device, props.attribute);
    });

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const content = <canvas ref={canvasRef} />;

    if (canvasRef.current == null) {
        return content;
    }

    const width = image[0].length;
    const height = image.length;

    let max = Number(image[0][0]);
    for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
            const pixel = Number(image[x][y]);
            if (pixel > max) {
                max = pixel;
            }
        }
    }

    const context = canvasRef?.current?.getContext("2d");
    if (context == null) {
        return content;
    }

    let imgData;
    if(context?.createImageData) imgData = context?.createImageData(width, height);

    for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
            const value = image[x][y];
            const index = y * width * 4 + x * 4;
            const normal = 255 * (Number(value) / (max === 0 ? 1 : max));
            if(imgData){
                imgData.data[index + 0] = normal;
                imgData.data[index + 1] = normal;
                imgData.data[index + 2] = normal;
                imgData.data[index + 3] = 255;
            }
        }
    }

    if(imgData) if(context?.putImageData) context?.putImageData(imgData, 0, 0);
    return content;
}