import React, { Component } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import TangoAPI from "../../../shared/api/tangoAPI";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import { getRunningDevices, checkVariableDevice } from "../../../shared/utils/DashboardVariables";
import { getDevicePropertiesFromVariables } from '../../../shared/utils/DashboardVariables'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../shared/modal/components/Modal/Modal";
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css'
import { validateEnvironment } from "../../../shared/utils/utils";

library.add(faEdit, faTrash)
export default class ConfigDashboardModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddBox: false,
      environment: null,
      savedEnvironment: null,
      variableExist: false,
      editVariable: 0,
      confirmDelete: 0,
      addVariableName: '',
      addVariableClass: '',
      addVariableDevice: '',
      alertMsg: '',
      showMsg: false,
      alertVariant: "success",
      contentHeaderTitle: ['Variable Name', 'Class Name', 'Default Device', 'Actions'],
      updatedVariables: [],
      tangoClasses: [],
      tangoDevices: [],
      tangoDevicesEdit: [],
      dashboardVariablesDevices: []
    }
  }

  componentDidMount() {
    this.fetchClasses();
    getDevicePropertiesFromVariables(this.props.tangoDB, this.props.dashboardVariables).then(devices =>
      this.setState({ dashboardVariablesDevices: devices }));
  }

  handleAddVariable(event) {
    event.preventDefault();
    this.props.addDashboardVariable(this.props.id, this.state.addVariableName, this.state.addVariableClass, this.state.addVariableDevice);
    this.setState({ showAddBox: false, showMsg: true, alertMsg: 'Variable added successfully', alertVariant: "success", addVariableClass: '', addVariableDevice: '' });

    const newDashboardVariable = {
      name: this.state.addVariableName,
      class: this.state.addVariableClass,
      device: this.state.addVariableDevice
    };
    // Only fetch attributes of the device if device is not present in this.state.dashboardVariablesDevices
    if (!this.state.dashboardVariablesDevices.find(d => d.name === newDashboardVariable.device)) {
      getDevicePropertiesFromVariables(this.props.tangoDB, [newDashboardVariable]).then(devices => {
        this.setState({ dashboardVariablesDevices: [...devices, ...this.state.dashboardVariablesDevices] });
      }
      );
    }

  }

  handleDeleteVariable(VariableName, confirm) {
    if (confirm) {
      this.props.deleteDashboardVariable(this.props.id, VariableName);
      this.setState({ showMsg: true, alertMsg: 'Variable deleted successfully', alertVariant: "success" });
      this.setState({ confirmDelete: 0 })
    }
    else {
      this.setState({ confirmDelete: VariableName })
    }
  }

  handleSaveVariable(variableId) {
    this.setState({ editVariable: 0 });
    this.props.saveDashboardVariables(variableId, this.props.id);
    this.setState({ showMsg: true, alertMsg: 'Variables saved successfully', alertVariant: "success" });

    const newDashboardVariable = {
      name: variableId,
      class: this.state.addVariableClass,
      device: this.state.addVariableDevice
    };
    // Only fetch attributes of the device if device is not present in this.state.dashboardVariablesDevices
    if (!this.state.dashboardVariablesDevices.find(d => d?.name === newDashboardVariable.device)) {
      getDevicePropertiesFromVariables(this.props.tangoDB, [newDashboardVariable]).then(devices => {
        this.setState({ dashboardVariablesDevices: [...devices, ...this.state.dashboardVariablesDevices] });
      }
      );
    }
  }

  handleEditVariable(VariableName, confirm, variableClass) {
    if (!confirm) {
      this.setState({ editVariable: VariableName });
      this.fetchDevices(variableClass, 'editVariable')
    }
    else {
      this.setState({ editVariable: 0 });
    }
  }

  handleChangeDeviceClassDropdown(event) {
    this.setState({ addVariableClass: event.target.value }, () => {
      if (this.state.tangoClasses.filter((c) => { return c.name === this.state.addVariableClass }).length > 0) {
        this.fetchDevices(this.state.addVariableClass);
      }
    })
  }

  handleChangeDeviceDropdown(event) {
    this.setState({ addVariableDevice: event.target.value });
  }

  handleChangeVariableName(event) {
    if (this.props.dashboardVariables.filter((v) => { return v.name === event.target.value }).length > 0)
      this.setState({ variableExist: true })
    else {
      this.setState({ addVariableName: event.target.value, variableExist: false });
    }
  }

  handleChangeEditDeviceDropdown(event, variableName) {
    this.setState({ addVariableDevice: event.target.value });
    this.props.updateDashboardVariable(variableName, 'Device', event.target.value);
  }

  handleChangeEditDeviceClassDropdown(event, variableName) {
    const newDeviceClassName = event.target.value;
    this.setState({ addVariableClass: newDeviceClassName });
    this.props.updateDashboardVariable(variableName, 'Class', newDeviceClassName);
    this.fetchDevices(newDeviceClassName, 'editVariable').then(() => {
      const defaultDevice = this.state.tangoDevicesEdit.length > 0 ? this.state.tangoDevicesEdit[0].name : '';
      this.setState({ addVariableDevice: defaultDevice });
      this.props.updateDashboardVariable(variableName, 'Device', defaultDevice);
    })
  }

  handleCancel() {
    this.setState({ editVariable: 0 });
    this.props.handleCancel();
  }

  deviceExistsInDashboardVariableDevices(device) {
    // Without this function, the user may see "Device not exported/running" for a fraction of second when adding/editing a variable
    const stateDevices = this.state.dashboardVariablesDevices;
    return stateDevices.some(function (d) {
      return d?.name === device;
    });
  }

  getAlertComponent() {
    return (
      <Alert
        show={this.state.showMsg}
        timeout={5000}
        onClose={() => this.setState({ showMsg: false })}
        dismissible
        className={`modal-alert ${this.state.showMsg ? 'alert-animate' : ''}`}
        variant={this.state.alertVariant}
        onAnimationEnd={() => this.setState({ showMsg: false })}
      >
        {this.state.alertMsg}
      </Alert>
    )
  }

  render() {
    let objThis = this;

    return (
      <Modal title={`Configure Dashboard "${this.props.name}"`} size="lg">
        <Modal.Body>
          <Tabs
            defaultActiveKey={"variable"}
            id="config-modal"
            className="mb-3"
          >
            <Tab eventKey="variable" title="Variables">
              {this.getAlertComponent()}
              <div className="config-wrapper">
                <div className="config-header">

                  {this.state.showAddBox === true &&
                    <form onSubmit={(e) => this.handleAddVariable(e)}>
                      <div className="row">
                        <h6 className="col form-group">Add new variable</h6>
                      </div>
                      <div className="row">
                        <div className="col col-md-3 col-lg-4 col-sm-6 form-group">
                          <label htmlFor="add-new-var-name" className="form-label">Variable Name</label>
                          <input type="text" id="add-new-var-name" name="add-new-var-name" required pattern="^[a-zA-Z]{1}[\w]{1,14}$" title="Variable name must be between 2-15 characters, its aplhanumeric and should start with alphabet" className="form-control" placeholder="Variable Name" onChange={(e) => this.handleChangeVariableName(e)} />
                          {this.state.variableExist === true &&
                            <span className="error">Variable already exist {this.state.variableExist}</span>
                          }

                        </div>
                        <div className="col col-md-3 col-lg-4 col-sm-6 form-group">
                          <label htmlFor="add-new-var-class" className="form-label">Device Class</label>
                          <select className="form-control"
                            title="Select Device Class"
                            required
                            id="add-new-var-class"
                            name="add-var-class"
                            onChange={(e) => this.handleChangeDeviceClassDropdown(e)}
                          >
                            <option value="">Select</option>
                            {this.state.tangoClasses.map((tangoClass, j) =>
                              <option key={j} value={tangoClass.name}>{tangoClass.name}</option>
                            )}
                          </select>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-sm-6 form-group">
                          <label htmlFor="add-new-var-device" className="form-label">Default Device</label>

                          <select className="form-control"
                            title="Select Device Class"
                            required
                            id="add-new-var-device"
                            name="add-var-device"
                            onChange={(e) => this.handleChangeDeviceDropdown(e)}
                            value={this.state.addVariableDevice}
                          >
                            {/* {this.state.addVariableClass.length !== 0 &&
                              getRunningDevices(this.state.tangoDevices).map((tangoDevice, j) =>
                                <option key={j} value={tangoDevice.name}>{tangoDevice.name}</option>
                              )
                            } */}
                            {this.state.tangoDevices.length === 0 ?
                              <option value="" disabled> No devices </option> : <option value="" disabled> Select device </option>
                            }
                            {
                              this.state.tangoDevices.map((tangoDevice, j) =>
                                <option key={j} value={tangoDevice.name}>{tangoDevice.name}</option>
                              )
                            }


                          </select>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col form-group d-flex justify-content-end">
                          <button type="button" onClick={() => this.setState({ showAddBox: !this.state.showAddBox })} className="btn btn-outline-secondary mb-2">Close</button>
                        </div>
                        <div className="form-group">
                          <button type="submit" disabled={this.state.variableExist} id="btn-add-variable" onSubmit={(e) => this.handleAddVariable(e)} className="btn btn-primary mb-2">Add Variable</button>
                        </div>
                      </div>
                    </form>
                  }
                </div>

                <div className="config-content">
                  <div className="row m-0 mb-1">
                    <div className="pull-left has-search col col-md-3 col-lg-4 col-sm-6">
                      <div className="has-search">
                        <span className="fa fa-search form-control-search"></span>
                        <input type="search" id="config-search-variable" className="form-control" placeholder="Search Variable" onChange={(e) => this.props.filterDashboardVariables(e.currentTarget.value)} />
                      </div>
                    </div>
                    <div className="col col-lg-5"></div>
                    <div className="col col-md-3 col-lg-3 col-sm-6">
                      {!this.state.showAddBox &&
                        <button title="Add New Dashboard Variable" className="btn btn-outline-dark pull-right add-variable-form" type="button" onClick={(e) => this.setState({ showAddBox: !this.state.showAddBox })}>
                          Add new variable
                        </button>
                      }
                    </div>
                  </div>

                  <div className="row m-0 header-row" style={{ borderTop: "1px solid #dee2e6" }}>
                    {this.state.contentHeaderTitle.map((title) => {
                      return <div key={title} className="col col-md-3 col-lg-3 col-sm-6">
                        <label className={`form-label text-muted ${title === "Actions" ? "pull-right" : ""}`}>{title}</label>
                      </div>
                    })}
                  </div>

                  {this.props.dashboardVariables.length === 0 &&
                    <div className="d-flex justify-content-center text-muted">No record found.</div>
                  }
                  {this.props.dashboardVariables.length > 0 && this.props.dashboardVariables.map(function (variable) {
                    return <div key={variable.name} className="modal-content-row">
                      <div className="edit-view">
                        <div className="row m-0">
                          <span className="col col-md-3 col-lg-3 col-sm-6 d-flex align-items-center label-div">{variable.name}</span>
                          <div className="col-sm-6 col-md-4 col-lg-3">
                            {objThis.state.editVariable === variable.name &&
                              <select
                                className="form-control list-class-name"
                                name="list-class-name"
                                value={variable.class}
                                onChange={(e) => {
                                  objThis.handleChangeEditDeviceClassDropdown(
                                    e,
                                    variable.name
                                  )
                                }
                                }
                              >
                                {objThis.state.tangoClasses.map((tangoClass, j) =>
                                  <option key={j} value={tangoClass.name}>{tangoClass.name}</option>
                                )}
                              </select>
                            }
                            {objThis.state.editVariable !== variable.name &&
                              <span>
                                {variable.class}
                              </span>

                            }
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-3">
                            {objThis.state.editVariable === variable.name &&
                              <select
                                className="form-control list-device-name"
                                name="list-device-name"
                                value={variable.device}
                                onChange={(e) => {
                                  objThis.handleChangeEditDeviceDropdown(
                                    e,
                                    variable.name
                                  )
                                }}
                              >

                                {objThis.state.tangoDevicesEdit.map((tangoDevice, j) =>
                                  <option key={j} value={tangoDevice.name}>{tangoDevice.name}</option>
                                )
                                }
                              </select>
                            }
                            {objThis.state.editVariable !== variable.name &&
                              <span>
                                {variable.device}
                                {(objThis.deviceExistsInDashboardVariableDevices(variable.device) && checkVariableDevice(variable.device, objThis.state.dashboardVariablesDevices).exported === false && objThis.state.tangoClasses.length > 0) &&
                                  <div className="error">Device not exported</div>
                                }
                                {(objThis.deviceExistsInDashboardVariableDevices(variable.device) && checkVariableDevice(variable.device, objThis.state.dashboardVariablesDevices).connected === false && objThis.state.tangoClasses.length > 0) &&
                                  <div className="error">Device not running</div>
                                }
                              </span>
                            }
                          </div>
                          <div className="col-sm-6 col-md-1 col-lg-3">
                            {(objThis.state.editVariable !== variable.name && objThis.state.confirmDelete !== variable.name) &&
                              <div>
                                <button
                                  title="Delete Variable"
                                  onClick={(e) => objThis.handleDeleteVariable(variable.name, false)}
                                  className="btn btn-outline-secondary btn-sm btn-layer-action pull-right btn-delete"
                                >
                                  <FontAwesomeIcon icon="trash" />{" "}
                                </button>
                                <button
                                  title="Edit Variable"
                                  onClick={(e) => objThis.handleEditVariable(variable.name, false, variable.class)}
                                  className="btn btn-outline-secondary btn-sm btn-layer-action pull-right btn-edit"
                                >
                                  <FontAwesomeIcon icon="edit" />{" "}
                                </button>
                              </div>
                            }
                            {objThis.state.editVariable === variable.name &&
                              <div>
                                <button
                                  title="Save Variable"
                                  onClick={(e) => objThis.handleSaveVariable(variable?.name)}
                                  className="btn btn-primary pull-right"
                                >
                                  Save
                                </button>
                                <button
                                  title="Cancel"
                                  onClick={(e) => objThis.handleCancel()}
                                  className="btn btn-outline-secondary pull-right"
                                >
                                  Cancel
                                </button>
                              </div>
                            }
                            {objThis.state.confirmDelete === variable.name &&
                              <div>
                                <span className="row">Are you sure to delete {variable.name}? </span>
                                <div className="row pull-right">
                                  <button
                                    title="No"
                                    onClick={(e) => { objThis.setState({ editVariable: 0 }); objThis.setState({ confirmDelete: 0 }); }}
                                    className="btn btn-outline-secondary pull-right"
                                  >
                                    No
                                  </button>
                                  <button
                                    title="Yes, delete my variable"
                                    onClick={(e) => objThis.handleDeleteVariable(variable.name, true)}
                                    className="btn btn-primary pull-right confirm-delete"
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </Tab>
            <Tab eventKey="environment" title="Environment">
              {this.getAlertComponent()}
              <TagsInput
                value={this.state.environment || this.props.environment || []}
                onChange={this.handleEnvTags}
                inputProps={{placeholder: "Hit enter to add"}}
                maxTags="10"
                onlyUnique={true}
                validate={(tag) => this.validateEnv(this, tag)}
              />
              <Button id="save-env" disabled={this.state.environment === this.state.savedEnvironment} onClick={this.saveEnvironment} className="mt-3" title="Save environment values!">Save</Button>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  validateEnv(objThis, tag) {
    const res = validateEnvironment(tag);
    if ("" !== res.errorMessage)
      objThis.setState({showMsg: true, alertMsg: res.errorMessage, alertVariant: "danger"});

    return ("" === res.errorMessage);
  }

  saveEnvironment = () => {
    console.log(this.state.environment,'----',this.props.environment);
    this.props.addUpdateEnvironment(this.props.id, this.state.environment);
    this.setState({ savedEnvironment: this.state.environment, showMsg: true, alertMsg: 'Environment updated successfully!', alertVariant: "success" });
  }

  handleEnvTags = (tags) => {
    this.setState({environment: tags})
  }

  async fetchClasses() {
    const { tangoDB } = this.props;
    const data = await TangoAPI.fetchAllClasses(tangoDB);

    this.setState({ tangoClasses: data });
  }

  async fetchDevices(targetClass, forComponent = 'addVariable') {
    const { tangoDB } = this.props;
    if (targetClass !== '') {
      const devices = await TangoAPI.fetchClassAndDevices(tangoDB, targetClass);
      const runningDevices = getRunningDevices(devices[0]?.devices);

      if (forComponent === 'addVariable') {
        this.setState({ tangoDevices: runningDevices });
      }
      if (forComponent === 'editVariable') {
        this.setState({ tangoDevicesEdit: runningDevices });
      }

      return runningDevices;
    }
  }
}

ConfigDashboardModal.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func,
  dashboardVariables: PropTypes.any,
  environment: PropTypes.array,
  filterDashboardVariables: PropTypes.func,
  deleteDashboardVariable: PropTypes.func,
  addDashboardVariable: PropTypes.func,
  updateDashboardVariable: PropTypes.func,
  saveDashboardVariables: PropTypes.func,
  handleCancel: PropTypes.func,
  addUpdateEnvironment: PropTypes.func
};
