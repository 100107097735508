import React, { Component } from "react";
import { parseCss } from "../../components/Inspector/StyleSelector";

import {
  WidgetDefinition,
  NumberInputDefinition,
  ColorInputDefinition,
  SelectInputDefinition,
  StringInputDefinition,
  StyleInputDefinition,
  Widget
} from "../../types";

type Inputs = {
  title: StringInputDefinition;
  bigWidget: NumberInputDefinition;
  smallWidget: NumberInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  borderColor: ColorInputDefinition;
  borderWidth: NumberInputDefinition;
  borderStyle: SelectInputDefinition;
  textSize: NumberInputDefinition;
  fontFamily: SelectInputDefinition;
  layout: SelectInputDefinition;
  alignment: SelectInputDefinition;
  padding: NumberInputDefinition;
  customCss: StyleInputDefinition;
};


type Props = {
  tangoDB: string;
  inputs: Inputs;
  mode: string;
  deleteWidget: (id) => void;
  innerWidgets: Widget[];
  hover: [];
}

interface State { }

class Box extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  DropDiv(props) {

    const style = {
      width: "100%",
      height: "100%",
      padding: props.inputs.padding + "em",
      color: props.inputs.textColor,
      backgroundColor: props.inputs.backgroundColor,
      fontSize: props.inputs.textSize + "em",
      fontFamily: props.inputs.fontFamily,
      borderColor: props.inputs.borderColor,
      borderWidth: props.inputs.borderWidth + "px",
      borderStyle: props.inputs.borderStyle,
      ...parseCss(props.inputs.customCss).data,
      border: props.hover > 0 ? "2px dashed lightskyblue" :
        `${props.inputs.borderWidth}px ${props.inputs.borderStyle} ${props.inputs.borderColor}`,
    }

    return (
      props.mode === "library" ?
        <div style={{ marginTop: "5px", width: "100%", height: "80px", border: "2px dashed gray", borderRadius: "5%" }} />
        : <div style={style}>
          {props.inputs.title}
          {props.innerWidgets && props.innerWidgets.map((innerWidget, i) => {
            return <div style={{ width: "100%" }} key={i}>
              {innerWidget}
            </div>
          })}
        </div>
    )
  }

  public render() {

    return (
      <this.DropDiv
        mode={this.props.mode}
        hover={this.props.hover ? this.props.hover.length : null}
        inputs={this.props.inputs}
        innerWidgets={this.props.innerWidgets}
      >
      </this.DropDiv>
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "BOX",
  name: "Box",
  defaultWidth: 20,
  defaultHeight: 10,
  inputs: {
    title: {
      type: "string",
      label: "Title",
      default: ""
    },
    bigWidget: {
      label: "Big widgets size (in row or column)",
      type: "number",
      default: 4,
      nonNegative: true
    },
    smallWidget: {
      label: "Small widgets size (in row or column)",
      type: "number",
      default: 1,
      nonNegative: true
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000"
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff"
    },
    borderColor: {
      type: "color",
      label: "Border Color",
      default: ""
    },
    borderWidth: {
      type: "number",
      label: "Border Width",
      default: 0
    },
    borderStyle: {
      type: "select",
      label: "Border Type",
      default: "solid",
      options: [
        {
          name: "No Border",
          value: "none"
        },
        {
          name: "Solid Border",
          value: "solid"
        },
        {
          name: "Dashed Border",
          value: "dashed"
        },
        {
          name: "Dotted Border",
          value: "dotted"
        },
        {
          name: "Ridge Border",
          value: "ridge"
        },
        {
          name: "Grove Border",
          value: "groove"
        }
      ]
    },
    textSize: {
      label: "Text Size (in units)",
      type: "number",
      default: 1,
      nonNegative: true
    },
    fontFamily: {
      type: "select",
      default: "Helvetica",
      label: "Font Type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica"
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new"
        }
      ]
    },
    layout: {
      type: "select",
      default: "vertical",
      label: "Layout",
      options: [
        {
          name: "Vertical",
          value: "vertical"
        },
        {
          name: "Horizontal",
          value: "horizontal"
        },
      ]
    },
    alignment: {
      type: "select",
      default: "Center",
      label: "Alignment",
      options: [
        {
          name: "Left",
          value: "Left"
        },
        {
          name: "Center",
          value: "Center"
        },
        {
          name: "Right",
          value: "Right"
        }
      ]
    },
    padding: {
      type: "number",
      label: "Padding",
      default: 0,
      nonNegative: true
    },
    customCss: {
      type: "style",
      default: "",
      label: "Custom Css (Advanced)"
    }
  }
};

const BoxExport = { definition, component: Box };
export default BoxExport;