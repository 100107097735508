import { take, fork, put, call, select, race, delay } from "redux-saga/effects";
import * as API from "../../../dashboard/dashboardRepo";
import {
    dashboardsLoaded,
    dashboardExported,
    dashboardRenamed,
    dashboardDeleted,
    dashboardCloned,
    dashboardLoaded,
    dashboardSaved,
    showNotification,
    hideNotification,
    dashboardShared,
    saveDashboard as saveDashboardAction,
    dashboardUpdated,
    allDashboardsExported
} from "../actions/actionCreators";
import {
    LOGIN_SUCCESS
} from "../../user/state/actionTypes";
import {
    resolveWidgetCompatibility,
} from "../reducers/selectedDashboard/lib";
import {
    RENAME_DASHBOARD,
    DELETE_DASHBOARD,
    CLONE_DASHBOARD,
    SHARE_DASHBOARD,
    LOAD_DASHBOARD,
    EXPORT_DASHBOARD,
    DASHBOARD_RENAMED,
    DASHBOARD_DELETED,
    DASHBOARD_SHARED,
    DASHBOARD_CLONED,
    SAVE_DASHBOARD,
    DASHBOARD_SAVED,
    DASHBOARD_CREATED,
    LOAD_DASHBOARDS,
    SHOW_NOTIFICATION,
    DASHBOARD_EXPORTED,
    IMPORT_DASHBOARD,
    UPDATE_DASHBOARD,
    DASHBOARD_UPDATED,
    EXPORT_ALL_DASHBOARDS,
} from "../actions/actionTypes";
import {
    getWidgets,
    getDashboards,
} from "../selectors";
import { editWidget } from "./editWidget";
import { NotificationLevel } from "../../notifications/notifications";
import { getDeviceNames } from "../selectors/deviceList";
import tangoAPIExport from "../../api/tangoAPI";
import { extractFullNamesFromWidgets } from "../../../dashboard/runtime/extraction";
import { createDeviceWithTangoDBFullPath, getTangoDBFilteredDevices } from "../../../dashboard/runtime/utils";
import { fetchDeviceNamesSuccess } from "../actions/tango";

export default function* dashboards() {
    yield fork(loadDashboards);
    yield fork(renameDashboard);
    yield fork(deleteDashboard);
    yield fork(cloneDashboard);
    yield fork(loadDashboardSaga);
    yield fork(exportDashboardSaga);
    yield fork(importDashboardSaga);
    yield fork(exportAllDashboardsSaga);
    yield fork(saveDashboard);
    yield fork(notifyOnSave);
    yield fork(notifyOnExport);
    yield fork(notifyOnClone);
    yield fork(notifyOnDelete);
    yield fork(notifyOnShare);
    yield fork(hideNotificationAfterDelay);
    yield fork(shareDashboard);
    yield fork(editWidget);
    yield fork(updateDashboard);
}


export function* loadDashboards() {
    while (true) {
        const payload = yield take([
            LOAD_DASHBOARDS,
            LOGIN_SUCCESS,
            DASHBOARD_RENAMED,
            DASHBOARD_DELETED,
            DASHBOARD_CLONED,
            DASHBOARD_SAVED,
            DASHBOARD_UPDATED,
            DASHBOARD_SHARED
        ]);

        //in the case of DASHBOARD_SAVED, only load the dashboard from the db if it was created.
        //Loading the dashboard on every save becomes very sluggish, e.g. when trying to type text
        //in a widget label
        if (payload.type !== DASHBOARD_SAVED || payload.created) {
            try {
                const result = yield call(API.loadUserDashboards);

                yield put(dashboardsLoaded(result));
            } catch (exception) {
                yield put(
                    showNotification(NotificationLevel.ERROR, LOAD_DASHBOARD, "Dashboard not loaded")
                );
            }
        }
    }
}

export function* shareDashboard() {
    while (true) {
        const { id, group, groupWriteAccess } = yield take(SHARE_DASHBOARD);
        yield call(API.shareDashboard, id, group, groupWriteAccess);
        yield put(dashboardShared(id, group, groupWriteAccess));
    }
}

export function* renameDashboard() {
    while (true) {
        const { id, name, environment } = yield take(RENAME_DASHBOARD);
        if (id === "") {
            const widgets = yield select(getWidgets);
            yield put(saveDashboardAction(id, name, widgets));
        } else {
            try {
                const dashboards = yield select(getDashboards);
                const existingNames = dashboards.map(d => d.name);
                console.log("environment: ",environment);
                if(existingNames.includes(name)){      
                    throw new Error(`Dashboard name: [${name}] already exists`)
                }
                else {
                yield call(API.renameDashboard, id, name, environment);
                yield put(dashboardRenamed(id, name));
                }
            } catch(error) { 
                console.log(error)
                yield put(
                    showNotification(
                        NotificationLevel.ERROR,
                        SAVE_DASHBOARD,
                        "You cannot edit this dashboard. " + error
                    )
                );
            }
        }
    }
}

export function* deleteDashboard() {
    while (true) {
        const { id } = yield take(DELETE_DASHBOARD);
        const result = yield call(API.deleteDashboard, id);
        yield put(dashboardDeleted(result.id));
    }
}

export function* cloneDashboard() {
    while (true) {
        const { id } = yield take(CLONE_DASHBOARD);
        const { id: newId } = yield call(API.cloneDashboard, id);
        yield put(dashboardCloned(newId));
    }
}

export function* loadDashboardSaga() {
    while (true) {
        const payload = yield take([
            LOAD_DASHBOARD,
            DASHBOARD_CLONED,
            DASHBOARD_SAVED,
            DASHBOARD_UPDATED,
        ]);

        let { id, type, deviceList } = payload;
        //In the case of dashboard_saved, only load dashboard if the dashboard was just created (we need the ID)
        let created = false;
        if (type === DASHBOARD_SAVED) {
            created = payload.created;
        }
        if (!(type === DASHBOARD_SAVED && !created)) {
            try {
                const {
                    widgets,
                    name,
                    user,
                    environment,
                    insertTime,
                    updateTime,
                    group,
                    groupWriteAccess,
                    lastUpdatedBy,
                    variables
                } = yield call(API.load, id);


                // Fetching device list bcz devices still loading hence using below approach
                // TODO: if we can memoize this
                if ((!deviceList) || deviceList.length === 0) {
                    let fullNames = extractFullNamesFromWidgets(widgets);
                    const devicesByTangoDB = getTangoDBFilteredDevices(fullNames);
                    const currentTangoDB = API.getTangoDB();
                    if (!Object.keys(devicesByTangoDB).includes(currentTangoDB)) {
                        devicesByTangoDB[currentTangoDB] = ""
                    }
                    deviceList = []
                    for (const [tangoDB, ] of Object.entries(devicesByTangoDB)) {
                        const newDevs = yield call(tangoAPIExport.fetchDeviceNames, tangoDB);
                        const devsWithTangoDB = newDevs.map((name) => createDeviceWithTangoDBFullPath(tangoDB, name));
                        yield put(fetchDeviceNamesSuccess(devsWithTangoDB))
                        deviceList = [...deviceList, ...devsWithTangoDB];
                    }
                }

                const { widgets: newWidgets } = resolveWidgetCompatibility(
                    widgets
                );

                yield put(
                    dashboardLoaded(
                        {
                            id,
                            name,
                            user,
                            environment,
                            insertTime,
                            updateTime,
                            group,
                            groupWriteAccess,
                            lastUpdatedBy,
                            variables,
                        },
                        newWidgets,
                        deviceList
                    )
                );
            } catch (exception) {
                yield put(
                    showNotification(NotificationLevel.WARNING, LOAD_DASHBOARD, "Dashboard not found")
                );
            }
        }
    }
}

export function* exportDashboardSaga() {
    while (true) {
        //Generator only executes is type is EXPORT_DASHBOARD
        const payload = yield take(EXPORT_DASHBOARD);
        const { id } = payload;
        try {
            const result = yield call(API.exportDash, id);
            const {
                widgets,
                name,
                user,
                insertTime,
                updateTime,
                group,
                groupWriteAccess,
                lastUpdatedBy,
                variables
            } = result;

            const { widgets: newWidgets } = resolveWidgetCompatibility(
                widgets
            );

            yield put(
                dashboardExported(
                    {
                        id,
                        name,
                        user,
                        insertTime,
                        updateTime,
                        group,
                        groupWriteAccess,
                        lastUpdatedBy,
                        variables
                    },
                    newWidgets
                )
            );

        } catch (exception) {
            yield put(
                showNotification(NotificationLevel.ERROR, EXPORT_DASHBOARD, "Dashboard not exported: " + exception)
            );
        }
    }
}

export function* importDashboardSaga() {
    while (true) {
         //Generator only executes is type is EXPORT_DASHBOARD
         const payload = yield take(IMPORT_DASHBOARD);
         const { content } = payload;
        try {
            const dashboards = yield select(getDashboards)
            const deviceList = yield select(getDeviceNames); 
            if(Array.isArray(content)){
                for (const file of content) {
                    const result = yield call(API.importDash, file, dashboards, deviceList);
                    if (result.created !== undefined) {
                        if (result.warning.length > 0) {
                            yield put(showNotification(NotificationLevel.WARNING, IMPORT_DASHBOARD, result.warning));
                        }
                        const { id: newId, created, name } = result;                            
                        yield put(dashboardSaved(newId, created, name));                                                
                    } else {
                        yield put(showNotification(NotificationLevel.ERROR, IMPORT_DASHBOARD, "Dashboard not imported: " + (result.error || result)));
                    }
                }
            }
            else {
                const result = yield call(API.importDash, content, dashboards, deviceList);
                    if (result.created !== undefined) {
                        if (result.warning.length > 0) {
                            yield put(showNotification(NotificationLevel.WARNING, IMPORT_DASHBOARD, result.warning));
                        }
                        const { id: newId, created, name } = result;                            
                        yield put(dashboardSaved(newId, created, name));                                                
                    } else {
                        yield put(showNotification(NotificationLevel.ERROR, IMPORT_DASHBOARD, "Dashboard not imported: " + (result.error || result)));
                    }
            }
            
        } catch (exception) {
            yield put(
                showNotification(NotificationLevel.ERROR, IMPORT_DASHBOARD, "Dashboard not imported: " + exception.error)
            );
        }
    }
}


export function* exportAllDashboardsSaga() {
    while (true) {
        yield take(EXPORT_ALL_DASHBOARDS)
        try {
            const dashboards = yield select(getDashboards);
            const dashboardIds = dashboards.map(dashboard => dashboard.id);
            const result = yield call(API.exportAllDash, dashboardIds);
            yield put(allDashboardsExported(result));
        } catch (exception) {
            yield put(
                showNotification(NotificationLevel.ERROR, EXPORT_ALL_DASHBOARDS, "Dashboards not exported: " + exception.error)
            );
        }
    }
}

export function* notifyOnExport() {
    while (true) {
        const { dashboard } = yield take(DASHBOARD_EXPORTED);
        if (dashboard.id) {
            yield put(
                showNotification(NotificationLevel.INFO, DASHBOARD_EXPORTED, "Dashboard exported")
            );
        }
    }
}

export function* saveDashboard() {
    while (true) {
        const { id, widgets, name, variables, environment } = yield take(SAVE_DASHBOARD);      
        const dashboards = yield select(getDashboards);
        const existingNames = dashboards.map(d => d.name);
        let count = dashboards.length;
        try {
            let updatedName = `${name}`
            while(existingNames.includes(updatedName)){      
                updatedName = `${name} ${++count}`;            
            }

            const { id: newId, created } = yield call(
                API.save,
                id,
                widgets,
                updatedName || "",
                variables,
                environment
            );
            yield put(dashboardSaved(newId, created, updatedName, variables, environment));
        } catch (exception) {
            console.log('Error response body:', exception);
            yield put(
                showNotification(
                    NotificationLevel.ERROR,
                    SAVE_DASHBOARD,
                    "You cannot edit this dashboard. " + (exception.error || exception.message || exception)
                )
            );
        }
    }
}

/**
 * Update specific fields on dashboard
 *
 */
export function* updateDashboard() {
    while (true) {
        const { id, fields } = yield take(UPDATE_DASHBOARD);
        try {
            yield call(
                API.updateDashboard,
                id,
                fields
            );
            const deviceList = yield select(getDeviceNames);
            yield put(dashboardUpdated(id, fields, deviceList));

        } catch (e) {
            yield put(
                showNotification(
                    NotificationLevel.ERROR,
                    UPDATE_DASHBOARD,
                    "Failed to save dashboard: " + e.message
                )
            );
        }
    }
}

export function* notifyOnSave() {
    while (true) {
        const { created } = yield take(DASHBOARD_SAVED);
        if (created) {
            yield put(
                showNotification(NotificationLevel.INFO, DASHBOARD_CREATED, "Dashboard created")
            );
        }
    }
}

export function* notifyOnShare() {
    while (true) {
        const { group, groupWriteAccess } = yield take(DASHBOARD_SHARED);
        const msg = group
            ? "Dashboard shared with " +
            group +
            (groupWriteAccess ? " (write access)" : "")
            : "Dashboard unshared";
        yield put(showNotification(NotificationLevel.INFO, DASHBOARD_SHARED, msg));
    }
}

export function* notifyOnClone() {
    while (true) {
        yield take(DASHBOARD_CLONED);
        yield put(showNotification(NotificationLevel.INFO, DASHBOARD_CLONED, "Dashboard cloned"));
    }
}

export function* notifyOnDelete() {
    yield take(DASHBOARD_DELETED);
    yield put(showNotification(NotificationLevel.INFO, DASHBOARD_DELETED, "Dashboard deleted"));
}

// Can possibly be simplified using debounce()
export function* hideNotificationAfterDelay() {
    while (true) {
        const { notification } = yield take(SHOW_NOTIFICATION);

        while (true) {
            const { timePassed } = yield race({
                newNotification: take(SHOW_NOTIFICATION),
                timePassed: delay(notification.duration)
            });

            if (timePassed) {
                break;
            }
        }

        yield put(hideNotification());
    }
}
