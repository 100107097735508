import React, { Component, CSSProperties } from "react";
import { WidgetProps } from "../types";

import {
  WidgetDefinition,
  BooleanInputDefinition,
  NumberInputDefinition,
  StringInputDefinition,
  AttributeInputDefinition,
  ColorInputDefinition,
  SelectInputDefinition,
  StyleInputDefinition,
} from "../../types";

import "../styles/AttributeDisplay.styles.css";
import { parseCss } from "../../components/Inspector/StyleSelector";
import AttributeValues from "./AttributeValues";
import { showHideTangoDBName } from "../../DBHelper";

type Inputs = {
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
  showUnit: BooleanInputDefinition;
  scientificNotation: BooleanInputDefinition;
  precision: NumberInputDefinition;
  format: StringInputDefinition;
  showEnumLabels: BooleanInputDefinition;
  jsonCollapsed: BooleanInputDefinition;
  showAttrQuality: BooleanInputDefinition;
  alignTextCenter: BooleanInputDefinition;
  alignValueRight: BooleanInputDefinition;
  attribute: AttributeInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

class AttributeReadOnly extends Component<Props> {
  public render() {
    const { device, name, label } = this.deviceAndAttribute();
    const { mode } = this.props;
    const {
      showDevice,
      showTangoDB,
      showAttribute,
      showUnit,
      showEnumLabels,
      jsonCollapsed,
      showAttrQuality,
      alignTextCenter,
      alignValueRight,
      attribute,
      textColor,
      size,
      font,
      precision,
      format,
      scientificNotation,
      backgroundColor,
    } = this.props.inputs;

    let display = "";
    if (showAttribute === "Label") display = label;
    else if (showAttribute === "Name") display = name;

    const widgetCss = this.props.inputs.widgetCss
      ? parseCss(this.props.inputs.widgetCss).data
      : {};
    const style: CSSProperties = {
      display: "flex",
      whiteSpace: "pre-wrap",
      backgroundColor,
      color: textColor,
      fontSize: size + "em",
      ...widgetCss,
    };
    if (font) style["fontFamily"] = font;
    const alignmentText = alignTextCenter
      ? " justify-content-center"
      : " justify-content-left";
    if (mode === "library") {
      return (
        <div id="AttributeDisplay" className="h-100 justify-content-left">
          Device/Attr: 100
        </div>
      );
    }

    const displayDevice = showHideTangoDBName(showDevice, showTangoDB, device);
    return (
      <div
        id="AttributeDisplay"
        style={style}
        className={"h-100" + alignmentText}
      >
        {displayDevice}
        {showDevice && showAttribute && "/"}
        {display}
        {(showDevice || showAttribute !== "None") && ": "}
        <AttributeValues
          attributeName={attribute?.attribute}
          deviceName={device}
          dataType={attribute?.dataType}
          mode={this.props.mode}
          showAttrQuality={showAttrQuality}
          precision={precision}
          format={format}
          showUnit={showUnit}
          scientificNotation={scientificNotation}
          showEnumLabels={showEnumLabels}
          jsonCollapsed={jsonCollapsed}
          enumlabels={attribute?.enumlabels}
          unit={attribute?.unit}
          minAlarm={attribute?.minAlarm}
          maxAlarm={attribute?.maxAlarm}
          alignValueRight={alignValueRight}
        />
      </div>
    );
  }

  private deviceAndAttribute(): {
    device: string;
    name: string;
    label: string;
  } {
    const { attribute } = this.props.inputs;
    const device = attribute.device || "device";
    const name = attribute.attribute || "attributeName";
    const label = attribute.label || "attributeLabel";
    return { device, name, label };
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTE_DISPLAY",
  name: "Attribute Display",
  defaultWidth: 10,
  defaultHeight: 2,
  inputs: {
    attribute: {
      type: "attribute",
      label: "",
      dataFormat: "scalar",
      required: true,
    },
    precision: {
      type: "number",
      label: "Precision",
      default: 2,
    },
    format: {
      type: "string",
      label: "Format",
      default: "",
    },
    showUnit: {
      type: "boolean",
      label: "Show unit",
      default: true,
    },
    showDevice: {
      type: "boolean",
      label: "Device Name",
      default: false,
    },
    jsonCollapsed: {
      type: "boolean",
      label: "Json tree collapsed",
      default: true,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
        {
          name: "None",
          value: "None",
        },
      ],
    },
    alignTextCenter: {
      type: "boolean",
      label: "Align text on center",
      default: false,
    },
    alignValueRight: {
      type: "boolean",
      label: "Align value on right",
      default: true,
    },
    scientificNotation: {
      type: "boolean",
      label: "Scientific Notation",
      default: false,
    },
    showEnumLabels: {
      type: "boolean",
      label: "Show Enum Labels",
      default: false,
    },
    showAttrQuality: {
      type: "boolean",
      label: "Show Attr Quality",
      default: false,
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
      ],
    },
    widgetCss: {
      type: "style",
      label: "Custom CSS",
      default: "",
    },
  },
};

const AttributeReadOnlyExport = { component: AttributeReadOnly, definition };
export default AttributeReadOnlyExport;
