import { SelectedSynoptic } from "../../../types";

import {
  SYNOPTIC_LOADED,
  SYNOPTIC_RENAMED,
  SYNOPTIC_DELETED,
  SYNOPTIC_SHARED,
  SYNOPTIC_EDITED, //this is a catch-all for synoptic editing (add/edit/delete input/widget etc.), which as initially handled in sagas
} from "../../actionTypes";

import { SynopticAction } from "../../actions";

export interface SelectedSynopticState extends SelectedSynoptic {
  selectedId: string | null;
}

const initialState = {
  svg: "",
  selectedId: null,
  selectedIds: [],
  id: "",
  name: "Untitled synoptic",
  user: "",
  group: "",
  groupWriteAccess: false,
  lastUpdatedBy: "",
  insertTime: null,
  updateTime: null,
  history: {
    undoActions: [],
    redoActions: [],
    undoIndex: 0,
    redoIndex: 0,
    undoLength: 0,
    redoLength: 0,
  },
  variables: [],
};

export default function canvases(
  state: SelectedSynopticState = initialState,
  action: SynopticAction
): SelectedSynopticState {
  switch (action.type) {
    case SYNOPTIC_EDITED: {
      const { synoptic } = action;
      return { ...synoptic };
    }
    case SYNOPTIC_SHARED: {
      const { id, group, groupWriteAccess } = action;
      if (id === state.id) {
        return {
          ...state,
          group,
          groupWriteAccess,
        };
      } else {
        return state;
      }
    }
    case SYNOPTIC_LOADED: {
      const { synoptic } = action;
      const {
        id,
        name,
        svg,
        user,
        insertTime,
        updateTime,
        group,
        groupWriteAccess,
        lastUpdatedBy,
      } = synoptic;

      return {
        ...state,
        id,
        name,
        svg,
        user,
        insertTime,
        updateTime,
        group,
        groupWriteAccess,
        lastUpdatedBy,
        selectedIds: [],
      };
    }
    case SYNOPTIC_RENAMED: {
      const { name } = action;
      return { ...state, name };
    }
    case SYNOPTIC_DELETED: {
      const { id } = action;
      if (id === state.id) {
        // Clear the selectedSynoptic state if we deleted the selected synoptic
        return {
          ...state,
          id: "",
          name: "",
          selectedIds: [],
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
