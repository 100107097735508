import { request as graphqlRequest } from "graphql-request";
import MongoAPI from "./mongoAPI";
import {
  FETCH_ATTRIBUTES,
  FETCH_COMMANDS,
  FETCH_DEVICE_NAMES,
  FETCH_DEVICE_STATE,
  EXECUTE_COMMAND,
  SET_DEVICE_ATTRIBUTE,
  SET_DEVICE_PROPERTY,
  DELETE_DEVICE_PROPERTY,
  FETCH_ATTRIBUTE_METADATA,
  FETCH_ATTRIBUTES_VALUES,
  FETCH_DEVICE_METADATA,
  FETCH_DEVICE,
  FETCH_DATABASE_INFO,
  FETCH_ALL_CLASSES_WITH_DEVICES,
  FETCH_CLASS_WITH_DEVICES,
  FETCH_ALL_CLASSES
} from "./graphqlQuery";

import {
  FetchAttributes,
  FetchCommands,
  FetchDeviceNames
} from "./graphqlQuery";

import { Variables } from "graphql-request/src/types"

import sanitize_device_name from "../../shared/utils/SanitizeDeviceName";
import { createDeviceWithTangoDBFullPath, getDeviceFromPath } from "../../dashboard/runtime/utils";
import { splitFullPath } from "../../dashboard/DBHelper"


const config = window['config'];

function request<T = any>(
  tangoDB: string,
  query: string,
  args?: Variables
): Promise<T> {
  const url = config.basename + `/${tangoDB}/db`;
  return graphqlRequest(url, query, args);
}

const tangoAPIExport = {
  async fetchDeviceAttributes(tangoDB: string, device: string[]) {
    try {
      const data = await request<FetchAttributes>(tangoDB, FETCH_ATTRIBUTES, {
        device
      });
      const { attributes } = data.device;
      if (attributes != null) {
        return attributes;
      } else {
        // Some kind of error reporting could go here. This should only happen when the attributes resolver in the backend fails for some unexpected reason. For now, just return an empty list.
        return [];
      }
    } catch (err) {
      return [];
    }
  },

  async fetchDatabaseInfo(tangoDB: string) {
    try {
      const data = await request(tangoDB, FETCH_DATABASE_INFO);
      return data.info;
    } catch (err) {
      return String(err);
    }
  },

  async fetchCommands(tangoDB: string, device: string) {
    try {
      const data = await request<FetchCommands>(tangoDB, FETCH_COMMANDS, {
        device
      });
      return data.device.commands;
    } catch (err) {
      return [];
    }
  },

  async fetchDeviceNames(tangoDB: string) {
    try {
      const data = await request<FetchDeviceNames>(tangoDB, FETCH_DEVICE_NAMES);
      return data.devices.map(({ name }) => name.toLowerCase());
    } catch (err) {
      return [];
    }
  },

  async executeCommand(
    tangoDB: string,
    device: string,
    command: string,
    argin?: any
  ) {
    try {
      const args =
        argin !== "" ? { device, command, argin } : { device, command };
      const data = await request(tangoDB, EXECUTE_COMMAND, args);
      const { ok, output, message } = data.executeCommand;
      if (ok) {
        const timestamp = new Date();
        MongoAPI.saveUserAction({
          actionType: "ExcuteCommandUserAction",
          timestamp,
          tangoDB,
          device,
          name: command,
          argin
        });
      }
      return { ok, output, message };
    } catch (err) {
      return { ok: false, output: '', message: err };
    }
  },

  async setDeviceAttribute(
    tangoDB: string,
    device: string,
    name: string,
    value: any
  ) {
    try {
      const deviceName = getDeviceFromPath(device);
      const args = { device: deviceName, attribute: name, value };
      const data = await request(tangoDB, SET_DEVICE_ATTRIBUTE, args);
      const { ok, valueBefore, attribute } = data.setAttributeValue;
      if (ok) {
        const timestamp = new Date();
        MongoAPI.saveUserAction({
          actionType: "SetAttributeValueUserAction",
          timestamp,
          tangoDB,
          device,
          name: attribute.name,
          valueBefore,
          valueAfter: attribute.value,
          value
        });
      }
      return { ok, attribute };
    } catch (err) {
      return { ok: false, attribute: null };
    }
  },

  async setDeviceProperty(
    tangoDB: string,
    device: string,
    name: string,
    value: any
  ) {
    const args = { device, name, value };
    const data = await request(tangoDB, SET_DEVICE_PROPERTY, args);
    const { ok } = data.putDeviceProperty;
    if (ok) {
      const timestamp = new Date();
      MongoAPI.saveUserAction({
        actionType: "PutDevicePropertyUserAction",
        timestamp,
        tangoDB,
        device,
        name,
        value
      });
    }
    return data.putDeviceProperty.ok;
  },

  async deleteDeviceProperty(tangoDB: string, device: string, name: string) {
    const args = { device, name };
    const data = await request(tangoDB, DELETE_DEVICE_PROPERTY, args);
    const { ok } = data.deleteDeviceProperty;
    if (ok) {
      const timestamp = new Date();
      MongoAPI.saveUserAction({
        actionType: "DeleteDevicePropertyUserAction",
        timestamp,
        tangoDB,
        device,
        name
      });
    }
    return ok;
  },
  async fetchDevice(tangoDB: string, name: string) {
    let [tangoDBActual, deviceName] = splitFullPath(name);
    tangoDBActual = tangoDBActual === "" ? tangoDB : tangoDBActual;
    const args = { name: deviceName };

    let device: any = null;
    let errors = [];

    try {
      const data = await request(tangoDBActual, FETCH_DEVICE, args);
      device = data.device;
      if (device === null) {
        return null;
      }
      return { ...device, name: createDeviceWithTangoDBFullPath(tangoDBActual, device.name.toLowerCase()), errors };
    } catch (err: any) {
      // The structure of errors is currently not ideal and will probably undergo change. Update this logic accordingly.
      errors = err.response.errors[0];
      device = err.response.data.device;
      return null;
    }
  },

  async fetchAttributeMetadata(tangoDB: string, fullNames: string[]) {
    try {
      fullNames.forEach(fullName => {
        fullName = fullName.toLowerCase();
      });
      const data = await request(tangoDB, FETCH_ATTRIBUTE_METADATA, {
        fullNames
      });
      const result = {};

      for (const attribute of data.attributes) {
        const {
          device,
          name,
          dataformat,
          datatype,
          unit,
          enumLabels,
          label,
          maxalarm,
          minalarm,
          minvalue,
          maxvalue
        } = attribute;

        const fullName = device + "/" + name;
        const dataFormat = dataformat.toLowerCase();
        const dataType = datatype;
        const enumlabels = enumLabels;
        const fullPath = createDeviceWithTangoDBFullPath(tangoDB, fullName);

        result[fullPath] = {
          dataFormat,
          dataType,
          unit,
          enumlabels,
          label,
          maxAlarm: maxalarm,
          minAlarm: minalarm,
          minValue: minvalue,
          maxValue: maxvalue
        };
      }

      return result;
    } catch (error: any) {
      const errors: string[] = [];
      error.response.errors.map(error => errors.push(error.desc));
      throw errors.join(" ");
    }
  },

  async fetchDeviceState(tangoDB: string, name: string) {
    try {
      const args = { name };
      const data = await request(tangoDB, FETCH_DEVICE_STATE, args);
      return data.device.state;
    } catch (err) {
      return null;
    }
  },

  async fetchAttributesValues(
    tangoDB: string,
    fullNames: string[]
  ): Promise<
    Array<{
      name: string;
      device: string;
      value: any;
      writevalue: any;
      quality: string;
      timestamp: number;
    }>
  > {
    try {
      const data = await request(tangoDB, FETCH_ATTRIBUTES_VALUES, {
        fullNames
      });
      return data.attributes;
    } catch (err) {
      return [];
    }
  },

  async fetchAllClassesAndDevices(tangoDB: string) {
    try {
      const data = await request(tangoDB, FETCH_ALL_CLASSES_WITH_DEVICES);
      return data.classes;
    } catch (err) {
      return [];
    }
  },

  async fetchClassAndDevices(tangoDB: string, name: string) {
    try {
      const args = { name };
      const data = await request(tangoDB, FETCH_CLASS_WITH_DEVICES, args);
      return data.classes;
    } catch (err) {
      return [];
    }
  },

  async fetchAllClasses(tangoDB: string) {
    try {
      const data = await request(tangoDB, FETCH_ALL_CLASSES);
      return data.classes;
    } catch (err) {
      return [];
    }
  },

  async fetchSelectedClassesAndDevices(tangoDB: string, variableNames) {
    if (variableNames?.length === 0) return [];
    let data: any;
    try {
      let query = "{";
      for (const variable of variableNames) {
        query += `
            ${variable.class}: classes(pattern: "${variable.class}") {
              name
              devices {
                name,
                exported,
                connected
              }
            }
          `;
      }
      query += "}";
      data = await request(tangoDB, query);
    } catch (err) {
      return [];
    }

    return data.classes;
  },

  async fetchDevicesProperty(tangoDB: string, variableNames) {
    let data: any;
    try {
      let query = "{";
      for (const variable of variableNames) {
        query += `
            ${sanitize_device_name(variable.device)}: device(name: "${variable.device
          }") {
              name,
              exported,
              connected
            }
          `;
      }
      query += "}";
      data = await request(tangoDB, query);
    } catch (err) {
      return [];
    }

    return Object.values(data);
  },

  async fetchDeviceMetadata(tangoDB: string, deviceNames: string[]) {
    const result = {};

    for (const deviceName of deviceNames) {
      let data: any;
      try {
        data = await request(tangoDB, FETCH_DEVICE_METADATA, { deviceName });
      } catch (err) {
        return null;
      }
      if (data.device) {
        const { alias } = data.device;
        result[deviceName] = { alias };
      }
    }

    return result;
  },
  async fetchDevicesMetadata(tangoDB: string, deviceNames: string[]) {
    let data: any;
    try {
      let query = "{";
      for (const device of deviceNames) {
        query += `
            ${sanitize_device_name(device)}: device(name: "${device}") {
              name, alias
            }
          `;
      }
      query += "}";
      data = await request(tangoDB, query);
    } catch (err) {
      return null;
    }

    return Object.values(data);
  }

};

export default tangoAPIExport;