import {
  SAVE_STATE_URL,
  SAVE_STATE_URL_SUCCESS,
  SAVE_STATE_URL_FAILED,
  LOAD_STATE_URL,
  LOAD_STATE_URL_SUCCESS,
  LOAD_STATE_URL_FAILED,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  PRELOAD_USER_SUCCESS,
  PRELOAD_USER_FAILED,
  LOGOUT,
  OPEN_LOGIN_DIALOG,
  CLOSE_LOGIN_DIALOG,
  SAVE_NOTIFICATIONS,
  SAVE_NOTIFICATIONS_SUCCESS,
  SAVE_NOTIFICATIONS_FAILED,
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_FAILED,
  LOAD_NOTIFICATIONS_SUCCESS,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_FAILED,
  CLEAR_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_FAILED,
  SET_NOTIFICATIONS_SUCCESS,
} from "./actionTypes";

import {
  ISaveStateUrlAction,
  ISaveStateUrlSuccessAction,
  ISaveStateUrlFailedAction,
  ILoadStateUrlAction,
  ILoadStateUrlSuccessAction,
  ILoadStateUrlFailedAction,
  ILoginAction,
  ILoginSuccessAction,
  ILoginFailedAction,
  ILogoutAction,
  ILogoutSuccessAction,
  IPreloadUserSuccessAction,
  IPreloadUserFailedAction,
  IOpenLoginDialogAction,
  ICloseLoginDialogAction,
  ISaveNotificationAction,
  ISaveNotificationFailedAction,
  ISaveNotificationSuccessAction,
  ILoadNotificationAction,
  ILoadNotificationFailedAction,
  ILoadNotificationSuccessAction,
  IClearNotificationAction,
  IClearNotificationFailedAction,
  IClearNotificationSuccessAction,
  ISetNotificationAction,
  ISetNotificationFailedAction,
  ISetNotificationSuccessAction,
} from "./actions";

import { Notification } from "../../notifications/notifications";
import { StateUrls } from "../../ui/navbar/Navbar";

export interface IUserState {
  stateUrls: StateUrls;
  username?: string;
  userGroups?: string[];
  awaitingResponse: boolean;
  awaitingNotification: boolean;
  loginFailed: boolean;
  notificationFailed: boolean;
  loginDialogVisible: boolean;
  notifications: Notification[];
  tangoDBName: string;
}

type UserAction =
  | ISaveStateUrlAction
  | ISaveStateUrlSuccessAction
  | ISaveStateUrlFailedAction
  | ILoadStateUrlAction
  | ILoadStateUrlSuccessAction
  | ILoadStateUrlFailedAction
  | ILoginAction
  | ILoginSuccessAction
  | ILoginFailedAction
  | ILogoutAction
  | ILogoutSuccessAction
  | IPreloadUserSuccessAction
  | IPreloadUserFailedAction
  | IOpenLoginDialogAction
  | ICloseLoginDialogAction
  | ISaveNotificationAction
  | ISaveNotificationFailedAction
  | ISaveNotificationSuccessAction
  | ILoadNotificationAction
  | ILoadNotificationFailedAction
  | ILoadNotificationSuccessAction
  | IClearNotificationAction
  | IClearNotificationFailedAction
  | IClearNotificationSuccessAction
  | ISetNotificationAction
  | ISetNotificationFailedAction
  | ISetNotificationSuccessAction

const initialState = {
  stateUrls: {
    deviceUrl: '',
    dashboardUrl: '',
    synopticUrl: ''
  },
  awaitingResponse: false,
  loginFailed: false,
  awaitingNotification: false,
  notificationFailed: false,
  loginDialogVisible: false,
  notifications: [],
  tangoDBName: ''
};

export default function user(
  state: IUserState = initialState,
  action: UserAction
) {
  switch (action.type) {
    case SAVE_STATE_URL:
      return { ...state };
    case SAVE_STATE_URL_SUCCESS:
      return {
        ...state,
        stateUrls: action.stateUrls
      };
    case SAVE_STATE_URL_FAILED:
      return {...state };
    case LOAD_STATE_URL:
      return { ...state };
    case LOAD_STATE_URL_SUCCESS:
      return {
        ...state,
        stateUrls: action.stateUrls
      };
    case LOAD_STATE_URL_FAILED:
      return {...state };
    case LOGIN:
      return { ...state, awaitingResponse: true };
    case LOGIN_FAILED:
      return { ...state, awaitingResponse: false, loginFailed: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        username: action.username,
        userGroups: action.userGroups,
        loginDialogVisible: false,
        awaitingResponse: false,
      };
    case PRELOAD_USER_SUCCESS:
      return { ...state, username: action.username, userGroups: action.userGroups, awaitingResponse: false };
    case PRELOAD_USER_FAILED:
      return { ...state, awaitingResponse: false };
    case LOGOUT:
      return { ...state, awaitingResponse: true };
    case LOGOUT_SUCCESS:
      return { ...initialState, awaitingResponse: false };
    case OPEN_LOGIN_DIALOG:
      return { ...initialState, loginDialogVisible: true };
    case CLOSE_LOGIN_DIALOG:
      return { ...initialState, loginDialogVisible: false };
    case SAVE_NOTIFICATIONS:
      return { ...state, awaitingNotification: true };
    case SAVE_NOTIFICATIONS_FAILED:
      return { ...state, awaitingNotification: false, notificationFailed: true };
    case SAVE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        awaitingNotification: false,
        notificationFailed: false
      };
    case LOAD_NOTIFICATIONS:
      return { ...state, awaitingNotification: true };
    case LOAD_NOTIFICATIONS_FAILED:
      return { ...state, awaitingNotification: false, notificationFailed: true };
    case LOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        awaitingNotification: false,
        notificationFailed: false
      };
    case CLEAR_NOTIFICATIONS:
      return { ...state, awaitingNotification: true };
    case CLEAR_NOTIFICATIONS_FAILED:
      return { ...state, awaitingNotification: false, notificationFailed: true };
    case CLEAR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        awaitingNotification: false,
        notificationFailed: false
      };
    case SET_NOTIFICATIONS:
      return { ...state, awaitingNotification: true };
    case SET_NOTIFICATIONS_FAILED:
      return { ...state, awaitingNotification: false, notificationFailed: true };
    case SET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        awaitingNotification: false,
        notificationFailed: false
      };
    default:
      return state;
  }
}
