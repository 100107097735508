import React, { Component, Suspense } from "react";

// In order to avoid importing the entire plotly.js library. Note that this mutates the global PlotlyCore object.
import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import createPlotlyComponent from "react-plotly.js/factory";
import { showHideTangoDBName } from "../../DBHelper";

PlotlyCore.register([PlotlyScatter]);
const Plotly = createPlotlyComponent(PlotlyCore);

export interface PlotParams {
  height: number;
  width: number;
  staticMode?: boolean;
  timeWindow: number;
}

export interface Trace {
  x?: number[];
  y?: number[];
  yLabels?: string[];
  fullName: string;
  axisLocation: string; //"left" | "right";
  yAxisDisplay: string;
  position: number;
}

interface PlotProps {
  traces: Trace[];
  params: PlotParams;
  dataFlow: boolean;
  eventView: boolean;
  mode: string;
  showTangoDB?: boolean | undefined;
}

let rangHistory = [0, 0];

interface State {
  mode: string;
}
export default class Plot extends Component<PlotProps, State> {
  dataHistory = [{}];
  public constructor(props: PlotProps) {
    super(props);

    this.state = {
      mode: "TIME_WINDOW",
    };

    this.dataAndRange = this.dataAndRange.bind(this);
  }

  render() {
    const { traces, params, dataFlow, eventView } = this.props;
    const { staticMode, width, height } = params;
    const { xaxis, subPlots, allY } = this.dataAndRange(
      traces,
      params,
      dataFlow,
      eventView
    );

    const traceSort = traces.slice();
    const traceCheck = traceSort.sort((a, b) => b.position - a.position)[0];
    let size = 0;
    if (traceCheck !== undefined) size = traceCheck.position;
    const layout = {
      xaxis,
      ...allY,
      autosize: true,
      showlegend: this.props.mode === "edit" ? false : true, //the legend has been disabled in edit mode because it generates problems when dropped in a boxwidget with click handler.
      legend: {
        x: 0,
        y: 6,
        traceorder: "normal",
        font: {
          family: "sans-serif",
          size: 12,
          color: "#000",
        },
        bordercolor: "#FFFFFF",
        borderwidth: 2,
      },
      uirevision: true,
      grid: {
        rows: size,
        columns: 1,
        subplots: subPlots,
        roworder: "bottom to top",
      },
    };

    const overriding = {
      width,
      height,
    };

    return (
      <div style={{ overflowY: "hidden" }}>
        <Suspense fallback={null}>
          <Plotly
            onRelayout={(e: any) =>
              e["xaxis.autorange"]
                ? this.setState({ mode: "HISTORY" })
                : this.setState({ mode: "TIME_WINDOW" })
            }
            data={this.dataHistory}
            layout={{ ...layout, ...overriding }}
            config={{ staticPlot: staticMode === true }}
            responsive={true}
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          />
        </Suspense>
      </div>
    );
  }

  dataAndRange(
    traces: Trace[],
    params: PlotParams,
    dataFlow: boolean,
    eventView: boolean,
  ) {
    const { timeWindow } = params;

    let showTangoDB  = this.props.showTangoDB !== undefined ? this.props.showTangoDB : false; 

    const data = traces.map((trace: Trace) => {
      const deviceName = showHideTangoDBName(true, showTangoDB, trace.fullName); 
      return {
        x: trace.x || [null],
        y: trace.y || [null],
        yLabels: trace.yLabels || [null],
        name: deviceName,
        position: trace.position,
        axisLocation: trace.axisLocation,
      };
    });

    //Set the x-axis range to include all data points recieved less than TIME_WINDOW
    //seconds ago, with a minimum time interval of TIME_WINDOW
    let minX = Number.MAX_SAFE_INTEGER;
    let maxX = 0;
    let allY = {};
    data.forEach((plot) => {
      if (plot.x && plot.x[0] && plot.x[0] < minX) {
        minX = plot.x[0];
      }
      if (plot.x && plot.x[0] && plot.x[plot.x.length - 1]) {
        maxX = Math.max(maxX, plot.x[plot.x.length - 1] || 0);
      }
    });
    let range = [minX, maxX];
    if (this.state.mode === "TIME_WINDOW") {
      range = [Math.max(minX, maxX - timeWindow), Math.max(maxX, timeWindow)];
      if (range[0] < 0) range[0] = 0;
    }

    const subPlots = [["xy"]];
    let maxEvents = 0;

    const dataHistory = [{}];
    data.forEach((element, i) => {
      if (maxEvents < element.x.length) maxEvents = element.x.length;
      const traceTmp = {
        x: eventView ? maxEvents : element.x,
        y: element.y,
        yaxis: "y" + element.position,
        mode: "lines+markers",
        name: element.name,
        line: { shape: "hv" },
        type: "scatter",
      };
      if (dataFlow) dataHistory.push(traceTmp);
      if (i > 0) subPlots.push(["xy" + (i + 1)]);
      if (traces[i].yAxisDisplay === "Label") {
        allY["yaxis" + String(element.position)] = {
          tickvals: Array.from(Array(element.yLabels.length).keys()),
          ticktext: element.yLabels,
          side: element.axisLocation,
        };
      }
    });

    if (dataFlow) {
      this.dataHistory = dataHistory;
    }

    const minEventValue = maxEvents > timeWindow ? maxEvents - timeWindow : 0;
    const maxEventValue = maxEvents > timeWindow ? maxEvents : timeWindow;
    if (dataFlow)
      rangHistory = eventView ? [minEventValue, maxEventValue] : range;

    const xaxis = {
      range: rangHistory,
      title: eventView ? "Events" : "Time (s)",
      titlefont: { size: 12 },
      zeroline: false,
    };

    return { data, xaxis, subPlots, allY };
  }
}
