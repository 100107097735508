import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const DeviceMenu = (props: any) => {

    const { selectedTab } = props;
    const { attributes, commands, errors } = props;
    const hasAttributes = attributes.length > 0;
    const hasCommands = commands.length > 0;
    const hasErrors = errors.length > 0;

    const mask = [true, hasErrors, true, hasAttributes, hasCommands, true];

    const tabTitles = [
      "Server",
      "Errors",
      "Properties",
      "Attributes",
      "Commands",
      "Logs"
    ].filter((_, i) => mask[i]);

    const tabs = tabTitles.map(title => {
      // Not quite happy about having to pass the location as a prop to this component, but it appeared to be the easiest way to have links preseve the query string
      const location = props.location || {};
      const tab = title.toLowerCase();
      const to = { ...location, pathname: tab };

      return (
        <li className={"nav-item"} key={tab}>
          <Link
            to={to}
            className={classNames("nav-link", { active: tab === selectedTab })}
          >
            {title}
          </Link>
        </li>
      );
    });

    return (
      <div className="DeviceMenu">
        <ul className="nav nav-tabs section-chooser">{tabs}</ul>
      </div>
    );
}