import React from "react";

import Plot, { Trace } from "./Plot";
import { WidgetProps } from "../types";
import { Inputs } from ".";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { getAttributeTimestampFromState, getAttributeValuesFromState } from "../../../shared/utils/getValuesHelper";

type Props = WidgetProps<Inputs>;
type AttributeEntries = Props["inputs"]["attributes"];

function AttributePlot(props: Props) {
  const { mode, inputs, actualWidth, actualHeight } = props;
  const { attributes, timeWindow, showZeroLine, logarithmic, showTangoDB, textColor, backgroundColor } = inputs;

  const runParams = {
    width: actualWidth,
    height: actualHeight,
    timeWindow,
    showZeroLine,
    logarithmic,
    showTangoDB,
    textColor,
    backgroundColor
  };

  const staticParams = { ...runParams, staticMode: true };

  if (mode === "run") {
    const traces = TracesFromAttributeInputs(attributes, props.t0);
    return <Plot traces={traces} params={runParams} mode={mode} showTangoDB={showTangoDB} />;
  }

  if (mode === "library") {
    const xValues = Array(timeWindow)
      .fill(0)
      .map((_, i) => i);
    const sample1 = xValues.map(x => 8 * Math.sin(x / 6) * Math.sin(x / 20));
    const sample2 = xValues.map(x => 5 * Math.cos(x / 20) * Math.cos(x / 3));
    const traces: Trace[] = [
      {
        fullName: "attribute 1",
        x: xValues,
        y: sample1,
        axisLocation: "left",
        lineColor: "#000000"
      },
      {
        fullName: "attribute 2",
        x: xValues,
        y: sample2,
        axisLocation: "left",
        lineColor: "#000000"
      }
    ];

    return <Plot traces={traces} params={{ ...staticParams, height: 150 }} mode={mode} showTangoDB={showTangoDB} />;
  } else {
    const traces = attributes.map(attributeInput => {
      const { device, attribute, label } = attributeInput.attribute;
      const { showAttribute } = attributeInput;

      let display = "";
      if (showAttribute === "Label") {
        if (label !== "") display = label;
        else display = "attributeLabel";
      }
      else if (showAttribute === "Name") {
        if (attribute !== null) display = attribute;
        else display = "attributeName";
      }

      const fullName = `${device || "?"}//${display || "?"}`;
      const trace: Trace = { fullName, axisLocation: attributeInput.yAxis, lineColor: attributeInput.lineColor };
      return trace;
    });

    return <Plot traces={traces} params={staticParams} mode={props.mode} showTangoDB={showTangoDB} />;
  }
}

export function TracesFromAttributeInputs(
  complexInputs: AttributeEntries,
  t0: number,
): Trace[] {

  const attributesList = complexInputs.map((attr) => ({
    device: attr.attribute.device,
    attribute: attr.attribute.attribute,
  }));

  const customEqualValues = (oldValue, newValue) => {
    for (var attribute of attributesList) {
      let pair = attribute?.device + '/' + attribute?.attribute;
      if (oldValue?.[pair]) {
        let oldValueIndex = oldValue?.[pair]?.values?.length;
        let newValueIndex = newValue?.[pair]?.values?.length;
        if (oldValue?.[pair]?.values?.[oldValueIndex - 1] !== newValue?.[pair]?.values?.[newValueIndex - 1])
          return false;
      }
    }
    return true;
  }

  const customEqualTimeStamp = (oldValue, newValue) => {

    for (var attribute of attributesList) {
      let pair = attribute?.device + '/' + attribute?.attribute;
      if (oldValue?.[pair]) {
        let oldTimestampIndex = oldValue?.[pair]?.timestamp?.length;
        let newTimestampIndex = newValue?.[pair]?.timestamp?.length;
        if (oldValue?.[pair]?.timestamp?.[oldTimestampIndex - 1] !== newValue?.[pair]?.timestamp?.[newTimestampIndex - 1])
          return false;
      }
    }
    return true;
  }

  const timestamp = useSelector((state: IRootState) =>
    getAttributeTimestampFromState(state.messages, attributesList), customEqualTimeStamp
  );

  const msg = useSelector((state: IRootState) =>
    getAttributeValuesFromState(state.messages, attributesList), customEqualValues
  );

  return complexInputs.map(complexInput => {
    const { attribute: attributeInput, yAxis, lineColor } = complexInput;
    const { device, attribute } = attributeInput;
    const fullName = `${device}/${attribute}`;
    const attr = (msg && msg[fullName]) ? msg[fullName] : [];

    let x: number[] = [];
    let y: number[] = [];

    if (attr && attr?.values?.length > 0) {
      let newValuesLenght: number = timestamp?.[fullName].timestamp.filter(time => time > t0).length;      
      x = x = timestamp?.[fullName]?.timestamp?.slice(newValuesLenght*-1).map(timestamp => timestamp - t0);
      y = attr.values.slice(newValuesLenght*-1);
    }

    return { fullName, x, y, axisLocation: yAxis, lineColor };
  });
}


export default AttributePlot;
