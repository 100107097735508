import { combineReducers } from "redux";

import deviceDetail, { IDeviceDetailState } from "./deviceDetail";
import deviceList, { IDeviceListState } from "./deviceList";
import attributesList, { IAttributeListState } from "./attributeList";
import loadingStatus, { ILoadingStatusState } from "./loadingStatus";
import commandOutput, { ICommandOutputState } from "./commandOutput";
import loggedActions, { ILoggedActionsState } from "./loggedActions";

import devices, { IDevicesState } from "./devices";
import database, { IDatabaseState } from "./database";
import attributes, { IAttributesState } from "./attributes";
import commands, { ICommandsState } from "./commands";
import properties, { IPropertiesState } from "./properties";

import user, { IUserState } from "../../user/state/reducer";
import messages from "./messagesReducer";
import subscription from "./subscriptionReducer";
import error from "./error";

import ui, { UIState } from "./ui";
import selectedDashboard, {
  SelectedDashboardState,
} from "./selectedDashboard/index";
import canvases, { CanvasesState } from "./canvases";
import dashboards, { DashboardsState } from "./dashboards";
import selectedSynoptic, {
  SelectedSynopticState,
} from "../../../synoptic/state/reducers/selectedSynoptic/index";
import synoptics, {
  SynopticsState,
} from "../../../synoptic/state/reducers/synoptics";
import notifications, { NotificationsState } from "./notifications";
import clipboard, { ClipboardState } from "./clipboard";
import elastic, { IElasticState } from "../../../shared/elastic/state/reducer";

export interface IRootState {
  // View state
  deviceDetail: IDeviceDetailState;
  deviceList: IDeviceListState;
  attributesList: IAttributeListState;

  // App State
  loadingStatus: ILoadingStatusState;
  error: string;
  user: IUserState;

  // Data state
  database: IDatabaseState;
  devices: IDevicesState;
  commandOutput: ICommandOutputState;
  attributes: IAttributesState;
  commands: ICommandsState;
  properties: IPropertiesState;
  loggedActions: ILoggedActionsState;
  messages: any;
  subscription: any;

  ui: UIState;
  canvases: CanvasesState;
  selectedDashboard: SelectedDashboardState;
  selectedSynoptic: SelectedSynopticState;
  elastic: IElasticState;
  dashboards: DashboardsState;
  synoptics: SynopticsState;
  notifications: NotificationsState;
  clipboard: ClipboardState;
}

const rootReducer = combineReducers<IRootState>({
  deviceDetail,
  deviceList,
  attributesList,
  database,
  devices,
  loadingStatus,
  commandOutput,
  attributes,
  commands,
  properties,
  error,
  user,
  loggedActions,
  messages,
  subscription,
  ui,
  canvases,
  selectedDashboard,
  selectedSynoptic,
  elastic,
  dashboards,
  synoptics,
  notifications,
  clipboard,
});

export default rootReducer;
