import React from "react";
import cx from "classnames";

import {
  WidgetDefinition,
  DeviceInputDefinition,
  NumberInputDefinition,
  AttributeInputDefinition,
  StyleInputDefinition
} from "../../../types";

import "./SardanaMotor.css";
import { AttributeAbsWriter } from "./AttributeAbsWriter";
import { StateIndicatorLabel } from "../../../../shared/ui/components/StateIndicatorLabel";
import { WidgetProps } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../shared/state/reducers/rootReducer";
import { executeCommand } from "../../../../shared/state/actions/tango";
import { getAttributeLastValueFromState } from "../../../../shared/utils/getLastValueHelper";
import { getTangoDB } from "../../../dashboardRepo";
import { parseCss } from "../../../components/Inspector/StyleSelector";

export type Inputs = {
  device: DeviceInputDefinition;
  precision: NumberInputDefinition;
  limits: AttributeInputDefinition;
  position: AttributeInputDefinition;
  power: AttributeInputDefinition;
  state: AttributeInputDefinition;
  outerDivCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

const SardanaMotor: React.FC<Props> = (props: Props) => {
  const { inputs, mode } = props;
  const { limits, precision, device, position, power, outerDivCss } = inputs;
  const dispatch = useDispatch();

  let outerDivCssObj = parseCss(outerDivCss).data;

  let positionValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      device.name,
      'position'
    );
  });

  let powerValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      device.name,
      'power'
    );
  });

  let stateValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      device.name,
      'state'
    );
  });

  const setPosition = (value: number) => {
    position.write(value);
  }

  const handleStop = () => {
    dispatch(
      executeCommand(
        getTangoDB(),
        "Stop",
        null,
        device.name,
      )
    );
  }
  const setPower = (value: boolean) => {
    power.write(value);
  }

  let name = "";
  let alias = "";

  if (device) {
    name = device.name;
    alias = device.alias;
  }
  const limitValues = limits.value;
  let limitWarningCss = "";
  let limitWarningTitle = "";
  if (limitValues) {
    if (limitValues[1]) {
      limitWarningCss = "upper-limit-warning";
      limitWarningTitle = "Upper limit reached";
    } else if (limitValues[2]) {
      limitWarningCss = "lower-limit-warning";
      limitWarningTitle = "Lower limit reached";
    }
  }

  const value = mode === "run" ? positionValue : 0;

  // Value hasn't been initialised yet
  if (value == null) {
    return null;
  }

  //support for motors without PowerOn attribute 
  if (typeof (powerValue) === 'undefined') {
    powerValue = true;
  }

  const valueWithPrecision = value.toFixed(precision);

  const displayElement =
    value === undefined ? (
      <span className="value" style={{ color: "gray" }}>
        n/a
      </span>
    ) : (
      <span
        style={{ fontFamily: "monospace" }}
        title={limitWarningTitle}
        className={cx("value", limitWarningCss)}
      >
        {valueWithPrecision} {position.unit}
      </span>
    );

  return (
    <div id="SardanaMotor" className="sardana-motor" style={outerDivCssObj}>
      <div className="motor-container">
        <div className="motor-row">
          <StateIndicatorLabel
            state={mode === "run" ? stateValue : "UNKNOWN"}
          />
          <span style={{ margin: "0em 0.3em" }}>
            {alias || name || "name"}
          </span>
        </div>
        {mode === "run" && !powerValue ? (
          <div className="motor-row">
            Power is off.{" "}
            <button
              className={"btn-stopped"}
              title="Power on motor"
              onClick={() => setPower(true)}
            />
          </div>
        ) : (
          <>
            <div className="motor-row">{displayElement}</div>
            <div className="motor-row">
              <AttributeAbsWriter
                state={stateValue}
                writeValue={positionValue}
                mode={mode}
                onSetPosition={value => setPosition(value)}
                onStop={handleStop}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const definition: WidgetDefinition<Inputs> = {
  type: "SARDANA_MOTOR",
  name: "Sardana Motor",
  defaultHeight: 2,
  defaultWidth: 24,
  inputs: {
    device: {
      type: "device",
      label: "Device",
      publish: "$device"
    },
    precision: {
      type: "number",
      label: "Precision",
      default: 3
    },
    limits: {
      type: "attribute",
      device: "$device",
      attribute: "limit_switches"
    },
    position: {
      type: "attribute",
      device: "$device",
      attribute: "position",
      invalidates: ["state"]
    },
    power: {
      type: "attribute",
      device: "$device",
      attribute: "poweron"
    },
    state: {
      type: "attribute",
      device: "$device",
      attribute: "state"
    },
    outerDivCss: {
      type: "style",
      label: "Outer Div CSS"
    },
  }
};

const SardanaMotorExport = { definition, component: SardanaMotor };
export default SardanaMotorExport;