const config = window['config'];

export function getTangoDBName() {
  try {
    if(config.basename !== "")
      return window.location.pathname.split("/")[2];
    else
      return window.location.pathname.split("/")[1];
  } catch (e) {
    return "";
  }
}