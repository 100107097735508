import React from 'react';
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { useSelector } from "react-redux";
import {
  getAttributeLastTimeStampFromState,
  getAttributeLastValueFromState
} from '../../../shared/utils/getLastValueHelper';
import { JSONTree, parseJSONObject } from '../../../shared/utils/JSONTree';
import { AttributeInput } from "../../types";

interface Props {
  attribute_obj: AttributeInput;
  showEnumLabels: boolean;
  precision: number;
  scientificNotation: boolean;
}

const Cell: React.FC<Props> = ({
  attribute_obj,
  showEnumLabels,
  precision,
  scientificNotation
}) => {
  const { device, attribute, dataType, enumlabels } = attribute_obj;
  
  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, device, attribute);
  });

  const timestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(state.messages, device, attribute)?.toString();
  });

  let valueDisplay: string | number | JSX.Element | null = '...';

  if (value !== undefined) {
    const parsedValue = parseJSONObject(value);
    if (parsedValue !== null) {
      valueDisplay = (
        <div className="json-tree" style={{ marginTop: '22px' }}>
          <JSONTree data={parsedValue} jsonCollapsed={true} />
        </div>
      );
    } else {
      if (typeof (value) === 'number') {
        if (showEnumLabels && enumlabels?.length > 0) valueDisplay = enumlabels?.[value] || value;
        else if (scientificNotation) valueDisplay = value.toExponential(precision);
        else valueDisplay = Number.isInteger(value) ? value : Number(value).toFixed(precision);
      }
      else valueDisplay = String(value);
    }
  }

  return (
    <td title={new Date(timestamp * 1000).toUTCString()}>
      <span className={dataType === "DevState" ? "stateTango " + valueDisplay : ""}>
        {valueDisplay}
      </span>
    </td>
  );
};

export default Cell;
