import { createDeviceWithTangoDBFullPath } from "./runtime/utils";
import { InputDefinition, InputDefinitionMapping } from "./types";
import { getTangoDB } from "./dashboardRepo";
import { SelectedDashboardState } from "../shared/state/reducers/selectedDashboard";
import { IUserState } from "../shared/user/state/reducer";
import { splitFullPath } from "./DBHelper";

function defaultInput(input: InputDefinition) {
  if (input.type === "attribute") {
    return { device: null, attribute: null };
  } else if (input.type === "command") {
    return { device: null, command: null };
  } else if (input.type === "device") {
    return null;
  } else if (input.type === "complex") {
    if (input.repeat) {
      return [];
    } else {
      return defaultInputs(input.inputs);
    }
  } else {
    return input.default;
  }
}

export function defaultInputs(inputs: InputDefinitionMapping) {
  const inputNames = Object.keys(inputs);
  return inputNames.reduce((accum, name) => {
    const input = inputs[name];
    const value = defaultInput(input);
    return { ...accum, [name]: value };
  }, {});
}

export function filterMissingDevices(deviceList: string[] | undefined, fullNames: string[], hasAttributes=true) {
  return fullNames.filter(name => {
    const [tangoDB, devName] = splitFullPath(name);
    const deviceName = (hasAttributes) ? devName.substring(0, devName.lastIndexOf('/')) : devName;
    return deviceList?.includes(createDeviceWithTangoDBFullPath(tangoDB, deviceName).toLowerCase());
  });
}

/**
 * This function checks if SVG node contains valid devices
 *
 * @param svgString
 * @param deviceList
 * @returns
 */
export function validateSVG(svgString: string, deviceList: string[]) {
  let isWarning = false,
      msg = '';
  const scannedDevices: string[] = [];
  svgString = svgString || "{}";
  try {
    svgString = JSON.parse(svgString).fileContent;
  } catch (error) {
    console.log("Error parsing JSON svgString: ",error);
  }

  if (svgString) {
    const unavailableDevices: string[] = [];
    const defaultDBName = getTangoDB();
    const pattern = /^(model|section|alarm)=(.*)/;

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "text/xml")
    const elements = svgDoc.querySelectorAll("desc");

    elements.forEach(element => {
      const lines = element?.textContent?.split("\n");
      lines?.forEach((line) => {
        const match = line.trim().match(pattern);

        if (match) {
          if (match[1] === 'model') {

            const devicePathWithAttr = match[2].trim().toLowerCase();
            let [tangoDB, devName] = splitFullPath(devicePathWithAttr);
            if (!tangoDB) {
              tangoDB = defaultDBName;
            }

            const deviceDet = devName.split('/');
            const devicewithTangoDB = tangoDB + '://' + (deviceDet.slice(0, 3).join('/'));

            // create device + attribute for subscription
            const isFullAttrPath = deviceDet.length > 3;
            const deviceWithAttr = isFullAttrPath ? tangoDB + '://' + devName
                                    : `${devicewithTangoDB}/state`;

            // create list of all devices in SVG
            if(!scannedDevices.includes(deviceWithAttr)  ) {
              scannedDevices.push(deviceWithAttr);
            }
            //Create list of unavailable devices
            if(-1 === (deviceList?.indexOf(devicewithTangoDB))) {
              if (-1 === unavailableDevices.indexOf(devicewithTangoDB))
                unavailableDevices.push(devicewithTangoDB);
            }
          }
        }
      });
    })

    if (unavailableDevices.length > 0) {
      msg = unavailableDevices.join(", ") + " not found.";
      isWarning = true;
    }
  }

  return { isWarning, msg, scannedDevices };
}

export function isEditableDashboard(
  dashboard: SelectedDashboardState, 
  currentUser: IUserState
): {result: Boolean} {

  const config = window['config'];
  const { group, groupWriteAccess, user } = dashboard; 
  let result: Boolean = false;

  if(currentUser.username !== undefined){ //if the user is not logged, the dashboard is not editable

    if([config.ADMIN_USERNAME, user].includes(currentUser.username)){ //if the user is either the owner or the admin
      result = true;
    } else {
      let commonGroup = false
      if(currentUser.userGroups !== undefined && group !== null){ //if the user is in hte same group of the dashboard and if has write access
        commonGroup = currentUser.userGroups.some(element => group.includes(element))
      }
      if(commonGroup && groupWriteAccess){
        result = true;
      }
    }
  }
  
  return { result }; 
}
