import React, { CSSProperties, Component } from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  NumberInputDefinition,
  StringInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
  ColorInputDefinition,
  StyleInputDefinition
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector"
import AttributeLEDValues from "./AttributeLEDValues"
import { showHideTangoDBName } from "../../DBHelper";

const config = window['config'];

type Inputs = {
  showAttributeValue: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
  showDeviceName: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  compare: StringInputDefinition;
  relation: SelectInputDefinition;
  attribute: AttributeInputDefinition;
  trueColor: ColorInputDefinition;
  falseColor: ColorInputDefinition;
  ledSize: NumberInputDefinition;
  textSize: NumberInputDefinition;
  alignTextCenter: BooleanInputDefinition;
  alignValueRight: BooleanInputDefinition;
  customCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

class AttributeLEDDisplay extends Component<Props> {
  public render() {
    const {
      trueColor,
      falseColor,
      ledSize,
      textSize,
      showDeviceName,
      showTangoDB,
      showAttribute,
      showAttributeValue,
      compare, 
      relation, 
      alignTextCenter,
      alignValueRight,
    } = this.props.inputs;
    const { mode } = this.props;
    const { deviceName, attributeName, label } = this.deviceAndAttribute();
    
    const parsedCss = parseCss(this.props.inputs.customCss).data;
    const emledSize = ledSize + "em";

    const minSize = config.MIN_WIDGET_SIZE;
    let minPadding = "0em";
    let minMargin = "0em 0em";
    let paddingLabel = "5px";
    if (minSize > 10) {
      paddingLabel = "0em";
      //minPadding = "0.5em";
      minMargin = "0em 0.5em";
    }
    
    const deviceLabel = showHideTangoDBName(showDeviceName, showTangoDB, deviceName);
    const alignCenter  = alignTextCenter ?  "center" : "left";
    const alignLeft : CSSProperties = alignValueRight ? {display: "inline-block", float: "right", textAlign: "right"} : {}
    const renderDeviceName = mode === "library" ? "device name" : deviceLabel;
    let display = "";
    if (showAttribute === "Label") display = label;
    else if (showAttribute === "Name") display = attributeName;
    let renderAttributeName =
      showAttribute || mode === "library" ? display || "" : "";
    renderAttributeName = renderDeviceName ? `/${renderAttributeName}` : renderAttributeName

    return (
      <div style={{ padding: minPadding, ...parsedCss, textAlign: alignCenter }}
      >
        <span style={{ fontSize: textSize + "em", padding: paddingLabel  }}>
          {renderDeviceName}
          {renderAttributeName}
        </span>
        <span className = "myLed" style = { alignLeft }>
          <AttributeLEDValues 
            deviceName={deviceName}
            attributeName={attributeName}
            trueColor={trueColor}
            falseColor={falseColor}
            emledSize={emledSize}
            minMargin={minMargin}
            textSize={textSize}
            compare={compare}
            relation={relation}
            showAttributeValue={showAttributeValue}
            />
        </span>
        
      </div>
    );
  }

  private deviceAndAttribute(): {
    deviceName: string;
    attributeName: string;
    label: string;
  } {
    const { attribute } = this.props.inputs;
    const deviceName = attribute.device || "device";
    const attributeName = attribute.attribute || "attribute";
    const label = attribute.label || "attributeLabel";
    return { deviceName, attributeName, label };
  }
}

export const definition: WidgetDefinition<Inputs> = {
  type: "LED_DISPLAY",
  name: "Attribute LED Display",
  defaultWidth: 10,
  defaultHeight: 2,
  inputs: {
    attribute: {
      /* tslint:disable-next-line */
      type: "attribute",
      label: "",
      dataFormat: "scalar",
      required: true,
    },
    relation: {
      type: "select",
      label: "relation",
      default: ">",
      options: [
        {
          name: "is more than",
          value: ">",
        },
        {
          name: "is less than",
          value: "<",
        },
        {
          name: "is equal to",
          value: "=",
        },
        {
          name: "is more than or equal to",
          value: ">=",
        },
        {
          name: "is less than or equal to",
          value: "<=",
        },
      ],
    },
    compare: {
      type: "string",
      label: "Compare",
      default: "",
    },
    trueColor: {
      type: "color",
      label: "True color",
      default: "#3ac73a",
    },
    falseColor: {
      type: "color",
      label: "False color",
      default: "#ff0000",
    },
    ledSize: {
      label: "Size of LED (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    textSize: {
      label: "Size of text (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    showAttributeValue: {
      type: "boolean",
      label: "Show Attribute Value",
      default: false,
    },
    showDeviceName: {
      type: "boolean",
      label: "Show Device Name",
      default: false,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
        {
          name: "None",
          value: "None",
        },
      ],
    },
    alignTextCenter: {
      type: "boolean",
      label: "Align text on center",
      default: false
    },
    alignValueRight: {
      type: "boolean",
      label: "Align value on right",
      default: true
    },
    customCss: {
      type: "style", 
      label: "Custom Css", 
      default: ""
    }
  },
};
const AttributeLEDDisplayExport = { component: AttributeLEDDisplay, definition };

export default AttributeLEDDisplayExport;
