import Cookies from "universal-cookie";
import { NOTIFY_ALL_NOTIFICATION } from '../../shared/notifications/notifications';
const config = window['config'];

const userAPIExport = {
  async saveStateUrls(stateUrls) {
    try {
      let savedStateUrls = sessionStorage.getItem("stateUrls");
      savedStateUrls = JSON.parse(savedStateUrls);
      if (savedStateUrls) {
        if (savedStateUrls.deviceUrl !== stateUrls.deviceUrl)
          savedStateUrls.deviceUrl = stateUrls.deviceUrl;
        if (savedStateUrls.dashboardUrl !== stateUrls.dashboardUrl)
          savedStateUrls.dashboardUrl = stateUrls.dashboardUrl;
        if (savedStateUrls.synopticUrl !== stateUrls.synopticUrl)
          savedStateUrls.synopticUrl = stateUrls.synopticUrl;
      } else {
        savedStateUrls = stateUrls;
      }

      sessionStorage.setItem("stateUrls", JSON.stringify(savedStateUrls));

      return savedStateUrls;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  async loadStateUrls() {
    try {
      let savedStateUrls = sessionStorage.getItem("stateUrls");
      savedStateUrls = JSON.parse(savedStateUrls);

      return savedStateUrls;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  async preloadUser() {
    try {
      const res = await fetch(config.basename + "/auth/user", { credentials: 'include' });
      const user = await res.json();
      if (!user.groups) {
        user.groups = [];
      }
      return user;
    } catch (err) {
      return null;
    }
  },

  async login(username, password) {
    const cookies = new Cookies();
    cookies.remove("taranta_jwt", { path: "/" });

    const init = {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    try {
      const res = await fetch(config.basename + "/auth/login", init);
      const json = await res.json()

      cookies.set("taranta_jwt", json.taranta_jwt, { path: "/" });
      let groups = [];
      //const message = {level: "Info", message: "Login Sucess"}
      //feedBackService.setData(message);
      try {
        groups = JSON.parse(window.atob(json.taranta_jwt.split(".")[1])).groups;
      } catch (err) {}
      return res.ok ? { username, groups } : null;
    } catch (err) {
      return null;
    }
  },

  async logout() {
    try {
      const init = { method: "POST", credentials: 'include' };
      await fetch(config.basename + "/auth/logout", init);
      const cookies = new Cookies();
      cookies.remove("taranta_jwt", { path: "/" });
      //const message = {level: "Info", message: "Logout Sucess"}
      //feedBackService.setData(message);
      return true;
    } catch (err) {
      return false;
    }
  },

  async extendLogin() {
    try {
      const init = { method: "POST", credentials: 'include' };
      const result = await fetch(config.basename + "/auth/extend", init);

      return !(403 === result.status);
    } catch (err) {
      return false;
    }
  },

  async saveNotification(notification, username) {
    const init = {
      method: "POST",
      body: JSON.stringify({ notification }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    try {
      let notifications = [];
      let notification = sessionStorage.getItem("notifications");
      let oldNotifications = JSON.parse(notification);
      if (oldNotifications) {
        oldNotifications.forEach((element) => {
          notifications.push(element);
        });
      }

      notifications.push(JSON.parse(init.body));
      sessionStorage.setItem("notifications", JSON.stringify(notifications));
      if (notifications) {
        notifications = notifications.filter(function(element) {
          return element["notification"]["username"] === username;
        });
      }

      return notifications;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  async loadNotifications(username) {
    try {
      let notifications = JSON.parse(sessionStorage.getItem("notifications"));
      if (notifications) {
        notifications = notifications.filter(function(element) {
          return element["notification"]["username"] === username;
        });
      }

      return notifications;
    } catch (err) {
      console.log(err);
      return null;
    }
  },

  async clearNotifications(username) {
    try {
      let notifications = JSON.parse(sessionStorage.getItem("notifications"));
      sessionStorage.setItem("notifications", JSON.stringify([]));
      notifications = []; //Clearing all user notifications, let only other user on session
      return notifications;
    } catch (err) {
      console.log(err);
      return null;
    }
  },

  async setNotifications(key) {
    try {
      let notifications = JSON.parse(sessionStorage.getItem("notifications"));
      let newNotifications = [];
      if (notifications && 0 < notifications.length) {
        notifications.forEach((element) => {
          if (
            NOTIFY_ALL_NOTIFICATION === key ||
            element.notification.key === key
          ) {
            element.notification.notified = true;
            newNotifications.push(element);
          } else newNotifications.push(element);
        });
        sessionStorage.setItem(
          "notifications",
          JSON.stringify(newNotifications)
        );
        notifications = newNotifications;
      }
      return notifications;
    } catch (err) {
      console.log(err);
      return null;
    }
  },
};

export default userAPIExport;
