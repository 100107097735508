import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import {
  getAttributeLastTimeStampFromState,
  getAttributeLastValueFromState
} from "../../../shared/utils/getLastValueHelper";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { STATE_COLORS, brighten } from "../../colorUtils";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { showHideTangoDBName } from "../../DBHelper";

interface Props {
  device: string;
  showDeviceName: boolean;
  showTangoDB: boolean;
  showStateString: boolean;
  showStateLED: boolean;
  LEDSize: number;
  textColor: string;
  backgroundColor: string;
  textSize: number;
  linkTo: string;
}

const DeviceStatusValues: React.FC<Props> = ({
  device,
  showDeviceName,
  showTangoDB,
  showStateString,
  showStateLED,
  LEDSize,
  textColor,
  backgroundColor,
  textSize,
  linkTo
}) => {
  let stateValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, device, "state");
  });

  const timestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(
      state.messages,
      device,
      "state"
    )?.toString();
  });

  const text = (
    <Text
      device={device}
      textSize={textSize}
      textColor={linkTo ? null : textColor}
      backgroundColor={backgroundColor}
      showDeviceName={showDeviceName}
      showTangoDB={showTangoDB}
      showStateString={showStateString}
      state={stateValue}
      showStateLED={showStateLED}
    />
  );
  const led = (
    <LED LEDSize={LEDSize} state={stateValue} showStateLED={showStateLED} />
  );

  const prefix = linkTo.toLowerCase().startsWith("http") ? "" : "http://";
  const content = linkTo ? (
    <a href={prefix + linkTo} rel="noopener noreferrer" target="_blank">
      {text}
      {led}
    </a>
  ) : (
    <div>
      {text}
      {led}
    </div>
  );

  return <div title={new Date(timestamp * 1000).toUTCString()}>{content}</div>;
};

const Text = (props: {
  widgetCss?: any;
  device?: any;
  textSize?: any;
  textColor?: any;
  backgroundColor?: any;
  showDeviceName?: any;
  showTangoDB?: boolean;
  showStateString?: any;
  state?: any;
  showStateLED?: any;
}) => {
  const {
    device,
    textSize,
    textColor,
    backgroundColor,
    showDeviceName,
    showTangoDB = false,
    showStateString,
    state,
    showStateLED
  } = props;
  const deviceName = showHideTangoDBName(
    showDeviceName,
    showTangoDB,
    device,
    "Device name"
  );
  const stateString = showStateString ? state || "STATE" : null;
  const widgetCss = parseCss(props.widgetCss).data;
  delete widgetCss["border"];

  const style: CSSProperties = {
    fontSize: textSize + "em",
    color: textColor,
    backgroundColor: backgroundColor,
    ...widgetCss
  };
  if (showStateLED) {
    style["marginRight"] = "0.5em";
  }
  return (
    (deviceName || stateString) && (
      <span style={style}>
        {deviceName} {stateString}
      </span>
    )
  );
};

const LED = (props: {
  size?: any;
  LEDSize?: any;
  state?: any;
  showStateLED?: any;
}) => {
  const { LEDSize, state, showStateLED } = props;
  if (!showStateLED) {
    return null;
  }
  const stateValue = state;
  const color: string = STATE_COLORS[stateValue || "UNKNOWN"];
  const emSize = 1 * LEDSize + "em";
  let baseColor = color;
  let highLightColor = brighten(color, 30);
  let borderColor = color;

  switch (color) {
    case "green":
      baseColor = brighten(baseColor, 15);
      highLightColor = brighten(highLightColor, 15);
      borderColor = brighten(borderColor, 15);
      break;
    case "white":
      borderColor = "#aaa";
      baseColor = "#f8f8f8";
      break;
    case "lightblue":
      baseColor = brighten(baseColor, -10);
      highLightColor = brighten(highLightColor, -10);
      break;
    case "yellow":
      baseColor = brighten(baseColor, -5);
      highLightColor = brighten(highLightColor, -5);
      borderColor = brighten(borderColor, -5);
      break;
    case "red":
      borderColor = brighten(borderColor, -5);
      break;
    case "beige":
      baseColor = brighten(baseColor, -15);
      highLightColor = brighten(highLightColor, -15);
      borderColor = brighten(borderColor, -15);
      break;
    case "darkgreen":
      borderColor = brighten(borderColor, 5);
      break;
    case "magenta":
      borderColor = brighten(borderColor, -5);
      break;
    case "grey":
      borderColor = brighten(borderColor, 5);
      break;
    default:
      break;
  }
  const background =
    "radial-gradient(circle, " +
    highLightColor +
    " 0%, " +
    baseColor +
    " 100%)";
  const border = props.size / 50 + "em solid " + borderColor;
  return (
    <div style={{ display: "inline-block", verticalAlign: "text-bottom" }}>
      <div
        style={{
          width: emSize,
          height: emSize,
          background,
          borderRadius: "50%",
          border
        }}
        title={stateValue}
      ></div>
    </div>
  );
};

export default DeviceStatusValues;
