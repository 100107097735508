import React, { Component, Fragment, CSSProperties } from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  AttributeInputDefinition,
  BooleanInputDefinition,
  NumberInputDefinition,
  SelectInputDefinition,
  StyleInputDefinition,
} from "../../types";
import LogValues from "./Logs/LogValues";

type Inputs = {
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showTime: BooleanInputDefinition;
  linesDisplayed: NumberInputDefinition;
  showLastValue: BooleanInputDefinition;
  attribute: AttributeInputDefinition;
  showAttribute: SelectInputDefinition;
  OuterDivCSS: StyleInputDefinition;
  LastValueCSS: StyleInputDefinition;
  TableCSS: StyleInputDefinition;
}
type Props = WidgetProps<Inputs>;

export class AttributeLogger extends Component<Props> {

  public render() {

    const { mode } = this.props;
    const {
      attribute,
      showAttribute,
      showTangoDB,
      showDevice,
      linesDisplayed,
      showLastValue,
      showTime,
      OuterDivCSS,
      LastValueCSS,
      TableCSS
    } = this.props.inputs;

    const style: CSSProperties = {  whiteSpace: "nowrap" };

    return (
      <div style={style}>
        <Fragment>
          <LogValues
            deviceName={attribute?.device}
            attributeName={attribute?.attribute}
            showAttribute={showAttribute}
            showDevice={showDevice}
            showTangoDB={showTangoDB}
            label={attribute?.label}
            linesDisplayed={linesDisplayed}
            showLastValue={showLastValue}
            showTime={showTime}
            mode={mode}
            OuterDivCSS={OuterDivCSS}
            LastValueCSS={LastValueCSS}
            TableCSS={TableCSS}
          />
        </Fragment>
      </div>
    )
  }
}

// defines the inputs on the RHS used to manage the widget
const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTE_LOGGER",
  name: "Attribute Logger",
  defaultWidth: 50,
  defaultHeight: 20,
  inputs: {
    attribute: {
      type: "attribute",
      label: "Attribute to log",
      dataFormat: "scalar",
      required: true
    },
    linesDisplayed: {
      type: "number",
      label: "Lines Logged",
      default: 5
    },
    showLastValue: {
      type: "boolean",
      label: "Show Last Value",
      default: true
    },
    showDevice: {
      type: "boolean",
      label: "Show Device",
      default: false
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label"
        },
        {
          name: "Name",
          value: "Name"
        },
        {
          name: "None",
          value: "None"
        }
      ]
    },
    showTime: {
      type: "boolean",
      label: "Show TimeStamp column",
      default: false
    },
    OuterDivCSS: {
      type: "style",
      label: "Outer div CSS",
      default: ""
    },
    LastValueCSS: {
      type: "style",
      label: "Last Value CSS",
      default: ""
    },
    TableCSS: {
      type: "style",
      label: "Table CSS",
      default: ""
    }
  }
};

const AttributeLoggerExport = { component: AttributeLogger, definition };
export default AttributeLoggerExport;
