import { validateSVG } from "../../dashboard/utils";
import { isWidgetValid } from "./canvas";

function getAttributes(attributes) {
    return attributes.map(attributeObj => `${attributeObj?.attribute?.device}/${attributeObj?.attribute?.attribute}`);
}

export const getSubs = (widgets, checkValidWidgets = true) => {
    if (widgets) {
        const result = [];
        Object.values(widgets).forEach((widget) => {
            if (checkValidWidgets && !isWidgetValid(widget)) return result;
            switch (widget.type) {
                case 'BOX':
                    const innerSubs = getSubs(widget.innerWidgets);
                    innerSubs?.forEach((sub) => {
                        result.push(sub);
                    });
                    break;
                case 'ATTRIBUTE_DISPLAY':
                case 'ATTRIBUTE_WRITER':
                case 'ATTRIBUTE_WRITER_DROPDOWN': case 'ATTRIBUTE WRITER DROPDOWN':
                    const attributeWriter = widget.inputs.attribute;
                    if (attributeWriter?.device) result.push(`${attributeWriter?.device}/${attributeWriter?.attribute}`);
                    //TODO: Do we need this additional subscrition to state.
                    if (attributeWriter?.device) result.push(`${attributeWriter?.device}/${"State"}`);
                    break;
                case 'SPECTRUM':
                case 'ATTRIBUTE_DIAL':
                case 'BOOLEAN_DISPLAY':
                case 'LED_DISPLAY':
                case 'Image_Display':
                case 'SPECTRUM_TABLE':
                case 'IMAGE_TABLE':
                case 'ATTRIBUTE_LOGGER':
                    const attribute = widget.inputs.attribute;
                    if (attribute?.device) result.push(`${attribute?.device}/${attribute?.attribute}`);
                    break;
                case 'ATTRIBUTE_PLOT':
                case 'TIMELINE':
                    widget.inputs.attributes.forEach((attr) => {
                        const { attribute } = attr;
                        result.push(`${attribute?.device}/${attribute?.attribute}`);
                    })
                    break;
                case 'ATTRIBUTE_SCATTER':
                    const independent = widget.inputs.independent;
                    const dependent = widget.inputs.dependent;
                    if (independent?.device) result.push(`${independent?.device}/${independent?.attribute}`);
                    if (dependent?.device) result.push(`${dependent?.device}/${dependent?.attribute}`);
                    break;
                case 'SPECTRUM_2D':
                    const { attributeX, attributes } = widget.inputs;
                    const results = [
                        `${attributeX?.device}/${attributeX?.attribute}`,
                        ...getAttributes(attributes)
                    ];
                    results.forEach((attr) => {
                        result.push(attr);
                    });
                    break;
                case 'TABULAR_VIEW':
                    if (widget.inputs?.customAttributes?.length > 0) {
                        widget.inputs.customAttributes.forEach((attributeObj) => {
                            const { attribute } = attributeObj;
                            if (attribute?.device) {
                                const pairs = attribute.device.split(",").reduce((acc, dev) => {
                                    const idx = acc.findIndex(item => item.startsWith(dev));
                                    if (idx === -1) {
                                        acc.push(`${dev}/${attribute.attribute}`);
                                    } else {
                                        acc[idx] += `,${attribute.attribute}`;
                                    }
                                    return acc;
                                }, []);
                                pairs.forEach(pair => {
                                    result.push(pair);
                                });
                            }
                        });
                    }
                    break;
                case 'DEVICE_STATUS':
                    const device = widget.inputs.device;
                    if (device) result.push(`${device}/state`);
                    break;
                case 'ATTRIBUTEHEATMAP':
                    const attributeHeatMap = widget.inputs.attribute;
                    const xAxis = widget.inputs.xAxis;
                    const yAxis = widget.inputs.yAxis;
                    result.push(`${attributeHeatMap.device}/${attributeHeatMap.attribute}`);
                    result.push(`${xAxis.device}/${xAxis.attribute}`);
                    result.push(`${yAxis.device}/${yAxis.attribute}`);
                    break;
                case 'MACROBUTTON':
                    const { door, macroserver, pool } = widget.inputs;
                    if (door) {
                        result.push(`${door}/State`)
                        result.push(`${door}/Output`);
                    }
                    if (macroserver) result.push(`${macroserver}/MacroList`);
                    if (pool) result.push(`${pool}/MotorList`);
                    break;
                case 'SARDANA_MOTOR':
                    const sardanaDevice = widget.inputs.device;
                    if (sardanaDevice) {
                        result.push(`${sardanaDevice}/Position`);
                        result.push(`${sardanaDevice}/Power`);
                        result.push(`${sardanaDevice}/State`);
                    }
                    break;
                case 'SVG_WIDGET':
                    const { scannedDevices } = validateSVG(widget.inputs.svgFile);
                    scannedDevices.forEach(r => result.push(r));
                    break;
                default:
                    break;
            }
        });
        const uniqueArr = [...new Set(result.filter(Boolean))];
        return uniqueArr;
    }
    return null;
}