import {
  FETCH_ELASTIC,
  FETCH_ELASTIC_ERROR,
  FETCH_ELASTIC_SUCCESS
} from "./actionTypes";

import {
  IFetchElasticLogsAction,
  IFetchElasticLogsErrordAction,
  IFetchElasticLogsSuccessAction
} from "./actions";

export interface IElasticState {
  messages: Object;
  error: string;
}

type ElasticAction =
  | IFetchElasticLogsAction
  | IFetchElasticLogsErrordAction
  | IFetchElasticLogsSuccessAction

const initialState = {
  messages: {},
  error: ""
};

export default function elastic(
  state: IElasticState = initialState,
  action: ElasticAction
) {
  switch (action.type) {
    case FETCH_ELASTIC:
      return { ...state };
    case FETCH_ELASTIC_SUCCESS:
      const msgs = {...state.messages, ...action.results};
      return { ...state, messages: msgs, error: "" };
    case FETCH_ELASTIC_ERROR:
      console.log("FETCH_ELASTIC_ERROR: ", action, "State: ", state);
      return { ...state, error: action.error };
    default:
      return state;
  }
}
