import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
    InputDefinitionMapping,
    InputMapping,
    IndexPath,
    Widget,
    Variable,
} from "../../types";

import NumericInput from "./NumericInput";
import FileInput from "./FileInput";
import AttributeSelect from "./AttributeSelect";
import DeviceSelect from "./DeviceSelect";
import CommandSelect from "./CommandSelect";
import StyleSelector from "./StyleSelector";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getDeviceNames } from "../../../shared/state/selectors/deviceList";
import { saveAs } from 'file-saver';

interface Props {
    inputId: string;
    tangoDB: string;
    inputDefinitions: InputDefinitionMapping;
    inputs: InputMapping;
    widgets: Widget[];
    onChange: (path: IndexPath, value) => void;
    onAdd: (path: IndexPath) => void;
    onDelete: (path: IndexPath) => void;
    widgetType?: string;
    basePath?: IndexPath;
    nonEditable: boolean;
    variables: Variable[];
    widgetDevices?: string;
    devices?: string[];
}

interface State {
    fileName: String;
    fileContent: any;
    fileType: String;
}

const animatedComponents = makeAnimated();

class InputList extends Component<Props, State> {

    public render() {
        const {
            inputDefinitions,
            inputs,
            widgets,
            tangoDB,
            widgetType,
            nonEditable,
            variables,
        } = this.props;

        const inputNames = Object.keys(inputDefinitions);

        const hasSameValue = inputNames.reduce((accum, inputName) => {
            const result = widgets.reduce(
                (acc, curr) =>
                    acc &&
                    curr.inputs[inputName] === widgets[0].inputs[inputName],
                true
            );
            return { ...accum, [inputName]: result };
        }, {});
        const inputControls = inputNames.map((inputName) => {
            const inputDefinition = inputDefinitions[inputName];
            const definitionLabel = inputDefinition.label;
            const warningSign =
                !hasSameValue[inputName] && definitionLabel !== "" ? (
                    <b style={{ transition: "opacity 0.1s", color: "red" }}>
                        *
                    </b>
                ) : (
                    ""
                );
            const label =
                definitionLabel == null ? (
                    inputName
                ) : (
                    <p
                        style={{ marginBottom: 0 }}
                        title={
                            hasSameValue[inputName]
                                ? ""
                                : "Selected widgets have different values in this field"
                        }
                    >
                        {definitionLabel}
                        {warningSign}
                    </p>
                );

            if (inputDefinition.type === "number") {
                const automaticResizeValue = inputs["automaticResize"] as string;
                let isDisabled = false;
                if(widgetType === 'LABEL') {
                    if(inputDefinition?.dependsOn === "automaticResize"){
                        isDisabled = !(inputDefinitions["automaticResize"]?.default === automaticResizeValue);
                    }
                }
                const value = inputs[inputName] as number;
                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td>
                            <NumericInput
                                className="form-control"
                                value={value}
                                onChange={(inputValue) =>
                                    this.props.onChange([inputName], inputValue)
                                }
                                isDisabled={isDisabled}
                            />
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "radio") {
                const value = inputs[inputName];
                return (
                    <tr key={inputName}>
                        <td className="w-50">{label}</td>
                        <td className="w-50">
                            <input
                                type="radio"
                                checked={value}
                                onChange={(e) =>
                                    this.props.onChange(
                                        [inputName],
                                        e.target.checked
                                    )
                                }
                            />
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "boolean") {
                const value = inputs[inputName] as boolean;
                return (
                    <tr key={inputName}>
                        <td className="w-50">{label}</td>
                        <td className="w-50">
                            <input
                                type="checkbox"
                                checked={value}
                                onChange={(e) =>
                                    this.props.onChange(
                                        [inputName],
                                        e.target.checked
                                    )
                                }
                            />
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "style") {
                const value = inputs[inputName] as string;
                return (
                    <React.Fragment key={inputName}>
                        <tr>
                            <td colSpan={2}>
                                <StyleSelector
                                    label={label}
                                    value={value}
                                    onChange={(parsedCss: string) =>
                                        this.props.onChange(
                                            [inputName],
                                            parsedCss
                                        )
                                    }
                                />
                            </td>
                        </tr>
                    </React.Fragment>
                );
            } else if (inputDefinition.type === "string") {
                let value, isDisabled = false;                
                if(inputDefinition.dependsOn === "svgFile") {     
                    isDisabled = true;
                    const svg = this.props.widgets[0].inputs.svgFile
                    value = svg !== undefined ? JSON.parse(svg).fileName : "None";
                }
                else {
                    value = inputs[inputName] as string
                }

                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td>
                            <input
                                className="form-control"
                                type="text"
                                value={value}
                                placeholder={inputDefinition.placeholder || ""}
                                onChange={(e) =>
                                    this.props.onChange(
                                        [inputName],
                                        e.target.value
                                    )
                                }
                                disabled={isDisabled}
                            />
                        </td>
                        {inputDefinition.dependsOn === "svgFile" && this.props.widgets[0].inputs.svgFile !== undefined &&
                            <td>
                                <button
                                    title={`Export file '${value}'`}
                                    className="btn-upload btn taranta-btn btn-secondary btn-sm"
                                    onClick = {() => {
                                        const svg = this.props.widgets[0].inputs.svgFile
                                        const blob = new Blob([JSON.parse(svg).fileContent], { type: 'image/svg+xml' });
                                        saveAs(blob, JSON.parse(svg).fileName);}}
                                    >
                                        <FontAwesomeIcon icon="arrow-alt-circle-down" />
                                </button>
                            </td>
                        } 
                    </tr>
                );
            } else if (inputDefinition.type === "color") {
                const value = inputs[inputName] as string;
                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td>
                            <input
                                type="color"
                                value={value}
                                onChange={(e) =>
                                    this.props.onChange(
                                        [inputName],
                                        e.target.value
                                    )
                                }
                            />
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "multipleselection") {
                let value = inputs[inputName];
                const options: any[] = inputDefinition.options ? inputDefinition.options : [];

                //Populate the option with devices if not passed from widget
                if (undefined === inputDefinition.options) {
                    this.props?.devices?.forEach(device => {
                        options.push({ value: device, label: device })
                    });
                }

                // Math.random() is used because if it's the same key
                // select will not always render multiple selected options
                return (
                    <tr key={Math.random()}>
                        <td colSpan={2}>
                            {label}
                            <Select
                                components={animatedComponents}
                                closeMenuOnSelect={false}
                                value={value}
                                onChange={(selected) =>
                                    this.props.onChange(
                                        [inputName],
                                        selected
                                    )}
                                options={options}
                                isMulti
                            />
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "attribute") {
                const constantDevice = inputDefinition.device != null;
                const constantAttribute = inputDefinition.attribute != null;

                if (constantDevice && constantAttribute) {
                    return null;
                }

                const value = inputs[inputName] as {
                    device: string;
                    attribute: string;
                    label: string;
                };

                if (constantDevice) {
                    return (
                        <tr key={inputName}>
                            <td colSpan={2}>
                                {label}
                                <input
                                    type="text"
                                    className="form-control"
                                    value={value.label}
                                    onChange={(event) =>
                                        this.props.onChange([inputName], {
                                            device: null,
                                            attribute: event.target.value,
                                        })
                                    }
                                />
                            </td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={inputName}>
                            <td colSpan={2}>
                                {label}
                                <AttributeSelect
                                    widgetId={this.props.inputId}
                                    nonEditable={nonEditable}
                                    tangoDB={tangoDB}
                                    device={this.props.widgetDevices ? this.props.widgetDevices : value.device}
                                    hideDeviceSuggester={this.props.widgetDevices ? true : false}
                                    attribute={value.attribute}
                                    label={value.label}
                                    dataFormat={inputDefinition.dataFormat}
                                    dataType={inputDefinition.dataType}
                                    onSelect={(device, attribute, label) =>
                                        this.props.onChange([inputName], {
                                            device,
                                            attribute,
                                            label
                                        })
                                    }
                                    variables={this.props.variables}
                                />
                            </td>
                        </tr>
                    );
                }
            } else if (inputDefinition.type === "device") {
                const value = inputs[inputName] as string;
                return (
                    <tr key={inputName}>
                        {definitionLabel !== '' &&
                            <td>{label}</td>
                        }
                        <td colSpan={definitionLabel !== '' ? 1 : 2}>
                            <DeviceSelect
                                widgetId={this.props.inputId}
                                nonEditable={nonEditable}
                                tangoDB={this.props.tangoDB}
                                device={value}
                                onSelect={(device) =>
                                    this.props.onChange([inputName], device)
                                }
                            />
                        </td>
                    </tr>
                );
            } else if (
                inputDefinition.type === "complex" &&
                inputDefinition.repeat === true
            ) {
                let value = inputs[inputName] as InputMapping[];
                let widgetDevices = '';
                if ( 'TABULAR_VIEW' === this.props?.widgetType) {
                    const devices = this.props?.inputs?.devices?.map(obj => obj.device);
                    widgetDevices = devices ? devices.toString() : ''
                    // Filter default attributes from list
                    value = value.filter(each => !(each?.attribute && each?.attribute?.hideList))
                }

                return (
                    <Fragment key={inputName}>
                        <tr>
                            <td>{label}</td>
                            <td>
                                <button
                                    className="btn btn-outline-dark"
                                    type="button"
                                    onClick={() => {
                                        // Doesn't support more than one degree of nesting
                                        this.props.onAdd([inputName]);
                                    }}
                                >
                                    <span className="fa fa-plus" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {/* Render list created using '+' button */}
                                {value.map((each, j) => (
                                    <div className="ComplexInput" key={j}>
                                        <button
                                            className="btn"
                                            style={{
                                                position: "absolute",
                                                right: "0em",
                                                top: "0em",
                                                padding: 0,
                                                zIndex: 2,
                                                boxShadow: "none",
                                            }}
                                            onClick={() =>
                                                this.props.onDelete([
                                                    inputName,
                                                    j,
                                                ])
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimesCircle}
                                            ></FontAwesomeIcon>
                                        </button>
                                        <InputList
                                            inputId={`${widgets[0].id}_${j}}`}
                                            tangoDB={tangoDB}
                                            inputDefinitions={
                                                inputDefinition.inputs
                                            }
                                            inputs={each}
                                            widgets={[]}
                                            onChange={(path2, value2) => {
                                                this.props.onChange(
                                                    [inputName, j, ...path2],
                                                    value2
                                                );
                                            }}
                                            onDelete={(path2) =>
                                                this.props.onDelete([
                                                    inputName,
                                                    j,
                                                    ...path2,
                                                ])
                                            }
                                            nonEditable={nonEditable}
                                            onAdd={(path) => null /* ??? */}
                                            variables={this.props.variables}
                                            widgetDevices={widgetDevices}
                                        />
                                    </div>
                                ))}
                            </td>
                        </tr>
                    </Fragment>
                );
            } else if (inputDefinition.type === "select") {
                const value = inputs[inputName] as string[];
                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td>
                            <select
                                className="form-control"
                                value={value}
                                onChange={(e) =>
                                    this.props.onChange(
                                        [inputName],
                                        e.currentTarget.value
                                    )
                                }
                            >
                                {inputDefinition.options.map((option, j) => (
                                    <option key={j} value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "datePicker") {
                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td className="date-picker">
                            <Datetime
                                className=""
                                input={true}
                                timeFormat={inputDefinition.hideTime ? false : inputDefinition.timeFormat || "hh:mm:ss"}
                                dateFormat={inputDefinition.dateFormat || "MM-DD-YYYY"}
                                value={(inputs[inputName] || inputDefinition.value) ? new Date(inputs[inputName] || inputDefinition.value) : ""}
                                closeOnSelect={true}
                                onChange={(e) => {
                                    const momentObj = moment(e);
                                    this.props.onChange(
                                        [inputName],
                                        momentObj.toDate()
                                    )
                                }}
                            />
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "variable") {
                const value = inputs[inputName] as string[];
                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td>
                            <select
                                className="form-control"
                                value={value}
                                onChange={(e) =>
                                    this.props.onChange(
                                        [inputName],
                                        e.currentTarget.value
                                    )
                                }
                            >
                                <option key="null" value=""> - select a variable - </option>
                                {variables.length === 0 &&
                                    <option disabled>
                                        No variables defined
                                    </option>
                                }
                                {variables.length > 0 &&
                                    variables.map((variable, j) => (
                                        <option key={j} value={variable.name}>
                                            {variable.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "file") {
                return (
                    <tr key={inputName}>
                        <td>{label}</td>
                        <td>
                            <FileInput
                                    className="form-control"
                                    onChange={(fileContent, fileName) => {                        
                                            this.props.onChange([inputName], JSON.stringify({fileContent, fileName}))
                                        }
                                    }
                                    accepted={[inputDefinition.fileAccepted]}
                                />
                            
                        </td>
                    </tr>
                );
            } else if (inputDefinition.type === "command") {
                const value = inputs[inputName] as {
                    device: string;
                    command: string;
                    acceptedType: string;
                };


                const constantDevice = inputDefinition.device != null;
                const constantCommand = inputDefinition.command != null;

                if (constantDevice && constantCommand) {
                    return null;
                }


                if (constantDevice) {
                    return (
                        <tr key={inputName}>
                            <td>{label}</td>
                            <td>
                                <input
                                    type="text"
                                    value={value.command}
                                    onChange={(e) =>
                                        this.props.onChange([inputName], {
                                            device: null,
                                            command: e.target.value,
                                            acceptedType: e.target.value,
                                        })
                                    }
                                />
                            </td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={inputName}>
                            <td colSpan={2}>
                                {label}
                                <CommandSelect
                                    widgetId={this.props.inputId}
                                    nonEditable={nonEditable}
                                    device={value.device}
                                    command={value.command}
                                    inputType={inputDefinition.intype}
                                    selectMultipleCommands={
                                        widgetType === "CONTAINER_FOR_DEVICE"
                                    }
                                    onSelect={(
                                        device,
                                        command,
                                        acceptedType,
                                        intypedesc,
                                        outtypedesc,
                                        outtype,
                                        tag
                                    ) =>
                                        this.props.onChange([inputName], {
                                            device,
                                            command,
                                            acceptedType,
                                            intypedesc,
                                            outtypedesc,
                                            outtype,
                                            tag
                                        })
                                    }
                                    variables={this.props.variables}
                                />
                            </td>
                        </tr>
                    );
                }
            }

            return (
                <tr key={inputName}>
                    <td colSpan={2}>
                        {label}
                        <pre>{JSON.stringify(inputDefinition)}</pre>
                    </td>
                </tr>
            );
        });

        const hasInputs = !!inputControls.find((control) => control !== null);
        const inner = hasInputs ? (
            inputControls
        ) : (
            <div>There are no configurable inputs for this widget.</div>
        );

        return (
            <div>
                <table style={{ width: "100%" }}>
                    <tbody>{inner}</tbody>
                </table>
                {widgets.length > 1 ? (
                    <div>
                        <Alert variant="info" style={{ padding: "6px 20px" }}>
                            {widgets.length} widgets are selected
                        </Alert>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        devices: getDeviceNames(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputList);
