import {
  UNDO,
  REDO,
  SELECT_CANVAS,
  TOGGLE_MODE,
  SYNOPTIC_LOADED,
  LOAD_SYNOPTICS,
  SYNOPTICS_LOADED,
  SYNOPTIC_EXPORTED,
  SYNOPTIC_IMPORTED,
  SYNOPTIC_RENAMED,
  SYNOPTIC_DELETED,
  SYNOPTIC_CLONED,
  LOAD_SYNOPTIC,
  EXPORT_SYNOPTIC,
  IMPORT_SYNOPTIC,
  RENAME_SYNOPTIC,
  DELETE_SYNOPTIC,
  CLONE_SYNOPTIC,
  SAVE_SYNOPTIC,
  SYNOPTIC_SAVED,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SHARE_SYNOPTIC,
  SYNOPTIC_SHARED,
  SYNOPTIC_EDITED,
} from "./actionTypes";

import {
  UndoAction,
  RedoAction,
  SelectCanvasAction,
  ToggleModeAction,
  SynopticLoadedAction,
  SynopticRenamedAction,
  SaveSynopticAction,
  SynopticSavedAction,
  RenameSynopticAction,
  CloneSynopticAction,
  synopticEditedAction,
  SynopticExportedAction,
  SynopticImportedAction,
} from "./actions";

import { Synoptic, SelectedSynoptic, Variable } from "../types";
import { SelectedSynopticState } from "./reducers/selectedSynoptic";

import { NotificationLevel } from "../../shared/notifications/notifications";

export function undo(): UndoAction {
  return { type: UNDO };
}

export function redo(): RedoAction {
  return { type: REDO };
}

export function selectCanvas(id: string): SelectCanvasAction {
  return { type: SELECT_CANVAS, id };
}

export function toggleMode(): ToggleModeAction {
  return { type: TOGGLE_MODE };
}

export function loadSynoptics() {
  return { type: LOAD_SYNOPTICS };
}

export function synopticsLoaded(synoptics: Synoptic[]) {
  return { type: SYNOPTICS_LOADED, synoptics };
}

export function renameSynoptic(id: string, name: string): RenameSynopticAction {
  return { type: RENAME_SYNOPTIC, id, name };
}
export function synopticRenamed(
  id: string,
  name: string
): SynopticRenamedAction {
  return { type: SYNOPTIC_RENAMED, id, name };
}
export function deleteSynoptic(id: string) {
  return { type: DELETE_SYNOPTIC, id };
}
export function synopticDeleted(id: string) {
  return { type: SYNOPTIC_DELETED, id };
}
export function shareSynoptic(
  id: string,
  group: string,
  groupWriteAccess: boolean
) {
  return { type: SHARE_SYNOPTIC, id, group, groupWriteAccess };
}
export function synopticShared(
  id: string,
  group: string,
  groupWriteAccess: boolean
) {
  return { type: SYNOPTIC_SHARED, id, group, groupWriteAccess };
}
export function cloneSynoptic(
  id: string,
  newUser: string
): CloneSynopticAction {
  return { type: CLONE_SYNOPTIC, id, newUser };
}
export function synopticCloned(id: string) {
  return { type: SYNOPTIC_CLONED, id };
}
export function loadSynoptic(id: string) {
  return { type: LOAD_SYNOPTIC, id };
}
export function exportSynoptic(id: string) {
  return { type: EXPORT_SYNOPTIC, id };
}
export function importSynoptic(content: any) {
  return { type: IMPORT_SYNOPTIC, content };
}
export function synopticLoaded(
  synoptic: SelectedSynoptic
): SynopticLoadedAction {
  return { type: SYNOPTIC_LOADED, synoptic };
}
export function synopticExported(synoptic: Synoptic): SynopticExportedAction {
  return { type: SYNOPTIC_EXPORTED, synoptic };
}
export function synopticImported(synoptic: Synoptic): SynopticImportedAction {
  return { type: SYNOPTIC_IMPORTED, synoptic };
}
export function synopticSaved(
  id: string,
  created: boolean,
  name: string,
  variables: Variable[]
): SynopticSavedAction {
  return { type: SYNOPTIC_SAVED, id, created, name, variables };
}
export function saveSynoptic(
  id: string,
  name: string,
  variables: Variable[]
): SaveSynopticAction {
  return { type: SAVE_SYNOPTIC, id, name, variables };
}
export function synopticEdited(
  synoptic: SelectedSynopticState
): synopticEditedAction {
  return { type: SYNOPTIC_EDITED, synoptic };
}
export function showNotification(
  level: NotificationLevel,
  action: string,
  msg: string,
  duration: number = 2000
) {
  return {
    type: SHOW_NOTIFICATION,
    notification: { level, action, msg, duration },
  };
}
export function hideNotification() {
  return { type: HIDE_NOTIFICATION };
}
