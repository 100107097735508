import React, { CSSProperties, useState } from "react";
import {
  WidgetDefinition,
  SelectInputDefinition,
  StyleInputDefinition,
  BooleanInputDefinition,
  StringInputDefinition,
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { WidgetProps } from "../types";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import './DashboardLink.css';

type Inputs = {
  DefaultDashboard: SelectInputDefinition;
  NewTab: BooleanInputDefinition;
  HideDropdown: BooleanInputDefinition;
  ButtonText: StringInputDefinition;
  DropDownCss: StyleInputDefinition;
  ButtonCss: StyleInputDefinition;
  CustomCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

let definition: WidgetDefinition<Inputs> = {
  type: "DASHLINK",
  name: "Dashboard Link",
  defaultHeight: 2,
  defaultWidth: 10,
  inputs: {
    DefaultDashboard: {
      type: "select",
      default: "",
      label: "Dashboard default option",
      options: [],
    },
    HideDropdown: {
      type: "boolean",
      label: "Hide dropdown selection",
      default: false
    },
    NewTab: {
      type: "boolean",
      label: "Open on a new tab",
      default: false
    },
    ButtonText: {
      type: "string",
      label: "Text for button",
      default: "OPEN"
    },
    DropDownCss: {
      type: "style",
      default: "",
      label: "Custom dropdown css (advanced)",
    },
    ButtonCss: {
      type: "style",
      default: "",
      label: "Custom button css (advanced)",
    },
    CustomCss: {
      type: "style",
      default: "",
      label: "Custom widget css (advanced)",
    },
  },
};

const DashboardLink = (props: Props) => {
  const { inputs, mode } = props;
  const {
    DefaultDashboard,
    HideDropdown,
    NewTab,
    ButtonText,
    DropDownCss,
    ButtonCss,
    CustomCss,
  } = inputs;

  const parsedDropDownCss = parseCss(DropDownCss).data;
  const parsedButtonCss = parseCss(ButtonCss).data;
  const parsedCustomCss = parseCss(CustomCss).data;

  const [selectedDashboard, setSelectedDashboard] = useState("");

  const currentDashboards = useSelector((state: IRootState) => state.dashboards?.dashboards);
  const options = currentDashboards.map(dashboard => ({
    name: dashboard.name,
    value: JSON.stringify({ name: dashboard.name, id: dashboard.id }),
  }));

  let myDashboardName = "";
  let myDashboardID = "";
  if (selectedDashboard !== "") {
    myDashboardName = selectedDashboard;
    myDashboardID = currentDashboards.filter(dashboard => dashboard.name === myDashboardName)?.[0]?.id;
  }
  else {
    try {
      myDashboardName = JSON.parse(DefaultDashboard)?.name;
      myDashboardID = JSON.parse(DefaultDashboard)?.id;
    } catch (error) { }
  }

  definition.inputs.DefaultDashboard.options = options;

  // In case the current assigned dashboard as changed is name but ID still remains
  const found = currentDashboards.filter(dashboard => dashboard.name === myDashboardName);
  if (found.length === 0) {
    const searchDashboard = currentDashboards.filter(dashboard => dashboard.id === myDashboardID);
    if (searchDashboard.length > 0) {
      myDashboardName = searchDashboard?.[0]?.name;
    }
  }

  const style: CSSProperties = {
    display: "flex",
    ...parsedCustomCss
  };

  return (
    <div style={{ padding: mode === "library" ? "5px " : "", ...style }}>
      {
        !HideDropdown && <select
          className="form-control"
          style={{ marginTop: "0px", ...parsedDropDownCss }}
          value={myDashboardName}
          onChange={e => { setSelectedDashboard(e.target.value) }}
          disabled={mode === "run" ? false : true}
          title="Select the dashboard you want to open"
        >
          {mode === "library" ?
            <option key="Dashboard 1" value="Dashboard 1">
              Dashboard 1
            </option> :
            options?.length === 0 ? (
              <option key="" disabled value="">
                No dashboards found
              </option>
            ) : options?.length > 0 &&
            options?.map(dashboard => {
              return <option key={dashboard.name}>{dashboard.name}</option>;
            })
          }
        </select>
      }
      <button
        className="btn btn-outline-secondary btn-sm ButtonLink"
        type="button"
        disabled={mode === "run" ? false : true}
        style={{ height: "auto", whiteSpace: "nowrap", overflow: "visible", ...parsedButtonCss }}
        title="Click to open a new dashboard"
        onClick={() => {
          const myID = currentDashboards.filter(dashboard => dashboard.name === myDashboardName)?.[0]?.id;
          if (myID) {
            if (NewTab) window.open("dashboard?id=" + myID + "&mode=run", "_blank");
            else window.location.href = "dashboard?id=" + myID + "&mode=run";
          }
        }}
      >{ButtonText}</button>
    </div>
  );
};


const DashboardLinkExport = { component: DashboardLink, definition };
export default DashboardLinkExport;
