import React, { useState } from "react";
import ModeToggleButton from "./ModeToggleButton";
import DashboardTitle from "./DashboardTitle";
import { DeviceConsumer } from "./DevicesProvider";
import { Navbar } from "../../shared/ui/navbar/Navbar";

interface Props {
  mode: "edit" | "run";
  onToggleMode: () => void;
  modeToggleDisabled: boolean;
}

function FetchInfo() {
  return (
    <span className="FetchInfo">
      <DeviceConsumer>
        {({ error, fetching }) =>
          fetching ? "Fetching devices…" : error ? "" : null
        }
      </DeviceConsumer>
    </span>
  );
}

const TopBar: React.FC<Props> = (props: Props) => {
  const { mode, onToggleMode, modeToggleDisabled } = props;
  const [timestamp, setTimestamp] = useState<string>('');

  const onClick = () => {
    if(mode==='edit') setTimestamp(new Date().toString());
    else setTimestamp('');
    onToggleMode();
  }

  return (
    <Navbar>
      <div
        style={{ width: "100%", padding: "0 1em" }}
      >
        <form className="form-inline" style={{ display: "inline-block" }}>
          <ModeToggleButton
            title={timestamp}
            onClick={onClick}
            disabled={modeToggleDisabled}
            mode={mode}
          />
        </form>
        <DashboardTitle />
        <FetchInfo />
      </div>
    </Navbar>
  );
};

export const MemoTopBar = React.memo(TopBar);