import React, { Component } from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";

import SynopticDisplay from "../SynopticDisplay/SynopticDisplay";
import {
  saveSynoptic,
  loadSynoptic,
  loadSynoptics,
  undo,
  redo,
  toggleMode,
} from "../../state/actionCreators";
import LoginDialog from "../../../shared/user/components/LoginDialog/LoginDialog";
import { getDeviceIsLoading } from "../../../shared/state/selectors/loadingStatus";
import Spinner from "../../../shared/components/Spinner/Spinner";

import {
  getMode,
  getCanvases,
  getSelectedCanvas,
  getSynoptics,
  getSelectedSynoptic,
  getNotification,
} from "../../state/selectors";

import { Notification as SynopticNotification } from "../../types";

import { Canvas, Synoptic as SynopticInterface, Variable } from "../../types";

import { IRootState } from "../../../shared/state/reducers/rootReducer";

import "./Synoptic.css";
import Sidebar from "../Sidebar";
import TopBar from "../TopBar";
import {
  getIsLoggedIn,
  getUsername,
} from "../../../shared/user/state/selectors";
import {
  TOGGLE_INSPECTOR_COLLAPSED,
  TOGGLE_LIBRARY_COLLAPSED,
} from "../../../shared/state/actions/actionTypes";

import { saveNotification } from "../../../shared/user/state/actionCreators";
import { Notification } from "../../../shared/notifications/notifications";
import { fetchDeviceNames } from "../../../shared/state/actions/tango";
import { generateUUID } from "../../../shared/utils/generateUUID";

interface Match {
  tangoDB: string;
}

interface Props extends RouteComponentProps<Match> {
  toggleMode: () => void;
  loadSynoptic: (id: string) => void;
  loadSynoptics: () => void;
  saveSynoptic: (id: string, name: string, variables: Variable[]) => void;
  mode: "edit" | "run";
  canvases: Canvas[];
  selectedCanvas: Canvas;
  synoptics: SynopticInterface[];
  selectedSynoptic: SynopticInterface;
  isLoggedIn: boolean;
  onUndo: () => void;
  onRedo: () => void;
  toggleInspectorCollapse: () => void;
  toggleLibraryCollapse: () => void;
  synopticNotification: SynopticNotification;
  username: string;
  onSaveNotification: (notification: Notification, username: string) => void;
  loadDeviceNames: (tangoDB: string) => void;
  loading: boolean;
}

class Synoptic extends Component<Props> {
  public constructor(props) {
    super(props);
    this.toggleMode = this.toggleMode.bind(this);
  }

  public async componentDidMount() {
    const { tangoDB } = this.props.match.params;
    this.props.loadDeviceNames(tangoDB);
    const { id, mode } = this.parseUrlQuery();
    this.props.loadSynoptics();
    if (id) {
      //TODO Load the choosen synoptic
      this.props.loadSynoptic(id);
    }
    if (mode && mode !== this.props.mode) {
      this.props.toggleMode();
    }
  }

  public async componentDidUpdate(prevProps) {
    const { id: currentId, name } = this.props.selectedSynoptic;
    const { name: oldName } = prevProps;
    const { id, mode } = this.parseUrlQuery();
    if (currentId !== id) {
      if (currentId) {
        this.props.history.replace(
          "?id=" + currentId + (mode ? "&mode=" + mode : "")
        );
      } else {
        this.props.history.replace("?" + (mode ? "mode=" + mode : ""));
      }
    }
    if (name && name !== oldName) {
      document.title = name + " - Taranta";
    }
  }
  public editCanvasHotKeyHandler = (event) => {
    if (this.props.mode !== "edit") {
      return;
    }
    //non-os specific hotkeys:
    if (event.altKey === true && event.key === "l") {
      this.props.toggleLibraryCollapse();
      event.preventDefault();
      return;
    }

    switch (window.navigator.platform) {
      case "MacIntel":
        //cmd+shift+z
        if (
          event.metaKey === true &&
          event.shiftKey === true &&
          event.key === "z"
        ) {
          this.props.onRedo();
          event.preventDefault();
        }
        //cmd+z
        else if (event.metaKey === true && event.key === "z") {
          this.props.onUndo();
          event.preventDefault();
        }
        break;
      default:
        //ctrl+y
        if (event.ctrlKey === true && event.key === "y") {
          this.props.onRedo();
          event.preventDefault();
        }
        //ctrl+z
        else if (event.ctrlKey === true && event.key === "z") {
          this.props.onUndo();
          event.preventDefault();
        }
    }
  };
  public render() {
    const { mode } = this.props;
    const { tangoDB } = this.props.match.params;
    const disabled = !this.isRootCanvas() || !this.allVarsPresent();

    if (this.props.loading) {
      return <Spinner size={4} />;
    }

    // Display notifications coming from synoptics
    // Ideally there should be only one notification structure
    // This should be refactored TODO
    if (
      this.props.synopticNotification.level !== "Undefined" &&
      this.props.username
    ) {
      const notification: Notification = {
        username: this.props.username,
        level: this.props.synopticNotification.level,
        message: this.props.synopticNotification.msg,
        notified: false,
        timestamp: Date.now().toString(),
        key: generateUUID(),
      };

      this.props.onSaveNotification(notification, this.props.username);
    }

    return (
      <div className="Synoptic">
        <LoginDialog />
        <TopBar
          mode={mode}
          onToggleMode={this.toggleMode}
          modeToggleDisabled={disabled}
        />
        <SynopticDisplay mode={mode} />
        <Sidebar mode={mode} selectedTab="synoptics" tangoDB={tangoDB} />
      </div>
    );
  }

  private getSynopticVariableNames() {
    let variables: string[] = [];
    const selectedSynopticId = this.props.selectedSynoptic.id;

    for (let i = 0; i < this.props.synoptics.length; i++) {
      //Match the synoptic
      const synoptic = this.props.synoptics[i];
      if (
        synoptic.id === selectedSynopticId &&
        undefined !== synoptic.variables
      ) {
        for (let j = 0; j < synoptic.variables.length; j++) {
          variables.push(synoptic.variables[j].name);
        }
      }
    }

    return variables;
  }

  private toggleMode() {
    const { mode, selectedSynoptic } = this.props;
    const { id } = selectedSynoptic;
    this.props.history.replace(
      "?id=" + id + (mode === "edit" ? "&mode=run" : "")
    );
    this.props.toggleMode();
  }
  private isRootCanvas() {
    return this.props.selectedCanvas.id === "0";
  }

  private allVarsPresent() {
    if (this.props.mode === "run") return true;
    if (this.props.selectedSynoptic) {
      return true;
    } else return false;
  }

  private parseUrlQuery(): { id: string; mode: String } {
    /* eslint-disable no-restricted-globals */
    const search = location.search;
    const parsed = queryString.parse(search);
    return { id: parsed.id || "", mode: parsed.mode || "" };
  }
}

function mapStateToProps(state: IRootState) {
  return {
    selectedSynoptic: getSelectedSynoptic(state),
    synoptics: getSynoptics(state),
    mode: getMode(state),
    selectedCanvas: getSelectedCanvas(state),
    canvases: getCanvases(state),
    isLoggedIn: getIsLoggedIn(state),
    synopticNotification: getNotification(state),
    username: getUsername(state),
    loading: getDeviceIsLoading(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveSynoptic: (id: string, name: string, variables: Variable[]) =>
      dispatch(saveSynoptic(id, name, variables)),
    toggleMode: () => dispatch(toggleMode()),
    loadSynoptic: (id: string) => dispatch(loadSynoptic(id)),
    loadSynoptics: () => dispatch(loadSynoptics()),
    onUndo: () => dispatch(undo()),
    onRedo: () => dispatch(redo()),
    toggleInspectorCollapse: () =>
      dispatch({ type: TOGGLE_INSPECTOR_COLLAPSED }),
    toggleLibraryCollapse: () => dispatch({ type: TOGGLE_LIBRARY_COLLAPSED }),
    onSaveNotification: (notification: Notification, username: string) =>
      dispatch(saveNotification(notification, username)),
    loadDeviceNames: (tangoDB: string) => dispatch(fetchDeviceNames(tangoDB)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(Synoptic));
