import {
    FETCH_ATTRIBUTES_SUCCESS
  } from "../actions/actionTypes";
  import JiveAction from "../actions";

  interface Attribute {
    name: string;
    label: string;
    dataformat: string;
    datatype: string;
  }
  
  export interface IAttributeListState {
    attributeList: Attribute[];
  }
  
  const initialState = {
    attributeList: []
  };
  
  export default function attributesList(
    state: IAttributeListState = initialState,
    action: JiveAction
  ): IAttributeListState {
    switch (action.type) {
      case FETCH_ATTRIBUTES_SUCCESS: {
        return { ...state, attributeList: action.attributes };
      }
      default:
        return state;
    }
  }
  