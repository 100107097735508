import React, { Component } from "react";
import WarningBadge from "./WarningBadge";
import { useDrop } from 'react-dnd';
import dndTypes from "../../dndTypes";
import boxIntersect from "box-intersect";
import { WIDGET_VALID } from "../../../shared/state/actions/actionTypes";

/* Resizing was difficult/impractical to implement with React features and react-dnd, so
   an approach using vanilla JavaScript event listeners was used instead. Subject to change
   at a later stage if a better solution is found. */

class ResizeArea extends Component {
  render() {
    const { location } = this.props;
    const cursorPrefix =
      location === "nw" || location === "se"
        ? "nwse"
        : location === "ne" || location === "sw"
          ? "nesw"
          : location === "n" || location === "s"
            ? "ns"
            : "ew";

    const stuckToTop = !location.includes("s");
    const stuckToBottom = !location.includes("n");
    const stuckToLeft = !location.includes("e");
    const stuckToRight = !location.includes("w");

    const topStyle = stuckToTop ? { top: 0 } : {};
    const bottomStyle = stuckToBottom ? { bottom: 0 } : {};
    const verticalStyle = { ...topStyle, ...bottomStyle };

    const leftStyle = stuckToLeft ? { left: 0 } : {};
    const rightStyle = stuckToRight ? { right: 0 } : {};
    const horizontalStyle = { ...leftStyle, ...rightStyle };

    const size = 16;

    const isVerticalEdge = location === "n" || location === "s";
    const isHorizontalEdge = location === "e" || location === "w";

    const horizontalFactor = isVerticalEdge ? 1 : -1;
    const verticalFactor = isHorizontalEdge ? 1 : -1;
    const horizontalMargin = (horizontalFactor * size) / 2;
    const verticalMargin = (verticalFactor * size) / 2;

    const locationStyle = {
      ...horizontalStyle,
      ...verticalStyle,
      marginTop: verticalMargin,
      marginBottom: verticalMargin,
      marginLeft: horizontalMargin,
      marginRight: horizontalMargin
    };

    const width = isVerticalEdge ? "auto" : size;
    const height = isHorizontalEdge ? "auto" : size;

    return (
      <div
        className="ResizeArea"
        onDrag={
          () => false /* Necessary to prevent default behaviour of dragging */
        }
        onMouseDown={event => {
          event.stopPropagation();
          this.props.onBeginResize(location, event.screenX, event.screenY);
        }}
        style={{
          position: "absolute",
          ...locationStyle,
          zIndex: 1,
          cursor: `${cursorPrefix}-resize`,
          width,
          height
        }}
      />
    );
  }
}

export default class EditWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizingLocation: null,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0
    };
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseMove(event) {    
    const { screenX: currentX, screenY: currentY } = event;
    this.setState({ currentX, currentY });
  }

  handleMouseUp() {
    const [diffX, diffY] = this.sizeDifference();
    const [adjustX, adjustY] = this.positionAdjustmentForOngoingResize();
    this.props.onResize(adjustX, adjustY, diffX, diffY);

    this.setState({ resizingLocation: null });
    document.removeEventListener("mouseup", this.handleMouseUp);
    document.removeEventListener("mousemove", this.handleMouseMove);
  }

  handleBeginResize(areaLocation, startX, startY) {
    const [currentX, currentY] = [startX, startY];
    this.setState({
      resizingLocation: areaLocation,
      startX,
      startY,
      currentX,
      currentY
    });
    document.addEventListener("mouseup", this.handleMouseUp);
    document.addEventListener("mousemove", this.handleMouseMove);
  }

  sizeFactors() {
    const { resizingLocation } = this.state;

    if (resizingLocation == null) {
      return [0, 0];
    } else if (resizingLocation.length === 2) {
      const [vertical, horizontal] = resizingLocation;
      const factorX = horizontal === "w" ? -1 : 1;
      const factorY = vertical === "n" ? -1 : 1;
      return [factorX, factorY];
    } else {
      return resizingLocation === "e"
        ? [1, 0]
        : resizingLocation === "w"
          ? [-1, 0]
          : resizingLocation === "s"
            ? [0, 1]
            : [0, -1];
    }
  }

  sizeDifference() {
    const [factorX, factorY] = this.sizeFactors();
    const { currentX, currentY, startX, startY } = this.state;
    const diffX = factorX * (currentX - startX);
    const diffY = factorY * (currentY - startY);
    return [diffX, diffY];
  }

  positionAdjustmentForOngoingResize() {
    const { resizingLocation } = this.state;

    if (resizingLocation == null) {
      return [0, 0];
    }

    const [diffX, diffY] = this.sizeDifference();

    if (resizingLocation.length === 2) {
      const [vertical, horizontal] = resizingLocation;
      const adjustX = horizontal === "w" ? -diffX : 0;
      const adjustY = vertical === "n" ? -diffY : 0;
      return [adjustX, adjustY];
    } else {
      const adjustX = resizingLocation === "w" ? -diffX : 0;
      const adjustY = resizingLocation === "n" ? -diffY : 0;
      return [adjustX, adjustY];
    }
  }

  render() {
    const { width, height, canResize, order, x, y, isSelected, type, canMove } = this.props;
    let resizeAreas = null;
    let [adjustX, adjustY] = [0, 0];
    let [diffX, diffY] = [0, 0];

    if (!this.props.isDragging) {
      resizeAreas =
        canResize === false
          ? []
          : ["nw", "ne", "sw", "se", "w", "e", "s", "n"].map(areaLocation => (
            <ResizeArea
              key={areaLocation}
              location={areaLocation}
              onBeginResize={(areaLocation, x, y) => {
                this.handleBeginResize(areaLocation, x, y);
              }}
            />
          ));
      [adjustX, adjustY] = this.positionAdjustmentForOngoingResize();
      [diffX, diffY] = this.sizeDifference();
    }

    const actualWidth = width + diffX - 1;
    const actualHeight = height + diffY - 1;
    const render = React.cloneElement(this.props.render, {
      actualWidth,
      actualHeight
    });

    let renderInside = <div></div>;
    if ('BOX' === type) {
      renderInside =
        <div className="header-div" style={{
          height: "100%",
          width: "100%",
          backgroundColor: "white"
        }}>
          <div style={{
            minHeight: "30px",
            backgroundColor: "lightgray",
            cursor: "move"
          }}
            onMouseDown={event => {
              if(canMove) {
                event.stopPropagation();
                this.props.onMouseDown(event);
              }
            }}
          >
          </div>
          <div
            style={{
              overflow: "hidden",
              width: "inherit",
              backgroundColor: "white",
              pointerEvents: "none",
              height: "calc(100% - 30px)",
            }}
          >
            {render}
          </div>
        </div>
    }
    else
      renderInside =
        <div className="header-div" style={{
          height: "100%",
          width: "100%"
        }}>
          <div
            style={{
              overflow: "hidden",
              width: "inherit",
              backgroundColor: "white",
              pointerEvents: "none",
              height: "100%",
            }}
          >
            {render}
          </div>
        </div>
    return (
      <div
        className={isSelected ? "Widget selected" : "Widget"}
        style={{
          left: x + adjustX,
          top: y + adjustY,
          width: actualWidth,
          height: actualHeight,
          outline: isSelected ? "2px solid lightskyblue" : "1px solid #ccc",
          zIndex: (isSelected ? 20000 : 10000) + order
        }}
        onMouseDown={event => {
        if (canMove && 'BOX' !== type) {
            event.stopPropagation();
            this.props.onMouseDown(event);
          }
        }}
        onMouseUp={event => void this.props.onMouseUp(event)}
      >
        <WarningBadge
          isWarning={this.props.isWarning}
          visible={(WIDGET_VALID !== this.props.valid) || this.props.isWarning} warningMessage={this.props.message}
        />
        {resizeAreas}
        <BoxHandler
          renderInside={renderInside}
          addInnerWidget={(targetID, x, y, widget) => {
            this.props.onAddInnerWidget(targetID, x, y, widget);
          }}
          id={this.props.id}
          type={this.props.type}
          parent={this.props}
        ></BoxHandler>
      </div>
    );
  }
}

export function BoxHandler(props) {
  const [{ hovered }, drop] = useDrop({
    accept: [dndTypes.LIBRARY_WIDGET, dndTypes.EDIT_WIDGET],
    drop: (item, monitor) => {
      if ("BOX" === props.type) {
        let idToDrop = props.id;
        const parentY = props.parent.y + 47; //47 navBar size
        const parentX = props.parent.x;
        const mouseY = monitor.getClientOffset().y;
        const mouseX = monitor.getClientOffset().x;

        const selectionBox = [mouseX, mouseY, mouseX, mouseY];
        const innerWidgets =
          props.renderInside.props.children[1].props.children.props
            .innerWidgets;
        if (innerWidgets !== undefined) {
          const widgetBoxes = innerWidgets.map((widget) => {
            let { x, y, width, height } = widget.props;
            x = x + parentX;
            y = y + parentY;
            return [x, y, x + width, y + height];
          });

          const overlaps = boxIntersect([selectionBox], widgetBoxes);
          if (overlaps[0]) {
            if (innerWidgets[overlaps[0][1]].props.type === "BOX")
              idToDrop = innerWidgets[overlaps[0][1]].props.id;
          }
        }
        props.addInnerWidget(
          idToDrop,
          monitor.getClientOffset().x,
          monitor.getClientOffset().y,
          monitor.getItem().type
        );
      }
    },
    collect: (monitor) => {
      return {
        hovered: monitor.isOver(),
      };
    },
  });
  return (
    <div
      ref={drop}
      className={
        "h-100 w-100 " +
        (hovered && "BOX" === props.type ? "widget-hovered" : "")
      }
    >
      {props.renderInside}
    </div>
  );
}
