import React from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Button } from "react-bootstrap";
import Modal from "../../shared/modal/components/Modal/Modal";
import "./notifications.css";

import { loadNotifications, clearNotifications, setNotifications } from "../../shared/user/state/actionCreators";

import {
  getIsLoggedIn,
  getUsername,
  getAwaitingResponse,
  getLoginFailure,
  getAwaitingNotification,
  getNotificationFailure,
  getNotifications
} from "../../shared/user/state/selectors";

const MSG_LENGTH = 130;
export const NOTIFY_ALL_NOTIFICATION = 0;

export enum NotificationLevel{
  UNDEFINED = "Undefined",
  ERROR = "Error", 
  WARNING = "Warning", 
  INFO = "Info"
}

export interface Notification {
  username: string;
  message: string;
  level: NotificationLevel;
  notified: boolean;
  timestamp: string;
  key: string;
}

interface State {
  username: string;
  showModal: boolean;
  display: boolean;
  notPop: boolean;
  key: string;
}

type Props = StateProps & DispatchProps;

//let key = '';

class Notifications extends React.Component<Props, State> {

  public async componentDidUpdate(prevProp){
    //if we just logged in, fetch dashboard count
    if (this.props.isLoggedIn && !prevProp.isLoggedIn){
      this.props.onLoadNotifications(this.props.username);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      showModal: false,
      display: false,
      notPop: false,
      key: ''
    };
    this.togglePopUp = this.togglePopUp.bind(this);
    this.toggleNotificationDisplay = this.toggleNotificationDisplay.bind(this);
    this.clearErrorMsgs = this.clearErrorMsgs.bind(this);
  }

  public render() {
    const {
      awaitingNotification,
    } = this.props;

    const bell = <FontAwesomeIcon  icon={faBell} />;
    let popDiv = <div></div>;
    let modal = <div></div>;
    let error = <div></div>;
    let infoCount = 0;
    let notifications;

    if(this.props.notifications) {
      notifications = this.props.notifications["notifications"];
      notifications = undefined === notifications ? notifications : notifications.reverse();
    } 
    let popUp = false;
    
    if(!awaitingNotification && notifications) {
      notifications.forEach(element => {
        if(element.notification.level === NotificationLevel.WARNING ||
          element.notification.level === NotificationLevel.ERROR) {
            infoCount++;
          } 
      });
    }

    if(notifications && !this.state.notPop)
    {
      let notification = this.props.notifications["notifications"];
      notification.forEach(element => {
        if(!element.notification.notified)
        {
          let classes = "pop ";
          const level = element.notification.level;
          if(level === NotificationLevel.WARNING) classes += "warning ";
          else if(level === NotificationLevel.ERROR) classes += "danger ";
          else if(level === NotificationLevel.INFO) {
            classes += "info ";
            setTimeout(() => this.togglePopUp(element.notification.key), 5000);
          } 
          popDiv = <div className={classes} >
                    <div className="closepop" onClick={()=>this.togglePopUp(element.notification.key)}>x</div>
                    <div className="indication">{level}</div> 
                    <div className="text-left msg-container">
                      {this.getFormatedMsg(element.notification, MSG_LENGTH)}
                    </div>
                  </div>;
          popUp = true;
        }
      });
    }

    if(this.state.display && notifications && !popUp) {

      const userErrors = notifications.filter(notification =>  
        notification.notification.level === NotificationLevel.ERROR || 
        notification.notification.level === NotificationLevel.WARNING );
      
      error = <ul className="dropdown-menu notifications show" role="menu" aria-labelledby="dLabel">
        <div className="arrow-up"></div>
        <div className="notification-heading"><span className="menu-title">Notifications</span><span id="clearButton" onClick={this.clearErrorMsgs} className="clear-all menu-title pull-right">Clear all<i className="glyphicon glyphicon-circle-arrow-right"></i></span></div>
        <li className="divider"></li>

        <div className="notifications-wrapper">
          {userErrors.length === 0 && (

            <div className="notification-item">
              <p className="item-info">No records found</p>
            </div>
          )}

          {userErrors.map((objError) => (
            <div key={objError['notification']['timestamp']+objError['notification']['level']} className={"notification-item "+objError['notification']['level']}>
              <div className="item-info">
                {this.getFormatedMsg(objError['notification'], MSG_LENGTH)}
              </div>
              {objError['notification']['timestamp'] &&
                <p className="notification-time">{new Date(Number(objError['notification']['timestamp'])).toLocaleTimeString()}, {new Date(Number(objError['notification']['timestamp'])).toLocaleDateString()}</p>
              }
            </div>
      
          ))}
        </div>
        <li className="divider"></li>
        <div className="notification-footer"><span onClick={this.clearErrorMsgs} className="menu-title clear-all">Clear all<i className="glyphicon glyphicon-circle-arrow-right"></i></span></div>
      </ul>;
    }

    if(notifications && this.state.key)
    {
      let notification = this.props.notifications["notifications"];
      notification.forEach(element => {
        if(element.notification.key === this.state.key)
        {
          modal = <Modal title={element.notification.level} 
          rightTitle={new Date(Number(element.notification.timestamp)).toLocaleTimeString() 
          + ", " + new Date(Number(element.notification.timestamp)).toLocaleDateString()}
          transparentModal={true}>
            <Modal.Body>
              <p>
                {element.notification.message}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button id="btn-close" variant="primary" onClick={() => this.closeModal()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>;
        }
      });
    }

    return (
      <div className="dropdown">
        <div className="notification" onClick={this.toggleNotificationDisplay}>
          {bell}
          <div className="badge" id="badgeNumber">{infoCount}</div>
        </div>
        {error}
        {popDiv}
        <div className="modal-container">
        {this.state.showModal && notifications && modal}
        </div>
      </div>
    );
  }

  getFormatedMsg(objMsg, maxLength) {
    if (objMsg.message.length > maxLength) {
      return (
        <div>
          {this.truncate(objMsg.message, maxLength)}
          <span onClick={() => this.openModal(objMsg)} id="viewMore" className="clear-all expand-btn">view more</span>
        </div>
      )
    } else {
      return objMsg.message;
    }
  }

  truncate(str, maxLength) {
    return (str.length > maxLength ? str.substr(0, maxLength)+' ...' : str);
  }

  openModal = (objMsg) => {
    this.setState({key: objMsg.key, showModal: true});
  }

  closeModal = () => {
    this.setState({key: '', showModal: false});
  }

  togglePopUp(key) {
    this.props.onSetNotifications(key);
  }

  clearErrorMsgs() {
    if(this.props.username) this.props.onClearNotifications(this.props.username);
    this.toggleNotificationDisplay();
  }

  toggleNotificationDisplay() {
    this.setState({ display: !this.state.display });
    this.props.onSetNotifications(NOTIFY_ALL_NOTIFICATION);
  }

}

interface StateProps {
  isLoggedIn: boolean;
  username?: string;
  awaitingResponse: boolean;
  loginFailure: boolean;
  awaitingNotification: boolean;
  notificationFailed: boolean;
  notifications: Notification[];
}

interface DispatchProps {
  onLoadNotifications: (username?: string) => void;
  onClearNotifications: (username: string) => void;
  onSetNotifications: (key: number) => void;
}

function mapStateToProps(state): StateProps {
  return {
    isLoggedIn: getIsLoggedIn(state),
    username: getUsername(state),
    awaitingResponse: getAwaitingResponse(state),
    loginFailure: getLoginFailure(state),
    awaitingNotification: getAwaitingNotification(state),
    notifications: getNotifications(state),
    notificationFailed: getNotificationFailure(state),
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onLoadNotifications: (username?: string) => dispatch(loadNotifications(username)),
    onClearNotifications: (username: string) => dispatch(clearNotifications(username)),
    onSetNotifications: (key: number) => dispatch(setNotifications(key)),
  };
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
