import React, { Component, Fragment, CSSProperties } from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  BooleanInputDefinition,
  AttributeInputDefinition,
  SelectInputDefinition,
  StyleInputDefinition
} from "../../types";

import BooleanValues from "./BooleanValues"
import "../styles/BooleanDisplay.styles.css";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { showHideTangoDBName } from "../../DBHelper";

type Inputs = {
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  alignSwitchRight: BooleanInputDefinition;
  attribute: AttributeInputDefinition;
  showAttribute: SelectInputDefinition;
  widgetCSS: StyleInputDefinition;
  OnCSS: StyleInputDefinition;
  OffCSS: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

interface State {
  pending: boolean;
}


class BooleanDisplay extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pending: false
    };
  }

  public render() {

    const { device, name, label } = this.deviceAndAttribute();

    const { inputs, mode } = this.props;

    const {
      alignSwitchRight,
      widgetCSS,
      showAttribute,
      showDevice,
      showTangoDB,
      OnCSS,
      OffCSS
    } = inputs;

    const styleCheckbox = {
      paddingLeft: "0em",
      marginLeft: alignSwitchRight ? "auto" : "",
    } as CSSProperties;

    const widgetCss = parseCss(widgetCSS).data;
    const style = {
      height: "100%",
      overflow: "auto",
      ...widgetCss
    } as CSSProperties;

    let display = "";
    if (showAttribute === "Label") display = label;
    else if (showAttribute === "Name") display = name;
    else if (showAttribute === "None") display = "";

    const deviceLabel = showHideTangoDBName(showDevice, showTangoDB, device);
    const deviceName = deviceLabel ? `${deviceLabel}/${display}` : display;

    return (
      <div style={style} className="d-flex">
        <Fragment>
          <span>{deviceName}</span>
          <BooleanValues
            attributeName={name}
            deviceName={device}
            dataType={inputs.attribute.dataType}
            mode={mode}
            styleCheckbox={styleCheckbox}
            OnCSS={OnCSS}
            OffCSS={OffCSS}
          />
        </Fragment>
      </div>
    );
  }

  private deviceAndAttribute(): { device: string; name: string, label: string } {
    const { attribute } = this.props.inputs;
    const device = attribute.device || "device";
    const name = attribute.attribute || "attribute";
    const label = attribute.label || "attributeLabel";
    return { device, name, label };
  }

}

const definition: WidgetDefinition<Inputs> = {
  type: "BOOLEAN_DISPLAY",
  name: "Boolean Display",
  defaultWidth: 10,
  defaultHeight: 2,
  inputs: {
    attribute: {
      type: "attribute",
      label: "",
      dataFormat: "scalar",
      required: true
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label"
        },
        {
          name: "Name",
          value: "Name"
        }
        ,
        {
          name: "None",
          value: "None"
        }
      ]
    },
    showDevice: {
      type: "boolean",
      label: "Device Name",
      default: false
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    alignSwitchRight: {
      type: "boolean",
      label: "Align switch right",
      default: true
    },
    widgetCSS: {
      type: "style",
      default: "",
      label: "Custom CSS"
    },
    OnCSS: {
      type: "style",
      default: "",
      label: "On state CSS"
    },
    OffCSS: {
      type: "style",
      default: "",
      label: "Off state CSS"
    },
  }
};

const BooleanDisplayExport = { component: BooleanDisplay, definition };

export default BooleanDisplayExport;
