import React, { useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../shared/modal/components/Modal/Modal";

import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import createPlotlyComponent from "react-plotly.js/factory";
import { Button } from "react-bootstrap";
import { ImageDisplay } from "./ImageDisplay";
import { IRootState } from "../../../../shared/state/reducers/rootReducer";
import { WEBSOCKET } from "../../../../shared/state/actions/actionTypes";
import { getAttributeLastValueFromState } from "../../../../shared/utils/getLastValueHelper";

PlotlyCore.register([PlotlyScatter]);
const Plotly = createPlotlyComponent(PlotlyCore);

interface SpectrumDisplayProps {
  device: string;
  attribute: string;
  datatype: string;
}

function SpectumDisplay(props: SpectrumDisplayProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const x: number[] = [];
  const y: number[] = [];

  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, props.device, props.attribute);
  });

  if (value) {
    for (let i = 0; i < value.length; i++) {
      x.push(i);
      y.push(Number(value[i]));
    }

    const width =
      containerRef.current == null ? 0 : containerRef.current.offsetWidth;

    const layout = {
      font: { family: "Helvetica, Arial, sans-serif" },
      margin: {
        l: 0,
        r: 0,
        t: 0,
        b: 0,
      },
      width,
    };

    /* "hv" produces a step chart where the y value in the beginning of each segment corresponds to the y value of the data point */
    const shape = props.datatype === "DevBoolean" ? "hv" : "linear";

    return (
      <div ref={containerRef}>
        {width === 0 ? (
          "Loading..."
        ) : (
          <Plotly
            layout={layout}
            data={[{ x, y, line: { shape } }]}
            config={{ displayModeBar: false }}
          />
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

interface StringDisplayProps {
  device: string;
  attribute: string;
}

function StringDisplay(props: StringDisplayProps) {
  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(state.messages, props.device, props.attribute);
  });

  return (
    <pre style={{ height: "15em", overflow: "scroll" }}>
      {JSON.stringify(value, null, 2)}
    </pre>
  );
}

interface Props {
  tangoDB: string;
  device: string;
  attribute: string;
  dataformat: string;
  datatype: string;
  onClose: () => void;
}

export function NonScalarValueModal(props: Props) {
  const {
    device,
    attribute,
    datatype,
    dataformat,
    onClose,
  } = props;

  const fullName = device + "/" + attribute;

  //Subscribe to current NonScalar Attribute
  const dispatch = useDispatch();
  dispatch({
    type: WEBSOCKET.WS_SUBSCRIBE,
    payload: { devices: [device + "/state", device + "/" + attribute] },
  });

  const content =
    datatype === "DevString" ? (
      <StringDisplay device={device} attribute={attribute} />
    ) : dataformat === "IMAGE" ? (
      <ImageDisplay
        device={device}
        attribute={attribute}
      />
    ) : (
      <SpectumDisplay
        device={device}
        attribute={attribute}
        datatype={datatype}
      />
    );

  return (
    <Modal title={fullName}>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
