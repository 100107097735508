import React, { Component, FormEvent, CSSProperties } from "react";

import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  StringInputDefinition,
  AttributeInputDefinition,
  BooleanInputDefinition,
  ColorInputDefinition,
  NumberInputDefinition,
  SelectInputDefinition,
  AttributeInput,
  StyleInputDefinition,
} from "../../types";

import { parseCss } from "../../components/Inspector/StyleSelector";
import AttributeValues from "./AttributeValues";
import { showHideTangoDBName } from "../../DBHelper";

type Inputs = {
  title: StringInputDefinition;
  attribute: AttributeInputDefinition;
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showAttribute: SelectInputDefinition;
  alignValueRight: BooleanInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

interface State {
  input: string;
  pending: boolean;
  validation: boolean;
}

class AttributeWriter extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      input: "",
      pending: false,
      validation: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public render() {
    const { mode, inputs } = this.props;
    const {
      title,
      attribute,
      showDevice,
      showTangoDB,
      showAttribute,
      alignValueRight,
      backgroundColor,      
      textColor,
      size,
      font,
      widgetCss,
    } = inputs;
    let {
      device,
      value,
      dataType,
      minAlarm,
      maxAlarm,
      minValue,
      maxValue,
    } = attribute;

    const WidgetDivCss = parseCss(widgetCss).data;
    const unit = mode === "run" ? attribute.unit : "unit";

    let display = this.getDisplay(attribute, showAttribute);
    const deviceLabel = showHideTangoDBName(showDevice, showTangoDB, device, "device");


    const displayWidget = [...([deviceLabel]), display].join(
      "/"
    );

    let dataTypeGet = this.dataType();
    if (
      mode === "run" &&
      dataTypeGet !== "numeric" &&
      dataTypeGet !== "string" &&
      dataTypeGet !== "boolean" &&
      dataTypeGet !== "devenum"
    ) {
      return (
        <div style={{ backgroundColor: "red"}}>
          {dataType} not implemented
        </div>
      );
    }

    let isInvalid = true;
    if (this.state.input !== "") {
      isInvalid =
        ((dataTypeGet === "numeric" || dataTypeGet === "devenum") &&
          isNaN(Number(this.state.input)) &&
          this.state.input !== "") ||
        (dataTypeGet === "boolean" &&
          this.state.input !== "" &&
          ["true", "false", ""].indexOf(this.state.input.toLowerCase()) === -1);
    }

    if (
      !isInvalid &&
      minValue !== undefined &&
      minValue !== null &&
      maxValue !== undefined &&
      maxValue !== null
    )
      isInvalid =
        Number(this.state.input) < minValue ||
        Number(this.state.input) > maxValue;

    if (mode === "edit") {
      dataType = "DevString";
      value = "...";
      dataTypeGet = "string";
      isInvalid = false;
    }

    let style: CSSProperties = {
      alignItems: "center",
      backgroundColor,
      color: textColor,
      fontSize: size + "em",
      height: "100%",
      ...WidgetDivCss,
    };
    if (font) {
      style["fontFamily"] = font;
    }

    const submitButton =
      dataTypeGet === "boolean" ? (
        <button
          style={{ padding: "revert" }}
          className="btn btn-primary btn-dashboard"
          type={"submit"}
        >
          Write
        </button>
      ) : null;
    let placeholder =
      dataTypeGet === "numeric" && dataType === "DevDouble" && value
        ? value.toFixed(2)
        : value;

    return (
      <div id="AttributeWriter" style={style}>
        <form
          style={{ display: "flex" }}
          className="justify-content-left"
          onSubmit={this.handleSubmit}
        >
          <div className="form-group">
            <span style={{ flexGrow: 0 }}>{title}</span>
            {displayWidget && (
              <span style={{ flexGrow: 0 }}>
                {displayWidget}:
              </span>
            )}
          </div>
          <AttributeValues
            mode={mode}
            attributeName={attribute.attribute}
            deviceName={attribute.device}
            alarms={[minAlarm, maxAlarm]}
            bounds={[minValue, maxValue]}
            type={dataTypeGet}
            value={this.state.input}
            isValid={!isInvalid}
            placeholder={placeholder}
            validating={this.state.validation}
            onFocus={() => this.setState({ validation: true })}
            onBlur={() => this.setState({ validation: false })}
            onChange={(e) => this.setState({ input: e.target.value })}
            alignValueRight={alignValueRight}
          />
          {submitButton}
          <div className="form-group">
            {unit && <span style={{ marginLeft: "0.5em" }}>{unit}</span>}
          </div>
        </form>
      </div>
    );
  }

  private dataType(): "numeric" | "boolean" | "string" | "devenum" | "other" {
    const { attribute } = this.props.inputs;
    const { dataType, isNumeric } = attribute;
    const isBoolean = dataType === "DevBoolean";
    const isString = dataType === "DevString";
    const isDevEnum = dataType === "DevEnum";
    return isNumeric
      ? "numeric"
      : isBoolean
      ? "boolean"
      : isString
      ? "string"
      : isDevEnum
      ? "devenum"
      : "other";
  }

  private getDisplay(attribute: AttributeInput, showAttribute: string): string {
    let display = "";
    if (showAttribute === "Label") {
      if (attribute.label !== "") display = attribute.label;
      else display = "attributeLabel";
    } else if (showAttribute === "Name") {
      if (attribute.attribute !== "") display = attribute.attribute;
      else display = "attributeName";
    }
    return display;
  }

  private async handleSubmit(event: FormEvent<HTMLFormElement>) {
    if (this.state.pending) {
      return;
    }

    event.preventDefault();
    const { attribute } = this.props.inputs;
    const { isNumeric, minValue, maxValue } = attribute;
    const isBoolean = attribute.dataType === "DevBoolean";
    const isDevEnum = attribute.dataType === "DevEnum";
    const { input } = this.state;
    let value: any = input;
    if (input === "") {
      return; //we don't want to interpret an emtpy string as a zero or false
    }
    if (isNumeric || isDevEnum) {
      value = Number(input);
    }
    if (isBoolean) {
      value = input.toLowerCase() === "f" ? false : true;
    }
    if (typeof value === "number" && isNaN(value)) {
      return;
    }
    if (maxValue !== undefined && maxValue !== null) {
      if (typeof value === "number" && value > maxValue) {
        return;
      }
    }
    if (minValue !== undefined && minValue !== null) {
      if (typeof value === "number" && value < minValue) {
        return;
      }
    }

    this.setState({ input: "", pending: true });
    await this.props.inputs.attribute.write(value);
    this.setState({ pending: false });
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTE_WRITER",
  name: " Attribute Writer",
  defaultHeight: 2,
  defaultWidth: 15,
  inputs: {
    title: {
      type: "string",
      label: "Title",
      default: "",
      placeholder: "Title of widget",
    },
    attribute: {
      type: "attribute",
      label: "",
      dataFormat: "scalar",
    },
    showDevice: {
      type: "boolean",
      label: "Show Device Name",
      default: true,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showAttribute: {
      type: "select",
      label: "Attribute display:",
      default: "Label",
      options: [
        {
          name: "Label",
          value: "Label",
        },
        {
          name: "Name",
          value: "Name",
        },
        {
          name: "None",
          value: "None",
        },
      ],
    },
    alignValueRight: {
      type: "boolean",
      label: "Align value on right",
      default: true
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
      ],
    },
    widgetCss: {
      type: "style",
      default: "",
      label: "Custom CSS",
    },
  },
};

const AttributeWriterExport = { definition, component: AttributeWriter };
export default AttributeWriterExport;
