import { WEBSOCKET } from "../actions/actionTypes";
import { WebsocketInt } from "../types/types";
const config = window['config'];
const initialState = {};

const messages = (state = initialState, action: WebsocketInt) => {
  try {

    switch (action.type) {
      case WEBSOCKET.WS_MESSAGE: {
        const result = getTangoGQLObject(action);
        if (state[result?.device] === undefined) {
          //New device
          state = assignDevice(state, result);
        } else if (
          state[result?.device].attributes === undefined
          || state[result?.device].attributes[result?.attribute] === undefined
        ) {
          //New attribute
          assignAttribute(state, result);
        } else {
          //Update attribute
          let values =
            state[result?.device]?.attributes[result?.attribute]?.values;

          let quality =
            state[result?.device]?.attributes[result?.attribute]?.quality;

          let timestamp =
            state[result?.device]?.attributes[result?.attribute]?.timestamp;

          let writeValue =
            state[result?.device]?.attributes[result?.attribute]?.writeValue;

          if (values.length >= config.historyLimit) {
            values = values.slice(-(config.historyLimit - 1));
            quality = quality.slice(-(config.historyLimit - 1));
            timestamp = timestamp.slice(-(config.historyLimit - 1));
            writeValue = writeValue.slice(-(config.historyLimit - 1));
          }

          values.push(result?.value);
          quality.push(result?.quality);
          timestamp.push(result?.timestamp);
          writeValue.push(result?.writeValue);
          state[result?.device].attributes[result?.attribute] = {
            values,
            quality,
            timestamp,
            writeValue
          };
        }
        return { ...state };
      }

      case WEBSOCKET.WS_EXECUTE_COMMAND_SUCCESS: {
        storeCommandOutput(state, action);
        return { ...state }
      }
      default:
        return state;
    }
  } catch (e) {
    console.log(state, '----', action, ' Error: ', e);
    return state;
  }
};

function storeCommandOutput(state: any, action: any) {
  const { device, command, output, id } = action

  if (!state[device]) state[device] = {}
  if (!state[device]['commandOutput']) state[device]['commandOutput'] = {}
  if (!state[device]['commandOutput'][command]) state[device]['commandOutput'][command] = {}

  state[device]['commandOutput'][command][id] = output;
}

function getTangoGQLObject(action) {
  const result = action.value.map((item) => {
    const dataJson = JSON.parse(item.data);
    const { payload } = dataJson;
    const { data } = payload;
    if (data) {
      const { attributes } = data;
      const { device, attribute, value, writeValue, quality, timestamp } = attributes;
      return { device, attribute, value, writeValue, quality, timestamp };
    }
    return null;
  });
  return result[0];
}

function assignDevice(state, action) {
  let device = {};
  let attributes = {};
  attributes[action.attribute] = {
    values: [action.value],
    quality: [action.quality],
    timestamp: [action.timestamp],
    writeValue: [action.writeValue]
  };
  device[action.device] = { attributes };
  Object.assign(state, device);
  return state;
}

function assignAttribute(state, action) {
  let attributes = {};
  attributes[action.attribute] = {
    values: [action.value],
    quality: [action.quality],
    timestamp: [action.timestamp],
    writeValue: [action.writeValue]
  };

  if (undefined === state[action.device].attributes)
    state[action.device].attributes = {}

  Object.assign(state[action.device].attributes, attributes);

  return state;
}

export default messages;
