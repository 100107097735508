import React from "react";
import { WidgetProps } from "../types";
import {
  WidgetDefinition,
  AttributeInputDefinition,
  NumberInputDefinition,
  SelectInputDefinition,
  BooleanInputDefinition,
  StyleInputDefinition
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import {
  getAttributeLastValueFromState,
  getAttributeLastWriteValueFromState
} from "../../../shared/utils/getLastValueHelper";
import { showHideTangoDBName } from "../../DBHelper";


type Inputs = {
  attribute: AttributeInputDefinition;
  min: NumberInputDefinition;
  max: NumberInputDefinition;
  label: SelectInputDefinition<"attribute" | "device" | "label">;
  showWriteValue: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition; 
  widgetCss: StyleInputDefinition
};

type Props = WidgetProps<Inputs>;

function normalizeWithFlex(
  value: number,
  min: number,
  max: number,
  flex: number = 0
): number {
  let result = (value - min) / (max - min);
  result = Math.min(result, 1 + flex);
  result = Math.max(result, 0 - flex);
  return result;
}

function normalizedValueToAngle(value: number) {
  return -225 + value * 270;
}

interface HandProps {
  radius: number;
  innerRadius: number;
  width: number;
  isWriteValue?: boolean;
  deviceName: string;
  attributeName: string;
  color: string;
  boltColor?: string;
  animationDuration: number;
  min: number;
  max: number;
}

function Hand(props: HandProps) {
  const {
    radius,
    innerRadius,
    width,
    isWriteValue,
    deviceName,
    attributeName,
    color,
    boltColor,
    animationDuration,
    min,
    max
  } = props;

  const value = useSelector((state: IRootState) => {
    if (isWriteValue)
      return getAttributeLastWriteValueFromState(state.messages, deviceName, attributeName);
    else
      return getAttributeLastValueFromState(state.messages, deviceName, attributeName);
  })

  const normalized = normalizeWithFlex(value, min, max, 0.02);
  const angle = normalizedValueToAngle(normalized);

  const handBackLength = 0.25 * innerRadius;
  const handCenterRadius = 0.075 * radius;
  const transition = `transform ${animationDuration}s ease-in-out`;

  return (
    <g transform={`translate(${radius} ${radius})`}>
      <path
        style={{
          transform: `rotate(${angle}deg)`,
          transition
        }}
        d={`
    M0 ${width}
    L${0.95 * innerRadius} ${0.15 * width}
    l0 ${-0.3 * width}
    L0 -${width}
    l-${handBackLength} 0
    l0 ${2 * width}
    Z`}
        fill={color}
      />
      <circle r={handCenterRadius} cx={0} cy={0} fill={color} />
      <circle
        r={0.5 * handCenterRadius}
        cx={0}
        cy={0}
        fill={boltColor || color}
      />
    </g>
  );
}

const AttributeDial: React.FC<Props> = (props) => {

  const { mode, inputs, actualWidth, actualHeight } = props;
  const { min, max, attribute, label, showWriteValue, showTangoDB } = inputs;
  const widgetCss =  parseCss(inputs.widgetCss).data;
  const textColor = widgetCss['color'] ? widgetCss['color'] : 'black';
  const transform = widgetCss['border'] || widgetCss['borderWidth'] ?  {transform: "scale(0.95)"}: null;

  const radius =
    mode === "library" ? 30 : Math.min(actualHeight, actualWidth) / 2;
  const libraryProps = mode === "library" ? { margin: "0.5em" } : {};

  const handWidth = 0.05 * radius;
  const handColor = "red";
  const boltColor = "darkred";
  const innerRadius = 0.9 * radius;

  const numBigTickMarks = 10;
  const numSmallPerBig = 5;
  const totalNumTickMarks = numBigTickMarks * numSmallPerBig + 1;
  const tickSectorAngle = 270 / (totalNumTickMarks - 1);

  const labelShown = showHideTangoDBName(true, showTangoDB, attribute[label])  || label;

  return (
    <div style={{ width: "100%", height: "100%", ...widgetCss}}>
      <div
        style={{
          position: "relative",
          width: 2 * radius,
          height: 2 * radius,
          userSelect: "none",
          ...transform,
          ...libraryProps
        }}
      >
        <svg 
          width={2 * radius} height={2 * radius}>
          <circle r={radius} cx={radius} cy={radius} fill="darkgray" />
          <circle r={innerRadius} cx={radius} cy={radius} fill="white" />

          {showWriteValue && (
            <Hand
              radius={radius}
              innerRadius={innerRadius}
              width={0.9 * handWidth}
              min={min}
              max={max}
              deviceName={attribute.device}
              attributeName={attribute.attribute}
              isWriteValue={true}
              color={"lightgray"}
              animationDuration={0.2}
            />
          )}

          {Array(totalNumTickMarks)
            .fill(0)
            .map((_, i) => {
              const isBig = i % numSmallPerBig === 0;
              const tickAngle = 45 - i * tickSectorAngle;
              const textRotation =
                90 + (tickAngle < -180 || tickAngle > 0 ? 180 : 0);
              const delta = max - min;
              const tickValue =
                min + delta * (1 - i / numBigTickMarks / numSmallPerBig);
              const tickLabel =
                Math.abs(delta) > 1000
                  ? tickValue.toExponential(0).replace("+", "")
                  : Math.round(tickValue * 100) / 100;

              return (
                // Draw the tick and number on circle
                <g
                  key={i}
                  transform={`translate(${radius} ${radius}) rotate(${tickAngle})`}
                >
                  {isBig && (
                    <text
                      style={{fontSize: 0.1 * radius, ...widgetCss}}
                      fill={textColor}
                      textAnchor="middle"
                      alignmentBaseline="middle"
                      x={0}
                      y={0}
                      transform={`translate(${0.8 *
                        innerRadius} 0) rotate(${textRotation}) `}
                    >
                      {tickLabel}
                    </text>
                  )}
                  <line
                    x1={(isBig ? 0.9 : 0.95) * innerRadius}
                    y1={0}
                    x2={innerRadius}
                    y2={0}
                    stroke={textColor}
                    strokeWidth={0.01 * radius * (isBig ? 2 : 1)}
                  />
                </g>
              );
            })}

          {min !== max && (
            <Hand
              radius={radius}
              innerRadius={innerRadius}
              width={handWidth}
              min={min}
              max={max}
              deviceName={attribute.device}
              attributeName={attribute.attribute}
              color={handColor}
              boltColor={boltColor}
              animationDuration={2}
            />
          )}

          <text
            textAnchor="middle"
            x={radius}
            y={1.6 * radius}
            style={{ fontSize: 0.1 * radius, ...widgetCss }}
            textLength={radius * Math.min(0.05 * labelShown.length, 1)}
            lengthAdjust="spacing"
            fill={textColor}
          >
            {labelShown}
          </text>
        </svg>
      </div>
    </div>
  );
}

const definition: WidgetDefinition<Inputs> = {
  type: "ATTRIBUTE_DIAL",
  name: "Attribute Dial",
  defaultWidth: 10,
  defaultHeight: 10,
  inputs: {
    attribute: {
      type: "attribute",
      label: "",
      dataFormat: "scalar",
      dataType: "numeric",
      required: true
    },
    min: {
      type: "number",
      label: "Minimum",
      default: 0
    },
    max: {
      type: "number",
      label: "Maximum",
      default: 10
    },
    label: {
      type: "select",
      label: "Label",
      options: [
        { name: "Attribute", value: "attribute" },
        { name: "Label", value: "label" },
        { name: "Device", value: "device" }
      ],
      default: "attribute"
    },
    showWriteValue: {
      type: "boolean",
      label: "Show Write Value",
      default: false
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    widgetCss: {
      type: "style",
      default: "",
      label: "Custom Css"
    },
  }
};

const AttributeDialExport = { component: AttributeDial, definition };
export default AttributeDialExport;
