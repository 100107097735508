import React, { CSSProperties } from 'react';
import { useSelector } from "react-redux";
import {
    getAttributeLastTimeStampFromState,
    getAttributeLastValueFromState
} from '../../../shared/utils/getLastValueHelper';
import { IRootState } from '../../../shared/state/reducers/rootReducer';

interface Props {
    attributeName: string;
    deviceName: string;
    mode: string;
    showSpecificIndexValue: string;
    precision: number;
    layout: string;
    showIndex: boolean;
    showLabel: boolean;
    attribute: { device: string; attribute: string; label: string; dataType: string; value: any[]; };
}
const SpectrumValues: React.FC<Props> = ({
    attributeName,
    deviceName,
    mode,
    showSpecificIndexValue,
    precision,
    layout,
    showIndex,
    showLabel,
    attribute, // Refactor to use device, attribute, label, dataType, value instead.
}) => {
    let stateValue = useSelector((state: IRootState) => {
        return getAttributeLastValueFromState(state.messages, deviceName, attributeName);
    });

    const timestamp = useSelector((state: IRootState) => {
        return getAttributeLastTimeStampFromState(state.messages, deviceName, attributeName)?.toString();
    });
    const sampleData = [1, 2, 3, 4, 5];
    const tdStyle: CSSProperties = { marginLeft: "5px", padding: "0.5em", whiteSpace: "nowrap", border: "1px solid black", textAlign: "center" };

    let value = mode === "run" ? stateValue : mode === "library" || mode === "edit" ? sampleData : [];
    value = value === undefined || value === null ? [null] : value;

    let hasValue = true;
    if (value?.length === 1) {
        if (value[0] === null) hasValue = false;
    }

    if (showSpecificIndexValue) {
        if (value[parseInt(showSpecificIndexValue)])
            value = [value[parseInt(showSpecificIndexValue)]];
        else
            hasValue = false;
    }

    return (
        <div>
            {!hasValue && "No data"}
            {hasValue &&
                <table>
                    {
                        layout === 'horizontal' ?
                            <tbody title={new Date(timestamp * 1000).toUTCString()}>
                                {showIndex === true ? <tr>{showLabel === true ? <td style={tdStyle}>Index:</td> : null}
                                    {value.map((item, i) => { return [<td style={tdStyle} key={i}>{getIndex(showSpecificIndexValue, i)}</td>]; })}</tr> : null}

                                <tr>{showLabel === true ? <td style={tdStyle}>Value:</td> : null}
                                    {value.map((item, i) => { return [<th style={tdStyle} key={i}>{getFormattedValue(item, precision, attribute?.dataType)}</th>]; })}</tr></tbody>
                            : //vertical
                            <tbody title={new Date(timestamp * 1000).toUTCString()}>
                                {showLabel === true ? <tr>{showIndex === true ? <td style={tdStyle}>Index:</td> : null}
                                    <td style={tdStyle}>Value:</td></tr> : null}
                                {value.map((item, i) => {
                                    return [<tr key={i}>{showIndex === true ? <td style={tdStyle}>{getIndex(showSpecificIndexValue, i)}</td> : null}
                                        <th style={tdStyle}>{getFormattedValue(item, precision, attribute?.dataType)}</th></tr>];
                                })}</tbody>
                    }
                </table>
            }
        </div>
    );
};


function getIndex(showSpecificIndexValue, i: number) {
    return parseInt(showSpecificIndexValue) ? parseInt(showSpecificIndexValue) : i;
}

function getFormattedValue(value, precision, dataType: string) {
    if(dataType === "DevBoolean"){
        return `${value}`;
    }
    else{
        return ((dataType && dataType.includes("String")) || Number.isInteger(value)) ? value : parseFloat(value).toFixed(precision);
    }
}

export default SpectrumValues;