import React, { CSSProperties } from "react";
import { WidgetProps } from "../types";
import "./Tabular.css"

import {
  WidgetDefinition,
  DeviceInputDefinition,
  BooleanInputDefinition,
  ComplexInputDefinition,
  ColorInputDefinition,
  SelectInputDefinition,
  StyleInputDefinition,
  NumberInputDefinition,
  AttributeInputDefinition,
  AttributeInput
} from "../../types";
import { showHideTangoDBName } from "../../DBHelper";

import { parseCss } from "../../components/Inspector/StyleSelector";
import Cell from "./Cell";

const config = window['config'];

export interface AttributeComplexInput {
  attribute: AttributeInputDefinition;
}

export interface DeviceComplexInput {
  device: DeviceInputDefinition;
}

type Inputs = {
  devices: ComplexInputDefinition<DeviceComplexInput>;
  customAttributes: ComplexInputDefinition<AttributeComplexInput>;
  precision: NumberInputDefinition;
  scientificNotation: BooleanInputDefinition;
  showDefaultAttribute: BooleanInputDefinition;
  showEnumLabels: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  compactTable: BooleanInputDefinition;
  borderedTable: BooleanInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

function Tabular(props: Props) {
  const {
    devices,
    customAttributes,
    compactTable,
    borderedTable,
    textColor,
    backgroundColor,
    font,
    size,
    scientificNotation,
    showEnumLabels,
    showTangoDB,
    precision,
    showDefaultAttribute
  } = props.inputs;

  const widgetCss = props.inputs.widgetCss ? parseCss(props.inputs.widgetCss).data : {};
  const style: CSSProperties = {
    display: "flex",
    width: "100%",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    backgroundColor,
    color: textColor,
    fontSize: size + "em",
    ...widgetCss
  };
  if (font) {
    style["fontFamily"] = font;
  }

  const attributes_inputs_obj: AttributeInput[] = [];
  let attributes_unique_inputs: string[] = [];

  if (showDefaultAttribute) {
    const defaultAttr = [...config.defaultAttribute];
    defaultAttr.forEach(attr => {
      if (!attributes_unique_inputs.includes(attr))
        attributes_unique_inputs.push(attr);
    });
  }

  customAttributes.forEach(obj => {
    if (Array.isArray(obj.attribute)) {
      obj.attribute.forEach(attrObj => {
        attributes_inputs_obj.push(attrObj);
        if (!attributes_unique_inputs.includes(attrObj.attribute))
          attributes_unique_inputs.push(attrObj.attribute);
      });
    }
    else {
      attributes_inputs_obj.push(obj.attribute);
      if (!attributes_unique_inputs.includes(obj.attribute.attribute))
        attributes_unique_inputs.push(obj.attribute.attribute);
    }
  });
  attributes_unique_inputs = attributes_unique_inputs.filter((element) => element !== undefined);
  const attributes_obj = attributes_inputs_obj.filter((element) => Object.keys(element).length > 0);

  return (
    <div>
      {props.mode !== "library" &&
        <div id="TabularView" style={style} className={`table-responsive ${compactTable === true ? "table-responsive table-sm " : ""} `}>
          {devices.length > 0 &&
            <table className={`table ${borderedTable === true ? "table-bordered " : ""} table-hover align-items-center justify-content-center"`}>
              <tbody>
                <tr className="thead-light">
                  <th>Device</th>
                  {attributes_unique_inputs.map((attribute, i) => {
                    return (
                      <th key={i}>{attribute}</th>
                    )
                  })
                  }
                </tr>
                {devices.map((device, i) => {
                  const deviceRow = showHideTangoDBName(true, showTangoDB, device.device.name);
                  return (
                    <tr key={i}>
                      <td key={i}><b>{deviceRow}</b></td>
                      {
                        attributes_unique_inputs.map((attribute, j) => {
                          const attribute_obj = attributes_obj.filter(
                            obj => obj.device === device?.device?.name && obj.attribute === attribute)[0];
                          if (attribute_obj)
                            return (
                              <Cell
                                key={j + '_cell'}
                                attribute_obj={attribute_obj}
                                showEnumLabels={showEnumLabels}
                                precision={precision}
                                scientificNotation={scientificNotation} />
                            )
                          else return <td key={j + '_cell'} >...</td>
                        })
                      }
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          }
          {devices.length === 0 &&
            "No devices configured"
          }
        </div>
      }
      {props.mode === "library" &&
        <div className="table-responsive table-responsive table-sm" >
          <table className="table table-hover align-items-center justify-content-center">
            <tbody>
              <tr className="thead-light">
                <th>Device</th>
                <th>Attribute 1</th>
                <th>Attribute 2</th>
              </tr>
              <tr>
                <td><b>device/test/1</b></td>
                <td>2.0</td>
                <td className="tangoState ON">ON</td>
              </tr>
              <tr>
                <td><b>device/test/2</b></td>
                <td>12</td>
                <td className="tangoState RUNNING">RUNNING</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  );
}

const definition: WidgetDefinition<Inputs> = {
  type: "TABULAR_VIEW",
  name: "Tabular view",
  defaultWidth: 20,
  defaultHeight: 10,
  inputs: {
    devices: {
      type: "complex",
      label: "Devices",
      repeat: true,
      inputs: {
        device: {
          type: "device",
          label: "",
          publish: "$device"
        },
      }
    },
    precision: {
      type: "number",
      label: "Precision",
      default: 2
    },
    scientificNotation: {
      type: "boolean",
      label: "Scientific Notation",
      default: false
    },
    showDefaultAttribute: {
      type: "boolean",
      label: "Show default attributes",
      default: false
    },
    showEnumLabels: {
      type: "boolean",
      label: "Show enum labels",
      default: true
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    customAttributes: {
      type: "complex",
      label: "Custom Attributes",
      repeat: true,
      inputs: {
        attribute: {
          label: "Attribute",
          type: "attribute",
          required: true
        },
      }
    },
    compactTable: {
      type: "boolean",
      label: "Show compact table",
      default: true
    },
    borderedTable: {
      type: "boolean",
      label: "Show bordered table",
      default: false
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000"
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff"
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true
    },
    font: {
      type: "select",
      default: "Courier new",
      label: "Font type",
      options: [
        {
          name: "Default (Courier new)",
          value: "Courier new"
        },
        {
          name: "Helvetica",
          value: "Helvetica"
        }
      ]
    },
    widgetCss: {
      type: "style",
      label: "Custom CSS",
      default: ""
    }
  }
};

const TabularExport = { component: Tabular, definition };
export default TabularExport;