import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../components/Spinner/Spinner';
import { JSONTree, parseJSONObject } from "./JSONTree";

export default class OutputDisplay extends Component {

  render() {
   if(this.props.value !== undefined && this.props.value !== null) {
     if(this.props.isLoading) return <Spinner size={1}/>;
     else {
      const values = [].concat(this.props.value);
      const valuesAsObjects = values.map(parseJSONObject);
      const allAreObjects = valuesAsObjects.indexOf(null) === -1;
    
      if (allAreObjects) {
        return valuesAsObjects.map((obj, i) => <div className='output-display'>
        <div className='arrow'/><JSONTree key={i} data={obj} jsonCollapsed={true} /></div>);
      }

      return <div className='output-display'>
              <div className='arrow'/>
              <div className='output'>{this.props.value.toString() || ''}</div>
            </div>;
     }
   } 
   else return null;
  }
  
}

OutputDisplay.propTypes = {
  value: PropTypes.any,
  isLoading: PropTypes.bool,
}
  
 