import React from "react";
import { useSelector } from "react-redux";
import SvgComponent from "../../../shared/components/SvgComponent/SvgComponent";
import { getSelectedSynoptic } from "../../state/selectors";

interface Props {
  mode: "edit" | "run";
}

const SynopticDisplay: React.FC<Props> = ({ mode }) => {
  let synoptic = useSelector(getSelectedSynoptic);

  return (
    <div className="SynopticDisplay">
      {<SvgComponent path={synoptic.svg} mode={mode} zoom={true} layers={true} />}
    </div>
  );
};

export default SynopticDisplay;
