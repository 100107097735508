import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { DropTarget } from "react-dnd";
import { connect } from "react-redux";
import cx from "classnames";
import boxIntersect from "box-intersect";

import dndTypes from "../../dndTypes";
import { componentForWidget, definitionForWidget } from "../../widgets";

import SelectionBox from "./SelectionBox";
import EditWidget from "./EditWidget";
import { isEditableDashboard } from "../../utils"

import {
  moveWidgets,
  addWidget,
  addInnerWidget,
  reorderInnerWidget,
  dropInnerWidget,
  resizeWidget,
  deleteWidget,
  selectWidgets,
  mouseDownOnWidget
} from "../../../shared/state/actions/actionCreators";

import {
  getSelectedWidgets,
  getCurrentDashoardVariables,
  getSelectedDashboard
} from "../../../shared/state/selectors";

import { getUsername, getUserState } from "../../../shared/user/state/selectors";
import { calculateInnerWidgetAlignment, getAllBoxWidgets, retrieveBoxWidget, isBoxWidgetContained, canBeNested, removeBoxAndInnerWidgets } from "../../../shared/utils/canvas";

import { enrichedInputs } from "../../runtime/enrichment";

import TangoAPI from "../../../shared/api/tangoAPI";
import { extractDeviceNamesFromWidgets } from "../../runtime/extraction";
import { variablePresent, devicePresent } from "../../../shared/utils/DashboardVariables";
import { WIDGET_MISSING_DEVICE, WIDGET_WARNING } from "../../../shared/state/actions/actionTypes";
import { getDeviceNames } from "../../../shared/state/selectors/deviceList";
import { getTangoDBFilteredDevices } from "../../runtime/utils";
import { validateSVG } from "../../utils";

const config = window['config'];
const TILE_SIZE = config.MIN_WIDGET_SIZE;

const BACKSPACE = 8;
const DELETE = 46;
const SHIFT = 16;
const LEFT_MOUSE_BUTTON = 0;

const MOVE = "MOVE";
const MOVE_INNERWIDGET = "MOVE_INNERWIDGET";
const SELECT = "SELECT";

class EditCanvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseActionType: null,
      mouseActionStartLocation: null,
      mouseActionCurrentLocation: null,
      isShiftDown: false,
      mouseInitialLocation: [0, 0],
      deviceMetadata: {},
      editableDashboard: this.getIsEditableDashboard(props.dashboard, props.user)
    };

    this.canvasRef = null;
    this.canvasHeight = null;
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.setCanvasHeight = this.setCanvasHeight.bind(this);

    this.executionResolver = {
      deviceMetadataLookup: name => this.state?.deviceMetadata?.[name]
    };
  }

  componentDidMount() {
    this.refreshDeviceMetadata(this.props.widgets);
    this.canvasRef.addEventListener("keyup", this.props.hotKeyHandler);
  }

  componentWillUnmount() {
    this.canvasRef.removeEventListener("keyup", this.props.hotKeyHandler);
  }

  componentDidUpdate(prevProps) {
    this.refreshDeviceMetadata(this.props.widgets, prevProps.widgets);
    if (prevProps.dashboard !== this.props.dashboard || prevProps.user !== this.props.user) {
      this.setState({
        editableDashboard: this.getIsEditableDashboard(this.props.dashboard, this.props.user)
      })
    }    
  }

  getIsEditableDashboard(dashboard, user) {
    const isEditable = dashboard?.id ? isEditableDashboard(dashboard, user).result : true;
    return isEditable;
  }

  async refreshDeviceMetadata(currentWidgets, previousWidgets = []) {
    const currentDeviceNames = extractDeviceNamesFromWidgets(currentWidgets);
    const previousDeviceNames = extractDeviceNamesFromWidgets(previousWidgets);

    if (this.deviceNamesHaveChanged(currentDeviceNames, previousDeviceNames)) {
      const deviceMetadata = []
      // TODO: no need to refetch when current names are a subset of previous
      const devicesByTangoDB = getTangoDBFilteredDevices(currentDeviceNames);
      for await (const [tangoDB, devices] of Object.entries(devicesByTangoDB)) {
        const deviceMetadataTemp = await TangoAPI.fetchDeviceMetadata(
          tangoDB,
          devices,
        );
        deviceMetadata.concat(deviceMetadataTemp);
      }
      this.setState({ deviceMetadata });
    }
  }

  deviceNamesHaveChanged(currentNames, previousNames) {
    // Maybe there's a much better way to do this...
    // Intent: check whether the set of devices has changed, regardless of duplicates and order
    const currentSet = new Set(currentNames);
    const previousSet = new Set(previousNames);
    const combinedSet = new Set([...previousNames, ...currentNames]);

    return !(
      currentSet.size === combinedSet.size &&
      previousSet.size === combinedSet.size
    );
  }

  componentForWidget(widget) {
    return this.definitionForWidget(widget).component;
  }

  initiateMouseEvent(type, event) {
    const { left, top } = this.canvasRef.getBoundingClientRect();
    const mouseActionStartLocation = [
      event.clientX - left,
      event.clientY - top
    ];

    this.setState({
      mouseActionType: type,
      mouseActionStartLocation,
      mouseActionCurrentLocation: mouseActionStartLocation,
      mouseInitialLocation: mouseActionStartLocation
    });
    if(this.state.editableDashboard) {
        document.addEventListener("mousemove", this.handleMouseMove);
    }
  }

  handleMouseDown(event) {
    if (event.button === LEFT_MOUSE_BUTTON) {
      this.initiateMouseEvent(SELECT, event);
    }
  }

  handleMouseMove(event) {
    const { left, top } = this.canvasRef.getBoundingClientRect();
    //This is used to if selected widget is hovering box widget using widget.hover
    if (this.props.selectedWidgets.length > 0) {
      let BoxWidgets = getAllBoxWidgets(this.props.widgets);
      if (BoxWidgets.length > 0) {
        BoxWidgets.forEach(widget => {

          if (widget.innerWidgets) {
            const alreadyIn = widget.innerWidgets.filter(widget => widget.id === this.props.selectedWidgets[0].id);
            if (alreadyIn.length > 0) return;
          }

          if (widget.id !== this.props.selectedWidgets[0].id) {
            const overlaps = this.overlaps(widget, event.clientX, event.clientY);
            widget.hover = [];
            if (overlaps.length > 0) {
              this.props.selectedWidgets.forEach(element => {
                //check if the element to overlap is not a contained box widget and if can be nested without create a box widget configuration with more than 2-levels nesting
                if (!isBoxWidgetContained(element, widget.id) && canBeNested(element, widget.id, BoxWidgets)) {
                  const hoverAlready =  BoxWidgets.filter(widget => widget.hover ? widget.hover.length > 0 : false);
                  if(hoverAlready.length===0)widget.hover.push(element);
                }
              });
            }
          }
        });
      }
    }

    const mouseActionCurrentLocation = [
      event.clientX - left,
      event.clientY - top
    ];
    this.setState({ mouseActionCurrentLocation });
  }

  getWidgetsAligned(widgets) {
    widgets.forEach(widget => {

      if (widget.innerWidgets) {
        let alignment = calculateInnerWidgetAlignment(widget, TILE_SIZE, "edit");
        //TODO: Refactor for 2+ level
        widget.innerWidgets.forEach((inWidget, i) => {
          let innerWidgetToConcat = inWidget;
          innerWidgetToConcat.x = alignment[i].x + widget.x
          innerWidgetToConcat.y = alignment[i].y + widget.y
          innerWidgetToConcat.width = alignment[i].width / TILE_SIZE
          innerWidgetToConcat.height = alignment[i].height / TILE_SIZE
          widgets = widgets.concat(innerWidgetToConcat);

          if (inWidget.innerWidgets) {
            inWidget.width = alignment[i].width / TILE_SIZE;
            inWidget.height = alignment[i].height / TILE_SIZE;
            let inAlignment = calculateInnerWidgetAlignment(inWidget, TILE_SIZE, "edit");

            inWidget.innerWidgets.forEach((inWidget1, j) => {
              let innerWidgetToConcat1 = inWidget1;
              innerWidgetToConcat1.x = inAlignment[j].x + inWidget.x
              innerWidgetToConcat1.y = inAlignment[j].y + inWidget.y
              innerWidgetToConcat1.width = inAlignment[j].width / TILE_SIZE
              innerWidgetToConcat1.height = inAlignment[j].height / TILE_SIZE
              widgets = widgets.concat(innerWidgetToConcat1);
            })
          }
        })
      }
    });

    return widgets;
  }

  handleMouseUp(event) {
    const {
      mouseActionType,
      mouseActionStartLocation,
      mouseActionCurrentLocation
    } = this.state;

    if (mouseActionType === SELECT) {
      if (mouseActionStartLocation != null) {
        const [x1, y1] = mouseActionStartLocation;
        const [x2, y2] = mouseActionCurrentLocation;
        const smallX = x1 < x2 ? x1 : x2;
        const largeX = x1 > x2 ? x1 : x2;
        const smallY = y1 < y2 ? y1 : y2;
        const largeY = y1 > y2 ? y1 : y2;
        const selectionBox = [smallX, smallY, largeX, largeY];

        let widgets = this.getWidgetsAligned(this.props.widgets);

        const widgetBoxes = widgets.map(widget => {
          const { x, y, width, height } = widget;
          return [x, y, x + width, y + height].map(val => val * TILE_SIZE);
        });

        const overlaps = boxIntersect([selectionBox], widgetBoxes);
        let selectedWidgetIds = overlaps
          .map(([i, j]) => j)
          .map(i => widgets[i])
          .map(({ id }) => id);

        // For multiple selection (smallX != largeX) return all selected Ids
        selectedWidgetIds = selectedWidgetIds.length > 1 && (smallX === largeX) ? [selectedWidgetIds.pop()] : selectedWidgetIds;
        if (1 < selectedWidgetIds.length) removeBoxAndInnerWidgets(this.props.widgets, selectedWidgetIds);

        this.props.onSelectWidgets(selectedWidgetIds.reverse());
      }
    } else if (mouseActionType === MOVE) {
      const [dx, dy] = this.moveDelta();
      var BoxWidgets = getAllBoxWidgets(this.props.widgets);
      BoxWidgets = BoxWidgets.filter(widget => widget.hover ? widget.hover.length > 0 : false);

      if ((dx !== 0 || dy !== 0) && BoxWidgets.length === 0) {
        const ids = this.props.selectedWidgets.map(({ id }) => id);
        this.props.onMoveWidgets(ids, dx, dy);

      } else {
        //Handle widgets moving from canvas to box
        BoxWidgets.forEach(widget => {
          this.props.onAddInnerWidget(widget.hover, event.clientX, event.clientY, widget.id, false);
          widget.hover = [];
        });
      }
    }
    else if (mouseActionType === MOVE_INNERWIDGET) {
      // Handle widgets moving from one box to another or to canvas
      const bWidgets = getAllBoxWidgets(this.props.widgets);

      let droppedOnBox = false;
      bWidgets.forEach(widget => { // If it's hover a widget drop inside that one
        if (widget.hover) if (widget.hover.length > 0) {
          this.props.onAddInnerWidget(widget.hover, event.clientX, event.clientY, widget.id, true);
          widget.hover = [];
          droppedOnBox = true;
          return;
        }
      });
      // Handle widgets moving from box to canvas
      if (!droppedOnBox) {
        bWidgets.forEach((widget, i) => {
          if (widget.innerWidgets) if (widget.innerWidgets.length > 0) {
            widget.innerWidgets.forEach(innerWidget => {
              if (innerWidget.id === this.props.selectedWidgets[0].id) {
                //Check if it no longer intersects the parent widget(Box) then drop on canvas
                const overlaps = this.overlaps(bWidgets[i], event.clientX, event.clientY);
                if (overlaps.length === 0) {
                  this.props.onDropInnerWidget(event.clientX, event.clientY, innerWidget);

                } else { //if it intersects the parent widget, reorder the inner widget. 
                  this.props.onReorderInnerWidget(event.clientX, event.clientY, bWidgets[i], innerWidget);
                }
              }
            });
          }
        });
      }
    }

    document.removeEventListener("mousemove", this.handleMouseMove);
    this.setState({
      mouseActionType: null,
      mouseActionStartLocation: null,
      mouseActionCurrentLocation: null,
    });
  }

  /**
   * This function sets the actual scroll height to the canvas
   */
  setCanvasHeight() {
    if (0 === this.props.widgets.length) {
      return;
    }

    let newHeight = window.innerHeight - 48;//Total height - header height
    this.props.widgets.forEach(({ height, y }) => {
      newHeight = newHeight <= (y + height) * TILE_SIZE ? 24 + ((y + height) * TILE_SIZE) : newHeight;
    });

    this.canvasHeight = newHeight;
  }

  isSelecting() {
    const {
      mouseActionType,
      mouseActionStartLocation: start,
      mouseActionCurrentLocation: current
    } = this.state;

    if (mouseActionType !== SELECT) {
      return false;
    }

    return start[0] !== current[0] || start[1] !== current[1];
  }

  moveDelta() {
    const {
      mouseActionStartLocation: start,
      mouseActionCurrentLocation: current,
      mouseActionType
    } = this.state;

    if (mouseActionType !== MOVE || start == null || current == null) {
      return [0, 0];
    }

    const [x1, y1] = start;
    const [x2, y2] = current;
    return [x2 - x1, y2 - y1];
  }


  overlaps(widget, clientX, clientY) {

    const { x, y, width, height } = widget;
    const BoxLimits = [[x, y, x + width, y + height].map(val => val * TILE_SIZE)];

    const myWidgetWidth = this.props.selectedWidgets[0].width;
    const myWidgetHeight = this.props.selectedWidgets[0].height;

    //Need to know the offset from mouse to widget on the beginning of drag
    const mouseOffsetX = this.state.mouseInitialLocation[0] - this.props.selectedWidgets[0].x * TILE_SIZE;
    const mouseOffsetY = this.state.mouseInitialLocation[1] - this.props.selectedWidgets[0].y * TILE_SIZE;

    const myX = clientX - mouseOffsetX;
    const myY = clientY - mouseOffsetY - TILE_SIZE * 2;

    //check box widgets nested
    let nestedBoxWidgets = [];
    retrieveBoxWidget(widget, nestedBoxWidgets); //retrieve nested box widgets
    nestedBoxWidgets = nestedBoxWidgets.filter(nestedBoxWidget => nestedBoxWidget.id !== widget.id);


    let overlapsNestedBox = false;
    if (nestedBoxWidgets.length > 0) nestedBoxWidgets.forEach(nestedBoxWidget => { //if the parent widget contains nested box widget, calculate if the widget is dropped in a nested box widget
      const { x, y, width, height } = nestedBoxWidget;
      const BoxLimits = [[x, y, x + width, y + height].map(val => val * TILE_SIZE)];

      const overlaps = boxIntersect(BoxLimits, [[myX, myY, myX + myWidgetWidth * TILE_SIZE, myY + myWidgetHeight * TILE_SIZE]]);
      if (overlaps.length > 0) {
        overlapsNestedBox = true;
      }
    })

    if (overlapsNestedBox)
      return [];
    else {
      const overlaps = boxIntersect(BoxLimits, [[myX, myY, myX + myWidgetWidth * TILE_SIZE, myY + myWidgetHeight * TILE_SIZE]]);
      //return the overlaps if the widget is dropped outside the nested boxwidgets
      return overlaps;
    }
  }

  /**
   * This func checks for missing variable / device on the widget.
   *
   * @param {widget object} widget
   * @returns object
   */
  checkValidity(widget) {
    const { valid } = widget;
    let isWarning = false,
        message = "";

    if ("BOX" === widget.type && 0 < widget.innerWidgets) {
      this.checkValidity(widget);

    } else if ("SVG_WIDGET" === widget.type) {
      const res = validateSVG(widget.inputs.svgFile, this.props.deviceList);
      isWarning = res.isWarning;
      message = res.msg;
      message = message.length > 100 ? message.substring(0, 100) + '...' : message;
    } else {
      if (-1 !== [WIDGET_WARNING, WIDGET_MISSING_DEVICE].indexOf(valid)) {
        isWarning = true
        switch(valid) {
          case WIDGET_WARNING:
            const checkVars = variablePresent(widget, this.props.variables);
            message = checkVars.device + " not found";
            break;
          case WIDGET_MISSING_DEVICE:
            const checkDevice = devicePresent(widget, this.props.deviceList);
            message = checkDevice.device + " not found";
            break;
          default:
            //do nothing
        }
      }
    }

    return { valid, isWarning, message }
  }

  render() {
    const { connectLibraryDropTarget, selectedWidgets } = this.props;
    const hasWidgets = this.props.widgets.length > 0;

    const isMoving = this.state.mouseActionType === MOVE;
    const isSelecting = this.isSelecting();

    let innerSelected = false;
    var BoxWidgets = this.props.widgets.filter(widget => widget.type === "BOX");
    BoxWidgets.forEach((widget) => {
      widget.innerWidgets && widget.innerWidgets.forEach((innerWidget) => {
        if (selectedWidgets.map((widget) => { return widget.id }).includes(innerWidget.id)) innerSelected = true;
      })
    });

    const selectionBox = isSelecting && !innerSelected ? (
      <SelectionBox
        start={this.state.mouseActionStartLocation}
        current={this.state.mouseActionCurrentLocation}
      />
    ) : null;

    //Calculate and update canvas height for LOAD, ADD, DELETE & MOVE Widgets
    this.setCanvasHeight();    
    
    return connectLibraryDropTarget(
      <div
        style={{ height: (this.canvasHeight + 'px') }}
        ref={ref => (this.canvasRef = ref)}
        className={cx("Canvas", "edit", { isSelecting, isMoving })}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onKeyDown={event => {
          if(this.state.editableDashboard) {
            const { keyCode } = event;
            if ([BACKSPACE, DELETE].indexOf(keyCode) !== -1) {
              event.preventDefault();
              this.props.onDeleteWidget();
            } else if (keyCode === SHIFT) {
              this.setState({ isShiftDown: true });
            }
          }
        }}
        onKeyUp={event => {
          if (event.keyCode === SHIFT) {
            this.setState({ isShiftDown: false });
          }
        }}
        onBlur={() => {
          this.handleMouseUp();
        }}
        tabIndex="0"
      >
        {selectionBox}

        <div className="Placeholder" style={{ opacity: hasWidgets ? 0 : 1 }}>
          Add widgets by dragging them from the library and dropping them on the
          canvas.
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            boxShadow: "inset -1em 0.9em 1em -1em rgba(0, 0, 0, 0.1)",
            width: "100%",
            height: "100%",
            zIndex: 1000,
            pointerEvents: "none"
          }}
        />
        <div className={"grid "+(this.state.editableDashboard ? "" : "opacity-75")}>
          {!this.state.editableDashboard &&
            <div className="read-only">
              READ ONLY DASHBOARD
            </div>
          }
          {/* render the lowest order widets first */}
          {this.props.widgets.map(widget => {
            const { x, y, id, width, height } = widget;
            const { valid, isWarning, message } = this.checkValidity(widget);

            const definition = definitionForWidget(widget);
            const inputs = enrichedInputs(
              widget.inputs,
              definition.inputs,
              this.executionResolver
            );
            const actualWidth = TILE_SIZE * width;
            const actualHeight = TILE_SIZE * height;
            const props = { inputs, mode: "edit", actualWidth, actualHeight, id: widget.id };

            let isSelected = selectedWidgets.indexOf(widget) !== -1;
            const [moveX, moveY] = isSelected ? this.moveDelta() : [0, 0];

            if ('BOX' === widget.type) {
              //Get the widget definition for widgets inside Box
              props.hover = widget.hover;
              if (widget.innerWidgets) props.innerWidgets = this.getInnerWidgetContent(widget, selectedWidgets, isMoving);
            }

            const component = componentForWidget(widget);
            const element = React.createElement(component, props);
            const selectedIds = selectedWidgets.map(({ id }) => id);

            return (
              <EditWidget
                id={id}
                key={id}
                order={widget.order}
                type={widget.type}
                isSelected={isSelected}
                x={1 + TILE_SIZE * x + moveX}
                y={1 + TILE_SIZE * y + moveY}
                isDragging={isMoving}
                width={actualWidth}
                height={actualHeight * 1.02}
                onAddInnerWidget={(targetID, x, y, widget) => this.props.onAddInnerWidget({ type: widget }, x, y, targetID, false)}
                onDelete={() => this.props.onDeleteWidget(id)}
                onMouseDown={event => {
                  if (this.state.isShiftDown) {
                    return;
                  }
                  this.props.onMouseDownOnWidget(true);
                  if (!isSelected) {
                    this.props.onSelectWidgets([id]);

                  }
                  this.initiateMouseEvent(MOVE, event);
                }}
                onMouseUp={() => {
                  this.props.onMouseDownOnWidget(false);
                  if (this.state.isShiftDown) {
                    const updatedSelectedWidgets = isSelected
                      ? selectedWidgets
                        .map(widget => widget.id)
                        .filter(id2 => id2 !== id)
                      : [...selectedIds, id];

                    this.props.onSelectWidgets(updatedSelectedWidgets);
                  } else {
                    if (isSelected && moveX === 0 && moveY === 0) {
                      this.props.onSelectWidgets([id]);
                    }
                  }
                }}
                canResize={this.state.editableDashboard && selectedIds.length < 2}
                canMove={this.state.editableDashboard}
                onResize={(moveX, moveY, dx, dy) =>
                  this.props.onResizeWidget(id, moveX, moveY, dx, dy)
                }
                valid={valid}
                message={message}
                isWarning={isWarning}
                render={element}
              />
            );

          })}
        </div>
      </div>
    );
  }

  getInnerWidgetContent(widget, selectedWidgets, isMoving) {
    const innerWidgets = widget.innerWidgets;
    const alignment = calculateInnerWidgetAlignment(widget, TILE_SIZE, "edit");
    try {
      return innerWidgets.map((widget, i) => {
        const { id } = widget;
        const actualWidth = alignment[i].width;
        const actualHeight = alignment[i].height;

        let x = alignment[i].x
        let y = alignment[i].y

        const { valid, isWarning, message } = this.checkValidity(widget);
        const definition = definitionForWidget(widget);
        const inputs = enrichedInputs(
          widget.inputs,
          definition.inputs,
          this.executionResolver
        );

        const props = { inputs, mode: "edit", actualWidth, actualHeight, id: widget.id };

        const isSelected = selectedWidgets.indexOf(widget) !== -1;

        const current = this.state.mouseActionCurrentLocation;
        const start = this.state.mouseActionStartLocation;
        const [moveX, moveY] = isSelected && current ? [current[0] - start[0], current[1] - start[1]] : [0, 0];

        if (current !== start && isSelected && this.state.mouseActionType !== MOVE_INNERWIDGET)
          this.setState({ mouseActionType: MOVE_INNERWIDGET });

        if ('BOX' === widget.type) {
          //Get the widget definition for widgets inside Box
          //update the new nested box widget size before calling the recursive function
          widget.width = actualWidth / TILE_SIZE;
          widget.height = actualHeight / TILE_SIZE;
          props.hover = widget.hover;// This is used to show hover effect when adding widget to Box
          if (widget.innerWidgets) props.innerWidgets = this.getInnerWidgetContent(widget, selectedWidgets, isMoving);
        }

        const component = componentForWidget(widget);
        const element = React.createElement(component, props);

        return (
          <EditWidget
            id={id}
            key={id}
            type={widget.type}
            order={widget.order}
            isSelected={isSelected}
            x={1 + TILE_SIZE * x + moveX}
            y={1 + TILE_SIZE * y + moveY}
            width={actualWidth}
            height={actualHeight}
            isDragging={isMoving}
            valid={valid}
            message={message}
            isWarning={isWarning}
            render={element}
          />
        );
      })
    } catch (e) {
      console.log('ERROR ', e);
    }
  }
}

const addFromLibraryDropTarget = DropTarget(
  [dndTypes.LIBRARY_WIDGET, dndTypes.EDIT_WIDGET],
  {
    canDrop(props, monitor) {
      return true;
    },
    drop(props, monitor, component) {

      const { x: x1, y: y1 } = findDOMNode(component).getBoundingClientRect();
      if (monitor.getClientOffset() !== null) {
        const { x: x2, y: y2 } = monitor.getClientOffset();
        const { type, dragOffset } = monitor.getItem();
        props.onAddWidget(type, x2 - x1 - dragOffset.x, y2 - y1 - dragOffset.y);
      }
    }
  },
  (connect, monitor) => ({
    connectLibraryDropTarget: connect.dropTarget()
  })
);

function mapStateToProps(state) {
  return {
    selectedWidgets: getSelectedWidgets(state),
    username: getUsername(state),
    variables: getCurrentDashoardVariables(state),
    deviceList: getDeviceNames(state),
    dashboard: getSelectedDashboard(state),
    user: getUserState(state),
  };
}

function toTile(value) {
  return Math.floor(0.5 + value / TILE_SIZE);
}

function mapDispatchToProps(dispatch) {
  return {
    onMoveWidgets: (ids, dx, dy) => {
      dispatch(moveWidgets(ids, toTile(dx), toTile(dy)));
    },
    onSelectWidgets: ids => dispatch(selectWidgets(ids)),
    onDeleteWidget: id => dispatch(deleteWidget(id)),
    onAddWidget: (type, x, y) => {
      dispatch(addWidget(toTile(x), toTile(y), type, "0"));
    },
    onAddInnerWidget: (widget, x, y, parentID, innerWidget) => {
      dispatch(addInnerWidget(toTile(x), toTile(y), widget, parentID, innerWidget));
    },
    onReorderInnerWidget: (x, y, parentWidget, innerWidget) => {
      dispatch(reorderInnerWidget(toTile(x), toTile(y), parentWidget, innerWidget));
    },
    onDropInnerWidget: (x, y, innerWidget) => {
      dispatch(dropInnerWidget(toTile(x), toTile(y), innerWidget));
    },
    onResizeWidget: (id, mx, my, dx, dy) => {
      dispatch(
        resizeWidget(id, toTile(mx), toTile(my), toTile(dx), toTile(dy))
      );
    },
    onMouseDownOnWidget: isDown => {
      dispatch(mouseDownOnWidget(isDown));
    },
  };
}

const connectWithState = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default [addFromLibraryDropTarget, connectWithState].reduce(
  (cls, decorator) => decorator(cls),
  EditCanvas
);
