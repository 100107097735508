import { take, fork, call, put, race, delay } from "redux-saga/effects";
import Cookies from "universal-cookie";
import {Buffer} from 'buffer'

import {
  saveStateUrlSucces,
  saveStateUrlFailed,
  loadStateUrlSucces,
  loadStateUrlFailed,
  preloadUserSuccess,
  preloadUserFailed,
  logoutSuccess,
  loginSuccess,
  loginFailed,
  extendLogin as extendLoginAction,
  extendLoginSuccess,
  extendLoginFailed,
  saveNotificationFailed,
  saveNotificationSuccess,
  loadNotificationsFailed,
  loadNotificationsSuccess,
  clearNotificationsFailed,
  clearNotificationsSuccess,
  setNotificationsFailed,
  setNotificationsSuccess,
} from "./actionCreators";

import {
  SAVE_STATE_URL,
  LOAD_STATE_URL,
  PRELOAD_USER,
  LOGIN,
  LOGOUT,
  EXTEND_LOGIN,
  PRELOAD_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SAVE_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  NOTIFY_LOGOUT,
} from "./actionTypes";

import UserAPI from "../api";
import { NotificationLevel } from '../../notifications/notifications';

function* saveStateUrls() {
  while (true) {
    const { stateUrls } = yield take(SAVE_STATE_URL);
    const updatedStateUrls = yield call(UserAPI.saveStateUrls, stateUrls);
    const action = updatedStateUrls ? saveStateUrlSucces(updatedStateUrls) : saveStateUrlFailed();
    yield put(action);
  }
}

function* loadStateUrls() {
  while (true) {
    yield take(LOAD_STATE_URL);
    const updatedStateUrls = yield call(UserAPI.loadStateUrls);
    const action = updatedStateUrls ? loadStateUrlSucces(updatedStateUrls) : loadStateUrlFailed();
    yield put(action);
  }
}

function* preloadUser() {
  while (true) {
    yield take(PRELOAD_USER);
    const user = yield call(UserAPI.preloadUser);
    const action = user ? preloadUserSuccess({username: user.username, userGroups: user.groups}) : preloadUserFailed();
    yield put(action);
  }
}

function* login() {
  while (true) {
    const { username, password } = yield take(LOGIN);
    const result = yield call(UserAPI.login, username, password);
    const action = result ? loginSuccess({ username, userGroups: result.groups }) : loginFailed();
    yield put(action);
  }
}

function* logout() {
  while (true) {
    yield take(LOGOUT);
    yield call(UserAPI.logout);
    yield put(logoutSuccess());
  }
}

function* extendLogin() {
  while (true) {
    yield take(EXTEND_LOGIN);
    const result = yield call(UserAPI.extendLogin);
    const action = result ? extendLoginSuccess() : extendLoginFailed();
    yield put(action);
  }
}

/**
 * This function logout user on expiry of JWT token
 */
function* notifyLogout() {
  while (true) {
    const { username } = yield take(NOTIFY_LOGOUT);
    const cookies = new Cookies();
    let jwt_token = cookies.get('taranta_jwt')
    //If jwt token is not expired and the cookie is a string
    if(typeof(jwt_token) === "string")
    {
      let exp_time = new Date(JSON.parse(Buffer.from(jwt_token.split('.')[1], 'base64').toString()).exp * 1000)
      
      if (Date.now() < exp_time) {
        let delayMs = exp_time - Date.now()
        const { logout } = yield race({
          wait: delay(delayMs),
          logout: take(LOGOUT_SUCCESS),
        });

        if (!logout) {
          const notification = {
            username: username,
            level: NotificationLevel.INFO,
            message: 'You are logged out, please login to continue using dashboard.',
            notified: false,
            timestamp: Date.now().toString(),
            key: Math.random()
          };

          yield put({ type: SAVE_NOTIFICATIONS, notification: notification, username: username });
          yield put({type: LOGOUT_SUCCESS});
        }
      }
    }
  }
}

function* periodicallyExtendLogin() {
  const interval = 5 * 60 * 1000; // Five minutes

  while (true) {
    const { username } = yield take([PRELOAD_USER_SUCCESS, LOGIN_SUCCESS]);
    yield put({type: NOTIFY_LOGOUT, username: username});

    while (true) {
      const { logout } = yield race({
        wait: delay(interval),
        logout: take(LOGOUT_SUCCESS)
      });

      if (logout) {
        break;
      }

      yield put(extendLoginAction());
    }
  }
}

function* saveNotification() {
  while (true) {
    const { notification, username } = yield take(SAVE_NOTIFICATIONS);
    const notifications = yield call(UserAPI.saveNotification, notification, username);
    const action = notifications ? saveNotificationSuccess({ notifications }) : saveNotificationFailed();
    yield put(action);
  }
}

function* LoadNotifications() {
  while (true) {
    const { username } = yield take(LOAD_NOTIFICATIONS);
    const notifications = yield call(UserAPI.loadNotifications, username);
    const action = notifications ? loadNotificationsSuccess({ notifications }) : loadNotificationsFailed();
    yield put(action);
  }
}

function* ClearNotifications() {
  while (true) {
    const { username } = yield take(CLEAR_NOTIFICATIONS);
    const notifications = yield call(UserAPI.clearNotifications, username);
    const action = notifications ? clearNotificationsSuccess({ notifications }) : clearNotificationsFailed();
    yield put(action);
  }
}

function* SetNotifications() {
  while (true) {
    const { key } = yield take(SET_NOTIFICATIONS);
    const notifications = yield call(UserAPI.setNotifications, key);
    const action = notifications ? setNotificationsSuccess({ notifications }) : setNotificationsFailed();
    yield put(action);
  }
}

export default function createUserSaga() {
  return function* user() {
    yield fork(saveStateUrls);
    yield fork(loadStateUrls);
    yield fork(preloadUser);
    yield fork(login);
    yield fork(logout);
    yield fork(extendLogin);
    yield fork(periodicallyExtendLogin);
    yield fork(notifyLogout);
    yield fork(saveNotification);
    yield fork(LoadNotifications);
    yield fork(ClearNotifications);
    yield fork(SetNotifications);
  }
}
