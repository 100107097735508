import React, { useEffect, useRef, useState } from "react";
import {
  WidgetDefinition,
  StringInputDefinition,
  ColorInputDefinition,
  NumberInputDefinition,
  SelectInputDefinition,
  StyleInputDefinition,
} from "../../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import { WidgetProps } from "../types";

type Inputs = {
  text: StringInputDefinition;
  backgroundColor: ColorInputDefinition;
  textColor: ColorInputDefinition;
  linkTo: StringInputDefinition;
  font: SelectInputDefinition;
  size: NumberInputDefinition;
  automaticResize: SelectInputDefinition;
  borderWidth: NumberInputDefinition;
  borderColor: ColorInputDefinition;
  customCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

const Inner = ({
  mode,
  text,
  linkTo,
  size,
  font,
  automaticResize,
  actualWidth,
  actualHeight,
}) => {
  const textRef = useRef<SVGTextElement>(null);
  const [viewBox, setViewBox] = useState("0 0 56 18");

  useEffect(() => {
    if (automaticResize === "Enabled" && textRef.current) {
      const bbox = textRef.current.getBBox();
      setViewBox(`0 0 ${bbox.width} ${bbox.height}`);
    }
  }, [text, automaticResize, actualWidth, actualHeight]);

  const style = {
    fontSize: size + "em",
    fontFamily: font,
  };

  const prefix = linkTo.toLowerCase().startsWith("http") ? "" : "http://";
  const content = linkTo ? (
    <a href={prefix + linkTo} rel="noopener noreferrer">
      {text}
    </a>
  ) : (
    text
  );

  if (automaticResize === "Enabled") {
    return (
      <svg viewBox={viewBox}>
        <text ref={textRef} x="0" y="0" dominantBaseline="hanging">
          {content}
        </text>
      </svg>
    );
  } else {
    return <div style={style}>{content}</div>;
  }
};

const Label = (props: Props) => {
  const { inputs, mode, actualWidth, actualHeight } = props;
  const {
    text,
    backgroundColor,
    textColor,
    linkTo,
    font,
    size,
    borderWidth,
    borderColor,
    customCss,
    automaticResize,
  } = inputs;
  const parsedCss = parseCss(customCss).data;
  parsedCss["fill"] = parsedCss["color"] ? parsedCss["color"] : textColor;
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        wordBreak: "break-word",
        border: `${borderWidth}em solid ${borderColor}`,
        height: actualHeight,
        width: mode === "library" ? "100%" : actualWidth,
        overflow: "hidden",
        ...parsedCss,
      }}
    >
      <Inner
        mode={mode}
        text={text}
        linkTo={linkTo}
        size={size}
        font={font}
        automaticResize={automaticResize}
        actualWidth={actualWidth}
        actualHeight={actualHeight}
      />
    </div>
  );
};

const definition: WidgetDefinition<Inputs> = {
  type: "LABEL",
  name: "Label",
  defaultHeight: 2,
  defaultWidth: 10,
  inputs: {
    text: {
      label: "Text",
      type: "string",
      default: "",
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    borderWidth: {
      label: "Border width (in units)",
      type: "number",
      default: 0,
      nonNegative: true,
    },
    borderColor: {
      label: "Border color",
      type: "color",
      default: "#000000",
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
      ],
    },
    automaticResize: {
      type: "select",
      default: "Disabled",
      label: "Auto Resize",
      options: [
        {
          name: "Enabled",
          value: "Enabled",
        },
        {
          name: "Disabled",
          value: "Disabled",
        },
      ],
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
      dependsOn: "automaticResize",
    },
    linkTo: {
      label: "Link to",
      type: "string",
      default: "",
      placeholder: "Optional link URL",
    },
    customCss: {
      type: "style",
      default: "",
      label: "Custom css (advanced)",
    },
  },
};

const LabelExport = { component: Label, definition };
export default LabelExport;
