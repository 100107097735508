const config = window['config'];

const headers = {
    "Content-Type": "application/json; charset=utf-8"
};

const actionsExport = {
    async fetchLoggedActions(tangoDB, deviceName, limit) {
        const isGlobal = deviceName === "";
        let data = await fetch(
            config.basename + `/logs/userActionLogs?tangoDB=${tangoDB}&device=${deviceName}&limit=${limit}`,
            {
                method: "GET",
                credentials: "include",
                headers
            }
        );
        data = await data.json();
        if (isGlobal) {
            return { name: "", userActions: data.userActions };
        } else {
            return {
                name: data.device.name.toLowerCase(),
                userActions: data.device.userActions
            };
        }
    },
    async saveUserAction(data) {
        try {
            const res = await fetch(config.basename + "/logs/saveUserAction", {
                method: "POST",
                headers,
                credentials: "include",
                body: JSON.stringify(data)
            });
            if (res.status !== 200) {
                console.log('Failed so save user action');
            }
        } catch (error) {
            console.log(error);
            //feedBackService.setData({level: NotificationLevel.ERROR, message: 'User action database not reachable'});
        }
    }
}

export default actionsExport;