import { WidgetProps } from "../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import React, { Component } from "react";
import {
  WidgetDefinition,
  CommandInputDefinition,
  StringInputDefinition,
  StyleInputDefinition,
  BooleanInputDefinition,
  ColorInputDefinition,
  NumberInputDefinition,
  SelectInputDefinition,
} from "../../types";

import CommandArgsFile from "../../../shared/components/CommandArgsFile/CommandArgsFile";
import { showHideTangoDBName } from "../../DBHelper";

type Inputs = {
  title: StringInputDefinition;
  uploadBtnLabel: StringInputDefinition;
  buttonLabel: StringInputDefinition;
  command: CommandInputDefinition;
  showDevice: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showCommand: BooleanInputDefinition;
  requireConfirmation: BooleanInputDefinition;
  displayOutput: BooleanInputDefinition;
  alignSendButtonRight: BooleanInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  outerDivCss: StyleInputDefinition;
  uploadButtonCss: StyleInputDefinition;
  sendButtonCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

class CommandFile extends Component<Props> {

  public render() {
    const { mode, inputs } = this.props;
    const {
      title,
      uploadBtnLabel,
      buttonLabel,
      command,
      showDevice,
      showTangoDB,
      showCommand,
      backgroundColor,
      textColor,
      size,
      font,
      alignSendButtonRight,
      outerDivCss,
      uploadButtonCss,
      sendButtonCss,
      requireConfirmation,
      displayOutput
    } = inputs;

    const uploadBtnCss = parseCss(uploadButtonCss).data;
    const sendBtnCss = parseCss(sendButtonCss).data;
    let outerDivCssObj = parseCss(outerDivCss).data;
    if (!outerDivCssObj['backgroundColor']) outerDivCssObj['backgroundColor'] = backgroundColor;
    if (!outerDivCssObj['color']) outerDivCssObj['color'] = textColor;
    if (!outerDivCssObj['fontSize']) outerDivCssObj['fontSize'] = size;
    if (!outerDivCssObj['fontFamily']) outerDivCssObj['fontFamily'] = font;

    const { device, command: commandName } = command;
    const deviceLabel = showHideTangoDBName(showDevice, showTangoDB, device, "device");
    const commandLabel = commandName || "command";
    const sendBtnText =
      "" === buttonLabel
        ? "Send " + (null !== commandName ? commandName : "")
        : buttonLabel;

    let label = [
      ...([deviceLabel]),
      ...(showCommand ? [commandLabel] : []),
    ].join("/");

    label = (title !== undefined ? title : "") + "" + label + "";

    return (
      <div className="CommandFile" style={outerDivCssObj}>
        <CommandArgsFile
          label={label}
          uploadBtnCss={uploadBtnCss}
          uploadBtnLabel={uploadBtnLabel}
          sendBtnCss={sendBtnCss}
          sendBtnText={sendBtnText}
          mode={mode}
          command={command}
          commandName={command.command}
          commandDevice={command.device}
          requireConfirmation={requireConfirmation}
          alignSendButtonRight={alignSendButtonRight}
          displayOutput={displayOutput}
        />
      </div>
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "command_file",
  name: "Command File",
  shortDescription: "The command File widget is used to upload a file selected by the user\n read/edit its content and send it to the command",
  defaultWidth: 18,
  defaultHeight: 2,

  inputs: {
    title: {
      type: "string",
      label: "Title",
      placeholder: "Title of widget",
    },
    uploadBtnLabel: {
      type: "string",
      label: "Upload Button Text",
      default: "Upload File",
      placeholder: "Text for Upload Button",
    },
    buttonLabel: {
      type: "string",
      label: "Send Button Text",
      default: "",
      placeholder: "Text for Send Command",
    },
    command: {
      label: "Command",
      type: "command",
      intype: "NotDevVoid",
    },
    showDevice: {
      type: "boolean",
      label: "Show Device",
      default: false,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showCommand: {
      type: "boolean",
      label: "Show Command",
      default: false,
    },
    requireConfirmation: {
      type: "boolean",
      label: "Require Confirmation",
      default: true,
    },
    displayOutput: {
      type: "boolean",
      label: "Display Output",
      default: true,
    },
    alignSendButtonRight: {
      type: "boolean",
      label: "Align the Send Button to Right",
      default: true,
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 14,
      nonNegative: true,
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
        {
          name: "Verdana",
          value: "Verdana",
        },
      ],
    },
    outerDivCss: {
      type: "style",
      label: "Outer Div CSS"
    },
    uploadButtonCss: {
      type: "style",
      label: "Upload Button CSS",
    },
    sendButtonCss: {
      type: "style",
      label: "Send Button CSS",
    },
  },
};

const CommandFileExport = { component: CommandFile, definition };
export default CommandFileExport;