import React, { Component } from "react";
import classNames from "classnames";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";

import EditCanvas from "./EditCanvas/EditCanvas";
import RunCanvas from "./RunCanvas/RunCanvas";
import DeviceProvider from "./DevicesProvider";
import {
  saveDashboard,
  copyToWidgetClipboard,
  pasteFromWidgetClipboard,
  loadDashboard
} from "../../shared/state/actions/actionCreators";
import LoginDialog from "../../shared/user/components/LoginDialog/LoginDialog";
import { getDeviceIsLoading } from "../../shared/state/selectors/loadingStatus";
import Spinner from "../../shared/components/Spinner/Spinner";

import {
  getWidgets,
  getMode,
  getCanvases,
  getSelectedCanvas,
  getSelectedWidgets,
  getDashboards,
  getSelectedDashboard,
  getNotification,
} from "../../shared/state/selectors";

import { Notification as DashNotification } from "../types";

import {
  undo,
  redo,
  duplicateWidget,
  moveWidgets,
  toggleMode,
  loadDashboards
} from "../../shared/state/actions/actionCreators";

import { Widget, Canvas, Dashboard as DashboardInterface, Variable } from "../types";
import { IRootState } from "../../shared/state/reducers/rootReducer";

import "./Dashboard.css";
import Sidebar from "./Sidebar";
import { MemoTopBar as TopBar } from "./TopBar";
import { getIsLoggedIn, getUsername } from "../../shared/user/state/selectors";
import { getUserGroups } from "../../shared/state/selectors";

import LeftSidebar from "./LeftSidebar";
import {
  TOGGLE_INSPECTOR_COLLAPSED,
  TOGGLE_LIBRARY_COLLAPSED,
  WIDGET_INVALID,
} from "../../shared/state/actions/actionTypes";

import { saveNotification } from "../../shared/user/state/actionCreators";
import { Notification } from "../../shared/notifications/notifications";
import { getAllInnerWidgetsById } from "../../shared/utils/canvas";
import { fetchDeviceNames } from "../../shared/state/actions/tango";
import { generateUUID } from "../../shared/utils/generateUUID";
import { isEditableDashboard } from "../utils"

interface Match {
  tangoDB: string;
}

interface Props extends RouteComponentProps<Match> {
  toggleMode: () => void;
  loadDashboard: (id: string) => void;
  loadDashboards: () => void;
  saveDashboard: (id: string, name: string, widgets: Widget[], variables: Variable[], environment: string[]) => void;
  mode: "edit" | "run";
  widgets: Widget[];
  selectedWidgets: Widget[];
  canvases: Canvas[];
  selectedCanvas: Canvas;
  dashboards: DashboardInterface[],
  selectedDashboard: DashboardInterface;
  isLoggedIn: boolean;
  onUndo: () => void;
  onRedo: () => void;
  onDuplicateWidget: () => void;
  onCopyWidget: (widgets: Widget[]) => void;
  onPasteWidget: () => void;
  onMoveWidgets: (ids, dx, dy) => void;
  toggleInspectorCollapse: () => void;
  toggleLibraryCollapse: () => void;
  dashNotification: DashNotification;
  username: string;
  onSaveNotification: (notification: Notification, username: string) => void;
  loadDeviceNames: (tangoDB: string) => void;
  loading: boolean;
  userGroups: string[];
}

class Dashboard extends Component<Props> {
  public constructor(props) {
    super(props);
    this.toggleMode = this.toggleMode.bind(this);
  }

  public async componentDidMount() {
    this.props.loadDeviceNames(this.props.match.params.tangoDB);

    const { id, mode } = this.parseUrlQuery();
    this.props.loadDashboards();

    if (id) {
      this.props.loadDashboard(id);
    }
    if (mode && mode !== this.props.mode) {
      this.props.toggleMode();
    }
  }

  public async componentDidUpdate(prevProps) {
    const { id: currentId, name } = this.props.selectedDashboard;
    const { name: oldName } = prevProps;
    const { id, mode } = this.parseUrlQuery();
    if (currentId !== id) {
      if (currentId) {
        this.props.history.replace(
          "?id=" + currentId + (mode ? "&mode=" + mode : "")
        );
      } else {
        this.props.history.replace("?" + (mode ? "mode=" + mode : ""));
      }
    }
    if (name && name !== oldName) {
      document.title = name + " - Taranta";
    }
  }
  public editCanvasHotKeyHandler = (event) => {
    if (this.props.mode !== "edit") {
      return;
    }
    const dashboard = this.props.selectedDashboard as any;
    const user = {username: this.props.username, userGroups: this.props.userGroups} as any;
    const editableDashboard = isEditableDashboard(dashboard, user).result;
    //non-os specific hotkeys:
    if(editableDashboard) {
    if (event.altKey === true && event.key === "i") {
      if (this.props.selectedWidgets.length > 0) {
        this.props.toggleInspectorCollapse();
        event.preventDefault();
        return;
      }
    } else if (event.altKey === true && event.key === "l") {
      this.props.toggleLibraryCollapse();
      event.preventDefault();
      return;
    } else if (event.key === "ArrowLeft") {
      const ids = this.props.selectedWidgets.map(({ id }) => id);
      this.props.onMoveWidgets(ids, -1, 0);
      event.preventDefault();
      return;
    } else if (event.key === "ArrowUp") {
      const ids = this.props.selectedWidgets.map(({ id }) => id);
      this.props.onMoveWidgets(ids, 0, -1);
      event.preventDefault();
      return;
    } else if (event.key === "ArrowRight") {
      const ids = this.props.selectedWidgets.map(({ id }) => id);
      this.props.onMoveWidgets(ids, 1, 0);
      event.preventDefault();
      return;
    } else if (event.key === "ArrowDown") {
      const ids = this.props.selectedWidgets.map(({ id }) => id);
      this.props.onMoveWidgets(ids, 0, 1);
      event.preventDefault();
      return;
    }
    switch (window.navigator.platform) {
      case "MacIntel":
        //cmd+shift+z
        if (
          event.metaKey === true &&
          event.shiftKey === true &&
          event.key === "z"
        ) {
          this.props.onRedo();
          event.preventDefault();
        }
        //cmd+z
        else if (event.metaKey === true && event.key === "z") {
          this.props.onUndo();
          event.preventDefault();
        }
        //cmd+d
        else if (event.metaKey === true && event.key === "d") {
          this.props.onDuplicateWidget();
          event.preventDefault();
        }
        //cmd+c
        else if (event.metaKey === true && event.key === "c") {
          this.props.onCopyWidget(this.props.selectedWidgets);
          event.preventDefault();
        }
        //cmd+v
        else if (event.metaKey === true && event.key === "v") {
          this.props.onPasteWidget();
          event.preventDefault();
        }
        break;
      default:
        //ctrl+y
        if (event.ctrlKey === true && event.key === "y") {
          this.props.onRedo();
          event.preventDefault();
        }
        //ctrl+z
        else if (event.ctrlKey === true && event.key === "z") {
          this.props.onUndo();
          event.preventDefault();
        }
        //ctrl+d
        else if (event.ctrlKey === true && event.key === "d") {
          this.props.onDuplicateWidget();
          event.preventDefault();
        }
        //ctrl+c
        else if (event.ctrlKey === true && event.key === "c") {
          this.props.onCopyWidget(this.props.selectedWidgets);
        }
        //ctrl+v
        else if (event.ctrlKey === true && event.key === "v") {
          this.props.onPasteWidget();
        }
    }
  }
  };

  public render() {
    const { mode, widgets, selectedWidgets } = this.props;
    const { tangoDB } = this.props.match.params;
    const allWidgets = getAllInnerWidgetsById({...this.props.widgets}, true);
    const disabled = !this.areAllValid(allWidgets) || !this.isRootCanvas();

    if (this.props.loading) {
      return <Spinner size={4} />;
    }

    // Display notifications coming from dashboards
    if (this.props.dashNotification.level !== "Undefined" && this.props.username) {
      const notification: Notification = {
        username: this.props.username,
        level: this.props.dashNotification.level,
        message: this.props.dashNotification.msg,
        notified: false,
        timestamp: Date.now().toString(),
        key: generateUUID()
      };
      this.props.onSaveNotification(notification, this.props.username);
    }

    const allprops = {
      widgets: this.props.widgets,
      tangoDB: tangoDB,
      onSaveNotification: () => {},
    }

    const variableNames = this.getDashboardVariableNames();
    const canvasContents =
      mode === "edit" ? (
        <EditCanvas
          hotKeyHandler={this.editCanvasHotKeyHandler}
          widgets={widgets}
          tangoDB={tangoDB}
        />
      ) : widgets.length > 0 ? (
        <RunCanvas {...allprops} />
      ) : <div>No Widgets found to Run Canvas</div>;

    return (
      <div className="Dashboard">
        <LoginDialog />
        <DeviceProvider tangoDB={tangoDB} variables={variableNames} selectedWidgets={selectedWidgets}>
          <TopBar
            mode={mode}
            onToggleMode={this.toggleMode}
            modeToggleDisabled={disabled}
          />
          <div className={classNames("CanvasArea", mode)}>{canvasContents}</div>
          <LeftSidebar
            mode={mode}
            tangoDB={tangoDB}
            selectedWidgets={selectedWidgets}
          />
          <Sidebar
            mode={mode}
            selectedTab="dashboards"
            tangoDB={tangoDB}
            selectedWidgets={selectedWidgets}
          />
        </DeviceProvider>
      </div>
    );
  }

  private getDashboardVariableNames() {
    let variables: string[] = [];
    const selectedDashboardId = this.props.selectedDashboard.id;

    for (let i = 0; i < this.props.dashboards.length; i++) {
      //Match the dashboard
      const dashboard = this.props.dashboards[i];
      if (dashboard.id === selectedDashboardId && undefined !== dashboard.variables) {
        for (let j = 0; j < dashboard.variables.length; j++) {
          variables.push(dashboard.variables[j].name);
        }
      }
    }

    return variables;
  }

  private toggleMode() {
    const { mode, selectedDashboard } = this.props;
    const { id } = selectedDashboard;
    this.props.history.replace(
      "?id=" + id + (mode === "edit" ? "&mode=run" : "")
    );
    this.props.toggleMode();
  }
  private isRootCanvas() {
    return this.props.selectedCanvas.id === "0";
  }

  private areAllValid(allWidgets: Widget[]) {
    return allWidgets.reduce((prev, widget) => prev && (WIDGET_INVALID !== widget.valid), true);
  }

  private parseUrlQuery(): { id: string; mode: String } {
    /* eslint-disable no-restricted-globals */
    const search = location.search;
    const parsed = queryString.parse(search);
    return { id: parsed.id || "", mode: parsed.mode || "" };
  }
}

function mapStateToProps(state: IRootState) {
  return {
    widgets: getWidgets(state),
    selectedDashboard: getSelectedDashboard(state),
    dashboards: getDashboards(state),
    selectedWidgets: getSelectedWidgets(state),
    mode: getMode(state),
    selectedCanvas: getSelectedCanvas(state),
    canvases: getCanvases(state),
    isLoggedIn: getIsLoggedIn(state),
    dashNotification: getNotification(state),
    username: getUsername(state),
    loading: getDeviceIsLoading(state),
    userGroups: getUserGroups(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveDashboard: (id: string, name: string, widgets: Widget[], variables: Variable[], environment: string[]) =>
      dispatch(saveDashboard(id, name, widgets, variables, environment)),
    toggleMode: () => dispatch(toggleMode()),
    loadDashboard: (id: string) => dispatch(loadDashboard(id)),
    loadDashboards: () => dispatch(loadDashboards()),
    onUndo: () => dispatch(undo()),
    onRedo: () => dispatch(redo()),
    onDuplicateWidget: () => dispatch(duplicateWidget()),
    onCopyWidget: (widgets: Widget[]) => {
      dispatch(copyToWidgetClipboard(widgets));
    },
    onPasteWidget: () => {
      dispatch(pasteFromWidgetClipboard());
    },
    onMoveWidgets: (ids, dx, dy) => {
      dispatch(moveWidgets(ids, dx, dy));
    },
    toggleInspectorCollapse: () =>
      dispatch({ type: TOGGLE_INSPECTOR_COLLAPSED }),
    toggleLibraryCollapse: () => dispatch({ type: TOGGLE_LIBRARY_COLLAPSED }),
    onSaveNotification: (notification: Notification, username: string) => dispatch(saveNotification(notification, username)),
    loadDeviceNames: (tangoDB: string) => dispatch(fetchDeviceNames(tangoDB)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(Dashboard));
