import { IndexPath, SynopticEditHistory } from "../../../types";

const HISTORY_SIZE = 100;

export function validateSVG(svgXML: string) {
  let validationResult = true;
  let importError: string[] = [];

  return { validationResult, importError };
}

export function undo(history: SynopticEditHistory) {
  if (history.undoLength === 0) {
    return { history };
  }
  //pull latest value from UNDO
  history.undoIndex =
    history.undoIndex === 0 ? HISTORY_SIZE - 1 : history.undoIndex - 1;
  history.undoLength = history.undoLength === 0 ? 0 : history.undoLength - 1;

  //push latest value from UNDO onto REDO
  history.redoLength =
    history.redoLength === HISTORY_SIZE ? HISTORY_SIZE : history.redoLength + 1;
  history.redoIndex = (history.redoIndex + 1) % HISTORY_SIZE;
  //TODO what can be undo in the synoptic?
  //history.redoActions[history.redoIndex] = widgets;
  return { history };
}

export function redo(history: SynopticEditHistory) {
  if (history.redoLength === 0) {
    return { history };
  }
  //push old widget as new action to UNDO
  history.undoLength =
    history.undoLength === HISTORY_SIZE ? HISTORY_SIZE : history.undoLength + 1;
  //history.undoActions[history.undoIndex] = widgets;
  history.undoIndex = (history.undoIndex + 1) % HISTORY_SIZE;

  //pull, update and return from REDO
  history.redoLength = history.redoLength === 0 ? 0 : history.redoLength - 1;
  history.redoIndex =
    history.redoIndex === 0 ? HISTORY_SIZE - 1 : history.redoIndex - 1;

  return { history };
}

export function pushToHistory(history: SynopticEditHistory) {
  history.undoLength =
    history.undoLength === HISTORY_SIZE ? HISTORY_SIZE : history.undoLength + 1;
  history.undoIndex = (history.undoIndex + 1) % HISTORY_SIZE;
  //invalidate REDO stack at a regular action
  history.redoLength = 0;
  return history;
}

const DELETE = Symbol("DELETE");
const ADD = Symbol("ADD");
const REPLACE = Symbol("REPLACE");
const MULTIPLE_COMMANDS = Symbol("MULTIPLE_COMMANDS");

type Mode =
  | typeof DELETE
  | typeof ADD
  | typeof REPLACE
  | typeof MULTIPLE_COMMANDS;

function handleMultipleCommands(
  obj: object,
  head: string | number,
  value: any,
  replacement: any
) {
  let values: string[] = [];

  if (typeof obj[head].command === "string") {
    values.push(obj[head].command);
  } else if (obj[head].command && obj[head].command.length > 0) {
    values = [...obj[head].command];
  }

  if (value.command) {
    if (values.indexOf(value.command) === -1) {
      values.push(value.command);
    } else {
      values = values.filter((val) => val !== value.command);
    }
  }

  replacement.command = values;

  return replacement;
}
export function setWithIndexPath(
  obj: object,
  path: IndexPath,
  value: any,
  mode: Mode
) {
  const [head, ...tail] = path;
  let replacement =
    tail.length > 0 ? setWithIndexPath(obj[head], tail, value, mode) : value;

  if (Array.isArray(obj)) {
    const copy = obj.concat();
    if (typeof head !== "number") {
      throw new Error("head must be an integer when obj is an array");
    } else {
      if (mode === DELETE) {
        copy.splice(head, 1);
      } else if (mode === REPLACE) {
        copy.splice(head, 1, replacement);
      } else if (mode === ADD) {
        copy.splice(0, 0, replacement);
      }
    }
    return copy;
  } else {
    if (mode === MULTIPLE_COMMANDS && head === "command") {
      replacement = handleMultipleCommands(obj, head, value, replacement);
    }

    return { ...obj, [head]: replacement };
  }
}
