import React, { useState } from "react";
import { Button } from "react-bootstrap";

import Modal from "../../../shared/modal/components/Modal/Modal";

const ShareDashboardModal = ({
  name,
  id,
  onShare,
  userGroups,
  currentGroup,
  currentGroupWriteAccess,
  onClose
}) => {
  const [selectedGroup, setSelectedGroup] = useState(currentGroup);
  const [groupWriteAccess, setGroupWriteAccess] = useState(currentGroupWriteAccess);
  return (
    <Modal title="Share dashboard">
      <Modal.Body>
        <div style={{ marginBottom: "0.5em" }}>
          Share <span style={{ fontStyle: "italic" }}>{name}</span> with
        </div>
        <select
          className="form-control"
          onChange={e => setSelectedGroup(e.target.value)}
          defaultValue={selectedGroup}
        >
          <option value={""}>No one</option>
          {userGroups.map(group => {
            return (
              <option key={group} value={group}>
                {group}
              </option>
            );
          })}
        </select>
        <label style={{marginTop: "0.5em"}} htmlFor={"write"}>
          <input
            id={"write"}
            type="checkbox"
            checked={groupWriteAccess}
            onChange={e => setGroupWriteAccess(e.target.checked)}
          />
          {" "}Allow the members of the group to edit this dashboard
        </label>
        <div style={{ marginTop: "1em", fontSize: "0.8em" }}>
          If you want to share a copy of this dashboard you can simply share the
          current URL, and anyone accessing it will be given the option to clone
          it
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button 
            variant="outline-secondary"
            onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => onShare(id, selectedGroup, groupWriteAccess)}
        >
          Share
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareDashboardModal;
