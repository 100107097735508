import React, { Component } from "react";
import { Button } from "react-bootstrap";
import TangoAPI from "../../../shared/api/tangoAPI";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import { getRunningDevices, checkVariableDevice } from "../../utils/SynopticVariables";
import { getDevicePropertiesFromVariables } from '../../utils/SynopticVariables'

import Modal from "../../../shared/modal/components/Modal/Modal";

export default class ConfigSynopticModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddBox: false,
      variableExist: false,
      editVariable: 0,
      confirmDelete: 0,
      addVariableName: '',
      addVariableClass: '',
      addVariableDevice: '',
      successMsg: '',
      showSuccessMsg: false,
      contentHeaderTitle: ['Variable Name', 'Class Name', 'Default Device', 'Actions'],
      updatedVariables: [],
      tangoClasses: [],
      tangoDevices: [],
      tangoDevicesEdit: [],
      synopticVariablesDevices: []
    }
  }

  componentDidMount() {
    this.fetchClasses();
    getDevicePropertiesFromVariables(this.props.tangoDB, this.props.synopticVariables).then(devices =>
      this.setState({ synopticVariablesDevices: devices }));
  }

  handleAddVariable(event) {
    event.preventDefault();
    this.props.addSynopticVariable(this.props.id, this.state.addVariableName, this.state.addVariableClass, this.state.addVariableDevice);
    this.setState({ showAddBox: false, showSuccessMsg: true, successMsg: 'Variable added successfully', addVariableClass: '', addVariableDevice: '' });

    const newSynopticVariable = {
      name: this.state.addVariableName,
      class: this.state.addVariableClass,
      device: this.state.addVariableDevice
    };
    // Only fetch attributes of the device if device is not present in this.state.synopticVariablesDevices
    if (!this.state.synopticVariablesDevices.find(d => d.name === newSynopticVariable.device)) {
      getDevicePropertiesFromVariables(this.props.tangoDB, [newSynopticVariable]).then(devices => {
        this.setState({ synopticVariablesDevices: [...devices, ...this.state.synopticVariablesDevices] });
      }
      );
    }

  }

  handleDeleteVariable(VariableName, confirm) {
    if (confirm) {
      this.props.deleteSynopticVariable(this.props.id, VariableName);
      this.setState({ showSuccessMsg: true, successMsg: 'Variable deleted successfully' });
      this.setState({ confirmDelete: 0 })
    }
    else {
      this.setState({ confirmDelete: VariableName })
    }
  }

  handleSaveVariable(variableId) {
    this.setState({ editVariable: 0 });
    this.props.saveSynopticVariables(variableId, this.props.id);
    this.setState({ showSuccessMsg: true, successMsg: 'Variables saved successfully' });

    const newSynopticVariable = {
      name: variableId,
      class: this.state.addVariableClass,
      device: this.state.addVariableDevice
    };
    // Only fetch attributes of the device if device is not present in this.state.synopticVariablesDevices
    if (!this.state.synopticVariablesDevices.find(d => d?.name === newSynopticVariable.device)) {
      getDevicePropertiesFromVariables(this.props.tangoDB, [newSynopticVariable]).then(devices => {
        this.setState({ synopticVariablesDevices: [...devices, ...this.state.synopticVariablesDevices] });
      }
      );
    }
  }

  handleEditVariable(VariableName, confirm, variableClass) {
    if (!confirm) {
      this.setState({ editVariable: VariableName });
      this.fetchDevices(variableClass, 'editVariable')
    }
    else {
      this.setState({ editVariable: 0 });
    }
  }

  handleChangeDeviceClassDropdown(event) {
    this.setState({ addVariableClass: event.target.value }, () => {
      if (this.state.tangoClasses.filter((c) => { return c.name === this.state.addVariableClass }).length > 0) {
        this.fetchDevices(this.state.addVariableClass);
      }
    })
  }

  handleChangeDeviceDropdown(event) {
    this.setState({ addVariableDevice: event.target.value });
  }

  handleChangeVariableName(event) {
    if (this.props.synopticVariables.filter((v) => { return v.name === event.target.value }).length > 0)
      this.setState({ variableExist: true })
    else {
      this.setState({ addVariableName: event.target.value, variableExist: false });
    }
  }

  handleChangeEditDeviceDropdown(event, variableName) {
    this.setState({ addVariableDevice: event.target.value });
    this.props.updateSynopticVariable(variableName, 'Device', event.target.value);
  }

  handleChangeEditDeviceClassDropdown(event, variableName) {
    const newDeviceClassName = event.target.value;
    this.setState({ addVariableClass: newDeviceClassName });
    this.props.updateSynopticVariable(variableName, 'Class', newDeviceClassName);
    this.fetchDevices(newDeviceClassName, 'editVariable').then(() => {
      const defaultDevice = this.state.tangoDevicesEdit.length > 0 ? this.state.tangoDevicesEdit[0].name : '';
      this.setState({ addVariableDevice: defaultDevice });
      this.props.updateSynopticVariable(variableName, 'Device', defaultDevice);
    })
  }

  handleCancel() {
    this.setState({ editVariable: 0 });
    this.props.handleCancel();
  }

  deviceExistsInSynopticVariableDevices(device) {
    // Without this function, the user may see "Device not exported/running" for a fraction of second when adding/editing a variable
    const stateDevices = this.state.synopticVariablesDevices;
    return stateDevices.some(function (d) {
      return d?.name === device;
    });
  }

  render() {
    let objThis = this;

    return (
      <Modal title="Configure Synoptic Variables" size="lg">
        <Modal.Body>
          <div className="config-wrapper">
            <div className="config-header">
              {this.state.showSuccessMsg === true &&
                <Alert
                  className={`modal-alert ${this.state.showSuccessMsg ? 'alert-animate' : ''}`}
                  variant='success'
                  onAnimationEnd={() => this.setState({ showSuccessMsg: false })}
                >{this.state.successMsg}</Alert>
              }

              {this.state.showAddBox === true &&
                <form onSubmit={(e) => this.handleAddVariable(e)}>
                  <div className="row">
                    <h6 className="col form-group">Add new variable</h6>
                  </div>
                  <div className="row">
                    <div className="col col-md-3 col-lg-4 col-sm-6 form-group">
                      <label htmlFor="add-new-var-name" className="form-label">Variable Name</label>
                      <input type="text" id="add-new-var-name" name="add-new-var-name" required pattern="^[a-zA-Z]{1}[\w]{1,14}$" title="Variable name must be between 2-15 characters, its aplhanumeric and should start with alphabet" className="form-control" placeholder="Variable Name" onChange={(e) => this.handleChangeVariableName(e)} />
                      {this.state.variableExist === true &&
                        <span className="error">Variable already exist {this.state.variableExist}</span>
                      }

                    </div>
                    <div className="col col-md-3 col-lg-4 col-sm-6 form-group">
                      <label htmlFor="add-new-var-class" className="form-label">Device Class</label>
                      <select className="form-control"
                        title="Select Device Class"
                        required
                        id="add-new-var-class"
                        name="add-var-class"
                        onChange={(e) => this.handleChangeDeviceClassDropdown(e)}
                      >
                        <option value="">Select</option>
                        {this.state.tangoClasses.map((tangoClass, j) =>
                          <option key={j} value={tangoClass.name}>{tangoClass.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col col-md-3 col-lg-4 col-sm-6 form-group">
                      <label htmlFor="add-new-var-device" className="form-label">Default Device</label>

                      <select className="form-control"
                        title="Select Device Class"
                        required
                        id="add-new-var-device"
                        name="add-var-device"
                        onChange={(e) => this.handleChangeDeviceDropdown(e)}
                        value={this.state.addVariableDevice}
                      >
                        {/* {this.state.addVariableClass.length !== 0 &&
                          getRunningDevices(this.state.tangoDevices).map((tangoDevice, j) =>
                            <option key={j} value={tangoDevice.name}>{tangoDevice.name}</option>
                          )
                        } */}
                        {this.state.tangoDevices.length === 0 ?
                          <option value="" disabled> No devices </option> : <option value="" disabled> Select device </option>
                        }
                        {
                          this.state.tangoDevices.map((tangoDevice, j) =>
                            <option key={j} value={tangoDevice.name}>{tangoDevice.name}</option>
                          )
                        }


                      </select>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col form-group d-flex justify-content-end">
                      <button type="button" onClick={() => this.setState({ showAddBox: !this.state.showAddBox })} className="btn btn-outline-secondary mb-2">Close</button>
                    </div>
                    <div className="form-group">
                      <button type="submit" disabled={this.state.variableExist} id="btn-add-variable" onSubmit={(e) => this.handleAddVariable(e)} className="btn btn-primary mb-2">Add Variable</button>
                    </div>
                  </div>
                </form>
              }
            </div>

            <div className="config-content">
              <div className="row m-0 mb-1">
                <div className="pull-left has-search col col-md-3 col-lg-4 col-sm-6">
                  <div className="has-search">
                    <span className="fa fa-search form-control-search"></span>
                    <input type="search" id="config-search-variable" className="form-control" placeholder="Search Variable" onChange={(e) => this.props.filterSynopticVariables(e.currentTarget.value)} />
                  </div>
                </div>
                <div className="col col-lg-5"></div>
                <div className="col col-md-3 col-lg-3 col-sm-6">
                  {!this.state.showAddBox &&
                    <button title="Add New Synoptic Variable" className="btn btn-outline-dark pull-right add-variable-form" type="button" onClick={(e) => this.setState({ showAddBox: !this.state.showAddBox })}>
                      Add new variable
                    </button>
                  }
                </div>
              </div>

              <div className="row m-0 header-row" style={{ borderTop: "1px solid #dee2e6" }}>
                {this.state.contentHeaderTitle.map((title) => {
                  return <div key={title} className="col col-md-3 col-lg-3 col-sm-6">
                    <label className={`form-label text-muted ${title === "Actions" ? "pull-right" : ""}`}>{title}</label>
                  </div>
                })}
              </div>

              {this.props.synopticVariables.length === 0 &&
                <div className="d-flex justify-content-center text-muted">No record found.</div>
              }
              {this.props.synopticVariables.length > 0 && this.props.synopticVariables.map(function (variable) {
                return <div key={variable.name} className="modal-content-row">
                  <div className="edit-view">
                    <div className="row m-0">
                      <span className="col col-md-3 col-lg-3 col-sm-6 d-flex align-items-center label-div">{variable.name}</span>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        {objThis.state.editVariable === variable.name &&
                          <select
                            className="form-control list-class-name"
                            name="list-class-name"
                            value={variable.class}
                            onChange={(e) => {
                              objThis.handleChangeEditDeviceClassDropdown(
                                e,
                                variable.name
                              )
                            }
                            }
                          >
                            {objThis.state.tangoClasses.map((tangoClass, j) =>
                              <option key={j} value={tangoClass.name}>{tangoClass.name}</option>
                            )}
                          </select>
                        }
                        {objThis.state.editVariable !== variable.name &&
                          <span>
                            {variable.class}
                          </span>

                        }
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        {objThis.state.editVariable === variable.name &&
                          <select
                            className="form-control list-device-name"
                            name="list-device-name"
                            value={variable.device}
                            onChange={(e) => {
                              objThis.handleChangeEditDeviceDropdown(
                                e,
                                variable.name
                              )
                            }}
                          >

                            {objThis.state.tangoDevicesEdit.map((tangoDevice, j) =>
                              <option key={j} value={tangoDevice.name}>{tangoDevice.name}</option>
                            )
                            }
                          </select>
                        }
                        {objThis.state.editVariable !== variable.name &&
                          <span>
                            {variable.device}
                            {(objThis.deviceExistsInSynopticVariableDevices(variable.device) && checkVariableDevice(variable.device, objThis.state.synopticVariablesDevices).exported === false && objThis.state.tangoClasses.length > 0) &&
                              <div className="error">Device not exported</div>
                            }
                            {(objThis.deviceExistsInSynopticVariableDevices(variable.device) && checkVariableDevice(variable.device, objThis.state.synopticVariablesDevices).connected === false && objThis.state.tangoClasses.length > 0) &&
                              <div className="error">Device not running</div>
                            }
                          </span>
                        }
                      </div>
                      <div className="col-sm-6 col-md-1 col-lg-3">
                        {(objThis.state.editVariable !== variable.name && objThis.state.confirmDelete !== variable.name) &&
                          <div>
                            <button
                              title="Delete Variable"
                              onClick={(e) => objThis.handleDeleteVariable(variable.name, false)}
                              className="btn btn-outline-secondary btn-sm btn-layer-action pull-right btn-delete"
                            >
                              <FontAwesomeIcon icon="trash" />{" "}
                            </button>
                            <button
                              title="Edit Variable"
                              onClick={(e) => objThis.handleEditVariable(variable.name, false, variable.class)}
                              className="btn btn-outline-secondary btn-sm btn-layer-action pull-right btn-edit"
                            >
                              <FontAwesomeIcon icon="edit" />{" "}
                            </button>
                          </div>
                        }
                        {objThis.state.editVariable === variable.name &&
                          <div>
                            <button
                              title="Save Variable"
                              onClick={(e) => objThis.handleSaveVariable(variable?.name)}
                              className="btn btn-primary pull-right"
                            >
                              Save
                            </button>
                            <button
                              title="Cancel"
                              onClick={(e) => objThis.handleCancel()}
                              className="btn btn-outline-secondary pull-right"
                            >
                              Cancel
                            </button>
                          </div>
                        }
                        {objThis.state.confirmDelete === variable.name &&
                          <div>
                            <span className="row">Are you sure to delete {variable.name}? </span>
                            <div className="row pull-right">
                              <button
                                title="No"
                                onClick={(e) => { objThis.setState({ editVariable: 0 }); objThis.setState({ confirmDelete: 0 }); }}
                                className="btn btn-outline-secondary pull-right"
                              >
                                No
                              </button>
                              <button
                                title="Yes, delete my variable"
                                onClick={(e) => objThis.handleDeleteVariable(variable.name, true)}
                                className="btn btn-primary pull-right confirm-delete"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  async fetchClasses() {
    const { tangoDB } = this.props;
    const data = await TangoAPI.fetchAllClasses(tangoDB);

    this.setState({ tangoClasses: data });
  }

  async fetchDevices(targetClass, forComponent = 'addVariable') {
    const { tangoDB } = this.props;
    if (targetClass !== '') {
      const devices = await TangoAPI.fetchClassAndDevices(tangoDB, targetClass);
      const runningDevices = getRunningDevices(devices[0]?.devices);
      if (forComponent === 'addVariable') {
        this.setState({ tangoDevices: runningDevices });
      }
      if (forComponent === 'editVariable') {
        this.setState({ tangoDevicesEdit: runningDevices });
      }

      return runningDevices;
    }
  }
}

ConfigSynopticModal.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  synopticVariables: PropTypes.any,
  filterSynopticVariables: PropTypes.func,
  deleteSynopticVariable: PropTypes.func,
  addSynopticVariable: PropTypes.func,
  updateSynopticVariable: PropTypes.func,
  saveSynopticVariables: PropTypes.func,
  handleCancel: PropTypes.func
};
