import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAttributes } from "../../../shared/state/actions/tango"
import { getAttributeList } from "../../../shared/state/selectors/attributeList";
import { DeviceConsumer } from "../DevicesProvider";
import DeviceSuggester from "./DeviceSuggester";
import AttributeSuggester from "./AttributeSuggester";
import { Variable } from "../../types";
import { getDeviceOfDashboardVariable } from "../../../shared/utils/DashboardVariables";
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath } from "../../runtime/utils";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import TangoDbSelector from "../TangoDbSelector";
import { splitFullPath } from "../../DBHelper"


interface Props {
  tangoDB: string;
  widgetId: string;
  device?: string;
  attribute?: string;
  label?: string;
  dataFormat?: "scalar" | "spectrum" | "image";
  dataType?: "numeric" | "enum" | "string";
  onSelect?: (device: string | null, attribute: string | null, label: string | null) => void;
  nonEditable: boolean;
  variables: Variable[];
  hideDeviceSuggester?: boolean;
  loadAttributes: (tangoDB: string, device: string[]) => Attribute[];
  attributesList?: Attribute[];
}

interface State {
  fetchingAttributes: boolean;
  attributes: Array<{
    name: string;
    label: string;
    datatype: string;
    dataformat: string;
  }>;
}

interface Attribute {
  name: string;
  label: string;
  dataformat: string;
  datatype: string;
}

export class AttributeSelect extends Component<Props, State> {
  
  public constructor(props: Props) {
    super(props);
    this.state = { fetchingAttributes: false, attributes: [] };
    this.handleSelectDevice = this.handleSelectDevice.bind(this);
    this.handleSelectAttribute = this.handleSelectAttribute.bind(this);
    this.cleanDevices = this.cleanDevices.bind(this);
  }

  public async componentDidMount() {
    this.getAttributesForDevice();
  }

  public componentDidUpdate(prevProps) {
    if (this.props.device !== prevProps.device) {
      this.setState({ attributes: [] });
      this.getAttributesForDevice();
    }
  }

  public handleSelectDevice(newDevice: string, tangoDB: string) {
    this.getAttributesForDevice();
    const { onSelect } = this.props;
    if (onSelect && newDevice) {
      const fullPath = createDeviceWithTangoDBFullPath(tangoDB, newDevice);
      onSelect(fullPath, null, null);
    }
  }

  public handleSelectAttribute(newAttribute: string) {
    const { dataType, dataFormat } = this.props;

    const attributes = this.filteredAttributes(this.props.attributesList ? this.props.attributesList : [], dataType ? dataType : "", dataFormat ? dataFormat : "");
    const labels = attributes.map(({ label }) => label);
    const names = attributes.map(({ name }) => name);
    const indexLabel = labels.indexOf(newAttribute);
    const { onSelect, device } = this.props;

    if (onSelect && device && newAttribute) {
      onSelect(device, names[indexLabel], labels[indexLabel]);
    }
  }

  public cleanDevices() {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect("", null, null);
    }
  }

  public render() {
    const { device, attribute, label, nonEditable } = this.props;
    return (
      <DeviceConsumer>
        {({ devices, onTangoDBChange, tangoDB }) => {
          const hasDevice = this.props.hideDeviceSuggester ? true : device != null && device !== "";
          const tangoDBActive = device ? getTangoDbFromPath(device) : tangoDB;

          return (
            <div className="AttributeSelect">
              {!this.props.hideDeviceSuggester && (
                <>
                <TangoDbSelector
                  widgetId={this.props.widgetId}
                  onSelect={(tangoDbSelected) => {
                    this.cleanDevices();
                    onTangoDBChange(tangoDbSelected)
                  }}
                  tangoDB={tangoDBActive}
                  hasDevice={!!device}
                />
                <DeviceSuggester
                    deviceName={device}
                    devices={devices}
                    onSelection={newValue => this.handleSelectDevice(newValue, tangoDBActive)}
                    nonEditable={nonEditable}
                    variables={this.props?.variables}
                  />
                </>
              )}

              <AttributeSuggester
                // @ts-ignore
                attributeName={attribute}
                attributeLabel={label}
                hasDevice={hasDevice}
                onSelection={newValue => this.handleSelectAttribute(newValue)}
                filter={(attributeList, dataType, dataFormat) => this.filteredAttributes(attributeList, dataType, dataFormat)}
                nonEditable={nonEditable}
                dataType={this.props.dataType}
                dataFormat={this.props.dataFormat}
              />
            </div>
          );
        }}
      </DeviceConsumer>
    );
  }

  private filteredAttributes(attributes: Attribute[], dataType: string, dataFormat: string) {
    const numericTypes = [
      "DevDouble",
      "DevFloat",
      "DevLong",
      "DevLong64",
      "DevShort",
      "DevUChar",
      "DevULong",
      "DevULong64",
      "DevUShort"
    ];


    return attributes.filter(attr => {
      if (dataFormat === "scalar" && attr.dataformat !== "SCALAR") return false;
      else if (dataFormat === "spectrum" && attr.dataformat !== "SPECTRUM") return false;
      else if (dataFormat === "image" && attr.dataformat !== "IMAGE") return false;
      else if (dataType === "numeric" && numericTypes.indexOf(attr.datatype) === -1) return false;
      else if (dataType === "enum" && !["devstate", "devenum"].includes(attr.datatype.toLocaleLowerCase())) return false;
      else return true;
    });
  }

  private async getAttributesForDevice() {
    let { device } = this.props;
    let attributes: Attribute[] = [];

    if (device) {
      let [tangoDB, devName] = splitFullPath(device);

      devName = devName.includes('/') ? devName : getDeviceOfDashboardVariable(this.props.variables, devName);
      this.setState({ attributes: [], fetchingAttributes: true });

      if (devName && devName.includes(',')) {
        this.props.loadAttributes(tangoDB, devName.split(","))
        if(this.props.attributesList)
        {
          attributes = this.props.attributesList; 
        }
      } else {
        this.props.loadAttributes(tangoDB, Array(devName))
        if(this.props.attributesList)
        {
          attributes = this.props.attributesList; 
        }
      }

      this.setState({ attributes: attributes, fetchingAttributes: false });
    }
  }
}

function mapStateToProps(state: IRootState) {
  return {
    attributesList: getAttributeList(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAttributes: (tangoDB: string, device: string[]) => dispatch(fetchAttributes(tangoDB, device))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributeSelect);