import React, { Component } from "react";

import DeviceSuggester from "./DeviceSuggester";
import { DeviceConsumer } from "../DevicesProvider";
import { createDeviceWithTangoDBFullPath, getTangoDbFromPath } from "../../runtime/utils";
import TangoDbSelector from "../TangoDbSelector";

interface Props {
  widgetId: string;
  tangoDB: string;
  device: string;
  onSelect: (device: string) => void;
  nonEditable: boolean;
}

export default class DeviceSelect extends Component<Props> {
  public render() {
    return (
      <DeviceConsumer>
        {({ devices, onTangoDBChange, tangoDB }) => {
          const { device } = this.props;
          const tangoDBActive = device ? getTangoDbFromPath(device) : tangoDB;

          return (
          <>
            <TangoDbSelector
              widgetId={this.props.widgetId}
              onSelect={
                (tangodb) => {
                  this.props.onSelect("");
                  onTangoDBChange(tangodb)
                }}
              tangoDB={tangoDBActive}
              hasDevice={!!this.props.device}
            />
          <DeviceSuggester
            devices={devices}
            deviceName={this.props.device}
            onSelection={device => this.props.onSelect(createDeviceWithTangoDBFullPath(tangoDB, device))}
            nonEditable={this.props.nonEditable}
          />
          </>)
        }}
      </DeviceConsumer>
    );
  }
}
