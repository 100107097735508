import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarTabs from "../SidebarTabs/SidebarTabs";
import SynopticLibrary from "./SynopticLibrary";
import { getLibraryCollapsed } from "../../state/selectors";
import { IRootState } from "../../../shared/state/reducers/rootReducer";

interface Props {
  selectedTab: "synoptics";
  onTabChange: (x: "synoptics") => void;
  isRootCanvas: boolean;
  tangoDB: string;
  libraryCollapsed: boolean;
}

class SynopticsConfiguration extends Component<Props> {
  public render() {
    const { onTabChange, selectedTab, libraryCollapsed } = this.props;
    return (
      <div>
        <SidebarTabs selectedTab={selectedTab} onTabChange={onTabChange} />
        {/* by sending a 'render' props instead of conditional rendering here, the component won't get unmounted by
      changing tabs, and the state won't be lost */}

        <SynopticLibrary
          render={!libraryCollapsed}
          tangoDB={this.props.tangoDB}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return { libraryCollapsed: getLibraryCollapsed(state) };
}

export default connect(mapStateToProps)(SynopticsConfiguration);
