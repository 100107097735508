import React from 'react';
import { useSelector } from "react-redux";
import { IRootState } from '../../../shared/state/reducers/rootReducer';
import { getAttributeLastValueFromState } from '../../../shared/utils/getLastValueHelper';
import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import PlotlyBar from "plotly.js/lib/bar";
import createPlotlyComponent from "react-plotly.js/factory";
import { getAttributeValuesFromState } from '../../../shared/utils/getValuesHelper';

const Spectrum2DValues = ({
    attributeX,
    attributes,
    plotStyling,
    layout,
    actualWidth,
    actualHeight
}) => {

    const attributeXvalue = useSelector((state: IRootState) => {
        return getAttributeLastValueFromState(state.messages, attributeX.device, attributeX.attribute);
    });

    const attributesList = attributes.map((attr) => ({
        device: attr.attribute.device,
        attribute: attr.attribute.attribute,
    }));

    const customEqualValues = (oldValue, newValue) => {
        for (var attribute of attributesList) {
            let pair = attribute?.device + '/' + attribute?.attribute;
            if (oldValue?.[pair]) {
                let oldValueIndex = oldValue?.[pair]?.values?.length;
                let newValueIndex = newValue?.[pair]?.values?.length;
                if (oldValue?.[pair]?.values?.[oldValueIndex - 1] !== newValue?.[pair]?.values?.[newValueIndex - 1])
                    return false;
            }
        }
        return true;
    }

    const attributesValues:{} = useSelector((state: IRootState) =>
        getAttributeValuesFromState(state.messages, attributesList), customEqualValues
    );

    function registerPlotly() {
        let plot = (plotStyling === "bar") ? PlotlyBar : PlotlyScatter;
        PlotlyCore.register([plot]);
        return createPlotlyComponent(PlotlyCore);
    }

    const Plotly = registerPlotly();

    function makeData() {
        let data = [] as any;
        if (plotStyling === "bar") {
            for (var attributeNameBar in attributesValues){
                const yValue = attributesValues[attributeNameBar]["values"]
                data.push({
                    type: "bar",
                    x: attributeXvalue,
                    y: yValue[yValue.length - 1],
                    name: `${attributeNameBar}`
                });
            }
        }
        else {
            for (let attributeName in attributesValues){
                const yValue = attributesValues[attributeName]["values"]
                data.push({
                    type: "scatter",
                    mode: plotStyling,
                    x: attributeXvalue,
                    y: yValue[yValue.length - 1],
                    name: `${attributeName}`
                });
            }
        }
        return data
    }

    return (
        <div id="myPlotlyDiv">
            <Plotly
                data={makeData()}
                layout={layout}
                config={{ scrollZoom: true }}
                responsive={true}
                style={{ width: actualWidth, height: actualHeight }}
            />
        </div>
    )
}

export default Spectrum2DValues;