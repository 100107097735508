import React, { Component } from "react";
import Library from "./Library/Library";
import { Widget } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRootState } from "../../shared/state/reducers/rootReducer";
import {  getLibraryCollapsed } from "../../shared/state//selectors";
import { TOGGLE_LIBRARY_COLLAPSED } from "../../shared/state/actions/actionTypes";
import { connect } from "react-redux";
interface Props {
  mode: "run" | "edit";
  selectedTab: "dashboards" | "library" | "layers";
  tangoDB: string;
  selectedWidgets: Widget[];
  libraryCollapsed: boolean;
  toggleLibraryCollapse: () => void;
}
interface State {
  selectedTab: "dashboards" | "library" | "layers";
}

class Sidebar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { selectedTab } = this.props;
    this.state = { selectedTab };
  }
  public render() {
    const {
      mode,
      selectedWidgets,
      tangoDB,
      libraryCollapsed,
      toggleLibraryCollapse
    } = this.props;
    const { selectedTab } = this.state;
    if (mode === "run") {
      return null;
    }
    const display = libraryCollapsed ? "none" : "block";
    return (
      <>
      {libraryCollapsed && (
        <div className="expand-library">
          
            <div
              className="Library-collapsed"
              title={`Show the library \n (Alt+l)`}
              onClick={toggleLibraryCollapse}
            >
              <FontAwesomeIcon icon={"angle-left"} />{" "}
            </div>
          
        </div>
        )}
        <div className="Sidebar" style={{ display }}>
          <Library
            selectedTab={selectedTab}
            onTabChange={newVal => this.setState({ selectedTab: newVal })}
            nbrSelectedWidgets={selectedWidgets.length}
            widgets={selectedWidgets}
            isRootCanvas={true}
            tangoDB={tangoDB}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return { libraryCollapsed: getLibraryCollapsed(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLibraryCollapse: () =>
      dispatch({ type: TOGGLE_LIBRARY_COLLAPSED }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);