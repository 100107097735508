import { WEBSOCKET } from "../actions/actionTypes";
import { WebsocketInt } from "../types/types";

const initialState = [];

const subscription = (state = initialState, action: WebsocketInt) => {
  try {

    switch (action.type) {
      case WEBSOCKET.WS_SUBSCRIBED:
        return action.payload;
      case WEBSOCKET.WS_UNSUBSCRIBED:
        return action.payload
      default:
        return state;
    }
  } catch(e) {
    console.log(state, '----', state, ' Error: ',e);
    return null;
  }
};


export default subscription;
