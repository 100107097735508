import { Widget, Dashboard, DashboardEditHistory } from "../../../../dashboard/types";

import {
  SELECT_WIDGETS,
  DASHBOARD_LOADED,
  DASHBOARD_RENAMED,
  DASHBOARD_DELETED,
  DASHBOARD_SHARED,
  DASHBOARD_EDITED, //this is a catch-all for dashboard editing (add/edit/delete input/widget etc.), which as initially handled in sagas
  DASHBOARD_SAVED,
  DASHBOARD_UPDATED,
} from "../../actions/actionTypes";

import { DashboardAction } from "../../actions/actions";
import { validate } from "./lib";

export interface SelectedDashboardState extends Dashboard {
  widgets: Record<string, Widget>;
  selectedIds: string[];
  history: DashboardEditHistory;
}

const initialState = {
  selectedId: null,
  selectedIds: [],
  widgets: {},
  id: "",
  name: "Untitled dashboard",
  user: "",
  environment: [],
  group: "",
  groupWriteAccess: false,
  lastUpdatedBy: "",
  insertTime: null,
  updateTime: null,
  history: {
    undoActions: [],
    redoActions: [],
    undoIndex: 0,
    redoIndex: 0,
    undoLength: 0,
    redoLength: 0
  },
  variables: []
};

export default function canvases(
  state: SelectedDashboardState = initialState,
  action: DashboardAction
): SelectedDashboardState {
  switch (action.type) {
    case SELECT_WIDGETS: {
      const { ids } = action;
      return { ...state, selectedIds: ids };
    }

    case DASHBOARD_EDITED: {
      const { dashboard } = action;
      return { ...dashboard };
    }
    case DASHBOARD_SHARED: {
      const { id, group, groupWriteAccess } = action;
      if (id === state.id) {
        return {
          ...state,
          group,
          groupWriteAccess
        };
      } else {
        return state;
      }
    }
    case DASHBOARD_LOADED: {
      const { widgets, dashboard, deviceList } = action;
      const {
        id,
        name,
        user,
        environment,
        insertTime,
        updateTime,
        group,
        groupWriteAccess,
        lastUpdatedBy,
        variables
      } = dashboard;

      const newWidgets = widgets.reduce((accum, widget) => {
        return { ...accum, [widget.id]: validate(widget, variables, deviceList) };
      }, {});

      return {
        ...state,
        widgets: newWidgets,
        id,
        name,
        user,
        environment,
        insertTime,
        updateTime,
        group,
        groupWriteAccess,
        lastUpdatedBy,
        selectedIds: [],
        variables
      };
    }
    case DASHBOARD_RENAMED: {
      const { name } = action;
      return { ...state, name };
    }
    case DASHBOARD_UPDATED: {
      const { fields } = action;
      return { ...state, ...fields };
    }
    case DASHBOARD_DELETED: {
      const { id } = action;
      if (id === state.id) {
        // Clear the selectedDashboard state if we deleted the selected dashboard
        return {
          ...state,
          id: "",
          name: "",
          widgets: {},
          selectedIds: [],
        };
      } else {
        return state;
      }
    }
    case DASHBOARD_SAVED:
      const { variables } = action;
      return { ...state, variables }

    default:
      return state;
  }
}
