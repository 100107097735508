import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { JSONTree, parseJSONObject } from "../../../../../shared/utils/JSONTree";

import "./ValueDisplay.css";
import { getAttributeLastTimeStampFromState, getAttributeLastValueFromState } from "../../../../../shared/utils/getLastValueHelper";

function looksLikeMonospace(str) {
  return str.match(/(\n {2})|\t| {4}/);
}

const DevStringValueDisplay = ({ value }) => {
  const values = [].concat(value);

  const valuesAsObjects = values.map(parseJSONObject);
  const allAreObjects = valuesAsObjects.indexOf(null) === -1;

  if (allAreObjects) {
    return valuesAsObjects.map((obj, i) => <JSONTree key={i} data={obj} jsonCollapsed={true} />);
  }

  const anyLooksLikeMonospace = null != values.find(looksLikeMonospace);
  const extraClass = anyLooksLikeMonospace ? "monospace" : "";

  return values.map((val, i) => (
    <p key={i} className={extraClass}>
      {val}
    </p>
  ));
};

DevStringValueDisplay.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

const ScalarValueDisplay = ({ value, datatype }) => {
  switch (datatype) {
    case "DevString":
      return <DevStringValueDisplay value={value} />;

    case "DevEncoded":
      const [type, payload] = value;
      if (type !== "json") {
        return `Unsupported encoding '${type}'`;
      }

      if (parseJSONObject(payload) == null) {
        return <span className="invalid-json">Invalid JSON</span>;
      }

      return <DevStringValueDisplay value={payload} />;
    default:
      return String(value);
  }
};

ScalarValueDisplay.propTypes = {
  datatype: PropTypes.string,
  maxvalue: PropTypes.any,
  minvalue: PropTypes.any,
  name: PropTypes.string,
  setDeviceAttribute: PropTypes.func,
  value: PropTypes.any,
  writeValue: PropTypes.any,
  writable: PropTypes.string
};

const SpectrumValueDisplay = ({ value, datatype }) => {
  if (datatype === "DevString") {
    return <DevStringValueDisplay value={value} />;
  }

  return null;
};

SpectrumValueDisplay.propTypes = {
  value: PropTypes.any,
  datatype: PropTypes.string
};

const ValueDisplay = ({
  writeValue,
  writable,
  setDeviceAttribute,
  datatype,
  dataformat,
  name,
  minvalue,
  maxvalue,
  deviceName
}) => {
  const value = useSelector((state) => {
    return getAttributeLastValueFromState(state.messages, deviceName, name);
  });

  const timestamp = useSelector((state) => {
    return getAttributeLastTimeStampFromState(state.messages, deviceName, name);
  });

  if (value === null) {
    return <span className="ValueDisplay no-value">No value</span>;
  }

  if (value === undefined) {
    return null;
  }

  const InnerDisplay = {
    SCALAR: ScalarValueDisplay,
    SPECTRUM: SpectrumValueDisplay
  }[dataformat];

  const className = ["ValueDisplay", dataformat.toLowerCase(), datatype].join(
    " "
  );

  return (
    <div className={className} title={new Date(new Date(0).setUTCSeconds(timestamp))}>
      <InnerDisplay
        value={value}
        writeValue={writeValue}
        datatype={datatype}
        name={name}
        writable={writable}
        maxvalue={maxvalue}
        minvalue={minvalue}
        setDeviceAttribute={setDeviceAttribute}
      />
    </div>
  );
};

ValueDisplay.propTypes = {
  dataformat: PropTypes.string,
  datatype: PropTypes.string,
  maxvalue: PropTypes.any,
  minvalue: PropTypes.any,
  name: PropTypes.string,
  setDeviceAttribute: PropTypes.func,
  value: PropTypes.any,
  writable: PropTypes.string
};

export default ValueDisplay;
