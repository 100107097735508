import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UserAware from "../shared/user/components/UserAware";
import Layout from "./components/Layout/Layout";
import InfoPage from "./components/InfoPage/InfoPage";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import { useDispatch } from "react-redux";
import { FETCH_DEVICE_NAMES } from "../shared/state/actions/actionTypes";
import { getTangoDB } from "../dashboard/dashboardRepo";

const config = window['config'];

const App = () => {
  const dispatch = useDispatch();
  dispatch({
    type: FETCH_DEVICE_NAMES,
    tangoDB: getTangoDB(),
  });

  return (
    <UserAware>
      <BrowserRouter basename={config.basename}>
        <Switch>
          <Route path="/" exact={true} component={InfoPage} />
          <Route
            path="/:tangoDB"
            exact={true}
            render={({ match }) => (
              <Redirect to={`/${match.params.tangoDB}/devices`} />
            )}
          />
          <Route path="/:tangoDB/devices" component={Layout} />
        </Switch>
      </BrowserRouter>
    </UserAware>
  );
};

export default App;
