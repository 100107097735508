import { Action } from "redux";
import {
  FETCH_DATABASE_INFO,
  FETCH_DATABASE_INFO_SUCCESS,
  FETCH_DATABASE_INFO_FAILED,
  FETCH_DEVICE,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_FAILED,
  FETCH_DEVICE_NAMES,
  FETCH_DEVICE_NAMES_SUCCESS,
  FETCH_ATTRIBUTES,
  FETCH_ATTRIBUTES_SUCCESS,
  FETCH_ATTRIBUTES_FAILED,
  SUBSCRIBE_DEVICE_ATTRS,
  UNSUBSCRIBE_DEVICE_ATTRS,
  EXECUTE_COMMAND,
  EXECUTE_COMMAND_SUCCESS,
  EXECUTE_COMMAND_FAILED,
  DISABLE_DISPLEVEL,
  ENABLE_DISPLEVEL,
  SET_DEVICE_PROPERTY,
  SET_DEVICE_PROPERTY_SUCCESS,
  SET_DEVICE_PROPERTY_FAILED,
  SET_DEVICE_ATTRIBUTE,
  SET_DEVICE_ATTRIBUTE_SUCCESS,
  SET_DEVICE_ATTRIBUTE_FAILED,
  DELETE_DEVICE_PROPERTY,
  DELETE_DEVICE_PROPERTY_SUCCESS,
  DELETE_DEVICE_PROPERTY_FAILED,
  FETCH_DEVICE_NAMES_FAILED,
  ATTRIBUTE_FRAME_RECEIVED,
  SET_DATA_FORMAT,
  DEVICE_STATE_RECEIVED,
  LOAD_TANGO_DB_NAME,
  LOAD_TANGO_DB_NAME_SUCCESS,
  LOAD_TANGO_DB_NAME_FAILED
} from "./actionTypes";
import { ToggleModeAction } from "./actions";

interface Attribute {
  name: string;
  label: string;
  dataformat: string;
  datatype: string;
}

interface FetchDatabaseInfoAction extends Action {
  type: typeof FETCH_DATABASE_INFO;
  tangoDB: string;
}

export function fetchDatabaseInfo(tangoDB: string): FetchDatabaseInfoAction {
  return { type: FETCH_DATABASE_INFO, tangoDB };
}

interface FetchDatabaseInfoSuccessAction extends Action {
  type: typeof FETCH_DATABASE_INFO_SUCCESS;
  tangoDB: string;
  info: string;
}

export function fetchDatabaseInfoSuccess(
  tangoDB: string,
  info
): FetchDatabaseInfoSuccessAction {
  return { type: FETCH_DATABASE_INFO_SUCCESS, tangoDB, info };
}

interface FetchDatabaseInfoFailedAction extends Action {
  type: typeof FETCH_DATABASE_INFO_FAILED;
  tangoDB: string;
}

export function fetchDatabaseInfoFailed(
  tangoDB: string
): FetchDatabaseInfoFailedAction {
  return { type: FETCH_DATABASE_INFO_FAILED, tangoDB };
}

interface LoadTangoDBNameAction extends Action {
  type: typeof LOAD_TANGO_DB_NAME;
}

export function loadTangoDBName(): LoadTangoDBNameAction {
  return { type: LOAD_TANGO_DB_NAME };
}

interface LoadTangoDBNameSuccessAction extends Action {
  type: typeof LOAD_TANGO_DB_NAME_SUCCESS;
  tangoDBName: string;
}

export function loadTangoDBNameSuccess(
  tangoDBName: string
): LoadTangoDBNameSuccessAction {
  return { type: LOAD_TANGO_DB_NAME_SUCCESS, tangoDBName };
}

interface LoadTangoDBNameFailedAction extends Action {
  type: typeof LOAD_TANGO_DB_NAME_FAILED;
}

export function loadTangoDBNameFailed(): LoadTangoDBNameFailedAction {
  return { type: LOAD_TANGO_DB_NAME_FAILED };
}

interface FetchDeviceNamesAction extends Action {
  type: typeof FETCH_DEVICE_NAMES;
  tangoDB: string;
}

export function fetchDeviceNames(tangoDB): FetchDeviceNamesAction {
  return { type: FETCH_DEVICE_NAMES, tangoDB };
}

interface FetchAttributesAction extends Action {
  type: typeof FETCH_ATTRIBUTES;
  tangoDB: string;
  device: string[];
}

export function fetchAttributes(tangoDB, device): FetchAttributesAction {
  return { type: FETCH_ATTRIBUTES, tangoDB, device };
}

interface SubscribeDeviceAttrsAction extends Action {
  type: typeof SUBSCRIBE_DEVICE_ATTRS;
  tangoDB: string;
  deviceName: string;
  attributes: string[];
}

export function subscribeDeviceAttrs(tangoDB, deviceName, attributes): SubscribeDeviceAttrsAction {
  return { type: SUBSCRIBE_DEVICE_ATTRS, tangoDB, deviceName, attributes };
}

interface UnsubscribeDeviceAttrsAction extends Action {
  type: typeof UNSUBSCRIBE_DEVICE_ATTRS;
  tangoDB: string;
  deviceName: string;
  attributes: string[];
}

export function unsubscribeDeviceAttrs(tangoDB, deviceName, attributes): UnsubscribeDeviceAttrsAction {
  return { type: UNSUBSCRIBE_DEVICE_ATTRS, tangoDB, deviceName, attributes };
}

interface DeleteDevicePropertyAction extends Action {
  type: typeof DELETE_DEVICE_PROPERTY;
  tangoDB: string;
  device: string;
  name: string;
}

export function deleteDeviceProperty(
  tangoDB: string,
  device,
  name
): DeleteDevicePropertyAction {
  return { type: DELETE_DEVICE_PROPERTY, tangoDB, device, name };
}

interface DeleteDevicePropertySuccessAction extends Action {
  type: typeof DELETE_DEVICE_PROPERTY_SUCCESS;
  tangoDB: string;
  device: string;
  name: string;
}

export function deleteDevicePropertySuccess(
  tangoDB: string,
  device: string,
  name: string
): DeleteDevicePropertySuccessAction {
  return { type: DELETE_DEVICE_PROPERTY_SUCCESS, tangoDB, device, name };
}

interface DeleteDevicePropertyFailedAction extends Action {
  type: typeof DELETE_DEVICE_PROPERTY_FAILED;
  tangoDB: string;
  device: string;
  name: string;
}

export function deleteDevicePropertyFailed(
  tangoDB: string,
  device,
  name
): DeleteDevicePropertyFailedAction {
  return { type: DELETE_DEVICE_PROPERTY_FAILED, tangoDB, device, name };
}

interface SetDataFormatAction extends Action {
  type: typeof SET_DATA_FORMAT;
  format;
}

export function setDataFormat(format): SetDataFormatAction {
  return { type: SET_DATA_FORMAT, format };
}

interface FetchDeviceNamesSuccessAction extends Action {
  type: typeof FETCH_DEVICE_NAMES_SUCCESS;
  names: string[];
}

export function fetchDeviceNamesSuccess(names): FetchDeviceNamesSuccessAction {
  return { type: FETCH_DEVICE_NAMES_SUCCESS, names };
}

interface FetchDeviceNamesFailedAction extends Action {
  type: typeof FETCH_DEVICE_NAMES_FAILED;
  reason;
}

export function fetchDeviceNamesFailed(reason): FetchDeviceNamesFailedAction {
  return { type: FETCH_DEVICE_NAMES_FAILED, reason };
}

interface FetchAttributesSuccessAction extends Action {
  type: typeof FETCH_ATTRIBUTES_SUCCESS;
  attributes: Attribute[];
}

export function fetchAttributesSuccess(attributes): FetchAttributesSuccessAction {
  return { type: FETCH_ATTRIBUTES_SUCCESS, attributes };
}

interface FetchAttributesFailedAction extends Action {
  type: typeof FETCH_ATTRIBUTES_FAILED;
  reason;
}

export function fetchAttributesFailed(reason): FetchAttributesFailedAction {
  return { type: FETCH_ATTRIBUTES_FAILED, reason };
}

interface ExecuteCommandAction extends Action {
  type: typeof EXECUTE_COMMAND;
  tangoDB: string;
  command;
  argin;
  device;
}

export function executeCommand(
  tangoDB: string,
  command,
  argin,
  device
): ExecuteCommandAction {
  return { type: EXECUTE_COMMAND, tangoDB, command, argin, device };
}

interface ExecuteCommandFailedAction extends Action {
  type: typeof EXECUTE_COMMAND_FAILED;
  tangoDB: string;
  device;
  command;
  argin;
  message;
}

export function executeCommandFailed(
  tangoDB: string,
  device,
  command,
  argin,
  message
): ExecuteCommandFailedAction {
  return { type: EXECUTE_COMMAND_FAILED, tangoDB, device, command, argin, message };
}

interface ExecuteCommandSuccessAction extends Action {
  type: typeof EXECUTE_COMMAND_SUCCESS;
  tangoDB: string;
  command;
  result;
  device;
}

export function executeCommandSuccess(
  tangoDB: string,
  command,
  result,
  device
): ExecuteCommandSuccessAction {
  return { type: EXECUTE_COMMAND_SUCCESS, tangoDB, command, result, device };
}

interface SetDeviceAttributeAction extends Action {
  type: typeof SET_DEVICE_ATTRIBUTE;
  tangoDB: string;
  device: string;
  name: string;
  value;
}

export function setDeviceAttribute(
  tangoDB: string,
  device: string,
  name: string,
  value
): SetDeviceAttributeAction {
  return { type: SET_DEVICE_ATTRIBUTE, tangoDB, device, name, value };
}

interface SetDeviceAttributeSuccessAction extends Action {
  type: typeof SET_DEVICE_ATTRIBUTE_SUCCESS;
  tangoDB: string;
  attribute;
}

export function setDeviceAttributeSuccess(
  tangoDB: string,
  attribute: string
): SetDeviceAttributeSuccessAction {
  return { type: SET_DEVICE_ATTRIBUTE_SUCCESS, tangoDB, attribute };
}

interface SetDeviceAttributeFailedAction extends Action {
  type: typeof SET_DEVICE_ATTRIBUTE_FAILED;
  tangoDB: string;
  device: string;
  name: string;
  value;
}

export function setDeviceAttributeFailed(
  tangoDB: string,
  device: string,
  name: string,
  value
): SetDeviceAttributeFailedAction {
  return { type: SET_DEVICE_ATTRIBUTE_FAILED, tangoDB, device, name, value };
}

interface SetDevicePropertyAction extends Action {
  type: typeof SET_DEVICE_PROPERTY;
  tangoDB: string;
  device;
  name: string;
  value;
}

export function setDeviceProperty(
  tangoDB: string,
  device: string,
  name: string,
  value
): SetDevicePropertyAction {
  return { type: SET_DEVICE_PROPERTY, tangoDB, device, name, value };
}

interface SetDevicePropertySuccessAction extends Action {
  type: typeof SET_DEVICE_PROPERTY_SUCCESS;
  tangoDB: string;
  device: string;
  name: string;
  value;
}

export function setDevicePropertySuccess(
  tangoDB: string,
  device: string,
  name: string,
  value
): SetDevicePropertySuccessAction {
  return { type: SET_DEVICE_PROPERTY_SUCCESS, tangoDB, device, name, value };
}

interface SetDevicePropertyFailedAction extends Action {
  type: typeof SET_DEVICE_PROPERTY_FAILED;
  tangoDB: string;
  device: string;
  name: string;
  value;
}

export function setDevicePropertyFailed(
  tangoDB: string,
  device: string,
  name: string,
  value
): SetDevicePropertyFailedAction {
  return { type: SET_DEVICE_PROPERTY_FAILED, tangoDB, device, name, value };
}

interface EnableDisplevelAction extends Action {
  type: typeof ENABLE_DISPLEVEL;
  displevel;
}

export function enableDisplevel(displevel): EnableDisplevelAction {
  return { type: ENABLE_DISPLEVEL, displevel };
}

interface DisableDisplevelAction extends Action {
  type: typeof DISABLE_DISPLEVEL;
  displevel;
}

export function disableDisplevel(displevel): DisableDisplevelAction {
  return { type: DISABLE_DISPLEVEL, displevel };
}

interface FetchDeviceAction extends Action {
  type: typeof FETCH_DEVICE;
  tangoDB: string;
  name: string;
}

export function fetchDevice(tangoDB, name): FetchDeviceAction {
  return { type: FETCH_DEVICE, tangoDB, name };
}

interface FetchDeviceSuccessAction extends Action {
  type: typeof FETCH_DEVICE_SUCCESS;
  tangoDB: string;
  device;
}

export function fetchDeviceSuccess(
  tangoDB: string,
  device
): FetchDeviceSuccessAction {
  return { type: FETCH_DEVICE_SUCCESS, tangoDB, device };
}

interface FetchDeviceFailedAction extends Action {
  type: typeof FETCH_DEVICE_FAILED;
  tangoDB: string;
  name: string;
}

export function fetchDeviceFailed(tangoDB, name): FetchDeviceFailedAction {
  return { type: FETCH_DEVICE_FAILED, tangoDB, name };
}

interface AttributeFrameReceivedAction extends Action {
  type: typeof ATTRIBUTE_FRAME_RECEIVED;
  frame;
}

export function attributeFrameReceived(frame): AttributeFrameReceivedAction {
  return { type: ATTRIBUTE_FRAME_RECEIVED, frame };
}

interface DeviceStateReceivedAction extends Action {
  type: typeof DEVICE_STATE_RECEIVED;
  device: string;
  state: string;
  tangoDB: string;
}

export type TangoAction =
  | FetchDatabaseInfoAction
  | FetchDatabaseInfoSuccessAction
  | FetchDatabaseInfoFailedAction
  | LoadTangoDBNameAction
  | LoadTangoDBNameSuccessAction
  | LoadTangoDBNameFailedAction
  | FetchDeviceNamesAction
  | FetchAttributesAction
  | DeleteDevicePropertyAction
  | DeleteDevicePropertySuccessAction
  | DeleteDevicePropertyFailedAction
  | SetDataFormatAction
  | FetchDeviceNamesSuccessAction
  | FetchDeviceNamesFailedAction
  | FetchAttributesSuccessAction
  | FetchAttributesFailedAction
  | ExecuteCommandAction
  | ExecuteCommandFailedAction
  | ExecuteCommandSuccessAction
  | SetDeviceAttributeAction
  | SetDeviceAttributeSuccessAction
  | SetDeviceAttributeFailedAction
  | SetDevicePropertyAction
  | SetDevicePropertySuccessAction
  | SetDevicePropertyFailedAction
  | EnableDisplevelAction
  | DisableDisplevelAction
  | FetchDeviceAction
  | FetchDeviceSuccessAction
  | FetchDeviceFailedAction
  | AttributeFrameReceivedAction
  | DeviceStateReceivedAction
  | ToggleModeAction
