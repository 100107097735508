import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import AttributeTable from "./AttributeTable/AttributeTable";
import CommandTable from "./CommandTable/CommandTable";
import PropertyTable from "./PropertyTable/PropertyTable";
import ServerInfo from "./ServerInfo/ServerInfo";
import DisplevelChooser from "./DisplevelChooser/DisplevelChooser";
import ErrorTable from "./ErrorTable/ErrorTable";
import Logs from "./Logs/Logs";
import Spinner from "../../../shared/components/Spinner/Spinner";

import { getDevices, getAttributesState, getCommandsState, getPropertiesState } from "../../../shared/state/selectors/devices";
import { getDeviceIsLoading } from "../../../shared/state/selectors/loadingStatus";
import { getDisabledDisplevels } from "../../../shared/state/selectors/deviceDetail";
import { MemoStateIndicator } from "./StateIndicator";

import {
  enableDisplevel,
  disableDisplevel,
  fetchDevice
} from "../../../shared/state/actions/tango";
import "./DeviceViewer.css";
import { DeviceMenu } from "./DeviceMenu";

class DeviceViewer extends Component {
  componentDidMount() {
    this.props.onRequireDevice(this.props.deviceName);
  }

  componentDidUpdate(prevProps) {
    const deviceName = this.props.deviceName;
    if (prevProps.deviceName !== deviceName) {
      this.props.onRequireDevice(deviceName);
    }
  }

  innerView(tab) {
    const { device, attributes, properties, tangoDB, deviceName } = this.props;

    if (tab === "properties") {
      return (
        <PropertyTable
          tangoDB={tangoDB}
          deviceName={deviceName}
          properties={Object.values(properties)}
        />
      );
    } else if (tab === "commands") {
      return (
        <CommandTable
          tangoDB={tangoDB}
          deviceName={deviceName}
        />
      );
    } else if (tab === "errors") {
      return <ErrorTable tangoDB={tangoDB} errors={device.errors}/>;
    } else if (tab === "attributes") {
      return (
        <AttributeTable
          tangoDB={tangoDB}
          deviceName={deviceName}
          attributes={Object.values(attributes)}
        />
      );
    } else if (tab === "logs") {
      return <Logs tangoDB={tangoDB} deviceName={device.name} />;
    } else {
      return <ServerInfo device={device} />;
    }
  }

  innerContent() {
    if (this.props.loading) {
      return <Spinner size={4} />;
    }

    if (!this.props.device || 0 === Object.keys(this.props.device).length) {
      return (
        <p style={{ margin: "1em", color: "red" }}>
          Couldn't load {this.props.deviceName}.
        </p>
      );
    }

    const selectedTab = this.props.selectedTab || "server";
    const {
      device,
      displevels,
      disabledDisplevels,
      onDisplevelChange,
      location
    } = this.props;

    // Disable the displevel chooser until its role in the user interface has been worked out properly. Currently it doesn't add much except distraction
    const enableDisplevelChooser = false;

    return (
      <div>
        <Helmet title={device.name} />
        <div className="device-header">
          <MemoStateIndicator deviceName={this.props.deviceName} /> {device.name}
          {enableDisplevelChooser && displevels.length > 1 && (
            <DisplevelChooser
              displevels={[] /*displevels*/}
              disabledDisplevels={disabledDisplevels}
              onChange={onDisplevelChange}
            />
          )}
        </div>
        <div className="device-body">
          <DeviceMenu
            tangoDB={this.props.tangoDB}
            deviceName={this.props.deviceName}
            selectedTab={selectedTab}
            location={location}
            attributes={Object.values(this.props.attributes)}
            commands={Object.values(this.props.commands)}
            errors={device.errors}
          />
          <div className="device-view">{this.innerView(selectedTab)}</div>
        </div>
      </div>
    );
  }

  render() {
    return <div className="DeviceViewer">{this.innerContent()}</div>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: getDeviceIsLoading(state),
    device: getDevices(state)[ownProps.deviceName],
    attributes: getAttributesState(state)[ownProps.deviceName],
    properties: getPropertiesState(state)[ownProps.deviceName],
    commands: getCommandsState(state)[ownProps.deviceName],
    disabledDisplevels: getDisabledDisplevels(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { tangoDB } = ownProps;

  return {
    onRequireDevice: device => dispatch(fetchDevice(tangoDB, device)),
    onDisplevelChange: (displevel, value) => {
      const action = value
        ? enableDisplevel(displevel)
        : disableDisplevel(displevel);
      dispatch(action);
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeviceViewer)
);