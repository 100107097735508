import React from "react";
import { Button } from "react-bootstrap";

import Modal from "../../../shared/modal/components/Modal/Modal";

const DeleteDashboardModal = ({ name, id, onDelete, onClose }) => (
  <Modal title="Delete Dashboard">
    <Modal.Body>
      <p>
        Are you sure you want to delete the dashboard {" "}
        <span style={{ fontWeight: "bold" }}>{name}</span>?
      </p>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="outline-secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={() => onDelete(id)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteDashboardModal;
