import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { getAttributeLastValueFromState } from "../../../shared/utils/getLastValueHelper";

const StateIndicator = (props: any) => {

    const deviceState = useSelector((state: IRootState) => {
        return getAttributeLastValueFromState(state.messages, props.deviceName, "state");
    });

    const classes = {
        ON: "on",
        OFF: "off",
        CLOSE: "close-status", // In order to avoid collision with bootstrap class name
        OPEN: "open",
        INSERT: "insert",
        EXTRACT: "extract",
        MOVING: "moving",
        STANDBY: "standby",
        FAULT: "fault",
        INIT: "init",
        RUNNING: "running",
        ALARM: "alarm",
        DISABLE: "disable",
        UNKNOWN: "unknown"
    };

    const className = classes[deviceState] || "invalid";
    return (
        <span className={classNames("StateIndicator", className)}>{deviceState}</span>
    );
}

export const MemoStateIndicator = React.memo(StateIndicator);