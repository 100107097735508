import React, { Component } from "react";
import { WidgetProps } from "../types";

import {
  WidgetDefinition,
  StringInputDefinition,
  NumberInputDefinition,
  AttributeInputDefinition,
  DeviceInputDefinition,
  BooleanInputDefinition,
  ColorInputDefinition,
  StyleInputDefinition
} from "../../types";

import { parseCss } from "../../components/Inspector/StyleSelector";
import DeviceStatusValues from "./DeviceStatusValues";

type Inputs = {
  device: DeviceInputDefinition;
  state: AttributeInputDefinition;
  showDeviceName: BooleanInputDefinition;
  showTangoDB: BooleanInputDefinition;
  showStateString: BooleanInputDefinition;
  showStateLED: BooleanInputDefinition;
  LEDSize: NumberInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  textSize: NumberInputDefinition;
  linkTo: StringInputDefinition;
  widgetCss: StyleInputDefinition;
};

type Props = WidgetProps<Inputs>;

class DeviceStatus extends Component<Props> {
  public render() {
    const { inputs } = this.props;
    const widgetCss = parseCss(inputs.widgetCss).data;

    return (
      <div
        id="DeviceStatus"
        style={{ padding: "0.5em", ...widgetCss }}
        className="w-100 h-100"
      >
        <DeviceStatusValues
          device={inputs.device.name}
          showDeviceName={inputs.showDeviceName}
          showTangoDB={inputs.showTangoDB}
          showStateString={inputs.showStateString}
          showStateLED={inputs.showStateLED}
          LEDSize={inputs.LEDSize}
          textColor={inputs.textColor}
          backgroundColor={inputs.backgroundColor}
          linkTo={inputs.linkTo}
          textSize={inputs.textSize}
        />
      </div>
    );
  }
}

const definition: WidgetDefinition<Inputs> = {
  type: "DEVICE_STATUS",
  name: "Device Status",
  defaultHeight: 2,
  defaultWidth: 20,
  inputs: {
    device: {
      type: "device",
      label: "",
      publish: "$device"
    },
    state: {
      type: "attribute",
      device: "$device",
      attribute: "state"
    },
    showDeviceName: {
      type: "boolean",
      label: "Show device name",
      default: true
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false
    },
    showStateString: {
      type: "boolean",
      label: "Show state name",
      default: true
    },
    showStateLED: {
      type: "boolean",
      label: "Show state LED",
      default: true
    },
    LEDSize: {
      label: "LED size (in units)",
      type: "number",
      default: 1,
      nonNegative: true
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000"
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff"
    },
    textSize: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true
    },
    linkTo: {
      label: "Link to",
      type: "string",
      default: "",
      placeholder: "Optional link URL"
    },
    widgetCss: {
      type: "style",
      default: "",
      label: "Custom Css"
    }
  }
};
const DeviceStatusExport = { component: DeviceStatus, definition };

export default DeviceStatusExport;
