import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TOGGLE_LIBRARY_COLLAPSED } from "../../state/actionTypes";
import "./SidebarTabs.css";

interface Props {
  selectedTab: string;
  onTabChange: (newVal: "synoptics") => void;
  toggleLibraryCollapse: () => void;
}

class SidebarTabs extends Component<Props> {
  public render() {
    const { onTabChange, selectedTab, toggleLibraryCollapse } = this.props;
    return (
      <div className="sidebar-tabs">
        <ul className="nav section-chooser">
          <li
            className={
              "toggle-button toggle-button-left " +
              (selectedTab === "synoptics" ? "toggle-button-selected" : "")
            }
            key="synoptics"
            style={{ width: "40%" }}
            onClick={(e) => onTabChange("synoptics")}
          >
            Synoptics
          </li>
          <li
            title={`Hide the library sidebar \n (Alt+l)`}
            className={"toggle-button toggle-button-right "}
            style={{ width: "10%" }}
            key="collapse"
            onClick={toggleLibraryCollapse}
          >
            <FontAwesomeIcon icon={"angle-right"} />
          </li>
        </ul>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLibraryCollapse: () => dispatch({ type: TOGGLE_LIBRARY_COLLAPSED }),
  };
}

export default connect(null, mapDispatchToProps)(SidebarTabs);
