import {
  SELECT_CANVAS,
  TOGGLE_MODE,
  TOGGLE_INSPECTOR_COLLAPSED,
  TOGGLE_LIBRARY_COLLAPSED,
  MOUSE_DOWN_ON_WIDGET,
} from "../actions/actionTypes";
import { DashboardAction } from "../actions/actions";

export interface UIState {
  mode: "edit" | "run";
  selectedCanvas: string;
  inspectorCollapsed: boolean;
  libraryCollapsed: boolean;
  MouseDownOnWidget: boolean;
}

const initialState: UIState = {
  mode: "edit",
  selectedCanvas: "0",
  inspectorCollapsed: false,
  libraryCollapsed: false,
  MouseDownOnWidget: false
};

export default function ui(
  state: UIState = initialState,
  action: DashboardAction
): UIState {
  switch (action.type) {
    case SELECT_CANVAS:
      return { ...state, selectedCanvas: action.id };
    case TOGGLE_MODE:
      return { ...state, mode: state.mode === "edit" ? "run" : "edit" };
    case TOGGLE_INSPECTOR_COLLAPSED:
      return { ...state, inspectorCollapsed: !state.inspectorCollapsed };
    case TOGGLE_LIBRARY_COLLAPSED:
      return { ...state, libraryCollapsed: !state.libraryCollapsed };
      case MOUSE_DOWN_ON_WIDGET:
        return { ...state, MouseDownOnWidget: action.isDown}
    default:
      return state;
  }
}
