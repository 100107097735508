import { createDeviceWithTangoDBFullPath } from "../../../dashboard/runtime/utils";
import { splitFullPath } from "../../../dashboard/DBHelper"

const config = window['config'];

export function socketUrl(tangoDB) {
  const loc = window.location;
  const protocol = loc.protocol.replace("http", "ws");
  const url = loc.href.replace(config.basename, "").split('/');
  const database = tangoDB === "" ? url[3]: tangoDB  
  return (
    protocol +
    "//" +
    loc.host +
    config.basename +
    "/" +
    database +
    "/socket"
  );
}

export function retriveDeviceFromSub(sub){

  const result = [];
  if(sub !== undefined)
  {
    sub.forEach(item => {
      const [tangoDB, device] = splitFullPath(item);
      const parts = device.split("/");
      let key = parts.slice(0, -1).join("/");
      key = createDeviceWithTangoDBFullPath(tangoDB, key);
      const value = parts[parts.length - 1];
    
      const existingObj = result.find(obj => obj.name === key);
    
      if (existingObj) {
        existingObj.attributes.push({ name: value });
      } else {
        const newObj = {
          name: key,
          attributes: [{ name: value }]
        };
        result.push(newObj);
      }
    });
  }
  return result
}