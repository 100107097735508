import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import {
  getAttributeLastTimeStampFromState,
  getAttributeLastValueFromState,
  getAttributeLastQualityFromState,
} from "../../../shared/utils/getLastValueHelper";
import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import createPlotlyComponent from "react-plotly.js/factory";
import { HISTORY_LIMIT } from "../../components/RunCanvas/RunCanvas";
import { AttributeInput } from "../../types";
PlotlyCore.register([PlotlyScatter]);
const Plotly = createPlotlyComponent(PlotlyCore);

interface Props {
  dependent: AttributeInput;
  independent: AttributeInput;
  mode: any;
  layout: any;
  config: any;
  responsive: boolean;
  style: CSSProperties;
}

let dependentBuffer: any[] = [];
let independentBuffer: any[] = [];
// prettier-ignore
const sampleX = [-3.96, -2.6, -2.89, -2.28, -1.19, -1.79, -0.19, 0.76, -0.37, 1.66, 0.83, 2.33, 3.41, 3.91, 4.44, 4.73, 5.15, 4.72];
// prettier-ignore
const sampleY = [-3.59, -3.84, -2.64, -2.28, -2.38, -1.04, -0.99, -0.87, 0.07, 0.15, 1.29, 1.61, 2.35, 2.83, 2.59, 3.11, 4.44, 4.79];
let dependentTimestamp = 0.0;
let independentTimestamp = 0.0;
let dependentHistory = dependentBuffer;
let independentHistory = independentBuffer;
const AttributeScatterValues: React.FC<Props> = ({
  dependent,
  independent,
  mode,
  layout,
  config,
  responsive,
  style,
}) => {
  let dependentStateValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      dependent.device,
      dependent.attribute
    );
  });

  let independentStateValue = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      independent.device,
      independent.attribute
    );
  });

  const dependentStateTimestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(
      state.messages,
      dependent.device,
      dependent.attribute
    )?.toString();
  });

  const independentStateTimestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(
      state.messages,
      independent.device,
      independent.attribute
    )?.toString();
  });

  let dependentStateQuality = useSelector((state: IRootState) => {
    return getAttributeLastQualityFromState(
      state.messages,
      dependent.device,
      dependent.attribute
    )?.toString();
  });

  let independentStateQuality = useSelector((state: IRootState) => {
    return getAttributeLastQualityFromState(
      state.messages,
      independent.device,
      independent.attribute
    )?.toString();
  });

  let x: number[] = [];
  let y: number[] = [];

  if (mode === "run") {
    dependent.history = dependentHistory;
    independent.history = independentHistory;
    dependentHistory.push({
      quality: dependentStateQuality,
      timestamp: dependentStateTimestamp,
      value: dependentStateValue,
      writeValue: 0,
    });
    independentHistory.push({
      quality: independentStateQuality,
      timestamp: independentStateTimestamp,
      value: independentStateValue,
      writeValue: 0,
    });

    if (dependentHistory.length > 0 && independentHistory.length > 0) {
      const fun = interpolated(
        dependentHistory.map((attr) => attr.timestamp),
        dependentHistory.map((attr) => attr.value)
      );

      x = independentHistory.map((attr) => attr.value);
      y = independentHistory.map((attr) => fun(attr.timestamp));
    }
  } else if (mode === "library") {
    x = sampleX;
    y = sampleY;
  }

  const data = [
    {
      type: "scatter",
      mode: "markers",
      x,
      y,
      marker: { symbol: "cross" },
    },
  ];

  dataBuffer(dependent, independent, mode);

  return (
    <Plotly
      data={data}
      layout={layout}
      config={config}
      responsive={responsive}
      style={style}
    />
  );
};

// Naive lerp implementation, with fallback to first/last value of unknown is ouside range
function interpolated(xs: number[], ys: number[]) {
  if (xs.length !== ys.length) {
    throw new Error("xs.length != ys.length");
  }

  if (xs.length === 0) {
    throw new Error("xs.length == 0");
  }

  return (input: number) => {
    if (input < xs[0]) {
      return ys[0];
    }

    for (let i = 0; i < xs.length - 1; i++) {
      const currX = xs[i];
      const nextX = xs[i + 1];

      if (input >= currX && input < nextX) {
        const deltaX = nextX - currX;
        if (deltaX !== 0) {
          const w1 = (nextX - input) / deltaX;
          const w2 = (input - currX) / deltaX;
          return w1 * ys[i] + w2 * ys[i + 1];
        } else {
          return ys[i];
        }
      }
    }

    return ys[ys.length - 1];
  };
}

function dataBuffer(
  dependent: AttributeInput,
  independent: AttributeInput,
  mode: string
) {
  const NEW_LIMIT = 10e4;
  if (mode === "edit") {
    dependentBuffer = [];
    independentBuffer = [];
  } else if (dependent.history.length > 0 && independent.history.length > 0) {
    // push new data to dependent buffer
    dependentBuffer = pushNewData(
      dependent,
      dependentBuffer,
      dependentTimestamp,
      NEW_LIMIT
    );

    // push new data to independent buffer
    independentBuffer = pushNewData(
      independent,
      independentBuffer,
      independentTimestamp,
      NEW_LIMIT
    );
  }
  dependentTimestamp =
    dependent.history.length > 0 ? dependent.history[0].timestamp : 0.0;
  independentTimestamp =
    independent.history.length > 0 ? independent.history[0].timestamp : 0.0;
}

function pushNewData(
  data: AttributeInput,
  buffer: any[],
  timeStamp: number,
  newLimit: number
) {
  if (buffer.length >= HISTORY_LIMIT) {
    // check if new data pushed in
    if (timeStamp !== data.history[0].timestamp) {
      buffer.push(data.history[data.history.length - 1]);
      buffer = buffer.length > newLimit ? buffer.slice(-newLimit) : buffer;
    }
  } else {
    buffer = data.history;
  }
  return buffer;
}

export default AttributeScatterValues;
