import { definitionForWidget } from "../widgets";
import {
  Widget,
  InputDefinitionMapping,
  InputMapping,
  AttributeInputDefinition,
  CommandInputDefinition
} from "../types";
import { PublishedDevices, resolveDevice, publishedDevices } from "./utils";

function* extractFullNamesFromInputsGen(
  inputs: InputMapping,
  inputDefinitions: InputDefinitionMapping,
  published: PublishedDevices
) {
  const inputNames = Object.keys(inputs);
  for (const name of inputNames) {
    const inputDefinition = inputDefinitions[name];
    if (!inputDefinition){
      continue;
    }
    const input = inputs[name];
    const { type, repeat } = inputDefinition;

    if (type === "attribute") {
      const {
        device: definitionDevice,
        attribute: definitionAttribute
      } = inputDefinition as AttributeInputDefinition;

      const { device: inputDevice, attribute: inputAttribute } = input;

      const inputDevices = inputDevice ? inputDevice.split(',').filter(x => x) : [null];
      for (let i=0; i<inputDevices.length; i++) {
        const attribute = definitionAttribute || inputAttribute;
        const resolvedDevice = resolveDevice(
          published,
          inputDevices[i],
          definitionDevice
        );
  
        if (resolvedDevice != null && attribute != null) {
          yield `${resolvedDevice}/${attribute}`;
        }
      }

    } if (type === "command") {
      const {
        device: definitionDevice,
      } = inputDefinition as CommandInputDefinition;

      const { device: inputDevice, command } = input;

      const resolvedDevice = resolveDevice(
        published,
        inputDevice,
        definitionDevice
      );
  
      if (resolvedDevice != null && command != null) {
        yield `${resolvedDevice}/${command}`;
      }

    } else if (type === "complex") {
      if (inputDefinition.type === "complex") {
        if (repeat) {
          for (const entry of input) {
            yield* extractFullNamesFromInputsGen(
              entry,
              inputDefinition.inputs,
              published
            );
          }
        } else {
          yield* extractFullNamesFromInputsGen(
            input.inputs,
            inputDefinition.inputs,
            published
          );
        }
      } else {
        throw new Error();
      }
    }
  }
}

function* extractFullNamesFromWidgetsGen(widgets: Widget[]) {
  for (const widget of widgets) {
    const definition = definitionForWidget(widget);
    const inputs = widget.inputs;
    const inputDefinitions = definition!.inputs;
    const published = publishedDevices(inputs, inputDefinitions);
    yield* extractFullNamesFromInputsGen(inputs, inputDefinitions, published);
  }
}

export function extractFullNamesFromWidgets(widgets: Widget[]) {
  return Array.from(extractFullNamesFromWidgetsGen(widgets));
}

function* extractDeviceNamesFromInputsGen(
  inputs: InputMapping,
  inputDefinitions: InputDefinitionMapping
) {
  const inputNames = Object.keys(inputs);
  for (const name of inputNames) {
    const inputDefinition = inputDefinitions[name];
    if (!inputDefinition) {
      return;
    }
    const input = inputs[name];
    const { type, repeat } = inputDefinition;

    if (type === "device" && input != null) {
      yield input;
    } else if (type === "attribute" && input != null) {
      if(input?.device !== null) {
        // For some widgets(tabular widget), input.device field may contain multiple comma separated device names
        const inputDevices = input && input.device ? input.device.split(',').filter(x => x) : [null];
        for (let i=0; i<inputDevices.length; i++)
          yield inputDevices[i];
      }
    } else if (type === "complex") {
      if (inputDefinition.type === "complex") {
        if (repeat) {
          for (const entry of input) {
            yield* extractDeviceNamesFromInputsGen(
              entry,
              inputDefinition.inputs
            );
          }
        } else {
          yield* extractDeviceNamesFromInputsGen(
            input.inputs,
            inputDefinition.inputs
          );
        }
      } else {
        throw new Error();
      }
    }
  }
}

function* extractDeviceNamesFromWidgetsGen(widgets: Widget[]) {
  for (const widget of widgets) {
    const definition = definitionForWidget(widget);
    const inputs = widget.inputs;
    const inputDefinitions = definition!.inputs;
    yield* extractDeviceNamesFromInputsGen(inputs, inputDefinitions);
  }
}

export function extractDeviceNamesFromWidgets(widgets: Widget[]) {
  const result = Array.from(extractDeviceNamesFromWidgetsGen(widgets));
  const uniqueArr = result.reduce((acc, str) => {
    str?.split(",")?.forEach(subStr => {
      if (acc.indexOf(subStr) === -1) {
        acc.push(subStr);
      }
    });
    return acc;
  }, []);
  return uniqueArr;
}
