import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SvgLoader, SvgProxy } from "react-svgmt";
import SvgTooltipComponent from "./SvgTooltip";
import {
  getAttributeLastValueFromState,
  getAttributeLastQualityFromState,
} from "../../../shared/utils/getLastValueHelper";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { WEBSOCKET } from "../../../shared/state/actions/actionTypes";
import { createDeviceWithTangoDBFullPath } from "../../../dashboard/runtime/utils";
import { getTangoDB } from "../../../dashboard/dashboardRepo";
import prepareZoom from "./zoom.js";
// CSS mainly from the Synoptic application (https://gitlab.com/MaxIV/lib-maxiv-svgsynoptic)
import "./styles/SvgComponent.css";
import "./styles/main.css";
import "./styles/layers.css";
import "./styles/quicklinks.css";
import "./styles/tango.css";
import "./styles/tooltip.css";
import "./styles/thumbnail.css";
import { splitFullPath } from "../../../dashboard/DBHelper";

interface SvgProps {
  path: string;
  mode: string;
  layers: boolean;
  zoom: boolean;
}

const pattern = /^(model|section|alarm)=(.*)/;

function SvgComponent({ path, mode, layers, zoom }: SvgProps) {
  const [devices, setDevices] = useState<string[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === "run") {
      // TODO: Issue #1
      // Limit the subscribtion to the first 20 devices
      const restrictedDevices = devices.slice(0, 20);
      dispatch({
        type: WEBSOCKET.WS_SUBSCRIBE,
        payload: { devices: restrictedDevices },
      });
    }
    // else {
    //   dispatch({
    //     type: WEBSOCKET.WS_UNSUBSCRIBE,
    //   });
    // }
  }, [mode, devices, dispatch]);

  function activateSVG(svgNode) {
    var svgdevices = prepareTangoModel(svgNode);
    if (layers) prepareLayersManagement(svgNode);
    if (zoom) prepareZoom(svgNode);
    setDevices(svgdevices);
  }

  /*
  Scan the description tag of each svg node for a tango model.
  For each node, create a new attribute that will help the renderer
  (better search on attribute than description content)
  Return the list of devices to subscribe
  */
  function prepareTangoModel(svgNode) {
    var scannedDevices: string[] = [];
    const tangoDB = getTangoDB();
    svgNode.querySelectorAll("desc").forEach((child) => {
      if (child.textContent) {
        const lines = child.textContent.split("\n");
        lines.forEach((line) => {
          const match = line.trim().match(pattern);
          if (match) {
            if (match[1] === "model") {
              const kind = match[1].trim().toLowerCase();
              const isFullAttrPath = match[2].split("/").length > 3;
              let devicePathWithAttr = match[2].toLowerCase();
              if (!isFullAttrPath) {
                devicePathWithAttr = `${devicePathWithAttr}/state`;
              } 
              // Check if it exists
              const deviceFullPathWithAttr = createDeviceWithTangoDBFullPath(
                tangoDB,
                devicePathWithAttr
              );
              if (
                deviceList.nameList.some((dev) =>
                  deviceFullPathWithAttr.startsWith(dev)
                )
              ) {
                // check if not already registered
                if (!scannedDevices.includes(deviceFullPathWithAttr)) {
                  scannedDevices.push(deviceFullPathWithAttr);
                }
                child.parentElement.setAttribute(
                  "data-" + kind,
                  devicePathWithAttr
                );
                child.parentElement.setAttribute(
                  "data-tooltip-id",
                  "svg-tooltip"
                );
                child.parentElement.setAttribute("data-tooltip-float", "true");
                child.parentElement.setAttribute("data-tooltip-offset", 15);
              }
            }
            child.parentElement.classList.add(match[1]);
          }
        });
      }
    });
    return scannedDevices;
  }

  function prepareLayersManagement(svg) {
    // Remove first the old Toggle layer
    const oldNode = document.getElementById("ToggleLayerNode");
    if (oldNode && oldNode.parentNode) {
      oldNode.parentNode.removeChild(oldNode);
    }
    // Create a layer for all the toggle layer buttons
    var toggleLayerNode = document.createElement("div");
    toggleLayerNode.setAttribute("id", "ToggleLayerNode");
    toggleLayerNode.classList.add("layer-toggles");
    svg.parentElement.appendChild(toggleLayerNode);

    /* 
    Scan through all layers. Note in the selector, the target attribute should be all together
    with the g element to retrieve only the 2nd g level. With a space, 
    you will have all g including the children.
    */
    const layersSelector = 'svg > g > g[inkscape\\:groupmode="layer"]';

    svg.querySelectorAll(layersSelector).forEach((svgnode) => {
      const dataName = svgnode.getAttribute("inkscape:label");
      svgnode.setAttribute("data-name", dataName);
      svgnode.classList.add("layer");
      svgnode.setAttribute("display", null);
      svgnode.style.display = null;
      // Make togglable the layers
      if (dataName && !["background", "hidden", "symbols"].includes(dataName)) {
        svgnode.classList.add("togglable");
        var layerButton = document.createElement("div");
        var id = svgnode.getAttribute("id");
        var button = toggleLayerNode.appendChild(layerButton);
        button.textContent = dataName;
        button.classList.add("layer-toggle");
        button.classList.add(id);
        button.onclick = function() {
          toggleLayer(button, svgnode);
        };
      }
    });
  }

  function toggleLayer(button, layer) {
    var shown = !button.classList.contains("hidden");
    if (shown) {
      button.classList.add("hidden");
      layer.classList.add("hidden");
    } else {
      button.classList.remove("hidden");
      layer.classList.remove("hidden");
    }
  }

  const extractSynopticValues = (messages: Object) => {
    let svgValues: Array<{
      deviceName: string;
      attributeName: string;
      selector: string;
      lastValue: string;
      attributeQuality: string;
    }> = [];

    Object.keys(messages).forEach((deviceName) => {
      const message = messages[deviceName];

      Object.keys(message.attributes).forEach((attributeName) => {
        const lastValue = String(
          getAttributeLastValueFromState(messages, deviceName, attributeName)
        );
        const attributeQuality = getAttributeLastQualityFromState(
          messages,
          deviceName,
          attributeName
        );
        const [, devName] = splitFullPath(deviceName);
        svgValues.push({
          deviceName: devName,
          attributeName: attributeName,
          selector: `[data-model="${devName}/${attributeName}"]`,
          lastValue: lastValue,
          attributeQuality: attributeQuality,
        });
      });
    });
    return svgValues;
  };

  const synopticValues = useSelector((state: IRootState) => {
    return extractSynopticValues(state.messages);
  });

  const deviceList = useSelector((state: IRootState) => {
    return state.deviceList;
  });

  // TODO: what does the onElement Selected shoud do?
  const onElementSelected = (node) => {
    // console.log("Node selected", node);
  };

  function renderSynoptic() {
    if (deviceList.nameList && deviceList.nameList.length > 0 && path) {
      return (
        <Fragment>
          <SvgLoader
            height="100%"
            width="100%"
            onSVGReady={activateSVG}
            svgXML={path}
          >
            {synopticValues.map((item, ix) => {
              if (item.attributeName === "state") {
                return (
                  <SvgProxy
                    key={ix}
                    onElementSelected={onElementSelected}
                    class="state"
                    selector={item.selector}
                    data-value={item.lastValue}
                    data-quality={item.attributeQuality}
                  ></SvgProxy>
                );
              } else {
                return (
                  <SvgProxy
                    key={ix}
                    onElementSelected={onElementSelected}
                    selector={item.selector + " tspan"}
                    data-value={item.lastValue}
                    data-quality={item.attributeQuality}
                  >
                    {item.lastValue}
                  </SvgProxy>
                );
              }
            })}
            {<SvgTooltipComponent />}
          </SvgLoader>
        </Fragment>
      );
    } else if (mode === "run") {
      return <p> Waiting for the list of existing devices </p>;
    } else {
      return <p> Waiting for a synoptic to be selected </p>;
    }
  }
  return <div className="SvgComponent">{renderSynoptic()}</div>;
}
export default SvgComponent;
