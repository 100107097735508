import React, { Suspense, useState } from "react";

// In order to avoid importing the entire plotly.js library. Note that this mutates the global PlotlyCore object.
import PlotlyCore from "plotly.js/lib/core";
import PlotlyScatter from "plotly.js/lib/scatter";
import createPlotlyComponent from "react-plotly.js/factory";
import { showHideTangoDBName } from "../../DBHelper";

PlotlyCore.register([PlotlyScatter]);
const Plotly = createPlotlyComponent(PlotlyCore);

export interface PlotParams {
  height: number;
  width: number;
  staticMode?: boolean;
  timeWindow: number;
  showZeroLine?: boolean;
  logarithmic?: boolean;
  showTangoDB: boolean;
  textColor: string;
  backgroundColor: string;
}

export interface Trace {
  x?: number[];
  y?: number[];
  fullName: string;
  axisLocation: string; //"left" | "right";
  lineColor: string;
}

interface PlotProps {
  traces: Trace[];
  params: PlotParams;
  mode: string;
  showTangoDB: boolean;
}

export function dataAndRange(traces: Trace[], params: PlotParams, mode: String) {
  const { timeWindow, showTangoDB } = params;
  const data = traces.map((trace: Trace) => {
    const yaxis = trace.axisLocation === "left" ? "y1" : "y2";
    const fullName = showHideTangoDBName(true, showTangoDB, trace.fullName)
    return {
      x: trace.x || [null],
      y: trace.y || [null],
      name: fullName,
      yaxis,
      line: {
        color: trace.lineColor,
        width: 2,
      },
    };
  });
  //Set the x-axis range to include all data points recieved less than TIME_WINDOW
  //seconds ago, with a minimum time interval of TIME_WINDOW
  let minX = Number.MAX_SAFE_INTEGER;
  let maxX = 0;
  data.forEach((plot) => {
    if (plot.x && plot.x[0] && plot.x[0] < minX) {
      minX = plot.x[0];
    }
    if (plot.x && plot.x[0] && plot.x[plot.x.length - 1]) {
      maxX = Math.max(maxX, plot.x[plot.x.length - 1] || 0);
    }
  });
  let range;
  if (mode === "TIME_WINDOW") {
    range = [Math.max(minX, maxX - timeWindow), Math.max(maxX, timeWindow)];
  } else {
    range = [minX, maxX];
  }

  return { data, range };
}

export default function Plot(props: PlotProps) {
  const [mode, setMode] = useState("TIME_WINDOW");
  const { traces, params } = props;
  const {
    staticMode,
    width,
    height,
    showZeroLine,
    logarithmic,
    textColor,
    backgroundColor,
  } = params;

  const { data, range } = dataAndRange(traces, params, mode);
  const xaxis = {
    range,
    title: "Time (s)",
    titlefont: { size: 12 },
    zeroline: false,
  };

  const zeroline = showZeroLine !== false;
  const hasRight = data.find(({ yaxis }) => yaxis === "y2") != null;
  const hasLeft =
    hasRight === false || data.find(({ yaxis }) => yaxis === "y1") != null;

  const addY1 = hasLeft
    ? {
        yaxis: {
          side: "left",
          showgrid: false,
          zeroline,
          type: logarithmic ? "log" : "",
        },
      }
    : {};
  const addY2 = hasRight
    ? {
        yaxis2: {
          side: "right",
          overlaying: "y",
          showgrid: false,
          zeroline,
        },
      }
    : {};

  const layout = {
    font: {
      family: "Helvetica, Arial, sans-serif",
      color: textColor,
    },
    paper_bgcolor: backgroundColor,
    plot_bgcolor: backgroundColor,
    xaxis,
    margin: {
      l: 30,
      r: 30,
      t: 15,
      b: 35,
    },
    autosize: true,
    showlegend: props.mode === "edit" ? false : true, //the legend has been disabled in edit mode because it generates problems when dropped in a boxwidget with click handler
    uirevision: true,
    legend: {
      y: 1.2,
      orientation: "h",
    },
    ...addY1,
    ...addY2,
  };

  const overriding = {
    ...addY1,
    ...addY2,
    width,
    height,
  };
  return (
    <Suspense fallback={null}>
      <Plotly
        onRelayout={(e: any) =>
          e["xaxis.autorange"] ? setMode("HISTORY") : setMode("TIME_WINDOW")
        }
        data={data}
        layout={{ ...layout, ...overriding }}
        config={{ staticPlot: staticMode === true }}
        responsive={true}
        style={{ width: "100%", height: "100%" }}
      />
    </Suspense>
  );
}
