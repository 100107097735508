import React from "react";
import { useSelector } from "react-redux";
import { getAttributeLastQualityFromState } from "../../../../shared/utils/getLastValueHelper";

export const QualityIndicator = ({ name, deviceName }) => {

    const quality = useSelector((state) => {
        return getAttributeLastQualityFromState(state.messages, deviceName, name);
    });

    const sub =
        {
            ATTR_VALID: "valid",
            ATTR_INVALID: "invalid",
            ATTR_CHANGING: "changing",
            ATTR_ALARM: "alarm",
            ATTR_WARNING: "warning",
        }[quality] || "invalid";

    return (
        <span className={`QualityIndicator ${sub}`} title={quality}>
            {sub.toUpperCase()}
        </span>
    );
};