import React, { useState, useEffect } from "react";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { useSelector } from "react-redux";
import {
  getAttributeLastQualityFromState,
  getAttributeLastTimeStampFromState,
  getAttributeLastValueFromState,
} from "../../../shared/utils/getLastValueHelper";
import { JSONTree, parseJSONObject } from "../../../shared/utils/JSONTree";
import "../Tabular/Tabular.css";
import numeral  from "numeral";
import { sprintf } from "sprintf-js";
import { decimalToSexagesimal } from "../../../shared/utils/utils";
import jsonata from "jsonata";

interface Props {
  attributeName: string;
  deviceName: string;
  dataType: string;
  mode: string;
  showAttrQuality: boolean;
  precision: number;
  format: string;
  scientificNotation: boolean;
  showEnumLabels: boolean;
  jsonCollapsed: boolean;
  enumlabels: string[];
  unit: string | undefined;
  showUnit: boolean;
  minAlarm?: number | undefined;
  maxAlarm?: number | undefined;
  alignValueRight: boolean;
}

const renderQuality = (mode: string, quality: string) => {
  if (mode !== "run") return null;

  const sub =
    {
      ATTR_VALID: "valid",
      ATTR_INVALID: "invalid",
      ATTR_CHANGING: "changing",
      ATTR_ALARM: "alarm",
      ATTR_WARNING: "warning",
    }[quality] || "invalid";

  return (
    <span
      style={{ marginLeft: "5px" }}
      className={`QualityIndicator ${sub}`}
      title={quality}
    >
      {sub.toUpperCase()}
    </span>
  );
};

const AttributeValues: React.FC<Props> = ({
  attributeName,
  deviceName,
  dataType,
  mode,
  showAttrQuality,
  precision,
  format,
  showUnit,
  scientificNotation,
  showEnumLabels,
  jsonCollapsed,
  enumlabels,
  unit,
  minAlarm,
  maxAlarm,
  alignValueRight,
}) => {
  const unitSuffix = unit && showUnit ? ` ${unit} ` : "";
  const value = useSelector((state: IRootState) => {
    return getAttributeLastValueFromState(
      state.messages,
      deviceName,
      attributeName
    );
  });

  const timestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(
      state.messages,
      deviceName,
      attributeName
    )?.toString();
  });

  const quality = useSelector((state: IRootState) => {
    return getAttributeLastQualityFromState(
      state.messages,
      deviceName,
      attributeName
    )?.toString();
  });

  let backgroundColor = "";
  if (maxAlarm && minAlarm && value !== undefined) {
    if (value >= maxAlarm || value <= minAlarm) backgroundColor = "#ffaaaa";
  }

  const cssObject = {
    backgroundColor: backgroundColor,
    marginLeft: alignValueRight ? "auto" : "",
    height: "fit-content",
  };

  const [parsedValue, setParsedValue] = useState<any>(null);
  const [result, setResult] = useState<string | null>("...");

  useEffect(() => {
    if (value !== undefined) {
      const parsedValue = parseJSONObject(value);

      if (parsedValue !== null && format !== "") {
        try {
          const expression = jsonata(format);
          expression.evaluate(parsedValue).then(evalResult => {
            if (evalResult !== undefined) {
              setResult(JSON.stringify(evalResult));
            } else {
              setResult("No result evaluating JSON expression");
            }
          });
        } catch (error) {
          setResult("Error evaluating JSON expression");
        }
      }
      setParsedValue(parsedValue);
    }
  }, [value, format]);

  let valueDisplay: string | number | JSX.Element | null = "...";

  if (parsedValue !== null) {
    if (format !== "") {
      valueDisplay = <div>{result}</div>;
    } else {
      valueDisplay = (
        <div>
          <JSONTree jsonCollapsed={jsonCollapsed} data={parsedValue} />
        </div>
      );
    }
  } else {
    if (typeof value === "number") {
      if (showEnumLabels && enumlabels?.length > 0)
        valueDisplay = enumlabels?.[value] || value;
      else if (format !== "") {
        if (format.startsWith("%")) {
          try {
            if (format.toString() === "%s") {
              valueDisplay = decimalToSexagesimal(Number(value), precision);
            } else {
              valueDisplay = sprintf(format, value);
            }
          } catch (e) {
            valueDisplay = "Format not valid" + e;
          }
        } else if (format.startsWith(".")) {
          try {
            let [functionName, functionValue] = format.slice(1, -1).split("(");
            switch (functionName) {
              case "add": {
                valueDisplay = numeral(value)
                  .add(functionValue)
                  .value();
                break;
              }
              case "multiply": {
                valueDisplay = numeral(value)
                  .multiply(functionValue)
                  .value();
                break;
              }
              case "subtract": {
                valueDisplay = numeral(value)
                  .subtract(functionValue)
                  .value();
                break;
              }
              case "divide": {
                valueDisplay = numeral(value)
                  .divide(functionValue)
                  .value();
                break;
              }
              default: {
                valueDisplay = "Function not supported: " + functionName;
                break;
              }
            }
          } catch (e) {
            valueDisplay = "Wrong function format:" + format;
          }
        } else {
          try {
            valueDisplay = numeral(value).format(format);
          } catch (e) {
            valueDisplay = "Unsupported format:" + format;
          }
        }
        valueDisplay =
          typeof valueDisplay === "number"
            ? valueDisplay.toFixed(precision)
            : valueDisplay;
      } else if (scientificNotation)
        valueDisplay = value.toExponential(precision);
      else
        valueDisplay = Number.isInteger(value)
          ? value
          : Number(value).toFixed(precision);
    } else valueDisplay = String(value);
  }

  return (
    <div
      title={new Date(timestamp * 1000).toUTCString()}
      style={cssObject}
      className={dataType === "DevState" ? "stateTango " + value : ""}
    >
      {valueDisplay !== undefined ? valueDisplay : "..."}
      {showAttrQuality ? renderQuality(mode, quality) : null} {unitSuffix}
    </div>
  );
};

export default AttributeValues;
