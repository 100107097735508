import React, {
  useState,
  Fragment,
  CSSProperties,
  ChangeEvent,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { getAttributeLastValueFromState } from "../../../shared/utils/getLastValueHelper";
import { getTangoDB } from "../../../dashboard/dashboardRepo";
import { executeCommand } from "../../../shared/state/actions/tango";
import { WidgetProps } from "../types";
import { parseCss } from "../../components/Inspector/StyleSelector";
import {
  WidgetDefinition,
  CommandInputDefinition,
  StringInputDefinition,
  StyleInputDefinition,
  BooleanInputDefinition,
  NumberInputDefinition,
  AttributeInputDefinition,
  ColorInputDefinition,
  SelectInputDefinition,
} from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { showHideTangoDBName } from "../../DBHelper";


type Inputs = {
  title: StringInputDefinition;
  onCommand: CommandInputDefinition;
  offCommand: CommandInputDefinition;
  attribute: AttributeInputDefinition;
  commandOn: StringInputDefinition;
  commandOff: StringInputDefinition;
  showDevice: BooleanInputDefinition;
  showCommand: BooleanInputDefinition;
  alignSendButtonRight: BooleanInputDefinition;
  onStateArgs: StringInputDefinition;
  offStateArgs: StringInputDefinition;
  onStateImageUrl: StringInputDefinition;
  offStateImageUrl: StringInputDefinition;
  onStateCss: StyleInputDefinition;
  offStateCss: StyleInputDefinition;
  imageCss: StyleInputDefinition;
  displayOutput: BooleanInputDefinition;
  timeDisplayOutput: NumberInputDefinition;
  textColor: ColorInputDefinition;
  backgroundColor: ColorInputDefinition;
  size: NumberInputDefinition;
  font: SelectInputDefinition;
  inputWidgetCss: StyleInputDefinition;
  showTangoDB: BooleanInputDefinition;
};

type Props = WidgetProps<Inputs>;

const CommandSwitch: React.FC<Props> = (props: Props) => {
  const {
    title,
    showDevice,
    showCommand,
    showTangoDB,
    timeDisplayOutput,
    attribute,
    onCommand,
    offCommand,
    commandOn,
    commandOff,
    onStateArgs,
    offStateArgs,
    displayOutput,
    backgroundColor,
    textColor,
    size,
    font,
  } = props.inputs;

  const widgetCss = props.inputs.inputWidgetCss
    ? parseCss(props.inputs.inputWidgetCss).data
    : {};
  const imageStyle = {
    width: "50px",
    height: "25px",
  };
  let outerDivCss;
  let waiting = false;
  const onCommandName = onCommand.command;
  const offCommandName = offCommand.command;
  const getAttributeValue = (key): any => {
    if (key === "onStateCss") {
      return props.inputs.onStateCss;
    } else if (key === "offStateCss") {
      return props.inputs.offStateCss;
    } else if (key === "onStateImageUrl") {
      return props.inputs.onStateImageUrl;
    } else if (key === "offStateImageUrl") {
      return props.inputs.offStateImageUrl;
    } else if (key === "onStateArgs") {
      return props.inputs.onStateArgs || "one";
    } else if (key === "offStateArgs") {
      return props.inputs.offStateArgs || "zero";
    } else if (key === "imageCss") {
      return props.inputs.imageCss;
    } else if (key === "title") {
      return props.inputs.title;
    } else if (key === "showDevice") {
      return props.inputs.showDevice;
    } else if (key === "showCommand") {
      return props.inputs.showCommand;
    } else return "";
  };
  const [pending, setPending] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [incorrectResult, setIncorrectResult] = useState(false);
  const onStateImageUrl = getAttributeValue("onStateImageUrl");
  const offStateImageUrl = getAttributeValue("offStateImageUrl");
  const onStateCss = parseCss(getAttributeValue("onStateCss")).data;
  const offStateCss = parseCss(getAttributeValue("offStateCss")).data;
  const imageCssData = parseCss(getAttributeValue("imageCss")).data;
  const [displayOutputCommand, setDisplayOutputCommand] = useState("");
  const isChecked = checkbox;
  const dispatch = useDispatch();

  const attributeValue = useSelector((state: IRootState) => {
    if (attribute?.attribute) {
      const attributeName = attribute.attribute;
      return getAttributeLastValueFromState(
        state.messages,
        attribute.device,
        attributeName
      );
    } else {
      return "";
    }
  });
  const showSelectedDeviceOrCommand = (keyword) => {
    let returnVal = "";

    if ("device" === keyword) {
      returnVal = checkbox
        ? null !== onCommand.device
          ? onCommand.device + "/"
          : "Device/"
        : null !== offCommand.device
        ? offCommand.device + "/"
        : "Device/";
    } else {
      returnVal = checkbox
        ? null !== onCommand.command
          ? onCommand.command
          : "Command"
        : null !== offCommand.command
        ? offCommand.command
        : "Command";
    }

    return returnVal;
  };
  const isInteger = (value) => {
    return /^\d+$/.test(value);
  };

  const isFloat = (value) => {
    return !Number.isNaN(Number.parseFloat(value));
  };

  const isArray = (value) => {
    return value[0] === "[";
  };

  const isObject = (value) => {
    return value[0] === "{";
  };

  const stringWithQuotes = (value) => {
    return value[0] === '"';
  };
  const checkCurrentStatus = (
    attributeValue,
    commandType: string,
    command: string
  ) => {
    if (!["On", "Off"].includes(commandType)) return;
    command = stringWithQuotes(command)
      ? command.substring(1, command.length - 1)
      : command;
    if (commandType === "On") {
      if (attributeValue?.toString() !== command) {
        setIncorrectResult(true);
      } else setIncorrectResult(false);
    } else {
      if (attributeValue?.toString() !== command) {
        setIncorrectResult(true);
      } else setIncorrectResult(false);
    }
  };
  const validateInputArray = (input, acceptedType: string) => {
    if (input.indexOf(",") !== -1) {
      let data = input.split(",");

      if (acceptedType.includes("String")) {
        let list: Array<string> = [];
        data.forEach((element) => {
          list.push(element);
        });
        return list;
      } else if (acceptedType.includes("Char")) {
        let list: Array<number> = [];
        data.forEach((element) => {
          list.push(Number.parseInt(element[1]));
        });
        return list;
      } else {
        let list: Array<Object> = [];
        data.forEach((element) => {
          list.push(Number.parseFloat(element));
        });
        return list;
      }
    } else {
      if (acceptedType.includes("String")) {
        let list: Array<string> = [];
        list.push(input);
        return list;
      } else {
        let list: Array<Object> = [];
        list.push(Number.parseFloat(input));
        return list;
      }
    }
  };
  const inputConvertion = (input, acceptedType) => {
    let result;
    if (!input) result = "";
    else if (input === "True" || input === "true") result = true;
    else if (input === "False" || input === "false") result = false;
    else if (isInteger(input)) result = parseInt(input);
    else if (isFloat(input)) result = parseFloat(input);
    else if (isArray(input)) {
      let inputArray: Array<Object>;
      var trim = input.substring(1, input.length - 1);
      acceptedType.includes("Array")
        ? (inputArray = validateInputArray(trim, acceptedType))
        : (inputArray = []);
      result = inputArray;
    } else if (isObject(input)) result = input;
    else if (stringWithQuotes(input)) {
      trim = input.substring(1, input.length - 1);
      result = trim;
    } else result = input;
    //else are there other possible types?

    return result;
  };
  const checkboxChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (true === pending) {
      return;
    }
    setCheckbox(!isChecked);
    setPending(true);

    if (false === checkbox) {
      const acceptedType: string = onCommand["acceptedType"];
      const input = onStateArgs;

      let result = inputConvertion(input, acceptedType);
      setDisplayOutputCommand(onCommand.command);
      dispatch(
        executeCommand(
          getTangoDB(),
          onCommand.command,
          result,
          onCommand.device
        )
      );
      checkCurrentStatus(attributeValue, "On", commandOn);
    } else {
      const acceptedType: string = offCommand["acceptedType"];
      const input = offStateArgs;

      let result = inputConvertion(input, acceptedType);
      setDisplayOutputCommand(offCommand.command);
      dispatch(
        executeCommand(
          getTangoDB(),
          offCommand.command,
          result,
          offCommand.device
        )
      );
      checkCurrentStatus(attributeValue, "Off", commandOff);
    }
    setPending(false);
  };

  const validateUrl = (url) => {
    return undefined !== url && -1 !== url.indexOf("http");
  };
  const commandOutput = useSelector((state: IRootState) => {
    if (displayOutputCommand === onCommandName) {
      return state.commandOutput?.[onCommand.device]?.[onCommand.command];
    } else if (displayOutputCommand === offCommandName) {
      return state.commandOutput?.[offCommand.device]?.[offCommand.command];
    } else return "";
  });

  const outputAndStyle = (): [string, CSSProperties] => {
    const { mode } = props;

    const shownOutput = displayOutput
      ? mode === "run"
        ? commandOutput
        : "output"
      : "";

    if (mode !== "run") {
      return [shownOutput, { color: "gray", fontStyle: "italic" }];
    }

    if (displayOutput && !commandOutput) {
      return ["n/a", { color: "gray" }];
    }

    return [shownOutput, {}];
  };

  useEffect(() => {
    const checkCommandStatus = (
      attributeValue,
      commandOn: string,
      commandOff: string
    ) => {
      if (stringWithQuotes(commandOn)) {
        commandOn = commandOn.substring(1, commandOn.length - 1);
      }
      if (stringWithQuotes(commandOff)) {
        commandOff = commandOff.substring(1, commandOff.length - 1);
      }
      if (commandOn.trim().length === 0 && commandOff.trim().length === 0) {
        setIncorrectResult(false);
      } else if (attributeValue?.toString() === commandOn) {
        setCheckbox(true);
        setIncorrectResult(false);
      } else if (attributeValue?.toString() === commandOff) {
        setCheckbox(false);
        setIncorrectResult(false);
      } else {
        setIncorrectResult(true);
      }
    };

    checkCommandStatus(attributeValue, commandOn, commandOff);
  }, [attributeValue, commandOn, commandOff]);

  const [output, outputStyle] = outputAndStyle();
  if (
    output[0] !== undefined &&
    (displayOutputCommand === onCommandName ||
      displayOutputCommand === offCommandName) &&
    !waiting
  ) {
    waiting = true;
    setTimeout(() => {
      setDisplayOutputCommand("");
      waiting = false;
      // this.setState({});
    }, timeDisplayOutput);
  }

  const fullOutputStyle: CSSProperties = {
    marginLeft: "0.5em",
    fontSize: "80%",
    display: "block",
    ...outputStyle,
  };
  const style: CSSProperties = {
    backgroundColor,
    display: "flex",
    color: textColor,
    fontSize: size + "em",
    height: "100%",
    ...widgetCss,
  };
  if (font) {
    style["fontFamily"] = font;
  }

  outerDivCss = { ...style };

  let titleLabel = undefined !== title && "" !== title ? title + " " : "";
  titleLabel += showDevice ?  showHideTangoDBName(true, showTangoDB, showSelectedDeviceOrCommand("device"))  : "";
  titleLabel += showCommand ? showSelectedDeviceOrCommand("command") : "";

  let Checkbox;
  if (
    false === validateUrl(onStateImageUrl) ||
    false === validateUrl(offStateImageUrl)
  ) {
    Checkbox = (
      <span className={props.inputs.alignSendButtonRight ? "ml-auto" : ""}>
        <label className="toggle">
          <input
            className="attribute-checkbox"
            type="checkbox"
            checked={checkbox}
            onChange={checkboxChange}
          />
          <span
            className="slider round"
            style={checkbox ? onStateCss : offStateCss}
          >
            {incorrectResult ? (
              <FontAwesomeIcon
                icon={faExclamation}
                color="red"
                title="The specified command On/Off status not correct"
              />
            ) : (
              ""
            )}
          </span>
        </label>
      </span>
    );
  } else {
    const imageUrl = true === checkbox ? onStateImageUrl : offStateImageUrl;
    const imageCss =
      undefined !== imageCssData
        ? { ...imageStyle, ...imageCssData }
        : imageStyle;

    Checkbox = (
      <span className={props.inputs.alignSendButtonRight ? "ml-auto" : ""}>
        <label className="toggle">
          <input
            type="checkbox"
            className="attribute-checkbox"
            id="attribute-checkbox"
            checked={checkbox}
            onChange={checkboxChange}
          />
          <img alt="not found" src={imageUrl} style={imageCss} />
        </label>
      </span>
    );
  }

  let outputSpan =
    displayOutputCommand === onCommandName ||
    displayOutputCommand === offCommandName ? (
      <span style={fullOutputStyle}>{output}</span>
    ) : null;

  return (
    <div style={outerDivCss}>
      <Fragment>
        {titleLabel} {Checkbox}
        {outputSpan}
      </Fragment>
    </div>
  );
};

const definition: WidgetDefinition<Inputs> = {
  type: "Command_Switch",
  name: "Command Switch",
  defaultWidth: 12,
  defaultHeight: 2,

  inputs: {
    title: {
      type: "string",
      label: "Title",
      placeholder: "Title of widget",
    },
    onCommand: {
      label: "On Switch Command",
      type: "command",
      intype: "Any",
    },
    offCommand: {
      label: "Off Switch Command",
      type: "command",
      intype: "Any",
    },
    attribute: {
      type: "attribute",
      label: "Status Attribute",
      dataFormat: "scalar",
      required: false,
    },
    commandOn: {
      type: "string",
      label: "On Status",
      placeholder: "Status for command on",
      default: "",
    },
    commandOff: {
      type: "string",
      label: "Off Status",
      placeholder: "Status for command off",
      default: "",
    },
    showDevice: {
      type: "boolean",
      label: "Show Device",
      default: true,
    },
    showTangoDB: {
      type: "boolean",
      label: "Show Tango database name",
      default: false,
    },
    showCommand: {
      type: "boolean",
      label: "Show Command",
      default: true,
    },
    displayOutput: {
      type: "boolean",
      label: "Display Output",
      default: true,
    },
    alignSendButtonRight: {
      type: "boolean",
      label: "Align the Send Button to Right",
      default: true,
    },
    timeDisplayOutput: {
      type: "number",
      label: "TimeOut for output display ms",
      default: 3000,
      nonNegative: true,
    },
    onStateArgs: {
      type: "string",
      label: "On State Argument",
      placeholder: "Ex. [1,2,3]",
    },
    offStateArgs: {
      type: "string",
      label: "Off State Argument",
      placeholder: "Ex. [1,2,3]",
    },
    onStateImageUrl: {
      type: "string",
      label: "On State Image Url",
      placeholder: "https://imagelocation?onImage.jpg",
    },
    offStateImageUrl: {
      type: "string",
      label: "Off State Image Url",
      placeholder: "https://imagelocation?offImage.jpg",
    },
    onStateCss: {
      type: "style",
      label: "On State CSS",
    },
    offStateCss: {
      type: "style",
      label: "Off State CSS",
    },
    imageCss: {
      type: "style",
      label: "Image CSS",
    },
    textColor: {
      label: "Text Color",
      type: "color",
      default: "#000000",
    },
    backgroundColor: {
      label: "Background Color",
      type: "color",
      default: "#ffffff",
    },
    size: {
      label: "Text size (in units)",
      type: "number",
      default: 1,
      nonNegative: true,
    },
    font: {
      type: "select",
      default: "Helvetica",
      label: "Font type",
      options: [
        {
          name: "Default (Helvetica)",
          value: "Helvetica",
        },
        {
          name: "Monospaced (Courier new)",
          value: "Courier new",
        },
      ],
    },
    inputWidgetCss: {
      type: "style",
      label: "Widget CSS",
      default: "",
    },
  },
};

const CommandSwitchExport = { component: CommandSwitch, definition };
export default CommandSwitchExport;
