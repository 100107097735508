import { createSelector } from "reselect";

import { IRootState } from "../reducers/rootReducer";


function getAttributeListState(state: IRootState) {
  return state.attributesList;
}

export const getAttributeList = createSelector(
  getAttributeListState,
  state => state.attributeList
);
