import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import {
  getAttributeLastTimeStampFromState,
} from "../../../shared/utils/getLastValueHelper";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import AttributeInputType from "../../../shared/utils/AttributeInputType/AttributeInputType";

interface Props {
  mode: string;
  attributeName: string;
  deviceName: string;
  alarms: [number | undefined, number | undefined];
  bounds: [number | undefined, number | undefined];
  type: string;
  value: any;
  isValid: boolean;
  placeholder: string;
  validating: boolean;
  onFocus: (event: FocusEvent) => void;
  onBlur: (event: FocusEvent) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  alignValueRight: boolean;
}

const AttributeValues: React.FC<Props> = ({
  attributeName,
  deviceName,
  alarms,
  bounds,
  type,
  value,
  isValid,
  placeholder,
  validating,
  onFocus,
  onBlur,
  onChange,
  alignValueRight,
}) => {
  const timestamp = useSelector((state: IRootState) => {
    return getAttributeLastTimeStampFromState(
      state.messages,
      deviceName,
      attributeName
    )?.toString();
  });

  const cssObject = {
    marginLeft: alignValueRight ? "auto" : "",
    height: "fit-content",
  };
  return (
    <div title={new Date(timestamp * 1000).toUTCString()} style={cssObject}>
      <AttributeInputType
        label={false}
        alarms={alarms}
        bounds={bounds}
        type={type}
        value={value}
        isValid={isValid}
        placeholder={placeholder}
        validating={validating}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};

export default AttributeValues;
