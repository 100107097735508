import React, { Component } from "react";
import { connect } from "react-redux";
import "./ModeToggleButton.css";
import { IRootState } from "../../shared/state/reducers/rootReducer";
import { SelectedDashboardState } from "../../shared/state/reducers/selectedDashboard";
import { IUserState } from "../../shared/user/state/reducer"
import {
  getSelectedDashboard
} from "../../shared/state/selectors";
import { isEditableDashboard } from "../utils"

interface Props {
  onClick: () => void;
  disabled: boolean;
  mode: "edit" | "run";
  title: string;
  user?: IUserState;
  selectedDashboard?: SelectedDashboardState;
}

class ModeToggleButton extends Component<Props> {
  public render() {
    const { onClick, mode, disabled, title } = this.props;

    let editStopButton = ["Edit", "pencil"]; 
    if(this.props.user !== undefined && this.props.selectedDashboard !== undefined){
      if(!isEditableDashboard(this.props.selectedDashboard, this.props.user).result)
      {
        editStopButton = ["Stop", "square"]; 
      }
    }

    const [label, icon] =
      mode === "run" ? editStopButton : ["Start", "play"];

    return (
      <button
        id="mode-toggle-btn"
        type="button"
        onClick={onClick}
        disabled={disabled}
        style={{
          cursor: disabled ? "not-allowed" : ""
        }}
        tabIndex={-1}
        title={title !== '' ? 'Running since: '+title : ''}
      >
        <span className={`fa fa-${icon}`} /> {label}
      </button>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    selectedDashboard: getSelectedDashboard(state),
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(ModeToggleButton);