import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getSelectedSynoptic,
  getUserName,
  getNotification,
  getMode,
  getUserGroups,
} from "../../state/selectors";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { Synoptic } from "../../types";
import {
  renameSynoptic,
  cloneSynoptic,
  undo,
  redo,
  shareSynoptic,
} from "../../state/actionCreators";
import { Notification } from "../../types";
import ShareSynopticModal from "../modals/ShareSynopticModal";
import "./SynopticTitle.css";

const redoHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+Shift+z" : "Ctrl+y";
const undoHotKey =
  window.navigator.platform === "MacIntel" ? "Cmd+z" : "Ctrl+z";

interface Props {
  synoptic: Synoptic;
  loggedInUser: string;
  notification: Notification;
  mode: "edit" | "run";
  onTitleChange: (id: string, name: string) => void;
  onClone: (id: string, newUser: string) => void;
  onUndo: () => void;
  onRedo: () => void;
  onShareSynoptic: (
    id: string,
    group: string,
    groupWriteAccess: boolean
  ) => void;
  userGroups: string[];
}

interface State {
  wipName: string | null;
  showShareModal: boolean;
}

class SynopticTitle extends Component<Props, State> {
  public inputRef: any;

  constructor(props) {
    super(props);
    this.handleShareSynoptic = this.handleShareSynoptic.bind(this);
    this.state = { wipName: null, showShareModal: false };
  }

  private handleShareSynoptic(
    id: string,
    group: string,
    groupWriteAccess: boolean
  ) {
    this.props.onShareSynoptic(id, group, groupWriteAccess);
    this.setState({ showShareModal: false });
  }

  public render() {
    const { synoptic, loggedInUser, mode, userGroups } = this.props;
    const {
      id,
      user: owner,
      group,
      lastUpdatedBy,
      updateTime,
      groupWriteAccess,
    } = synoptic;

    const isMine = loggedInUser === owner;
    const inEditMode = mode === "edit";
    const showRecentlyEditedMessage =
      wasRecently(updateTime) &&
      lastUpdatedBy &&
      lastUpdatedBy !== loggedInUser;
    const editableTitle = (isMine || !owner) && inEditMode;
    const isSharedWithMe = userGroups.includes(group || "") && !isMine;

    const showOwnedByElseMsg = !isMine && owner;
    const showSharedMessage = isSharedWithMe;
    const showSharedForEditMessage = isSharedWithMe && groupWriteAccess;

    const clonable = !!owner; // && !isMine; user requested option to always clone a synoptic!
    const { msg: notificationMsg } = this.props.notification;

    const shareButtonColor =
      isMine && group ? "#17a6b7" : isMine ? "inherit" : "greytext";

    const { wipName } = this.state;
    const name =
      wipName != null ? wipName : synoptic.name || "Untitled synoptic";
    const redoDisabled = this.props.synoptic.history.redoLength === 0;
    const undoDisabled = this.props.synoptic.history.undoLength === 0;

    if (!loggedInUser) {
      if (inEditMode) {
        //feedBackService.setData({level: NotificationLevel.INFO, message: 'You need to be logged in to save synoptics'})
        console.log("You need to be logged in to save synoptics");
      }
      return (
        <div className="synoptic-menu">
          {!!id && <span style={{ marginLeft: "0.5em" }}>{name}</span>}
        </div>
      );
    }

    if (inEditMode && notificationMsg) {
      //const error = {level: level, message: notificationMsg}
      //feedBackService.setData(error);
    }

    return (
      <>
        {this.state.showShareModal && (
          <ShareSynopticModal
            id={synoptic.id}
            name={synoptic.name}
            userGroups={userGroups}
            currentGroup={synoptic.group}
            currentGroupWriteAccess={synoptic.groupWriteAccess}
            onClose={() => this.setState({ showShareModal: false })}
            onShare={this.handleShareSynoptic}
          />
        )}
        <div className="synoptic-menu">
          <input
            ref={(ref) => (this.inputRef = ref)}
            type="text"
            value={name}
            disabled={!editableTitle}
            onChange={(e) => this.setState({ wipName: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter" && wipName != null) {
                this.props.onTitleChange(id, wipName);
                e.currentTarget.blur();
              }
            }}
            onBlur={() => this.setState({ wipName: null })}
            onFocus={() => this.inputRef.select()}
          />
          {inEditMode && (
            <>
              <button
                className="synoptic-menu-button"
                title={`Undo last action\n${undoHotKey}`}
                onClick={this.props.onUndo}
                disabled={undoDisabled}
              >
                <FontAwesomeIcon icon="undo" />
              </button>
              <button
                className="synoptic-menu-button"
                title={`Redo last action\n${redoHotKey}`}
                onClick={this.props.onRedo}
                disabled={redoDisabled}
              >
                <FontAwesomeIcon icon="redo" />
              </button>
            </>
          )}
          {inEditMode && userGroups && userGroups.length > 0 && (
            <button
              className="synoptic-menu-button"
              style={{
                color: shareButtonColor,
              }}
              disabled={!synoptic.id || !isMine}
              title={
                synoptic.group
                  ? "This synoptic is shared with the group '" +
                    synoptic.group +
                    "'"
                  : "Share this synoptic with a user group"
              }
              onClick={() => this.setState({ showShareModal: true })}
            >
              <FontAwesomeIcon icon="share-alt" />
            </button>
          )}
          {showOwnedByElseMsg && inEditMode && (
            <span
              className="notification-msg "
              title={`This synoptic is owned by ${owner}`}
            >
              <FontAwesomeIcon icon="user" /> {owner}
            </span>
          )}
          {showSharedMessage && inEditMode && (
            <span
              className="notification-msg "
              title={
                "This synoptic is shared " +
                (showSharedForEditMessage ? "for edit" : "as readonly") +
                " with the group " +
                synoptic.group
              }
            >
              {showSharedForEditMessage ? (
                <FontAwesomeIcon icon="pen" />
              ) : (
                <FontAwesomeIcon icon="share-alt" />
              )}{" "}
              {synoptic.group}
            </span>
          )}
          {showRecentlyEditedMessage && inEditMode && (
            <span
              className="notification-msg ERROR "
              title={`This synoptic is currently being edited by ${lastUpdatedBy}`}
            >
              <FontAwesomeIcon icon="user-edit" /> {lastUpdatedBy}
            </span>
          )}
          {clonable && inEditMode && (
            <button
              onClick={() => this.props.onClone(id, loggedInUser)}
              className="btn-clone"
              title="Create a new copy of this synoptic"
            >
              Clone
            </button>
          )}
          {/* {inEditMode && notificationMsg && (
            <span className={`notification-msg " + ${level}`}>
              {notificationMsg}
            </span>
          )} */}
        </div>
      </>
    );
  }
}
function wasRecently(timestamp: Date | null) {
  if (!moment(timestamp || "").isValid()) {
    return false;
  }
  const diffInSeconds = moment().diff(moment(timestamp || "")) / 1000;
  return diffInSeconds < 60;
}

function mapStateToProps(state: IRootState) {
  return {
    synoptic: getSelectedSynoptic(state),
    loggedInUser: getUserName(state),
    notification: getNotification(state),
    userGroups: getUserGroups(state),
    mode: getMode(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onTitleChange: (id: string, name: string) => {
      dispatch(renameSynoptic(id, name));
    },
    onClone: (id: string, newUser: string) => {
      dispatch(cloneSynoptic(id, newUser));
    },
    onUndo: () => {
      dispatch(undo());
    },
    onRedo: () => {
      dispatch(redo());
    },
    onShareSynoptic: (id: string, group: string, groupWriteAccess: boolean) =>
      dispatch(shareSynoptic(id, group, groupWriteAccess)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SynopticTitle);
