import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTangoDB } from "../../dashboard/dashboardRepo";
import { executeCommand } from "../state/actions/tango";
import { commandExamples, validateCommandInput } from "./commandInputValidation";

export const InputField = props => {

  useEffect(() => {
    if ("custom" === props.renderType) getDefaultValue();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const isLoggedIn = useSelector((state) => state.user?.username ? true : false);
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [valid, setValid] = useState(props.intype === "DevString" || props.intype === "DevVoid");
  const [defaultValue, setDefaultValue] = useState("");
  const [defaultValidity, setDefaultValidity] = useState(false);
  const [pending, setPending] = useState(false)

  const disabled = !(valid && isLoggedIn);
  const { intypedesc, intype, placeholder } = props;
  const displayPlaceHolder = placeholder==='intypedesc' ? intypedesc : intype;
  let inner = null;
  const buttonLabel = props.buttonLabel
    ? props.buttonLabel
    : "Execute";

  // Supporting functions
  function getDefaultValue() {
    if (props.commandArgs && props.commandArgs.length > 0) {
      props.commandArgs.forEach((arg) => {
        if (arg.isDefault && arg.value) {
          const response = validateCommandInput(props.intype, arg.value);

          if (undefined !== response) {
            setValue(response);
            setValid(true);
            setDefaultValue(response);
            setDefaultValidity(true);
          }
        }
      });
    }
  }

  const handleBlur = (event) => {
    if (props.intype.includes('Array')) {
      initiateValidation(event);
    }
  }

  const handleChange = (event) => {
    if (props.intype.includes('Array')) {
      //input value of commands of type array are validated during submit
      setValue(event.target.value);

      const valid = (event.target.value) ? true : false
      setValid(valid)
      return;
    }

    initiateValidation(event);
  }

  const initiateValidation = (event) => {
    const response = validateCommandInput(props.intype, event.target.value);
    const value = (undefined === response) ? "" : response
    const valid = (undefined === response) ? false : true

    setValue(value);
    setValid(valid);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleExecute(e);
    }
  }

  const handleExecute = (event) => {
    event.preventDefault();
    if (pending) return;

    const val = Array.isArray(value) ? JSON.stringify(value) : value;
    const parameterMessage = ` with parameter ` + val;
    let message = `Confirm executing ${props.name} on ${props.device}`;
    if (props.intype)
      message += props.intype.includes("Void") ? "" : parameterMessage;

    if (!props.requireConfirmation || window.confirm(message)) {
      setPending(true);
      //EXECUTE COMMAND 
      dispatch(executeCommand(getTangoDB(), props.name, value, props.device))
      setValue("");
      setPending(false);
    }
  }

  const getNameFromValue = (value) => {
    const argObj = props.commandArgs.filter(
      (arg) => (String(arg.value) === String(value) || String(arg.value).replace(/[[\]]/g, '') === String(value))
    );
    return argObj.length > 0 && argObj[0].name !== "" ? argObj[0].name : value;
  }

  //End - Supporting functions

  if (intype === "DevVoid") {
    return (
      <button
        style={{...props.btnCss}}
        className="btn btn-outline-secondary btn-sm"
        type="button"
        disabled={disabled}
        onClick={handleExecute}
      >
        {buttonLabel}
      </button>
    );
  }

  if (intype === "DevBoolean") {
    inner = (
      <select
        className="custom-select"
        id="inputGroupSelect04"
        value={value}
        onChange={handleChange}
      >
        <option value="" defaultValue disabled hidden>
          Choose...
        </option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    );

  } else if (
    props.renderType &&
    "custom" === props.renderType.toLowerCase()
  ) {
    inner = (
      <Dropdown className="drp-wrapper" style={{ width: "160px" }}>
        <Dropdown.Toggle
          className="drp-bttn"
          variant="outline-secondary"
          style={{ width: "160px" }}
        >
          {value ? getNameFromValue(value) : "Select Argument"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            key="resetSelection"
            selected={true}
            as="button"
            value="reset_selection"
            onClick={() => {
              setValue(defaultValue);
              setValid(defaultValidity);  
            }}
          >
            Reset to default
          </Dropdown.Item>

          {props.commandArgs.length > 0 &&
            props.commandArgs
              .filter((arg) => undefined !== arg.value && arg.value !== "")
              .map((arg, idx) => {
                const response = validateCommandInput(props.intype, arg.value)
                const valid = undefined !== response ? true : false;
                const label = arg.name || arg.value;
                const title = valid
                  ? `Write value "${arg.value}"`
                  : `Invalid value "${arg.value}"`;

                return (
                  <span key={idx} title={title}>
                    <Dropdown.Item
                      disabled={!valid}
                      as="button"
                      value={arg.value}
                      onClick={handleChange}
                    >
                      {label}
                    </Dropdown.Item>
                  </span>
                );
              })}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else if (intype.toLowerCase().includes("array")) {
    const val = Array.isArray(value) ? JSON.stringify(value) : value;
    inner = (
      <input
        className="form-control"
        value={val}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={displayPlaceHolder}
      />
    );
  } else if (intype.includes("U")) {
    inner = (
      <input
        type="number"
        min="0"
        className="form-control"
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder={displayPlaceHolder}
      />
    );
  } else if (intype === "DevString") {
    inner = (
      <input
        type="text"
        className="form-control"
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder={displayPlaceHolder}
      />
    );
  } else {
    inner = (
      <input
        type="number"
        className="form-control"
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder={displayPlaceHolder}
      />
    );
  }

  const divLength = "library" === props.mode ? "75px" : "160px";
  return (
    <div className="input-group">
     
      <div className={(props.alignButtonRight ? "ml-auto" : "") + " input-group-append"}>
      <div style={{width: divLength}}>{inner}</div>
        <button
          style={{...props.btnCss}}
          className="btn btn-outline-secondary btn-sm"
          type="button"
          disabled={disabled}
          onClick={handleExecute}
          title={props.mode === 'run' ? isLoggedIn ? "Ex: " + commandExamples(props.intype) :
            "⚠️ You need to be logged in to use this ⚠️" : ""}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
}
