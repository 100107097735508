import React, { Component } from "react";
import { connect } from "react-redux";
import LibraryWidget from "./LibraryWidget";
import { bundles } from "../../widgets";
import {
  getSelectedDashboard
} from "../../../shared/state/selectors";
import { SelectedDashboardState } from "../../../shared/state/reducers/selectedDashboard";
import { IRootState } from "../../../shared/state/reducers/rootReducer";
import { IUserState } from "../../../shared/user/state/reducer"
import { isEditableDashboard } from "../../utils"

const config = window['config'];

interface Props {
  render: boolean;
  selectedDashboard: SelectedDashboardState;
  user: IUserState;
}
class WidgetLibrary extends Component<Props> {
  render() {
    if (!this.props.render) {
      return null;
    }
    // Widget library should be enabled if no dashboard is selected.
    const editableDashboard = this.props.selectedDashboard.id ? isEditableDashboard(this.props.selectedDashboard, this.props.user).result : true;

    return (
      <div className={`Library ${editableDashboard ? '' : 'disabled'}`}>
        {bundles.map((bundle, i) => {
          // Check if the current URL is accepted to include ELASTICSEARCH_LOG_VIEWER
          if (!config?.ELASTIC_ACCEPTED_URLS.includes(window.location.origin) &&
            bundle.definition.type === 'ELASTICSEARCH_LOG_VIEWER')
            return null;
          else return <LibraryWidget key={i} bundle={bundle} />;
        })}
      </div>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    selectedDashboard: getSelectedDashboard(state),
    user: state.user,
  };
}

function mapDispatchToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLibrary);