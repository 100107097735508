import React, { useState, useEffect } from "react";
import { IRootState } from "../../../shared/state/reducers/rootReducer" ;
import { connect } from "react-redux";
import Files from "react-files";
import { parse } from 'svg-parser';
import { Notification, NotificationLevel } from "../../../shared/notifications/notifications";
import { saveNotification } from "../../../shared/user/state/actionCreators";
import { generateUUID } from "../../../shared/utils/generateUUID";
import {
  getUsername,
} from "../../../shared/user/state/selectors";

interface StateProps {
  username?: string;
}

interface ComponentProps {
  className: string;
  onChange: (fileContent: string, fileName: string) => void;
  accepted: (string | undefined)[];
}

type Props = ComponentProps & StateProps & DispatchProps;

interface State {
  fileName: string;
  fileType: string;
  fileContent: any;
}

const FileInput: React.FC<Props> = (props) => {
  const [state, setState] = useState<State>({
    fileName: "No file selected",
    fileContent: "",
    fileType: "",
  });

  const onFilesChange = async (files) => {
    try {
      if (files[0] !== undefined) {
        let uploadFileName = files[0].name;
        setState({ ...state, fileName: uploadFileName });

        const fileContent = await readFileAsync(files[0]);
        if (files[0].type.includes("json") || files[0].type.includes("text"))
          setState({ ...state, fileType: "text" });
        else setState({ ...state, fileType: "binary" });

        try { 
          if(props.accepted[0] === ".svg"){
            parse(fileContent);
          }            
          setState((prevState) => ({ ...prevState, fileContent, fileName: uploadFileName }));

        } catch(e) {
          let message = ""; 
          if(props.accepted[0] === ".svg"){
            message = "Error on parsing the svg file: "
          }
          const username = props.username !== undefined ? props.username : "unknown"; 
          const notification: Notification = {
            username: username,
            level: NotificationLevel.ERROR,
            message: message + String(e),
            notified: false,
            timestamp: Date.now().toString(),
            key: generateUUID()
          };
      
          if(props.onSaveNotification !== undefined)
            props.onSaveNotification(notification, username);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const { onChange } = props;

  useEffect(() => {
    const { fileContent, fileName } = state;
    if (fileContent !== "") {
      onChange(fileContent, fileName);
    }
  }, [state, onChange]);

  return (
    <div>
      <Files
        onChange={onFilesChange}
        multiple={false}
        maxFileSize={25165824}
        minFileSize={0}
        clickable={true}
        accepts={props.accepted}
      >
        <div>
          <button
            className="btn-upload btn taranta-btn btn-secondary btn-sm"
            title={"Upload File (Max file size 24MB)"}
            disabled={false}
          >
          <span>Browse</span>
          </button>
        </div>
        <div className="file-name-wrapper">{state.fileName}</div>
      </Files>
    </div>
  );
}

interface DispatchProps {
  onSaveNotification?: (notification: Notification, username: string) => void;
}

function mapStateToProps(state:IRootState): StateProps {
  return {
    username: getUsername(state),
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onSaveNotification: (notification: Notification, username: string) =>
      dispatch(saveNotification(notification, username))
  };
}

export default connect<StateProps, DispatchProps, Props, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(FileInput);
